"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: null,
      listLoading: true,
      editBank: {}
    };
  },
  mounted: function mounted() {
    this.load();
  },
  methods: {
    load: function load() {
      var _this = this;
      this.listLoading = true;
      (0, _accounts.payAccountListApi)().then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
      }).catch(function (message) {
        _this.listLoading = false;
        _this.list = [];
      });
    },
    addBank: function addBank() {
      this.editBank = {
        show: true,
        name: '',
        pay_type: 'bank_transfer',
        account_name: '',
        account_number: '',
        open_bank: '',
        btnLoading: false
      };
    },
    editBankAction: function editBankAction(item) {
      this.editBank = {
        show: true,
        id: item.pay_account_id,
        name: item.name,
        pay_type: item.pay_type,
        account_name: item.param.account_name,
        account_number: item.param.account_number,
        open_bank: item.param.open_bank,
        btnLoading: false
      };
    },
    saveEditBankAction: function saveEditBankAction() {
      var _this2 = this;
      if (!this.editBank.account_name) {
        this.$message.error('请输入账户名称');
        return false;
      }
      if (!this.editBank.account_number) {
        this.$message.error('请输入账号');
        return false;
      }
      if (this.editBank.pay_type === 'bank_transfer' && !this.editBank.open_bank) {
        this.$message.error('请输入开户行');
        return false;
      }
      var action = _accounts.payAccountCreateApi;
      var data = JSON.parse(JSON.stringify(this.editBank));
      if (this.editBank.id) {
        action = _accounts.payAccountEditApi;
      } else {
        var newD = Date.parse(new Date()) / 1000;
        data.name = "".concat(data.pay_type === 'bank_transfer' ? '银行卡' : '支付宝', "-").concat(newD);
      }
      this.editBank.btnLoading = true;
      action(this.editBank.id, data).then(function () {
        _this2.$message.success('操作成功');
        _this2.load();
        _this2.editBank.btnLoading = false;
        _this2.editBank.show = false;
      }).catch(function (message) {
        _this2.editBank.btnLoading = false;
      });
    },
    delBankAction: function delBankAction(item) {
      var _this3 = this;
      this.$confirm('确认删除此账户？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _accounts.payAccountDelApi)(item.pay_account_id).then(function () {
          _this3.$message.success('操作成功');
          _this3.load();
        }).catch(function () {});
      }).catch(function (err) {
        console.error(err);
      });
    }
  }
};