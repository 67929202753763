"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _videoComponent = _interopRequireDefault(require("@/components/videoComponent.vue"));
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    VideoComponent: _videoComponent.default
  },
  data: function data() {
    return {
      timeLine: '',
      moduleVisible: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    window.addEventListener('message', function (event) {
      if (event.data && typeof event.data === 'string' && event.data.indexOf('timeline>>>') === 0) {
        if (_this.$refs.videoTimeRef && _this.moduleVisible) {
          _this.$refs.videoTimeRef.destroy();
          _this.moduleVisible = false;
        }
        var timeline = event.data.split('>>>')[1];
        _this.timeLine = JSON.parse(timeline);
        _this.$nextTick(function () {
          _this.moduleVisible = true;
        });
      }
    });
    // this.moduleVisible = true;
  },
  unmounted: function unmounted() {
    if (this.$refs.videoTimeRef) {
      this.$refs.videoTimeRef.destroy();
    }
  },
  methods: {
    onLoad: function onLoad() {
      this.$refs.videoTimeRef.initVideo(this.timeLine);
    }
  }
};