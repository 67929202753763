"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SystemRole',
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        roleName: ''
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _setting.menuRoleApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      console.log(row);
      (0, _setting.roleStatusApi)({
        roleId: row.roleId,
        status: Number(row.status)
      }).then(function (_ref) {
        var message = _ref.message;
        _this2.$message.success('操作成功');
        _this2.getList();
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push('/merchant/setting/systemRule/addSystemRole');
    },
    // 编辑
    onEdit: function onEdit(id) {
      this.$router.push({
        path: '/merchant/setting/systemRule/addSystemRole',
        query: {
          id: id
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u89D2\u8272?", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _setting.roleDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this3.$message.success('操作成功');
          _this3.getList(1);
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      }).catch(function () {});
    }
  }
};