"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _nft = require("@/api/nft");
var _index = require("@/utils/index");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      goodsArr: [],
      orderidArr: [],
      tab: "goods",
      listLoading: false,
      queryParams: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      formatIssuance: function formatIssuance(val) {
        var res = "";
        switch (val) {
          case true:
            res = "已发行";
            break;
          case false:
            res = "未发行";
            break;
          default:
            break;
        }
        return res;
      }
    };
  },
  created: function created() {
    this.getCollection();
    this.getList(1);
  },
  methods: {
    getCollection: function getCollection() {
      var _this = this;
      (0, _marketing.getGameCanuseCollection)({
        pageNum: 1,
        pageSize: 50
      }).then(function (res) {
        _this.goodsArr = res.data;
        _this.orderidArr = res.data.map(function (item) {
          return item.productId;
        });
      });
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _nft.nftCollectionListApi)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    selectinver: function selectinver(row) {
      var _this3 = this;
      if (this.orderidArr.indexOf(row.productId) > -1) {
        return;
      }
      (0, _marketing.setGameCanuseCollection)(row.productId, {
        productId: row.productId,
        addition: 0
      }).then(function (res) {
        _this3.getCollection();
      });
    },
    cancelSelect: function cancelSelect(row) {},
    delgoods: function delgoods(row) {
      var _this4 = this;
      (0, _marketing.delGameCanuseCollection)(row.id, {
        productId: row.productId,
        id: row.id
      }).then(function (res) {
        _this4.getCollection();
      });
    },
    changeaddition: function changeaddition(row) {
      // customize_price   自定义价格
      // is_customize    是否使用自定义价格 1:是 2:否
      (0, _marketing.setGameCanuseCollectionProperties)({
        id: row.id,
        nftId: row.nftId,
        productId: row.productId,
        addition: row.addition,
        hasDiyScore: row.hasDiyScore,
        diyScore: row.diyScore
      }).then(function (res) {});
    }
  }
};