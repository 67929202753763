"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _system = require("@/api/system");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      multipleSelection: [],
      rowList: {},
      visible: false,
      imgList: [],
      roterPre: _settings.roterPre,
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val.map(function (item) {
        return item.id;
      });
    },
    showImgAction: function showImgAction(row) {
      this.imgList = row.images.split(",");
      this.visible = true;
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _system.feedbackListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    batchDelete: function batchDelete() {
      var ids = this.multipleSelection.toString(",");
      this.handleDelete(ids);
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this2 = this;
      this.$modalSure().then(function () {
        (0, _system.feedbackDelApi)({
          ids: id
        }).then(function (_ref) {
          var message = _ref.message;
          _this2.$message.success("操作成功");
          _this2.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    }
  }
};