"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _banner = _interopRequireDefault(require("./banner"));
var _rubik = _interopRequireDefault(require("./rubik"));
var _search = _interopRequireDefault(require("./search"));
var _title = _interopRequireDefault(require("./title"));
var _video = _interopRequireDefault(require("./video"));
var _nav = _interopRequireDefault(require("./nav"));
var _blank = _interopRequireDefault(require("./blank"));
var _notice = _interopRequireDefault(require("./notice"));
var _tag = _interopRequireDefault(require("./tag"));
var _bbs = _interopRequireDefault(require("./bbs"));
var _ticket = _interopRequireDefault(require("./ticket"));
var _word = _interopRequireDefault(require("./word"));
var _work = _interopRequireDefault(require("./work"));
var _wechat = _interopRequireDefault(require("./wechat"));
var _mine_nav = _interopRequireDefault(require("./mine_nav"));
var _rubik_weChat = _interopRequireDefault(require("./rubik_weChat"));
var _textView = _interopRequireDefault(require("./textView"));
var _newExclusive = _interopRequireDefault(require("./newExclusive"));
var _memberEnter = _interopRequireDefault(require("./memberEnter"));
var _nftCollection = _interopRequireDefault(require("./nftCollection"));
var _nftCollectionMarket = _interopRequireDefault(require("./nftCollectionMarket"));
var _nftBlindBox = _interopRequireDefault(require("./nftBlindBox"));
var _blindBox = _interopRequireDefault(require("./blindBox"));
var _nftDetail = _interopRequireDefault(require("./nftDetail"));
var _nftBoxDetail = _interopRequireDefault(require("./nftBoxDetail"));
var _nftCollectionAlbum = _interopRequireDefault(require("./nftCollectionAlbum"));
var _allPointProducts = _interopRequireDefault(require("./allPointProducts"));
var _material = _interopRequireDefault(require("./material"));
var _Publisher = _interopRequireDefault(require("./Publisher"));
var _compose = _interopRequireDefault(require("./compose"));
var _blind = _interopRequireDefault(require("./blind"));
var _blindDetail = _interopRequireDefault(require("./blindDetail"));
var _blindOpen = _interopRequireDefault(require("./blindOpen"));
var _blindOrderList = _interopRequireDefault(require("./blindOrderList"));
var _nftInfo = _interopRequireDefault(require("./nftInfo"));
var _login = _interopRequireDefault(require("./login"));
var _composeList = _interopRequireDefault(require("./composeList"));
// 导入新人专享
// 会员入口
// 积分商城
// 实物商品
// 发行方组件
var _default = exports.default = {
  banner: _banner.default,
  rubik: _rubik.default,
  search: _search.default,
  title: _title.default,
  video: _video.default,
  nav: _nav.default,
  blank: _blank.default,
  notice: _notice.default,
  tag: _tag.default,
  bbs: _bbs.default,
  ticket: _ticket.default,
  word: _word.default,
  work: _work.default,
  wechat: _wechat.default,
  mine_nav: _mine_nav.default,
  rubik_weChat: _rubik_weChat.default,
  textView: _textView.default,
  newExclusive: _newExclusive.default,
  // 新人专享
  memberEnter: _memberEnter.default,
  // 会员入口
  Publisher: _Publisher.default,
  nftCollection: _nftCollection.default,
  allPointProducts: _allPointProducts.default,
  material: _material.default,
  nftCollectionMarket: _nftCollectionMarket.default,
  nftBlindBox: _nftBlindBox.default,
  blindBox: _blindBox.default,
  nftDetail: _nftDetail.default,
  nftBoxDetail: _nftBoxDetail.default,
  nftCollectionAlbum: _nftCollectionAlbum.default,
  compose: _compose.default,
  blind: _blind.default,
  blindDetail: _blindDetail.default,
  blindOpen: _blindOpen.default,
  blindOrderList: _blindOrderList.default,
  nftInfo: _nftInfo.default,
  login: _login.default,
  composeList: _composeList.default
};