"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'addRole',
  data: function data() {
    return {
      roleForm: {
        roleName: '',
        menuIds: [],
        status: ''
      },
      rules: {
        roleName: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }]
      },
      menuArr: [],
      props: {
        label: 'label',
        children: 'children'
      },
      checkedKeys: []
    };
  },
  created: function created() {
    this.menuTreeSelectApiMethod();
    if (this.$route.query.id) {
      this.roleDetailApiMethod(this.$route.query.id);
      this.roleMenuTreeselectApiMethod(this.$route.query.id);
    }
  },
  methods: {
    backAction: function backAction() {
      this.$router.back();
    },
    menuTreeSelectApiMethod: function menuTreeSelectApiMethod() {
      var _this = this;
      (0, _setting.menuTreeSelectApi)().then(function (res) {
        _this.menuArr = res.data;
      });
    },
    roleDetailApiMethod: function roleDetailApiMethod(id) {
      var _this2 = this;
      (0, _setting.roleDetailApi)(id).then(function (res) {
        _this2.roleForm.roleName = res.data.roleName;
        _this2.roleForm.status = res.data.status;
      });
    },
    roleMenuTreeselectApiMethod: function roleMenuTreeselectApiMethod(id) {
      var _this3 = this;
      (0, _setting.roleMenuTreeselectApi)(id).then(function (res) {
        _this3.checkedKeys = res.data.checkedKeys;
      });
    },
    // this.$refs.tree.getCheckedKeys()
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.roleForm));
          if (_this4.$route.query.id) {
            data.roleId = _this4.$route.query.id;
          }
          data.menuIds = _this4.$refs.tree.getCheckedKeys().concat(_this4.$refs.tree.getHalfCheckedKeys());
          data.status = Number(data.status);
          var action = _setting.roleCreateApi;
          if (_this4.$route.query.id) {
            action = _setting.roleUpdateApi;
          }
          var loading = _this4.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          action(data).then(function () {
            loading.close();
            _this4.$message.success('操作成功');
            _this4.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          return false;
        }
      });
    }
  }
};