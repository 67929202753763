var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "padding20 bg-white borderRadius6",
          staticStyle: { "min-height": "100vh" },
        },
        [
          _c(
            "div",
            { staticClass: "remove-control form-view" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合成名称：", prop: "activeName" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入活动名称" },
                            model: {
                              value: _vm.ruleForm.activeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "activeName", $$v)
                              },
                              expression: "ruleForm.activeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动页头图：", prop: "pictureUrl" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "pictureUrl")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.pictureUrl
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.pictureUrl + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提前合成：", prop: "nameLists" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                        },
                        model: {
                          value: _vm.ruleForm.isPriority,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "isPriority", $$v)
                          },
                          expression: "ruleForm.isPriority",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "ml10 w300",
                          attrs: {
                            placeholder: "请选择可提前合成的名单",
                            clearable: "",
                            multiple: "",
                            disabled: !_vm.ruleForm.isPriority,
                          },
                          model: {
                            value: _vm.ruleForm.nameLists,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "nameLists", $$v)
                            },
                            expression: "ruleForm.nameLists",
                          },
                        },
                        _vm._l(_vm.nameListsList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.name },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml20" }, [_vm._v("可提前")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "w100",
                        attrs: {
                          placeholder: "请输入",
                          disabled: !_vm.ruleForm.isPriority,
                        },
                        model: {
                          value: _vm.ruleForm.priorityTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "priorityTime", $$v)
                          },
                          expression: "ruleForm.priorityTime",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("分钟开始合成")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合成须知：" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.ruleForm.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "remarks", $$v)
                          },
                          expression: "ruleForm.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "信息推送：", prop: "toMessageTime" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.ruleForm.isToMessage,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "isToMessage", $$v)
                                },
                                expression: "ruleForm.isToMessage",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: 0 } }, [
                                _vm._v("站内消息"),
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 1 } }, [
                                _vm._v("短信群发"),
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 2 } }, [
                                _vm._v("Android推送"),
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 3 } }, [
                                _vm._v("iOS推送"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticClass: "selwidth ml20",
                            attrs: {
                              type: "datetime",
                              placeholder: "选择发送时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              disabled: _vm.ruleForm.isToMessage.length == 0,
                            },
                            model: {
                              value: _vm.ruleForm.toMessageTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "toMessageTime", $$v)
                              },
                              expression: "ruleForm.toMessageTime",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [
                            _vm._v("通知符合条件的用户"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "通用合成结果：", prop: "nameLists" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          disabled: _vm.noEdit,
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.ruleForm.isOnlyResult,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "isOnlyResult", $$v)
                          },
                          expression: "ruleForm.isOnlyResult",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml10 ft12 color-gray" }, [
                        _vm._v(
                          "打开后，隐藏单独玩法中的合成结果，所有玩法共用一个通用的合成结果"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.isOnlyResult
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合成结果：", required: "" } },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: {
                                        input: function (val) {
                                          return _vm.changeRadio(val)
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.isAllResult,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "isAllResult",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.isAllResult",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("全部合成"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("随机合成一项"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.ruleForm.isShow,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "isShow", $$v)
                                        },
                                        expression: "ruleForm.isShow",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("不展示剩余数量"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("展示剩余数量"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticClass: "table",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "tooltip-effect": "dark",
                                    data: _vm.composeResult.objectData,
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { width: "80", label: "类型" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatComposeType")(
                                                      scope.row.composeType
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1546899883
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { width: "80", label: "图片" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.composeType == 3
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upLoadPicBox",
                                                      attrs: {
                                                        title: "正方形图片",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.modalPicTap(
                                                            "1",
                                                            "composeResult",
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      scope.row.picture
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pictrue",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    scope.row
                                                                      .picture +
                                                                    "!120a",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoad",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-camera cameraIconfont",
                                                              }),
                                                            ]
                                                          ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "demo-image__preview",
                                                    },
                                                    [
                                                      scope.row.coverPicture
                                                        ? _c("el-image", {
                                                            attrs: {
                                                              src:
                                                                scope.row
                                                                  .coverPicture +
                                                                "!120a",
                                                              "preview-src-list":
                                                                [
                                                                  scope.row
                                                                    .coverPicture,
                                                                ],
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2578406680
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "名称",
                                      "min-width": "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      scope.row.productName
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      861716861
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        label: "名称",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                scope.row.isLock
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    已导入名单 总数" +
                                                            _vm._s(
                                                              scope.row
                                                                .lockNum || 0
                                                            ) +
                                                            " 已合成" +
                                                            _vm._s(
                                                              scope.row
                                                                .lockAlreadyNum ||
                                                                0
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "mini",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delUploadAction(
                                                                  "composeResult",
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除名单")]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-upload",
                                                          {
                                                            ref: "uploadImg",
                                                            staticClass:
                                                              "upload-demo mr10",
                                                            staticStyle: {
                                                              display:
                                                                "inline-block",
                                                            },
                                                            attrs: {
                                                              action: "#",
                                                              limit: 1,
                                                              multiple: "",
                                                              "http-request":
                                                                function (
                                                                  file
                                                                ) {
                                                                  return _vm.airdropExcels(
                                                                    file,
                                                                    "composeResult",
                                                                    scope.$index
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                  size: "mini",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "导入名单"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2420674588
                                      ),
                                    },
                                    [
                                      _c("template", { slot: "header" }, [
                                        _c(
                                          "div",
                                          [
                                            _vm._v(
                                              "\n                    指定合成\n                    "
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: { click: _vm.downLoad },
                                              },
                                              [_vm._v("下载名单模板")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "预留库存",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", [
                                                _vm._v(_vm._s(scope.row.stock)),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1566712401
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { "min-width": "100" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v("单次合成数量"),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "拥有符合条件的合成材料，一次合成可合成几份",
                                                    placement: "top-start",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.composeType != 3
                                                ? _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入单次合成数量",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.everyOpenNum,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "everyOpenNum",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.everyOpenNum",
                                                    },
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.everyOpenNum
                                                      )
                                                    ),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      759074400
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { "min-width": "100" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v("可被合成次数"),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "拥有多份符合条件的合成材料，可最多合成几次，不填为无限制",
                                                    placement: "top-start",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder:
                                                    "请输入可被合成次数",
                                                },
                                                model: {
                                                  value: scope.row.ableOpenNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "ableOpenNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.ableOpenNum",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3985852579
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { "min-width": "100" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v("合成总数量"),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "该玩法下，所有用户可以合成多少份",
                                                    placement: "top-start",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder:
                                                    "请输入合成总数量",
                                                  disabled: _vm.noEdit,
                                                },
                                                model: {
                                                  value: scope.row.allOpenNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "allOpenNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.allOpenNum",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      649501454
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "显示数量",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入数量",
                                                  disabled: _vm.noEdit,
                                                },
                                                model: {
                                                  value: scope.row.virtualStock,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "virtualStock",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.virtualStock",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4016585988
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      "min-width": "80",
                                      fixed: "right",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelComposeSelect(
                                                            scope.$index,
                                                            "composeResult"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      删除\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1922537587
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdditemComposeWorks(
                                        "composeResult"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              添加合成对象(藏品)\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdditemComposeBlind(
                                        "composeResult"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              添加合成对象(盲盒)\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.ruleForm.isAllResult == 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleAddFail(
                                            "composeResult"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              添加失败项\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { "content-position": "left" } }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("div", { staticClass: "playname" }, [_vm._v("玩法设置")]),
              _vm._v(" "),
              _vm._l(_vm.ruleSets, function (item, index) {
                return _c(
                  "el-form",
                  {
                    key: "rule" + index,
                    staticClass: "demo-ruleForm",
                    attrs: { "label-width": "120px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "玩法名称：", required: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入玩法名称" },
                          model: {
                            value: item.ruleSetName,
                            callback: function ($$v) {
                              _vm.$set(item, "ruleSetName", $$v)
                            },
                            expression: "item.ruleSetName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "玩法说明：", required: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: {
                            type: "textarea",
                            rows: 4,
                            placeholder: "请输入玩法说明",
                          },
                          model: {
                            value: item.ruleSetExplain,
                            callback: function ($$v) {
                              _vm.$set(item, "ruleSetExplain", $$v)
                            },
                            expression: "item.ruleSetExplain",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "玩法头图：", prop: "ruleSetImage" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(
                                  "1",
                                  "ruleSetImage",
                                  index
                                )
                              },
                            },
                          },
                          [
                            item.ruleSetImage
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: item.ruleSetImage + "!120a" },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "合成时间：", required: "" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            align: "right",
                          },
                          model: {
                            value: item.assistDate,
                            callback: function ($$v) {
                              _vm.$set(item, "assistDate", $$v)
                            },
                            expression: "item.assistDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "参与条件：" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: item.ruleSetCondition,
                              callback: function ($$v) {
                                _vm.$set(item, "ruleSetCondition", $$v)
                              },
                              expression: "item.ruleSetCondition",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex align-items-c" },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("无门槛"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  { staticClass: "mr20", attrs: { label: 1 } },
                                  [_vm._v("活动价格")]
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w200 mr20",
                                  attrs: {
                                    disabled: item.ruleSetCondition != 1,
                                    placeholder: "输入价格",
                                  },
                                  model: {
                                    value: item.commission,
                                    callback: function ($$v) {
                                      _vm.$set(item, "commission", $$v)
                                    },
                                    expression: "item.commission",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "ml10 mr20",
                                    attrs: { label: 2 },
                                  },
                                  [_vm._v("消耗积分")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w100",
                                    attrs: {
                                      disabled: item.ruleSetCondition != 2,
                                      placeholder: "积分类型",
                                    },
                                    model: {
                                      value: item.scoreType,
                                      callback: function ($$v) {
                                        _vm.$set(item, "scoreType", $$v)
                                      },
                                      expression: "item.scoreType",
                                    },
                                  },
                                  _vm._l(_vm.typeList, function (m, i) {
                                    return _c("el-option", {
                                      key: "type" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "w200 ml10",
                                    attrs: {
                                      disabled: item.ruleSetCondition != 2,
                                      placeholder: "输入积分",
                                    },
                                    model: {
                                      value: item.scoreNum,
                                      callback: function ($$v) {
                                        _vm.$set(item, "scoreNum", $$v)
                                      },
                                      expression: "item.scoreNum",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "append" },
                                        slot: "append",
                                      },
                                      [_vm._v("积分")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "人群限制：", required: "" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              input: function ($event) {
                                return _vm.changeConditions(index)
                              },
                            },
                            model: {
                              value: item.joinConditions,
                              callback: function ($$v) {
                                _vm.$set(item, "joinConditions", $$v)
                              },
                              expression: "item.joinConditions",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex align-items-c" },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("不限"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("快照名单"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "ml10 mr10",
                                    staticStyle: { width: "500px" },
                                    attrs: {
                                      placeholder: "选择名单",
                                      clearable: "",
                                      multiple: "",
                                      disabled: item.joinConditions != 1,
                                    },
                                    model: {
                                      value: item.shotList,
                                      callback: function ($$v) {
                                        _vm.$set(item, "shotList", $$v)
                                      },
                                      expression: "item.shotList",
                                    },
                                  },
                                  _vm._l(_vm.nameListsList, function (m, i) {
                                    return _c("el-option", {
                                      key: "nList" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("指定名单和合成次数"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-upload",
                                  {
                                    ref: "uploadImg",
                                    refInFor: true,
                                    staticClass: "upload-demo mr10",
                                    staticStyle: { display: "inline-block" },
                                    attrs: {
                                      action: "#",
                                      limit: 1,
                                      multiple: "",
                                      "http-request": function (file) {
                                        return _vm.airdropExcels(
                                          file,
                                          "ruleSets",
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled: item.joinConditions != 2,
                                          size: "mini",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.isLock
                                              ? "重新导入数据"
                                              : "导入数据"
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                item.isLock
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "text",
                                          size: "mini",
                                          underline: false,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                已导入" +
                                            _vm._s(item.userNum) +
                                            "条记录,可合成" +
                                            _vm._s(item.lockNum) +
                                            "次\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      disabled: item.joinConditions != 2,
                                      type: "text",
                                      size: "mini",
                                    },
                                    on: { click: _vm.downLoad },
                                  },
                                  [
                                    _vm._v(
                                      "\n                下载模板\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "合成材料：", required: "" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: item.isAll,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isAll", $$v)
                                  },
                                  expression: "item.isAll",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("全部满足"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("满足任意一项"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            staticClass: "table mt10",
                            staticStyle: { width: "100%" },
                            attrs: {
                              "tooltip-effect": "dark",
                              data: item.materialData,
                              size: "mini",
                              "span-method": _vm.objectSpanMethod,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { width: "80", label: "编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.tag) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { width: "80", label: "图片" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            scope.row.coverPicture
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.coverPicture +
                                                      "!120a",
                                                    "preview-src-list": [
                                                      scope.row.coverPicture,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "名称", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.productName) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "合成后操作",
                                "min-width": "180",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.composeType != 4
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                [
                                                  _c("span", [
                                                    _vm._v("合成成功"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .afterOperation,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "afterOperation",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.afterOperation",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 1 } },
                                                        [_vm._v("回收")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 2 } },
                                                        [_vm._v("销毁")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 3 } },
                                                        [_vm._v("保留")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              (_vm.showFail &&
                                                _vm.ruleForm.isOnlyResult) ||
                                              _vm.ruleSets[
                                                index
                                              ].objectData.findIndex(function (
                                                item
                                              ) {
                                                return item.composeType == 3
                                              }) > -1
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("span", [
                                                        _vm._v("合成失败"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .failAfterOperation,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "failAfterOperation",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.failAfterOperation",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: 1,
                                                              },
                                                            },
                                                            [_vm._v("回收")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: 2,
                                                              },
                                                            },
                                                            [_vm._v("销毁")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: 3,
                                                              },
                                                            },
                                                            [_vm._v("保留")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "合成需要数量",
                                "min-width": "180",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.productNum > 1
                                          ? _c("el-input-number", {
                                              attrs: { min: 1 },
                                              model: {
                                                value:
                                                  item.newRules[
                                                    scope.row.ruleIndex
                                                  ].count,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item.newRules[
                                                      scope.row.ruleIndex
                                                    ],
                                                    "count",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.newRules[scope.row.ruleIndex].count",
                                              },
                                            })
                                          : _c("el-input-number", {
                                              attrs: { min: 1 },
                                              model: {
                                                value: scope.row.needNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "needNum",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.needNum",
                                              },
                                            }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "规则名称", "min-width": "180" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.composeType != 4
                                          ? _c(
                                              "div",
                                              [
                                                scope.row.productNum > 1
                                                  ? _c("el-input", {
                                                      attrs: { min: 1 },
                                                      model: {
                                                        value:
                                                          item.newRules[
                                                            scope.row.ruleIndex
                                                          ].ruleSetName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item.newRules[
                                                              scope.row
                                                                .ruleIndex
                                                            ],
                                                            "ruleSetName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.newRules[scope.row.ruleIndex].ruleSetName",
                                                      },
                                                    })
                                                  : _c("el-input", {
                                                      attrs: { min: 1 },
                                                      model: {
                                                        value:
                                                          scope.row.ruleSetName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "ruleSetName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.ruleSetName",
                                                      },
                                                    }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "持仓时间限制",
                                "min-width": "180",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.composeType != 4
                                          ? _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  "\n                  大于等于"
                                                ),
                                                _c("el-input-number", {
                                                  staticClass: "w50",
                                                  attrs: {
                                                    controls: false,
                                                    precision: 0,
                                                    min: 0,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.holdLimitTime,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "holdLimitTime",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.holdLimitTime",
                                                  },
                                                }),
                                                _vm._v(
                                                  "小时\n                "
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                "min-width": "80",
                                fixed: "right",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.cancelSelect(
                                                      scope.$index,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    删除\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.oldMaterialData.length
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "20px" } },
                              [
                                _vm._v(
                                  "\n            设置总量：\n            "
                                ),
                                _vm._l(item.newRules, function (it, ins) {
                                  return _c(
                                    "span",
                                    { key: "srule" + ins },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(it.tagList.join("+")) +
                                          _vm._s(
                                            it.ruleSetName
                                              ? "(" + it.ruleSetName + ")"
                                              : ""
                                          ) +
                                          " =\n              " +
                                          _vm._s(it.count)
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml5 mr20",
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteRuleAction(
                                                index,
                                                ins
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                _vm._v(" "),
                                item.oldMaterialData.length >
                                  item.hasRuleIds.length + 1 &&
                                _vm.noInner(item.oldMaterialData)
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addRuleAction(index)
                                          },
                                        },
                                      },
                                      [_vm._v("添加规则")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAdditem(index)
                              },
                            },
                          },
                          [_vm._v("\n            添加合成材料\n          ")]
                        ),
                        _vm._v(" "),
                        !item.hasRuleIds.length && _vm.isShowAddInner
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAddInner(index)
                                  },
                                },
                              },
                              [_vm._v("\n            添加内丹\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.ruleForm.isOnlyResult
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "合成结果：", required: "" } },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: {
                                      input: function (val) {
                                        return _vm.changeRadio(val, index)
                                      },
                                    },
                                    model: {
                                      value: item.isAllResult,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isAllResult", $$v)
                                      },
                                      expression: "item.isAllResult",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("全部合成"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("随机合成一项"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: item.isShow,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("不展示剩余数量"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("展示剩余数量"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              {
                                staticClass: "table",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "tooltip-effect": "dark",
                                  data: item.objectData,
                                  size: "mini",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { width: "80", label: "类型" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatComposeType")(
                                                    scope.row.composeType
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { width: "80", label: "图片" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.composeType == 3
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                    attrs: {
                                                      title: "正方形图片",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.modalPicTap(
                                                          "1",
                                                          "objectData",
                                                          index,
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    scope.row.picture
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pictrue",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src:
                                                                  scope.row
                                                                    .picture +
                                                                  "!120a",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "upLoad",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-camera cameraIconfont",
                                                            }),
                                                          ]
                                                        ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "demo-image__preview",
                                                  },
                                                  [
                                                    scope.row.coverPicture
                                                      ? _c("el-image", {
                                                          attrs: {
                                                            src:
                                                              scope.row
                                                                .coverPicture +
                                                              "!120a",
                                                            "preview-src-list":
                                                              [
                                                                scope.row
                                                                  .coverPicture,
                                                              ],
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "名称", "min-width": "100" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.productName
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "名称",
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.isLock
                                                ? _c(
                                                    "div",
                                                    [
                                                      _vm._v(
                                                        "\n                  已导入名单 总数" +
                                                          _vm._s(
                                                            scope.row.lockNum ||
                                                              0
                                                          ) +
                                                          " 已合成" +
                                                          _vm._s(
                                                            scope.row
                                                              .lockAlreadyNum ||
                                                              0
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.delUploadAction(
                                                                index,
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除名单")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-upload",
                                                        {
                                                          ref: "uploadImg",
                                                          refInFor: true,
                                                          staticClass:
                                                            "upload-demo mr10",
                                                          staticStyle: {
                                                            display:
                                                              "inline-block",
                                                          },
                                                          attrs: {
                                                            action: "#",
                                                            limit: 1,
                                                            multiple: "",
                                                            "http-request":
                                                              function (file) {
                                                                return _vm.airdropExcels(
                                                                  file,
                                                                  index,
                                                                  scope.$index
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                                size: "mini",
                                                              },
                                                            },
                                                            [_vm._v("导入名单")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "div",
                                        [
                                          _vm._v(
                                            "\n                  指定合成\n                  "
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: { click: _vm.downLoad },
                                            },
                                            [_vm._v("下载名单模板")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "预留库存",
                                    "min-width": "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(_vm._s(scope.row.stock)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { "min-width": "100" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v("单次合成数量"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "拥有符合条件的合成材料，一次合成可合成几份",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-warning-outline",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.composeType != 3
                                              ? _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入单次合成数量",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.everyOpenNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "everyOpenNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.everyOpenNum",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.everyOpenNum
                                                    )
                                                  ),
                                                ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { "min-width": "100" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v("可被合成次数"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "拥有多份符合条件的合成材料，可最多合成几次，不填为无限制",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-warning-outline",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "请输入可被合成次数",
                                              },
                                              model: {
                                                value: scope.row.ableOpenNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "ableOpenNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.ableOpenNum",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { "min-width": "100" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [_vm._v("合成总数量")]),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "该玩法下，所有用户可以合成多少份",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-warning-outline",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入合成总数量",
                                                disabled: _vm.noEdit,
                                              },
                                              model: {
                                                value: scope.row.allOpenNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "allOpenNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.allOpenNum",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "显示数量",
                                    "min-width": "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入数量",
                                                disabled: _vm.noEdit,
                                              },
                                              model: {
                                                value: scope.row.virtualStock,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "virtualStock",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.virtualStock",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "操作",
                                    "min-width": "80",
                                    fixed: "right",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelComposeSelect(
                                                          scope.$index,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    删除\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAdditemComposeWorks(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            添加合成对象(藏品)\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAdditemComposeBlind(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            添加合成对象(盲盒)\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            item.isAllResult == 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAddFail(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            添加失败项\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "赠送积分：" } },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: item.isGiveScore,
                            callback: function ($$v) {
                              _vm.$set(item, "isGiveScore", $$v)
                            },
                            expression: "item.isGiveScore",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticClass: "w100 ml10",
                            attrs: {
                              disabled: !item.isGiveScore,
                              placeholder: "积分类型",
                            },
                            model: {
                              value: item.giveScoreType,
                              callback: function ($$v) {
                                _vm.$set(item, "giveScoreType", $$v)
                              },
                              expression: "item.giveScoreType",
                            },
                          },
                          _vm._l(_vm.typeList, function (m, i) {
                            return _c("el-option", {
                              key: "type" + i,
                              attrs: { value: m.id, label: m.name },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-input",
                          {
                            staticClass: "w200 ml10",
                            attrs: {
                              disabled: !item.isGiveScore,
                              placeholder: "输入积分",
                            },
                            model: {
                              value: item.giveScoreNum,
                              callback: function ($$v) {
                                _vm.$set(item, "giveScoreNum", $$v)
                              },
                              expression: "item.giveScoreNum",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "append" }, slot: "append" },
                              [_vm._v("积分")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        item.deleteId
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteplay(item.deleteId)
                                  },
                                },
                              },
                              [_vm._v("\n            删除玩法\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    index != _vm.ruleSets.length - 1
                      ? _c(
                          "el-form-item",
                          [
                            _c("el-divider", {
                              attrs: { "content-position": "left" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "10px", "margin-left": "120px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addPlay },
                },
                [_vm._v("\n        添加玩法\n      ")]
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              staticStyle: {
                "margin-left": "120px",
                width: "200px",
                "margin-top": "30px",
              },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAction("ruleForm")
                },
              },
            },
            [_vm._v("\n      确认创建\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.visibleTitle,
            visible: _vm.visible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.visibleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: {
                    size: "small",
                    clearable: "",
                    placeholder: "名称搜索",
                  },
                  on: { change: _vm.nftCollectionListApiMethod },
                  model: {
                    value: _vm.materialForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.materialForm, "name", $$v)
                    },
                    expression: "materialForm.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.nftCollectionListApiMethod },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  key: Math.random(),
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.materialList.data, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "头像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.coverPicture },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseRow(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.chooseList.findIndex(function (res) {
                                      return res.productId === scope.row.id
                                    }) != -1
                                      ? "取消选择"
                                      : "选择"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                staticClass: "mt10",
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.materialForm.pageSize,
                  "current-page": _vm.materialForm.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.materialList.total,
                },
                on: {
                  "size-change": _vm.materialFormSizeChange,
                  "current-change": _vm.materialFormpageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "创建规则", visible: _vm.createRule, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.createRule = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "change-product" },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.changeP,
                    callback: function ($$v) {
                      _vm.changeP = $$v
                    },
                    expression: "changeP",
                  },
                },
                [
                  _vm._l(
                    _vm.ruleSets[_vm.ruleIndex].oldMaterialData,
                    function (m, i) {
                      return [
                        _vm.ruleSets[_vm.ruleIndex].hasRuleIds.indexOf(
                          m.productId
                        ) == -1
                          ? [
                              _c(
                                "div",
                                { key: "rule" + i, staticClass: "mt10" },
                                [
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: m.productId } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex align-items-c" },
                                        [
                                          _c("div", { staticClass: "tag" }, [
                                            _vm._v(_vm._s(m.tag)),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "img ml10" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: m.coverPicture + "!120a",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "name ml10 flex-one line1",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(m.productName) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "set-count flex align-items-c mt20" }, [
            _c("span", [_vm._v("需要数量")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml10" },
              [
                _c("el-input", {
                  staticClass: "w200",
                  model: {
                    value: _vm.changeNum,
                    callback: function ($$v) {
                      _vm.changeNum = $$v
                    },
                    expression: "changeNum",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "ml10" }, [_vm._v("规则名称")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml10" },
              [
                _c("el-input", {
                  staticClass: "w200",
                  model: {
                    value: _vm.ruleName,
                    callback: function ($$v) {
                      _vm.ruleName = $$v
                    },
                    expression: "ruleName",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.createRuleAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择产出项",
            visible: _vm.produceDialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.produceDialog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.produceData } },
            [
              _c("el-table-column", { attrs: { label: "Id", prop: "id" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          attrs: { src: scope.row.image, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户持有" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v(_vm._s(scope.row.allNum))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "未领取上限", prop: "maxAccount" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.selectProduce(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }