"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _frame = require("@/api/frame");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var activeIndexObj = {
  "1": "frameList",
  "2": "frameType",
  "3": "framePaper",
  "4": "frameScence",
  "5": "frameFrame"
};
var _default = exports.default = {
  name: "frameFrame",
  data: function data() {
    return {
      srcList: [],
      lists: [],
      queryParams: {
        pageNum: 1,
        pageSize: 20
      },
      categoryId: "",
      categorys: [],
      total: 0,
      frameflag: false,
      typeStatus: "0",
      formList: {
        bgcolor: "",
        category: "",
        categoryId: 0,
        downHeight: 0,
        downImg: "",
        downNeiShadowHeight: 0,
        downShadowHeight: 0,
        id: "",
        imgUrl: "",
        isDel: 0,
        isScroll: 0,
        leftDownHeight: 0,
        leftDownImg: "",
        leftDownWidth: 0,
        leftImg: "",
        leftShadowWidth: 0,
        leftUpHeight: 0,
        leftUpImg: "",
        leftUpWidth: 0,
        leftWidth: 0,
        rightDownHeight: 0,
        rightDownImg: "",
        rightDownWidth: 0,
        rightImg: "",
        rightNeiShadowWidth: 0,
        rightShadowWidth: 0,
        rightUpHeight: 0,
        rightUpImg: "",
        rightUpWidth: 0,
        rightWidth: 0,
        roundBorder: 0,
        roundParams: "",
        type: 0,
        upHeight: 0,
        upImg: "",
        upShadowHeight: 0,
        categoryType: 0
      }
    };
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      this.$router.push({
        name: activeIndexObj[index]
      });
    },
    changeType: function changeType(type) {
      this.queryParams.pageNum = 1;
      this.load();
    },
    changeTypeStatus: function changeTypeStatus(value) {
      this.typeStatus = value;
      this.load();
    },
    edit: function edit(row, type) {
      this.work = row;
      this.price = "";
      if (type === 1) {
        this.saleflag = true;
        return;
      }
      this.handle(type);
    },
    addFrame: function addFrame() {
      this.frameflag = true;
      this.formList = {
        bgcolor: "",
        category: "",
        categoryId: this.categoryId,
        downHeight: 0,
        downImg: "",
        downNeiShadowHeight: 0,
        downShadowHeight: 0,
        id: "",
        imgUrl: "",
        isScroll: "",
        leftDownHeight: 0,
        leftDownImg: "",
        leftDownWidth: 0,
        leftImg: "",
        leftShadowWidth: 0,
        leftUpHeight: 0,
        leftUpImg: "",
        leftUpWidth: 0,
        leftWidth: 0,
        rightDownHeight: 0,
        rightDownImg: "",
        rightDownWidth: 0,
        rightImg: "",
        rightNeiShadowWidth: 0,
        rightShadowWidth: 0,
        rightUpHeight: 0,
        rightUpImg: "",
        rightUpWidth: 0,
        rightWidth: 0,
        roundBorder: 0,
        roundParams: "",
        type: 0,
        upHeight: 0,
        upImg: "",
        upShadowHeight: 0,
        categoryType: 0
      };
    },
    save: function save() {
      var _this = this;
      var json = this.formList;
      if (json.id) {
        (0, _frame._updateFrame)(json).then(function (res) {
          if (res.code === 200) {
            _this.$message.success("编辑成功");
          } else {
            _this.$message.error(res.msg);
          }
          _this.frameflag = false;
          _this.load();
        }).catch(function (err) {
          console.log(err);
          _this.$message.error(err.message);
        });
      } else {
        (0, _frame._createFrame)(json).then(function (res) {
          if (res.code === 200) {
            _this.$message.success("添加成功");
          } else {
            _this.$message.error(res.msg);
          }
          _this.frameflag = false;
          _this.queryParams.pageNum = 1;
          _this.load();
        }).catch(function (err) {
          console.log(err);
          /* this.$message.error(err.message); */
        });
      }
    },
    handle: function handle(row) {
      this.frameflag = true;
      this.formList = JSON.parse(JSON.stringify(row));
    },
    deleteFrame: function deleteFrame(row) {
      var _this2 = this;
      (0, _frame._deleteFrame)({
        id: row.id,
        type: 1
      }).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success("删除成功");
          _this2.load();
        } else {
          _this2.$message.error(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err.message);
      });
    },
    getCategory: function getCategory() {
      var _this3 = this;
      var params = {};
      params.type = 1;
      (0, _frame._getTypeList)(params).then(function (res) {
        if (res.code === 200) {
          _this3.categorys = res.rows;
          if (res.rows.length) {
            _this3.categoryId = res.rows[0].id;
            _this3.load();
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    load: function load() {
      var _this4 = this;
      var params = this.queryParams;
      params.categoryId = this.categoryId;
      params.categoryType = this.typeStatus == 0 ? "" : this.typeStatus;
      this.resultData = [];
      this.loading = true;
      (0, _frame._getFrameList)(params).then(function (res) {
        if (res.code === 200) {
          _this4.total = res.total;
          _this4.srcList = res.rows.map(function (item) {
            return item.imgUrl;
          });
          _this4.lists = res.rows.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              categoryName: _this4.categorys.find(function (i) {
                return i.id == item.categoryId;
              }).categoryName
            });
          });
        }
        _this4.loading = false;
      }).catch(function (err) {
        _this4.loading = false;
        console.log(err);
      });
    },
    onUpload: function onUpload(type) {
      var _this5 = this;
      this.$modalUpload(function (img) {
        if (img.length > 1) return _this5.$message.error("只能选择一张");
        _this5.formList[type] = img[0];
      });
    },
    handleRemove: function handleRemove(type) {
      this.formList[type] = "";
    },
    changePageSize: function changePageSize(pageSize) {
      this.queryParams.pageSize = pageSize;
      this.queryParams.pageNum = 1;
      this.$router.push({
        path: this.$route.path,
        query: {
          type: this.queryParams.topictype,
          pageNum: 1,
          pageSize: pageSize
        }
      });
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.pageNum = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          type: this.queryParams.topictype,
          pageNum: newPage,
          pageSize: this.queryParams.pageSize
        }
      });
      this.load();
    },
    change: function change() {}
  },
  watch: {},
  created: function created() {
    this.getCategory();
  }
};