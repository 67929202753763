var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c("ul", [
            _c("li", [
              _c("span", [_vm._v("守卫")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "custom-table" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.outPutData } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "守卫名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    scope.row.productImage
                                      ? _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            _c("el-image", {
                                              staticClass: "mr10",
                                              attrs: {
                                                src: scope.row.productImage,
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.productName)
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectOutPut(
                                                  "guard",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    选择\n                  "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "效果(缩减成长值)" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  staticClass: "w100 ml5 mr5",
                                  attrs: {
                                    controls: false,
                                    min: 1,
                                    precision: 0,
                                  },
                                  model: {
                                    value: scope.row.downOrUpNum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "downOrUpNum", $$v)
                                    },
                                    expression: "scope.row.downOrUpNum",
                                  },
                                }),
                                _vm._v("g\n              "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteOutOut(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false, type: "primary" },
                      on: { click: _vm.addOutPut },
                    },
                    [_vm._v("+添加")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("肥料")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "custom-table" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.fertilizerData } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "肥料名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    scope.row.productImage
                                      ? _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            _c("el-image", {
                                              staticClass: "mr10",
                                              attrs: {
                                                src: scope.row.productImage,
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.productName)
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectOutPut(
                                                  "fertilizer",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    选择\n                  "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "效果(缩减成长值)" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  staticClass: "w100 ml5 mr5",
                                  attrs: {
                                    controls: false,
                                    min: 1,
                                    precision: 0,
                                  },
                                  model: {
                                    value: scope.row.downOrUpNum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "downOrUpNum", $$v)
                                    },
                                    expression: "scope.row.downOrUpNum",
                                  },
                                }),
                                _vm._v("g\n              "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteFertilizer(
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false, type: "primary" },
                      on: { click: _vm.addFertilizer },
                    },
                    [_vm._v("+添加")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: {
          "current-data": [],
          size: "1",
          showSelect: true,
          needPointsDeductionType: false,
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }