var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "code-v" },
        [
          _c(
            "el-form",
            {
              attrs: { size: "small", "label-width": "0px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("el-form-item", { attrs: { label: " " } }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("span", { staticClass: "fWeight500" }, [
                      _vm._v("状态："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "mr20",
                        attrs: { type: "button" },
                        on: { change: _vm.changeList },
                        model: {
                          value: _vm.tableFrom.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "status", $$v)
                          },
                          expression: "tableFrom.status",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "" } }, [
                          _vm._v("全部"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "0" } }, [
                          _vm._v("待开始"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("兑换中"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "-1" } }, [
                          _vm._v("已结束"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c("span", { staticClass: "fWeight500" }, [
                    _vm._v("兑换码名称："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "w200",
                      attrs: { placeholder: "请输入兑换码名称", clearable: "" },
                      model: {
                        value: _vm.tableFrom.codeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "codeName", $$v)
                        },
                        expression: "tableFrom.codeName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.search },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path:
                            "" +
                            _vm.roterPre +
                            "/marketing/conversionCode/conversionCodeAdd",
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("创建兑换码")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.getExportFileList(20)
                        },
                      },
                    },
                    [_vm._v("查看导出记录")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.rows,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "兑换码名称",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "兑换藏品", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.products, function (y, index) {
                        return _c("div", { key: index }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(y.type == 1 ? "数字藏品" : "盲盒") +
                              ":" +
                              _vm._s(y.count) +
                              "\n          "
                          ),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "count", label: "数量", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "code_num",
                            on: {
                              click: function ($event) {
                                return _vm.clickNum(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.count) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "redeemedCount",
                  label: "已兑换",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "code_num",
                            on: {
                              click: function ($event) {
                                return _vm.clickExchange(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.redeemedCount) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "兑换有效期", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.startTime) +
                            " 至 " +
                            _vm._s(scope.row.endTime) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("div", [_vm._v("待开始")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 1
                          ? _c("div", [_vm._v("兑换中")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == -1
                          ? _c("div", [_vm._v("已结束")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.exportRecord(scope.row, 20)
                              },
                            },
                          },
                          [_vm._v("导出兑换码")]
                        ),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getRemove(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("结束兑换\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == -1 || scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getRemove(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v("\n      >\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                staticClass: "mt20",
                staticStyle: { "text-align": "right" },
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "70%",
            visible: _vm.receiveData.show,
            "append-to-body": "",
            title: "查看兑换码",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.receiveData, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "receive-data flex align-items-c" }, [
            _c(
              "div",
              { staticClass: "flex align-items-c flex-one" },
              [
                _c(
                  "el-form",
                  {
                    attrs: { size: "small", "label-width": "0px" },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("el-form-item", { attrs: { label: " " } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("span", { staticClass: "fWeight500" }, [
                            _vm._v("状态："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button" },
                              on: { change: _vm.changeList1 },
                              model: {
                                value: _vm.tableFrom1.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom1, "status", $$v)
                                },
                                expression: "tableFrom1.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("未兑换"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("已兑换"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [_vm._v("已作废")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: " " } },
                      [
                        _c("span", { staticClass: "fWeight500" }, [
                          _vm._v("兑换码："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-input",
                          {
                            staticClass: "w200",
                            attrs: {
                              placeholder: "请输入兑换码",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom1.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom1, "code", $$v)
                              },
                              expression: "tableFrom1.code",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: { slot: "append", icon: "el-icon-search" },
                              on: { click: _vm.searchLook },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.batchInvalid },
                  },
                  [_vm._v("批量作废")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "receive-list mt20" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.receiveData.rows,
                    size: "small",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.changeSelect },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      selectable: _vm.selectable,
                      type: "selection",
                      width: "55",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "兑换码",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.code))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "状态", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              scope.row.status == 1
                                ? _c("div", [_vm._v(_vm._s("已兑换"))])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status == 0
                                ? _c("div", [_vm._v(_vm._s("未兑换"))])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status == -1
                                ? _c("div", [_vm._v(_vm._s("已作废"))])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "150",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportInvalid(scope.row, 1)
                                      },
                                    },
                                  },
                                  [_vm._v("作废\n            ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "block mt20",
                  staticStyle: { "text-align": "right" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.receiveData.query.pageSize,
                      "current-page": _vm.receiveData.query.pageNum,
                      total: _vm.receiveData.total,
                    },
                    on: { "current-change": _vm.pageChangeNew },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "70%",
            visible: _vm.receiveData1.show,
            "append-to-body": "",
            title: "兑换记录",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.receiveData1, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "receive-data flex align-items-c" }, [
            _c(
              "div",
              { staticClass: "flex align-items-c flex-one" },
              [
                _c(
                  "el-form",
                  {
                    attrs: { size: "small", "label-width": "0px" },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("el-form-item", { attrs: { label: " " } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("span", { staticClass: "fWeight500" }, [
                            _vm._v("状态："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button" },
                              on: { change: _vm.changeList1 },
                              model: {
                                value: _vm.tableFrom2.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom2, "status", $$v)
                                },
                                expression: "tableFrom2.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("待开始"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("兑换中"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [_vm._v("已结束")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: " " } },
                      [
                        _c("span", { staticClass: "fWeight500" }, [
                          _vm._v("用户名或手机号："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-input",
                          {
                            staticClass: "w200",
                            attrs: {
                              placeholder: "请输入用户名/手机号",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom2.nickNameOrPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom2, "nickNameOrPhone", $$v)
                              },
                              expression: "tableFrom2.nickNameOrPhone",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: { slot: "append", icon: "el-icon-search" },
                              on: { click: _vm.searchList },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: " " } },
                      [
                        _c("span", { staticClass: "fWeight500" }, [
                          _vm._v("兑换码："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-input",
                          {
                            staticClass: "w200",
                            attrs: {
                              placeholder: "请输入兑换码",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom2.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom2, "code", $$v)
                              },
                              expression: "tableFrom2.code",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: { slot: "append", icon: "el-icon-search" },
                              on: { click: _vm.searchList },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.exportRecord(_vm.tableFrom2, 21)
                      },
                    },
                  },
                  [_vm._v("导出\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.getExportFileList(21)
                      },
                    },
                  },
                  [_vm._v("导出已生成列表")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "receive-list mt20" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.receiveData1.rows,
                    size: "small",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "兑换码",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.code))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "用户", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.memberNickName +
                                    "/" +
                                    scope.row.memberPhone
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "type",
                      label: "兑换类型",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.type == 1
                              ? _c("div", [_vm._v(_vm._s("数字藏品"))])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.type == 2
                              ? _c("div", [_vm._v(_vm._s("数字魔盒"))])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "名称", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.productName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "type",
                      label: "兑换时间",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.createTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "block mt20",
                  staticStyle: { "text-align": "right" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.receiveData1.query.pageSize,
                      "current-page": _vm.receiveData1.query.pageNum,
                      total: _vm.receiveData1.total,
                    },
                    on: { "current-change": _vm.pageChangeNew1 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }