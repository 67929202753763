var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mealBox" },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formState,
            rules: _vm.rules,
            size: "small",
            inline: "",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "formFlex",
              attrs: { label: "选择客户：", prop: "memberId" },
            },
            [
              _c("el-autocomplete", {
                staticClass: "inline-input",
                attrs: {
                  "fetch-suggestions": _vm.querySearch,
                  placeholder: "请输入客户名称/手机号",
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.nameOrPhone,
                  callback: function ($$v) {
                    _vm.nameOrPhone = $$v
                  },
                  expression: "nameOrPhone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formFlex",
              attrs: { label: "产品类型：", prop: "associationId" },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "inputWidth mb-15 ",
                  attrs: { placeholder: "选择产品" },
                  model: {
                    value: _vm.formState.temporaryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "temporaryId", $$v)
                    },
                    expression: "formState.temporaryId",
                  },
                },
                _vm._l(_vm.productTypeList, function (type) {
                  return _c("el-option", {
                    key: type.productPackageId,
                    attrs: {
                      label: type.packageName,
                      value: type.productPackageId,
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "inputWidth",
                  attrs: { placeholder: "选择版本" },
                  model: {
                    value: _vm.formState.associationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "associationId", $$v)
                    },
                    expression: "formState.associationId",
                  },
                },
                _vm._l(_vm.versionList, function (vis) {
                  return _c("el-option", {
                    key: vis.productPackageId,
                    attrs: {
                      label: vis.versionName,
                      value: vis.productPackageId,
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "inputWidth",
                  attrs: { placeholder: "选择时长" },
                  model: {
                    value:
                      _vm.formState.expandInformation.productPackage.dateNumber,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formState.expandInformation.productPackage,
                        "dateNumber",
                        $$v
                      )
                    },
                    expression:
                      "formState.expandInformation.productPackage.dateNumber",
                  },
                },
                _vm._l(_vm.timeList, function (vis, index) {
                  return _c("el-option", {
                    key: vis.index,
                    attrs: {
                      label:
                        vis.number + (vis.dateType == "month" ? "个月" : "年"),
                      value: vis.number,
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "proPrice ml-10" }, [
                _vm._v(
                  "产品价格：" + _vm._s(_vm.productTypePrice.toFixed(2)) + "元"
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "proPrice ml-10" }, [
                _vm._v(
                  "系统价格：" + _vm._s(_vm.systemPrice.toFixed(2)) + "元"
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "formLine" }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "formFlex", attrs: { label: "选配功能：" } },
            [
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-plus", circle: "" },
                on: { click: _vm.addProduct },
              }),
              _vm._v(" "),
              _vm._l(
                _vm.formState.expandInformation.selectMatchProductData,
                function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "选择功能" },
                          model: {
                            value: item.productId,
                            callback: function ($$v) {
                              _vm.$set(item, "productId", $$v)
                            },
                            expression: "item.productId",
                          },
                        },
                        _vm._l(_vm.selectSaasProductsList, function (itm) {
                          return _c("el-option", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: itm.isShow,
                                expression: "itm.isShow",
                              },
                            ],
                            key: itm.productId,
                            attrs: {
                              label: itm.productName,
                              value: itm.productId,
                            },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-circle-close",
                        on: {
                          click: function ($event) {
                            return _vm.deleteProduct(item)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "proPrice" }, [
                        _vm._v(
                          "产品价格：" +
                            _vm._s(
                              item.price ? item.price.toFixed(2) : "0.00"
                            ) +
                            "元"
                        ),
                      ]),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "formLine" }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "formFlex", attrs: { label: "定制功能：" } },
            [
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-plus", circle: "" },
                on: { click: _vm.addFeature },
              }),
              _vm._v(" "),
              _vm._l(
                _vm.formState.expandInformation.customizationProductData,
                function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-top": "20px" } },
                    [
                      _c("el-input", {
                        staticClass: "textBox",
                        attrs: { placeholder: "请输入功能描述" },
                        model: {
                          value: item.description,
                          callback: function ($$v) {
                            _vm.$set(item, "description", $$v)
                          },
                          expression: "item.description",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "proPrice" },
                        [
                          _vm._v("服务价格："),
                          _c("el-input", {
                            staticClass: "inputMinWidth",
                            attrs: {
                              size: "small",
                              placeholder: "请输入金额",
                              oninput:
                                "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                            },
                            model: {
                              value: item.price,
                              callback: function ($$v) {
                                _vm.$set(item, "price", $$v)
                              },
                              expression: "item.price",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "formLine" }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "formFlex", attrs: { label: "优惠减免：" } },
            [
              _c("el-input", {
                staticClass: "textBox",
                attrs: { placeholder: "请输入优惠理由" },
                model: {
                  value: _vm.formState.expandInformation.discountPrice.remark,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formState.expandInformation.discountPrice,
                      "remark",
                      $$v
                    )
                  },
                  expression:
                    "formState.expandInformation.discountPrice.remark",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "proPrice" },
                [
                  _vm._v("减免金额："),
                  _c("el-input", {
                    staticClass: "inputMinWidth",
                    attrs: {
                      size: "small",
                      placeholder: "请输入金额",
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                    },
                    model: {
                      value:
                        _vm.formState.expandInformation.discountPrice.price,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formState.expandInformation.discountPrice,
                          "price",
                          $$v
                        )
                      },
                      expression:
                        "formState.expandInformation.discountPrice.price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "conTotle" }, [
                _vm._v(
                  "合同总金额：" + _vm._s(_vm.totalPrice.toFixed(2)) + "元"
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "formLine" }),
          _vm._v(" "),
          false
            ? _c(
                "el-form-item",
                {
                  staticClass: "formFlex",
                  attrs: { label: "技术服务费：", prop: "" },
                },
                [
                  _vm._v("起始时间：\n      "),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "value-format": "timestamp",
                      "editable:": false,
                      clearable: false,
                      placeholder: "选择日期",
                    },
                    model: {
                      value:
                        _vm.formState.expandInformation.serviceFee.startTime,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formState.expandInformation.serviceFee,
                          "startTime",
                          $$v
                        )
                      },
                      expression:
                        "formState.expandInformation.serviceFee.startTime",
                    },
                  }),
                  _vm._v(" "),
                  _vm._v("\n\n      服务时长："),
                  _c(
                    "el-input",
                    {
                      staticClass: "inputMinWidth",
                      attrs: {
                        size: "small",
                        oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      },
                      on: { input: _vm.changeInput },
                      model: {
                        value:
                          _vm.formState.expandInformation.serviceFee
                            .serviceTime,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formState.expandInformation.serviceFee,
                            "serviceTime",
                            $$v
                          )
                        },
                        expression:
                          "formState.expandInformation.serviceFee.serviceTime",
                      },
                    },
                    [
                      _c("template", { slot: "suffix" }, [
                        _c("span", { staticClass: "year" }, [_vm._v("年")]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "proPrice" },
                    [
                      _vm._v("服务价格："),
                      _c("el-input", {
                        staticClass: "inputMinWidth",
                        attrs: {
                          required: true,
                          size: "small",
                          placeholder: "请输入金额",
                          oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                        },
                        model: {
                          value:
                            _vm.formState.expandInformation.serviceFee.price,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formState.expandInformation.serviceFee,
                              "price",
                              $$v
                            )
                          },
                          expression:
                            "formState.expandInformation.serviceFee.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "conTotle" }, [
                    _vm._v("技术服务费：" + _vm._s(_vm.servePrice)),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          false ? _c("div", { staticClass: "formLine" }) : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("确认创建")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }