"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      roterPre: _settings.roterPre
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    console.log(this.venue_name);
  },
  methods: {
    changeTab: function changeTab(data) {
      if (data !== this.$route.path) {
        this.$router.push({
          path: data
        });
      }
    }
  }
};