var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "code-v" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "兑换对象：", prop: "resource" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.resource,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "resource", $$v)
                        },
                        expression: "ruleForm.resource",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "线上品牌商赞助" } }),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "线下场地免费" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "兑换次数：", prop: "count" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.count,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "count", $$v)
                      },
                      expression: "ruleForm.count",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }