import { render, staticRenderFns } from "./record.vue?vue&type=template&id=1502d376&scoped=true&lang=true"
import script from "./record.vue?vue&type=script&lang=js"
export * from "./record.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1502d376",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/java_admin_Rm9R/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1502d376')) {
      api.createRecord('1502d376', component.options)
    } else {
      api.reload('1502d376', component.options)
    }
    module.hot.accept("./record.vue?vue&type=template&id=1502d376&scoped=true&lang=true", function () {
      api.rerender('1502d376', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/writeOff/components/record.vue"
export default component.exports