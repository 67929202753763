var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container workDetails",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作品类型：", prop: "fileType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.fileTypeChange },
                      model: {
                        value: _vm.workDetail.fileType,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "fileType", $$v)
                        },
                        expression: "workDetail.fileType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("图片"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [_vm._v("3D")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("视频"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "4" } }, [
                        _vm._v("音频"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w120 fontW400 ml40 ft14",
                  staticStyle: { color: "#606266", display: "inline-block" },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v("作品封面：\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center mb20",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "image")
                        },
                      },
                    },
                    [
                      _vm.workDetail.image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2023131/image/1675135522101940138.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.workDetail.image
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: _vm.workDetail.image + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ft14" },
                    [
                      _vm._v("\n          列表页\n          "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "列表页的展示图",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.workDetail.fileType !== "2"
                ? _c(
                    "div",
                    {
                      staticClass: "item-align-center mb20 ml10",
                      staticStyle: {
                        display: "inline-block",
                        color: "#606266",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "infoImage")
                            },
                          },
                        },
                        [
                          _vm.workDetail.infoImage
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("infoImage")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2023131/image/1675135522101940138.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workDetail.infoImage
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.workDetail.infoImage + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://java-test-cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ft14" },
                        [
                          _vm._v("\n          详情页file\n          "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "详情页展示图，如不传则使用列表页图",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ft14 ml20",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _vm._v(
                    "\n        (图片格式支持：JPG、JPEG、PNG、GIF；文件大小≤15M；图片比例建议1：1)\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.workDetail.fileType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "作品文件", prop: "file" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.radioChange },
                          model: {
                            value: _vm.workDetail.fileRadio,
                            callback: function ($$v) {
                              _vm.$set(_vm.workDetail, "fileRadio", $$v)
                            },
                            expression: "workDetail.fileRadio",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("上传3d文件"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("上传3d文件夹"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.workDetail.fileRadio == 2
                        ? _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              { staticClass: "upload-btn" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "92px",
                                      height: "32px",
                                    },
                                    attrs: { type: "primary" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          "font-size": "12px",
                                          left: "-4px",
                                          top: "-6px",
                                        },
                                      },
                                      [_vm._v("选择文件夹")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      ref: "file",
                                      staticStyle: {
                                        opacity: "0",
                                        cursor: "pointer",
                                        position: "relative",
                                        top: "-12px",
                                        left: "-85px",
                                        width: "92px",
                                        height: "32px",
                                      },
                                      attrs: {
                                        id: "file1",
                                        type: "file",
                                        webkitdirectory: "",
                                        directory: "",
                                        multiple: "multiple",
                                      },
                                      on: { change: _vm.uploadAllImg },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.workDetail.file
                              ? _c(
                                  "span",
                                  { staticClass: "ml10 color-gray ft12" },
                                  [_vm._v("已上传")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.workDetail.file
                              ? _c(
                                  "span",
                                  { staticClass: "ml10 color-gray ft12" },
                                  [_vm._v("未上传")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "ml10 color-gray ft12" },
                              [_vm._v("(请上传包含 .gltf 文件的文件夹)")]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.workDetail.fileRadio == 1
                        ? _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              { staticClass: "upload-btn" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "92px",
                                      height: "32px",
                                    },
                                    attrs: { type: "primary" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          "font-size": "12px",
                                          left: "-4px",
                                          top: "-6px",
                                        },
                                      },
                                      [_vm._v("选择文件")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      ref: "file",
                                      staticStyle: {
                                        opacity: "0",
                                        cursor: "pointer",
                                        position: "relative",
                                        top: "-12px",
                                        left: "-85px",
                                        width: "92px",
                                        height: "32px",
                                      },
                                      attrs: {
                                        id: "file1",
                                        type: "file",
                                        multiple: "multiple",
                                      },
                                      on: { change: _vm.uploadAllImg },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.workDetail.file
                              ? _c(
                                  "span",
                                  { staticClass: "ml10 color-gray ft12" },
                                  [_vm._v("已上传")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.workDetail.file
                              ? _c(
                                  "span",
                                  { staticClass: "ml10 color-gray ft12" },
                                  [_vm._v("未上传")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "ml10 color-gray ft12" },
                              [_vm._v("(请上传包含 .glb 文件的文件)")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "作品文件：", prop: "file" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c(
                                "el-upload",
                                {
                                  ref: "uploadImg",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action: _vm.fileUrl,
                                    headers: _vm.myHeaders,
                                    "show-file-list": false,
                                    multiple: "",
                                    "http-request": _vm.uploadALiActionScanFile,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [_vm._v("上传预览版")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workDetail.scanFile &&
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("已上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.workDetail.scanFile &&
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("未上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              ref: "uploadImg",
                              staticClass: "upload-demo",
                              class:
                                _vm.workDetail.fileType != 1 &&
                                _vm.workDetail.fileType != 2
                                  ? "ml20"
                                  : null,
                              attrs: {
                                action: _vm.fileUrl,
                                headers: _vm.myHeaders,
                                "show-file-list": false,
                                multiple: "",
                                "http-request": _vm.uploadALiActionFile,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.workDetail.fileType == 1
                                        ? "上传源文件"
                                        : "上传完整版"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.workDetail.file
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("已上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.workDetail.file
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("未上传")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
              _vm._v(" "),
              _vm.workDetail.fileType != 2
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "showMetohd", label: "详情图片展示：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.workDetail.showMetohd,
                            callback: function ($$v) {
                              _vm.$set(_vm.workDetail, "showMetohd", $$v)
                            },
                            expression: "workDetail.showMetohd",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("动态展示"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("静态展示"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml40",
                          staticStyle: { color: "#606266" },
                        },
                        [_vm._v("(选择动态展示，详情页展示图片3D左右晃动)")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品名称：", prop: "storeName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入作品名称" },
                            model: {
                              value: _vm.workDetail.storeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "storeName", $$v)
                              },
                              expression: "workDetail.storeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属专辑：", prop: "seriesId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                remote: "",
                                clearable: "",
                                "reserve-keyword": "",
                                placeholder: "请输入选择所属专辑",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.workDetail.seriesId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "seriesId", $$v)
                                },
                                expression: "workDetail.seriesId",
                              },
                            },
                            _vm._l(_vm.albumList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属分类：", prop: "categoryId" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              clearable: "",
                              options: _vm.categoryList,
                              props: {
                                expandTrigger: "hover",
                                value: "id",
                                label: "label",
                              },
                            },
                            on: { change: _vm.changeCateId },
                            model: {
                              value: _vm.workDetail.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "categoryId", $$v)
                              },
                              expression: "workDetail.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品标签：", prop: "tagIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                multiple: "",
                                placeholder: "请选择所属标签",
                              },
                              model: {
                                value: _vm.workDetail.tagIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "tagIds", $$v)
                                },
                                expression: "workDetail.tagIds",
                              },
                            },
                            _vm._l(_vm.tagList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "转赠规则：",
                            prop: "transferRuleId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: { placeholder: "请选择转赠规则" },
                              model: {
                                value: _vm.workDetail.transferRuleId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "transferRuleId",
                                    $$v
                                  )
                                },
                                expression: "workDetail.transferRuleId",
                              },
                            },
                            _vm._l(_vm.transferList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品属性：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: { placeholder: "作品属性", clearable: "" },
                              model: {
                                value: _vm.workDetail.attr,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "attr", $$v)
                                },
                                expression: "workDetail.attr",
                              },
                            },
                            _vm._l(_vm.attrList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { value: item.id, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发行方：", prop: "issuerId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                placeholder: "选择发行方",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.workDetail.issuerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "issuerId", $$v)
                                },
                                expression: "workDetail.issuerId",
                              },
                            },
                            _vm._l(_vm.issuerArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "分账比例" },
                          nativeOn: {
                            keyup: function ($event) {
                              _vm.workDetail.issuerSharingRatio =
                                _vm.loadNumber(
                                  _vm.workDetail.issuerSharingRatio
                                )
                            },
                          },
                          model: {
                            value: _vm.workDetail.issuerSharingRatio,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.workDetail,
                                "issuerSharingRatio",
                                $$v
                              )
                            },
                            expression: "workDetail.issuerSharingRatio",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("%")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作者：", prop: "authorId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                placeholder: "选择作者",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.workDetail.authorId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "authorId", $$v)
                                },
                                expression: "workDetail.authorId",
                              },
                            },
                            _vm._l(_vm.authorArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "分账比例" },
                          nativeOn: {
                            keyup: function ($event) {
                              _vm.workDetail.authorSharingRatio =
                                _vm.loadNumber(
                                  _vm.workDetail.authorSharingRatio
                                )
                            },
                          },
                          model: {
                            value: _vm.workDetail.authorSharingRatio,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.workDetail,
                                "authorSharingRatio",
                                $$v
                              )
                            },
                            expression: "workDetail.authorSharingRatio",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("%")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上链选择：",
                            prop: "blockchainTypeId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: { placeholder: "请选择上链选择" },
                              model: {
                                value: _vm.workDetail.blockchainTypeId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "blockchainTypeId",
                                    $$v
                                  )
                                },
                                expression: "workDetail.blockchainTypeId",
                              },
                            },
                            _vm._l(_vm.blockchainArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "藏品简介：" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 4 },
                            model: {
                              value: _vm.workDetail.introduction,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "introduction", $$v)
                              },
                              expression: "workDetail.introduction",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "作品编号：", prop: "numberPrefix" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入作品编号" },
                            model: {
                              value: _vm.workDetail.numberPrefix,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "numberPrefix", $$v)
                              },
                              expression: "workDetail.numberPrefix",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发行总量：", prop: "stock" } },
                        [
                          _c(
                            "div",
                            { staticClass: "align-items-c flex" },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.isIssuance ? true : false,
                                  type: "number",
                                  placeholder: "请输入发行总量",
                                },
                                model: {
                                  value: _vm.workDetail.stock,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "stock",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "workDetail.stock",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "custom-item",
                          attrs: { label: "预留数量：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "custom-radio-group",
                              attrs: { disabled: _vm.workDetail.isShow },
                              model: {
                                value: _vm.workDetail.reservedType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "reservedType", $$v)
                                },
                                expression: "workDetail.reservedType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: 0 } },
                                [
                                  _vm._v("正常预留\n                "),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.workDetail.reservedType === 0,
                                        expression:
                                          "workDetail.reservedType === 0",
                                      },
                                    ],
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      disabled: _vm.workDetail.isShow,
                                      type: "number",
                                      placeholder:
                                        "请输入预留数量(数量不超过发行总量)",
                                    },
                                    model: {
                                      value: _vm.workDetail.reservedQuantity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "reservedQuantity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "workDetail.reservedQuantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                { attrs: { label: 1 } },
                                [
                                  _vm._v("指定编号\n                "),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.workDetail.reservedType === 1,
                                        expression:
                                          "workDetail.reservedType === 1",
                                      },
                                    ],
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      type: "textarea",
                                      disabled: _vm.workDetail.isShow,
                                      placeholder: "多个编号用逗号隔开",
                                      rows: 3,
                                    },
                                    model: {
                                      value: _vm.workDetail.reservedNumbers,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "reservedNumbers",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.reservedNumbers",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "限购数量：",
                            prop: "purchaseQuantity",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "每个用户的限购数量",
                            },
                            model: {
                              value: _vm.workDetail.purchaseQuantity,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workDetail,
                                  "purchaseQuantity",
                                  _vm._n($$v)
                                )
                              },
                              expression: "workDetail.purchaseQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单次限购：",
                            prop: "oneTimeBuyLimit",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "单个订单可购数量",
                            },
                            model: {
                              value: _vm.workDetail.oneTimeBuyLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workDetail,
                                  "oneTimeBuyLimit",
                                  _vm._n($$v)
                                )
                              },
                              expression: "workDetail.oneTimeBuyLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售价格：", prop: "price" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                disabled: _vm.workDetail.isShow,
                                placeholder: "请输入发售价格",
                                type: "number",
                                oninput:
                                  "if(value.length>6)value=value.slice(0,6)",
                              },
                              model: {
                                value: _vm.workDetail.price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "price", $$v)
                                },
                                expression: "workDetail.price",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠价格：", prop: "vipPrice" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              oninput:
                                "if(value.length>6)value=value.slice(0,6)",
                              placeholder: "输入价格",
                            },
                            model: {
                              value: _vm.workDetail.vipPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workDetail,
                                  "vipPrice",
                                  _vm._n($$v)
                                )
                              },
                              expression: "workDetail.vipPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售时间：", prop: "startTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: "请选择发售时间",
                              align: "right",
                              disabled: _vm.workDetail.isShow,
                            },
                            model: {
                              value: _vm.workDetail.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "startTime", $$v)
                              },
                              expression: "workDetail.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "购买限制：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function ($event) {
                                    _vm.workDetail.snapshotId = ""
                                  },
                                },
                                model: {
                                  value: _vm.workDetail.crowdRestrictionType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "crowdRestrictionType",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.crowdRestrictionType",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c" },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("不限制"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("新用户可购买"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("人群可购买"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mr20",
                                        staticStyle: { width: "250px" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "选择名单",
                                              clearable: "",
                                              disabled:
                                                _vm.workDetail
                                                  .crowdRestrictionType != 2,
                                            },
                                            model: {
                                              value: _vm.workDetail.snapshotId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.workDetail,
                                                  "snapshotId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workDetail.snapshotId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.nameListsList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  value: item.id,
                                                  label: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("自定义人群数量"),
                                    ]),
                                    _vm._v(" "),
                                    _vm.workDetail.crowdRestrictionType == 3 &&
                                    _vm.workDetail.crowdRestrictions &&
                                    _vm.workDetail.crowdRestrictions.length
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                  disabled:
                                                    _vm.workDetail
                                                      .crowdRestrictionType !=
                                                    3,
                                                },
                                                on: {
                                                  click: _vm.delUploadAction,
                                                },
                                              },
                                              [_vm._v("已导入 删除名单")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "el-upload",
                                              {
                                                ref: "uploadImg",
                                                staticClass: "upload-demo mr10",
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                                attrs: {
                                                  action: "#",
                                                  limit: 1,
                                                  "show-file-list": false,
                                                  "http-request": function (
                                                    file
                                                  ) {
                                                    return _vm.airdropExcels(
                                                      file
                                                    )
                                                  },
                                                  disabled:
                                                    _vm.workDetail
                                                      .crowdRestrictionType !=
                                                    3,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                      disabled:
                                                        _vm.workDetail
                                                          .crowdRestrictionType !=
                                                        3,
                                                    },
                                                  },
                                                  [_vm._v("导入名单")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                  disabled:
                                                    _vm.workDetail
                                                      .crowdRestrictionType !=
                                                    3,
                                                },
                                                on: { click: _vm.downLoad },
                                              },
                                              [_vm._v("下载导入模板")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分抵扣：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.workDetail.pointsDeductionType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "pointsDeductionType",
                                    $$v
                                  )
                                },
                                expression: "workDetail.pointsDeductionType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("抵扣"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("兑换"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "积分消耗：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("span", [_vm._v("积分类型：")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w200" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "选择积分类型" },
                                    model: {
                                      value: _vm.workDetail.scoreTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "scoreTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.scoreTypeId",
                                    },
                                  },
                                  _vm._l(_vm.typeList, function (m, i) {
                                    return _c("el-option", {
                                      key: "type" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("积分数量："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticClass: "w200",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.workDetail.pointsDeduction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "pointsDeduction",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.pointsDeduction",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("积分"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("动态积分数量"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "ml10" },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#ccc",
                                  },
                                  model: {
                                    value: _vm.workDetail.hasAutoScore,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "hasAutoScore",
                                        $$v
                                      )
                                    },
                                    expression: "workDetail.hasAutoScore",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w200 ml20" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: !_vm.workDetail.hasAutoScore,
                                    },
                                    model: {
                                      value: _vm.workDetail.autoScoreType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "autoScoreType",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.autoScoreType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        value: 1,
                                        label: "按寄售市场最低价",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        value: 2,
                                        label: "按寄售市场均价",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticClass: "w200 ml10",
                                attrs: {
                                  type: "number",
                                  disabled: !_vm.workDetail.hasAutoScore,
                                },
                                model: {
                                  value: _vm.workDetail.autoCoefficient,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "autoCoefficient",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.autoCoefficient",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("倍"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分使用人群：" } },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              { staticStyle: { width: "400px" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "选择名单",
                                      clearable: "",
                                      multiple: "",
                                    },
                                    model: {
                                      value: _vm.workDetail.airdropIds,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "airdropIds",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.airdropIds",
                                    },
                                  },
                                  _vm._l(_vm.nameListsList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        label: item.name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "ml10",
                                staticStyle: {
                                  color: "#606266",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("（不选为全部用户可用）")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "绑定实物" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { input: _vm.changeBandingNft },
                              model: {
                                value: _vm.workDetail.isBandingMaterial,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "isBandingMaterial",
                                    $$v
                                  )
                                },
                                expression: "workDetail.isBandingMaterial",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不绑定"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(
                                  "兑换实物  兑换过实物的藏品将不可再二级市场寄售"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.workDetail.isBandingMaterial == 1 &&
                          !_vm.selectCollection.length
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "el-icon-plus",
                                  staticStyle: { display: "block" },
                                  attrs: { type: "text" },
                                  on: { click: _vm.addCollection },
                                },
                                [
                                  _vm._v(
                                    "\n              选择实物商品\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectCollection.length
                            ? _c(
                                "el-table",
                                { attrs: { data: _vm.selectCollection } },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "图片" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("img", {
                                                staticClass: "w50 h50",
                                                attrs: {
                                                  src: scope.row
                                                    .bandProductImage,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1709232285
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "商品名称",
                                      prop: "bandProductName",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { label: "操作" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    underline: false,
                                                    type: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.selectCollection = []
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2686513759
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "赠送实物：",
                            prop: "giveProductPrompt",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ccc",
                                },
                                model: {
                                  value: _vm.workDetail.hasGiveProduct,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "hasGiveProduct",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.hasGiveProduct",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "w300" },
                                [
                                  _c("el-input", {
                                    staticClass: "ml10",
                                    attrs: {
                                      placeholder: "提示文案",
                                      disabled: !_vm.workDetail.hasGiveProduct,
                                    },
                                    model: {
                                      value: _vm.workDetail.giveProductPrompt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "giveProductPrompt",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "workDetail.giveProductPrompt",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml20" }, [
                                _vm._v("藏品操作："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled: !_vm.workDetail.hasGiveProduct,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.workDetail.hasRecover,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "hasRecover",
                                        $$v
                                      )
                                    },
                                    expression: "workDetail.hasRecover",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("保留"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("回收"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml20" }, [
                                _vm._v(
                                  "（打开后，订单支付后会提示用户输入收货地址，线下邮寄实物。）"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "赠送优惠券：",
                            prop: "giveProductPrompt",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ccc",
                                },
                                model: {
                                  value: _vm.workDetail.hasGiveCoupon,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "hasGiveCoupon",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.hasGiveCoupon",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticClass: "ml10",
                                  attrs: {
                                    disabled: !_vm.workDetail.hasGiveCoupon,
                                  },
                                  model: {
                                    value: _vm.workDetail.couponId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.workDetail, "couponId", $$v)
                                    },
                                    expression: "workDetail.couponId",
                                  },
                                },
                                _vm._l(_vm.couponList, function (m, i) {
                                  return _c("el-option", {
                                    key: "coupon" + i,
                                    attrs: { value: m.id, label: m.name },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "line-height": "36px",
                        color: "#606266",
                        "font-size": "14px",
                      },
                      attrs: { span: 16 },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "ml10",
                          attrs: { disabled: !_vm.workDetail.hasGiveCoupon },
                          model: {
                            value: _vm.workDetail.couponRule,
                            callback: function ($$v) {
                              _vm.$set(_vm.workDetail, "couponRule", $$v)
                            },
                            expression: "workDetail.couponRule",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("仅赠送一次"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("每次购买均赠送"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "赠送门票" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#ccc",
                              },
                              model: {
                                value: _vm.workDetail.hasGiveTicket,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "hasGiveTicket", $$v)
                                },
                                expression: "workDetail.hasGiveTicket",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ml10 flex align-items-c",
                                staticStyle: { width: "180px" },
                              },
                              [
                                _c("span", [_vm._v("门票名称：")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex-one" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        disabled: !_vm.workDetail.hasGiveTicket,
                                      },
                                      model: {
                                        value: _vm.workDetail.ticketName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workDetail,
                                            "ticketName",
                                            $$v
                                          )
                                        },
                                        expression: "workDetail.ticketName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v("赠送数量："),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "ml10 w100",
                              attrs: {
                                precision: 0,
                                controls: false,
                                placeholder: "输入数量",
                                disabled: !_vm.workDetail.hasGiveTicket,
                              },
                              model: {
                                value: _vm.workDetail.giveTicketNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "giveTicketNum",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "workDetail.giveTicketNum",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v("单张可核销次数："),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "ml10 w100",
                              attrs: {
                                controls: false,
                                precision: 0,
                                placeholder: "输入次数",
                                disabled: !_vm.workDetail.hasGiveTicket,
                              },
                              model: {
                                value: _vm.workDetail.ticketUseNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "ticketUseNum",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "workDetail.ticketUseNum",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ml10 flex align-items-c",
                                staticStyle: { width: "580px" },
                              },
                              [
                                _c("span", { staticClass: "ml10" }, [
                                  _vm._v("门票有效期："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex-one" },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "datetimerange",
                                        "range-separator": "至",
                                        "start-placeholder": "选择开始时间",
                                        "end-placeholder": "选择结束时间",
                                        "value-format": "timestamp",
                                        disabled: !_vm.workDetail.hasGiveTicket,
                                      },
                                      on: { change: _vm.onchangeTime },
                                      model: {
                                        value: _vm.workDetail.validityPeriod,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workDetail,
                                            "validityPeriod",
                                            $$v
                                          )
                                        },
                                        expression: "workDetail.validityPeriod",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "抽签购：",
                            prop: "giveProductPrompt",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ccc",
                                },
                                model: {
                                  value: _vm.workDetail.hasDrawLots,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workDetail, "hasDrawLots", $$v)
                                  },
                                  expression: "workDetail.hasDrawLots",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ml10 flex align-items-c",
                                  staticStyle: { width: "480px" },
                                },
                                [
                                  _c("span", [_vm._v("申购时间：")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex-one" },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "datetimerange",
                                          "range-separator": "至",
                                          "start-placeholder": "选择开始时间",
                                          "end-placeholder": "选择结束时间",
                                          "value-format": "timestamp",
                                          disabled: !_vm.workDetail.hasDrawLots,
                                        },
                                        on: { change: _vm.onchangeTime },
                                        model: {
                                          value: _vm.workDetail.drawLotsTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workDetail,
                                              "drawLotsTime",
                                              $$v
                                            )
                                          },
                                          expression: "workDetail.drawLotsTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("中签及付款时间："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "w200" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetime",
                                      placeholder: "选择时间",
                                      "value-format": "timestamp",
                                      disabled: !_vm.workDetail.hasDrawLots,
                                    },
                                    model: {
                                      value: _vm.workDetail.luckyNumberTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "luckyNumberTime",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.luckyNumberTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("中签数量："),
                              ]),
                              _vm._v(" "),
                              _c("el-input-number", {
                                staticClass: "ml10 w200",
                                attrs: {
                                  controls: false,
                                  placeholder: "输入数量",
                                  disabled: !_vm.workDetail.hasDrawLots,
                                },
                                model: {
                                  value: _vm.workDetail.luckyNumberCount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "luckyNumberCount",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "workDetail.luckyNumberCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.workDetail.hasDrawLotsAppoint,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "hasDrawLotsAppoint", $$v)
                      },
                      expression: "workDetail.hasDrawLotsAppoint",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("中签名单：")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        placeholder: "请选择快照名单",
                        clearable: "",
                        disabled: !_vm.workDetail.hasDrawLotsAppoint,
                      },
                      model: {
                        value: _vm.workDetail.drawLotsSnapshotId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "drawLotsSnapshotId", $$v)
                        },
                        expression: "workDetail.drawLotsSnapshotId",
                      },
                    },
                    _vm._l(_vm.nameListsList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情介绍：", prop: "description" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.workDetail.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "description", $$v)
                      },
                      expression: "workDetail.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: {
          "current-data": [],
          size: "1",
          showSelect: true,
          pointsDeductionType: "2",
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }