var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "ArtAI请求和消耗",
        visible: _vm.show,
        direction: _vm.direction,
        "append-to-body": "",
        size: "800px",
        "before-close": _vm.close,
        "custom-class": "drawer-custom",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "drawer-contain" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.tabs, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.label, name: item.name },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%", color: "#000" },
              attrs: { data: _vm.list, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "空间ID", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "modelName",
                  label: "模型名称",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "modelNumber",
                  label: "授权号",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "requestCount",
                  label: "请求次数",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.requestCountClick(
                                  row.id,
                                  row.modelName
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.requestCount))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "consumptionTraffic",
                  label: "消耗流量",
                  "min-width": "100",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.pages.pageSize,
                  "current-page": _vm.pages.pageNum,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.pages.total,
                },
                on: { "current-change": _vm.changePage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showDetails
        ? _c("statisticsVue", {
            attrs: {
              "app-id": _vm.appId,
              "space-id": _vm.spaceId,
              "model-name": _vm.modelName,
              drawer: _vm.showDetails,
            },
            on: { close: _vm.appdetailclose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }