"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _marketing = require("@/api/marketing");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _nftblind = _interopRequireDefault(require("@/components/customChildren/nftblind.vue"));
var _coupon = _interopRequireDefault(require("@/components/customChildren/coupon"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Article",
  components: {
    Nft: _nft.default,
    Nftblind: _nftblind.default,
    Coupon: _coupon.default
  },
  data: function data() {
    return {
      rule: {},
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: []
      },
      listLoading: true,
      changeIndex: 0,
      typeList: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _marketing.integralTypeListApi)().then(function (res) {
      _this.typeList = res.data;
    }).catch(function (res) {
      // this.$message.error(res.message)
    });
    this.getList();
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      if (!this.tableData.data.length) {
        this.$message.error("请先添加签到规则");
        return false;
      }
      if (this.rule.isOpen) {
        if (this.rule.adChannel !== 0 && this.rule.adChannel !== 1) {
          this.$message.error("请选择广告厂商");
          return false;
        }
        if (!this.rule.appId) {
          this.$message.error("请输入广告APPID");
          return false;
        }
        if (!this.rule.posId) {
          this.$message.error("请输入广告ID");
          return false;
        }
        if (!this.rule.number) {
          this.$message.error("请输入每日可观看次数");
          return false;
        }
        if (!this.rule.scoreTypeId) {
          this.$message.error("请选择获得的积分类型");
          return false;
        }
        if (!this.rule.scoreNum) {
          this.$message.error("请输入获得的积分数量");
          return false;
        }
      }
      var error = "";
      try {
        this.tableData.data.forEach(function (m, i) {
          if (i === 0) {
            m.dayTime = 1;
            m.prizeType = 3;
          }
          if (!m.dayTime) {
            error = "请输入连续签到天数";
          } else if (!m.prizeType) {
            error = "请选择奖品类型";
          } else if (!m.objectId) {
            switch (m.prizeType) {
              case 1:
                error = "请选择数字藏品";
                break;
              case 2:
                error = "请选择数字盲盒";
                break;
              case 3:
                error = "请选择积分类型";
                break;
              case 4:
                error = "请选择优惠券";
                break;
            }
          } else if (m.prizeType === 3 && !m.scoreNum) {
            error = "请输入积分数量";
          } else if (m.prizeType !== 3 && !m.prizeNum) {
            error = "请输入获得奖品数量";
          }
          if (error) {
            throw new Error(error);
          }
        });
      } catch (e) {
        this.$message.error(error);
        return false;
      }
      var data = JSON.parse(JSON.stringify(this.rule));
      data.rulesList = JSON.parse(JSON.stringify(this.tableData.data));
      if (this.lock) return false;
      this.lock = true;
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      (0, _marketing.sigEditApi)(data).then(function (res) {
        _this2.$message.success("操作成功");
        _this2.lock = false;
        loading.close();
      }).catch(function () {
        _this2.lock = false;
        loading.close();
      });
      console.log(1122);
    },
    addRuleAction: function addRuleAction() {
      this.tableData.data.push({
        dayTime: "",
        prizeType: 1,
        prizeNum: 1,
        objectId: "",
        productId: "",
        scoreNum: "",
        name: "",
        image: ""
      });
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _marketing.signListApi)(this.tableFrom).then(function (res) {
        if (res.data.rulesList && res.data.rulesList[0] && res.data.rulesList[0].prizeType !== 3) {
          res.data.rulesList[0].prizeType = 3;
        }
        _this3.tableData.data = res.data.rulesList;
        _this3.rule = {
          isOpen: res.data.isOpen || false,
          adChannel: res.data.adChannel || 0,
          posId: res.data.posId || "",
          appId: res.data.appId || "",
          number: res.data.number || "",
          scoreNum: res.data.scoreNum || "",
          scoreTypeId: res.data.scoreTypeId || ""
        };
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      this.tableData.data.splice(idx, 1);
    },
    changeProduct: function changeProduct(index) {
      // 开始选择
      this.changeIndex = index;
      var changeEL = "";
      switch (this.tableData.data[index].prizeType) {
        case 1:
          changeEL = "product_nft";
          break;
        case 2:
          changeEL = "product_nft_blind";
          break;
        case 4:
          changeEL = "coupon";
          break;
      }
      console.log(changeEL);
      this.$refs[changeEL].changeshow();
    },
    selectMessage: function selectMessage(data) {
      // 选完处理选中数据
      var d = data.data[0];
      var luckyData = this.tableData.data[this.changeIndex];
      luckyData.objectId = d.id;
      luckyData.productId = d.p_id || "";
      luckyData.name = d.name;
      luckyData.image = d.image || "";
    }
  }
};