var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchForm },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.searchForm.enterpriseType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "enterpriseType", $$v)
                        },
                        expression: "searchForm.enterpriseType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.enterpriseTypeList, function (item) {
                        return _c(
                          "el-radio-button",
                          { key: item.value, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.label) + "\n          ")]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { size: "small", placeholder: "应用名称" },
                          model: {
                            value: _vm.searchForm.appName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "appName", $$v)
                            },
                            expression: "searchForm.appName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            size: "small",
                            placeholder: "客户名称/手机号",
                          },
                          model: {
                            value: _vm.searchForm.nameOrPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "nameOrPhone", $$v)
                            },
                            expression: "searchForm.nameOrPhone",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "应用图标", width: "70", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: {
                            width: "36px",
                            height: "36px",
                            display: "block",
                            margin: "0 auto",
                          },
                          attrs: { src: scope.row.appIcon + "!120a" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "应用名称", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.goRouter("SdkAppList", {
                                  appName: row.appName,
                                })
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.appName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "空间数量",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.appIdClick(row.appId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.spaceCount) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "请求次数",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.appIdClick(row.appId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.requestCount) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "productVersion",
                  label: "平均停留",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.averageTime
                          ? _c("div", [_vm._v(_vm._s(row.averageTime) + "秒")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "消耗流量",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.appIdClick(row.appId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.consume))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "remain",
                  label: "剩余流量",
                  "min-width": "100",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "nickName", label: "用户", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.nickNameClick(row.memberId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.nickName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "类型", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.memberType))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orgName",
                  label: "发行机构",
                  "min-width": "100",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": _vm.pages.pageSize,
              "current-page": _vm.pages.pageNum,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pages.total,
            },
            on: { "current-change": _vm.changePage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? _c("userdetail", {
            attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
            on: { close: _vm.userdetailclose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAppdetail
        ? _c("requestCount", {
            attrs: { "app-id": _vm.appid, drawer: _vm.showAppdetail },
            on: { close: _vm.appdetailclose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }