var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "logistics acea-row row-top" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "logistics_cent" }, [
        _c("span", [
          _vm._v(
            "物流公司：" +
              _vm._s(
                _vm.logisticsName
                  ? _vm.orderDatalist.delivery_type
                  : _vm.orderDatalist.delivery_name
              )
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v("物流单号：" + _vm._s(_vm.orderDatalist.delivery_id)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "acea-row row-column-around trees-coadd" }, [
      _c(
        "div",
        { staticClass: "scollhide" },
        [
          _c(
            "el-timeline",
            _vm._l(_vm.result, function (item, i) {
              return _c("el-timeline-item", { key: i }, [
                _c("p", {
                  staticClass: "time",
                  domProps: { textContent: _vm._s(item.time) },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass: "content",
                  domProps: { textContent: _vm._s(item.status) },
                }),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logistics_img" }, [
      _c("img", { attrs: { src: require("@/assets/images/expressi.jpg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }