import { render, staticRenderFns } from "./produceUser.vue?vue&type=template&id=7844e204&lang=true"
import script from "./produceUser.vue?vue&type=script&lang=js"
export * from "./produceUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/java_admin_Rm9R/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7844e204')) {
      api.createRecord('7844e204', component.options)
    } else {
      api.reload('7844e204', component.options)
    }
    module.hot.accept("./produceUser.vue?vue&type=template&id=7844e204&lang=true", function () {
      api.rerender('7844e204', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/animals/components/produceUser.vue"
export default component.exports