var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout", staticStyle: { background: "#f7f7f7" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            {
              staticStyle: {
                position: "fixed",
                left: "0",
                top: "0",
                right: "0",
              },
              attrs: { height: "64px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-align-center",
                  staticStyle: {
                    width: "1345px",
                    margin: "0 auto",
                    height: "64px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "page-back item-align-center",
                      on: { click: _vm.backAction },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/b8b0d796b5afef0149e76265443f1cbd.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("返回")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-shu" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-name item-align-center" }, [
                    _c("div", { staticClass: "l" }, [_vm._v("自定义页面")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/6390304e322470c6b1a238158de1ccf4.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "r" }, [
                      _vm._v(_vm._s(_vm.pageInfo.name || "")),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "page-btn item-align-center" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "large" },
                          on: { click: _vm.handlePageSave },
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: { click: _vm.handleCancelAction },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-main",
            {
              staticStyle: {
                "padding-top": "64px",
                "box-sizing": "border-box",
                width: "1345px",
                margin: "0 auto",
              },
            },
            [
              _c(
                "div",
                { staticClass: "layout-tool" },
                [
                  _c(
                    "el-scrollbar",
                    { style: _vm.scrollHeight },
                    [
                      _c(
                        "el-collapse",
                        {
                          attrs: {
                            value: [
                              "basics",
                              "advanced",
                              "sales",
                              "other",
                              "marketing",
                            ],
                          },
                        },
                        _vm._l(_vm.panels, function (mounted, index) {
                          return _c(
                            "el-collapse-item",
                            { key: index, attrs: { name: index } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("i", {
                                  staticClass: "le-icon le-icon-arrow",
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(mounted.title))]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "fm-collapse-tool" },
                                [
                                  _vm._l(
                                    mounted.children,
                                    function (item, index) {
                                      return [
                                        item.name == "task" &&
                                        _vm.$manifest("task", "status")
                                          ? _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "widget",
                                                attrs: {
                                                  id:
                                                    item.title == "视频"
                                                      ? "guide001"
                                                      : "",
                                                  draggable: true,
                                                  "data-type": "module",
                                                },
                                                on: {
                                                  dragstart: function ($event) {
                                                    return _vm.dragStart(item)
                                                  },
                                                  dragend: _vm.dragEnd,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "widget-icon",
                                                  attrs: { src: item.icon },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ]
                                            )
                                          : _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "widget",
                                                attrs: {
                                                  id:
                                                    item.title == "视频"
                                                      ? "guide001"
                                                      : "",
                                                  draggable: true,
                                                  "data-type": "module",
                                                },
                                                on: {
                                                  dragstart: function ($event) {
                                                    return _vm.dragStart(item)
                                                  },
                                                  dragend: _vm.dragEnd,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "widget-icon",
                                                  attrs: { src: item.icon },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ]
                                            ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "clears" }),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            2
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-body" },
                [
                  _c(
                    "el-scrollbar",
                    {
                      ref: "scrollbar",
                      style: _vm.scrollHeight,
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handlePackage($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "layout-phone",
                          style: {
                            minHeight:
                              !_vm.pageInfo.open_header ||
                              _vm.pageInfo.headerSetting.header_air
                                ? "700px"
                                : "765px",
                            backgroundColor: _vm.pageInfo.background,
                            backgroundImage:
                              "url(" + _vm.pageInfo.bg_image + ")",
                            backgroundRepeat:
                              _vm.pageInfo.bg_image_mode === 3
                                ? "repeat-y"
                                : "no-repeat",
                            backgroundPosition:
                              _vm.pageInfo.bg_image_mode == 3 ||
                              _vm.pageInfo.bg_image_mode == 4
                                ? "center top"
                                : "center",
                            backgroundSize:
                              _vm.pageInfo.bg_image_mode == 2
                                ? "cover"
                                : "375px",
                          },
                        },
                        [
                          _vm.pageInfo.headerSetting
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "layout-phone-header pos-absolute",
                                  style: {
                                    backgroundColor:
                                      !_vm.pageInfo.hide_header &&
                                      !_vm.pageInfo.hide_header_view
                                        ? _vm.pageInfo.headerSetting
                                            .header_background
                                        : "",
                                    backgroundImage:
                                      "url(" +
                                      (_vm.pageInfo.headerSetting
                                        .header_color === "white"
                                        ? "https://saas.cdn.yunzongbu.cn/image/20211026/9823014575b33347581d939efbd91aee.png"
                                        : "https://saas.cdn.yunzongbu.cn/image/20211026/a3459168515274a30097453656ad97b1.png") +
                                      ")",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.mouseOver("headerHover")
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.mouseleave("headerHover")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.pageInfo.hide_header &&
                                            !_vm.pageInfo.hide_header_view,
                                          expression:
                                            "!pageInfo.hide_header && !pageInfo.hide_header_view",
                                        },
                                      ],
                                    },
                                    [
                                      _vm.headerHover
                                        ? _c("div", {
                                            staticClass: "common-hover",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.index == -2
                                        ? _c("div", {
                                            staticClass: "common-curr",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "layout-phone-title item-align-center",
                                          class: {
                                            "justify-left":
                                              _vm.pageInfo.headerSetting
                                                .logo_position === 1 &&
                                              _vm.pageInfo.headerSetting.logo,
                                            "justify-center":
                                              _vm.pageInfo.headerSetting
                                                .logo_position === 2 ||
                                              (!_vm.pageInfo.headerSetting
                                                .logo &&
                                                !_vm.pageInfo.headerSetting
                                                  .open_header_search),
                                          },
                                          style: {
                                            "z-index": 200,
                                            color:
                                              _vm.pageInfo.headerSetting
                                                .header_color,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handlePageName($event)
                                            },
                                          },
                                        },
                                        [
                                          !_vm.pageInfo.headerSetting
                                            .hide_back_btn
                                            ? _c(
                                                "div",
                                                { staticClass: "back" },
                                                [
                                                  _vm.pageInfo.headerSetting
                                                    .header_color === "white"
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: "https://saas.cdn.yunzongbu.cn/image/20211029/ccfd6697386671c2bd471fb840c7fa22.png",
                                                          alt: "",
                                                        },
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: "https://saas.cdn.yunzongbu.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png",
                                                          alt: "",
                                                        },
                                                      }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.pageInfo.headerSetting.logo
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "header-img",
                                                  style: {
                                                    "margin-left":
                                                      _vm.pageInfo.headerSetting
                                                        .logo_left_margin /
                                                        2 +
                                                      "px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm.pageInfo
                                                        .headerSetting.logo,
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.pageInfo.headerSetting
                                            .open_header_search
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "search-content item-align-center",
                                                  class: {
                                                    yuanxing:
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_type ===
                                                      1,
                                                  },
                                                  style: {
                                                    color:
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_text_color,
                                                    "background-color":
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_background_color,
                                                    "border-color":
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_border_color,
                                                    borderRadius:
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_border_radius /
                                                        2 +
                                                      "px",
                                                    fontSize:
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_text_size /
                                                        2 +
                                                      "px",
                                                    width:
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_width_style /
                                                        2 +
                                                      "px",
                                                    fontWeight:
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_text_style,
                                                    height: "32px",
                                                    marginLeft:
                                                      _vm.pageInfo.headerSetting
                                                        .header_search_margin_left /
                                                        2 +
                                                      "px",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-search",
                                                    style: {
                                                      color:
                                                        _vm.pageInfo
                                                          .headerSetting
                                                          .header_search_icon_color,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.pageInfo.headerSetting
                                                    .header_search_type === 2
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-one",
                                                          staticStyle: {
                                                            "text-align":
                                                              "left",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.pageInfo
                                                                .headerSetting
                                                                .header_search_text
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.pageInfo.headerSetting.logo &&
                                          !_vm.pageInfo.headerSetting
                                            .open_header_search
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "ml5",
                                                  style: {
                                                    fontSize:
                                                      _vm.pageInfo.headerSetting
                                                        .header_text_size /
                                                        2 +
                                                      "px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.pageInfo.name || ""
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !(
                            _vm.pageInfo.hide_header ||
                            _vm.pageInfo.hide_header_view ||
                            (_vm.pageInfo.headerSetting &&
                              _vm.pageInfo.headerSetting.header_air)
                          )
                            ? _c("div", {
                                staticClass: "zhanwei",
                                staticStyle: { height: "88px" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.pageInfo.hide_header &&
                          _vm.pageInfo.hide_header_view
                            ? _c("div", {
                                staticClass: "zhanwei",
                                staticStyle: { height: "50px" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "layout-phone-body",
                              attrs: { id: "guide002" },
                            },
                            [_c("router-view", { ref: "childView" })],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.is_totop
                    ? _c(
                        "div",
                        {
                          staticClass: "layout-totop",
                          on: { click: _vm.handleTotop },
                        },
                        [_c("i", { staticClass: "le-icon le-icon-placed-top" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pageInfo && _vm.pageInfo.footerSetting
                    ? _c(
                        "div",
                        {
                          staticClass: "footer",
                          style: {
                            background: _vm.footerBackground,
                            color: _vm.pageInfo.footerSetting.footer_text_color,
                            fontSize:
                              _vm.pageInfo.footerSetting.footer_text_size / 2 +
                              "px",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleFooterPage($event)
                            },
                            mouseover: function ($event) {
                              return _vm.mouseOver("footerHover")
                            },
                            mouseleave: function ($event) {
                              return _vm.mouseleave("footerHover")
                            },
                          },
                        },
                        [
                          !_vm.pageInfo.hide_footer
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "footer-view item-align-center justify-between",
                                  style: {
                                    padding:
                                      "0 " +
                                      _vm.pageInfo.footerSetting.footer_margin /
                                        2 +
                                      "px",
                                  },
                                },
                                [
                                  _vm.footerHover
                                    ? _c("div", { staticClass: "common-hover" })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.index == -3
                                    ? _c("div", { staticClass: "common-curr" })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.footerData, function (m, i) {
                                    return _c(
                                      "div",
                                      { key: i, staticClass: "item" },
                                      [
                                        _c("div", { staticClass: "img" }, [
                                          m.url
                                            ? _c("img", {
                                                class: {
                                                  tuchu:
                                                    _vm.pageInfo.footerSetting
                                                      .footer_type === 2 &&
                                                    parseInt(
                                                      _vm.footerData.length / 2,
                                                      10
                                                    ) === i,
                                                },
                                                attrs: {
                                                  src: m.url + "!120a",
                                                  alt: "",
                                                },
                                              })
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "no-img item-flex-center",
                                                  class: {
                                                    tuchu:
                                                      _vm.pageInfo.footerSetting
                                                        .footer_type === 2 &&
                                                      parseInt(
                                                        _vm.footerData.length /
                                                          2,
                                                        10
                                                      ) === i,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item-txt" }, [
                                          _vm._v(_vm._s(m.title)),
                                        ]),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "hengtiao" }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.is_lock
                ? _c(
                    "div",
                    {
                      staticClass: "layout-attribute display-flex",
                      attrs: { id: "guide004" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _vm.index >= 0
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _vm.modules[_vm.attribute]
                                    ? _c(_vm.modules[_vm.attribute].attribute, {
                                        tag: "component",
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.index == -1
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _c("pageAttribute", {
                                    model: {
                                      value: _vm.pageInfo,
                                      callback: function ($$v) {
                                        _vm.pageInfo = $$v
                                      },
                                      expression: "pageInfo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.index == -2
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _c("titleAttribute", {
                                    model: {
                                      value: _vm.pageInfo,
                                      callback: function ($$v) {
                                        _vm.pageInfo = $$v
                                      },
                                      expression: "pageInfo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.index == -3
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _c("footerAttribute", {
                                    model: {
                                      value: _vm.pageInfo,
                                      callback: function ($$v) {
                                        _vm.pageInfo = $$v
                                      },
                                      expression: "pageInfo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }