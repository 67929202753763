var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "审批状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("待盘点"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("已盘点"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [_vm._v("有差异")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "width100", attrs: { label: "货架：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.tableFrom.shelf,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "shelf", $$v)
                                },
                                expression: "tableFrom.shelf",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "客户名称：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.tableFrom.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "name", $$v)
                                },
                                expression: "tableFrom.name",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "width100", attrs: { label: "货品：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "货品名称/ID",
                                size: "small",
                              },
                              model: {
                                value: _vm.tableFrom.product_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "product_name", $$v)
                                },
                                expression: "tableFrom.product_name",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "width100", attrs: { label: "标签：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "标签", size: "small" },
                              model: {
                                value: _vm.tableFrom.label,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "label", $$v)
                                },
                                expression: "tableFrom.label",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "图片", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.product.image,
                                "preview-src-list": [scope.row.product.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "product.store_name",
                  label: "货品名称",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "品牌分类", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          scope.row.product.brand
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(scope.row.product.brand.brand_name)
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.product.storeCategory
                            ? _c("span", [
                                _vm._v(
                                  "/" +
                                    _vm._s(
                                      scope.row.product.storeCategory.cate_name
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "属性", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          _vm._l(scope.row.product.attrInfo, function (m, i) {
                            return _c("span", { key: "attr_" + i }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(m.attr_name) +
                                  ":" +
                                  _vm._s(m.attr_value_name) +
                                  "\n                "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标签", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.product.label,
                        function (item, index) {
                          return _c("span", [
                            _vm._v(
                              "\n            # " + _vm._s(item) + "\n          "
                            ),
                          ])
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货架", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                scope.row.status == 0 ? "待盘点" : "已盘点"
                              ) +
                              " "
                          ),
                          scope.row.abnormal_status == 1
                            ? _c("span", [_vm._v("-异常")])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘库照片", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.credentials
                          ? _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: scope.row.credentials,
                                    "preview-src-list": [scope.row.credentials],
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", [_vm._v("未上传")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }