"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sdk = require("@/api/sdk");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MonitoringList',
  data: function data() {
    return {
      list: [],
      listLoading: false
    };
  },
  created: function created() {
    this.getSdkConfigList();
  },
  methods: {
    // 获取监控列表
    getSdkConfigList: function getSdkConfigList() {
      var _this = this;
      this.listLoading = true;
      (0, _sdk.getSdkConfigApi)().then(function (res) {
        if (res.code === 200) {
          _this.list = res.data || [];
        } else {
          _this.$message.error(res.massage);
        }
        _this.listLoading = false;
      }).catch(function (err) {
        _this.$message.error(err.massage);
        _this.listLoading = false;
      });
    },
    handleEdit: function handleEdit(id) {
      this.$router.push({
        name: 'SdkProductInfo',
        params: {
          id: id
        }
      });
    }
  }
};