"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.autoMemberListApi = autoMemberListApi;
exports.autoMemberListApiV2 = autoMemberListApiV2;
exports.autoOrderListApi = autoOrderListApi;
exports.batchSubmitApi = batchSubmitApi;
exports.batchSubmitListApi = batchSubmitListApi;
exports.batchSubmitListV2Api = batchSubmitListV2Api;
exports.batchSubmitV2Api = batchSubmitV2Api;
exports.cancelApi = cancelApi;
exports.cancelOrderApi = cancelOrderApi;
exports.deleteNameListApi = deleteNameListApi;
exports.deleteUserApi = deleteUserApi;
exports.downloadTemplateApi = downloadTemplateApi;
exports.getPayApi = getPayApi;
exports.getPayByOrderApi = getPayByOrderApi;
exports.initiatePayApi = initiatePayApi;
exports.initiatePayV2Api = initiatePayV2Api;
exports.listByClearApi = listByClearApi;
exports.marketApi = marketApi;
exports.memberlistApi = memberlistApi;
exports.payOrderStatusApi = payOrderStatusApi;
exports.saveBatchApi = saveBatchApi;
exports.saveNameListApi = saveNameListApi;
exports.setPayAccountApi = setPayAccountApi;
exports.uploadTemplateApi = uploadTemplateApi;
var _request = _interopRequireDefault(require("./request"));
//显示自动下单用户列表
function autoOrderListApi(data) {
  return _request.default.get("member/sys/member/autoorder/list", data);
}

//获取用户列表
function listByClearApi(data) {
  return _request.default.get("member/sys/member/info/listByclear", data);
}
//设置是否为支付账户
function setPayAccountApi(data) {
  return _request.default.put("member/sys/member/autoorder/setPayAccount", data);
}
//添加自动下单用户
function saveBatchApi(data) {
  return _request.default.post("member/sys/member/autoorder/saveBatch", data);
}
//移除自动下单用户
function deleteUserApi(id) {
  return _request.default.delete("member/sys/member/autoorder/".concat(id));
}

//后台批量下订单
function batchSubmitApi(data) {
  return _request.default.post("/order/sys/order/batchSubmit", data);
}

//获取订单列表支付方式
function getPayByOrderApi(data) {
  return _request.default.get("order/sys/order/getPaytsByOrder", data);
}
//获取待支付订单列表
function batchSubmitListApi(data) {
  return _request.default.get("order/sys/order/batchSubmitList", data);
}

//支付
function initiatePayApi(data) {
  return _request.default.post("/pay/sys/initiatePay", data);
}

//查询支付状态
function payOrderStatusApi(id) {
  return _request.default.get("pay/sys/getPayRequestByPayOrderSn/".concat(id));
}
//获取订单列表

function autoMemberListApi(data) {
  return _request.default.get("product/sys/membernft/autoMemberList", data);
}
//批量取消寄售
function cancelApi(data) {
  return _request.default.post("market/sys/market-info/cancel", data);
}

//发起寄售
function marketApi(data) {
  return _request.default.post("market/sys/market-info/market", data);
}
//获取支付渠道

function getPayApi(data) {
  return _request.default.get("member/sys/member/autoorder/getDefaultPayInfos", data);
}

//批量取消订单
function cancelOrderApi(data) {
  return _request.default.post("order/sys/order/cancel", data);
}
//下载导入模板
function downloadTemplateApi(data) {
  return _request.default.get("member/sys/member/autoorder/downloadTemplate", data);
}
//导入模板
function uploadTemplateApi(data) {
  return _request.default.post("member/sys/member/autoorder/uploadTemplate", data);
}
//批量下单b用户名单
function memberlistApi(data) {
  return _request.default.get("member/sys/member/autoorder/memberlist", data);
}
//删除批量下单B用户

function deleteNameListApi(id) {
  return _request.default.delete("member/sys/member/autoorder/deleteNameList/".concat(id));
}
//保存批量下单B名单
function saveNameListApi(data) {
  return _request.default.post("member/sys/member/autoorder/saveNameList", data);
}

//立即执行B版本
function batchSubmitV2Api(data) {
  return _request.default.post("/order/sys/order/batchSubmitV2", data);
}
//去支付B版本
function initiatePayV2Api(data) {
  return _request.default.post("/pay/sys/initiatePayV2", data);
}

//获取待支付订单列表V2
function batchSubmitListV2Api(data) {
  return _request.default.get("order/sys/order/batchSubmitListV2", data);
}

//获取订单列表V2

function autoMemberListApiV2(data) {
  return _request.default.get("product/sys/membernft/autoMemberListV2", data);
}