"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UserOwned",
  data: function data() {
    return {
      name: "",
      list: {
        data: [],
        total: 0
      },
      listLoading: false,
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      }
    };
  },
  methods: {
    getList: function getList() {},
    handleSizeChange: function handleSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.getList();
    }
  }
};