"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var animalsRouter = {
  path: "".concat(_settings.roterPre, "/animals"),
  name: "animals",
  meta: {
    icon: "",
    title: "孵化游戏"
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: "manageMent",
    name: "AnimalsManageMent",
    meta: {
      title: "神兽管理"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/animals/index"));
      });
    }
  }, {
    path: "detail",
    name: "Detail",
    meta: {
      title: "创建神兽"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/animals/detail"));
      });
    }
  }, {
    path: "landDetail",
    name: "LandDetail",
    meta: {
      title: "创建土地"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/animals/components/manor/landDetail"));
      });
    }
  }, {
    path: "userOwned",
    name: "UserOwned",
    meta: {
      title: "用户持有"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/animals/components/userOwned"));
      });
    }
  }, {
    path: "produceUser",
    name: "ProduceUser",
    meta: {
      title: "用户持有"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/animals/components/produceUser"));
      });
    }
  }]
};
var _default = exports.default = animalsRouter;