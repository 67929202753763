"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._updateType = exports._updateScence = exports._updatePaper = exports._updateModel = exports._updateFrame = exports._getTypeList = exports._getScenceList = exports._getPaperList = exports._getModelList = exports._getFrameList = exports._deleteType = exports._deleteScence = exports._deletePaper = exports._deleteModel = exports._deleteFrame = exports._createType = exports._createScence = exports._createModel = exports._createFrame = void 0;
var _request = _interopRequireDefault(require("./request"));
/* ------------- 模版 --------------- */
// 获取模版列表
var _getModelList = exports._getModelList = function _getModelList(state) {
  return _request.default.get("/open3d/sys/art/frame/multi/template/getList", state);
};

// 获取模版详情
/* export const _getModelDetail = state => {
  return request.get(`frame/multiTemplate/detail/${state.template_id}`, state);
}; */

// 创建模版
var _createModel = exports._createModel = function _createModel(state) {
  return _request.default.post("/open3d/sys/art/frame/multi/template/add", state);
};

// 更新模版
var _updateModel = exports._updateModel = function _updateModel(state) {
  return _request.default.post("/open3d/sys/art/frame/multi/template/edit", state);
};

// 删除模版
var _deleteModel = exports._deleteModel = function _deleteModel(state) {
  return _request.default.delete("/open3d/sys/art/frame/multi/template/del/".concat(state.id), state);
};

/* ------------- 框类型 --------------- */
// 获取类型列表
var _getTypeList = exports._getTypeList = function _getTypeList(state) {
  return _request.default.get("/open3d/sys/art/frame/category/getList", state);
};

// 获取类型详情
/* export const _getTypeDetail = state => {
  return request.get(`frame/category/detail/${state.template_id}`, state);
}; */

// 创建类型
var _createType = exports._createType = function _createType(state) {
  return _request.default.post("/open3d/sys/art/frame/category/create", state);
};

// 更新类型
var _updateType = exports._updateType = function _updateType(state) {
  return _request.default.post("/open3d/sys/art/frame/category/update", state);
};

// 删除类型
var _deleteType = exports._deleteType = function _deleteType(state) {
  return _request.default.delete("/open3d/sys/art/frame/category/del/".concat(state.id), state);
};

// 修改类型排序
/* export const _ChangeTypeSort = state => {
  return request.post(`frame/category/switchSort/${state.id}`, state);
}; */

/* ------------- 框卡纸 --------------- */
// 获取框卡纸列表
var _getPaperList = exports._getPaperList = function _getPaperList(state) {
  return _request.default.get("/open3d/sys/art/frame/paper/getList", state);
};

// 获取框卡纸详情
/* export const _getPaperDetail = state => {
  return request.get(`frame/paper/detail/${state.template_id}`, state);
}; */

// 创建框卡纸
/* export const _createPaper = state => {
  return request.post(`frame/paper/create`, state);
}; */

// 更新框卡纸
var _updatePaper = exports._updatePaper = function _updatePaper(state) {
  return _request.default.post("/open3d/sys/art/frame/paper/edit", state);
};

// 删除框卡纸
var _deletePaper = exports._deletePaper = function _deletePaper(state) {
  return _request.default.delete("/open3d/sys/art/frame/paper/del/".concat(state.id), state);
};

// 修改框卡纸排序
/* export const _ChangePaperSort = state => {
  return request.post(`frame/paper/switchSort/${state.id}`, state);
}; */

/* ------------- 场景 --------------- */
// 获取场景列表
var _getScenceList = exports._getScenceList = function _getScenceList(state) {
  return _request.default.get("/open3d/sys/art/frame/scene/getList", state);
};

// 获取场景详情
/* export const _getScenceDetail = state => {
  return request.get(`frame/scene/detail/${state.template_id}`, state);
}; */

// 创建框场景
var _createScence = exports._createScence = function _createScence(state) {
  return _request.default.post("/open3d/sys/art/frame/scene/add", state);
};

// 更新场景
var _updateScence = exports._updateScence = function _updateScence(state) {
  return _request.default.post("/open3d/sys/art/frame/scene/edit", state);
};

// 删除场景
var _deleteScence = exports._deleteScence = function _deleteScence(state) {
  return _request.default.delete("/open3d/sys/art/frame/scene/del/".concat(state.id), state);
};

// 修改场景排序
/* export const _ChangeScenceSort = state => {
  return request.post(`frame/scene/switchSort/${state.id}`, state);
}; */

/* ---------- 画框 ------------------ */
// 获取画框列表
var _getFrameList = exports._getFrameList = function _getFrameList(state) {
  return _request.default.get("/open3d/sys/art/frame/template/getList", state);
};

// 获取画框详情
/* export const _getFrameDetail = state => {
  return request.get(`frame/template/detail`, state);
}; */

// 创建画框
var _createFrame = exports._createFrame = function _createFrame(state) {
  return _request.default.post("/open3d/sys/art/frame/template/add", state);
};

// 更新画框
var _updateFrame = exports._updateFrame = function _updateFrame(state) {
  return _request.default.post("/open3d/sys/art/frame/template/edit", state);
};

// 删除画框
var _deleteFrame = exports._deleteFrame = function _deleteFrame(state) {
  return _request.default.delete("/open3d/sys/art/frame/template/del/".concat(state.id), state);
};