"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._createSaaSProduct = _createSaaSProduct;
exports._createSaasOrder = _createSaasOrder;
exports._editSaaSproduct = _editSaaSproduct;
exports._getPackageNames = _getPackageNames;
exports.loginSaasApi = loginSaasApi;
exports.merchantUpdate = merchantUpdate;
exports.productPackage = productPackage;
exports.saasCloseOrder = saasCloseOrder;
exports.saasGetProductType = saasGetProductType;
exports.saasMemberList = saasMemberList;
exports.saasOrderList = saasOrderList;
exports.saasOrderSubmit = saasOrderSubmit;
exports.saasProductCreate = saasProductCreate;
exports.saasProductDetail = saasProductDetail;
exports.saasProductList = saasProductList;
exports.saasProductUpdate = saasProductUpdate;
var _request = _interopRequireDefault(require("./request"));
//获取saas产品列表
function saasProductList(data) {
  return _request.default.get("/open3d/system/sys/product/list", data);
}
//获取saas客户列表
function saasMemberList(data) {
  return _request.default.get("/open3d/product/sys/saas/member/list", data);
}
//获取saas产品类型
function saasGetProductType() {
  return _request.default.get("/open3d/system/sys/product/getProductType");
}
//saas套餐列表
function productPackage() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _request.default.get("/open3d/system/sys/productPackage/list", data);
}
//saas套餐详情
function saasProductDetail(productPackageId) {
  return _request.default.get("/open3d/system/sys/productPackage/detail/".concat(productPackageId));
}
// 获取套餐名称/open3d/system/sys/productPackage/getNames
function _getPackageNames(systemType) {
  return _request.default.get("/open3d/system/sys/productPackage/getNames?systemType=".concat(systemType));
}
// 修改商户信息
function merchantUpdate(data) {
  return _request.default.post("/open3d/product/sys/saas/member/merchantUpdate", data);
}
//saas套餐新增
function saasProductCreate(data) {
  return _request.default.post("/open3d/system/sys/productPackage/create", data);
}
//saas套餐编辑
function saasProductUpdate(data) {
  return _request.default.post("/open3d/system/sys/productPackage/update/".concat(data.productPackageId), data);
}
//saas获取商户订单列表
function saasOrderList(data) {
  return _request.default.get("/open3d/order/sys/merchant/order/list", data);
}
//saas下商户订单
function saasOrderSubmit(data) {
  return _request.default.post("/open3d/order/sys/merchant/order/submit", data);
}
//saas关闭商户订单
function saasCloseOrder(id) {
  return _request.default.post("/open3d/order/sys/merchant/order/closeOrder/".concat(id));
}

// 新增产品
function _createSaaSProduct(data) {
  return _request.default.post("/open3d/system/sys/product/create", data);
}

// 修改产品信息
function _editSaaSproduct(data) {
  return _request.default.post("/open3d/system/sys/product/update/".concat(data.productId), data);
}

// 创建订单
function _createSaasOrder(data) {
  return _request.default.post("/open3d/order/sys/merchant/order/submit", data);
}

// 
function loginSaasApi(merchantid) {
  return _request.default.post("/open3d/product/sys/saas/member/loginSaas?merchantid=".concat(merchantid), {});
}