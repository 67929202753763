"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _nft = require("@/api/nft");
var _user = require("@/api/user");
var _index = require("@/utils/index");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      tableFrom: {
        type: 1,
        id: "",
        pageNum: 1,
        pageSize: 10,
        productName: "",
        seriesId: ""
      },
      total: 0,
      list: [],
      showNftSale: false,
      albumList: [],
      totalprice: 0
    };
  },
  methods: {
    loadInit: function loadInit(value, t) {
      var _this = this;
      (0, _nft.nftCollectionAlbumListApi)({
        pageSize: 100,
        pageNum: 1
      }).then(function (res) {
        _this.albumList = res.rows;
      }).catch(function () {});
      this.list = [];
      this.total = 0;
      this.tableFrom = {
        type: t,
        authorId: value,
        pageNum: 1,
        pageSize: 10,
        productName: "",
        seriesId: ""
      };
      this.showNftSale = true;
      this.load();
    },
    load: function load() {
      var _this2 = this;
      var data = JSON.parse(JSON.stringify(this.tableFrom));
      (0, _user.authorCommisionList)(data).then(function (res) {
        _this2.totalprice = res.statistical.totalPrice;
        _this2.list = res.rows;
        _this2.total = res.total;
      }).catch(function () {});
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.load("");
    },
    changeData: function changeData() {
      this.tableFrom.pageNum = 1;
      this.load("");
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this3 = this;
      (0, _user.exportAuthorCommision)({
        authorId: this.tableFrom.authorId,
        productName: this.tableFrom.productName,
        seriesId: this.tableFrom.seriesId
      }).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this3.$createElement;
        _this3.$msgbox({
          title: "提示",
          message: h("p", null, [h("span", null, '文件正在生成中，请稍后点击"'), h("span", {
            style: "color: teal"
          }, "导出已生成列表"), h("span", null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(38);
    }
  }
};