"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {};
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    // console.log(this.venue_name);
  },
  methods: {
    changeTab: function changeTab(data) {
      if (data && data !== this.$route.path) {
        this.$router.push({
          path: data
        });
      }
    }
  }
};