var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "80px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "时间选择：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange(_vm.queryParams.timeType)
                            },
                          },
                          model: {
                            value: _vm.queryParams.timeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "timeType", $$v)
                            },
                            expression: "queryParams.timeType",
                          },
                        },
                        _vm._l(_vm.timeList, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text) + "\n            ")]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "value-format": "yyyy/MM/dd HH:mm:ss",
                          format: "yyyy/MM/dd HH:mm:ss",
                          size: "small",
                          type: "datetimerange",
                          placement: "bottom-end",
                          "start-placeholder": "选择开始时间",
                          "end-placeholder": "选择结束时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeType,
                          callback: function ($$v) {
                            _vm.timeType = $$v
                          },
                          expression: "timeType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "用户名称/手机号", size: "small" },
                      model: {
                        value: _vm.queryParams.memberNameOrPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "memberNameOrPhone", $$v)
                        },
                        expression: "queryParams.memberNameOrPhone",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入订单编号", size: "small" },
                      model: {
                        value: _vm.queryParams.orderSn,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "orderSn", $$v)
                        },
                        expression: "queryParams.orderSn",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入支付订单号", size: "small" },
                      model: {
                        value: _vm.queryParams.payOrderSn,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "payOrderSn", $$v)
                        },
                        expression: "queryParams.payOrderSn",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item selWidth",
                      attrs: {
                        placeholder: "请选择支付渠道",
                        size: "small",
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "payType", $$v)
                        },
                        expression: "queryParams.payType",
                      },
                    },
                    _vm._l(_vm.payList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt10 mb10" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("查看导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(
                                      _vm.queryParams.pageNum
                                    ) > -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.orderNo) > -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.orderNo) ===
                                  -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单时间/编号", "min-width": "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [_vm._v(_vm._s(scope.row.createTime))]),
                        _vm._v(" "),
                        _c("span", {
                          staticStyle: { display: "block" },
                          domProps: { textContent: _vm._s(scope.row.id) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0" },
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.showDetail(scope.row.uid)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.realName))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0" },
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.showDetail(scope.row.uid)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.userPhone))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.totalPrice))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "积分抵扣" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.deductionPrice))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "优惠券抵扣" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.couponPrice))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatStatus")(scope.row.status))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.paid ? "已支付" : "未支付")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付方式", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.payType))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付订单号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.payOrderSn))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付时间", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.payTime || "-"))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "180",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelOrderAction(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("\n            取消订单")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 4 &&
                        scope.row.refundStatus === 0 &&
                        Number(scope.row.totalPrice) !== 0 &&
                        Number(scope.row.payPrice) != 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.refundAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("退款")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.queryParams.pageSize,
                  "current-page": _vm.queryParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleRefund
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleRefund,
                "close-on-click-modal": false,
                title: "退款",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleRefund = $event
                },
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("支付方式：" + _vm._s(_vm.refundRow.payType || "-")),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("支付订单号：" + _vm._s(_vm.refundRow.payOrderSn)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "支付金额：" + _vm._s(_vm.refundRow.payPrice || "-")
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("支付时间：" + _vm._s(_vm.refundRow.payTime || "-")),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "refundForm",
                  staticClass: "mt20",
                  attrs: {
                    size: "small",
                    model: _vm.refundForm,
                    rules: _vm.refundRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款金额", prop: "refundPrice" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.refundForm.refundPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundForm, "refundPrice", $$v)
                          },
                          expression: "refundForm.refundPrice",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          "(最多可退" + _vm._s(_vm.refundRow.payPrice) + "元)"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款原因" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.refundForm.refundReasonWapExplain,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.refundForm,
                              "refundReasonWapExplain",
                              $$v
                            )
                          },
                          expression: "refundForm.refundReasonWapExplain",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmRefundAction("refundForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleShip
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleShip,
                "close-on-click-modal": false,
                title: _vm.shipTitle,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleShip = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "shipForm",
                  staticClass: "mt20",
                  attrs: {
                    size: "small",
                    model: _vm.shipForm,
                    rules: _vm.shipRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递公司", prop: "deliveryCompanyId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择快递公司",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.shipForm.deliveryCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.shipForm, "deliveryCompanyId", $$v)
                            },
                            expression: "shipForm.deliveryCompanyId",
                          },
                        },
                        _vm._l(_vm.dcList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号", prop: "deliveryId" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.shipForm.deliveryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipForm, "deliveryId", $$v)
                          },
                          expression: "shipForm.deliveryId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmShipAction("shipForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }