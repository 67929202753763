var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild
                  ? _c(
                      "app-link",
                      {
                        attrs: { to: _vm.resolvePath(_vm.onlyOneChild.route) },
                      },
                      [
                        _c(
                          "el-menu-item",
                          {
                            class: { "submenu-title-noDropdown": !_vm.isNest },
                            attrs: {
                              index: _vm.resolvePath(_vm.onlyOneChild.route),
                            },
                          },
                          [
                            [
                              _c("item", {
                                attrs: {
                                  icon:
                                    _vm.onlyOneChild.icon ||
                                    (_vm.item && _vm.item.icon),
                                  title: _vm.onlyOneChild.label,
                                },
                              }),
                            ],
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _c(
                "el-submenu",
                {
                  ref: "subMenu",
                  attrs: {
                    index: _vm.resolvePath(_vm.item.route),
                    "popper-append-to-body": "",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _vm.item
                        ? _c("item", {
                            attrs: {
                              icon: _vm.item && _vm.item.icon,
                              title: _vm.item.label,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex align-items-c flex-wrap",
                      staticStyle: { "padding-left": "40px" },
                    },
                    _vm._l(_vm.item.children, function (child) {
                      return _c("sidebar-item", {
                        key: child.id,
                        staticClass: "nest-menu",
                        attrs: {
                          "is-nest": true,
                          item: child,
                          "base-path": _vm.resolvePath(child.route),
                        },
                      })
                    }),
                    1
                  ),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }