var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { staticClass: "mb-20", attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { label: "时间/ID", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "demand-time" }, [
                      _vm._v(_vm._s(row.createTime)),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(row.id))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "需求标题", prop: "title", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "雇主", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("span", { staticClass: "blue-text" }, [
                      _vm._v(_vm._s(row.employerUser)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "模型类型", width: "100", prop: "modelTypeName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "尺寸要求", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s(row.size))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "工期要求", width: "100", prop: "workDay" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "费用预算", prop: "budget", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "补充材料", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "flex-b flex-c" }, [
                      _c("span", [
                        _vm._v(_vm._s(row.fileCount || 0) + " 个附件"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(row.imageCount || 0) + " 张图片"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(row.videoCoutnt || 0) + " 个视频"),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "派单/投标", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "flex-b flex-c" }, [
                      _c("span", [
                        _vm._v(
                          "派单：" + _vm._s(row.dispatchCount || 0) + " 人"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("投标：" + _vm._s(row.count || 0) + " 人"),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              fixed: "right",
              align: "center",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "div",
                      { staticClass: "flex-b" },
                      [
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.hasShow == 0,
                                expression: "row.hasShow == 0",
                              },
                            ],
                            attrs: { title: "确定公开[" + row.title + "]吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.onShowDemand(row, 1)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "blue-text",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v("公开招标")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.hasShow == 1,
                                expression: "row.hasShow == 1",
                              },
                            ],
                            attrs: { title: "确定隐藏[" + row.title + "]吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.onShowDemand(row, 0)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "blue-text",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v("隐藏招标")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            staticClass: "blue-text",
                          },
                          [_vm._v("匹配派单")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "blue-text" }, [
                          _vm._v("联系雇主"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "blue-text" }, [
                          _vm._v("取消招标"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }