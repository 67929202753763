var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          [
            _c(
              "div",
              _vm._l(_vm.viewData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "one-list",
                    class: { mt0: index == 0 },
                    style:
                      "width: 100%;margin-top:" +
                      _vm.facade.item_margin / 2 +
                      "px;border-top-right-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-top-left-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-bottom-left-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;border-bottom-right-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;background: " +
                      _vm.facade.status_background +
                      " url(" +
                      _vm.facade.status_bg_image +
                      ") repeat left center;",
                  },
                  [
                    _vm.content.style !== 4 && !_vm.point
                      ? _c(
                          "div",
                          {
                            staticClass: "time flex align-items-c",
                            style:
                              "color: " +
                              _vm.facade.status_color +
                              ";font-size: " +
                              _vm.facade.status_fontSize / 2 +
                              "px;" +
                              (_vm.facade.status_bg_color &&
                              _vm.facade.status_bg_color_gradient
                                ? "background: linear-gradient(90deg, " +
                                  _vm.facade.status_bg_color +
                                  " 0%, " +
                                  _vm.facade.status_bg_color_gradient +
                                  " 100%)"
                                : "background: " + _vm.facade.status_bg_color) +
                              ";",
                          },
                          [
                            _vm.facade.status_not_started
                              ? _c("img", {
                                  attrs: { src: _vm.facade.status_not_started },
                                })
                              : _vm._e(),
                            _c("span", [_vm._v("未开始")]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style:
                          (_vm.content.style == 5
                            ? "width: " +
                              (375 - _vm.facade.page_margin - 70) +
                              "px;height: " +
                              (375 - _vm.facade.page_margin - 70) +
                              "px;"
                            : "") +
                          "border-bottom-left-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;border-bottom-right-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;overflow: hidden;",
                      },
                      [
                        _vm.content.style == 1
                          ? _c("img", {
                              staticStyle: { display: "block" },
                              attrs: { src: item.image },
                            })
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: item.image, fit: "cover" },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            staticStyle: { overflow: "hidden" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text color-black line1",
                                style:
                                  "color: " +
                                  _vm.facade.title_color +
                                  ";font-size: " +
                                  _vm.facade.title_font_size / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.title_font_weight +
                                  ";",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "surplus" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "h",
                                  style:
                                    "background: " +
                                    _vm.facade.surplus_desc_color +
                                    ";",
                                },
                                [
                                  _c("div", {
                                    staticClass: "x",
                                    style:
                                      "background: " +
                                      _vm.facade.surplus_color +
                                      ";width: 20%;",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "txt",
                                  style:
                                    "color: " +
                                    _vm.facade.surplus_text_color +
                                    ";",
                                },
                                [
                                  _vm._v(
                                    "\n                剩余220\n              "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bottom bottom1 flex align-items-c",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "author flex-one flex align-items-c",
                                    style:
                                      "color: " +
                                      _vm.facade.sub_color +
                                      ";font-size: " +
                                      _vm.facade.sub_font_size / 2 +
                                      "px;line-height: 20px;",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-one line1" },
                                      [_vm._v(_vm._s(new Date()))]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(_vm.viewData, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "list-horizontal",
                class: { mt0: index < 1 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "list-h-left",
                    style: {
                      width: _vm.facade.img_width / 2 + "px",
                      height: _vm.facade.img_height / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "list-h-right",
                    style: _vm.facade.no_show_img
                      ? "padding-bottom: 30px;"
                      : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        style:
                          "color: " +
                          _vm.facade.title_color +
                          ";font-size: " +
                          _vm.facade.title_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.title_font_weight +
                          ";",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "center-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sign",
                          style:
                            "color: " +
                            _vm.facade.time_color +
                            ";font-size: " +
                            _vm.facade.time_text_size / 2 +
                            "px;font-weight: " +
                            _vm.facade.time_text_style +
                            ";",
                        },
                        [_vm._v("\n            2022-06-14 11:47\n          ")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sign time flex align-items-c",
                          style:
                            "color: " +
                            _vm.facade.status_color +
                            ";font-size: " +
                            _vm.facade.status_fontSize / 2 +
                            "px;" +
                            (_vm.facade.status_bg_color &&
                            _vm.facade.status_bg_color_gradient
                              ? "background: linear-gradient(90deg, " +
                                _vm.facade.status_bg_color +
                                " 0%, " +
                                _vm.facade.status_bg_color_gradient +
                                " 100%)"
                              : "background: " + _vm.facade.status_bg_color) +
                            ";",
                        },
                        [
                          _vm.facade.status_not_started
                            ? _c("img", {
                                attrs: { src: _vm.facade.status_not_started },
                              })
                            : _vm._e(),
                          _c("span", [_vm._v("未开始")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "surplus" }, [
                        _c(
                          "div",
                          {
                            staticClass: "h",
                            style:
                              "background: " +
                              _vm.facade.surplus_desc_color +
                              ";",
                          },
                          [
                            _c("div", {
                              staticClass: "x",
                              style:
                                "background: " +
                                _vm.facade.surplus_color +
                                ";width: 20%;",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "txt",
                            style:
                              "color: " + _vm.facade.surplus_text_color + ";",
                          },
                          [_vm._v("\n              剩余220\n            ")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }