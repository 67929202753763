var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-form",
                { attrs: { inline: "" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "用户名/手机号/真实姓名" },
                          model: {
                            value: _vm.tableFrom.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keyword", $$v)
                            },
                            expression: "tableFrom.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item"),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "", "min-width": "65" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row.uid },
                            nativeOn: {
                              change: function ($event) {
                                return _vm.getTemplateRow(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: _vm.templateRadio,
                              callback: function ($$v) {
                                _vm.templateRadio = $$v
                              },
                              expression: "templateRadio",
                            },
                          },
                          [_vm._v(" ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "uid", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nickname",
                  label: "微信用户名称",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "客服头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticClass: "tabImage",
                              attrs: {
                                src: scope.row.avatar
                                  ? scope.row.avatar
                                  : _vm.moren,
                                "preview-src-list": [
                                  scope.row.avatar || _vm.moren,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户类型", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("statusFilter")(scope.row.user_type))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }