"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      companyList: [],
      timeType: [],
      queryParams: {
        companyId: '',
        invoiceType: '2',
        status: '0',
        timeType: '',
        pageSize: 10,
        pageNum: 1
      },
      //表格 分页
      statistical: {},
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      visible: false,
      form: {},
      dialogVisible: false,
      formState: {}
    };
  },
  created: function created() {
    var _this = this;
    (0, _finance.getComponseListApi)().then(function (res) {
      return _this.companyList = res.data;
    });
    this.getTableList();
  },
  methods: {
    onDownload: function onDownload(row) {
      if (!row.url) return;
      var parts = row.url.split('.');
      var suffix = parts[parts.length - 1];
      var imgUrl = row.url;
      // 时间戳文件名
      var imgName = "".concat(new Date().getTime(), ".").concat(suffix);
      fetch(imgUrl).then(function (res) {
        return res.blob().then(function (blob) {
          var a = document.createElement('a');
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = imgName;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      });
    },
    getTableList: function getTableList(num) {
      var _this2 = this;
      if (num) this.queryParams.pageNum = num;
      (0, _finance.getInvoiceListApi)((0, _objectSpread2.default)({}, this.queryParams)).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.statistical = res.statistical;
      });
    },
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.timeType = e ? this.timeType.join("-") : "";
      this.getTableList(1);
    },
    onDetails: function onDetails(row) {
      console.log(row);
      this.form = row;
      this.visible = true;
    },
    modalPicTap: function modalPicTap(file) {
      var _this3 = this;
      var fm = new FormData();
      fm.append('file', file.file);
      (0, _finance.resourceUpdApi)(fm).then(function (res) {
        // console.log(res);
        _this3.formState.url = res.data.url;
        _this3.$message.success('上传成功');
      });
      // const _this = this;
      // this.$modalUpload(function(img) {
      //   _this.formState.url = img[0];
      // }, 1);
    },
    onSubmit: function onSubmit() {
      var _this4 = this;
      var model = this.formState.isManual == 1 ? {
        url: this.formState.url
      } : {};
      (0, _finance.invoiceAccept)(this.formState.id, model).then(function (res) {
        // console.log(res);
        if (res.code == 200) {
          _this4.$message.success('操作成功');
          _this4.getTableList(1);
          _this4.dialogVisible = false;
        }
      });
    },
    onVerify: function onVerify(row) {
      this.formState = row;
      this.dialogVisible = true;
    },
    pageChange: function pageChange(val) {
      this.getTableList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getTableList(1);
    }
  }
};