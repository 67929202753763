var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "code-v" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称：", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "名称" },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生成方式：", prop: "createMethod" } },
                    [
                      _c(
                        "div",
                        { staticClass: "align-items-c" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.disabledFile },
                              on: { input: _vm.changCreateMethod },
                              model: {
                                value: _vm.ruleForm.createMethod,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "createMethod", $$v)
                                },
                                expression: "ruleForm.createMethod",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("自动生成"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                { attrs: { label: 2 } },
                                [
                                  _vm._v("导入兑换码"),
                                  _c(
                                    "el-link",
                                    {
                                      staticClass: "ver-b",
                                      attrs: {
                                        type: "primary",
                                        href: "http://www.all-tool.cn/Tools/rand_key",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("(生成兑换码小工具)")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.createMethod == 1
                ? _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "兑换码数量：", prop: "count" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                attrs: { type: "number", placeholder: "数量" },
                                model: {
                                  value: _vm.ruleForm.count,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "count", $$v)
                                  },
                                  expression: "ruleForm.count",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.createMethod == 2
                ? _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "count" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "uploadImg",
                                  staticClass: "upload-demo mr10 mb15",
                                  staticStyle: { display: "inline-block" },
                                  attrs: {
                                    action: "#",
                                    limit: 1,
                                    "on-success": _vm.handleSuccess,
                                    "show-file-list": true,
                                    disabled: _vm.disabledFile,
                                    "http-request": _vm.infoImport,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.distinctList.length
                                            ? "已导入"
                                            : "导入"
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [_vm._v(_vm._s(_vm.uploadMsg))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "text" },
                                  on: { click: _vm.downLoad },
                                },
                                [_vm._v("下载导入模版")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期：", prop: "date" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "range-separator": "至",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.ruleForm.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "date", $$v)
                              },
                              expression: "ruleForm.date",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.createMethod == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "匹配生成" } },
                    [
                      _c("el-switch", {
                        on: { change: _vm.changeAutoPaddingNumber },
                        model: {
                          value: _vm.ruleForm.autoPaddingNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "autoPaddingNumber", $$v)
                          },
                          expression: "ruleForm.autoPaddingNumber",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#ccc" } }, [
                        _vm._v(
                          "开启该选项后,系统会自动根据每个藏品的唯一编号生成对应的兑换码,确保每个兑换码的唯一性,仅支持单个藏品"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "兑换设置：", prop: "setCode" } },
                [
                  _c(
                    "div",
                    { staticClass: "tab" },
                    [
                      _c("div", { staticClass: "tr flex align-items-c" }, [
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "20%" } },
                          [_vm._v("类型")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "25%" } },
                          [_vm._v("图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "th",
                            staticStyle: { width: "15%", "text-align": "left" },
                          },
                          [_vm._v("名称")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "th",
                            staticStyle: { width: "10%", "text-align": "left" },
                          },
                          [_vm._v("\n              投放数量\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "th",
                            staticStyle: { width: "10%", "text-align": "left" },
                          },
                          [_vm._v("\n              预留库存\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "20%" } },
                          [_vm._v("操作")]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.products, function (m, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "tr flex align-items-c" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: { width: "20%" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.ruleForm.createMethod == 2 ||
                                        _vm.ruleForm.autoPaddingNumber,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.editBuyTypeAction(i)
                                      },
                                    },
                                    model: {
                                      value: m.type,
                                      callback: function ($$v) {
                                        _vm.$set(m, "type", $$v)
                                      },
                                      expression: "m.type",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { value: 1, label: "数字藏品" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { value: 2, label: "数字盲盒" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td item-flex-center",
                                staticStyle: { width: "25%" },
                              },
                              [
                                m.image
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-image flex align-items-c justify-c",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: m.image + "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-image flex align-items-c justify-c",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture",
                                        }),
                                      ]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        _vm.disabledFile ||
                                        _vm.ruleForm.createMethod == 2,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleBuyAdditem(m.type, i)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: {
                                  width: "15%",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(m.name || "") +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: {
                                  width: "10%",
                                  "text-align": "left",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled:
                                      _vm.disabledFile ||
                                      _vm.ruleForm.createMethod == 2,
                                    type: "number",
                                  },
                                  model: {
                                    value: m.putCount,
                                    callback: function ($$v) {
                                      _vm.$set(m, "putCount", $$v)
                                    },
                                    expression: "m.putCount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            m.type == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "td",
                                    staticStyle: {
                                      width: "10%",
                                      "text-align": "left",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          m.reserved_number || m.reserveStock
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : m.type == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "td",
                                    staticStyle: {
                                      width: "10%",
                                      "text-align": "left",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(m.reserved_number || m.stock) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.ruleForm.autoPaddingNumber
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "td",
                                    staticStyle: { width: "20%" },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.disabledFile ||
                                            _vm.ruleForm.createMethod == 2,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delBuyAction(i)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      !_vm.ruleForm.autoPaddingNumber
                        ? _c("div", { staticClass: "tr flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "left",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      disabled:
                                        _vm.disabledFile ||
                                        _vm.ruleForm.createMethod == 2,
                                    },
                                    on: { click: _vm.addBuyAction },
                                  },
                                  [_vm._v("新增")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-form-item", { attrs: { label: "", prop: "type" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.create("ruleForm")
                              },
                            },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Productblind", {
        ref: "blind_box",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "错误原因",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.failTextContent))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }