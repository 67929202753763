var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px 0" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { path: _vm.roterPre + "/marketing/luckDraw/detail" },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("创建抽奖活动")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "抽奖活动名称",
              "min-width": "150",
              prop: "luckyName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "抽奖类型", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.luckyType == 0
                      ? _c("div", [_vm._v("九宫格")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.luckyType == 1
                      ? _c("div", [_vm._v("大转盘")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.luckyType == 2
                      ? _c("div", [_vm._v("泡泡机")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "参与人数", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.joinPeopleNum))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "中奖人数", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.getPrizePeopleNum))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品/已抽", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showPrizeCount(scope.row.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.prizeNum) +
                            "/" +
                            _vm._s(scope.row.hasGetNum)
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动时间", "min-width": "200", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v("开始时间：" + _vm._s(scope.row.startTime)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("结束时间：" + _vm._s(scope.row.endTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "min-width": "100", prop: "isStart" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c("div", [_vm._v("待开始")])
                      : scope.row.status == 1
                      ? _c("div", [_vm._v("进行中")])
                      : scope.row.status == 2
                      ? _c("div", [_vm._v("已结束")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "创建时间",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "120",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status != 2
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  _vm.roterPre +
                                  "/marketing/luckDraw/detail?id=" +
                                  scope.row.id,
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status != 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showAddCount(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("增加抽奖次数")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleStop(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("结束")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.pageSize,
              "current-page": _vm.tableFrom.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "合成详情", visible: _vm.visible, width: "800px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "mt20",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dialogTable, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "ruleSetName",
                  label: "玩法名称",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "消耗材料/条件/已消耗数量",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.conditionsList,
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  item.materialName +
                                    "/" +
                                    (item.conditionsNum
                                      ? item.conditionsNum
                                      : 0) +
                                    "/" +
                                    (item.alreadyConsumeNum
                                      ? item.alreadyConsumeNum
                                      : 0)
                                )
                              ),
                            ]),
                          ])
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "已合成对象数量详情",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.contentsList, function (y, i) {
                        return _c("div", { key: i }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                y.composeType == 1 ? "【作品】" : "【盲盒】"
                              ) +
                                "-" +
                                _vm._s(y.composeTypeName) +
                                "-已合成" +
                                _vm._s(y.alreadyOpenNum) +
                                "份"
                            ),
                          ]),
                        ])
                      })
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "增加抽奖次数",
            visible: _vm.showAddData.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showAddData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadImg",
                  staticClass: "upload-demo mr10",
                  staticStyle: { display: "inline-block" },
                  attrs: {
                    action: "#",
                    limit: 1,
                    "show-file-list": false,
                    multiple: "",
                    "http-request": _vm.airdropExcels,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("导入")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.downLoad },
                },
                [_vm._v("下载导入模板")]
              ),
              _vm._v(" "),
              _vm.showAddData.data
                ? _c("span", { staticClass: "ml20" }, [
                    _vm._v(
                      "导入成功 共" +
                        _vm._s(_vm.showAddData.data.userNum) +
                        "人增加" +
                        _vm._s(_vm.showAddData.data.luckyDrawNum) +
                        "次抽奖机会"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveAddAction },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看奖品",
            visible: _vm.showPrizeCountData.show,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showPrizeCountData, "show", $event)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.showPrizeCountData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "prizeName",
                  label: "奖品名称",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeName",
                  label: "奖品类型",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.prizeType == 0
                          ? _c("span", [_vm._v("谢谢参与")])
                          : scope.row.prizeType == 1
                          ? _c("span", [_vm._v("藏品")])
                          : scope.row.prizeType == 2
                          ? _c("span", [_vm._v("盲盒")])
                          : scope.row.prizeType == 3
                          ? _c("span", [_vm._v("积分")])
                          : scope.row.prizeType == 4
                          ? _c("span", [_vm._v("优惠券")])
                          : scope.row.prizeType == 5
                          ? _c("span", [_vm._v("实物")])
                          : scope.row.prizeType == 6
                          ? _c("span", [_vm._v("其他")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeNum",
                  label: "奖品数量",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasGetNum",
                  label: "已抽奖",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "appendPrizeNum",
                  label: "追加数量",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "120",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.addAgainAction(scope.row)
                              },
                            },
                          },
                          [_vm._v("追加")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "追加",
            visible: _vm.showPrizeAddData.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showPrizeAddData, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "mt20" }, [
            _vm._v(
              "\n      奖品名称：" + _vm._s(_vm.showPrizeAddData.name) + " "
            ),
            _c("span", { staticClass: "ml20" }, [
              _vm._v("奖品数量：" + _vm._s(_vm.showPrizeAddData.prizeNum)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "追加数量" },
                model: {
                  value: _vm.showPrizeAddData.appendNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.showPrizeAddData, "appendNum", $$v)
                  },
                  expression: "showPrizeAddData.appendNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveAddPrizeAction },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }