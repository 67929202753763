"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authorList = authorList;
exports.blockchainList = blockchainList;
exports.contractConfig = contractConfig;
exports.createGoods = createGoods;
exports.createinventoryOrder = createinventoryOrder;
exports.createsellOrder = createsellOrder;
exports.editcellgoods = editcellgoods;
exports.editcontractConfig = editcontractConfig;
exports.editinventoryOrder = editinventoryOrder;
exports.editletterStatus = editletterStatus;
exports.fastcreateGoods = fastcreateGoods;
exports.getbasicgoodsList = getbasicgoodsList;
exports.getidsgoods = getidsgoods;
exports.getinventoryOrderDetail = getinventoryOrderDetail;
exports.getinventoryOrderList = getinventoryOrderList;
exports.getletterDetail = getletterDetail;
exports.getpreviewsetting = getpreviewsetting;
exports.getproductcatelog = getproductcatelog;
exports.getproductlog = getproductlog;
exports.getpushinventoryOrderList = getpushinventoryOrderList;
exports.getsellOrderList = getsellOrderList;
exports.goodsCategoryAttr = goodsCategoryAttr;
exports.goodsDetail = goodsDetail;
exports.goodsList = goodsList;
exports.goodsMoreDetail = goodsMoreDetail;
exports.goodswarehouseList = goodswarehouseList;
exports.labelDeleteApi = labelDeleteApi;
exports.labelEditApi = labelEditApi;
exports.labelFormApi = labelFormApi;
exports.labelLstApi = labelLstApi;
exports.letterCreateApi = letterCreateApi;
exports.letterList = letterList;
exports.letterUpdate = letterUpdate;
exports.setpreviewsetting = setpreviewsetting;
exports.shelfCreate = shelfCreate;
exports.shelfDel = shelfDel;
exports.shelfList = shelfList;
exports.shelfMoreCreate = shelfMoreCreate;
exports.shelfUpdate = shelfUpdate;
exports.updateGoods = updateGoods;
exports.upgoods = upgoods;
var _request = _interopRequireDefault(require("./request"));
/**
 * 货架列表
 */
function shelfList(data) {
  return _request.default.get('store/product/shelf/list', data);
}
/**
* 创建货架
*/
function shelfCreate(data) {
  return _request.default.post('store/product/shelf/create', data);
}
/**
* 批量创建货架
*/
function shelfMoreCreate(data) {
  return _request.default.post('store/product/shelf/batchCreate', data);
}
/**
* 修改货架
*/
function shelfUpdate(data) {
  return _request.default.post('store/product/shelf/update', data);
}
/**
* 删除货架
*/
function shelfDel(data) {
  return _request.default.post('store/product/shelf/del', data);
}
/**
* 创建货品
*/
function createGoods(data) {
  return _request.default.post('erp/product/create', data);
}
/**
* 快速创建货品
*/
function fastcreateGoods(data) {
  return _request.default.post('erp/product/fastCreate', data);
}
/**
* 更新货品
*/
function updateGoods(id, data) {
  return _request.default.post('erp/product/update/' + id, data);
}
/**
* 货品详情
*/
function goodsDetail(id) {
  return _request.default.get('erp/product/detail/' + id);
}

/**
* 货品详情
*/
function goodsMoreDetail(data) {
  return _request.default.get('erp/entrust/order/getCategoryProductLst', data);
}
/**
* 货品列表
*/
function goodsList(data) {
  return _request.default.get('erp/product/lst', data);
}
/**
* 仓库货品列表
*/
function goodswarehouseList(data) {
  return _request.default.get('erp/warehouse/product/lst', data);
}
/**
* 货品分类属性
*/
function goodsCategoryAttr(data) {
  return _request.default.get('store/category/attrList', data);
}
/**
 * @description 标签 -- 列表
 */
function labelLstApi(data) {
  return _request.default.get('product/sys/tag/list', data);
}
/**
 * @description 标签 -- 添加表单
 */
function labelFormApi() {
  return _request.default.get('store/label/form');
}
/**
 * @description 标签 -- 编辑表单
 */
function labelEditApi(id) {
  return _request.default.get('store/label/form/' + id);
}
/**
 * @description 标签 -- 删除
 */
function labelDeleteApi(id) {
  return _request.default.delete("store/label/".concat(id));
}
/**
 * @description 创建委托人
 */
function letterCreateApi(data) {
  return _request.default.post('erp/entrust/user/create', data);
}
/**
 * @description 编辑委托人
 */
function letterUpdate(id, data) {
  return _request.default.post("erp/entrust/user/update/".concat(id), data);
}
/**
 * @description 委托列表
 */
function letterList(data) {
  return _request.default.get('product/sys/issuer/list', data);
}
/**
 * @description 作者列表
 */
function authorList(data) {
  return _request.default.get('product/sys/author/list', data);
}
/**
 * @description 链类型列表
 */
function blockchainList(data) {
  return _request.default.get('product/sys/blockchain/list', data);
}
/**
 * @description 委托人详情
 */
function getletterDetail(id) {
  return _request.default.get("erp/entrust/user/detail/".concat(id));
}
/**
 * @description 委托人详情
 */
function editletterStatus(id, data) {
  return _request.default.post("erp/entrust/user/switchStatus/".concat(id), data);
}
/**
 * @description 合同详情配置
 */
function contractConfig() {
  return _request.default.get('erp/entrust/order/getFieldConfig');
}
/**
 * @description 修改合同详情配置
 */
function editcontractConfig(data) {
  return _request.default.post('erp/entrust/order/setFieldConfig', data);
}
/**
 * @description 创建委托单
 */
function createinventoryOrder(data) {
  return _request.default.post('erp/entrust/order/create', data);
}
/**
 * @description 修改委托单
 */
function editinventoryOrder(data) {
  return _request.default.post('erp/entrust/order/create', data);
}
/**
 * @description 委托单详情
 */
function getinventoryOrderDetail(id) {
  return _request.default.get("erp/entrust/order/detail/".concat(id));
}
/**
 * @description 委托单列表
 */
function getinventoryOrderList(data) {
  return _request.default.get("erp/entrust/order/lst", data);
}
/**
 * @description 入库委托单列表
 */
function getpushinventoryOrderList(data) {
  return _request.default.get("erp/entrust/order/product/getWaitPushWarehouseLst", data);
}
/**
 * @description 销售单列表
 */
function getsellOrderList(data) {
  return _request.default.get("erp/salesOrder/lst", data);
}
/**
 * @description 创建销售单
 */
function createsellOrder(data) {
  return _request.default.post('erp/salesOrder/create', data);
}
/**
 * @description 货品操作日志
 */
function getproductlog(data) {
  return _request.default.get("erp/product/log/lst", data);
}
/**
 * @description 货品操作日志
 */
function getproductcatelog(data) {
  return _request.default.get("erp/product/log/categoryList", data);
}
/**
 * @description 获取配置
 */
function getpreviewsetting() {
  return _request.default.get("system/admin/audit/getAuditAdmin");
}
/**
 * @description 设置审批配置
 */
function setpreviewsetting(data) {
  return _request.default.post("system/admin/audit/setAuditAdmin", data);
}
/**
 * @description 上架到拍买
 */
function upgoods(data) {
  return _request.default.post("erp/product/batchProductToAuction", data);
}
// 单个编辑货品
function editcellgoods(id, data) {
  return _request.default.post("erp/product/updateField/".concat(id), data);
}
// 获取货品配置列表
function getbasicgoodsList(data) {
  return _request.default.get('erp/product/lst_v2', data);
}
// 通过id获取货品
function getidsgoods(data) {
  return _request.default.get('erp/product/idsByMainList', data);
}