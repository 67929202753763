"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBuilderContributeApi = getBuilderContributeApi;
exports.getBuilderContributeExportApi = getBuilderContributeExportApi;
exports.getBuilderListApi = getBuilderListApi;
exports.getConsumeOrderType = getConsumeOrderType;
exports.getCoreBuilderContributeApi = getCoreBuilderContributeApi;
exports.getCoreBuilderContributeExportApi = getCoreBuilderContributeExportApi;
exports.getListApi = getListApi;
exports.getMemberEquityManagementInfoApi = getMemberEquityManagementInfoApi;
exports.getMemberEquityManagementListApi = getMemberEquityManagementListApi;
exports.getRecommendInfoApi = getRecommendInfoApi;
exports.getRecommendListApi = getRecommendListApi;
exports.gradeInfoApi = gradeInfoApi;
exports.gradeUpdateApi = gradeUpdateApi;
exports.updateMemberEquityManagementInfoApi = updateMemberEquityManagementInfoApi;
exports.updateRecommendInfoApi = updateRecommendInfoApi;
var _request = _interopRequireDefault(require("./request"));
//获取等级列表
function getListApi(data) {
  return _request.default.get("/open3d/member/sys/member/level/list", data);
}
//获取等级详情
function gradeInfoApi(id) {
  return _request.default.get("/open3d/member/sys/member/level/".concat(id));
}
//修改用户等级
function gradeUpdateApi(data) {
  return _request.default.put("/open3d/member/sys/member/level/update", data);
}

//Builder列表
function getBuilderListApi(data) {
  return _request.default.get("/open3d/member/sys/member/level/getBuilderList", data);
}
//推荐奖励列表
function getRecommendListApi(data) {
  return _request.default.get("/open3d/member/sys/member/level/getRecommendList", data);
}

//查询奖励项详情
function getRecommendInfoApi(id) {
  return _request.default.get("/open3d/member/sys/member/level/getRecommendInfo/".concat(id));
}
//修改奖励详情

function updateRecommendInfoApi(data) {
  return _request.default.post("/open3d/member/sys/member/level/updateRecommendInfo", data);
}

//贡献测量-核心Builder
function getCoreBuilderContributeApi(data) {
  return _request.default.get("/open3d/member/sys/member/level/getContribute",
  // `/open3d/member/sys/member/level/getCoreBuilderContribute`,
  data);
}

//贡献测量-普通Builder
function getBuilderContributeApi(data) {
  return _request.default.get("/open3d/member/sys/member/level/getBuilderContribute", data);
}

//规则列表

function getMemberEquityManagementListApi(data) {
  return _request.default.get("/open3d/member/sys/member/level/getMemberEquityManagementList", data);
}
//规则详情
function getMemberEquityManagementInfoApi(id) {
  return _request.default.get("/open3d/member/sys/member/level/getMemberEquityManagementInfo/".concat(id));
}
//
function getConsumeOrderType(data) {
  return _request.default.get("/open3d/member/sys/member/level/getConsumeOrderType", data);
}
//修改规则

function updateMemberEquityManagementInfoApi(data) {
  return _request.default.post("/open3d/member/sys/member/level/updateMemberEquityManagementInfo", data);
}
//普通导出
function getBuilderContributeExportApi(data) {
  return _request.default.get("/open3d/member/sys/member/level/getBuilderContributeExport", data);
}
//核心导出
function getCoreBuilderContributeExportApi(data) {
  return _request.default.get("/open3d/member/sys/member/level/getCoreBuilderContributeExport", data);
}