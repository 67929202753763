"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _sdk = require("@/api/sdk");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = (0, _defineProperty2.default)((0, _defineProperty2.default)({
  name: 'MonitoringList',
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      identityTypeList: [{
        value: 1,
        label: '产品'
      }, {
        value: 2,
        label: '程序员'
      }, {
        value: 3,
        label: '商务'
      }, {
        value: 4,
        label: '其他'
      }],
      enterpriseTypeList: [{
        value: 1,
        label: '个人'
      }, {
        value: 2,
        label: '企业'
      }],
      modeTypeList: [{
        value: 1,
        label: '平台'
      }, {
        value: 2,
        label: 'SaaS'
      }],
      productTypeList: [{
        value: 1,
        label: '入门'
      }, {
        value: 2,
        label: '进阶'
      }, {
        value: 3,
        label: '精英'
      }, {
        value: 4,
        label: '至尊'
      }],
      expireStatusList: [{
        value: 0,
        label: '不可用'
      }, {
        value: 1,
        label: '正常状态'
      }, {
        value: 2,
        label: '即将过期'
      }, {
        value: 3,
        label: '已经过期'
      }],
      searchForm: {
        // identityType: '',
        enterpriseType: '',
        // modeType: 2,
        // productType: '',
        // expireStatus: '',
        nameOrPhone: '',
        appName: ''
      },
      pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      list: [],
      listLoading: false,
      visible: false,
      labelForm: {},
      uid: '',
      showdetail: false
    };
  },
  watch: {
    searchForm: {
      handler: function handler(val) {
        this.getList(1);
      },
      deep: true
    }
  },
  created: function created() {
    this.routeSearch();
    this.getList(1);
  }
}, "watch", {
  '$route': {
    handler: function handler(to, from) {
      if (to.query.modelType != from.query.modelType) {
        this.routeSearch();
        this.getList(1);
      }
    }
  }
}), "methods", {
  routeSearch: function routeSearch() {
    var query = this.$route.query;
    this.searchForm = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), query);
  },
  formatClient: function formatClient(map) {
    var clientEnum = {
      androidInfo: {
        name: 'Android应用',
        form: [{
          key: 'url',
          name: '应用下载地址',
          placeholder: '请输入应用下载地址',
          label: 'App包下载地址，可查看完整App功能。'
        }, {
          key: 'buildId',
          name: '应用包名',
          placeholder: '请输入包名',
          label: '应用在一设置上的唯一标识，在Manifest文件里面声明，该包名应和正式发布的包名一致。'
        }, {
          key: 'keystore',
          name: '应用签名',
          placeholder: '请输入应用签名',
          label: '用于对当前应用进行二次身份校验，开发者可以使用签名生成工具直接从安装当前应用的手机中获取。应用签名由开发者签名该应用的keystore文件决定。'
        }]
      },
      iosInfo: {
        name: 'IOS应用',
        form: [{
          key: 'url',
          name: '应用下载地址',
          placeholder: '请输入应用下载地址',
          label: 'App包下载地址，可查看完整App功能。'
        }, {
          key: 'buildId',
          name: '应用包名',
          placeholder: '请输入包名',
          label: '应用在一设置上的唯一标识，在Manifest文件里面声明，该包名应和正式发布的包名一致。'
        }, {
          key: 'keystore',
          name: '应用签名',
          placeholder: '请输入应用签名',
          label: '用于对当前应用进行二次身份校验，开发者可以使用签名生成工具直接从安装当前应用的手机中获取。应用签名由开发者签名该应用的keystore文件决定。'
        }]
      },
      pcWebInfo: {
        name: 'PC网站',
        form: [{
          key: 'url',
          name: '应用下载地址',
          placeholder: '请输入应用下载地址',
          label: 'App包下载地址，可查看完整App功能。'
        }, {
          key: 'buildId',
          name: '应用包名',
          placeholder: '请输入包名',
          label: '应用在一设置上的唯一标识，在Manifest文件里面声明，该包名应和正式发布的包名一致。'
        }, {
          key: 'keystore',
          name: '应用签名',
          placeholder: '请输入应用签名',
          label: '用于对当前应用进行二次身份校验，开发者可以使用签名生成工具直接从安装当前应用的手机中获取。应用签名由开发者签名该应用的keystore文件决定。'
        }]
      },
      h5WebInfo: {
        name: 'H5网站',
        form: [{
          key: 'url',
          name: '应用下载地址',
          placeholder: '请输入应用下载地址',
          label: 'App包下载地址，可查看完整App功能。'
        }, {
          key: 'buildId',
          name: '应用包名',
          placeholder: '请输入包名',
          label: '应用在一设置上的唯一标识，在Manifest文件里面声明，该包名应和正式发布的包名一致。'
        }, {
          key: 'keystore',
          name: '应用签名',
          placeholder: '请输入应用签名',
          label: '用于对当前应用进行二次身份校验，开发者可以使用签名生成工具直接从安装当前应用的手机中获取。应用签名由开发者签名该应用的keystore文件决定。'
        }]
      },
      vrInfo: {
        name: 'VR眼镜',
        form: [{
          key: 'url',
          name: '应用下载地址',
          placeholder: '请输入应用下载地址',
          label: 'App包下载地址，可查看完整App功能。'
        }, {
          key: 'buildId',
          name: '应用包名',
          placeholder: '请输入包名',
          label: '应用在一设置上的唯一标识，在Manifest文件里面声明，该包名应和正式发布的包名一致。'
        }, {
          key: 'keystore',
          name: '应用签名',
          placeholder: '请输入应用签名',
          label: '用于对当前应用进行二次身份校验，开发者可以使用签名生成工具直接从安装当前应用的手机中获取。应用签名由开发者签名该应用的keystore文件决定。'
        }]
      },
      wxMiniAppInfo: {
        name: '微信小程序',
        form: [{
          key: 'url',
          name: '应用下载地址',
          placeholder: '请输入应用下载地址',
          label: 'App包下载地址，可查看完整App功能。'
        }, {
          key: 'buildId',
          name: '应用包名',
          placeholder: '请输入包名',
          label: '应用在一设置上的唯一标识，在Manifest文件里面声明，该包名应和正式发布的包名一致。'
        }, {
          key: 'keystore',
          name: '应用签名',
          placeholder: '请输入应用签名',
          label: '用于对当前应用进行二次身份校验，开发者可以使用签名生成工具直接从安装当前应用的手机中获取。应用签名由开发者签名该应用的keystore文件决定。'
        }]
      }
    };
    var obj = {};
    // 循环map数据
    Object.keys(map).forEach(function (e) {
      // 将map变为对象
      var item = map[e] ? JSON.parse(map[e]) : null;
      if (item) {
        // 遍历每一项
        Object.keys(item).forEach(function (itemEl) {
          // 找到映射对象
          var enumItem = clientEnum[e].form.find(function (findEnum) {
            return findEnum.key === itemEl;
          });
          // 将映射对象里的name拿出来 赋值给它对象的value
          item[enumItem.name] = item[itemEl];
          delete item[itemEl];
        });
      }
      obj[clientEnum[e].name] = item;
    });
    return obj;
  },
  checkType: function checkType(val, type) {
    var list = this[type + 'List'];
    if (list && list.length && !isNaN(val)) {
      var item = list.find(function (e) {
        return e.value === Number(val);
      });
      return item.label || val;
    } else {
      return val;
    }
  },
  // 获取列表
  getList: function getList(reset) {
    var _this = this;
    if (reset) {
      this.pages.pageNum = 1;
    }
    var data = this.searchForm;
    this.listLoading = true;
    (0, _sdk.getSdkApplicationApi)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), this.pages)).then(function (res) {
      if (res.code === 200) {
        _this.pages.total = res.total;
        _this.list = res.rows;
      }
      _this.listLoading = false;
    }).catch(function () {
      _this.listLoading = false;
    });
  },
  changePage: function changePage(page) {
    this.pages.pageNum = page;
    this.getList(1);
  },
  handleEdit: function handleEdit(id) {
    this.labelForm = {
      id: id,
      type: 4,
      reason: ''
    };
    this.visible = true;
  },
  submitForm: function submitForm(formName) {
    var _this2 = this;
    this.$refs[formName].validate(function (valid) {
      if (valid) {
        _this2.setSdkStatus((0, _objectSpread2.default)({}, _this2.labelForm));
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  },
  nickNameClick: function nickNameClick(id) {
    this.uid = id;
    this.showdetail = true;
  },
  userdetailclose: function userdetailclose() {
    this.showdetail = false;
  },
  // 修改状态
  setSdkStatus: function setSdkStatus(params) {
    var _this3 = this;
    (0, _sdk.setSdkStatusApi)(params).then(function (res) {
      if (res.code === 200) {
        if (params.type === 4) {
          _this3.visible = false;
        }
        _this3.getList();
      } else {
        _this3.$message.error(res.massage);
      }
    }).catch(function (err) {
      _this3.$message.error(err.massage);
    });
  }
});