var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "页面基本配置", is_tab: false } },
    [
      _vm.facade && _vm.facade.show
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "背景颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#f7f7f7" },
                      model: {
                        value: _vm.facade.background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "background", $$v)
                        },
                        expression: "facade.background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("背景图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.bg_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.bg_image + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "背景图设置" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.facade.bg_image_mode,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bg_image_mode", $$v)
                          },
                          expression: "facade.bg_image_mode",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("宽度固定上下居中"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("满屏上下左右截取"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("宽度固定上下平铺循环展示"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("宽度固定高度自适应顶部展示"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "页面边距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.page_margin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "page_margin", $$v)
                        },
                        expression: "facade.page_margin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("返回按钮"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "back_image")
                        },
                      },
                    },
                    [
                      _vm.facade.back_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("back_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.back_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.back_image + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("通用内容设置"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "左右距离" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.marginLeft,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "marginLeft", $$v)
                        },
                        expression: "facade.marginLeft",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "内间距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.item_padding,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_padding", $$v)
                        },
                        expression: "facade.item_padding",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "内容圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.borderRadius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "borderRadius", $$v)
                        },
                        expression: "facade.borderRadius",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "背景颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.item_background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_background", $$v)
                        },
                        expression: "facade.item_background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "边框颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.border,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "border", $$v)
                        },
                        expression: "facade.border",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.titleColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "titleColor", $$v)
                        },
                        expression: "facade.titleColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.titleFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "titleFontSize", $$v)
                        },
                        expression: "facade.titleFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("分享设置"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "分享按钮" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.show_share,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "show_share", $$v)
                          },
                          expression: "facade.show_share",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("展示"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("不展示"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮位置" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.share_area,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "share_area", $$v)
                          },
                          expression: "facade.share_area",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("名称上方"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("右上角"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("未收藏按钮"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "collection_image")
                        },
                      },
                    },
                    [
                      _vm.facade.collection_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("collection_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.collection_image
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.collection_image + "!120a",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center ml10",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "has_collection_image")
                        },
                      },
                    },
                    [
                      _vm.facade.has_collection_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("has_collection_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.has_collection_image
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.has_collection_image + "!120a",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt5" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "color-gray ft12",
                      staticStyle: { width: "40px", "text-align": "center" },
                    },
                    [_vm._v("未收藏")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "color-gray ft12 ml10",
                      staticStyle: { width: "40px", "text-align": "center" },
                    },
                    [_vm._v("已收藏")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("底部按钮设置"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "底部背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.bottom_background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "bottom_background", $$v)
                        },
                        expression: "facade.bottom_background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "价格颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.priceColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "priceColor", $$v)
                        },
                        expression: "facade.priceColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮风格" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.btnBgStyle,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "btnBgStyle", $$v)
                          },
                          expression: "facade.btnBgStyle",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "white" } }, [
                          _vm._v("白色"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "black" } }, [
                          _vm._v("黑色"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.btnBgColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "btnBgColor", $$v)
                        },
                        expression: "facade.btnBgColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.btnColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "btnColor", $$v)
                        },
                        expression: "facade.btnColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "特殊文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.txtColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "txtColor", $$v)
                        },
                        expression: "facade.txtColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("盲盒开启页设置"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("开盒背景图"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "open_bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.open_bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("open_bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.open_bg_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.open_bg_image + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("开盒效果图"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "open_effect_image")
                        },
                      },
                    },
                    [
                      _vm.facade.open_effect_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("open_effect_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.open_effect_image
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.open_effect_image + "!120a",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openBtnBgColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openBtnBgColor", $$v)
                        },
                        expression: "facade.openBtnBgColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮边框" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openBtnBorderColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openBtnBorderColor", $$v)
                        },
                        expression: "facade.openBtnBorderColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openBtnColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openBtnColor", $$v)
                        },
                        expression: "facade.openBtnColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "副按钮背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openBtnBgColorSub,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openBtnBgColorSub", $$v)
                        },
                        expression: "facade.openBtnBgColorSub",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "副按钮边框" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openBtnBorderColorSub,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openBtnBorderColorSub", $$v)
                        },
                        expression: "facade.openBtnBorderColorSub",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "副按钮文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openBtnColorSub,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openBtnColorSub", $$v)
                        },
                        expression: "facade.openBtnColorSub",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("开盒结果页设置"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("结果页背景"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "open_result_image")
                        },
                      },
                    },
                    [
                      _vm.facade.open_result_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("open_result_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.open_result_image
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.open_result_image + "!120a",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("开盒成功图"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "open_result_finish_image")
                        },
                      },
                    },
                    [
                      _vm.facade.open_result_finish_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("open_result_finish_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.open_result_finish_image
                        ? _c("img", {
                            attrs: {
                              src:
                                _vm.facade.open_result_finish_image + "!120a",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "内容背景色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultContentBgColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openResultContentBgColor", $$v)
                        },
                        expression: "facade.openResultContentBgColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "文字颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openResultColor", $$v)
                        },
                        expression: "facade.openResultColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "价格颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultPriceColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openResultPriceColor", $$v)
                        },
                        expression: "facade.openResultPriceColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultBtnBgColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openResultBtnBgColor", $$v)
                        },
                        expression: "facade.openResultBtnBgColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮边框" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultBtnBorderColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openResultBtnBorderColor", $$v)
                        },
                        expression: "facade.openResultBtnBorderColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultBtnColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openResultBtnColor", $$v)
                        },
                        expression: "facade.openResultBtnColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "副按钮背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultBtnBgColorSub,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openResultBtnBgColorSub", $$v)
                        },
                        expression: "facade.openResultBtnBgColorSub",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "副按钮边框" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultBtnBorderColorSub,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.facade,
                            "openResultBtnBorderColorSub",
                            $$v
                          )
                        },
                        expression: "facade.openResultBtnBorderColorSub",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "副按钮文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.openResultBtnColorSub,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "openResultBtnColorSub", $$v)
                        },
                        expression: "facade.openResultBtnColorSub",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }