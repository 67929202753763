var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-button",
        {
          staticClass: "mr20",
          attrs: { size: "mini", icon: "el-icon-back" },
          on: { click: _vm.close },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
                inline: "",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        "font-size": "18px",
                      },
                      attrs: { span: 12, offset: 0 },
                    },
                    [_vm._v("创建入库单")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务负责人", prop: "manage_admin_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "业务负责人",
                            clearable: "",
                            filterable: "",
                            disabled: !!_vm.lockid,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getinvent(1)
                            },
                          },
                          model: {
                            value: _vm.formValidate.manage_admin_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "manage_admin_id", $$v)
                            },
                            expression: "formValidate.manage_admin_id",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库房", prop: "warehouse_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "库房",
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.pushshelf },
                          model: {
                            value: _vm.formValidate.warehouse_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "warehouse_id", $$v)
                            },
                            expression: "formValidate.warehouse_id",
                          },
                        },
                        _vm._l(_vm.storeArr, function (item) {
                          return _c("el-option", {
                            key: item.warehouse_id,
                            attrs: {
                              label: item.name,
                              value: item.warehouse_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库类型", prop: "push_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "入库类型",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formValidate.push_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "push_type", $$v)
                            },
                            expression: "formValidate.push_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "委托拍卖", value: "entrust" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "采购入库", value: "procurement" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "生产入库", value: "production" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "调拨入库", value: "transfers" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "退货入库", value: "refund" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "其他入库", value: "other" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "单据编号" } }, [
                    _vm._v(_vm._s(_vm.orderno)),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "制单人" } }, [
                    _vm._v(_vm._s(_vm.mannagename)),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cateArr, function (citem, cindex) {
                    return [
                      _c(
                        "el-table",
                        {
                          staticStyle: { "margin-top": "30px" },
                          attrs: {
                            data: citem.goodsList,
                            size: "small",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "选择货架（选填）",
                              "min-width": "130",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.formValidate.warehouse_id,
                                            placeholder: "请选择货架",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: scope.row.shelf_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "shelf_id",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.shelf_id",
                                          },
                                        },
                                        _vm._l(_vm.shelfList, function (item) {
                                          return _c("el-option", {
                                            key: item.shelf_id,
                                            attrs: {
                                              label: item.shelf_no,
                                              value: item.shelf_id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "分类",
                                prop: "product_id",
                                "min-width": "50",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(citem.cate.cate_name) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "图片", "min-width": "60" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "demo-image__preview" },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "36px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              src: scope.row.image,
                                              "preview-src-list": [
                                                scope.row.image,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "拍品名称",
                              prop: "store_name",
                              "min-width": "140",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(scope.row.store_name) +
                                            "\n            "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "标签",
                              "min-width": "120",
                              prop: "label",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "保留价", "min-width": "80" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.retain_price) +
                                          "\n          "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "作者", "min-width": "80" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        scope.row.brand
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.brand.brand_name
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "属性",
                                align: "center",
                                "min-width": "150",
                              },
                            },
                            _vm._l(citem.attribute, function (item, index) {
                              return _c("el-table-column", {
                                key: "ps_" + index,
                                attrs: { label: item.attr_name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return _vm._l(
                                          scope.row.attrInfo,
                                          function (m, i) {
                                            return m.attr_id == item.attr_id
                                              ? _c(
                                                  "span",
                                                  { key: "attr_" + cindex + i },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          m.attr_value_name
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delGoods(
                                                    scope.$index,
                                                    scope.row,
                                                    cindex
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                移除\n              "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "20px", width: "400px" },
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("formValidate")
                            },
                          },
                        },
                        [_vm._v("\n            确认创建\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "30px" } }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              attrs: { "before-leave": _vm.leavetab },
              on: { "tab-click": _vm.changetab },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  label: "委托标的清单",
                  name: "inv",
                  disabled: !!_vm.locktype,
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  label: "货品档案",
                  name: "goods",
                  disabled: !!_vm.locktype,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tab == "inv",
                  expression: "tab == 'inv'",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: "", size: "small", "label-width": "110px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品搜索：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            placeholder: "请输入货品名称，关键字，产品编号",
                          },
                          model: {
                            value: _vm.invtableFrom.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.invtableFrom, "keyword", $$v)
                            },
                            expression: "invtableFrom.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getinvent(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品ID：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入货品ID" },
                          model: {
                            value: _vm.invtableFrom.product_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.invtableFrom, "product_id", $$v)
                            },
                            expression: "invtableFrom.product_id",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getinvent(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "multipleSelection",
                  staticClass: "table",
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: {
                    "tooltip-effect": "dark",
                    "row-key": function (row) {
                      return row.order_id
                    },
                    data: _vm.invtableData.data,
                    size: "mini",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "委托方", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.userEntrust
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.userEntrust
                                          .entrust_contact_company
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "单据时间",
                      "min-width": "90",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "order_no",
                      label: "单据编号",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creator.real_name",
                      label: "制单人",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditAdmin.real_name",
                      label: "审批人",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: { "min-width": "600" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              scope.row.entrustOrderProduct,
                              function (item, index) {
                                return item.productItem
                                  ? _c(
                                      "div",
                                      {
                                        key: "enp_" + index,
                                        staticClass: "flex-box",
                                        class: {
                                          "flex-box-border-bottom":
                                            index <
                                            scope.row.entrustOrderProduct
                                              .length -
                                              1,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex-box-image" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "demo-image__preview",
                                              },
                                              [
                                                _c("el-image", {
                                                  attrs: {
                                                    src: item.productItem.image,
                                                    "preview-src-list": [
                                                      item.productItem.image,
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-box-name" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  item.productItem.store_name
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-box-price" },
                                          [_vm._v(_vm._s(item.retain_price))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-box-autor" },
                                          [
                                            item.productItem.brand
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.productItem.brand
                                                        .brand_name
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-box-name" },
                                          _vm._l(
                                            item.productItem.attrInfo,
                                            function (m, i) {
                                              return _c(
                                                "span",
                                                { key: "attr_" + i },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(m.attr_name) +
                                                      ":" +
                                                      _vm._s(
                                                        m.attr_value_name
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-box-image" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.saleGoods(
                                                      item.productItem,
                                                      "inv",
                                                      scope.row.entrust_id,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.goodsIdArr.indexOf(
                                                        item.productItem
                                                          .product_id
                                                      ) > -1
                                                        ? "已选择货品"
                                                        : "选择货品"
                                                    ) +
                                                    "\n            "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e()
                              }
                            )
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", { staticClass: "flex-box" }, [
                          _c("div", { staticClass: "flex-box-image" }, [
                            _vm._v("图片"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-name" }, [
                            _vm._v("拍品名称"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-price" }, [
                            _vm._v("保留价"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-autor" }, [
                            _vm._v("作者"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-name" }, [
                            _vm._v("属性"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-image" }, [
                            _vm._v("操作"),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.invtableFrom.limit,
                      "current-page": _vm.invtableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.invtableData.total,
                    },
                    on: {
                      "size-change": _vm.invhandleSizeChange,
                      "current-change": _vm.invpageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tab == "goods",
                  expression: "tab == 'goods'",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: "", size: "small", "label-width": "110px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品搜索：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            placeholder: "请输入货品名称，关键字，产品编号",
                          },
                          model: {
                            value: _vm.tableFrom.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keyword", $$v)
                            },
                            expression: "tableFrom.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品ID：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入货品ID" },
                          model: {
                            value: _vm.tableFrom.product_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "product_id", $$v)
                            },
                            expression: "tableFrom.product_id",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.tableData.data,
                    size: "small",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "货品ID",
                      prop: "product_id",
                      "min-width": "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "货品图", "min-width": "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "36px",
                                    height: "36px",
                                  },
                                  attrs: {
                                    src: scope.row.image,
                                    "preview-src-list": [scope.row.image],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "store_name",
                      label: "货品名称",
                      "min-width": "140",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brand",
                      label: "作者/品牌",
                      "min-width": "90",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.brand
                              ? _c("div", [
                                  _vm._v(
                                    "\n               " +
                                      _vm._s(scope.row.brand.brand_name) +
                                      "\n             "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分类", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.storeCategory
                              ? [
                                  _c("span", { staticClass: "mr10" }, [
                                    _vm._v(
                                      _vm._s(scope.row.storeCategory.cate_name)
                                    ),
                                  ]),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "属性", "min-width": "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              _vm._l(scope.row.attrInfo, function (m, i) {
                                return _c("span", { key: "attr_" + i }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(m.attr_name) +
                                      ":" +
                                      _vm._s(m.attr_value_name) +
                                      "\n                "
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "创建时间",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "录入",
                      "min-width": "90",
                      prop: "creator.real_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saleGoods(
                                          scope.row,
                                          "goods",
                                          ""
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.goodsIdArr.indexOf(
                                            scope.row.product_id
                                          ) > -1
                                            ? "已选择货品"
                                            : "选择货品"
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }