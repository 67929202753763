"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _devLop = require("@/api/devLop");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {
        phone: "",
        price: 0,
        name: ""
      },
      rules: {
        name: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        }],
        phone: [{
          required: true,
          message: "请输入手机号",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请输入转账金额",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _devLop.transferApi)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this.ruleForm), {}, {
            payMethod: 7
          })).then(function (res) {
            _this.ruleForm = {
              phone: "",
              price: "",
              name: ""
            };
            _this.$message.success("转账成功");
          }).catch(function (err) {
            console.log(err);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};