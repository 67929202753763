var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单类型：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "type", $$v)
                                },
                                expression: "tableFrom.type",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("数字藏品"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v("数字盲盒"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "3" } }, [
                                _vm._v("寄售市场"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          staticStyle: { display: "block" },
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.timeType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "timeType", $$v)
                                },
                                expression: "tableFrom.timeType",
                              },
                            },
                            _vm._l(_vm.fromList, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text) + "\n              ")]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd HH:mm:ss",
                              format: "yyyy/MM/dd HH:mm:ss",
                              size: "small",
                              type: "datetimerange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "分销员：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.tableFrom.nameOrPhone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "nameOrPhone", $$v)
                                },
                                expression: "tableFrom.nameOrPhone",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.changeadduser },
                    },
                    [_vm._v("添加分销员")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-top",
                      },
                      on: { click: _vm.exportRecord },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "text" },
                      on: { click: _vm.getExportFileList },
                    },
                    [_vm._v("导出已生成列表")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt20" }, [
                _c("span", [
                  _vm._v(
                    "订单数：" + _vm._s(_vm.tableData.statistical.teamOrderNum)
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "ml20" }, [
                  _vm._v(
                    "销售额：" + _vm._s(_vm.tableData.statistical.teamAllMoney)
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "ml20" }, [
                  _vm._v(
                    "团队成员总数: " +
                      _vm._s(_vm.tableData.statistical.teamPeopleNum)
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                data: _vm.tableData.data,
                size: "mini",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.avatar
                                  ? scope.row.avatar
                                  : _vm.moren,
                                "preview-src-list": [
                                  scope.row.avatar || _vm.moren,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "昵称", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#1890ff",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row)
                              },
                            },
                          },
                          [_vm._v("\n            " + _vm._s(row.userName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "teamNum",
                  label: "团队成员",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showteamDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.teamNum))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "teamOrderNum",
                  label: "订单数",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showOrderList(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.teamOrderNum))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "saleAllMoney",
                  label: "销售额",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showOrderList(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.saleAllMoney))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "saleAllMoney",
                  label: "佣金",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showOrderList(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.dividedMoney))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.status == 1 ? "启用" : "已解除"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "加入时间",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "80",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editHandle(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            编辑\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeretailstatus(scope.row, 0)
                                  },
                                },
                              },
                              [_vm._v("\n            解除分销\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.retailstatus(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("\n            启用\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.changeretailstatus(scope.row, 1)
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.userTitle, visible: _vm.showadd, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: { model: _vm.createForm, rules: _vm.createRules },
            },
            [
              _vm.showEdit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "绑定员工：", prop: "adminId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择员工" },
                          model: {
                            value: _vm.createForm.adminId,
                            callback: function ($$v) {
                              _vm.$set(_vm.createForm, "adminId", $$v)
                            },
                            expression: "createForm.adminId",
                          },
                        },
                        _vm._l(_vm.adminList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.userId,
                              attrs: {
                                value: item.userId,
                                label: item.nickName,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                      "border-radius": "50%",
                                      "margin-right": "12px",
                                    },
                                    attrs: { src: item.avatar, alt: "" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "line-height": "18px",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(item.nickName)),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(_vm._s(item.phonenumber)),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showEdit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "分销员：", prop: "userId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择用户",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.searchUserAction,
                            loading: _vm.searchLoading,
                          },
                          model: {
                            value: _vm.createForm.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.createForm, "userId", $$v)
                            },
                            expression: "createForm.userId",
                          },
                        },
                        _vm._l(_vm.userList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.userId,
                              attrs: {
                                value: item.userId,
                                label: item.nickName,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                      "border-radius": "50%",
                                      "margin-right": "12px",
                                    },
                                    attrs: { src: item.avatar, alt: "" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "line-height": "18px",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(item.nickName)),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(_vm._s(item.userPhone)),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showEdit
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否保留之前数据：",
                        prop: "isSaveBefore",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.createForm.isSaveBefore,
                            callback: function ($$v) {
                              _vm.$set(_vm.createForm, "isSaveBefore", $$v)
                            },
                            expression: "createForm.isSaveBefore",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("保留之前邀请"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("忽略之前邀请"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "佣金比例：", prop: "dividedScale" } },
                [
                  _c(
                    "el-input-number",
                    {
                      attrs: {
                        placeholder: "输入比例（数字）",
                        max: 100,
                        min: 0,
                      },
                      model: {
                        value: _vm.createForm.dividedScale,
                        callback: function ($$v) {
                          _vm.$set(_vm.createForm, "dividedScale", $$v)
                        },
                        expression: "createForm.dividedScale",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("(针对一级发售订单)")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "寄售分佣：", prop: "isOpenRemark" } },
                [
                  _c("el-switch", {
                    staticClass: "ml10",
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#909399",
                    },
                    model: {
                      value: _vm.createForm.isOpenRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.createForm, "isOpenRemark", $$v)
                      },
                      expression: "createForm.isOpenRemark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleadd("ruleForm")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "启用分销员",
            visible: _vm.changestatusflag,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changestatusflag = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.changestatusRow.otherIsSave,
                callback: function ($$v) {
                  _vm.$set(_vm.changestatusRow, "otherIsSave", $$v)
                },
                expression: "changestatusRow.otherIsSave",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [
                _vm._v("保留原团队成员"),
              ]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "0" } }, [_vm._v("全新分销员")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.changestatusflag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlechangestatus },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showretaildetail
        ? [
            _c("retaildetail", {
              attrs: { showretaildetail: _vm.showretaildetail, uid: _vm.uid },
              on: {
                refash: _vm.retaildetailrefash,
                close: _vm.retaildetailclose,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "订单列表", visible: _vm.orderFlag, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.orderFlag = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "order-table table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.orderData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品图片", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: { src: scope.row.image },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "购买价格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.price))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.orderParams.pageSize,
              "current-page": _vm.orderParams.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.orderData.total,
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.pageChange1,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }