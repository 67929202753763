var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "mobile",
      staticClass: "package",
      attrs: { id: "min-page", draggable: false, "data-index": -2 },
      on: {
        dragover: [
          function ($event) {
            return _vm.allowDrop($event)
          },
          function ($event) {
            $event.preventDefault()
          },
        ],
        drop: _vm.drop,
      },
    },
    [
      _vm._l(_vm.pages, function (item, index) {
        return _c(
          "div",
          { key: index + 1, attrs: { role: "widgetgroup" } },
          [
            _vm.dragActive(index, "top") ? _c("Covermask") : _vm._e(),
            _vm._v(" "),
            item.name === "title"
              ? _c("Title", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "memberEnter"
              ? _c("MemberEnter", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "video"
              ? _c("Video", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "banner"
              ? _c("Banner", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "rubik"
              ? _c("Rubik", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "blank"
              ? _c("Blank", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "notice"
              ? _c("Notice", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "wechat"
              ? _c("Wechat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "mine_nav"
              ? _c("MineNav", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "nftInfo"
              ? _c("NftInfo", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "textView"
              ? _c("textView", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.dragActive(index, "down") ? _c("Covermask") : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "package-empty", attrs: { "data-index": -2 } },
        [
          _vm.dragActive(-2, "down")
            ? _c("Covermask", { attrs: { "data-index": -2 } })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }