"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _open3Dgrade = require("@/api/open3Dgrade");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      id: "",
      gradeDetail: {},
      couponsArr: [],
      rules: {
        memberLevelName: [{
          required: true,
          message: "请输入等级名称",
          trigger: "blur"
        }],
        image: [{
          required: true,
          message: "请选择等级标识",
          trigger: "change"
        }],
        levelGrowth: [{
          required: true,
          message: "请输入升级所需成长值",
          trigger: "blur"
        }],
        integralMultiple: [{
          required: true,
          message: "请输入用户获得积分倍数",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请输入价格",
          trigger: "blur"
        }],
        levelType: [{
          required: true,
          message: "请选择类型",
          trigger: "change"
        }]
      },
      typeList: []
    };
  },
  created: function created() {
    var _this = this;
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.loadDetail();
    }
    (0, _marketing.couponslist)({
      pageNum: 1,
      pageSize: 20
    }).then(function (res) {
      _this.couponsArr = res.rows;
    });
    (0, _marketing.integralTypeListApi)().then(function (res) {
      _this.typeList = res.data;
    });
  },
  methods: {
    loadDetail: function loadDetail() {
      var _this2 = this;
      (0, _open3Dgrade.gradeInfoApi)(this.id).then(function (res) {
        _this2.gradeDetail = res.data;
        if (res.data.memberLevelAwardCouponIds) {
          _this2.gradeDetail.memberLevelAwardCouponIds = res.data.memberLevelAwardCouponIds.split(",");
        } else {
          _this2.gradeDetail.memberLevelAwardCouponIds = [];
        }
      });
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this3.gradeDetail));
          if (!data.scoreTypeId && data.memberLevelAwardScore) {
            _this3.$message.error("请选择积分类型");
            return false;
          }
          if (!data.memberLevelPrivilegeNftDiscount) data.memberLevelPrivilegeNftDiscount = 100;
          if (!data.memberLevelPrivilegeBoxDiscount) data.memberLevelPrivilegeBoxDiscount = 100;
          data["memberLevelAwardCouponIds"] = _this3.gradeDetail.memberLevelAwardCouponIds.length ? _this3.gradeDetail.memberLevelAwardCouponIds.join(",") : "";
          data.id = _this3.id;
          (0, _open3Dgrade.gradeUpdateApi)(data).then(function () {
            _this3.$message.success("操作成功");
            _this3.$router.back();
          }).catch(function (msg) {
            _this3.$message.error(msg);
          });
        } else {
          return false;
        }
      });
    },
    backAction: function backAction() {
      this.$router.back();
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, key, key1) {
      var _this4 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this4.gradeDetail[key1][key] = img[0];
        } else {
          _this4.gradeDetail[key] = img[0];
        }
        _this4.$forceUpdate();
      }, tit);
    },
    delImg: function delImg(key, key1) {
      if (key1) {
        this.gradeDetail[key1][key] = "";
      } else {
        this.gradeDetail[key] = "";
      }
      this.$forceUpdate();
    }
  }
};