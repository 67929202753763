var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.showNftSale,
            size: "80%",
            "modal-append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showNftSale = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "show-t", attrs: { slot: "title" }, slot: "title" },
            [_vm._v("查看作品信息")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0 20px" } },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c ft12" },
                [
                  _c("span", [_vm._v("作品名称：")]),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "w200",
                      staticStyle: { position: "relative" },
                      attrs: { placeholder: "作品名称", size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.pageChange(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.key,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "key", $$v)
                        },
                        expression: "tableFrom.key",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.pageChange(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml20" }, [_vm._v("选择系列：")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.changeData },
                      model: {
                        value: _vm.tableFrom.seriesId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "seriesId", $$v)
                        },
                        expression: "tableFrom.seriesId",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "", label: "全部" } }),
                      _vm._v(" "),
                      _vm._l(_vm.albumList, function (m, i) {
                        return _c("el-option", {
                          key: i,
                          attrs: { value: m.id, label: m.name },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticClass: "ft12 mt10",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.list },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "作品ID", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "flex" }, [
                              _vm._v(_vm._s(scope.row.id)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "图片", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "80px",
                                  height: "80px",
                                  display: "block",
                                },
                                attrs: {
                                  src: scope.row.image + "!120a",
                                  alt: "",
                                },
                              }),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作品名称", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "flex align-items-c" }, [
                              _vm._v(_vm._s(scope.row.name)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "系列名称", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "flex align-items-c" }, [
                              _vm._v(_vm._s(scope.row.seriesName)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分账比例", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(scope.row.splitProportion) + "%"),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发行数量", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.issuedCount))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发售价格", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.price))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "销量", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.sales))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分账单价", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.splitPrice))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分成金额", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(scope.row.splitTotalPrice)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发行时间", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.startTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      "page-sizes": [10, 20, 40, 60, 80],
                      "page-size": _vm.tableFrom.pageSize,
                      "current-page": _vm.tableFrom.pageNum,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: { "current-change": _vm.pageChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }