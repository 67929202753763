"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _popconfirm = _interopRequireDefault(require("@/components/customPage/popconfirm.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    popconfirm: _popconfirm.default
  },
  props: {
    index: {
      type: [Number, String]
    },
    type: {
      type: [Number, String],
      default: ''
    },
    facade: {
      type: [Object, Array]
    },
    content: {
      type: [Object, Array]
    },
    no_control: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      hover: false
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set() {
        this.$store.commit('finish/setPagesData', pages);
      }
    },
    select: {
      get: function get() {
        return this.$store.state.finish.index;
      },
      set: function set(value) {
        this.$store.commit('finish/setIndex', value);
      }
    },
    is_copy: function is_copy() {
      if (this.type == 'wechat') {
        return true;
      } else {
        return false;
      }
    },
    is_up: function is_up() {
      if (this.index === 0) {
        return true;
      } else {
        return false;
      }
    },
    is_down: function is_down() {
      if (this.index === this.pages.length - 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created: function created() {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  methods: {
    /**
     * 删除
     * @return {[type]} [description]
     */
    onDelete: function onDelete() {
      var _this = this;
      this.$confirm('确认删除该模块？', '操作提示').then(function () {
        var index = _this.index;
        _this.pages.splice(index, 1);
      });
    },
    /**
     * 拷贝
     * @return {[type]} [description]
     */
    onCopy: function onCopy() {
      var index = this.index;
      var data = this.pages[index];
      if (data.name !== 'wechat') {
        var _pages = JSON.parse(JSON.stringify(this.pages));
        _pages.splice(index, 0, data);
        this.$store.commit('finish/setPagesData', _pages);
        this.$store.commit('finish/setIndex', index + 1);
      }
    },
    /**
     * 上移
     * @return {[type]} [description]
     */
    moveUp: function moveUp() {
      if (this.is_up) {
        return;
      }
      var index = this.index;
      /* 数组两个元素位置互换*/
      this.pages[index] = this.pages.splice(index - 1, 1, this.pages[index])[0];
      this.$store.commit('finish/setIndex', index - 1);
    },
    /**
     * 下移
     * @return {[type]} [description]
     */
    moveDown: function moveDown() {
      if (this.is_down) {
        return;
      }
      var index = this.index;
      /* 数组两个元素位置互换*/
      this.pages[index] = this.pages.splice(index + 1, 1, this.pages[index])[0];
      this.$store.commit('finish/setIndex', index + 1);
    },
    mouseOver: function mouseOver() {
      this.hover = true;
    },
    mouseleave: function mouseleave() {
      this.hover = false;
    }
  }
};