var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "80px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "下载状态：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.downloadStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "downloadStatus",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.downloadStatus",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "" } },
                                    [_vm._v("全部")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [_vm._v("已下载")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "0" } },
                                    [_vm._v("未下载")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "作品质量：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.textureMode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "textureMode",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.textureMode",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "" } },
                                    [_vm._v("全部")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "2" } },
                                    [_vm._v("高")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [_vm._v("中")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "0" } },
                                    [_vm._v("低")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "建模状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "status", $$v)
                            },
                            expression: "queryParams.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("建模中"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("成功"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "-1" } }, [
                            _vm._v("失败"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: {
                        placeholder: "创建人名称/手机号",
                        size: "small",
                      },
                      model: {
                        value: _vm.queryParams.userNameOrPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "userNameOrPhone", $$v)
                        },
                        expression: "queryParams.userNameOrPhone",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt10 mb10" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("查看导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", { attrs: { type: "ID", prop: "id" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "3D文件" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: row.url,
                            "preview-src-list": [row.url],
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "质量", prop: "textureModeText" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "大小", prop: "fileSize" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "面数", prop: "faceCount" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "贴图大小", prop: "mapSize" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片素材" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v(_vm._s(row.imgCount || 0) + "张")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作终端", prop: "terminal" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "设备信息", prop: "deviceInfo" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "文件位置", prop: "fileLocation" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "建模状态", prop: "statusText" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "建模时间", prop: "finishTime" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建人", prop: "userName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "渠道", prop: "channel" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "180",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.watch(scope.row.modelUrl)
                              },
                            },
                          },
                          [_vm._v("\n            查看")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.downLoad(scope.row)
                              },
                            },
                          },
                          [_vm._v("下载")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.queryParams.pageSize,
                  "current-page": _vm.queryParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }