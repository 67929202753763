"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {},
      rules: {}
    };
  },
  methods: {
    backAction: function backAction() {
      this.$router.back();
    }
  }
};