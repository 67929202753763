"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _nft = require("@/api/nft");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      keyword: '',
      status: '',
      newDeliverDetail: {
        show: false,
        isIncrease: true,
        name: '',
        id: '',
        condition: [{
          day: '',
          handlingFee: ''
        }],
        hasThirdParty: true,
        thirdPartyIsIncrease: true,
        thirdPartyRule: [{
          day: '',
          handlingFee: ''
        }]
      },
      dialogTitle: '新建转赠规则',
      rules: {
        name: [{
          required: true,
          message: '请输入规则名称',
          trigger: 'blur'
        }],
        isIncrease: [{
          required: true,
          message: '请选择是否可以转赠',
          trigger: 'change'
        }],
        thirdPartyIsIncrease: [{
          required: true,
          message: '请选择是否可以转赠',
          trigger: 'change'
        }]
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 分页跳转
    getListPage: function getListPage(val) {
      // this.queryParams.pageNum = val;
      this.getList(val);
    },
    // input 规则
    changeInput: function changeInput(value) {
      console.log(value, 1);
      if (/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(value)) {
        return value;
      }
    },
    editDayAction: function editDayAction(i) {
      this.newDeliverDetail.condition[i].day = Math.ceil(this.newDeliverDetail.condition[i].day);
    },
    addRuleAction: function addRuleAction() {
      this.newDeliverDetail.condition.push({
        day: '',
        handlingFee: ''
      });
    },
    delRuleAction: function delRuleAction(i) {
      if (this.newDeliverDetail.condition[i]) {
        this.newDeliverDetail.condition.splice(i, 1);
      }
    },
    // input 规则
    changeInputNew: function changeInputNew(value) {
      if (/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(value)) {
        return value;
      }
    },
    editDayActionNew: function editDayActionNew(i) {
      this.newDeliverDetail.thirdPartyRule[i].day = Math.ceil(this.newDeliverDetail.thirdPartyRule[i].day);
    },
    addRuleActionNew: function addRuleActionNew() {
      this.newDeliverDetail.thirdPartyRule.push({
        day: '',
        handlingFee: ''
      });
    },
    delRuleActionNew: function delRuleActionNew(i) {
      if (this.newDeliverDetail.thirdPartyRule[i]) {
        this.newDeliverDetail.thirdPartyRule.splice(i, 1);
      }
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _nft.transferListApi)(this.queryParams).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    goSearch: function goSearch() {
      this.queryParams.keyword = this.keyword;
      this.queryParams.status = this.status;
      this.getList(1);
    },
    changeTab: function changeTab(val) {
      this.queryParams.type = val;
      this.getList(1);
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.queryParams.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.queryParams.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    addAction: function addAction() {
      this.newDeliverDetail = {
        show: true,
        name: '',
        id: '',
        isIncrease: true,
        condition: [{
          day: '',
          handlingFee: ''
        }],
        hasThirdParty: true,
        thirdPartyIsIncrease: true,
        thirdPartyRule: [{
          day: '',
          handlingFee: ''
        }]
      };
    },
    showDetailAction: function showDetailAction() {
      this.deliverDetail = {
        show: true,
        keyword: '',
        status: '',
        queryParams: {
          page: 1,
          limit: 10
        },
        listLoading: false,
        tableData: {
          data: [{
            img: 'https://saas.cdn.yunzongbu.cn/merchant/338807/2022426/image/1650954547314824622.jpg',
            name: '天降祥瑞宫猫',
            sdk: '443****480',
            price: 9999.01,
            fx: 100,
            sock: 200,
            type: '图片',
            status: 1,
            order_num: 1,
            admin: '晓琪'
          }],
          total: 0
        },
        rowCancel: null
      };
      this.loadUserAction(1);
    },
    goSearchUserAction: function goSearchUserAction() {
      this.loadUserAction(1);
    },
    loadUserAction: function loadUserAction(num) {},
    submitForm: function submitForm(formName) {
      var _this2 = this;
      var data = JSON.parse(JSON.stringify(this.newDeliverDetail));
      var isShow = data.condition.every(function (m, i) {
        if (m.handlingFee < 0) {
          return false;
        } else {
          return true;
        }
      });
      if (!isShow) {
        this.$message({
          type: 'warning',
          message: '金额输入格式不正确'
        });
        return;
      }
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var _data = JSON.parse(JSON.stringify(_this2.newDeliverDetail));
          _data.condition.forEach(function (m, i) {
            m.count = i + 1;
            if (!m.day) m.day = 0;
            if (!m.handlingFee) m.handlingFee = 0;
          });
          _data.thirdPartyRule.forEach(function (m, i) {
            m.count = i + 1;
            if (!m.day) m.day = 0;
            if (!m.handlingFee) m.handlingFee = 0;
          });
          var action = _nft.transferCreateApi;
          var submitData = {
            name: _data.name,
            rule: _data.condition,
            isIncrease: _data.isIncrease,
            thirdPartyRule: _data.thirdPartyRule,
            hasThirdParty: _data.hasThirdParty,
            thirdPartyIsIncrease: _data.thirdPartyIsIncrease
          };
          if (_data.id) {
            action = _nft.transferUpdateApi;
            submitData.id = _data.id;
          }
          action(submitData).then(function () {
            _this2.$message.success('操作成功');
            _this2.newDeliverDetail.show = false;
            _this2.getList(1);
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    delAction: function delAction(id) {
      var _this3 = this;
      this.$confirm('确认删除此规则？', '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      }).then(function () {
        (0, _nft.transferDelApi)(id).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList();
        });
      }).catch(function (_ref) {
        var message = _ref.message;
      });
    },
    cancel: function cancel() {
      // this.newDeliverDetail = this.rowCancel;
      // const row = this.rowCancel
      // this.newDeliverDetail = {
      //   show: true,
      //   name: row.name,
      //   id: row.id,
      //   condition: row.rule,
      //   isIncrease: row.isIncrease || false
      // };
      this.newDeliverDetail.show = false;
    },
    editAction: function editAction(row, index) {
      console.log(row, index, 309);
      // console.log(this.tableData.data[index]);
      this.rowCancel = JSON.parse(JSON.stringify(row));
      console.log(this.rowCancel, 320);
      this.newDeliverDetail = {
        show: true,
        name: '',
        id: '',
        isIncrease: true,
        condition: [{
          day: '',
          handlingFee: ''
        }],
        hasThirdParty: true,
        thirdPartyIsIncrease: true,
        thirdPartyRule: [{
          day: '',
          handlingFee: ''
        }]
      };
      this.dialogTitle = '编辑转赠规则';
      this.newDeliverDetail = {
        show: true,
        name: this.rowCancel.name,
        id: this.rowCancel.id,
        condition: this.rowCancel.rule,
        isIncrease: this.rowCancel.isIncrease || false,
        hasThirdParty: this.rowCancel.hasThirdParty,
        thirdPartyIsIncrease: this.rowCancel.thirdPartyIsIncrease || false,
        thirdPartyRule: this.rowCancel.thirdPartyRule || false
      };
    }
  }
};