var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { margin: "20px 0" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.create } },
            [_vm._v(" 添加 ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "small",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", "min-width": "160" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "身份名称", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [_vm._v(_vm._s(row.identityName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "排序", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.sort))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "是否审核", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(_vm._s(row.toExamine ? "是" : "否")),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "身份资料", "min-width": "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.identityInformationList
                                    ? row.identityInformationList.join(",")
                                    : ""
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "identityLimit",
                      label: "身份权限",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "100",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _vm._v(" "),
                            _vm._l(scope.row.type, function (item) {
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.lookSetting(scope.row, item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "设置" +
                                      _vm._s(item == 1 ? "个人" : "企业") +
                                      "资料"
                                  ),
                                ]
                              )
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block mt20" },
                [
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableFrom.pageSize,
                      "current-page": _vm.tableFrom.pageNum,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleIden,
                "close-on-click-modal": false,
                center: "",
                title: _vm.title,
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleIden = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "addUserForm",
                  attrs: {
                    size: "small",
                    model: _vm.addUserForm,
                    rules: _vm.userRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份名称", prop: "identityName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addUserForm.identityName,
                          callback: function ($$v) {
                            _vm.$set(_vm.addUserForm, "identityName", $$v)
                          },
                          expression: "addUserForm.identityName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addUserForm.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.addUserForm, "sort", $$v)
                          },
                          expression: "addUserForm.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份类型", prop: "type" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.addUserForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.addUserForm, "type", $$v)
                            },
                            expression: "addUserForm.type",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: 2 } }, [
                            _vm._v("企业"),
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: 1 } }, [
                            _vm._v("个人"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份审核", prop: "toExamine" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.addUserForm.toExamine,
                          callback: function ($$v) {
                            _vm.$set(_vm.addUserForm, "toExamine", $$v)
                          },
                          expression: "addUserForm.toExamine",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmUserAction("addUserForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置身份资料",
            visible: _vm.settingflag,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.settingflag = $event
            },
          },
        },
        [
          _c("div", { staticClass: "setting-box" }, [
            _c(
              "div",
              { staticClass: "setting-left" },
              _vm._l(_vm.settingLeftList, function (item, index) {
                return _vm.settingflag
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "setting-left-title" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(item.name) +
                              "\n                      "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          item.informationSetList,
                          function (nitem, nindex) {
                            return _c(
                              "div",
                              { staticClass: "setting-left-list" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      checked: _vm.settingKeyArr.includes(
                                        nitem.key
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeSetting($event, nitem)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(nitem.name))]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e()
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "setting-right" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.settingRightList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "已选字段", prop: "name" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "定义名称" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入定义名称",
                                  size: "normal",
                                  clearable: "",
                                },
                                model: {
                                  value: scope.row.customName,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "customName", $$v)
                                  },
                                  expression: "scope.row.customName",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "必选" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: scope.row.isMust,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "isMust", $$v)
                                  },
                                  expression: "scope.row.isMust",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "排序" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input-number", {
                                staticStyle: { width: "100px" },
                                on: {
                                  change: function ($event) {
                                    return _vm.changesort($event, scope.$index)
                                  },
                                },
                                model: {
                                  value: scope.row.sort,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "sort", $$v)
                                  },
                                  expression: "scope.row.sort",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.savesetting },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }