var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", _vm._b({}, "el-col", _vm.grid, false), [
            _c("div", { staticClass: "Nav" }, [
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "选择分类",
                      "prefix-icon": "el-icon-search",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "trees-coadd" }, [
                _c("div", { staticClass: "scollhide" }, [
                  _c(
                    "div",
                    { staticClass: "trees" },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.treeData2,
                          "filter-node-method": _vm.filterNode,
                          props: _vm.defaultProps,
                          "highlight-current": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "div",
                                {
                                  staticClass: "custom-tree-node",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleNodeClick(data)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex-one" }, [
                                    _c("span", [_vm._v(_vm._s(node.label))]),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "el-ic" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-plus-outline",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onAdd(data)
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    data.space_id != "0" &&
                                    (!data.children ||
                                      data.children == "undefined") &&
                                    data.id
                                      ? _c("i", {
                                          staticClass: "el-icon-edit",
                                          attrs: { title: "修改" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onEdit(data)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    data.space_id != "0" &&
                                    (!data.children ||
                                      data.children == "undefined") &&
                                    data.id
                                      ? _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { title: "删除分类" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return (function () {
                                                return _vm.handleDelete(data.id)
                                              })($event)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            _vm._b({ staticClass: "colLeft" }, "el-col", _vm.grid2, false),
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "conter",
                },
                [
                  _c(
                    "div",
                    { staticClass: "bnt" },
                    [
                      _vm.params !== "/merchant/config/picture" &&
                      !_vm.changeCategory
                        ? _c(
                            "el-button",
                            {
                              staticClass: "mb10 mr10",
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.checkPics },
                            },
                            [_vm._v("使用选中图片")]
                          )
                        : _vm.params !== "/merchant/config/picture" &&
                          _vm.changeCategory
                        ? _c(
                            "el-button",
                            {
                              staticClass: "mb10 mr10",
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.checkCategory },
                            },
                            [_vm._v("使用选中分类")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          ref: "uploadImg",
                          staticClass: "upload-demo mr10 mb15",
                          attrs: {
                            action: _vm.fileUrl,
                            "on-success": _vm.handleSuccess,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                            "http-request": _vm.modeUpload,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", size: "small" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onAdd()
                            },
                          },
                        },
                        [_vm._v("添加分类")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "error", size: "small" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.selectAll($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.selectItem.length ==
                                _vm.pictrueList.list.length
                                ? "取消"
                                : "全选"
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          attrs: {
                            type: "error",
                            size: "small",
                            disabled: _vm.checkPicList.length === 0,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.editPicList("图片")
                            },
                          },
                        },
                        [_vm._v("删除图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请输入图片名称搜索" },
                          on: {
                            change: function ($event) {
                              return _vm.getFileList(1)
                            },
                          },
                          model: {
                            value: _vm.tableData.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableData, "name", $$v)
                            },
                            expression: "tableData.name",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getFileList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "mb15",
                          attrs: { placeholder: "图片移动至", size: "small" },
                          model: {
                            value: _vm.sleOptions.label,
                            callback: function ($$v) {
                              _vm.$set(_vm.sleOptions, "label", $$v)
                            },
                            expression: "sleOptions.label",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            {
                              staticStyle: {
                                "max-width": "560px",
                                height: "200px",
                                overflow: "auto",
                                "background-color": "#fff",
                              },
                              attrs: {
                                label: _vm.sleOptions.label,
                                value: _vm.sleOptions.id,
                              },
                            },
                            [
                              _c("el-tree", {
                                ref: "tree2",
                                attrs: {
                                  data: _vm.treeData2,
                                  "filter-node-method": _vm.filterNode,
                                  props: _vm.defaultProps,
                                  "highlight-current": "",
                                },
                                on: { "node-click": _vm.handleSelClick },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "pictrueList acea-row mb15" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowPic,
                            expression: "isShowPic",
                          },
                        ],
                        staticClass: "imagesNo",
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-picture",
                          staticStyle: {
                            "font-size": "60px",
                            color: "rgb(219, 219, 219)",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "imagesNo_sp" }, [
                          _vm._v("图片库为空"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "conters" },
                      _vm._l(_vm.pictrueList.list, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "gridPic" },
                          [
                            item.num > 0
                              ? _c(
                                  "p",
                                  { staticClass: "number" },
                                  [
                                    _c(
                                      "el-badge",
                                      {
                                        staticClass: "item",
                                        attrs: { value: item.num },
                                      },
                                      [
                                        _c("a", {
                                          staticClass: "demo-badge",
                                          attrs: { href: "#" },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === 1
                              ? _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value:
                                        item.url +
                                        (item.url.indexOf(".ico") == -1
                                          ? "!120a"
                                          : ""),
                                      expression:
                                        "\n                  item.url + (item.url.indexOf('.ico') == -1 ? '!120a' : '')\n                ",
                                    },
                                  ],
                                  class: item.isSelect ? "on" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changImage(
                                        item,
                                        index,
                                        _vm.pictrueList.list
                                      )
                                    },
                                  },
                                })
                              : item.type === 2
                              ? _c("video", {
                                  class: item.isSelect ? "on" : "",
                                  attrs: { src: item.url },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changImage(
                                        item,
                                        index,
                                        _vm.pictrueList.list
                                      )
                                    },
                                  },
                                })
                              : item.type === 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item-flex-center",
                                    class: item.isSelect ? "on" : "",
                                    staticStyle: {
                                      background: "#f7f7f7",
                                      width: "100%",
                                      height: "110px",
                                    },
                                    attrs: { src: item.url },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changImage(
                                          item,
                                          index,
                                          _vm.pictrueList.list
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "30px",
                                        height: "30px",
                                      },
                                      attrs: {
                                        src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2024720/image/1721446143117813920.png!120a",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === 2
                              ? _c("div", { staticClass: "bofang" })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between",
                                },
                              },
                              [
                                _vm.editId === item.id
                                  ? _c("el-input", {
                                      model: {
                                        value: item.name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name",
                                      },
                                    })
                                  : _c(
                                      "p",
                                      {
                                        staticClass: "name",
                                        staticStyle: { width: "80%" },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                _vm._v(" "),
                                _vm.editId !== item.id
                                  ? _c("i", {
                                      staticClass: "el-icon-edit",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(
                                            item.id,
                                            item.name
                                          )
                                        },
                                      },
                                    })
                                  : _c("i", {
                                      staticClass: "el-icon-check",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(
                                            item.id,
                                            item.name
                                          )
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-sizes": [12, 20, 40, 60, 100],
                          "page-size": _vm.tableData.pageSize,
                          "current-page": _vm.tableData.pageNum,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.pictrueList.total,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.pageChange,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editCateData.show,
            width: "500px",
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.editCateData.id ? "修改分类" : "添加分类",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editCateData, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: { model: _vm.editCateData, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级分类" } },
                [
                  _c("el-cascader", {
                    staticClass: "w300",
                    attrs: {
                      options: _vm.treeData,
                      props: {
                        expandTrigger: "hover",
                        checkStrictly: true,
                        value: "id",
                      },
                    },
                    model: {
                      value: _vm.editCateData.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCateData, "parentId", $$v)
                      },
                      expression: "editCateData.parentId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.editCateData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCateData, "name", $$v)
                      },
                      expression: "editCateData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.editCateData.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCateData, "sort", $$v)
                      },
                      expression: "editCateData.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveCategory },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }