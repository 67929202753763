var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 7
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index === 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                _c("div", { staticClass: "content-main" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "70%" } },
                    [
                      _c(
                        "el-image",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { src: item.image, fit: "cover" },
                          on: { error: _vm.error },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { width: "25%", "overflow-y": "scroll" } },
                    [
                      _c(
                        "el-image",
                        {
                          staticStyle: {
                            height: "60px",
                            "margin-bottom": "10px",
                          },
                          attrs: { src: item.image, fit: "cover" },
                          on: { error: _vm.error },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-image",
                        {
                          staticStyle: {
                            height: "60px",
                            "margin-bottom": "10px",
                          },
                          attrs: { src: item.image, fit: "cover" },
                          on: { error: _vm.error },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-image",
                        {
                          staticStyle: {
                            height: "60px",
                            "margin-bottom": "10px",
                          },
                          attrs: { src: item.image, fit: "cover" },
                          on: { error: _vm.error },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "text color-black",
                    style:
                      "color: " +
                      _vm.facade.name_color +
                      ";font-size: " +
                      _vm.facade.text_size / 2 +
                      "px;font-weight: " +
                      _vm.facade.text_style +
                      ";",
                  },
                  [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tags-box" },
                  _vm._l(item.tags, function (m, i) {
                    return _c("div", { key: i, staticClass: "tag" }, [
                      _vm._v(_vm._s(m)),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "bottom-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "sign",
                      style:
                        "color: " +
                        _vm.facade.time_color +
                        ";font-size: " +
                        _vm.facade.time_text_size / 2 +
                        "px;font-weight: " +
                        _vm.facade.time_text_style +
                        ";",
                    },
                    [_vm._v("\n          " + _vm._s(item.sign) + "\n        ")]
                  ),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }