"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _orderDetail = _interopRequireDefault(require("./orderDetail.vue"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _saasApi = require("@/api/saasApi");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    orderDetail: _orderDetail.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _utils.formatPriceNumber,
      //小数点
      showdetail: false,
      uid: "",
      productList: [],
      //产品列表
      timeType: "",
      statistical: {},
      //订单数量
      queryParams: {
        status: "",
        timeType: "",
        associationId: "",
        pageSize: 10,
        pageNum: 1
      },
      //表格 分页
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      drawerVisib: false
    };
  },
  created: function created() {
    var _this = this;
    (0, _saasApi.productPackage)().then(function (res) {
      return _this.productList = res.rows;
    });
    this.getTableList();
  },
  mounted: function mounted() {},
  methods: {
    getTableList: function getTableList(num) {
      var _this2 = this;
      if (num) this.queryParams.pageNum = num;
      (0, _saasApi.saasOrderList)((0, _objectSpread2.default)({}, this.queryParams)).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.statistical = res.statistical;
      });
    },
    onchangeTime: function onchangeTime(e) {
      this.queryParams.timeType = e.join("-");
      this.getTableList(1);
    },
    addOrder: function addOrder() {
      this.drawerVisib = true;
    },
    changeDrawerVisib: function changeDrawerVisib(val) {
      this.drawerVisib = val;
      this.getTableList(1);
    },
    onCancel: function onCancel(_ref) {
      var _this3 = this;
      var id = _ref.id;
      this.$confirm("确定取消此订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _saasApi.saasCloseOrder)(id).then(function (res) {
          _this3.$message.success("操作成功");
          _this3.getTableList(1);
        });
      });
    },
    pageChange: function pageChange(val) {
      this.getTableList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getTableList(1);
    },
    openUserDetail: function openUserDetail(uid) {
      this.uid = uid;
      this.showdetail = true;
    }
  },
  filters: {
    formatStatus: function formatStatus(val) {
      var res = "";
      switch (val) {
        case -3:
          res = "售后中";
          break;
        case -2:
          res = "已退款";
          break;
        case -1:
          res = "已取消";
          break;
        case 0:
          res = "待付款";
          break;
        case 5:
          res = "待审核";
          break;
        case 2:
          res = "支付审核中";
          break;
        case 3:
          res = "待交付";
          break;
        case 4:
          res = "已完成";
          break;
        default:
          break;
      }
      return res;
    }
  }
};