"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createIdentity = createIdentity;
exports.deleteIdentity = deleteIdentity;
exports.editIdentity = editIdentity;
exports.getIdenList = getIdenList;
exports.getIdenSettingDetail = getIdenSettingDetail;
exports.getList = getList;
exports.getMerchantList = getMerchantList;
exports.getReviewDetail = getReviewDetail;
exports.getReviewList = getReviewList;
exports.loginMerchant = loginMerchant;
exports.reviewMerchant = reviewMerchant;
exports.setIdenSettingDetail = setIdenSettingDetail;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 获取身份列表
 */
function getIdenList(data) {
  return _request.default.get("/open3d/member/sys/member/identity/set/getInfoList", data);
}
/**
 * @description 获取列表
 */
function getList(data) {
  return _request.default.get("/open3d/member/sys/member/identity/set/getList", data);
}
/**
 * @description 新增身份设置
 */
function createIdentity(data) {
  return _request.default.post("/open3d/member/sys/member/identity/set/create", data);
}
/**
 * @description 编辑身份设置
 */
function editIdentity(data) {
  return _request.default.put("/open3d/member/sys/member/identity/set/update", data);
}
/**
 * @description 删除身份设置
 */
function deleteIdentity(data) {
  return _request.default.delete("/open3d/member/sys/member", data);
}
/**
 * @description 获取身份设置
 */
function getIdenSettingDetail(id, data) {
  return _request.default.get("/open3d/member/sys/member/identity/set/getInformation/".concat(id), data);
}
/**
 * @description 修改身份设置
 */
function setIdenSettingDetail(data) {
  return _request.default.post("/open3d/member/sys/member/identity/set/setInformation", data);
}
/**
 * @description 获取审核列表
 */
function getReviewList(data) {
  return _request.default.get("/open3d/member/sys/member/application/examine/getList", data);
}
/**
 * @description 获取审核商户
 */
function reviewMerchant(data) {
  return _request.default.post("/open3d/member/sys/member/application/examine/check", data);
}
function getReviewDetail(id) {
  return _request.default.get("/open3d/member/sys/member/application/examine/info/".concat(id));
}
function getMerchantList(data) {
  return _request.default.get("/open3d/member/sys/member/merchant/getList", data);
}
function loginMerchant(id) {
  return _request.default.post("/open3d/member/sys/member/merchant/former/login/".concat(id), {});
}