var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "padding-top": "0" } },
    [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("标题栏")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("Title", { attrs: { "header-setting": _vm.title } }),
            _vm._v(" "),
            _c("rightBtn", {
              on: {
                showset: function ($event) {
                  return _vm.showSetAction("title")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("底部导航")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "div",
              {
                staticClass: "footer",
                style: {
                  background: _vm.footer_data.footerSetting.footer_background,
                  color: _vm.footer_data.footerSetting.footer_text_color,
                  fontSize:
                    _vm.footer_data.footerSetting.footer_text_size / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "footer-view item-align-center justify-between",
                    style: {
                      padding:
                        "0 " +
                        _vm.footer_data.footerSetting.footer_margin / 2 +
                        "px",
                    },
                  },
                  _vm._l(
                    _vm.footer_data.footerSetting.content,
                    function (m, i) {
                      return _c("div", { key: i, staticClass: "footer-item" }, [
                        _c("div", { staticClass: "img" }, [
                          m.url
                            ? _c("img", {
                                class: {
                                  tuchu:
                                    _vm.footer_data.footerSetting
                                      .footer_type === 2 &&
                                    parseInt(
                                      _vm.footer_data.footerSetting.content
                                        .length / 2,
                                      10
                                    ) === i,
                                },
                                attrs: { src: m.url + "!120a", alt: "" },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "no-img item-flex-center",
                                  class: {
                                    tuchu:
                                      _vm.footer_data.footerSetting
                                        .footer_type === 2 &&
                                      parseInt(
                                        _vm.footer_data.footerSetting.content
                                          .length / 2,
                                        10
                                      ) === i,
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item-txt",
                            style: {
                              color:
                                i === 0
                                  ? _vm.footer_data.footerSetting
                                      .footer_active_color
                                  : _vm.footer_data.footerSetting
                                      .footer_text_color,
                            },
                          },
                          [_vm._v("\n              " + _vm._s(m.title))]
                        ),
                      ])
                    }
                  ),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "hengtiao" }),
              ]
            ),
            _vm._v(" "),
            _c("rightBtn", {
              on: {
                showset: function ($event) {
                  return _vm.showSetAction("footer_data")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("按钮")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("Button", { attrs: { facade: _vm.button } }),
            _vm._v(" "),
            _c("rightBtn", {
              on: {
                showset: function ($event) {
                  return _vm.showSetAction("button")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("轮播图")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("Banner", { attrs: { facade: _vm.banner } }),
            _vm._v(" "),
            _c("rightBtn", {
              on: {
                showset: function ($event) {
                  return _vm.showSetAction("banner")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("图片圆角设置")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("Img", { attrs: { facade: _vm.img } }),
            _vm._v(" "),
            _c("rightBtn", {
              on: {
                showset: function ($event) {
                  return _vm.showSetAction("img")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.showSet,
            "append-to-body": true,
            "show-close": false,
            "with-header": _vm.setKey !== "footer_data",
            "before-close": _vm.handleClose,
            size: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSet = $event
            },
          },
        },
        [
          _vm.setKey == "footer_data"
            ? [
                _vm.showSet
                  ? _c("footerAttribute", {
                      model: {
                        value: _vm.settingData,
                        callback: function ($$v) {
                          _vm.settingData = $$v
                        },
                        expression: "settingData",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "b flex align-items-c justify-c" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.saveAction(_vm.setKey)
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.handleClose } }, [
                      _vm._v("关闭"),
                    ]),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  {
                    staticClass: "set-header flex align-items-c justify-b",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("div", [_vm._v(_vm._s(_vm.setTitle))]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20220109/d4ca9a0e7cf99adaee8e544a9935d008.png",
                        alt: "",
                      },
                      on: { click: _vm.handleClose },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "set-content", style: _vm.scrollHeight },
                  [
                    _vm.modules[_vm.setKey]
                      ? _c(
                          _vm.modules[_vm.setKey].attribute,
                          {
                            tag: "component",
                            model: {
                              value: _vm.settingData,
                              callback: function ($$v) {
                                _vm.settingData = $$v
                              },
                              expression: "settingData",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "b flex align-items-c justify-c" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveAction(_vm.setKey)
                                      },
                                    },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  { on: { click: _vm.handleClose } },
                                  [_vm._v("关闭")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }