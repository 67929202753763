"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderMemberApi = OrderMemberApi;
exports.deleteOrderApi = deleteOrderApi;
exports.listApi = listApi;
exports.orderApi = orderApi;
exports.startApi = startApi;
var _request = _interopRequireDefault(require("./request"));
function OrderMemberApi(id) {
  return _request.default.post("member/sys/member/info/isLockOrderMember/".concat(id));
}
function listApi(data) {
  return _request.default.get("market/sys/lock/order/list", data);
}
function orderApi(data) {
  return _request.default.post("market/sys/lock/order", data);
}
function deleteOrderApi(id) {
  return _request.default.delete("market/sys/lock/order/".concat(id));
}
function startApi(data) {
  return _request.default.post("market/sys/lock/order/start", data);
}