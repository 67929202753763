"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "350px"
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler() {
        this.initChart();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  methods: {
    initChart: function initChart() {
      var myChart = this.$echarts.init(document.getElementById('transactionLineTwo'));
      myChart.setOption(this.chartData);
    }
  }
};