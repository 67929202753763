var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "type", $$v)
                            },
                            expression: "tableFrom.type",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v(
                              "全部 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.all
                                    ? _vm.orderChartType.all
                                    : 0 + ")"
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v(
                              "未开盒 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.waitOpenCount
                                    ? _vm.orderChartType.waitOpenCount
                                    : 0 + ")"
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v(
                              "已开盒 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.overOpenCount
                                    ? _vm.orderChartType.overOpenCount
                                    : 0 + ")"
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "3" } }, [
                            _vm._v(
                              "待提货 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.waitReceiveCount
                                    ? _vm.orderChartType.waitReceiveCount
                                    : 0 + ")"
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "4" } }, [
                            _vm._v(
                              "已提货 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.overReceiveCount
                                    ? _vm.orderChartType.overReceiveCount
                                    : 0 + ")"
                                )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "时间选择：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange(_vm.tableFrom.date)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "date", $$v)
                            },
                            expression: "tableFrom.date",
                          },
                        },
                        _vm._l(_vm.fromList.fromTxt, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text))]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "value-format": "yyyy/MM/dd",
                          format: "yyyy/MM/dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "自定义时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "order-table table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            "highlight-current-row": "",
            "cell-class-name": _vm.addTdClass,
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "sort-change": _vm.changeSortAction,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "订单编号",
              "min-width": "150",
              prop: "receiveOrderInfo.order_sn",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "盲盒信息", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "tabBox acea-row row-middle",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.blindBoxProduct.image + "!120a",
                                "preview-src-list": [
                                  scope.row.blindBoxProduct.image,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { flex: "1", "margin-left": "8px" } },
                          [
                            _c("div", [
                              _vm._v(
                                "盲盒名称：" +
                                  _vm._s(scope.row.blindBoxProduct.store_name)
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "货品信息", "min-width": "330" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.product
                      ? _c(
                          "div",
                          { staticClass: "tabBox acea-row row-middle" },
                          [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: scope.row.product.image + "!120a",
                                    "preview-src-list": [
                                      scope.row.product.image,
                                    ],
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "tabBox_tit" }, [
                              _vm._v(_vm._s(scope.row.product.store_name)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "real_name", label: "用户名", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.user
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(scope.row.uid)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.user.real_name ||
                                  scope.row.user.nickname
                              )
                            ),
                            scope.row.user.real_name
                              ? _c("span", [
                                  _vm._v(
                                    "(" + _vm._s(scope.row.user.nickname) + ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "user.phone", label: "手机号", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "下单时间",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "pay_time", label: "拆盒状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.is_open == 1
                      ? _c(
                          "span",
                          { staticStyle: { color: "rgb(153, 153, 153)" } },
                          [_vm._v("已拆盒")]
                        )
                      : _c(
                          "span",
                          { staticStyle: { color: "rgb(238, 0, 0)" } },
                          [_vm._v("未拆盒")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "pay_time", label: "提货状态", "min-width": "130" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.is_open == 1
                      ? [
                          scope.row.receive_time
                            ? _c(
                                "span",
                                { staticStyle: { color: "rgb(34, 187, 102)" } },
                                [_vm._v("已提货")]
                              )
                            : _c(
                                "span",
                                { staticStyle: { color: "rgb(238, 0, 0)" } },
                                [_vm._v("待提货")]
                              ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "8",
            attrs: {
              label: "操作",
              "min-width": "150",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.receiveOrderInfo
                      ? [
                          scope.row.receiveOrderInfo.status === 1
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "mr10",
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.send(
                                        scope.row.receiveOrderInfo.order_id,
                                        scope.row.receiveOrderInfo.order_sn
                                      )
                                    },
                                  },
                                },
                                [_vm._v("发货")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.receiveOrderInfo.status > 1
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "mr10",
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onOrderDetails(
                                        scope.row.receiveOrderInfo.order_id,
                                        scope.row.receiveOrderInfo.order_sn
                                      )
                                    },
                                  },
                                },
                                [_vm._v("订单详情")]
                              )
                            : _vm._e(),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "order_id",
                  align: "center",
                  label: "订单ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_message",
                  label: "操作记录",
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_time",
                  label: "操作时间",
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改订单",
            visible: _vm.editVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              attrs: { model: _vm.formValidate, "label-width": "120px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单总价：" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "请输入订单总价" },
                    on: { change: _vm.changePrice },
                    model: {
                      value: _vm.formValidate.total_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "total_price", $$v)
                      },
                      expression: "formValidate.total_price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实际支付邮费：" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "请输入订单油费" },
                    on: { change: _vm.changePrice },
                    model: {
                      value: _vm.formValidate.pay_postage,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "pay_postage", $$v)
                      },
                      expression: "formValidate.pay_postage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "优惠金额" } }, [
                _c("span", [_vm._v(_vm._s(_vm.formValidate.coupon_price))]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "实际支付金额：" } }, [
                _c("span", [_vm._v(_vm._s(_vm.formValidate.pay_price))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editConfirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", { ref: "orderDetail" }),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }