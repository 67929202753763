var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox open3d" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              staticStyle: { "margin-top": "-10px" },
              on: { "tab-click": _vm.changePageAction },
              model: {
                value: _vm.types,
                callback: function ($$v) {
                  _vm.types = $$v
                },
                expression: "types",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "作品发行", name: "works" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "版权发行", name: "album" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              staticClass: "filter",
              attrs: {
                size: "small",
                "label-width": "85px",
                inline: true,
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作品类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: { change: _vm.changeOneCategoryAction },
                      model: {
                        value: _vm.categoryIndex,
                        callback: function ($$v) {
                          _vm.categoryIndex = $$v
                        },
                        expression: "categoryIndex",
                      },
                    },
                    [
                      _vm._l(_vm.categoryList, function (m, i) {
                        return [
                          m.type == 10
                            ? [
                                _c(
                                  "el-radio-button",
                                  { key: i, attrs: { label: i } },
                                  [_vm._v(_vm._s(m.label))]
                                ),
                              ]
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "ml40", attrs: { label: "文件类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.fileType,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "fileType", $$v)
                        },
                        expression: "tableFrom.fileType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("3D模型"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("图片"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("视频"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "4" } }, [
                        _vm._v("音频"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.categoryList && _vm.categoryList.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.categoryList[_vm.categoryIndex].id == 1
                                ? "适用行业："
                                : "表现风格：",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "medium" },
                              on: { change: _vm.changeTwoCategoryAction },
                              model: {
                                value: _vm.tableFrom.levelTwoId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "levelTwoId", $$v)
                                },
                                expression: "tableFrom.levelTwoId",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.categoryList[_vm.categoryIndex].children,
                                function (m, i) {
                                  return [
                                    m.type == 10
                                      ? [
                                          _c(
                                            "el-radio-button",
                                            {
                                              key: "children" + i,
                                              attrs: { label: m.id },
                                            },
                                            [_vm._v(_vm._s(m.label))]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.attrList && _vm.attrList.length > 0
                ? _vm._l(_vm.attrList, function (m, i) {
                    return _c(
                      "div",
                      {
                        key: "attrList" + i,
                        staticClass: "attr-change-new flex",
                      },
                      [
                        _c("div", { staticClass: "l" }, [
                          _vm._v(_vm._s(m.attrName) + "："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "r flex flex-wrap" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "item",
                                class: { curr: !_vm.changeAttr[i] },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearAttrAction(i)
                                  },
                                },
                              },
                              [_vm._v("全部")]
                            ),
                            _vm._v(" "),
                            _vm._l(m.sysAttrValueList, function (item, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: "sysAttrValueList" + i + index,
                                    staticClass: "item",
                                    class: {
                                      curr:
                                        _vm.changeAttr[i] &&
                                        _vm.changeAttr[i].attrValueIds.indexOf(
                                          item.id
                                        ) != -1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeAttrAction(item, i)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.attrValueName))]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "作品价格：" } }, [
                _c(
                  "div",
                  { staticClass: "inp flex align-items-c" },
                  [
                    _c("el-input-number", {
                      staticClass: "w60",
                      attrs: { size: "medium", controls: false },
                      model: {
                        value: _vm.tableFrom.priceMin,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "priceMin", $$v)
                        },
                        expression: "tableFrom.priceMin",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "zj" }, [_vm._v("￥ 至")]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      staticClass: "w60",
                      attrs: { size: "medium", controls: false },
                      model: {
                        value: _vm.tableFrom.priceMax,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "priceMax", $$v)
                        },
                        expression: "tableFrom.priceMax",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "zj" }, [_vm._v("￥")]),
                    _vm._v(" "),
                    _c("el-button", {
                      attrs: { icon: "el-icon-search", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.categoryList[_vm.categoryIndex]
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.categoryList[_vm.categoryIndex]
                          ? _vm.categoryList[_vm.categoryIndex].size.name + "："
                          : " ",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "inp flex align-items-c" },
                        [
                          _c("el-input-number", {
                            staticClass: "w60",
                            attrs: { size: "medium", controls: false },
                            model: {
                              value: _vm.tableFrom.sizeMin,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "sizeMin", $$v)
                              },
                              expression: "tableFrom.sizeMin",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "zj" }, [
                            _vm._v(
                              _vm._s(
                                _vm.categoryList[_vm.categoryIndex] &&
                                  _vm.categoryList[_vm.categoryIndex].size
                                    .unit == "平方米"
                                  ? "m²"
                                  : _vm.categoryList[_vm.categoryIndex].size
                                      .unit
                              ) + " 至"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w60",
                            attrs: { size: "medium", controls: false },
                            model: {
                              value: _vm.tableFrom.sizeMax,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "sizeMax", $$v)
                              },
                              expression: "tableFrom.sizeMax",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "zj" }, [
                            _vm._v(
                              _vm._s(
                                _vm.categoryList[_vm.categoryIndex] &&
                                  _vm.categoryList[_vm.categoryIndex].size
                                    .unit == "平方米"
                                  ? "m²"
                                  : _vm.categoryList[_vm.categoryIndex].size
                                      .unit
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-button", {
                            attrs: { icon: "el-icon-search", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "推荐状态", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.isRecommend,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "isRecommend", $$v)
                    },
                    expression: "tableFrom.isRecommend",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 1, label: "推荐" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 0, label: "不推荐" } }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div", {
                staticStyle: { display: "inline-block", width: "80px" },
              }),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "作品名称", size: "medium" },
                  model: {
                    value: _vm.tableFrom.storeName,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "storeName", $$v)
                    },
                    expression: "tableFrom.storeName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200 ml10",
                  attrs: { placeholder: "作品哈希", size: "medium" },
                  model: {
                    value: _vm.tableFrom.fileHash,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "fileHash", $$v)
                    },
                    expression: "tableFrom.fileHash",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200 ml10",
                  attrs: { placeholder: "作者名称", size: "medium" },
                  model: {
                    value: _vm.tableFrom.authorName,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "authorName", $$v)
                    },
                    expression: "tableFrom.authorName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200 ml10",
                  attrs: { placeholder: "发行机构名称", size: "medium" },
                  model: {
                    value: _vm.tableFrom.issuerName,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "issuerName", $$v)
                    },
                    expression: "tableFrom.issuerName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { width: "45" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(
                                      _vm.tableFrom.pageNum
                                    ) > -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.id) === -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", "min-width": "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.id))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品类型", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.cateAttrInfo.modelTypeName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品预览", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "demo-image__preview item-flex-center",
                          },
                          [
                            _c("el-image", {
                              attrs: {
                                src: row.coverPicture,
                                fit: "contain",
                                "preview-src-list": [row.coverPicture],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "文件格式", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.fileType == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showVideoAction(row)
                                  },
                                },
                              },
                              [_vm._v("图片")]
                            )
                          : row.fileType == 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showVideoAction(row)
                                  },
                                },
                              },
                              [_vm._v("3D")]
                            )
                          : row.fileType == 3
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showVideoAction(row)
                                  },
                                },
                              },
                              [_vm._v("视频")]
                            )
                          : row.fileType == 4
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showVideoAction(row)
                                  },
                                },
                              },
                              [_vm._v("音频")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "二级分类", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.cateAttrInfo.categoryName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "作品属性",
                  "min-width": "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.cateAttrInfo &&
                        row.cateAttrInfo.attrs &&
                        row.cateAttrInfo.attrs.length
                          ? _vm._l(row.cateAttrInfo.attrs, function (m, i) {
                              return _c(
                                "div",
                                {
                                  key: "attr" + i,
                                  staticClass: "cur",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDetailAction(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(m.attrName) +
                                      ":" +
                                      _vm._s(m.attrValueNames.join("、"))
                                  ),
                                ]
                              )
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品名称", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.storeName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "链类型", "min-width": "90", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.blockchainType))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "作品哈希",
                  "min-width": "170",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.hash))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "发售价格",
                  "min-width": "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.price))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.isIssuance && row.isShow
                          ? _c(
                              "div",
                              {
                                staticClass: "color-lv cur",
                                on: {
                                  click: function ($event) {
                                    return _vm.showDetailAction(row)
                                  },
                                },
                              },
                              [_vm._v("已发行")]
                            )
                          : row.isIssuance
                          ? _c(
                              "div",
                              {
                                staticClass: "cur",
                                on: {
                                  click: function ($event) {
                                    return _vm.showDetailAction(row)
                                  },
                                },
                              },
                              [_vm._v("已下架")]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "color-gray cur",
                                on: {
                                  click: function ($event) {
                                    return _vm.showDetailAction(row)
                                  },
                                },
                              },
                              [_vm._v("未发行")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "排序", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "100px" },
                          attrs: { min: 0, controls: false },
                          on: {
                            change: function (val) {
                              return _vm.editSortAction(val, scope.$index)
                            },
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发行方", "min-width": "160", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.issuerName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作者", "min-width": "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row.authorMemberId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.authorName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "版权方", "min-width": "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row.userId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.userName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  "min-width": "170",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.createTime))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建人", "min-width": "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showDetailAction(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.createBy))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "120",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.goIssueAction(row.nftId)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "3px" },
                            attrs: { type: "text", size: "mini" },
                          },
                          [_vm._v("链接")]
                        ),
                        _vm._v(" "),
                        row.hasRecommend
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "3px" },
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.nftCollectionListCheckApiFun(4, [
                                      row.id,
                                    ])
                                  },
                                },
                              },
                              [_vm._v("取推")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "3px" },
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.nftCollectionListCheckApiFun(3, [
                                      row.id,
                                    ])
                                  },
                                },
                              },
                              [_vm._v("推荐")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block flex align-items-c mt20" },
            [
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.batchUp(1)
                        },
                      },
                    },
                    [_vm._v("批量上架")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.batchUp(2)
                        },
                      },
                    },
                    [_vm._v("批量下架")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.batchUp(3)
                        },
                      },
                    },
                    [_vm._v("批量推荐")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.batchUp(4)
                        },
                      },
                    },
                    [_vm._v("取消推荐")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                staticStyle: { "margin-top": "0" },
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "作品详情",
            visible: _vm.showDetail,
            "with-header": false,
            size: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetail = $event
            },
          },
        },
        [
          _c("div", { staticClass: "drawer-title flex align-items-c" }, [
            _c("div", { staticClass: "l flex-one" }, [_vm._v("作品详情")]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [
              _c("i", { staticClass: "el-icon-close" }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "all-height" },
            [
              _vm.showDetail
                ? _c("WorksDetail", {
                    attrs: { id: _vm.showWorksId },
                    on: {
                      showVideoAction: _vm.showVideoAction,
                      showUser: _vm.onDetails,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.fileVisible,
            "close-on-click-modal": false,
            "before-close": _vm.closeBeforeAction,
            title: "源文件详情",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fileVisible = $event
            },
          },
        },
        [
          _vm.fileTypes == 1
            ? _c(
                "div",
                [
                  _c("el-image", {
                    staticStyle: { width: "550px" },
                    attrs: { src: _vm.fileShow },
                  }),
                ],
                1
              )
            : _vm.fileTypes == 2
            ? _c("div", [
                _c("iframe", {
                  staticStyle: { width: "550px", height: "550px" },
                  attrs: { src: _vm.fileShow, frameborder: "0" },
                }),
              ])
            : _vm.fileTypes == 3
            ? _c("div", [
                _c("video", {
                  attrs: {
                    id: "id_test_video",
                    src: _vm.fileShow,
                    controls: "",
                  },
                }),
              ])
            : _vm.fileTypes == 4
            ? _c("div", [
                _c("audio", {
                  staticStyle: { width: "550px" },
                  attrs: { src: _vm.fileShow, controls: "", autoplay: "" },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }