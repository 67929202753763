var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "label-width": "120px",
            size: "mini",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "模板名称", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "withs",
                attrs: { placeholder: "请输入模板名称" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "计费方式", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.changeRadio(_vm.ruleForm.type)
                    },
                  },
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("按件数")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("按重量")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("按体积")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "配送区域及运费", prop: "region" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "tempBox",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.ruleForm.region,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    size: "mini",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "可配送区域",
                      "min-width": "260",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.$index === 0
                              ? _c("span", [_vm._v("默认全国")])
                              : _c("el-cascader", {
                                  staticStyle: { width: "98%" },
                                  attrs: {
                                    options: _vm.cityList,
                                    props: _vm.props,
                                    "collapse-tags": "",
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { change: _vm.changeRegion },
                                  model: {
                                    value: scope.row.city_ids,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "city_ids", $$v)
                                    },
                                    expression: "scope.row.city_ids",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "130px",
                      align: "center",
                      label: _vm.columns.title,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              attrs: { "controls-position": "right", min: 0 },
                              model: {
                                value: row.first,
                                callback: function ($$v) {
                                  _vm.$set(row, "first", $$v)
                                },
                                expression: "row.first",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120px",
                      align: "center",
                      label: "运费（元）",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              attrs: { "controls-position": "right", min: 0 },
                              model: {
                                value: row.first_price,
                                callback: function ($$v) {
                                  _vm.$set(row, "first_price", $$v)
                                },
                                expression: "row.first_price",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120px",
                      align: "center",
                      label: _vm.columns.title2,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              attrs: { "controls-position": "right", min: 0.1 },
                              model: {
                                value: row.continue,
                                callback: function ($$v) {
                                  _vm.$set(row, "continue", $$v)
                                },
                                expression: "row.continue",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "status-col",
                      align: "center",
                      label: "续费（元）",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              attrs: { "controls-position": "right", min: 0 },
                              model: {
                                value: row.continue_price,
                                callback: function ($$v) {
                                  _vm.$set(row, "continue_price", $$v)
                                },
                                expression: "row.continue_price",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      "min-width": "80",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.$index > 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmEdit(
                                          _vm.ruleForm.region,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("\n              删除\n            ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-edit",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addRegion(_vm.ruleForm.region)
                    },
                  },
                },
                [_vm._v("\n        添加配送区域\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.appoint === 1
            ? _c(
                "el-form-item",
                { attrs: { prop: "free" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.ruleForm.free,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                        size: "mini",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "选择地区",
                          "min-width": "220",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-cascader", {
                                    staticStyle: { width: "95%" },
                                    attrs: {
                                      options: _vm.cityList,
                                      props: _vm.props,
                                      "collapse-tags": "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: row.city_ids,
                                      callback: function ($$v) {
                                        _vm.$set(row, "city_ids", $$v)
                                      },
                                      expression: "row.city_ids",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3891925036
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "180px",
                          align: "center",
                          label: _vm.columns.title3,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      min: 1,
                                    },
                                    model: {
                                      value: row.number,
                                      callback: function ($$v) {
                                        _vm.$set(row, "number", $$v)
                                      },
                                      expression: "row.number",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2893068961
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "120px",
                          align: "center",
                          label: "最低购买金额（元）",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      min: 0.01,
                                    },
                                    model: {
                                      value: row.price,
                                      callback: function ($$v) {
                                        _vm.$set(row, "price", $$v)
                                      },
                                      expression: "row.price",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2216462721
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "操作",
                          "min-width": "120",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmEdit(
                                            _vm.ruleForm.free,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              删除\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4029474057
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.appoint === 1
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-edit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addFree(_vm.ruleForm.free)
                        },
                      },
                    },
                    [_vm._v("\n        添加指定包邮区域\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input", {
                staticClass: "withs",
                attrs: { placeholder: "请输入排序" },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "footer acea-row" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onsubmit("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }