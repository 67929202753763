var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "80px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收款主体" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.queryParams.mainBodyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "mainBodyId", $$v)
                            },
                            expression: "queryParams.mainBodyId",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部 "),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.componseList, function (item) {
                            return _c(
                              "el-radio-button",
                              { key: item.id, attrs: { label: item.id } },
                              [_vm._v(_vm._s(item.nameAs) + "\n            ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务类型" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.queryParams.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "type", $$v)
                            },
                            expression: "queryParams.type",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部 "),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.businessTypeList, function (item) {
                            return _c(
                              "el-radio-button",
                              { key: item.type, attrs: { label: item.type } },
                              [_vm._v(_vm._s(item.name) + "\n            ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "时间选择：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange(_vm.queryParams.timeType)
                            },
                          },
                          model: {
                            value: _vm.queryParams.timeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "timeType", $$v)
                            },
                            expression: "queryParams.timeType",
                          },
                        },
                        _vm._l(_vm.timeList, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text) + "\n            ")]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "value-format": "yyyy/MM/dd HH:mm:ss",
                          format: "yyyy/MM/dd HH:mm:ss",
                          size: "small",
                          type: "datetimerange",
                          placement: "bottom-end",
                          "start-placeholder": "选择开始时间",
                          "end-placeholder": "选择结束时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeType,
                          callback: function ($$v) {
                            _vm.timeType = $$v
                          },
                          expression: "timeType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.flagChnl,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "flagChnl", $$v)
                        },
                        expression: "queryParams.flagChnl",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "", label: "支付场景（全部）" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "APPLET", label: "小程序" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "PC", label: "电脑" },
                      }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: "H5", label: "H5" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "payType", $$v)
                        },
                        expression: "queryParams.payType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "", label: "收款渠道（全部）" },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.payList, function (m, i) {
                        return _c("el-option", {
                          key: i,
                          attrs: { value: m.id, label: m.name },
                        })
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "客户名称/手机号", size: "small" },
                      model: {
                        value: _vm.queryParams.key,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "key", $$v)
                        },
                        expression: "queryParams.key",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入支付订单号", size: "small" },
                      model: {
                        value: _vm.queryParams.payOrderSn,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "payOrderSn", $$v)
                        },
                        expression: "queryParams.payOrderSn",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt10 mb10" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("查看导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data },
            },
            [
              _c("el-table-column", {
                attrs: { label: "支付金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(row.realPrice)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "业务类型", prop: "orderTypeName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付时间", prop: "payTime", width: "180px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付方式", prop: "payTypeName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "收款主体",
                  prop: "companyName",
                  width: "180px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付场景", prop: "flagChnl" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.flagChnl == "APPLET"
                          ? _c("div", [_vm._v("小程序")])
                          : row.flagChnl == "PC"
                          ? _c("div", [_vm._v("电脑")])
                          : row.flagChnl == "H5"
                          ? _c("div", [_vm._v("H5")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "客户", width: "120px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row.memberId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.memberNickName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "客户手机号",
                  prop: "memberPhone",
                  width: "120px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商户名称", prop: "merName", width: "120px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "支付订单号",
                  prop: "payOrderSn",
                  width: "180px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "平台订单号", prop: "orderNo", width: "180px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "支付状态",
                  prop: "statusName",
                  width: "140px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark", width: "140px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.payType == 10
                          ? _c(
                              "div",
                              [
                                row.status == 2
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toSelectInfo("1", row)
                                          },
                                        },
                                      },
                                      [_vm._v("待确认")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status == 1 || row.status == -1
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.orderIdRecord(row)
                                          },
                                        },
                                      },
                                      [_vm._v("\n              审核记录")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status == 1 || row.status == -1
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toSelectInfo("2", row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              汇款记录\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.onUpdRemark(row)
                              },
                            },
                          },
                          [_vm._v("\n            修改备注\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.queryParams.pageSize,
                  "current-page": _vm.queryParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("ul", [
            _c("li", [
              _c("span", [_vm._v("汇款时间  ")]),
              _c("span", [_vm._v(_vm._s(_vm.selectInfo.remittanceTime))]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("汇款金额  ")]),
              _c("span", [_vm._v(_vm._s(_vm.selectInfo.realPrice))]),
            ]),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("span", [_vm._v("汇款截图  ")]),
                _vm._v(" "),
                _c("el-image", {
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: {
                    src: _vm.selectInfo.remittanceUrl,
                    "preview-src-list": [_vm.selectInfo.remittanceUrl],
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.recordList, function (item) {
              return _vm.curType === "3"
                ? _c("li", { staticClass: "flex justify-b align-items-c" }, [
                    item.status == 1
                      ? _c(
                          "p",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-link",
                              { attrs: { underline: false, type: "success" } },
                              [_vm._v("审核通过 ")]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.price) + "元")]),
                          ],
                          1
                        )
                      : _c(
                          "p",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-link",
                              { attrs: { underline: false, type: "danger" } },
                              [_vm._v("审核失败 ")]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.remark))]),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c("p", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.createTime)),
                    ]),
                  ])
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _vm.curType === "1"
            ? _c(
                "div",
                {
                  staticClass: "flex flex-direction",
                  staticStyle: { margin: "20px 0" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { margin: "20px 0" },
                      model: {
                        value: _vm.verify.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.verify, "status", $$v)
                        },
                        expression: "verify.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("审核通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: -1 } }, [
                        _vm._v("拒绝"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.verify.status === 1
                    ? _c("el-input-number", {
                        attrs: { placeholder: "输入回款金额", controls: false },
                        model: {
                          value: _vm.verify.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.verify, "price", $$v)
                          },
                          expression: "verify.price",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.verify.status === -1
                    ? _c("el-input", {
                        staticClass: "w200",
                        attrs: { placeholder: "输入拒绝理由" },
                        model: {
                          value: _vm.verify.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.verify, "remark", $$v)
                          },
                          expression: "verify.remark",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.remVisible, title: "修改备注", width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.remVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-ruleForm mt20",
              attrs: { "label-width": "40px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "textarea",
                      size: "small",
                      placeholder: "请输入备注",
                    },
                    model: {
                      value: _vm.formState.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formState, "remark", $$v)
                      },
                      expression: "formState.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }