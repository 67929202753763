var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "输入操作密码", prop: "pwd1" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { type: "password", placeholder: "输入操作密码" },
                model: {
                  value: _vm.form.pwd1,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "pwd1", $$v)
                  },
                  expression: "form.pwd1",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "确认操作密码", prop: "pwd2" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { type: "password", placeholder: "确认操作密码" },
                model: {
                  value: _vm.form.pwd2,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "pwd2", $$v)
                  },
                  expression: "form.pwd2",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "获取验证码" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "请输入内容" },
                  model: {
                    value: _vm.form.verificationCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "verificationCode", $$v)
                    },
                    expression: "form.verificationCode",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { disabled: _vm.disabled },
                          on: { click: _vm.getCode },
                        },
                        [_vm._v(_vm._s(_vm.text))]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.submit("form")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }