"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.morepyeeApi = morepyeeApi;
exports.transferApi = transferApi;
var _request = _interopRequireDefault(require("./request"));
function morepyeeApi(data) {
  return _request.default.post("/pay/sys/withdrawal/morepyee", data);
}
function transferApi(data) {
  return _request.default.post("/pay/sys/transfer", data);
}