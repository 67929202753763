var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c("div", { staticClass: "flex_box flexNo" }, [
      _c(
        "div",
        { staticClass: "flex_left" },
        [
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "box-tit" }, [_vm._v("横图3:4")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex_box" },
              _vm._l(_vm.hList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "edit_label",
                    attrs: { draggable: "true" },
                    on: {
                      dragstart: function ($event) {
                        return _vm.onDragStart($event, item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "box-tit" }, [_vm._v("竖图4:3")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex_box" },
              _vm._l(_vm.sList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "edit_label",
                    attrs: { draggable: "true" },
                    on: {
                      dragstart: function ($event) {
                        return _vm.onDragStart($event, item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "box-tit" }, [_vm._v("方图1:1")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex_box" },
              _vm._l(_vm.zList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "edit_label",
                    attrs: { draggable: "true" },
                    on: {
                      dragstart: function ($event) {
                        return _vm.onDragStart($event, item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "box-tit" }, [_vm._v("图片缩放")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex_box mSpac" }, [
              _c("i", {
                staticClass: "el-icon-remove-outline cameraIconfont",
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.reduceSize },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "salcBox" }, [
                _vm._v(_vm._s(_vm.comNum * 20) + "%"),
              ]),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-circle-plus-outline cameraIconfont",
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.addSize },
              }),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.imgInfo.image
        ? _c("div", { ref: "flexRight", staticClass: "flex_right" }, [
            _c(
              "div",
              { staticClass: "handEndBox" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.onSave },
                  },
                  [_vm._v("保 存")]
                ),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.backAction } },
                  [_vm._v("取 消")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "draggableBox",
                staticClass: "draggable_box",
                style: "width: " + _vm.domWidth + "px;",
                on: {
                  dragover: function ($event) {
                    $event.preventDefault()
                    return _vm.onDragover($event)
                  },
                  drop: _vm.onDrop,
                },
              },
              [
                _c("img", {
                  ref: "draggableImg",
                  staticClass: "draggable_img",
                  attrs: { src: _vm.imgInfo.image, alt: "" },
                }),
                _vm._v(" "),
                _vm._l(_vm.frameList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "dragItem",
                      style:
                        "width: " +
                        item.width +
                        "px;height: " +
                        item.height +
                        "px;top: " +
                        item.top +
                        "px;left: " +
                        item.left +
                        "px;",
                      on: {
                        mousedown: function ($event) {
                          return _vm.handleStart($event, item, index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
              ],
              2
            ),
          ])
        : _c(
            "div",
            { staticClass: "flex_right djcac" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.modalPicTap } },
                [_vm._v(" 上传图片 ")]
              ),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }