var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tinymce-container",
      class: { fullscreen: _vm.fullscreen },
      style: { width: _vm.containerWidth },
    },
    [
      _c("textarea", {
        staticClass: "tinymce-textarea",
        attrs: { id: _vm.tinymceId },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "editor-custom-btn-container" }, [
        _c(
          "div",
          { staticClass: "upload-container" },
          [
            _c(
              "el-button",
              {
                staticStyle: { background: "#1890ff", borderColor: "#1890ff" },
                attrs: {
                  icon: "el-icon-upload",
                  size: "mini",
                  type: "primary",
                },
                on: { click: _vm.uploadImgAction },
              },
              [_vm._v("\n        上传图片\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                staticStyle: { background: "#1890ff", borderColor: "#1890ff" },
                attrs: {
                  icon: "el-icon-upload",
                  size: "mini",
                  type: "primary",
                },
                on: { click: _vm.uploadVideoAction },
              },
              [_vm._v("\n        上传视频\n      ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }