var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageInfo.footerSetting
    ? _c(
        "control",
        { attrs: { title: "底部导航" } },
        [
          _c("template", { slot: "content" }, [
            _c("div", { staticClass: "item-align-center mt20" }, [
              _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                _vm._v("内容和链接"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "ml10",
                  staticStyle: { "font-size": "12px", color: "#999" },
                },
                [_vm._v("上传300x300px的正方形图片，最多6个")]
              ),
            ]),
            _vm._v(" "),
            !_vm.pageInfo.footerSetting.no_change
              ? _c(
                  "div",
                  { staticClass: "item-align-center mt20" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.pageInfo.footerSetting.use_type,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageInfo.footerSetting,
                              "use_type",
                              $$v
                            )
                          },
                          expression: "pageInfo.footerSetting.use_type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("使用通用导航"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("使用当前页导航"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageInfo.footerSetting.use_type === 2
              ? _c(
                  "div",
                  { staticClass: "pt10" },
                  [
                    _c("div", { staticClass: "item-align-center mt10" }, [
                      _c("div", {
                        staticClass: "go-down-img",
                        staticStyle: { height: "1px" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "del-img",
                        staticStyle: { height: "1px" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img ft12 color-gray align-c",
                          staticStyle: { height: "auto" },
                        },
                        [_vm._v("未选中")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img ft12 color-gray align-c",
                          staticStyle: { height: "auto" },
                        },
                        [_vm._v("选中")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.pageInfo.footerSetting.content,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "kuang1 item-align-center mt10",
                            class: { mt0: index === 0 },
                          },
                          [
                            index === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "go-down-img",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goDown(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            index > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "go-up-img",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goUp(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "del-img",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "upload-img-view item-flex-center",
                                staticStyle: { "margin-left": "4px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.uploadIconAction(index, "url")
                                  },
                                },
                              },
                              [
                                item.url
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "del-upload-img",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delImg(index, "url")
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.url
                                  ? _c("img", {
                                      attrs: { src: item.url + "!120a" },
                                    })
                                  : _c("img", {
                                      staticClass: "upload-icon",
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                        alt: "",
                                      },
                                    }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "upload-img-view item-flex-center",
                                staticStyle: { "margin-left": "4px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.uploadIconAction(
                                      index,
                                      "url_after"
                                    )
                                  },
                                },
                              },
                              [
                                item.url_after
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "del-upload-img",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delImg(
                                              index,
                                              "url_after"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.url_after
                                  ? _c("img", {
                                      attrs: { src: item.url_after + "!120a" },
                                    })
                                  : _c("img", {
                                      staticClass: "upload-icon",
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                        alt: "",
                                      },
                                    }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "input-view ml4" },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    type: "text",
                                    placeholder: "设置文字",
                                    maxlength: "10",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: item.title,
                                    callback: function ($$v) {
                                      _vm.$set(item, "title", $$v)
                                    },
                                    expression: "item.title",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            item.link.name1 || item.link.name
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-align-center flex-one justify-end ml10 cur-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCategory(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "ft14" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.link.name1 || item.link.name
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "arrow-img",
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCategory(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "ft14" }, [
                                      _vm._v("设置跳转链接"),
                                    ]),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "arrow-img",
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageInfo.footerSetting.use_type === 2
              ? _c("div", { staticClass: "item-align-center mt20" }, [
                  _c(
                    "a",
                    {
                      staticClass: "fontW500 ft16",
                      on: { click: _vm.handleAdditem },
                    },
                    [_vm._v("+ 新增一条")]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("template", { slot: "style" }, [
            _vm.pageInfo.footerSetting.use_type === 2
              ? _c(
                  "div",
                  [
                    _c("choose", {
                      staticClass: "mt20",
                      attrs: {
                        data: _vm.styleData,
                        title: "风格类型",
                        margin: "0 10px 0 0",
                      },
                      model: {
                        value: _vm.pageInfo.footerSetting.footer_type,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.pageInfo.footerSetting,
                            "footer_type",
                            $$v
                          )
                        },
                        expression: "pageInfo.footerSetting.footer_type",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          "label-width": "80px",
                          size: "small",
                          "label-position": "left",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "背景颜色" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#ffffff" },
                              model: {
                                value:
                                  _vm.pageInfo.footerSetting.footer_background,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.footerSetting,
                                    "footer_background",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.footerSetting.footer_background",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "左右距离" } },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 750 },
                              model: {
                                value: _vm.pageInfo.footerSetting.footer_margin,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.footerSetting,
                                    "footer_margin",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.footerSetting.footer_margin",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "文字颜色" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value:
                                  _vm.pageInfo.footerSetting.footer_text_color,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.footerSetting,
                                    "footer_text_color",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.footerSetting.footer_text_color",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "选中颜色" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value:
                                  _vm.pageInfo.footerSetting
                                    .footer_active_color,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.footerSetting,
                                    "footer_active_color",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.footerSetting.footer_active_color",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "字号" } },
                          [
                            _c("slider", {
                              attrs: { min: 12, max: 50 },
                              model: {
                                value:
                                  _vm.pageInfo.footerSetting.footer_text_size,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.footerSetting,
                                    "footer_text_size",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.footerSetting.footer_text_size",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("selectCategory", {
            ref: "setCategoryView",
            on: { selectMessage: _vm.selectMessage },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }