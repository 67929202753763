"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _open3d = require("@/api/open3d");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listLoading: false,
      tableData: [],
      fromState: {},
      pageNum: 1,
      pageSize: 20,
      total: 0,
      categoryList: [],
      categoryIndex: 1
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _product.storeCategoryListApi)({
      type: 1
    }).then(function (res) {
      _this.categoryList = res.data;
    });
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this2 = this;
      var model = (0, _objectSpread2.default)({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }, this.fromState);
      (0, _open3d.getWorksNewListApi)(model).then(function (res) {
        console.log(res);
        _this2.tableData = res.rows;
        _this2.total = res.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    changeCategoryAction: function changeCategoryAction() {
      var _this3 = this;
      console.log(this.fromState.type);
      this.categoryIndex = this.categoryList.findIndex(function (item) {
        return item.id == _this3.fromState.type;
      });
      this.fromState.categoryId = '';
      this.onResh();
    },
    onResh: function onResh() {
      this.pageNum = 1;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    }
  }
};