"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _list = _interopRequireDefault(require("./components/list.vue"));
var _produce = _interopRequireDefault(require("./components/produce.vue"));
var _cardSlot = _interopRequireDefault(require("./components/cardSlot.vue"));
var _landList = _interopRequireDefault(require("./components/manor/landList.vue"));
var _guard = _interopRequireDefault(require("./components/manor/guard.vue"));
var _gameImg = _interopRequireDefault(require("./components/gameImg.vue"));
var _dataStatistics = _interopRequireDefault(require("./components/dataStatistics.vue"));
var _animal = require("@/api/animal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Animals",
  components: {
    List: _list.default,
    Produce: _produce.default,
    CardSlot: _cardSlot.default,
    LandList: _landList.default,
    Guard: _guard.default,
    GameImg: _gameImg.default,
    DataStatistics: _dataStatistics.default
  },
  data: function data() {
    return {
      activeName: "first",
      showLand: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _animal.isHiddenApi)().then(function (res) {
      _this.showLand = res.data;
    });
    if (this.$route.query.name) {
      this.activeName = this.$route.query.name;
    }
  },
  methods: {
    handleClick: function handleClick(_ref) {
      var name = _ref.name;
      console.log(name);
      switch (name) {
        case "second":
          this.$refs.Produce.getList();
          break;
        default:
          break;
      }
    }
  }
};