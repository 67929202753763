"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _nft = require("@/api/nft");
var _basic = require("@/api/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      id: '',
      workDetail: {
        id: '',
        name: '',
        image: '',
        infoImage: '',
        startTime: '',
        purchaseLimit: '',
        detail: '',
        labelIds: []
      },
      rules: {
        name: [{
          required: true,
          message: '请输入专辑名称',
          trigger: 'blur'
        }, {
          min: 3,
          max: 64,
          message: '专辑名称长度为3到64个字符',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请选择封面图',
          trigger: 'change'
        }],
        infoImage: [{
          required: true,
          message: '请选择封面图',
          trigger: 'change'
        }],
        startTime: [{
          required: true,
          message: '请选择发售时间',
          trigger: 'change'
        }],
        detail: [{
          required: true,
          message: '请输入专辑详情',
          trigger: 'blur'
        }],
        labelIds: [{
          required: true,
          message: '请选择专辑标签',
          trigger: 'change'
        }]
      },
      tagList: [],
      status: 0
    };
  },
  created: function created() {
    var _this = this;
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.loadDetail();
    }
    (0, _basic.labelLstApi)({
      pageNum: 1,
      pageSize: 100
    }).then(function (res) {
      _this.tagList = res.rows;
    });
  },
  methods: {
    // 上传图片
    modalPicTap: function modalPicTap(tit, key, key1) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this2.workDetail[key1][key] = img[0];
        } else {
          _this2.workDetail[key] = img[0];
        }
        _this2.$forceUpdate();
      }, tit);
    },
    delImg: function delImg(key, key1) {
      if (key1) {
        this.workDetail[key1][key] = '';
      } else {
        this.workDetail[key] = '';
      }
      this.$forceUpdate();
    },
    loadDetail: function loadDetail() {
      var _this3 = this;
      (0, _nft.nftCollectionAlbumDetailApi)({
        id: this.id
      }).then(function (res) {
        var d = res.data;
        _this3.workDetail = {
          id: d.id,
          name: d.name,
          image: d.image,
          infoImage: d.infoImage,
          startTime: d.startTime,
          purchaseLimit: d.purchaseLimit,
          detail: d.detail,
          labelIds: d.labelIds ? d.labelIds.split(',') : []
        };
      });
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.workDetail));
          data['labelIds'] = _this4.workDetail.labelIds.length ? _this4.workDetail.labelIds.join(',') : '';
          var action = _nft.nftCollectionAlbumCreateApi;
          if (_this4.id) {
            action = _nft.nftCollectionAlbumUpdateApi;
          }
          action(data).then(function () {
            _this4.$message.success('操作成功');
            _this4.$router.back();
          }).catch(function (msg) {
            _this4.$message.error(msg);
          });
        } else {
          return false;
        }
      });
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};