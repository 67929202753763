var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-button", { staticClass: "mt20", on: { click: _vm.backAction } }, [
        _vm._v("返回"),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm mt20",
          attrs: {
            model: _vm.roleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称：", prop: "roleName" } },
            [
              _c("el-input", {
                staticClass: "w200",
                attrs: {
                  size: "small",
                  placeholder: "角色名称",
                  clearable: "",
                },
                model: {
                  value: _vm.roleForm.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.roleForm, "roleName", $$v)
                  },
                  expression: "roleForm.roleName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "权限菜单：", prop: "menuIds" } },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.menuArr,
                  props: _vm.props,
                  "show-checkbox": "",
                  "default-expand-all": "",
                  "default-checked-keys": _vm.checkedKeys,
                  "node-key": "id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否开启：", prop: "status" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": "0",
                  "inactive-value": "1",
                  "active-color": "#13ce66",
                  "inactive-color": "#909399",
                },
                model: {
                  value: _vm.roleForm.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.roleForm, "status", $$v)
                  },
                  expression: "roleForm.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }