"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
var _frame = require("@/api/frame");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var activeIndexObj = {
  "1": "frameList",
  "2": "frameType",
  "3": "framePaper",
  "4": "frameScence",
  "5": "frameFrame"
};
var _default = exports.default = {
  name: "framePaper",
  data: function data() {
    return {
      srcList: [],
      type: "0",
      worksName: "",
      worksOwn: "",
      beginTime: 0,
      lastTime: 0,
      changeSize: 10,
      startDateOption: {
        disabledDate: this.setStartDate
      },
      endDateOption: {
        disabledDate: this.setEndDate
      },
      lists: [],
      queryParams: {
        pageNum: 1,
        pageSize: 20
      },
      categorys: [],
      total: 0,
      currentPage: 1,
      saleflag: false,
      price: "",
      work: {}
    };
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      this.$router.push({
        name: activeIndexObj[index]
      });
    },
    changeType: function changeType(type) {
      this.queryParams.pageNum = 1;
      this.load();
    },
    edit: function edit(row, type) {
      this.work = row;
      this.price = "";
      if (type === 1) {
        this.saleflag = true;
        return;
      }
      this.handle(type);
    },
    load: function load() {
      var _this = this;
      var params = this.queryParams;
      this.resultData = [];
      this.loading = true;
      params.topictype = this.$route.query.type;
      params.type = this.type == 0 ? "" : this.type;
      (0, _frame._getPaperList)(params).then(function (res) {
        if (res.code === 200) {
          _this.total = res.total;
          _this.srcList = res.rows.map(function (item) {
            return item.imgUrl;
          });
          _this.lists = res.rows;
        }
        _this.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this.loading = false;
      });
    },
    changeSort: function changeSort(row) {
      var _this2 = this;
      var value = row.sort;
      if (isNaN(value)) {
        this.$message.error("请输入数字");
        this.load();
        return;
      }
      (0, _frame._updatePaper)({
        id: row.id,
        type: this.type,
        sort: value
      }).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success('保存成功');
          _this2.load();
        } else {
          _this2.$message.err(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
        _this2.$message.err(err.message);
      });
    },
    deltag: function deltag(row) {
      var _this3 = this;
      (0, _frame._deletePaper)({
        id: row.id,
        type: row.type
      }).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success("删除成功");
          _this3.queryParams.pageNum = 1;
          _this3.load();
        } else {
          _this3.$message.err(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
        _this3.$message.err(err.message);
      });
    },
    setEndDate: function setEndDate(date) {
      // 设置结束时间规则 大于开始时间
      if (this.beginTime) {
        return date && date.valueOf() < Date.parse(this.beginTime).valueOf();
      }
      return "";
    },
    setStartDate: function setStartDate(date) {
      // 设置开始时间规则 小于结束时间
      if (this.lastTime) {
        return date && date.valueOf() > Date.parse(this.lastTime).valueOf();
      }
      return "";
    },
    changeStartDate: function changeStartDate(data) {
      // 筛选条件选择时间
      this.beginTime = data;
    },
    changeEndDate: function changeEndDate(data) {
      // 筛选条件选择时间
      this.lastTime = data;
    },
    changePageSize: function changePageSize(pageSize) {
      this.queryParams.pageSize = pageSize;
      this.queryParams.pageNum = 1;
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.pageNum = page;
      this.load();
    },
    change: function change() {}
  },
  watch: {
    $route: function $route(to, form) {
      if (to.name === "DataList" && form.name === "DataList") {
        if (to.query.type !== form.query.type) {
          console.log("watch");
        }
      }
    }
  },
  created: function created() {
    this.load();
  }
};