"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.function.name");
var _uploadPDF = _interopRequireDefault(require("../uploadPDF"));
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SelectMineLink',
  components: {
    uploadPDF: _uploadPDF.default
  },
  data: function data() {
    return {
      selectData1: {},
      selectData2: {},
      classifyData1: [{
        name: '订单相关',
        child: [{
          name: '全部订单',
          child: [],
          link: '/order/list'
        }, {
          name: '待支付',
          child: [],
          link: '/order/list?status=0',
          link_type: 'order_wait_pay'
        }, {
          name: '待发货',
          child: [],
          link: '/order/list?status=2',
          link_type: 'order_wait_send'
        }, {
          name: '待收货',
          child: [],
          link: '/order/list?status=3',
          link_type: 'order_wait_collect'
        }, {
          name: '已完成',
          child: [],
          link: '/order/list?status=4',
          link_type: 'order_wait_refund'
        }, {
          name: '售后',
          child: [],
          link: '/order/list?status=6',
          link_type: 'order_wait_refund'
        }, {
          name: '已关闭',
          child: [],
          link: '/order/list?status=-1'
        }],
        link: ''
      }, {
        name: '账户相关',
        child: [{
          name: '钱包余额',
          child: [],
          link: '/pages/wallet/index',
          link_type: 'my_account'
        }, {
          name: '供应商余额',
          child: [],
          link: '/pages/wallet/entrust',
          link_type: 'entrust_account'
        }, {
          name: '修改资料',
          child: [],
          link: '/users/edit'
        }, {
          name: '收货地址',
          child: [],
          link: '/users/address_list'
        }, {
          name: '银行卡管理',
          child: [],
          link: '/users/bank_list'
        }, {
          name: '我的积分',
          child: [],
          link: '/users/integral',
          link_type: 'my_integral'
        }, {
          name: '我的优惠券',
          child: [],
          link: '/users/my_coupon',
          link_type: 'my_coupon'
        }, {
          name: '我的优惠券',
          child: [],
          link: '/users/my_coupon',
          link_type: 'my_coupon'
        }, {
          name: '汇付钱包',
          child: [],
          link: '/users/hfpay'
        }, {
          name: '易宝钱包',
          child: [],
          link: '/users/yeepay'
        }, {
          name: '杉德钱包',
          child: [],
          link: '/users/hfpay?t=7'
        }],
        link: ''
      }, {
        name: '列表',
        child: [{
          name: '我的藏品',
          child: [],
          link: '/users/my_nft'
        }, {
          name: '转赠记录',
          child: [],
          link: '/users/my_gift_nft'
        }, {
          name: '我的NFT盲盒',
          child: [],
          link: '/users/user_box'
        }, {
          name: '合成记录',
          child: [],
          link: '/users/my_nft_compose'
        }, {
          name: '我的寄售',
          child: [],
          link: '/nft_market/my_consignment'
        }, {
          name: '我的盲盒',
          child: [],
          link: '/users/user_box',
          link_h5: ''
        }],
        link: ''
      }, {
        name: '签到',
        child: [],
        link: '/pages/marketing/signIn/signIn'
      }, {
        name: '其他',
        child: [{
          name: '自定义链接',
          child: [{
            key: 'link_h5',
            value: '',
            placeholder: '输入H5跳转地址'
          }],
          link: '',
          link_h5: ''
        }, {
          name: '电话',
          child: [{
            key: 'phone',
            value: '',
            placeholder: '输入电话',
            required: true
          }],
          link: 'phone?',
          link_h5: ''
        }, {
          name: '打开PDF文件',
          child: [{
            key: 'url',
            value: '',
            placeholder: '输入电话',
            uploadPdf: true
          }],
          link: 'pdf?',
          link_h5: ''
        }],
        link: '',
        link_h5: ''
      }],
      classifyData2: [],
      classifyData3: [],
      showSet: false,
      ossClient: null,
      statusText: false,
      authProgress: 0,
      fileUrl: ''
    };
  },
  created: function created() {},
  methods: {
    changeshow: function changeshow(data) {
      var _this = this;
      if (data.name || data.name1) {
        for (var key in this.classifyData1) {
          var val = this.classifyData1[key];
          if (val.name === data.name) {
            this.selectData1 = val;
            this.classifyData2 = val.child;
          }
        }
        if (data.name1) {
          var _loop = function _loop() {
            var val = _this.classifyData2[_key];
            if (val.name === data.name1) {
              _this.selectData2 = val;
              _this.classifyData3 = val.child;
              if (data.name1 === '自定义链接') {
                _this.classifyData3[0].value = data.link;
              } else if (_this.classifyData3.length > 0) {
                var query = data.link.split('?')[1];
                var qList = query.split('&');
                _this.classifyData3.forEach(function (m) {
                  qList.forEach(function (cc) {
                    var hasIndex = cc.indexOf("".concat(m.key, "="));
                    console.log(cc, m, hasIndex);
                    if (hasIndex !== -1) {
                      var param = cc.split('=');
                      m.value = param[1];
                    }
                  });
                });
              }
            }
          };
          for (var _key in this.classifyData2) {
            _loop();
          }
        }
      } else {
        this.selectData1 = {};
        this.selectData2 = {};
        this.classifyData2 = [];
        this.classifyData3 = [];
      }
      this.showSet = true;
    },
    handleChange1: function handleChange1(item) {
      this.classifyData2 = item.child;
      this.classifyData3 = [];
    },
    handleChange2: function handleChange2(item) {
      item.child.forEach(function (m) {
        if (m.value) {
          m.value = '';
        }
      });
      this.classifyData3 = item.child;
    },
    uploadPDF: function uploadPDF(url) {
      console.log(this.classifyData3, url);
      this.classifyData3[0].value = url;
    },
    submitAction: function submitAction() {
      var data = {
        link: '',
        name: '',
        name1: '',
        select_num_type: ''
      };
      if (!this.selectData1.name) {
        this.showSet = false;
        // 关闭
      } else {
        data.link = this.selectData1.link;
        data.name = this.selectData1.name;
        if (this.selectData1.child.length > 0) {
          if (!this.selectData2.name) {
            this.$message.error('请选择具体跳转链接');
            return false;
            // 关闭
          }
          data.link += this.selectData2.link;
          data.name1 = this.selectData2.name;
          data.select_num_type = this.selectData2.link_type || '';
          if (this.selectData2.child.length > 0) {
            if (data.link) {
              var d = [];
              for (var key in this.classifyData3) {
                var m = this.classifyData3[key];
                if (m.required && !m.value) {
                  this.$message.error('请输入具体跳转内容ID');
                  return false;
                }
                d.push("".concat(m.key, "=").concat(m.value));
              }
              data.link += d.length > 0 ? d.join('&') : '';
            } else {
              data.link = this.classifyData3[0].value;
            }
          }
        }
      }
      this.$emit('selectMessage', {
        data: data
      });
      this.showSet = false;
    }
  }
};