"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find-index");
var _sdk = require("@/api/sdk");
var _ipip = require("@/api/ipip");
var _user = require("@/api/user");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _merchant = require("@/api/merchant");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "MonitoringList",
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      labelForm: {
        tagId: [],
        userId: []
      },
      tagList: [],
      multipleSelection: [],
      visibleLabel: false,
      identityTypeList: [{
        value: 1,
        label: "产品"
      }, {
        value: 2,
        label: "程序员"
      }, {
        value: 3,
        label: "商务"
      }, {
        value: 4,
        label: "其他"
      }],
      enterpriseTypeList: [{
        value: 1,
        label: "个人"
      }, {
        value: 2,
        label: "企业"
      }],
      modeTypeList: [{
        value: 1,
        label: "平台"
      }, {
        value: 2,
        label: "SaaS"
      }],
      productTypeList: [{
        value: 1,
        label: "入门"
      }, {
        value: 2,
        label: "进阶"
      }, {
        value: 3,
        label: "精英"
      }, {
        value: 4,
        label: "至尊"
      }],
      expireStatusList: [{
        value: 0,
        label: "不可用"
      }, {
        value: 1,
        label: "正常状态"
      }, {
        value: 2,
        label: "即将过期"
      }, {
        value: 3,
        label: "已经过期"
      }],
      searchForm: {
        identityType: "",
        enterpriseType: "",
        modeType: "",
        productType: "",
        nameOrPhone: "",
        appName: ""
      },
      pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      list: [],
      listLoading: false,
      uid: "",
      showdetail: false,
      identityList: []
    };
  },
  watch: {
    searchForm: {
      handler: function handler(val) {
        this.getList(1);
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    this.getList(1);
    this.getLabelList();
    (0, _merchant.getList)().then(function (res) {
      _this.identityList = res.rows;
    });
  },
  methods: {
    // 处理form
    formatForm: function formatForm(form, type) {
      var _this2 = this;
      var copy_form = JSON.parse(JSON.stringify(form));
      var types = ["identityType", "enterpriseType", "modeType", "productType", "expireStatus"];
      if (type === "set") {
        types.forEach(function (item) {
          if (copy_form[item].length) {
            var emptyIndex = copy_form[item].findIndex(function (e) {
              return e === "";
            });
            if (emptyIndex !== -1) {
              copy_form[item].splice(emptyIndex, 1);
            }
            // 全选
            if (copy_form[item].length === _this2[item + "List"].length) {
              copy_form[item] = [""];
            }
          } else {
            copy_form[item] = [""];
          }
        });
      }
      return copy_form;
    },
    // 获取列表
    getList: function getList(reset) {
      var _this3 = this;
      if (reset) {
        this.pages.pageNum = 1;
      }
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), this.pages);
      this.listLoading = true;
      (0, _ipip.getMemberList)(data).then(function (res) {
        if (res.code === 200) {
          _this3.pages.total = res.total;
          _this3.list = res.rows;
        }
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    changePage: function changePage(page) {
      this.pages.pageNum = page;
      this.getList();
    },
    goRouter: function goRouter(name) {
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.$router.push({
        name: name,
        query: query
      });
    },
    nickNameClick: function nickNameClick(id) {
      this.uid = id;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    batchLabel: function batchLabel() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning("请先选择用户");
      }
      var checkIds = this.multipleSelection.map(function (item) {
        return item.userId;
      });
      this.labelForm.userId = checkIds;
      this.visibleLabel = true;
    },
    // 修改标签
    setLabel: function setLabel(row) {
      this.labelForm.userId = [row.userId];
      var arr = [];
      if (row.tags) {
        row.tags.forEach(function (item) {
          arr.push(item.id);
        });
      }
      this.labelForm.tagId = arr;
      console.log(this.labelForm);
      this.visibleLabel = true;
    },
    confirmLabelAction: function confirmLabelAction(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.labelForm.tagId = _this4.labelForm.tagId.join(",");
          (0, _user.addTagApi)(_this4.labelForm).then(function () {
            _this4.$message.success("操作成功");
            if (_this4.labelForm.userId.length > 1) {
              _this4.$refs.multipleTable.clearSelection();
            }
            _this4.visibleLabel = false;
            _this4.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    getLabelList: function getLabelList() {
      var _this5 = this;
      (0, _user.labelLstApi)({
        pageNum: 1,
        pageSize: 100
      }).then(function (res) {
        _this5.tagList = res.rows;
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    checkType: function checkType(val, type) {
      var list = this[type + "List"];
      if (list && list.length && !isNaN(val)) {
        var item = list.find(function (e) {
          return e.value === Number(val);
        });
        return item.label || val;
      } else {
        return val;
      }
    }
  }
};