var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "digital-collection" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.changeTypeAction },
          model: {
            value: _vm.types,
            callback: function ($$v) {
              _vm.types = $$v
            },
            expression: "types",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "作品", name: "works" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "专辑", name: "album" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c" },
        [
          _c(
            "el-form",
            {
              attrs: {
                size: "small",
                "label-width": "80px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v(
                          "发售中 " +
                            _vm._s(
                              "(" + _vm.tableData.statistical.saleAmount
                                ? _vm.tableData.statistical.saleAmount
                                : 0 + ")"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v(
                          "已售罄 " +
                            _vm._s(
                              "(" + _vm.tableData.statistical.saleOver
                                ? _vm.tableData.statistical.saleOver
                                : 0 + ")"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v(
                          "待开始 " +
                            _vm._s(
                              "(" + _vm.tableData.statistical.waitStart
                                ? _vm.tableData.statistical.waitStart
                                : 0 + ")"
                            )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt10" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addAction },
            },
            [_vm._v("+创建专辑")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.downloadAction },
            },
            [_vm._v("下载导入模板")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发售时间", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.startTime))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "专辑封面" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: { src: row.image },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "专辑名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.count))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发售/已售/库存", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.saleCount) +
                              " / " +
                              _vm._s(row.soldCount) +
                              " / " +
                              _vm._s(row.stock)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "预留/库存" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.reserveCount) +
                              " / " +
                              _vm._s(row.reserveStock)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "限购" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.purchaseLimit))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 2
                          ? _c("div", { staticClass: "color-red" }, [
                              _vm._v("未起售"),
                            ])
                          : row.status == 0
                          ? _c("div", { staticClass: "color-lv" }, [
                              _vm._v("销售中"),
                            ])
                          : row.status == 1
                          ? _c("div", [_vm._v("已售罄")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "排序", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "100px" },
                          attrs: { min: 0, controls: false },
                          on: {
                            change: function (val) {
                              return _vm.editSortAction(val, scope.$index)
                            },
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建人", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.createBy))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-dropdown",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-dropdown-link mr10",
                                    staticStyle: {
                                      color: "#1890ff",
                                      "font-size": "12px",
                                      cursor: "pointer",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                链接\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.linkAction(row.id)
                                          },
                                        },
                                      },
                                      [_vm._v("H5链接")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.QRAction(row.id)
                                          },
                                        },
                                      },
                                      [_vm._v("二维码")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(row.id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(row.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.batchUploadAuction(row.id)
                                  },
                                },
                              },
                              [_vm._v("批量上传")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("div", { staticClass: "flex-one align-items-c flex" }),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  "page-size": _vm.queryParams.pageSize,
                  "current-page": _vm.queryParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visible
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visible,
                "close-on-click-modal": false,
                center: "",
                title: "批量上传",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
              },
            },
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "600px",
                    title: "错误报告",
                    visible: _vm.innerVisible,
                    "append-to-body": "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.innerVisible = $event
                    },
                  },
                },
                [
                  _vm.fileCheck.length
                    ? _c(
                        "div",
                        _vm._l(_vm.fileCheck, function (item) {
                          return _c("div", [_vm._v(_vm._s(item))])
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: { size: "small", "label-width": "120px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择素材分类：" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.openChangeCategory },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.changeCate.id ? "重新选择" : "去选择")
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.changeCate.id
                        ? _c("span", { staticClass: "ml10" }, [
                            _vm._v(
                              "已选中分类：" + _vm._s(_vm.changeCate.label)
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传表格：" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "myUpload",
                          staticClass: "upload-demo",
                          attrs: {
                            action: "",
                            headers: _vm.myHeaders,
                            "http-request": _vm.uploadRequest,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "表格检查：" } }, [
                    !_vm.checkFlag
                      ? _c("span", { staticStyle: { color: "#28AB29" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fileCheck.length == 0 && _vm.checkFlag
                      ? _c("span", { staticStyle: { color: "#28AB29" } }, [
                          _vm._v(
                            "检查完毕  " +
                              _vm._s(_vm.checkoutworkArr.length) +
                              "件作品"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fileCheck.length && _vm.checkFlag
                      ? _c(
                          "div",
                          [
                            _c("span", { staticStyle: { color: "#AE0000" } }, [
                              _vm._v(
                                "存在" + _vm._s(_vm.fileCheck.length) + "个问题"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: { click: _vm.checkLook },
                              },
                              [_vm._v("查看问题")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: { click: _vm.checkAgain },
                              },
                              [_vm._v("重新检查")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.fileCheck.length,
                      },
                      on: { click: _vm.confirmAction },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }