"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var configRouter = {
  path: "".concat(_settings.roterPre, "/config"),
  name: 'system_config',
  meta: {
    icon: 'dashboard',
    title: '系统配置'
  },
  alwaysShow: true,
  // 一直显示根路由
  component: _layout.default,
  children: [{
    path: 'picture',
    name: 'system_config_picture',
    meta: {
      title: '素材管理'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/config/picture'));
      });
    }
  }, {
    path: 'service',
    name: 'Service',
    meta: {
      title: '客服管理'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/service/index'));
      });
    }
  }]
};
var _default = exports.default = configRouter;