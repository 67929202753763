"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "CouponUser",
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  props: {
    integralType: [String, Number]
  },
  data: function data() {
    return {
      Loading: false,
      tableFromIssue: {
        pageNum: 1,
        pageSize: 20,
        type: 1
      },
      issueData: {
        data: [],
        total: 0
      },
      timeVal: [],
      uid: "",
      showdetail: false
    };
  },
  watch: {
    integralType: function integralType(val, oldVal) {
      this.getIssueList();
    }
  },
  mounted: function mounted() {
    // this.getIssueList();
  },
  methods: {
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(6);
    },
    derive: function derive() {
      var _this = this;
      var data = {
        user: this.tableFromIssue.user
      };
      (0, _marketing.scoreExport)(data).then(function (res) {
        _this.$message.success("导出成功");
      });
    },
    // 列表
    getIssueList: function getIssueList() {
      var _this2 = this;
      if (this.integralType) {
        this.tableFromIssue.scoreTypeId = this.integralType;
      } else {
        this.tableFromIssue.scoreTypeId = "";
      }
      this.Loading = true;
      (0, _marketing.integralReceivingRecordListApi)(this.tableFromIssue).then(function (res) {
        _this2.issueData.data = res.rows;
        _this2.issueData.total = res.total;
        _this2.Loading = false;
      }).catch(function (res) {
        _this2.Loading = false;
        // this.$message.error(res.message)
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      if (e) {
        this.tableFromIssue.startTime = e[0];
        this.tableFromIssue.endTime = e[1];
      } else {
        this.tableFromIssue.startTime = "";
        this.tableFromIssue.endTime = "";
      }
      this.getIssueList();
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.pageNum = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.pageSize = val;
      this.getIssueList();
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.userId;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    }
  }
};