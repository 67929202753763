var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control attribute", class: { "no-tabs": !_vm.is_tab } },
    [
      _c("h3", { staticClass: "control-content__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.is_tab
        ? _c(
            "el-tabs",
            { staticClass: "control-content__tabs", attrs: { value: "first" } },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "内容设置", name: "first" } },
                [_vm._t("content")],
                2
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "样式设置", name: "second" } },
                [_vm._t("style")],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }