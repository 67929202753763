var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "优先购名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "selwidth",
                    attrs: { placeholder: "请输入优先购名称" },
                    model: {
                      value: _vm.formValidate.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "name", $$v)
                      },
                      expression: "formValidate.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型：", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.$route.query.id },
                      model: {
                        value: _vm.formValidate.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "type", $$v)
                        },
                        expression: "formValidate.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("按快照名单"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("自定义名单、时间、数量"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "限购规则：", prop: "restrictedType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.$route.query.id },
                      model: {
                        value: _vm.formValidate.restrictedType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "restrictedType", $$v)
                        },
                        expression: "formValidate.restrictedType",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 1 } },
                        [
                          _vm._v("按“藏品”可购上限\n            "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                title: "按“藏品”可购上限",
                                width: "300",
                                trigger: "hover",
                                content:
                                  "例：“优先购”限购2，“抢购”限购1，则用户分别在两个时段可购买2个+1个。",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "13px",
                                  height: "13px",
                                  position: "relative",
                                  bottom: "-1px",
                                },
                                attrs: {
                                  slot: "reference",
                                  src: "https://saas.cdn.yunzongbu.cn/image/20221019/7754df66419f6442c129bbd93477818c.png",
                                  alt: "",
                                },
                                slot: "reference",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { attrs: { label: 2 } },
                        [
                          _vm._v("按“优先购期间”可购上限\n            "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                title: "按“优先购期间”可购上限",
                                width: "300",
                                trigger: "hover",
                                content:
                                  "例：“优先购”限购2，“抢购”限购1，则用户分别在两个时段可购买总数2个。",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "13px",
                                  height: "13px",
                                  position: "relative",
                                  bottom: "-1px",
                                },
                                attrs: {
                                  slot: "reference",
                                  src: "https://saas.cdn.yunzongbu.cn/image/20221019/7754df66419f6442c129bbd93477818c.png",
                                  alt: "",
                                },
                                slot: "reference",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formValidate.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "关联名单：", prop: "type" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                placeholder: "请选择快照名单",
                                clearable: "",
                                multiple: "",
                              },
                              on: { change: _vm.snapshotIdsChange },
                              model: {
                                value: _vm.formValidate.snapshotIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "snapshotIds", $$v)
                                },
                                expression: "formValidate.snapshotIds",
                              },
                            },
                            _vm._l(_vm.snapshotList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { value: item.id, label: item.name },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml20" }, [
                            _vm._v(
                              "总人数：共" +
                                _vm._s(_vm.checkSnapshot.totalNum || 0) +
                                "条记录/共" +
                                _vm._s(_vm.checkSnapshot.removalNum || 0) +
                                "人"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "ml20",
                              model: {
                                value: _vm.formValidate.hasMergeSnapshots,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "hasMergeSnapshots",
                                    $$v
                                  )
                                },
                                expression: "formValidate.hasMergeSnapshots",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12px" } },
                                [_vm._v("合并重复名单")]
                              ),
                              _c(
                                "span",
                                { staticClass: "ml10 ft12 color-gray" },
                                [_vm._v("(勾选后如有多条记录，则按一条计算)")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "导入规则名单：" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "myUpload",
                          staticClass: "upload-demo",
                          attrs: {
                            action: "",
                            headers: _vm.myHeaders,
                            "http-request": _vm.uploadRequest,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("导入规则表格")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: { click: _vm.downLoad },
                        },
                        [_vm._v("下载规则模板")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "设置藏品：", prop: "products" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.formValidate.products,
                        size: "small",
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "80",
                          prop: "productType",
                          label: "商品类型",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatProductType")(
                                        row.productType
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          width: "60",
                          label: "图片",
                          prop: "coverPicture",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                      },
                                      attrs: { src: row.coverPicture },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "100",
                          prop: "productName",
                          label: "商品名称",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [_vm._v(_vm._s(row.productName))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "110",
                          prop: "price",
                          label: "价格",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_c("div", [_vm._v(_vm._s(row.price))])]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _vm.formValidate.type == 1
                        ? _c(
                            "el-table-column",
                            {
                              attrs: {
                                "min-width": "240",
                                label: "优先购数量",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: { size: "mini" },
                                            model: {
                                              value: row.firstBuyCountType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "firstBuyCountType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "row.firstBuyCountType",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mq0 flex align-items-c",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 1 } },
                                                  [_vm._v("按设置")]
                                                ),
                                                _vm._v(" "),
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "80px",
                                                    "margin-right": "10px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请输入",
                                                    controls: false,
                                                    min: 1,
                                                    max: 50,
                                                    disabled:
                                                      row.firstBuyCountType !=
                                                      1,
                                                  },
                                                  model: {
                                                    value: row.firstBuyCount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "firstBuyCount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.firstBuyCount",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 2 } },
                                                  [_vm._v("按持仓")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2633098508
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c" },
                                  [
                                    _c("span", { staticClass: "mr5" }, [
                                      _vm._v("优先购数量"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          title: "按“藏品”可购上限",
                                          width: "400",
                                          trigger: "hover",
                                          content: "",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _c("div", [
                                            _vm._v(
                                              "按设置数量：统一按设置的数量做为购买上限。"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "按持仓数量：按快照名单中的持仓数做为购买上限，名单中同时存在多个藏品持仓，系统会以“数量总和”做为购买上限。"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "选择的名单中如果有重复用户则“数量叠加”"
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticStyle: {
                                            width: "13px",
                                            height: "13px",
                                            position: "relative",
                                            display: "block",
                                            cursor: "pointer",
                                          },
                                          attrs: {
                                            slot: "reference",
                                            src: "https://saas.cdn.yunzongbu.cn/image/20221019/7754df66419f6442c129bbd93477818c.png",
                                            alt: "",
                                          },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.type == 1
                        ? _c("el-table-column", {
                            attrs: {
                              "min-width": "160",
                              label: "优先购购买时间",
                              prop: "aheadBuyTime",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-input-number", {
                                        staticStyle: {
                                          width: "80px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          min: 1,
                                          controls: false,
                                        },
                                        model: {
                                          value: row.aheadBuyTime,
                                          callback: function ($$v) {
                                            _vm.$set(row, "aheadBuyTime", $$v)
                                          },
                                          expression: "row.aheadBuyTime",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("分钟")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3857993472
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "140",
                          label: "发售时间",
                          prop: "startTime",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [_vm._v(_vm._s(row.startTime))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { "min-width": "80", label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: { click: _vm.productDel },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.$route.query.id && !_vm.formValidate.products.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProducts(1)
                            },
                          },
                        },
                        [_vm._v("添加数字藏品")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$route.query.id && !_vm.formValidate.products.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProducts(2)
                            },
                          },
                        },
                        [_vm._v("添加数字盲盒")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$route.query.id && !_vm.formValidate.products.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProducts(3)
                            },
                          },
                        },
                        [_vm._v("添加专辑")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "短信通知：", prop: "hasSms" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#cccccc",
                        },
                        model: {
                          value: _vm.formValidate.hasSms,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "hasSms", $$v)
                          },
                          expression: "formValidate.hasSms",
                        },
                      }),
                      _vm._v(" "),
                      _vm.formValidate.hasSms
                        ? _c("el-date-picker", {
                            staticClass: "ml10",
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: "选择发送时间",
                            },
                            model: {
                              value: _vm.formValidate.smsSendTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "smsSendTime", $$v)
                              },
                              expression: "formValidate.smsSendTime",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml10" }, [
                        _vm._v("开启后，给优先购的用户发送短信通知"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.visibleTitle,
            visible: _vm.visible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.visibleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-list mt20" },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: {
                    size: "small",
                    clearable: "",
                    placeholder: "名称搜索",
                  },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.productForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.productForm, "name", $$v)
                    },
                    expression: "productForm.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.goSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  key: Math.random(),
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.productList.data, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.coverPicture },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseRow(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.choose ? "取消选择" : "选择")
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.productList.pageSize,
                  "current-page": _vm.productList.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.productList.total,
                },
                on: {
                  "size-change": _vm.productFormSizeChange,
                  "current-change": _vm.productFormpageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }