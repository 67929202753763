"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _finance = require("@/api/finance");
var _detailed = _interopRequireDefault(require("./table/detailed.vue"));
var _entry = _interopRequireDefault(require("./table/entry.vue"));
var _output = _interopRequireDefault(require("./table/output.vue"));
var _drawIndex = _interopRequireDefault(require("./table/drawIndex.vue"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//出金
//入金
var _default = exports.default = {
  components: {
    DetailedTable: _detailed.default,
    EntryTable: _entry.default,
    OutputTable: _output.default,
    DrawIndexVue: _drawIndex.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      form: {
        operationType: 1,
        type: "2",
        pageSize: 20,
        pageNum: 1,
        orderByColumn: "",
        // 拍序列
        isAsc: "",
        userId: "",
        user: "",
        // 手机号或用户名
        timeType: "" // 时间类型 today yesterday lately7 lately30 month year 或 yyyy/MM/dd-yyyy/MM/dd
      },
      settingForm: {},
      params: null,
      balanceType: "detailed",
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: ""
      },
      // form: {
      //   pageNum: 1,
      //   pageSize: 20,
      //   timeType: ''
      // },
      timeType: [],
      isClose: false,
      timeList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    exportOrder: function exportOrder() {
      var _this = this;
      // if (this.form.type == '1') {

      // }
      if (this.form.type == "-1" || this.form.type == "1") {
        (0, _finance.getExportDetail)(this.form).then(function (res) {
          _this.$message.success("操作成功");
        });
      }
      if (this.form.type == 2) {
        (0, _finance.getExport)({
          type: 23,
          timeType: this.form.timeType,
          user: this.form.user
        }).then(function (res) {
          _this.$message.success("操作成功");
        });
      }
      // alert(" 暂未开发");
    },
    getExportFileList: function getExportFileList() {
      if (this.form.type == "2") {
        this.$refs.exportList.exportFileList(60);
      }
      if (this.form.type == "1") {
        if (this.form.operationType == 1) {
          this.$refs.exportList1.exportFileList(61);
        } else if (this.form.operationType == 2) {
          this.$refs.exportList1.exportFileList(62);
        }
      }
      if (this.form.type == "-1") {
        if (this.form.operationType == 3) {
          this.$refs.exportList1.exportFileList(63);
        } else if (this.form.operationType == 4) {
          this.$refs.exportList1.exportFileList(64);
        }
      }
    },
    // 组件关闭弹框
    close: function close(val) {
      console.log(val, 176);
      this.isClose = val;
    },
    drawerParam: function drawerParam(val) {
      this.params = JSON.parse(val);
    },
    // 请求列表
    getList: function getList() {
      // this.listLoading = true;
      // this.form.pageNum = num || this.form.pageNum;
      console.log(this.form, 196);
      this.tableData.total = 0;
      this.tableData.statistical = null;
      this.tableData.data = [];
      if (this.form.type !== "2") {
        this.getLists();
      } else {
        this.getListCount();
      }
    },
    getLists: function getLists() {
      var _this2 = this;
      this.listLoading = true;
      (0, _finance.getEntryList)(this.form).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.tableData.statistical = res.statistical.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    getListCount: function getListCount() {
      var _this3 = this;
      var obj = {
        type: ""
      };
      var forms = Object.assign({}, this.form, obj);
      console.log(forms, 201);
      (0, _finance.getBalanceList)(this.form).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        if (res.statistical) {
          _this3.tableData.statistical = res.statistical.count;
        } else {
          _this3.tableData.statistical = 0;
        }
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    selectChange: function selectChange(tab) {
      this.timeType = [];
      this.form.timeType = tab;
      this.getList();
    },
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.form.timeType = e ? this.timeType.join("-") : "";
      this.getList();
    },
    changeTypeAction: function changeTypeAction(tab) {
      var _this4 = this;
      console.log(tab.name);
      if (tab.name == -1) {
        this.form.operationType = 3;
      }
      this.form.type = tab.name;
      if (tab.name !== "setting") {
        this.getList();
      } else {
        var loadingInstance = _elementUi.Loading.service();
        (0, _finance.getPayInfoApi)().then(function (res) {
          _this4.settingForm = res.data;
          _this4.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this4.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }
    },
    pageChange: function pageChange(page) {
      this.form.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.form.pageSize = val;
      this.getList();
    },
    saveAudit: function saveAudit() {
      var _this5 = this;
      var loadingInstance = _elementUi.Loading.service();
      var _this$settingForm = this.settingForm,
        id = _this$settingForm.id,
        maxPrice = _this$settingForm.maxPrice,
        hasAudit = _this$settingForm.hasAudit;
      var data = {
        id: id,
        maxPrice: maxPrice,
        hasAudit: hasAudit
      };
      (0, _finance.updateInfoApi)(data).then(function (res) {
        _this5.$message.success("保存成功");
        _this5.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (err) {
        _this5.$nextTick(function () {
          loadingInstance.close();
        });
      });
    }
  }
};