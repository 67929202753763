var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "content-left " }, [
      _c("h3", { staticClass: "content-title" }, [
        _vm._v(_vm._s(_vm.demandInfo.title)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex content-tags" }, [
        _c("div", { staticClass: "content-tag" }, [_vm._v("投标")]),
        _vm._v(" "),
        _c("div", { staticClass: "content-tag" }, [_vm._v("3D模型设计")]),
        _vm._v(" "),
        _c("div", { staticClass: "content-tag" }, [
          _vm._v(_vm._s(_vm.demandInfo.modelTypeName)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content-tag site flex" }, [
          _c("img", {
            staticClass: "site-icon",
            attrs: { src: require("@/assets/images/address.png") },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.demandInfo.cityName)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-info" }, [
        _c("div", { staticClass: "info-title flex" }, [
          _c("div", { staticClass: "type" }, [_vm._v("需求标题")]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.demandInfo.title))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-title flex" }, [
          _c("div", { staticClass: "type" }, [_vm._v("需求描述")]),
          _vm._v(" "),
          _c("div", { staticClass: "info" }, [
            _c("span", { staticClass: "mr-5" }, [
              _vm._v(
                "\n            模型类型：" +
                  _vm._s(_vm.demandInfo.modelTypeName) +
                  "；\n          "
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "mr-5" }, [
              _vm._v(" 尺寸要求：" + _vm._s(_vm.demandInfo.size) + " "),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "info-desc" },
              _vm._l(_vm.demandDescList, function (desc, index) {
                return _c("li", { key: index, staticClass: "desc-item" }, [
                  _vm._v("\n              " + _vm._s(desc) + "\n            "),
                ])
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-title flex" }, [
          _c("div", { staticClass: "type" }, [_vm._v("补充说明")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "info" },
            _vm._l(_vm.files, function (file, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "file",
                  attrs: { href: file.fileUrl, target: "_blank" },
                },
                [_vm._v(_vm._s(file.fileName))]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-title flex" }, [
          _c("div", { staticClass: "price-type type" }, [_vm._v("预算金额")]),
          _vm._v(" "),
          _c("div", { staticClass: "info price" }, [
            _vm._v(_vm._s(_vm.demandInfo.budget)),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-bottom" },
      [
        _c("div", [_vm._v("操作日志：")]),
        _vm._v(" "),
        _c(
          "el-table",
          { attrs: { data: _vm.demandInfo.demandLogs } },
          [
            _c("el-table-column", {
              attrs: { prop: "createTime", label: "时间", width: "180" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作人", width: "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            row.userType == 1
                              ? "雇主"
                              : row.userType == 2
                              ? "服务商"
                              : ""
                          ) +
                            " " +
                            _vm._s(row.nickName)
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "title", label: "操作内容" },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-center flex" }, [
      _c("div", { staticClass: "center-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("参与人数")]),
        _vm._v(" "),
        _c("span", { staticClass: "text" }, [_vm._v("不限")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "center-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("参与地区")]),
        _vm._v(" "),
        _c("span", { staticClass: "text" }, [_vm._v("不限")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "center-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("参与者规模")]),
        _vm._v(" "),
        _c("span", { staticClass: "text" }, [_vm._v("不限")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "center-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("反馈要求")]),
        _vm._v(" "),
        _c("span", { staticClass: "text" }, [_vm._v("无要求")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "center-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("开票要求")]),
        _vm._v(" "),
        _c("span", { staticClass: "text" }, [_vm._v("无要求")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }