"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var panels = {
  basics: {
    title: '通用组件',
    children: [{
      name: 'nftInfo',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/navigation_icon.png',
      title: '用户NFT详情',
      content: {},
      // 外观
      facade: {
        left_image: 'https://saas.cdn.yunzongbu.cn/image/20220801/967ff95a41cb70f5783ea2124beabc0b.png',
        copy_image: 'https://saas.cdn.yunzongbu.cn/image/20220801/249582472b5b940f5d4054bf85f8e73f.png',
        ewm_image: 'https://saas.cdn.yunzongbu.cn/image/20220801/bdf0d5003f6c4108bbe4d8f0a2093397.png',
        background: 'rgb(17, 63, 170, 1)',
        bg_image: '',
        item_height: 72,
        page_margin: 30,
        page_radius: 0,
        page_shadow: '#24273D',
        page_shadow_size: 10,
        item_padding: 20,
        title_color: '#ffffff',
        title_style: 500,
        title_size: 28,
        blockchain_color: '#999999'
      }
    }, {
      name: 'mine_nav',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/navigation_icon.png',
      title: '图文栏目',
      content: {
        style: 1,
        title: '',
        right_desc: '',
        is_member_desc: '',
        data: [{
          url: '',
          title: '菜单1',
          right_title: '',
          link: {}
        }, {
          url: '',
          title: '菜单2',
          right_title: '',
          link: {}
        }, {
          url: '',
          title: '菜单3',
          right_title: '',
          link: {}
        }, {
          url: '',
          title: '菜单4',
          right_title: '',
          link: {}
        }, {
          url: '',
          title: '菜单5',
          right_title: '',
          link: {}
        }]
      },
      // 外观
      facade: {
        nav_style: 1,
        // 展示方式 1 居左 居中
        background: '#FFFFFF',
        bg_image: '',
        page_margin: 30,
        page_radius: 8,
        page_shadow: '#D9D9D9',
        page_shadow_size: 10,
        line_count: 5,
        alignment: 'center',
        item_padding: 20,
        title_color: '#000000',
        title_style: 500,
        title_size: 28,
        title_right_color: '#000000',
        title_right_style: 400,
        title_right_size: 24,
        text_color: '#000000',
        text_style: 400,
        text_size: 24,
        vice_text_color: '#B60005',
        vice_text_style: 400,
        vice_text_size: 24
      }
    }, {
      name: 'banner',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/banner_icon.png',
      title: '轮播图',
      content: {
        style: 1,
        // 风格模板选择  1  单张滑动  2 左右露出  3 右侧露出
        view_width: 750,
        view_height: 750,
        data: [{
          url: '',
          link: {}
        }, {
          url: '',
          link: {}
        }, {
          url: '',
          link: {}
        }]
      },
      // 外观
      facade: {
        background_color: '',
        img_radius: 0,
        // 图上圆角
        img_bottom_radius: 0,
        // 图下圆角
        page_padding: 0,
        // 页面边距
        item_padding: 0,
        // 容器边距
        chamfer_style: 1,
        // 倒角样式
        indicator_style: 2,
        // 指示器样式  1 远点  2 长条
        indicator_align: 'center',
        // 指示器位置
        indicator_color: '#dd0000' // 指示器颜色
      }
    }, {
      name: 'title',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/mokuai_icon.png',
      title: '标题栏',
      content: {
        style: 1,
        title: '标题文字',
        subtitle: '二级标题',
        is_more: true,
        moretitle: '更多',
        morelink: {},
        title_img: ''
      },
      // 外观
      facade: {
        title_color: '#000000',
        title_font_size: 40,
        subtitle_color: '#999999',
        subtitle_font_size: 24,
        more_color: '#000000',
        more_font_size: 24,
        more_img: 1,
        // 更多箭头图标 1 黑色 2 白色
        background_color: '',
        page_padding: 40
      }
    }, {
      name: 'blank',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/kongge_icon.png',
      title: '分割',
      content: {
        has_line: false
      },
      // 外观
      facade: {
        height: 20,
        background_color: '',
        line_width: 375,
        line_height: 1,
        line_color: '#DDDDDD'
      }
    }, {
      name: 'rubik',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/image_icon.png',
      title: '图片',
      content: {
        style: 1,
        // 风格 1  2  3  4  5  6
        density: 4,
        // 魔方密度
        data: [{
          url: '',
          link: ''
        }]
      },
      // 外观
      facade: {}
    }, {
      name: 'video',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/video_icon.png',
      title: '视频',
      content: {
        video: {},
        cover: '',
        autoplay: false,
        loop: true,
        source: 0,
        address: ''
      },
      // 外观
      facade: {}
    }, {
      name: 'notice',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/gonggao_icon.png',
      title: '公告',
      content: {
        title: '',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20211022/28c84b92d651186d4bf97a62d8cd0400.png',
        data: [],
        right_link: {},
        right_text: '',
        right_icon: ''
      },
      // 外观
      facade: {
        title_color: '#d19974',
        // 文字颜色
        speed: 80,
        page_padding: 30,
        // 页面边距
        item_padding: 30,
        // 容器边距
        background_color: '#fff8f0',
        // 背景颜色
        roll_type: 1,
        // 滚动方式  1横向  2竖向
        page_margin: 0,
        radius: 0,
        item_shadow: '',
        // 阴影颜色
        item_shadow_size: 0,
        // 阴影大小
        right_title_color: '',
        right_padding: 0
      }
    }, {
      name: 'textView',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/mokuai_icon.png',
      title: '文字',
      content: {
        title: '标题文字',
        subtitle: '内容文字'
      },
      // 外观
      facade: {
        theme: 1,
        page_margin: 0,
        // 页面间距
        item_margin: 30,
        // 内容间距
        item_background: '#FFFFFF',
        // 内容背景颜色
        item_padding: 30,
        // 内间距
        radius: 8,
        // 内容圆角
        item_shadow: '#DEDEDE',
        // 专场阴影颜色
        item_shadow_size: 10,
        // 专场阴影大小
        title_margin: 20,
        title_color: '#000000',
        // 标题颜色
        title_weight: 500,
        title_size: 28,
        title_text_align: 'left',
        desc_margin: 30,
        desc_color: '#999999',
        desc_weight: 400,
        desc_size: 24,
        desc_line_height: 40,
        desc_text_align: 'left'
      }
    }, {
      name: 'memberEnter',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/gonggao_icon.png',
      title: '会员入口',
      content: {
        // title: '',
        // icon: 'https://saas.cdn.yunzongbu.cn/image/20211022/28c84b92d651186d4bf97a62d8cd0400.png',
        // data: [],
        // right_link: {},
        // right_text: '',
        // right_icon: ''
      },
      facade: {
        item_padding: 32,
        page_margin: 32,
        m_height: 96,
        m_use_image: 1,
        // 是否使用背景图片 1，不使用 2，使用
        m_integral_color: '#765A25',
        // 积分颜色
        m_integral_font_w: 400,
        // 是否加粗
        m_level_color: '#765A25',
        // 等级颜色
        m_level_font_w: 500,
        // 是否加粗
        m_bg: 'linear-gradient(90deg, #F4E4C3 0%, #D8B57F 100%)',
        m_bg_image: 'https://saas.cdn.yunzongbu.cn/image/20211013/45a77dffada667126effa371b1c1c6c9.png',
        // 背景图片
        m_right_icon: 'https://saas.cdn.yunzongbu.cn/image/20211013/45a77dffada667126effa371b1c1c6c9.png',
        // 右侧icon
        m_border_radio: 8,
        // 整体圆角
        m_fontSize: 18,
        // 积分字号
        m_level_fontSize: 12,
        // 等级字号
        m_desc_fontSize: 10 // 描述字号
      }
    }]
  }
};
var _default = exports.default = panels;