var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTypeAction },
              model: {
                value: _vm.userType,
                callback: function ($$v) {
                  _vm.userType = $$v
                },
                expression: "userType",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "等级管理", name: "grade" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "升级任务", name: "uptask" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("h4", [_vm._v("基础配置")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-switch", {
                  model: {
                    value: _vm.gradeDetail.isBuyProductLevelGrowth,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeDetail, "isBuyProductLevelGrowth", $$v)
                    },
                    expression: "gradeDetail.isBuyProductLevelGrowth",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("购买发售藏品，每消费1元，获得")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "w100",
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.gradeDetail.buyProductLevelGrowth,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeDetail, "buyProductLevelGrowth", $$v)
                    },
                    expression: "gradeDetail.buyProductLevelGrowth",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("成长值")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt10" },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.gradeDetail.isBuyMacketLevelGrowth,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeDetail, "isBuyMacketLevelGrowth", $$v)
                    },
                    expression: "gradeDetail.isBuyMacketLevelGrowth",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("购买寄售藏品，每消费1元，获得")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "w100",
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.gradeDetail.buyMacketLevelGrowth,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeDetail, "buyMacketLevelGrowth", $$v)
                    },
                    expression: "gradeDetail.buyMacketLevelGrowth",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("成长值")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt10" },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.gradeDetail.isBuyMaterialLeveGrowth,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeDetail, "isBuyMaterialLeveGrowth", $$v)
                    },
                    expression: "gradeDetail.isBuyMaterialLeveGrowth",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("购买实物商品，每消费1元，获得")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "w100",
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.gradeDetail.buyMaterialLeveGrowth,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeDetail, "buyMaterialLeveGrowth", $$v)
                    },
                    expression: "gradeDetail.buyMaterialLeveGrowth",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("成长值")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "mt20",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.updateHandle },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }