"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _demand = require("@/api/demand");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["tableData"],
  methods: {
    onShowDemand: function onShowDemand(row, isShow) {
      var _this = this;
      this.$emit('changeLoading', true);
      (0, _demand._onShowDemand)({
        id: row.id,
        hasShow: isShow
      }).then(function (res) {
        _this.$message({
          message: '操作成功',
          type: 'success'
        });
        _this.$emit('getDemandList', 1);
      }).catch(function (err) {
        console.log(err);
        _this.$emit('changeLoading', false);
      });
    }
  }
};