var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              staticClass: "attrFrom mb20",
              attrs: {
                size: "small",
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "版本名称：", prop: "versionType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "auto-width",
                              model: {
                                value: _vm.formData.versionType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "versionType", $$v)
                                },
                                expression: "formData.versionType",
                              },
                            },
                            _vm._l(_vm.productTypeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "购买价格：", prop: "price" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入购买金额" },
                            on: {
                              blur: function ($event) {
                                return _vm.numInput(
                                  "price",
                                  2,
                                  0,
                                  99999999999999
                                )
                              },
                            },
                            model: {
                              value: _vm.formData.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "price", $$v)
                              },
                              expression: "formData.price",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品模式：", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "auto-width",
                              model: {
                                value: _vm.formData.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "type", $$v)
                                },
                                expression: "formData.type",
                              },
                            },
                            _vm._l(_vm.modeType, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "空间上限：", prop: "spaceCount" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入版本名称" },
                            on: {
                              input: function ($event) {
                                return _vm.numInput(
                                  "spaceCount",
                                  0,
                                  0,
                                  99999999999999
                                )
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "suffix",
                                fn: function () {
                                  return [_vm._v("个")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.formData.spaceCount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "spaceCount", $$v)
                              },
                              expression: "formData.spaceCount",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "在线人数：", prop: "onlineCount" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入版本名称" },
                            on: {
                              input: function ($event) {
                                return _vm.numInput(
                                  "onlineCount",
                                  0,
                                  0,
                                  99999999999999
                                )
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "suffix",
                                fn: function () {
                                  return [_vm._v("人")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.formData.onlineCount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "onlineCount", $$v)
                              },
                              expression: "formData.onlineCount",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "免费客户端数量：",
                            prop: "defaultClientCount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入免费客户端数量" },
                            on: {
                              input: function ($event) {
                                return _vm.numInput(
                                  "defaultClientCount",
                                  0,
                                  0,
                                  6
                                )
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "suffix",
                                fn: function () {
                                  return [_vm._v("个")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.formData.defaultClientCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "defaultClientCount",
                                  $$v
                                )
                              },
                              expression: "formData.defaultClientCount",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("formRef")
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }