"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _AIGCApi = require("@/api/AIGCApi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "numberStyle",
  components: {},
  data: function data() {
    return {
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      editCateData: {
        show: false,
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.editCateData.image = img[0];
        _this.$forceUpdate();
      }, tit);
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _AIGCApi.getStyleListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.editCateData = {
        show: true,
        name: '',
        image: ''
      };
    },
    // 编辑
    onEdit: function onEdit(m) {
      this.editCateData = {
        show: true,
        name: m.name,
        id: m.id,
        image: m.image
      };
    },
    saveAction: function saveAction() {
      var _this3 = this;
      if (!this.editCateData.name) {
        this.$message.error('请输入标签名称');
        return false;
      }
      var action = _AIGCApi.humanStyleSave;
      if (this.editCateData.id) {
        action = _AIGCApi.updateStyleUpd;
      }
      action(this.editCateData).then(function () {
        _this3.$message.success('操作成功');
        _this3.getList();
        _this3.editCateData.show = false;
      }).catch(function (_ref) {
        var message = _ref.message;
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _AIGCApi.styleDel)(id).then(function (_ref2) {
          var message = _ref2.message;
          _this4.$message.success('操作成功');
          _this4.getList();
        }).catch(function (_ref3) {
          var message = _ref3.message;
        });
      });
    }
  }
};