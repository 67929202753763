"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _frame = require("@/api/frame");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var activeIndexObj = {
  "1": "frameList",
  "2": "frameType",
  "3": "framePaper",
  "4": "frameScence",
  "5": "frameFrame"
};
var _default = exports.default = {
  name: "frameList",
  data: function data() {
    return {
      srcList: [],
      type: "0",
      worksName: "",
      worksOwn: "",
      lists: [],
      queryParams: {
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      saleflag: false,
      price: "",
      work: {},
      model1: false,
      formList: {
        image: "",
        id: "",
        type: 1,
        pos: [{
          x: "",
          y: "",
          w: "",
          h: ""
        }]
      },
      frameTypeList: ["一副", "二副", "三幅", "四幅", "五幅"]
    };
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      this.$router.push({
        name: activeIndexObj[index]
      });
    },
    onUpload: function onUpload(type) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (img.length > 1) return _this.$message.error("只能选择一张");
        _this.formList.image = img[0];
      });
    },
    handleRemove: function handleRemove(file) {
      this.formList.image = "";
    },
    changeType: function changeType(type) {
      this.queryParams.pageNum = 1;
      this.load();
    },
    save: function save() {
      var _this2 = this;
      var json = this.formList;
      if (!json.image) return this.$message.warning("请上传图片");
      if (json.id) {
        (0, _frame._updateModel)(json).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success("编辑成功");
            _this2.onClose();
            _this2.load();
          } else {
            _this2.$message.error(res.msg);
          }
        }).chact(function (err) {
          console.log(err);
          _this2.$message.error(err.message);
        });
      } else {
        (0, _frame._createModel)(json).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success("添加成功");
            _this2.onClose();
            _this2.queryParams.pageNum = 1;
            _this2.load();
          } else {
            _this2.$message.error(res.msg);
          }
        }).catch(function (err) {
          console.log(err);
          _this2.$message.error(err.message);
        });
      }
    },
    onClose: function onClose() {
      this.model1 = false;
      this.formList = {
        image: "",
        id: "",
        type: 1,
        pos: [{
          x: "",
          y: "",
          w: "",
          h: ""
        }]
      };
    },
    del: function del(row) {
      var _this3 = this;
      var id = row.id;
      var type = this.type;
      (0, _frame._deleteModel)({
        id: id,
        type: type
      }).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success("删除成功");
          _this3.queryParams.pageNum = 1;
          _this3.load();
        } else {
          _this3.$message.error(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
        _this3.$message.error(err.message);
      });
    },
    onAdd: function onAdd() {
      // this.model1 = true;
      this.$router.push({
        name: 'addFrame',
        params: {}
      });
    },
    onEdit: function onEdit(row) {
      // this.formList.image = row.image;
      // this.formList.type = row.type;
      // this.formList.id = row.id;
      // this.formList.pos = row.pos;
      // this.model1 = true;
      this.$router.push({
        name: 'addFrame',
        params: {
          id: row.id,
          wide: row.wide,
          high: row.wide,
          image: row.image,
          pos: row.pos
        }
      });
    },
    edit: function edit(row, type) {
      this.work = row;
      this.price = "";
      if (type === 1) {
        this.saleflag = true;
        return;
      }
      this.handle(type);
    },
    handle: function handle(type) {
      var _this4 = this;
      console.log(1);
      if (type === 1 && !this.price) {
        this.$Message.error("请输入价格");
        return false;
      }
      var json = {
        goods_price: this.price,
        work_id: this.work.work_id,
        state: type,
        goods_photos: this.work.works_image
      };
      return this.$http.post("frame_works_pre_order_confirm_price", json).then(function (res) {
        if (res.code === 200) {
          _this4.$Message.success("操作成功");
          _this4.load();
        }
        _this4.saleflag = false;
      });
    },
    load: function load() {
      var _this5 = this;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      this.resultData = [];
      this.loading = true;
      params.topictype = this.$route.query.type;
      params.type = this.type;
      if (params.type == 0) params.type = "";
      (0, _frame._getModelList)(params).then(function (res) {
        if (res.code === 200) {
          _this5.total = res.total;
          _this5.srcList = res.rows.map(function (item) {
            return item.image;
          });
          _this5.lists = res.rows;
        }
        _this5.loading = false;
      }).catch(function (err) {
        console.log(err);
      });
    },
    changePageSize: function changePageSize(pageSize) {
      this.queryParams.pageSize = pageSize;
      this.$router.push({
        path: this.$route.path,
        query: {
          type: this.queryParams.topictype,
          pageNum: 1,
          pageSize: pageSize
        }
      });
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.pageNum = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          type: this.queryParams.topictype,
          pageNum: newPage,
          pageSize: this.queryParams.pageSize
        }
      });
      this.load();
    },
    change: function change(e) {
      console.log(e);
      var arr = [];
      for (var i = 0; i < e; i += 1) {
        var json = {
          x: "",
          y: "",
          w: "",
          h: ""
        };
        arr.push(json);
      }
      this.formList.pos = arr;
    }
  },
  watch: {
    $route: function $route(to, form) {
      if (to.name === "DataList" && form.name === "DataList") {
        if (to.query.type !== form.query.type) {
          console.log("watch");
        }
      }
    }
  },
  created: function created() {
    this.load();
  }
};