var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c("h3", [_vm._v("创建土地")]),
          _vm._v(" "),
          _c("ul", [
            _c(
              "li",
              { staticClass: "align-items-c" },
              [
                _c("span", [_vm._v("*土地关联")]),
                _vm._v(" "),
                !_vm.form.productName
                  ? _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: { click: _vm.selectAnimal },
                      },
                      [_vm._v("\n          选择\n        ")]
                    )
                  : _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-image", {
                          staticClass: "w50 h50 mr5",
                          attrs: { src: _vm.form.productImage, alt: "" },
                        }),
                        _vm._v(" "),
                        !_vm.$route.query.id
                          ? _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: { click: _vm.selectAnimal },
                              },
                              [_vm._v("更改")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("*土地背景")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "upLoadPicBox",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap("1")
                    },
                  },
                },
                [
                  _vm.form.backGroundImage
                    ? _c("div", { staticClass: "pictrue" }, [
                        _c("img", { attrs: { src: _vm.form.backGroundImage } }),
                      ])
                    : _c("div", { staticClass: "upLoad" }, [
                        _c("i", {
                          staticClass: "el-icon-camera cameraIconfont",
                        }),
                      ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "align-items-c" }, [
              _c(
                "div",
                [
                  _c("span", [_vm._v("*初始肥沃度")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0 },
                    model: {
                      value: _vm.form.initEnergy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "initEnergy", $$v)
                      },
                      expression: "form.initEnergy",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("span", [_vm._v("*最大肥沃度")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0 },
                    model: {
                      value: _vm.form.maxEnergy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "maxEnergy", $$v)
                      },
                      expression: "form.maxEnergy",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "align-items-c" }, [
              _c(
                "div",
                [
                  _c("span", [_vm._v("*肥沃度消耗")]),
                  _vm._v("\n          每"),
                  _c("el-input-number", {
                    staticClass: "ml10 mr10 w100",
                    attrs: { min: 1, controls: false, precision: 0 },
                    model: {
                      value: _vm.form.consumeEnergy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "consumeEnergy", $$v)
                      },
                      expression: "form.consumeEnergy",
                    },
                  }),
                  _vm._v("秒消耗1点\n        "),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "align-items-c" }, [
              _c(
                "div",
                [
                  _c("span", [_vm._v("*自然成长值")]),
                  _vm._v("\n          每"),
                  _c("el-input-number", {
                    staticClass: "ml10 mr10 w100",
                    attrs: { min: 1, controls: false, precision: 0 },
                    model: {
                      value: _vm.form.natureGrow,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "natureGrow", $$v)
                      },
                      expression: "form.natureGrow",
                    },
                  }),
                  _vm._v("秒增长1点\n        "),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "align-items-c" }, [
              _c(
                "div",
                [
                  _c("span", [_vm._v("*每天可浇水")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "ml10 mr10 w100",
                    attrs: { min: 1, controls: false, precision: 0 },
                    model: {
                      value: _vm.form.waterNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "waterNum", $$v)
                      },
                      expression: "form.waterNum",
                    },
                  }),
                  _vm._v("次\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("span", [_vm._v("*每次浇水消耗")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "ml10 mr10 w100",
                    attrs: { min: 1, controls: false, precision: 0 },
                    model: {
                      value: _vm.form.consumeWater,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "consumeWater", $$v)
                      },
                      expression: "form.consumeWater",
                    },
                  }),
                  _vm._v("g\n        "),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "div",
                [
                  _c("span", [_vm._v("*每次浇水补充成长值")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "ml10 mr10 w100",
                    attrs: { min: 1, controls: false, precision: 0 },
                    model: {
                      value: _vm.form.growNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "growNum", $$v)
                      },
                      expression: "form.growNum",
                    },
                  }),
                  _vm._v("g\n        "),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("*产出")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "custom-table" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.outPutData } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "种子名称", width: "200" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    scope.row.seedImage
                                      ? _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.seedImage,
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.seedName)
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectOutPut(
                                                  "seed",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    选择\n                  "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "成熟成长值", width: "200" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  staticClass: "w100 ml5 mr5",
                                  attrs: {
                                    controls: false,
                                    min: 1,
                                    precision: 0,
                                  },
                                  model: {
                                    value: scope.row.matureGrow,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "matureGrow", $$v)
                                    },
                                    expression: "scope.row.matureGrow",
                                  },
                                }),
                                _vm._v("g\n              "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "收获植物" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  _vm._l(
                                    scope.row.savageryGroundPlants,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex align-items-c justify-b",
                                        },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              src: item.plantImage,
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(item.plantName)),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "danger",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteItem(index)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectOutPut(
                                          "manor",
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("添加")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteOutOut(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.outPutData.length
                    ? _c(
                        "el-link",
                        {
                          attrs: { underline: false, type: "primary" },
                          on: { click: _vm.addOutPut },
                        },
                        [_vm._v("+添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c("el-button", { attrs: { type: "" }, on: { click: _vm.goBack } }, [
            _vm._v("返回"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: {
          "current-data": [],
          size: "1",
          showSelect: true,
          needPointsDeductionType: false,
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }