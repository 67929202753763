"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _AIGCApi = require("@/api/AIGCApi");
var _user = require("@/api/user");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _merchant = require("@/api/merchant");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "numberPerson",
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      labelForm: {
        tagId: [],
        userId: []
      },
      tagList: [],
      multipleSelection: [],
      visibleLabel: false,
      searchForm: {
        phoneOrName: ""
      },
      pages: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
      list: [],
      listLoading: false,
      uid: "",
      showdetail: false,
      timeType: [],
      timeList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }]
    };
  },
  watch: {
    searchForm: {
      handler: function handler(val) {
        this.getList(1);
      },
      deep: true
    }
  },
  created: function created() {
    this.getList(1);
    this.getLabelList();
  },
  methods: {
    onPrompt: function onPrompt(id) {
      this.$router.push({
        path: '/merchant/AIGC/prompt',
        query: {
          id: id
        }
      });
    },
    onWorkStyle: function onWorkStyle(id) {
      this.$router.push({
        path: '/merchant/AIGC/workStyleNew',
        query: {
          id: id
        }
      });
    },
    uploadImgAction: function uploadImgAction(row, type) {
      var _this = this;
      this.$modalUpload(function (img) {
        var state = {
          id: row.id
        };
        console.log(img);
        if (type == 2) {
          state.twoDimensionalImage = img[0];
        } else {
          state.threeDimensionalImage = img[0];
        }
        (0, _AIGCApi._changeImage)(state).then(function (res) {
          _this.$message.success('上传成功');
          _this.getList(1);
        }).catch(function (err) {
          console.log(err);
        });
      });
    },
    // 获取列表
    getList: function getList(reset) {
      var _this2 = this;
      if (reset) {
        this.pages.pageNum = 1;
      }
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), this.pages);
      this.listLoading = true;
      (0, _AIGCApi.getMemberList)(data).then(function (res) {
        if (res.code === 200) {
          _this2.total = res.total;
          _this2.list = res.rows;
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    // 修改状态
    onChangeStatus: function onChangeStatus(row, status) {
      var _this3 = this;
      var state = {
        id: row.id,
        status: status
      };
      (0, _AIGCApi._changeMerberStatus)(state).then(function (res) {
        _this3.$message.success("操作成功");
        _this3.getList(1);
      }).catch(function (err) {
        console.log(err);
      });
    },
    onDelete: function onDelete(row) {
      var _this4 = this;
      (0, _AIGCApi._deleteMerber)(row.id).then(function (res) {
        _this4.$message.success("操作成功");
        _this4.getList(1);
      }).catch(function (err) {
        console.log(err);
      });
    },
    changePage: function changePage(page) {
      this.pages.pageNum = page;
      this.getList();
    },
    nickNameClick: function nickNameClick(id) {
      this.uid = id;
      this.showdetail = true;
      console.log(id);
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    batchLabel: function batchLabel() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning("请先选择用户");
      }
      var checkIds = this.multipleSelection.map(function (item) {
        return item.userId;
      });
      this.labelForm.userId = checkIds;
      this.visibleLabel = true;
    },
    // 修改标签
    setLabel: function setLabel(row) {
      this.labelForm.userId = [row.userId];
      var arr = [];
      if (row.tags) {
        row.tags.forEach(function (item) {
          arr.push(item.id);
        });
      }
      this.labelForm.tagId = arr;
      console.log(this.labelForm);
      this.visibleLabel = true;
    },
    confirmLabelAction: function confirmLabelAction(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.labelForm.tagId = _this5.labelForm.tagId.join(",");
          (0, _user.addTagApi)(_this5.labelForm).then(function () {
            _this5.$message.success("操作成功");
            if (_this5.labelForm.userId.length > 1) {
              _this5.$refs.multipleTable.clearSelection();
            }
            _this5.visibleLabel = false;
            _this5.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    getLabelList: function getLabelList() {
      var _this6 = this;
      (0, _user.labelLstApi)({
        pageNum: 1,
        pageSize: 100
      }).then(function (res) {
        _this6.tagList = res.rows;
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectChange: function selectChange(tab) {
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.date = e ? this.timeType.join("-") : "";
      this.queryParams.timeType = this.queryParams.date;
      this.getList(1);
    }
  }
};