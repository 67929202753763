"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.typed.uint8-array");
var _typeof2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.string.link");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _guide = _interopRequireDefault(require("@/components/guide/guide"));
var _pagetitle = _interopRequireDefault(require("@/components/pagetitle/pagetitle"));
var _component = _interopRequireDefault(require("@/views/decoration/modules/nftCollection/component"));
var _page = _interopRequireDefault(require("@/views/decoration/modules/nftCollection/page"));
var _page2 = _interopRequireDefault(require("@/views/decoration/modules/blind/page"));
var _page3 = _interopRequireDefault(require("@/views/decoration/modules/blindDetail/page"));
var _page4 = _interopRequireDefault(require("@/views/decoration/modules/blindOpen/page"));
var _page5 = _interopRequireDefault(require("@/views/decoration/modules/blindOrderList/page"));
var _page6 = _interopRequireDefault(require("@/views/decoration/modules/compose/page"));
var _panels_mine = _interopRequireDefault(require("../../libs/panels_mine.js"));
var _modules = _interopRequireDefault(require("@/views/decoration/modules"));
var _system = require("@/api/system");
var _product = require("@/api/product");
var _vuex = require("vuex");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)("setting"),
  mapGetters = _createNamespacedHelp.mapGetters;
var _default = exports.default = {
  name: "CustomPage",
  components: {
    guide: _guide.default,
    pagetitle: _pagetitle.default,
    nftAttribute: _component.default,
    nftPage: _page.default,
    blindPage: _page2.default,
    blindDetailPage: _page3.default,
    blindOpenPage: _page4.default,
    blindOrderListPage: _page5.default,
    composePage: _page6.default
  },
  data: function data() {
    return {
      panels: _panels_mine.default,
      modules: _modules.default,
      is_totop: false,
      fullHeight: document.documentElement.clientHeight,
      qrcode: {
        wechat: {}
      },
      avatar: "",
      headerHover: false,
      footerHover: false,
      editType: this.$route.query.type || "album_component"
    };
  },
  watch: {
    $route: function $route(val) {
      if (val.name === "basicsDiy" && val.query.type) {
        this.editType = val.query.type;
      }
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, mapGetters({
    getStore: "getStore",
    version: "getVersion"
  })), {}, {
    scrollHeight: function scrollHeight() {
      return {
        height: parseInt(this.fullHeight) - 65 + "px"
      };
    },
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    index: function index() {
      return this.$store.state.finish.index;
    },
    attribute: function attribute() {
      return this.$store.getters.attribute;
    },
    is_attribute: function is_attribute() {
      return this.$store.state.finish.pages.length > 0;
    }
  }),
  created: function created() {
    this.avatar = localStorage.getItem("avatar");
  },
  methods: {
    changeType: function changeType(type) {
      this.$router.replace({
        path: "/merchant/blindAlbumControl/page",
        query: {
          type: type,
          id: this.$route.query.id,
          newtype: this.$route.query.newtype
        }
      });
    },
    handleTplOpen: function handleTplOpen() {
      var _this2 = this;
      return new Promise(function (resolve) {
        _this2.$confirm("选择新模板后，当前页面内容将被替换且不被保存， 请确认是否需要手动保存。", "提示", {
          confirmButtonText: "继续选择模板",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }).then(function () {
          resolve(true);
        }).catch(function () {
          resolve(false);
        });
      });
    },
    /**
     * 处理返回顶部
     * @return {[type]} [description]
     */
    handleTotop: function handleTotop() {
      this.scrollbar.$el.firstChild.scrollTop = 0;
    },
    /**
     * 处理返回顶部
     * @return {[type]} [description]
     */
    handleGuide: function handleGuide() {
      window.localStorage.setItem("is_guide", 0);
      this.$refs.guide.is_close = false;
      this.$refs.guide.onSetPage();
    },
    /**
     * 处理名称
     * @param  {[type]} value [description]
     * @return {[type]}       [description]
     */
    getPageTitle: function getPageTitle(value) {
      if (value && Number(value.length) === 13) {
        return "默认微页面" + this.pageInfo.id;
      } else {
        return value || "默认微页面";
      }
    },
    /**
     * [handlePageName description]
     * @return {[type]} [description]
     */
    handlePageName: function handlePageName() {
      this.$store.commit("finish/setIndex", -2);
    },
    /**
     * 设置组件排序
     * @return {[type]} [description]
     */
    handlePackage: function handlePackage() {
      this.$store.commit("finish/setIndex", -1);
    },
    handleFooterPage: function handleFooterPage() {
      this.$store.commit("finish/setIndex", -3);
    },
    /**
     * 执行页面保存操作
     * @return {[type]} [description]
     */
    handlePageSave: function handlePageSave() {
      var _this3 = this;
      var loading = this.$loading({
        lock: true,
        text: "保存中……",
        spinner: "el-icon-loading"
      });
      if (this.pageInfo.title === "默认微页") {
        return;
      }
      this.actionPageSave().then(function () {
        loading.close();
        _this3.$message.success("保存成功");
        // this.$router.back();
      }).catch(function (error) {
        loading.close();
        _this3.$message.error(error);
        console.error("错误信息", error);
      });
    },
    /**
     * 处理数据保存
     * @return {[type]} [description]
     */
    actionPageSave: function actionPageSave() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var content = _this.$store.state.finish.pages;
        var select_num_type = [];
        content.forEach(function (m, i) {
          if (m.content && m.content.data && m.content.data.length > 0) {
            m.content.data.forEach(function (item, index) {
              console.log(item);
              if (item.link.select_num_type) {
                select_num_type.push({
                  key: "".concat(i, "-").concat(index),
                  type: item.link.select_num_type,
                  desc: item.right_title
                });
              }
            });
          }
        });
        // let newcontent = _this.formatJsonStr(JSON.stringify(content));
        // console.log(newcontent);
        // return
        var data = {
          name: _this.pageInfo.title,
          content: JSON.stringify(content),
          config: JSON.stringify(_this.pageInfo),
          type: _this.editType
        };
        if (_this.$route.query.newtype && _this.editType !== "blind_order_list") {
          if (_this.$route.query.type === "blind_detail_page") {
            data = {
              detail_config_data: JSON.stringify({
                content: content,
                config_data: _this.pageInfo
              })
            };
          } else if (_this.$route.query.type === "blind_detail_pop") {
            data = {
              pop_config_data: JSON.stringify({
                content: content,
                config_data: _this.pageInfo
              })
            };
          } else if (_this.$route.query.type === "blind_open") {
            data = {
              open_box_config_data: JSON.stringify({
                content: content,
                config_data: _this.pageInfo
              })
            };
          }
          (0, _product.blindboxSeriesUpdateApi)(_this.$route.query.id, data).then(function (res) {
            resolve(true);
          }).catch(function (message) {
            return reject(message);
          });
          return;
        }
        (0, _system.diypageCreate)(data).then(function (res) {
          resolve(true);
        }).catch(function (message) {
          return reject(message);
        });
      });
    },
    formatJsonStr: function formatJsonStr(str) {
      if (str === null || str === "{}" || str === undefined) {
        return str;
      }
      try {
        var json = {};
        if (typeof str === 'string') {
          json = JSON.parse(str);
        } else {
          json = str;
        }
        for (var k in json) {
          var kv = json[k];
          try {
            //数组
            if (Array.isArray(kv)) {
              try {
                //json字符串处理
                var sub = kv.toString().replace("[", "").replace("]", "").split(",");
                for (var i = 0; i < sub.length; i++) {
                  if ((0, _typeof2.default)(JSON.parse(sub[i])) == "object") {
                    sub[i] = this.formatJsonStr(sub[i]);
                  }
                }
                json[k] = sub;
              } catch (e) {}
              continue;
            }
            if (kv && typeof kv == "string") {
              // if(k.indexOf('_f')>-1){
              //   delete json[k];
              // }
              if ((kv.indexOf("#") !== -1 || kv.indexOf("rgb") !== -1) && k.indexOf("_f") === -1) {
                var kvf = k + '_f';
                json[kvf] = (0, _index.setRgbaTo16)(kv);
              }
            }
            if ((0, _typeof2.default)(kv) == "object") {
              json[k] = this.formatJsonStr(kv);
            }
          } catch (e2) {}
        }
        return json;
      } catch (e) {}
      return str;
    },
    downloadFile: function downloadFile(content, fileName) {
      // 下载base64图片
      var base64ToBlob = function base64ToBlob(code) {
        var parts = code.split(";base64,");
        var contentType = parts[0].split(":")[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {
          type: contentType
        });
      };
      var aLink = document.createElement("a");
      var blob = base64ToBlob(content); // new Blob([content]);
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    },
    /**
     * 设置为首页
     * 确认设置该微页面为首页？
     * @return {[type]} [description]
     */
    handlePageSetting: function handlePageSetting() {
      // this.$confirm('确认设置该微页面为首页？', '提示', {
      //   confirmButtonText: '确认',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   center: true
      // })
      //   .then(() => {
      //
      //   })
      //   .catch(() => {});
      this.$message.error("暂未开放");
    },
    backAction: function backAction() {
      this.$router.back();
    },
    /**
     * 拖拽拿起事件
     * sort 排序  drag 拖拽
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    dragStart: function dragStart($event) {
      this.$store.commit("finish/setComs", $event);
    },
    /**
     * 拖拽放下事件
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    dragEnd: function dragEnd($event) {
      this.$store.commit("finish/setComs", "");
      $event.preventDefault();
    },
    mouseOver: function mouseOver(key) {
      this[key] = true;
    },
    mouseleave: function mouseleave(key) {
      this[key] = false;
    },
    handleCancelAction: function handleCancelAction() {
      this.$refs.childView.id = this.pageInfo.id;
      console.log(this.pageInfo.id);
      this.$refs.childView.loadPageInfo();
    }
  }
};