"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemForm = require("@/api/systemForm");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UserGroup",
  data: function data() {
    return {
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      editCateData: {
        show: false,
        classifyName: ""
      },
      rules: {
        classifyName: [{
          required: true,
          message: "请输入分类名称",
          trigger: "blur"
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _marketing.planeclassifyList)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.editCateData = {
        show: true,
        name: ""
      };
    },
    // 编辑
    onEdit: function onEdit(m) {
      this.editCateData = {
        show: true,
        classifyName: m.classifyName,
        id: m.id
      };
    },
    saveAction: function saveAction() {
      var _this2 = this;
      if (!this.editCateData.classifyName) {
        this.$message.error("请输入分类名称");
        return false;
      }
      this.$confirm("\u786E\u8BA4".concat(this.editCateData.id ? "修改" : "添加", "\u8BE5\u5206\u7C7B?"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var action = _marketing.addPlaneClass;
        if (_this2.editCateData.id) {
          action = _marketing.editPlaneClass;
        }
        action(_this2.editCateData).then(function () {
          _this2.$message.success("操作成功");
          _this2.getList();
          _this2.editCateData.show = false;
        }).catch(function (_ref) {
          var message = _ref.message;
        });
      }).catch(function () {});
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _marketing.planeclassifyDelete)(id).then(function (_ref2) {
          var message = _ref2.message;
          _this3.$message.success("操作成功");
          _this3.tableData.data.splice(idx, 1);
        }).catch(function (_ref3) {
          var message = _ref3.message;
        });
      });
    }
  }
};