"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var cmsRouter = {
  path: "".concat(_settings.roterPre, "/video"),
  name: 'video',
  meta: {
    icon: 'dashboard',
    title: '视频'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'assembly',
    name: 'assembly',
    meta: {
      title: '视频组件管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/assembly'));
      });
    }
  }, {
    path: 'template',
    name: 'templateList',
    meta: {
      title: '视频模版管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/template'));
      });
    }
  }, {
    path: 'templateDetail',
    name: 'templateDetail',
    meta: {
      title: '视频模版详情',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/templateDetail'));
      });
    }
  }, {
    path: 'fontList',
    name: 'fontList',
    meta: {
      title: '字体管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/fontList'));
      });
    }
  }, {
    path: 'artistList',
    name: 'artistList',
    meta: {
      title: '艺术家管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/artistList'));
      });
    }
  }]
};
var _default = exports.default = cmsRouter;