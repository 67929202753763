var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticClass: "mt20 mb20",
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [_vm._v("添加核销员")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData.data, stripe: "" },
        },
        _vm._l(_vm.columnData, function (item) {
          return _c("el-table-column", {
            key: item.userId,
            attrs: { prop: item.prop, label: item.label, width: item.width },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      item.prop === "avatar"
                        ? [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: scope.row.avatar,
                                "preview-src-list": [scope.row.avatar],
                              },
                            }),
                          ]
                        : item.prop === "handle"
                        ? [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定删除吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteItem(scope.row.userId)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      underline: false,
                                      type: "primary",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row[item.prop]) +
                                  "\n          "
                              ),
                            ]),
                          ],
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": _vm.queryParams.pageSize,
              "current-page": _vm.queryParams.pageNum,
              layout: "total, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: { "current-change": _vm.getList },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加核销员",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              _vm.userId = ""
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "w300",
              attrs: {
                placeholder: "搜索客户昵称/手机号/真实姓名",
                filterable: "",
                remote: "",
                "remote-method": _vm.searchUserAction,
                loading: _vm.userLoading,
              },
              model: {
                value: _vm.userId,
                callback: function ($$v) {
                  _vm.userId = $$v
                },
                expression: "userId",
              },
            },
            _vm._l(_vm.userList, function (item) {
              return _c(
                "el-option",
                {
                  key: item.userId,
                  attrs: { value: item.userId, label: item.nickName },
                },
                [
                  _c("div", { staticClass: "avatar flex align-items-c" }, [
                    _c("img", { attrs: { src: item.avatar, alt: "" } }),
                    _vm._v(" "),
                    _c("div", { staticClass: "user-r flex-one" }, [
                      _c("div", { staticClass: "line1" }, [
                        _vm._v(_vm._s(item.nickName)),
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(item.userPhone))]),
                    ]),
                  ]),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20 mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addUser } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }