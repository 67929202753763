"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      showdetail: false,
      uid: '',
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      listLoading: true,
      queryParams: {
        status: '',
        timeType: '',
        pageNum: 1,
        pageSize: 20
      },
      timeType: [],
      timeList: [{
        text: '全部',
        val: ''
      }, {
        text: '今天',
        val: 'today'
      }, {
        text: '昨天',
        val: 'yesterday'
      }, {
        text: '最近7天',
        val: 'lately7'
      }, {
        text: '最近30天',
        val: 'lately30'
      }, {
        text: '本月',
        val: 'month'
      }, {
        text: '本年',
        val: 'year'
      }],
      payList: []
    };
  },
  filters: {
    formatType: function formatType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '作品';
          break;
        case 2:
          res = '盲盒';
          break;
        default:
          break;
      }
      return res;
    },
    formatStatus: function formatStatus(val) {
      var res = '';
      switch (val) {
        case 0:
          res = '退款中';
          break;
        case 1:
          res = '已退款';
          break;
        default:
          break;
      }
      return res;
    }
  },
  created: function created() {
    this.getPayTypeList();
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    getPayTypeList: function getPayTypeList() {
      var _this = this;
      (0, _order.getPayType)().then(function (res) {
        // console.log(res)
        _this.payList = res.data;
      }).catch(function (res) {});
    },
    exportOrder: function exportOrder() {
      var _this2 = this;
      (0, _order.orderExportRefundApi)(this.queryParams).then(function (res) {
        _this2.$message.success('操作成功');
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(1);
    },
    selectChange: function selectChange(tab) {
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.timeType = e ? this.timeType.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _order.recordListApi)(this.queryParams).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.tableData.data.forEach(function (item) {
          item.payType = _this3.payTypeFilter(item.payType);
        });
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    payTypeFilter: function payTypeFilter(payType) {
      var str = '';
      this.payList.forEach(function (item) {
        if (item.id == payType) {
          str = item.name;
        }
      });
      return str;
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList('');
    },
    showDetail: function showDetail(uid) {
      this.uid = uid;
      this.showdetail = true;
    }
  }
};