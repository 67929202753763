"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _demandDetail = _interopRequireDefault(require("./modules/demandDetail.vue"));
var _contributeList = _interopRequireDefault(require("./modules/contributeList.vue"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['tableData'],
  components: {
    DemandDetail: _demandDetail.default,
    ContributeList: _contributeList.default
  },
  data: function data() {
    return {
      drawerVisib: false,
      contributeVisib: false,
      demandInfo: null
    };
  },
  methods: {
    onDemandInfo: function onDemandInfo(row) {
      this.drawerVisib = true;
      this.demandInfo = row ? (0, _utils.deepClone)(row) : {};
    },
    onContributeList: function onContributeList(row) {
      this.contributeVisib = true;
      this.demandInfo = row ? (0, _utils.deepClone)(row) : {};
    }
  }
};