var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            staticStyle: { "padding-top": "0" },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "flex align-items-c" }, [
              _c(
                "div",
                { staticClass: "img-list" },
                [
                  _vm.ruleData.myProductUrl
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "del",
                            on: {
                              click: function ($event) {
                                return _vm.delImgAction("myProductUrl")
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          attrs: { src: _vm.ruleData.myProductUrl + "!120a" },
                          on: {
                            click: function ($event) {
                              return _vm.showImageAction(1, "myProductUrl")
                            },
                          },
                        }),
                      ]
                    : _c(
                        "div",
                        {
                          staticClass: "item-flex-center",
                          staticStyle: { height: "50px" },
                          on: {
                            click: function ($event) {
                              return _vm.showImageAction(1, "myProductUrl")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "30px",
                              height: "30px",
                              display: "block",
                            },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                ],
                2
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c" }, [
            _c(
              "div",
              { staticClass: "img-list" },
              [
                _vm.ruleData.myProductFloatUrl
                  ? [
                      _c("img", {
                        attrs: {
                          src: _vm.ruleData.myProductFloatUrl + "!120a",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showImageAction(1, "myProductFloatUrl")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "del",
                          on: {
                            click: function ($event) {
                              return _vm.delImgAction("myProductFloatUrl")
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      ),
                    ]
                  : _c(
                      "div",
                      {
                        staticClass: "item-flex-center",
                        staticStyle: { height: "50px" },
                        on: {
                          click: function ($event) {
                            return _vm.showImageAction(1, "myProductFloatUrl")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "30px",
                            height: "30px",
                            display: "block",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                      ]
                    ),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.ruleData.hasWechatPop,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "hasWechatPop", $$v)
                    },
                    expression: "ruleData.hasWechatPop",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("不弹出"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v("提示关注"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _c("div", { staticClass: "flex-one" }, [
            _vm._v("3D作品购买前可体验"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce66", "inactive-color": "#ccc" },
                model: {
                  value: _vm.ruleData.productBuyExperience,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "productBuyExperience", $$v)
                  },
                  expression: "ruleData.productBuyExperience",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce66", "inactive-color": "#ccc" },
                model: {
                  value: _vm.ruleData.hasCirculation,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "hasCirculation", $$v)
                  },
                  expression: "ruleData.hasCirculation",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce66", "inactive-color": "#ccc" },
                model: {
                  value: _vm.ruleData.noShowUnpackingRecord,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "noShowUnpackingRecord", $$v)
                  },
                  expression: "ruleData.noShowUnpackingRecord",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _c("div", { staticClass: "flex-one" }, [_vm._v("编号后缀")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "输入编号" },
                model: {
                  value: _vm.ruleData.numberSuffix,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "numberSuffix", $$v)
                  },
                  expression: "ruleData.numberSuffix",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item  " }, [
          _c("p", [_vm._v("剩余库存展示")]),
          _vm._v(" "),
          _c("ul", { staticClass: "flex" }, [
            _c(
              "li",
              { staticClass: "mr20" },
              [
                _c("span", [_vm._v("数字藏品")]),
                _c("el-switch", {
                  model: {
                    value: _vm.ruleData.nftStockShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "nftStockShow", $$v)
                    },
                    expression: "ruleData.nftStockShow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "mr20" },
              [
                _c("span", [_vm._v("数字盲盒")]),
                _c("el-switch", {
                  model: {
                    value: _vm.ruleData.nftBoxStockShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "nftBoxStockShow", $$v)
                    },
                    expression: "ruleData.nftBoxStockShow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "mr20" },
              [
                _c("span", [_vm._v("积分兑换")]),
                _c("el-switch", {
                  model: {
                    value: _vm.ruleData.scoreStockShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "scoreStockShow", $$v)
                    },
                    expression: "ruleData.scoreStockShow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "mr20" },
              [
                _c("span", [_vm._v("实物藏品")]),
                _c("el-switch", {
                  model: {
                    value: _vm.ruleData.materialStockShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "materialStockShow", $$v)
                    },
                    expression: "ruleData.materialStockShow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "mr20" },
              [
                _c("span", [_vm._v("藏品系列")]),
                _c("el-switch", {
                  model: {
                    value: _vm.ruleData.seriesStockShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "seriesStockShow", $$v)
                    },
                    expression: "ruleData.seriesStockShow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "mr20" },
              [
                _c("span", [_vm._v("nft详情页")]),
                _c("el-switch", {
                  model: {
                    value: _vm.ruleData.nftInfoStockShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "nftInfoStockShow", $$v)
                    },
                    expression: "ruleData.nftInfoStockShow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "mr20" },
              [
                _c("span", [_vm._v("合成")]),
                _c("el-switch", {
                  model: {
                    value: _vm.ruleData.composeIsShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "composeIsShow", $$v)
                    },
                    expression: "ruleData.composeIsShow",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c" }, [
          _c("div", { staticClass: "flex-one" }, [_vm._v("购买须知")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "输入购买须知",
                },
                model: {
                  value: _vm.ruleData.purchaseNotes,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "purchaseNotes", $$v)
                  },
                  expression: "ruleData.purchaseNotes",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "primary" },
              on: { click: _vm.saveAction },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imagesKey + "!m640" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        我的藏品弹出图片"),
      _c("span", [
        _vm._v(
          "（我的藏品页面，当有藏品持仓时，弹出此图片。图片格式支持：JPG、JPEG、PNG、GIF）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        我的藏品浮动小图"),
      _c("span", [
        _vm._v(
          "（我的藏品页面，当有藏品持仓时，关闭弹出图片后，右下角浮动小图。图片格式支持：JPG、JPEG、PNG、GIF）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        公众号弹出配置"),
      _c("span", [
        _vm._v(
          "（未关注公众号用户弹出二维码）（提示关注：可关闭关注弹窗，关闭后一天内不展示）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        不展示区块链流转信息"),
      _c("span", [
        _vm._v(
          "（打开后我的藏品详情页不展示区块链流转信息、我的藏品详情页哈希值、查看证书按钮）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        不展示拆盒记录"),
      _c("span", [_vm._v("（打开后盲盒详情页不展示盲盒开出记录）")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }