var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                size: "small",
                "label-width": "80px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.productType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "productType", $$v)
                        },
                        expression: "queryParams.productType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "4" } }, [
                        _vm._v("短信充值"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("流量充值"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("区块链充值"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("商户余额充值"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "9" } }, [
                        _vm._v("用户余额充值"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "订单状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部\n            "),
                        _vm.tableData.statistical
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "(" + _vm.tableData.statistical
                                    ? _vm.tableData.statistical.all
                                    : 0 + ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("待付款\n            "),
                        _vm.tableData.statistical
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "(" + _vm.tableData.statistical
                                    ? _vm.tableData.statistical.noBuyCount
                                    : 0 + ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "4" } }, [
                        _vm._v("已完成\n            "),
                        _vm.tableData.statistical
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "(" + _vm.tableData.statistical
                                    ? _vm.tableData.statistical.overCount
                                    : 0 + ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "-1" } }, [
                        _vm._v("已取消\n            "),
                        _vm.tableData.statistical
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "(" + _vm.tableData.statistical
                                    ? _vm.tableData.statistical.cancelCount
                                    : 0 + ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "width100", attrs: { label: "时间选择：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "mr20",
                      attrs: { type: "button", size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.selectChange(_vm.queryParams.timeType)
                        },
                      },
                      model: {
                        value: _vm.queryParams.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "timeType", $$v)
                        },
                        expression: "queryParams.timeType",
                      },
                    },
                    _vm._l(_vm.timeList, function (item, i) {
                      return _c(
                        "el-radio-button",
                        { key: i, attrs: { label: item.val } },
                        [_vm._v(_vm._s(item.text) + "\n          ")]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      "value-format": "yyyy/MM/dd HH:mm:ss",
                      format: "yyyy/MM/dd HH:mm:ss",
                      size: "small",
                      type: "datetimerange",
                      placement: "bottom-end",
                      "start-placeholder": "选择开始时间",
                      "end-placeholder": "选择结束时间",
                    },
                    on: { change: _vm.onchangeTime },
                    model: {
                      value: _vm.timeType,
                      callback: function ($$v) {
                        _vm.timeType = $$v
                      },
                      expression: "timeType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "selWidth",
                  attrs: { placeholder: "产品名称", size: "small" },
                  model: {
                    value: _vm.queryParams.productName,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "productName", $$v)
                    },
                    expression: "queryParams.productName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "selWidth",
                  attrs: {
                    placeholder: "客户/买家名称或手机号",
                    size: "small",
                  },
                  model: {
                    value: _vm.queryParams.memberNameOrPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "memberNameOrPhone", $$v)
                    },
                    expression: "queryParams.memberNameOrPhone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "selWidth",
                  attrs: { placeholder: "请输入订单编号", size: "small" },
                  model: {
                    value: _vm.queryParams.orderSn,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderSn", $$v)
                    },
                    expression: "queryParams.orderSn",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "selWidth",
                  attrs: { placeholder: "请输入支付订单号", size: "small" },
                  model: {
                    value: _vm.queryParams.payOrderSn,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "payOrderSn", $$v)
                    },
                    expression: "queryParams.payOrderSn",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "filter-item selWidth",
                  attrs: {
                    placeholder: "请选择支付渠道",
                    size: "small",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.queryParams.payType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "payType", $$v)
                    },
                    expression: "queryParams.payType",
                  },
                },
                _vm._l(_vm.payList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mt10 mb10" }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "order-table table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "订单编号", "min-width": "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("p", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "客户手机号", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("p", [_vm._v(_vm._s(scope.row.phone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "客户姓名", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetail(scope.row.userId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.merName || scope.row.phone))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "充值产品", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.productName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "充值金额", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.shouldPrice
                      ? _c("div", [
                          _vm._v(
                            "￥" +
                              _vm._s(
                                _vm.formatPriceNumber(scope.row.shouldPrice, 2)
                              )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "优惠金额", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.subPrice
                      ? _c("div", [
                          _vm._v(
                            "￥" +
                              _vm._s(
                                _vm.formatPriceNumber(scope.row.subPrice, 2)
                              )
                          ),
                        ])
                      : _c("div", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "实际支付", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.realPrice
                      ? _c("div", [
                          _vm._v(
                            "￥" +
                              _vm._s(
                                _vm.formatPriceNumber(scope.row.realPrice, 2)
                              )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单状态", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("formatStatus")(scope.row.status))),
                    ]),
                    _vm._v(" "),
                    scope.row.status == -2 && scope.row.refundPrice
                      ? _c("div", [
                          _vm._v(
                            "￥" +
                              _vm._s(
                                _vm.formatPriceNumber(scope.row.refundPrice, 2)
                              )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "收款主体", "min-width": "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.mainBodyName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付状态", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("formatStatus")(scope.row.status))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付方式", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.payTypeName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付订单号", "min-width": "160", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.payOrderSn))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付时间", "min-width": "150", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.payTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", "min-width": "150", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "120",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelOrderAction(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          取消订单")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block mt20" },
        [
          _c("el-pagination", {
            staticStyle: { "text-align": "right" },
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.queryParams.pageSize,
              "current-page": _vm.queryParams.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleRefund
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleRefund,
                "close-on-click-modal": false,
                title: "退款",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleRefund = $event
                },
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("支付方式：" + _vm._s(_vm.refundRow.payType || "-")),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("支付订单号：" + _vm._s(_vm.refundRow.payOrderSn)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "支付金额：" + _vm._s(_vm.refundRow.payPrice || "-")
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("支付时间：" + _vm._s(_vm.refundRow.payTime || "-")),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "refundForm",
                  staticClass: "mt20",
                  attrs: {
                    size: "small",
                    model: _vm.refundForm,
                    rules: _vm.refundRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款金额", prop: "refundPrice" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.refundForm.refundPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundForm, "refundPrice", $$v)
                          },
                          expression: "refundForm.refundPrice",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          "(最多可退" + _vm._s(_vm.refundRow.payPrice) + "元)"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款原因" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.refundForm.refundReasonWapExplain,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.refundForm,
                              "refundReasonWapExplain",
                              $$v
                            )
                          },
                          expression: "refundForm.refundReasonWapExplain",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmRefundAction("refundForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleShip
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleShip,
                "close-on-click-modal": false,
                title: _vm.shipTitle,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleShip = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "shipForm",
                  staticClass: "mt20",
                  attrs: {
                    size: "small",
                    model: _vm.shipForm,
                    rules: _vm.shipRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递公司", prop: "deliveryCompanyId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择快递公司",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.shipForm.deliveryCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.shipForm, "deliveryCompanyId", $$v)
                            },
                            expression: "shipForm.deliveryCompanyId",
                          },
                        },
                        _vm._l(_vm.dcList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号", prop: "deliveryId" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.shipForm.deliveryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipForm, "deliveryId", $$v)
                          },
                          expression: "shipForm.deliveryId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmShipAction("shipForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? _c("userdetail", {
            attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
            on: {
              close: function ($event) {
                _vm.showdetail = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }