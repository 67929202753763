"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _record = _interopRequireDefault(require("./components/record.vue"));
var _verifier = _interopRequireDefault(require("./components/verifier.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "WriteOff",
  components: {
    Record: _record.default,
    Verifier: _verifier.default
  },
  data: function data() {
    return {
      activeName: "record"
    };
  },
  methods: {
    handleClick: function handleClick() {
      this.$refs[this.activeName].getList(1);
      console.log(this.activeName);
    }
  }
};