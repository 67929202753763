"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _warehouse = require("@/api/warehouse");
var _basic = require("@/api/basic");
var _product = require("@/api/product");
var _settings = require("@/settings");
var _printJs = _interopRequireDefault(require("print-js"));
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "review",
  components: {
    'el-image-viewer': function elImageViewer() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('element-ui/packages/image/src/image-viewer'));
      });
    },
    fileList: _fileList.default
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0
      },
      storeList: [],
      listLoading: true,
      tableFrom: {
        category: "push_warehouse",
        username: "",
        type: "",
        date: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: ""
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      showreview: true,
      mask: '',
      showlabel: false,
      labelarr: [],
      showdetail: false,
      detailjson: {},
      showhouse: false,
      house: {
        image: '',
        remark: ''
      },
      rules: {
        image: [{
          required: true,
          message: "请上传凭证"
        }]
      },
      showshelf: false,
      shelf: {
        image: '',
        remark: '',
        id: ''
      },
      shelfList: [],
      showouthouse: false,
      showimage: false,
      imagearr: [],
      urlshow: false,
      urlshowList: [],
      showalldetail: false
    };
  },
  mounted: function mounted() {
    this.getList(1);
    this.getStoreList(1);
  },
  methods: {
    delwarehouseorder: function delwarehouseorder(row) {
      var _this2 = this;
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "不删除",
        type: "warning"
      }).then(function () {
        (0, _warehouse.delwarehouse)(row.order_id).then(function () {
          _this2.$message.success("删除成功");
          _this2.getList();
        });
      });
    },
    losewarehouseorder: function losewarehouseorder(row) {
      var _this3 = this;
      var that = this;
      this.$prompt("", "提示", {
        confirmButtonText: "确认作废",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputPlaceholder: "请输入作废理由",
        inputErrorMessage: "请输入作废理由"
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _warehouse.failwarehouse)(row.order_id, {
          remark: value
        }).then(function () {
          that.$message.success("作废成功");
          that.getList();
        });
      }).catch(function () {
        _this3.$message({
          type: "info",
          message: "取消输入"
        });
      });
    },
    showpro: function showpro(item) {
      this.urlshow = true;
      console.log(item.credentials);
      this.urlshowList = [item.credentials];
    },
    getIntoCard: function getIntoCard(row) {
      //  this.showimage = true;
      //  this.imagearr = [row.credentials]
      this.urlshow = true;
      this.urlshowList = [row.credentials];
    },
    getIntoshelfCard: function getIntoshelfCard(row) {
      this.showimage = true;
      var imagearr = [];
      row.orderProduct.forEach(function (item) {
        imagearr.push(item.credentials);
      });
      this.imagearr = imagearr;
    },
    getStoreList: function getStoreList(num) {
      var _this4 = this;
      (0, _product.getStoreList)({
        page: 1
      }).then(function (res) {
        _this4.storeList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    outhouse: function outhouse(row) {
      this.currentrow = row;
      this.showouthouse = true;
    },
    cursorouthouse: function cursorouthouse() {
      var _this5 = this;
      if (!this.house.image) {
        this.$message.error('请上传凭证');
        return;
      }
      (0, _warehouse.outwarehouse)(this.currentrow.order_id, {
        credentials: this.house.image,
        remark: this.house.remark
      }).then(function (res) {
        if (res.status == 200) {
          _this5.$message.success('出库成功');
          _this5.showouthouse = false;
        }
      }).catch(function (err) {
        console.log(err);
        _this5.$message.error(err.message);
      });
    },
    pushshelf: function pushshelf(row, item) {
      var _this6 = this;
      console.log(item);
      this.currentitem = item;
      this.shelf.image = '';
      this.shelf.id = item.shelf_id || '';
      (0, _basic.shelfList)({
        warehouse_id: row.push_warehouse_id
      }).then(function (res) {
        _this6.shelfList = res.data.list;
      });
      this.showshelf = true;
    },
    cursorshelf: function cursorshelf() {
      var _this7 = this;
      (0, _warehouse.giveshelf)(this.currentitem.order_product_id, {
        shelf_id: this.shelf.id,
        credentials: this.shelf.image,
        remark: this.shelf.remark,
        status: this.currentitem.status == 1 ? 2 : 1
      }).then(function (res) {
        if (res.status == 200) {
          _this7.$message.success('入位成功');
          _this7.getList();
          _this7.showshelf = false;
        }
      });
    },
    pushhouse: function pushhouse(row) {
      this.currentrow = row;
      this.showhouse = true;
    },
    cursorpush: function cursorpush() {
      var _this8 = this;
      if (!this.house.image) {
        this.$message.error('请上传凭证');
        return;
      }
      (0, _warehouse.intowarehouse)(this.currentrow.order_id, {
        credentials: this.house.image,
        remark: this.house.remark
      }).then(function (res) {
        if (res.status == 200) {
          _this8.$message.success('入库成功');
          _this8.getList();
          _this8.showhouse = false;
        }
      });
    },
    getdetail: function getdetail(id) {
      var _this9 = this;
      (0, _warehouse.getwarehouseDetail)(id).then(function (res) {
        if (res.status == 200) {
          _this9.detailjson = res.data;
          _this9.showdetail = true;
        }
      });
    },
    getalldetail: function getalldetail() {
      if (!this.multipleSelection.length) {
        this.$message.error('请选择入库单');
        return;
      }
      if (this.multipleSelection.length > 1) {
        this.$message.error('请选择单个入库单');
        return;
      }
      this.showalldetail = true;
      console.log(this.multipleSelection);
      // let ids = []
      // this.multipleSelection.forEach(item=>{
      //    item.orderProduct.forEach(m=>{
      //      ids.push(m.product_id);
      //    })
      // })
    },
    getmorelabel: function getmorelabel() {
      if (!this.multipleSelection.length) {
        this.$message.error('请选择入库单');
        return;
      }
      var ids = [];
      this.multipleSelection.forEach(function (item) {
        item.orderProduct.forEach(function (m) {
          ids.push(m.product_id);
        });
      });
      this.showlabel = true;
      this.getecode(ids.join(','));
    },
    getlabel: function getlabel(row) {
      this.showlabel = true;
      var ids = row.orderProduct.map(function (item) {
        return item.product_id;
      });
      this.getecode(ids.join(','));
    },
    getecode: function getecode(ids) {
      var _this10 = this;
      (0, _warehouse.getbarcode)({
        product_ids: ids
      }).then(function (res) {
        console.log(res);
        if (res.status == 200) {
          _this10.labelarr = res.data;
        }
      });
    },
    bindPrint: function bindPrint() {
      (0, _printJs.default)({
        printable: "printTable",
        type: 'html',
        header: null,
        targetStyles: ['*'],
        style: "@page {margin:0 10mm}"
      });
    },
    bindPrintorder: function bindPrintorder() {
      (0, _printJs.default)({
        printable: "printorder",
        type: 'html',
        header: null,
        targetStyles: ['*'],
        style: "@page {margin:0 10mm}"
      });
    },
    allbindPrintorder: function allbindPrintorder() {
      (0, _printJs.default)({
        printable: "allprintorder",
        type: 'html',
        header: null,
        targetStyles: ['*'],
        style: "@page {margin:0 10mm}"
      });
    },
    revieworder: function revieworder(row) {
      this.handlerow = row;
      this.showreview = true;
      this.mask = '';
    },
    handlereview: function handlereview(state) {
      var _this11 = this;
      if (state == -1 && !this.mask) {
        this.$message.error('请填写拒绝备注');
        return;
      }
      review(this.handlerow.id, {
        status: state,
        remark: this.mask
      }).then(function (res) {
        _this11.$message.success('审核成功');
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList("");
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList("");
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this12 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _warehouse.warehouselist)(this.tableFrom).then(function (res) {
        _this12.orderChartType = res.data.stat;
        _this12.tableData.data = res.data.list;
        _this12.tableData.total = res.data.count;
        _this12.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this12.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this13 = this;
      console.log(tit, num, i);
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(img);
        if (tit === "1" && !num) {
          _this.house.image = img[0];
        }
        if (tit === "2" && !num) {
          _this.shelf.image = img[0];
        }
        _this13.$forceUpdate();
      }, 1);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this14 = this;
      this.tableFrom.export_type = 'push_warehouse_order';
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this14.$createElement;
        _this14.$msgbox({
          title: "提示",
          message: h("p", null, [h("span", null, '文件正在生成中，请稍后点击"'), h("span", {
            style: "color: teal"
          }, "导出记录"), h("span", null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("push_warehouse_order");
    }
  }
};