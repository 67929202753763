var render, staticRenderFns
import script from "./pagetitle.vue?vue&type=script&lang=js"
export * from "./pagetitle.vue?vue&type=script&lang=js"
import style0 from "./pagetitle.vue?vue&type=style&index=0&id=a11fe73a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a11fe73a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/java_admin_Rm9R/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a11fe73a')) {
      api.createRecord('a11fe73a', component.options)
    } else {
      api.reload('a11fe73a', component.options)
    }
    
  }
}
component.options.__file = "src/components/pagetitle/pagetitle.vue"
export default component.exports