var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "code-v" },
      [
        _c(
          "div",
          { staticClass: "tab flex align-items-c" },
          [
            _c(
              "el-input",
              {
                staticClass: "w200",
                attrs: { placeholder: "商品名称" },
                on: { change: _vm.goSearch },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    icon: "el-icon-search",
                    size: "small",
                  },
                  on: { click: _vm.goSearch },
                  slot: "append",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-input",
              {
                staticClass: "w200 ml10",
                attrs: { placeholder: "品牌名称" },
                on: { change: _vm.goSearch },
                model: {
                  value: _vm.authorName,
                  callback: function ($$v) {
                    _vm.authorName = $$v
                  },
                  expression: "authorName",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    icon: "el-icon-search",
                    size: "small",
                  },
                  on: { click: _vm.goSearch },
                  slot: "append",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-input",
              {
                staticClass: "w200 ml10",
                attrs: { placeholder: "供应商名称" },
                on: { change: _vm.goSearch },
                model: {
                  value: _vm.issuerName,
                  callback: function ($$v) {
                    _vm.issuerName = $$v
                  },
                  expression: "issuerName",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    icon: "el-icon-search",
                    size: "small",
                  },
                  on: { click: _vm.goSearch },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab flex align-items-c mt20" },
          [
            _c("el-cascader", {
              staticClass: "w200",
              attrs: {
                clearable: "",
                placeholder: "商品分类",
                options: _vm.categoryList,
                props: { expandTrigger: "hover", value: "id", label: "label" },
              },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.categoryId,
                callback: function ($$v) {
                  _vm.categoryId = $$v
                },
                expression: "categoryId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab flex align-items-c mt10" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.addAction },
              },
              [_vm._v("添加商品")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "order-table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  size: "mini",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popover",
                            {
                              staticClass: "tabPop",
                              attrs: {
                                placement: "top-start",
                                width: "100",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "spBlock onHand",
                                    class: { check: _vm.chkName === "dan" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandle("dan", scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("选中本页")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                attrs: {
                                  slot: "reference",
                                  value:
                                    (_vm.chkName === "dan" &&
                                      _vm.checkedPage.indexOf(
                                        _vm.queryParams.pageNum
                                      ) > -1) ||
                                    _vm.chkName === "duo",
                                },
                                on: { change: _vm.changeType },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            attrs: {
                              value:
                                _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                                (_vm.chkName === "duo" &&
                                  _vm.noChecked.indexOf(scope.row.id) === -1),
                            },
                            on: {
                              change: function (v) {
                                return _vm.changeOne(v, scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "ID" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.id))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发售时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.startTime))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "文件预览" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: row.coverPicture,
                              "preview-src-list": [row.coverPicture],
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "源文件" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.fileType != 2 && row.file
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showVideoAction(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.fileType == 1
                                          ? "图片"
                                          : row.fileType == 3
                                          ? "视频"
                                          : "音频"
                                      )
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v("-")]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "商品名称" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.name))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "销售价格" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.price))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "优惠价格" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.vipPrice))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发售/已售/库存", "min-width": "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(row.issuedCount) +
                                " / " +
                                _vm._s(row.sales) +
                                " / " +
                                _vm._s(row.stock)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "限购" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [_vm._v(_vm._s(row.purchaseQuantity))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "排序", "min-width": "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input-number", {
                            staticStyle: { width: "100px" },
                            attrs: { min: 0, controls: false },
                            on: {
                              change: function (val) {
                                return _vm.editSortAction(val, scope.$index)
                              },
                            },
                            model: {
                              value: scope.row.sort,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "sort", $$v)
                              },
                              expression: "scope.row.sort",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "供应商" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.issuerName))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "品牌" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.authorName))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "创建时间", "min-width": "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "创建人" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.createBy))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", fixed: "right", width: "170" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-dropdown",
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-dropdown-link mr10",
                                  staticStyle: {
                                    color: "#1890ff",
                                    "font-size": "12px",
                                    cursor: "pointer",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                链接\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.linkAction(row.productId)
                                        },
                                      },
                                    },
                                    [_vm._v("H5链接")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.QRAction(row.productId)
                                        },
                                      },
                                    },
                                    [_vm._v("二维码")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "m0",
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.editAction(row.id)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          row.isIssuance
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "m0",
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onchangeIsShow(row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.isShow ? "下架" : "上架"))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !row.isIssuance
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "m0",
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delAction(row.id)
                                    },
                                  },
                                },
                                [_vm._v("删除\n            ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt20 flex justify-e" },
              [
                _c(
                  "div",
                  { staticClass: "flex-one align-items-c flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mr10",
                        attrs: {
                          type: "primary",
                          icon: "ios-search",
                          label: "default",
                          size: "small",
                        },
                        on: { click: _vm.batchUp },
                      },
                      [_vm._v("批量上架\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mr10",
                        attrs: {
                          type: "primary",
                          icon: "ios-search",
                          label: "default",
                          size: "small",
                        },
                        on: { click: _vm.batchDown },
                      },
                      [_vm._v("\n            批量下架\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mr10",
                        attrs: {
                          type: "primary",
                          icon: "ios-search",
                          label: "default",
                          size: "small",
                        },
                        on: { click: _vm.batchDel },
                      },
                      [_vm._v("批量删除\n          ")]
                    ),
                    _vm._v(" "),
                    _vm.checkedIds.length > 0 || _vm.allCheck
                      ? _c("el-alert", {
                          staticClass: "w200",
                          staticStyle: { height: "32px" },
                          attrs: {
                            title: _vm.allCheck
                              ? "已选择  " + _vm.tableData.total + "  项"
                              : "已选择  " + _vm.checkedIds.length + "  项",
                            type: "info",
                            "show-icon": "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                    "page-size": Number(_vm.queryParams.pageSize),
                    "current-page": Number(_vm.queryParams.pageNum),
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.getList,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.showIncreaseQuantity.show,
              "close-on-click-modal": false,
              center: "",
              title: "藏品增发",
              width: "350px",
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.showIncreaseQuantity, "show", $event)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "item-flex-center" },
              [
                _c("el-input-number", {
                  attrs: { placeholder: "请输入增发数量", controls: false },
                  model: {
                    value: _vm.showIncreaseQuantity.addAmount,
                    callback: function ($$v) {
                      _vm.$set(_vm.showIncreaseQuantity, "addAmount", $$v)
                    },
                    expression: "showIncreaseQuantity.addAmount",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item-flex-center mt20" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showIncreaseQuantity.show = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.confirmIncreaseAction },
                  },
                  [_vm._v("确认增发")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.fileVisible,
              "close-on-click-modal": false,
              "before-close": _vm.closeBeforeAction,
              title: "源文件详情",
              width: "600px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.fileVisible = $event
              },
            },
          },
          [
            _vm.fileTypes == 1
              ? _c(
                  "div",
                  [
                    _c("el-image", {
                      staticStyle: { width: "550px" },
                      attrs: { src: _vm.fileShow },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.fileTypes == 3
              ? _c("div", [
                  _c("video", {
                    attrs: {
                      src: _vm.fileShow,
                      id: "id_test_video",
                      controls: "",
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.fileTypes == 4
              ? _c("div", [
                  _c("audio", {
                    staticStyle: { width: "550px" },
                    attrs: { src: _vm.fileShow, controls: "", autoplay: "" },
                  }),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.showImg,
              "close-on-click-modal": false,
              center: "",
              title: "查看二维码",
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showImg = $event
              },
            },
          },
          [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.showImgUrl, alt: "" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-drawer",
          {
            attrs: {
              size: "50%",
              visible: _vm.receiveData.show,
              "append-to-body": "",
              title: "中签名单",
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.receiveData, "show", $event)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "receive-list" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.receiveData.listLoading,
                        expression: "receiveData.listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.receiveData.list,
                      size: "small",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "title",
                        label: "中签用户",
                        "min-width": "200",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(scope.row.memberNickName) +
                                    " "
                                ),
                                scope.row.memberPhone
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(scope.row.memberPhone) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "中签编号", "min-width": "80" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      scope.row.drawLotsNumber
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "是否购买", "min-width": "80" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return scope.row.hasBuy
                                ? [_c("div", [_vm._v("已购买")])]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "购买时间", "min-width": "140" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [_vm._v(_vm._s(scope.row.createTime))]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "block mt20",
                    staticStyle: { "text-align": "right" },
                  },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-size": _vm.receiveData.query.pageSize,
                        "current-page": _vm.receiveData.query.pageNum,
                        total: _vm.receiveData.total,
                      },
                      on: { "current-change": _vm.pageChangeNew },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }