"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.modalSure = modalSure;
exports.modalSureDelete = modalSureDelete;
function modalSure(title) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.$confirm("\u786E\u5B9A".concat(title || '删除该文件吗', "?"), '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      resolve();
    }).catch(function () {
      _this.$message({
        type: 'info',
        message: '已取消'
      });
    });
  });
}
function modalSureDelete(title) {
  var _this2 = this;
  return new Promise(function (resolve, reject) {
    _this2.$confirm("".concat(title || '该记录删除后不可恢复，您确认删除吗？', "?"), '提示', {
      confirmButtonText: '删除',
      cancelButtonText: '不删除',
      type: 'warning'
    }).then(function () {
      resolve();
    }).catch(function (action) {
      _this2.$message({
        type: 'info',
        message: '已取消'
      });
    });
  });
}