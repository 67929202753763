var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "padding20 bg-white borderRadius6",
          staticStyle: { "min-height": "100vh" },
        },
        [
          _c(
            "div",
            {
              staticClass: "remove-control form-view",
              staticStyle: { position: "relative" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "投票名称：", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入投票名称" },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头图：", required: "" } },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c("div", { staticClass: "img-item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("image", "")
                                },
                              },
                            },
                            [
                              _vm.ruleForm.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.ruleForm.image + "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "投票时间：", prop: "assistDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          align: "right",
                        },
                        model: {
                          value: _vm.ruleForm.assistDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "assistDate", $$v)
                          },
                          expression: "ruleForm.assistDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "投票说明：" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "remark", $$v)
                          },
                          expression: "ruleForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "人群&次数：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.hasCrowdRestrictions,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "hasCrowdRestrictions",
                                $$v
                              )
                            },
                            expression: "ruleForm.hasCrowdRestrictions",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("不限"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("快照名单"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "ml20",
                          attrs: {
                            placeholder: "请选择快照名单",
                            clearable: "",
                            disabled: !_vm.ruleForm.hasCrowdRestrictions,
                          },
                          model: {
                            value: _vm.ruleForm.airdropId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "airdropId", $$v)
                            },
                            expression: "ruleForm.airdropId",
                          },
                        },
                        _vm._l(_vm.snapshotList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "参与条件：", required: "" } },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          { staticClass: "l" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "参与条件" },
                                model: {
                                  value: _vm.ruleForm.conditionType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "conditionType", $$v)
                                  },
                                  expression: "ruleForm.conditionType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: 1, label: "无门槛" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: 2, label: "消耗积分" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: 3, label: "消耗藏品" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.ruleForm.conditionType == 2
                          ? _c(
                              "div",
                              { staticClass: "flex-one ml20" },
                              [
                                _c("span", [_vm._v("每次投票需消耗")]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w100 ml10",
                                    attrs: { placeholder: "积分类型" },
                                    model: {
                                      value:
                                        _vm.ruleForm.voteRule.scoreRule.typeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm.voteRule.scoreRule,
                                          "typeId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ruleForm.voteRule.scoreRule.typeId",
                                    },
                                  },
                                  _vm._l(_vm.typeList, function (m, i) {
                                    return _c("el-option", {
                                      key: "type" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-input-number", {
                                  staticClass: "w100 ml10",
                                  attrs: { controls: false, min: 1 },
                                  model: {
                                    value:
                                      _vm.ruleForm.voteRule.scoreRule.count,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm.voteRule.scoreRule,
                                        "count",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.voteRule.scoreRule.count",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml10" }, [
                                  _vm._v("积分"),
                                ]),
                              ],
                              1
                            )
                          : _vm.ruleForm.conditionType == 3
                          ? _c("div", { staticClass: "flex-one ml20" }, [
                              _c(
                                "div",
                                { staticClass: "t" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value:
                                          _vm.ruleForm.voteRule.productRule
                                            .type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm.voteRule.productRule,
                                            "type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.voteRule.productRule.type",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("全部藏品"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("部分藏品"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.ruleForm.voteRule.productRule.type == 1
                                ? _c(
                                    "div",
                                    { staticClass: "b mt10" },
                                    [
                                      _c("span", [
                                        _vm._v("每次投票需消耗藏品"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-input-number", {
                                        staticClass: "w100 ml10",
                                        attrs: { controls: false, min: 1 },
                                        model: {
                                          value:
                                            _vm.ruleForm.voteRule.productRule
                                              .count,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm.voteRule.productRule,
                                              "count",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.voteRule.productRule.count",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml10" }, [
                                        _vm._v("个"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.ruleForm.voteRule.productRule.type == 2
                                ? _c("div", { staticClass: "b mt10" }, [
                                    _c(
                                      "div",
                                      { staticClass: "tab" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tr flex align-items-c",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "th",
                                                staticStyle: { width: "20%" },
                                              },
                                              [_vm._v("需消耗数量")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "th",
                                                staticStyle: { width: "70%" },
                                              },
                                              [_vm._v("消耗藏品")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "th",
                                                staticStyle: { width: "10%" },
                                              },
                                              [_vm._v("操作")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.ruleForm.voteRule.productRule
                                            .productConsume,
                                          function (m, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass:
                                                  "tr flex align-items-c",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "td",
                                                    staticStyle: {
                                                      width: "20%",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        type: "number",
                                                        placeholder: "输入数量",
                                                      },
                                                      model: {
                                                        value: m.count,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            m,
                                                            "count",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "m.count",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "td",
                                                    staticStyle: {
                                                      width: "70%",
                                                    },
                                                  },
                                                  [
                                                    _c("PopoverTable", {
                                                      attrs: {
                                                        "show-data":
                                                          m.productList,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "ml10",
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showChangeAction(
                                                              "productConsume",
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("选择藏品")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "td",
                                                    staticStyle: {
                                                      width: "10%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "ml10",
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delAction(
                                                              "productConsume",
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tr flex align-items-c",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "td",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "ml10",
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addAction(
                                                          "productConsume"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("添加规则")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "投票次数：" } },
                    [
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: { type: "number", placeholder: "输入数量" },
                        model: {
                          value: _vm.ruleForm.voteCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "voteCount", $$v)
                          },
                          expression: "ruleForm.voteCount",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml10" }, [_vm._v("次")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "color-gray ft12" }, [
                        _vm._v("（0为不限制投票次数）"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-divider", { attrs: { "content-position": "left" } }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设置投票项：" } },
                    [
                      _vm._l(_vm.ruleForm.voteItems, function (m, i) {
                        return _c(
                          "div",
                          { key: "voteItems" + i, staticClass: "vote-item" },
                          [
                            _c(
                              "div",
                              { staticClass: "title flex align-items-c" },
                              [
                                _c("div", { staticClass: "sort" }, [
                                  _vm._v(_vm._s(i + 1) + "、"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w200 ml20",
                                  attrs: { placeholder: "输入投票项名称" },
                                  model: {
                                    value: m.title,
                                    callback: function ($$v) {
                                      _vm.$set(m, "title", $$v)
                                    },
                                    expression: "m.title",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("最多可选"),
                                ]),
                                _vm._v(" "),
                                _c("el-input-number", {
                                  staticClass: "w100 ml5",
                                  attrs: {
                                    min: 1,
                                    max: m.voteItemContexts.length,
                                    controls: false,
                                    placeholder: "可选则数量",
                                  },
                                  model: {
                                    value: m.count,
                                    callback: function ($$v) {
                                      _vm.$set(m, "count", $$v)
                                    },
                                    expression: "m.count",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml5 flex-one" }, [
                                  _vm._v("项"),
                                ]),
                                _vm._v(" "),
                                _vm.ruleForm.voteItems.length > 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delVoteItem(i)
                                          },
                                        },
                                      },
                                      [_vm._v("删除投票项")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "tab mt20" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tr flex align-items-c" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "15%" },
                                      },
                                      [_vm._v("序号")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "15%" },
                                      },
                                      [_vm._v("图片")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "30%" },
                                      },
                                      [_vm._v("标题")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "30%" },
                                      },
                                      [_vm._v("说明")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "10%" },
                                      },
                                      [_vm._v("操作")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  m.voteItemContexts,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "voteItemContexts" + index + i,
                                        staticClass: "tr flex align-items-c",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "td",
                                            staticStyle: { width: "15%" },
                                          },
                                          [_vm._v(_vm._s(index + 1))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "td",
                                            staticStyle: { width: "15%" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upLoadPicBox",
                                                attrs: { title: "图片" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.modalPicTap(
                                                      index,
                                                      i
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                item.image
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "pictrue",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              item.image +
                                                              "!120a",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "td",
                                            staticStyle: { width: "30%" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "输入选项标题",
                                              },
                                              model: {
                                                value: item.title,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "title", $$v)
                                                },
                                                expression: "item.title",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "td",
                                            staticStyle: { width: "30%" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "输入说明文字",
                                              },
                                              model: {
                                                value: item.remark,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "remark", $$v)
                                                },
                                                expression: "item.remark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "td",
                                            staticStyle: { width: "10%" },
                                          },
                                          [
                                            m.voteItemContexts.length > 1
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItemContext(
                                                          i,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            index > 0
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.moveUp(
                                                          i,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("上移")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "tr flex align-items-c" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "td",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addItemContext(i)
                                              },
                                            },
                                          },
                                          [_vm._v("添加选项")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn mt20" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.addVoteItem },
                            },
                            [_vm._v("添加投票项")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.saveAction("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认提交")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "nft",
        attrs: { "current-data": [] },
        on: { selectMessage: _vm.selectNftMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }