"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/web.dom.iterable");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "planeDetail",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      formValidate: {
        fighterName: "",
        fighterImage: "",
        rateOfSuccess: 0,
        planetImage: "",
        planetName: "",
        hitPoint: "",
        fighterMiningTime: "",
        classifyId: "",
        fighterStrengthens: []
      },
      rules: {
        strengthenName: [{
          required: true,
          message: "请输入强化名称",
          trigger: "blur"
        }],
        fighterMiningTime: [{
          required: true,
          message: "请输入挖矿时长",
          trigger: "blur"
        }],
        rateOfSuccess: [{
          required: true,
          message: "请输入成功率",
          trigger: "blur"
        }]
      },
      ruleValidate: {
        fighterImage: [{
          required: true,
          message: "请选择图片"
        }],
        fighterName: [{
          required: true,
          message: "请输入战机名称",
          trigger: "blur"
        }],
        planetImage: [{
          required: true,
          message: "请选择图片"
        }],
        planetName: [{
          required: true,
          message: "请输入星球名称",
          trigger: "blur"
        }],
        classifyId: [{
          required: true,
          message: "请选择分类",
          trigger: "change"
        }],
        hitPoint: [{
          required: true,
          message: "请输入耐久度",
          trigger: "blur"
        }],
        rateOfSuccess: [{
          required: true,
          message: "请输入成功率",
          trigger: "blur"
        }],
        fighterMiningTime: [{
          required: true,
          message: "请输入挖矿时长",
          trigger: "blur"
        }]
      },
      output: [{
        image: "",
        name: "",
        stock: "",
        probability: "100",
        giveNum: 1,
        type: 1
      }],
      outtype: true,
      condition: [{
        image: "",
        name: "",
        needNum: ""
      }],
      conditiontype: true,
      durability: [{
        image: "",
        name: "",
        needNum: ""
      }],
      durabilitytype: true,
      classList: [],
      changePlaneIndex: null
    };
  },
  created: function created() {
    var _this2 = this;
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getPlaneDetail();
    }
    (0, _marketing.planeclassifyList)({
      pageNum: 1,
      pageSize: 50
    }).then(function (res) {
      _this2.classList = res.rows;
    });
    // getPlaneDetail()
  },
  methods: {
    addEnhancedFighter: function addEnhancedFighter() {
      var data = {
        strengthLevel: 1,
        strengthenName: "",
        rateOfSuccess: 1,
        fighterMiningTime: 1,
        isAllGive: false,
        fighterMiningPrizeList: [{
          image: "",
          name: "",
          stock: "",
          probability: "100",
          giveNum: 1,
          type: 1
        }],
        exchangeConditionList: [{
          image: "",
          name: "",
          needNum: 1,
          strengthenRate: 0
        }]
      };
      this.formValidate.fighterStrengthens.push(data);
    },
    getPlaneDetail: function getPlaneDetail() {
      var _this3 = this;
      (0, _marketing.getPlaneDetail)(this.id, {
        id: this.id
      }).then(function (res) {
        console.log(res);
        _this3.formValidate = {
          fighterName: res.data.fighterName,
          fighterImage: res.data.fighterImage,
          rateOfSuccess: res.data.rateOfSuccess,
          planetImage: res.data.planetImage,
          planetName: res.data.planetName,
          hitPoint: res.data.hitPoint,
          fighterMiningTime: res.data.fighterMiningTime,
          classifyId: res.data.classifyId || "",
          fighterStrengthens: res.data.fighterStrengthens || []
        };
        _this3.outtype = res.data.isAllGive;
        if (res.data.fighterMiningPrizeList) {
          _this3.output = res.data.fighterMiningPrizeList;
        }
        _this3.conditiontype = res.data.exchangeCondition;
        if (res.data.exchangeConditionList) {
          _this3.condition = res.data.exchangeConditionList.filter(function (item) {
            return item.type == 1;
          });
        }
        _this3.durabilitytype = res.data.hitPointCondition;
        if (res.data.exchangeConditionList) {
          _this3.durability = res.data.exchangeConditionList.filter(function (item) {
            return item.type == 2;
          });
        }
      });
    },
    submitForm: function submitForm() {
      var _this4 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          var flag = false;
          var result = false;
          _this4.output.forEach(function (item, index) {
            if (!item.productId && item.type == 1) {
              flag = true;
            } else if (item.type == 2) {
              item.name = "Y积分";
            }
          });
          if (flag) {
            return _this4.$message.error("请完善产出材料或者删除无用的条件");
          }
          _this4.condition.forEach(function (item, index) {
            if (!item.productId) {
              flag = true;
            }
          });
          if (flag) {
            return _this4.$message.error("请完善兑换条件或者删除无用的条件");
          }
          if (_this4.formValidate.fighterStrengthens.length > 0) {
            var promiseList = _this4.$refs.customForm.map(function (v) {
              return v.validate();
            });
            Promise.all(promiseList).then(function (res) {
              _this4.formValidate.fighterStrengthens.forEach(function (item) {
                item.fighterMiningPrizeList.forEach(function (inner) {
                  if (!inner.productId && inner.type == 1) {
                    result = true;
                    return;
                  } else if (inner.type == 2) {
                    inner.name = "Y积分";
                  }
                });
                if (result) {
                  return _this4.$message.error("请完善产出材料或者删除无用的条件");
                }
                item.exchangeConditionList.forEach(function (inner) {
                  if (!inner.productId) {
                    result = true;
                  }
                });
                if (result) {
                  return _this4.$message.error("请完善强化条件或者删除无用的条件");
                }
                _this4.submitData();
              });
              console.log(result);
              if (result) {
                _this4.$message;
              }
            }).catch(function (err) {
              _this4.$message.error("请完善信息");
              return;
            });
          } else {
            _this4.submitData();
          }
        } else {
          _this4.$message.error("请完善信息");
        }
      });
    },
    submitData: function submitData() {
      var _this5 = this;
      var json = JSON.parse(JSON.stringify(this.formValidate));
      json.isAllGive = this.outtype;
      json.exchangeCondition = this.conditiontype;
      json.hitPointCondition = this.durabilitytype;
      json.fighterMiningPrizeList = this.output;
      var newarr = [];
      this.condition.forEach(function (item, index) {
        if (item.productId) {
          item.type = 1;
          newarr.push(item);
        }
      });
      this.durability.forEach(function (item, index) {
        if (item.productId) {
          item.type = 2;
          newarr.push(item);
        }
      });
      json.exchangeConditionList = newarr;
      if (this.id) {
        json.id = this.id;
        (0, _marketing.editPlane)(json).then(function () {
          _this5.$message.success("创建成功");
          _this5.$router.back();
        });
        return;
      }
      (0, _marketing.createPlane)(json).then(function (res) {
        console.log(res);
        _this5.$message.success("创建成功");
        _this5.$router.back();
      });
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      var product = data.data[0];
      if (this.changeType == "output" || this.changeType === "fighterMiningPrizeList") {
        if (this.changePlaneIndex !== null) {
          var changePlaneData = this.formValidate.fighterStrengthens[this.changePlaneIndex].fighterMiningPrizeList[this.changeIndex];
          console.log(changePlaneData);
          changePlaneData.image = product.image;
          changePlaneData.name = product.name;
          changePlaneData.stock = product.stock;
          changePlaneData.objectId = product.id;
          changePlaneData.productId = product.p_id;
        } else {
          this.output[this.changeIndex].image = product.image;
          this.output[this.changeIndex].name = product.name;
          this.output[this.changeIndex].stock = product.stock;
          this.output[this.changeIndex].objectId = product.id;
          this.output[this.changeIndex].productId = product.p_id;
        }
      } else if (this.changeType == "condition" || this.changeType === "exchangeConditionList") {
        if (this.changePlaneIndex !== null) {
          var _changePlaneData = this.formValidate.fighterStrengthens[this.changePlaneIndex].exchangeConditionList[this.changeIndex];
          _changePlaneData.image = product.image;
          _changePlaneData.name = product.name;
          _changePlaneData.objectId = product.id;
          _changePlaneData.productId = product.p_id;
        } else {
          this.condition[this.changeIndex].image = product.image;
          this.condition[this.changeIndex].name = product.name;
          this.condition[this.changeIndex].objectId = product.id;
          this.condition[this.changeIndex].productId = product.p_id;
        }
      } else if (this.changeType == "durability") {
        this.durability[this.changeIndex].image = product.image;
        this.durability[this.changeIndex].name = product.name;
        this.durability[this.changeIndex].objectId = product.id;
        this.durability[this.changeIndex].productId = product.p_id;
      }
      this.changePlaneIndex = null;
    },
    changerate: function changerate(index) {
      if (index !== undefined) {
        if (this.formValidate.fighterStrengthens[index].fighterMiningPrizeList.length > 1) {
          var probability = 100;
          this.formValidate.fighterStrengthens[index].fighterMiningPrizeList.forEach(function (item, index) {
            if (index > 0) {
              probability = probability - Number(item.probability);
            }
          });
          this.formValidate.fighterStrengthens[index].fighterMiningPrizeList[0].probability = probability;
        } else {
          this.formValidate.fighterStrengthens[index].fighterMiningPrizeList[0].probability = 100;
        }
      } else {
        if (this.output.length > 1) {
          var _probability = 100;
          this.output.forEach(function (item, index) {
            if (index > 0) {
              _probability = _probability - Number(item.probability);
            }
          });
          this.output[0].probability = _probability;
        } else {
          this.output[0].probability = 100;
        }
      }
    },
    addlist: function addlist(type, index) {
      if (type == "output" || type == "fighterMiningPrizeList") {
        if (index !== undefined) {
          this.formValidate.fighterStrengthens[index].fighterMiningPrizeList.push({
            image: "",
            name: "",
            stock: "",
            probability: "",
            type: 1
          });
        } else {
          this.output.push({
            image: "",
            name: "",
            stock: "",
            probability: ""
          });
        }
      } else if (type == "condition" || type == "exchangeConditionList") {
        if (index !== undefined) {
          this.formValidate.fighterStrengthens[index].exchangeConditionList.push({
            image: "",
            name: "",
            needNum: 1,
            strengthenRate: 0
          });
        } else {
          this.condition.push({
            image: "",
            name: "",
            needNum: ""
          });
        }
      } else if (type == "durability") {
        this.durability.push({
          image: "",
          name: "",
          needNum: ""
        });
      }
    },
    dellist: function dellist(type, index, changeIndex) {
      if (type == "output" || type == "fighterMiningPrizeList") {
        if (changeIndex !== undefined) {
          this.formValidate.fighterStrengthens[changeIndex].fighterMiningPrizeList.splice(index, 1);
          this.changerate(changeIndex);
        } else {
          this.output.splice(index, 1);
        }
      } else if (type == "condition" || type == "exchangeConditionList") {
        if (changeIndex !== undefined) {
          this.formValidate.fighterStrengthens[changeIndex].exchangeConditionList.splice(index, 1);
          this.changerate(changeIndex);
        } else {
          this.condition.splice(index, 1);
          this.changerate();
        }
      } else if (type == "durability") {
        this.durability.splice(index, 1);
      }
    },
    handleAdditem: function handleAdditem(type, i, index) {
      this.changeType = type;
      if (index !== undefined) {
        this.changePlaneIndex = index;
      } else {
        this.changePlaneIndex = null;
      }
      this.changeIndex = i;
      this.$refs.product_nft.changeshow();
    },
    // 修改图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          console.log(img);
          _this.formValidate[num] = img[0];
          _this.$refs.formValidate.clearValidate();
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = "";
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};