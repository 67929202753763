var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "el-form",
            {
              attrs: { size: "small", "label-width": "0px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.identityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "identityId", $$v)
                        },
                        expression: "tableFrom.identityId",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.identityList, function (m, i) {
                        return _c(
                          "el-radio-button",
                          { key: i, attrs: { label: m.id } },
                          [_vm._v(_vm._s(m.identityName))]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "ml20",
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "status", $$v)
                        },
                        expression: "tableFrom.status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("已通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "-1" } }, [
                        _vm._v("已拒绝"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("待审核"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { height: "20px" } }),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "w300",
                      staticStyle: { position: "relative" },
                      attrs: { placeholder: "作者名称", size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "name", $$v)
                        },
                        expression: "tableFrom.name",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "w300 ml20",
                      staticStyle: { position: "relative" },
                      attrs: { placeholder: "作者手机号", size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "phone", $$v)
                        },
                        expression: "tableFrom.phone",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "order-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { width: "50" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        staticClass: "tabPop",
                        attrs: {
                          placement: "top-start",
                          width: "100",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "spBlock onHand",
                              class: { check: _vm.chkName === "dan" },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandle("dan", scope.$index)
                                },
                              },
                            },
                            [_vm._v("选中本页")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-checkbox", {
                          attrs: {
                            slot: "reference",
                            value:
                              (_vm.chkName === "dan" &&
                                _vm.checkedPage.indexOf(_vm.tableFrom.pageNum) >
                                  -1) ||
                              _vm.chkName === "duo",
                          },
                          on: { change: _vm.changeType },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: {
                        disabled: scope.row.status != 1,
                        value:
                          _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                          (_vm.chkName === "duo" &&
                            _vm.noChecked.indexOf(scope.row.id) === -1),
                      },
                      on: {
                        change: function (v) {
                          return _vm.changeOne(v, scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像", "min-width": "50", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: scope.row.image + "!webp640",
                            fit: "cover",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户名称", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.memberName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "真实姓名", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申请类型", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.typeName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申请时间", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == 2
                      ? [
                          _c("div", { staticClass: "color-gray" }, [
                            _vm._v("已拒绝"),
                          ]),
                        ]
                      : row.status == 0
                      ? _c("div", { staticClass: "color-red" }, [
                          _vm._v("待审核"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.status == 1
                      ? _c("div", { staticClass: "color-lv" }, [
                          _vm._v("已通过"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "100",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showEditAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看详情")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 0
                      ? [
                          _c("div"),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.checkFinishAction(
                                    scope.row,
                                    [scope.row.id],
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("通过")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.refuseAction([scope.row.id])
                                },
                              },
                            },
                            [_vm._v("拒绝")]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex align-items-c" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.batchTgAction } },
                [_vm._v("批量通过")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.batchJjAction } },
                [_vm._v("批量拒绝")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-top": "0" },
            attrs: {
              "page-size": _vm.tableFrom.pageSize,
              "current-page": _vm.tableFrom.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editIssuerData.show,
            width: "800px",
            "close-on-click-modal": false,
            title: "查看详情",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editIssuerData, "show", $event)
            },
          },
        },
        [
          _vm.editIssuerData.data
            ? _c(
                "div",
                { staticClass: "detail" },
                [
                  _vm._l(_vm.editIssuerData.data, function (item) {
                    return [
                      item.key == 5 || item.key == 15 || item.key == 23
                        ? _c("div", { staticClass: "item flex mt10" }, [
                            _c("div", { staticClass: "l" }, [
                              _vm._v(
                                _vm._s(item.customName || item.name) + "："
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "r" },
                              [
                                item.image
                                  ? _c("el-image", {
                                      staticStyle: { width: "80px" },
                                      attrs: {
                                        src: item.image,
                                        fit: "fill",
                                        lazy: true,
                                        "preview-src-list": [item.image],
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : item.key == 13
                        ? _c("div", { staticClass: "item flex mt10" }, [
                            _c("div", { staticClass: "l" }, [
                              _vm._v(
                                _vm._s(item.customName || item.name) + "："
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "r" },
                              [
                                item.image
                                  ? _c("el-image", {
                                      staticStyle: { width: "80px" },
                                      attrs: {
                                        src: item.image,
                                        fit: "fill",
                                        lazy: true,
                                        "preview-src-list": [item.image],
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.infoImage
                                  ? _c("el-image", {
                                      staticStyle: { width: "80px" },
                                      attrs: {
                                        src: item.infoImage,
                                        fit: "fill",
                                        lazy: true,
                                        "preview-src-list": [item.infoImage],
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : item.key == 8 ||
                          item.key == 11 ||
                          item.key == 19 ||
                          item.key == 20
                        ? _c("div", { staticClass: "item flex mt10" }, [
                            _c("div", { staticClass: "l" }, [
                              _vm._v(
                                _vm._s(item.customName || item.name) + "："
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "r" },
                              [
                                item.infovalue
                                  ? [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(item.infovalue.province) +
                                          " - " +
                                          _vm._s(item.infovalue.city) +
                                          " - " +
                                          _vm._s(item.infovalue.district) +
                                          "   " +
                                          _vm._s(item.infovalue.address) +
                                          "\n            "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ])
                        : _c("div", { staticClass: "item flex mt10" }, [
                            _c("div", { staticClass: "l" }, [
                              _vm._v(
                                _vm._s(item.customName || item.name) + "："
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "r" }, [
                              _vm._v(_vm._s(item.value)),
                            ]),
                          ]),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("WorkNumberTmp", { ref: "workNumberTmp" }),
      _vm._v(" "),
      _vm.showdetail ? void 0 : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "拒绝原因", visible: _vm.showRefuse, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.showRefuse = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2, maxRows: 4 },
              placeholder: "请输入拒绝原因",
            },
            model: {
              value: _vm.reason,
              callback: function ($$v) {
                _vm.reason = $$v
              },
              expression: "reason",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.checkFinishAction("", _vm.reasonIds, 2)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showImg, title: "查看图片" },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("el-image", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.fileVisible,
            "close-on-click-modal": false,
            "before-close": (_vm.fileVisible = false),
            title: "查看3D文件",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fileVisible = $event
            },
          },
        },
        [
          _c("iframe", {
            staticStyle: { width: "550px", height: "550px" },
            attrs: { src: _vm.fileShow, frameborder: "0" },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }