var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "兑换对象类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择兑换对象" },
                      model: {
                        value: _vm.formValidate.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "type", $$v)
                        },
                        expression: "formValidate.type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "数字藏品", value: 1 },
                      }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "实物", value: 2 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formValidate.type == 1
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "兑换的数字藏品",
                          required: "",
                          prop: "image",
                        },
                      },
                      [
                        _vm.product
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "demo-image__preview",
                                    staticStyle: {
                                      "flex-shrink": "0",
                                      "margin-right": "6px",
                                    },
                                  },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: _vm.product.image + "!120a",
                                        "preview-src-list": [_vm.product.image],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(" " + _vm._s(_vm.product.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "name",
                                    staticStyle: { "margin-left": "20px" },
                                  },
                                  [
                                    _vm._v(
                                      " 当前库存：" + _vm._s(_vm.product.stock)
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { type: "text", size: "default" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAdditem("product")
                                      },
                                    },
                                  },
                                  [_vm._v("更换")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "default" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAdditem("product")
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == 2
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "实物名称：", prop: "name" } },
                      [
                        _c("el-input", {
                          staticClass: "selwidth",
                          attrs: { placeholder: "请输入实物名称" },
                          model: {
                            value: _vm.formValidate.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "name", $$v)
                            },
                            expression: "formValidate.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "实物图片：",
                          prop: "image",
                          required: "",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1", "image")
                              },
                            },
                          },
                          [
                            _vm.formValidate.image
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.formValidate.image + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(
                          "\n                    比例1:1,png透明图片\n                "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "可兑换数量", prop: "prizeNum" } },
                [
                  _c("el-input-number", {
                    staticClass: "selwidth",
                    attrs: {
                      min: 1,
                      controls: true,
                      placeholder: "请输入可兑换数量",
                    },
                    model: {
                      value: _vm.formValidate.prizeNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "prizeNum", $$v)
                      },
                      expression: "formValidate.prizeNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "兑换条件", required: "" } },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "800px" } },
                    [
                      _c(
                        "el-table",
                        { attrs: { data: _vm.condition, border: "" } },
                        [
                          _c("el-table-column", {
                            attrs: { label: "类型", "min-width": "100" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: scope.row.type,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "type", $$v)
                                          },
                                          expression: "scope.row.type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-option",
                                          {
                                            attrs: { label: "藏品", value: 1 },
                                          },
                                          [_vm._v("藏品")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-option",
                                          {
                                            attrs: { label: "积分", value: 2 },
                                          },
                                          [_vm._v("积分")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "名称", "min-width": "140" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.type == 1
                                      ? [
                                          scope.row.name
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "demo-image__preview",
                                                      staticStyle: {
                                                        "flex-shrink": "0",
                                                        "margin-right": "6px",
                                                      },
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        attrs: {
                                                          src:
                                                            scope.row.image +
                                                            "!120a",
                                                          "preview-src-list": [
                                                            scope.row.image,
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "name" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(scope.row.name)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "default",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleAdditem(
                                                            "condition",
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("更换")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "default",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleAdditem(
                                                            "condition",
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("选择")]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      : scope.row.type == 2
                                      ? [
                                          _vm._v(
                                            "\n                                  Y积分\n                                "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "需要数量" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input-number", {
                                      attrs: {
                                        min: 1,
                                        step: 1,
                                        controls: true,
                                        "controls-position": "both",
                                      },
                                      model: {
                                        value: scope.row.needNum,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "needNum", $$v)
                                        },
                                        expression: "scope.row.needNum",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return scope.$index > 0
                                      ? [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "default",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.dellist(
                                                    "condition",
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      : undefined
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "20px",
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "default" },
                              on: {
                                click: function ($event) {
                                  return _vm.addlist("condition")
                                },
                              },
                            },
                            [_vm._v("+添加")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm._v(
                                "\n                            需满足全部条件 "
                              ),
                              _c("el-switch", {
                                attrs: {
                                  "active-value": true,
                                  "inactive-value": false,
                                },
                                model: {
                                  value: _vm.conditiontype,
                                  callback: function ($$v) {
                                    _vm.conditiontype = $$v
                                  },
                                  expression: "conditiontype",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }