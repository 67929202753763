"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    listLoading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    closes: {
      type: Boolean,
      default: false
    }
    // objdrawer: {
    //   required: false,
    //   type: Object,
    //   default: () => {
    //     return {};
    //   }
    // }
  },
  data: function data() {
    return {
      drawer: false,
      objdrawer: {}
    };
  },
  watch: {
    closes: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        console.log(val, 75);
      }
    }
  },
  mounted: function mounted() {
    console.log(this.$store.state, 82);
    this.drawer = this.$store.state.isClose;
  },
  methods: {
    bookDetail: function bookDetail(row) {
      this.drawer = true;
      this.objdrawer = {
        drawer: this.drawer,
        type: "1",
        name: "入账明细",
        row: row
      };
      this.$emit("drawer", JSON.stringify(this.objdrawer));
    },
    outAccountDetail: function outAccountDetail(row) {
      this.objdrawer = {
        drawer: this.drawer,
        type: "-1",
        name: "出账明细",
        row: row
      };
      this.$emit("drawer", JSON.stringify(this.objdrawer));
    }
  }
};