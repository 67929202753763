"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      SettingMer: _settingMer.default,
      list: [],
      listLoading: false,
      queryParams: {
        type: "diy_page",
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      showImg: false,
      showImgUrl: ""
    };
  },
  mounted: function mounted() {
    this.load();
  },
  methods: {
    load: function load() {
      var _this = this;
      this.listLoading = true;
      (0, _system.diypageList)(this.queryParams).then(function (response) {
        console.log(response);
        _this.total = response.total;
        _this.list = response.rows;
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.load();
    },
    createdDiy: function createdDiy() {
      this.$router.push({
        path: "/merchant/diy/page"
      });
    },
    editDiy: function editDiy(id) {
      this.$router.push({
        path: "/merchant/diy/page",
        query: {
          id: id
        }
      });
    },
    copy: function copy(id) {
      var _this2 = this;
      (0, _system.copyApi)(id).then(function (res) {
        _this2.$message.success("复制成功");
        _this2.load();
      });
    },
    delDiy: function delDiy(id) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u9875\u9762?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _system.delDiypageDetailApi)(id).then(function () {
          _this3.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    createQRCode: function createQRCode(id, key) {
      var _this4 = this;
      if (key === 2 || key === 4) {
        this.copyText = "".concat(_settingMer.default.appUrl, "/pages/home/home?id=").concat(id);
        var input = document.createElement("input"); // 直接构建input
        input.value = this.copyText; // 设置内容
        console.log(input.value);
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.$message.success("复制成功");
      } else if (key === 3) {
        this.showImg = true;
        (0, _system.sharePosterImgApi)({
          width: 340,
          height: 340,
          url: encodeURIComponent("/pages/home/home?id=".concat(id))
        }).then(function (res) {
          _this4.showImgUrl = res.data.image;
        });
      }
    },
    setHomeAction: function setHomeAction(id) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u8BBE\u7F6E\u4E3A\u9996\u9875?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _system.diypageSetDefaultApi)(id).then(function (res) {
          _this5.$message.success("操作成功");
          _this5.load();
        }).catch(function () {});
      }).catch(function () {});
    }
  }
};