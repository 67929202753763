"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._deleteSpace = _deleteSpace;
exports._onHasRecommended = _onHasRecommended;
exports.cardPriceApi = cardPriceApi;
exports.configListApi = configListApi;
exports.frameAdd = frameAdd;
exports.getExhibitList = getExhibitList;
exports.getFrameList = getFrameList;
exports.getIpToken = getIpToken;
exports.getMaterialList = getMaterialList;
exports.getMemberList = getMemberList;
exports.getSpaceList = getSpaceList;
exports.printUpdateApi = printUpdateApi;
exports.sizeList = sizeList;
exports.updateCardPriceApi = updateCardPriceApi;
exports.updateConfigApi = updateConfigApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 获取空间列表
 */
function getSpaceList(data) {
  return _request.default.get('/open3d/product/sys/artai/space/list', data);
}
/**
 * @description 获取展品列表
 */
function getExhibitList(data) {
  return _request.default.get('/open3d/product/sys/exhibit/list', data);
}
/**
 * @description 获取素材列表
 */
function getMaterialList(data) {
  return _request.default.get('/open3d/product/sys/source/material/list', data);
}

/**
 * @description 获取用户token
 */
function getIpToken(userId) {
  return _request.default.post("/open3d/member/sys/member/info/getToken/".concat(userId));
}

/**
 * @description 获取用户列表
 */
function getMemberList(data) {
  return _request.default.get("/open3d/product/sys/artai/space/memberList", data);
}

// 开启和关闭推荐
function _onHasRecommended(data) {
  return _request.default.put("/open3d/product/sys/artai/space", data);
}

// 删除空间
function _deleteSpace(id) {
  return _request.default.delete("/open3d/product/sys/artai/space/".concat(id));
}

// 尺寸列表
function sizeList() {
  return _request.default.get("/open3d/product/sys/print/frame/sizeList");
}
// 修改价格
function printUpdateApi(data) {
  return _request.default.post("/open3d/product/sys/print/frame/update", data);
}
// 画框列表
function getFrameList() {
  return _request.default.get("/open3d/product/sys/print/frame/List");
}
// 新增框类型
function frameAdd(data) {
  return _request.default.post("/open3d/product/sys/print/frame/add", data);
}

// 获取卡片价格
function cardPriceApi(data) {
  return _request.default.get("/open3d/activity/sys/calculus/card/list", data);
}

// 修改卡片价格
function updateCardPriceApi(data) {
  return _request.default.put("/open3d/activity/sys/calculus/card/update", data);
}

// 获取配置列表
function configListApi(data) {
  return _request.default.get("/open3d/activity/sys/calculus/card/ability/list", data);
}

// 更新配置列表的数据
function updateConfigApi(data) {
  return _request.default.put("/open3d/activity/sys/calculus/card/ability/update", data);
}