var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "td-game" },
        [
          _vm._v("\n    开启游戏\n    "),
          _c("el-switch", {
            attrs: { "active-value": true, "inactive-value": false },
            on: { change: _vm.changegameflag },
            model: {
              value: _vm.tdgameflag,
              callback: function ($$v) {
                _vm.tdgameflag = $$v
              },
              expression: "tdgameflag",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticClass: "mt20",
          attrs: { data: _vm.tabeldata, border: "", stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "塔名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "率" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.odds) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "怪物出现概率" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.monsterOdds) + "% ")]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "保底" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.breakEven) + "%\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "攻击目标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c("el-image", {
                          attrs: {
                            src: scope.row.attackPicture,
                            fit: "fill",
                            lazy: true,
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(scope.row.attackName))]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", prop: "updateTime" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { type: "primary", size: "default" },
          on: { click: _vm.editSwitch },
        },
        [_vm._v("编辑")]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "玩法说明" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { height: 300 },
                model: {
                  value: _vm.formValidate.caption,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "caption", $$v)
                  },
                  expression: "formValidate.caption",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "用户协议" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { height: 300 },
                model: {
                  value: _vm.formValidate.agreement,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "agreement", $$v)
                  },
                  expression: "formValidate.agreement",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.setConfig } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.showEditFlag, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.showEditFlag = $event
            },
            close: function ($event) {
              _vm.showEditFlag = false
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "mt20",
              attrs: { data: _vm.editTableData, border: "", stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "塔名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入塔名称" },
                          model: {
                            value: scope.row.name,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "name", $$v)
                            },
                            expression: "scope.row.name",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "率" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "120px" },
                          attrs: {
                            min: 0,
                            step: 1,
                            controls: true,
                            "controls-position": "both",
                          },
                          model: {
                            value: scope.row.odds,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "odds", $$v)
                            },
                            expression: "scope.row.odds",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "怪物出现概率" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "120px" },
                          attrs: {
                            disabled: scope.$index == 0,
                            min: 0,
                            max: 100,
                            step: 1,
                            controls: true,
                            "controls-position": "both",
                          },
                          on: { change: _vm.changeprobility },
                          model: {
                            value: scope.row.monsterOdds,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "monsterOdds", $$v)
                            },
                            expression: "scope.row.monsterOdds",
                          },
                        }),
                        _vm._v("%\n        "),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "保底" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "120px" },
                          attrs: {
                            min: 0,
                            step: 1,
                            controls: true,
                            "controls-position": "both",
                          },
                          model: {
                            value: scope.row.breakEven,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "breakEven", $$v)
                            },
                            expression: "scope.row.breakEven",
                          },
                        }),
                        _vm._v("\n          %\n        "),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "攻击目标头像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1", scope.$index)
                              },
                            },
                          },
                          [
                            scope.row.attackPicture
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: scope.row.attackPicture + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "攻击目标名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入攻击目标名称" },
                          model: {
                            value: scope.row.attackName,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "attackName", $$v)
                            },
                            expression: "scope.row.attackName",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showEditFlag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.setConfig } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }