"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.option = void 0;
var option = exports.option = [{
  xAxis: {
    axisLine: {
      lineStyle: {
        color: "#dfdfdf"
      }
    },
    boundaryGap: false,
    axisTick: {
      show: false
    },
    name: "日期",
    axisLabel: {
      interval: 0,
      rotate: 30
    },
    data: []
  },
  // dataZoom: [
  //   {
  //     type: 'slider',
  //     start: 0,
  //     end: 30
  //   },
  //   {
  //     start: 0,
  //     end: 30
  //   }
  // ],
  grid: {
    left: 50,
    right: 50,
    bottom: 20,
    top: 60,
    containLabel: true
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross"
    },
    padding: [5, 10]
  },
  legend: {
    data: ["挂单成交", "挂单失败"],
    left: 10
  },
  yAxis: {
    name: "数量",
    type: "value"
  },
  series: [{
    name: "挂单成交",
    markPoint: {
      data: [{
        type: "max",
        name: "峰值"
      }]
    },
    itemStyle: {
      normal: {
        color: "#5b8ff9",
        lineStyle: {
          color: "#5b8ff9",
          width: 2
        }
      }
    },
    smooth: false,
    type: "line",
    data: [],
    animationDuration: 2800,
    animationEasing: "cubicInOut"
  }, {
    name: "挂单失败",
    markPoint: {
      data: [{
        type: "max",
        name: "峰值"
      }]
    },
    itemStyle: {
      normal: {
        color: "#5d7092",
        lineStyle: {
          color: "#5d7092",
          width: 2
        },
        areaStyle: {
          color: "rgba(255,255,255,.4)"
        }
      }
    },
    smooth: false,
    type: "line",
    data: [],
    animationDuration: 2800,
    animationEasing: "quadraticOut"
  }]
}, {
  xAxis: {
    axisLine: {
      lineStyle: {
        color: "#dfdfdf"
      }
    },
    name: "日期",
    boundaryGap: false,
    axisTick: {
      show: false
    },
    axisLabel: {
      interval: 0,
      rotate: 30
    },
    data: []
  },
  // dataZoom: [
  //   {
  //     type: "inside",
  //     start: 0,
  //     end: 10
  //   },
  //   {
  //     start: 0,
  //     end: 10
  //   }
  // ],
  grid: {
    left: 50,
    right: 50,
    bottom: 20,
    top: 60,
    containLabel: true
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross"
    },
    padding: [5, 10]
  },
  legend: {
    data: ["挂单量", "成交量", "成交比"],
    left: 10
  },
  yAxis: {
    name: "数量",
    type: "value"
  },
  series: [{
    name: "挂单量",
    markPoint: {
      data: [{
        type: "max",
        name: "峰值"
      }]
    },
    itemStyle: {
      normal: {
        color: "#5b8ff9",
        lineStyle: {
          color: "#5b8ff9",
          width: 2
        }
      }
    },
    smooth: false,
    type: "line",
    data: [],
    animationDuration: 2800,
    animationEasing: "cubicInOut"
  }, {
    name: "成交量",
    markPoint: {
      data: [{
        type: "max",
        name: "峰值"
      }]
    },
    itemStyle: {
      normal: {
        color: "#5d7092",
        lineStyle: {
          color: "#5d7092",
          width: 2
        },
        areaStyle: {
          color: "rgba(255,255,255,.4)"
        }
      }
    },
    smooth: false,
    type: "line",
    data: [],
    animationDuration: 2800,
    animationEasing: "quadraticOut"
  }, {
    name: "成交比",
    markPoint: {
      data: [{
        type: "max",
        name: "峰值"
      }]
    },
    itemStyle: {
      normal: {
        color: "#5ad8a6",
        lineStyle: {
          color: "#5ad8a6",
          width: 2
        },
        areaStyle: {
          color: "rgba(255,255,255,.4)"
        }
      }
    },
    smooth: false,
    type: "line",
    data: [],
    animationDuration: 2800,
    animationEasing: "quadraticOut"
  }]
}, {
  xAxis: {
    axisLine: {
      lineStyle: {
        color: "#dfdfdf"
      }
    },
    name: "日期",
    boundaryGap: false,
    axisTick: {
      show: false
    },
    axisLabel: {
      interval: 0,
      rotate: 30
    },
    data: []
  },
  // dataZoom: [
  //   {
  //     type: "inside",
  //     start: 0,
  //     end: 10
  //   },
  //   {
  //     start: 0,
  //     end: 10
  //   }
  // ],
  grid: {
    left: 50,
    right: 50,
    bottom: 20,
    top: 60,
    containLabel: true
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross"
    },
    padding: [5, 10]
  },
  legend: {
    data: ["挂单额", "成交额", "成交比"],
    left: 10
  },
  yAxis: {
    name: "数量",
    type: "value"
  },
  series: [{
    name: "挂单额",
    markPoint: {
      data: [{
        type: "max",
        name: "峰值"
      }]
    },
    itemStyle: {
      normal: {
        color: "#5b8ff9",
        lineStyle: {
          color: "#5b8ff9",
          width: 2
        }
      }
    },
    smooth: false,
    type: "line",
    data: [],
    animationDuration: 2800,
    animationEasing: "cubicInOut"
  }, {
    name: "成交额",
    markPoint: {
      data: [{
        type: "max",
        name: "峰值"
      }]
    },
    itemStyle: {
      normal: {
        color: "#5d7092",
        lineStyle: {
          color: "#5d7092",
          width: 2
        },
        areaStyle: {
          color: "rgba(255,255,255,.4)"
        }
      }
    },
    smooth: false,
    type: "line",
    data: [],
    animationDuration: 2800,
    animationEasing: "quadraticOut"
  }, {
    name: "成交比",
    markPoint: {
      data: [{
        type: "max",
        name: "峰值"
      }]
    },
    itemStyle: {
      normal: {
        color: "#5ad8a6",
        lineStyle: {
          color: "#5ad8a6",
          width: 2
        },
        areaStyle: {
          color: "rgba(255,255,255,.4)"
        }
      }
    },
    smooth: false,
    type: "line",
    data: [],
    animationDuration: 2800,
    animationEasing: "quadraticOut"
  }]
}];