"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      companyList: [],
      deliveryList: [],
      timeType: [],
      queryParams: {
        companyId: '',
        invoiceType: '2',
        status: '',
        timeType: '',
        pageSize: 10,
        pageNum: 1,
        hasPaper: ''
      },
      //表格 分页
      statistical: {},
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      visible: false,
      form: {},
      dialogVisible: false,
      formState: {}
    };
  },
  created: function created() {
    var _this = this;
    (0, _finance.getComponseListApi)().then(function (res) {
      return _this.companyList = res.data;
    });
    (0, _finance.getDeliveryListApi)().then(function (res) {
      return _this.deliveryList = res.data;
    });
    this.getTableList();
  },
  methods: {
    getTableList: function getTableList(num) {
      var _this2 = this;
      if (num) this.queryParams.pageNum = num;
      (0, _finance.getContractListApi)((0, _objectSpread2.default)({}, this.queryParams)).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.statistical = res.statistical;
      });
    },
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.timeType = e ? this.timeType.join("-") : "";
      this.getTableList(1);
    },
    onDetails: function onDetails(row) {
      window.open(row.contractUrl);
    },
    onSubmit: function onSubmit() {
      var _this3 = this;
      (0, _finance.contractSendApi)(this.formState).then(function (res) {
        // console.log(res);
        if (res.code == 200) {
          _this3.$message.success('操作成功');
          _this3.getTableList(1);
          _this3.dialogVisible = false;
        }
      });
    },
    onVerify: function onVerify(row) {
      this.formState = row;
      this.dialogVisible = true;
    },
    pageChange: function pageChange(val) {
      this.getTableList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getTableList(1);
    }
  }
};