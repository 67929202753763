"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = _interopRequireDefault(require("element-ui"));
require("@/styles/element-variables.scss");
var _index = _interopRequireDefault(require("./index.vue"));
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});
var uploadFrom = {};
uploadFrom.install = function (Vue, options) {
  var ToastConstructor = Vue.extend(_index.default);
  // 生成一个该子类的实例
  var instance = new ToastConstructor();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);
  Vue.prototype.$modalUpload = function (callback, isMore, changeType, changeCategory) {
    instance.visible = true;
    instance.callback = callback;
    instance.isMore = "".concat(isMore || 0);
    instance.changeType = "".concat(changeType || '1');
    instance.changeCategory = "".concat(changeCategory || '');
  };
};
var _default = exports.default = uploadFrom;