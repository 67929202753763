var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "code-v" },
      [
        _c(
          "div",
          { staticStyle: { margin: "20px 0" } },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.create },
              },
              [_vm._v("创建消费排行统计")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData.data,
                      size: "small",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "名称", "min-width": "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_c("span", [_vm._v(_vm._s(row.topName))])]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "统计时间", "min-width": "180" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(row.startTime + "-" + row.endTime)
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createTime",
                        label: "创建时间",
                        "min-width": "150",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "100",
                        fixed: "right",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-dropdown",
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "el-dropdown-link mr10",
                                      staticStyle: {
                                        color: "#1890ff",
                                        "font-size": "12px",
                                        cursor: "pointer",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  链接\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.linkAction(
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("H5链接")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.QRAction(scope.row.id)
                                            },
                                          },
                                        },
                                        [_vm._v("二维码")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.lookShow(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看排行榜")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block mt20" },
                  [
                    _c("el-pagination", {
                      staticStyle: { "text-align": "right" },
                      attrs: {
                        "page-sizes": [20, 40, 60, 80],
                        "page-size": _vm.tableFrom.pageSize,
                        "current-page": _vm.tableFrom.pageNum,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.tableData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.pageChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.showImg,
              "close-on-click-modal": false,
              center: "",
              title: "查看二维码",
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showImg = $event
              },
            },
          },
          [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.showImgUrl, alt: "" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.isRank,
              width: "500px",
              title: _vm.title + "消费排行榜",
              "close-on-click-modal": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isRank = $event
              },
            },
          },
          [
            _c(
              "el-form",
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "名称" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.topName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "topName", $$v)
                        },
                        expression: "form.topName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "设置统计排行结束时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.timeArr,
                        callback: function ($$v) {
                          _vm.timeArr = $$v
                        },
                        expression: "timeArr",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.form.isEveryDay,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isEveryDay", $$v)
                          },
                          expression: "form.isEveryDay",
                        },
                      },
                      [_vm._v("可按每天查看排行")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.isRank = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.submit },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-drawer",
          {
            attrs: {
              title: "查看排行榜",
              visible: _vm.params.drawer,
              "append-to-body": "",
              size: "50%",
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.params, "drawer", $event)
              },
              close: _vm.close,
              open: _vm.open,
            },
          },
          [
            _c(
              "div",
              { staticClass: "ml20 pr20" },
              [
                _c(
                  "el-tabs",
                  {
                    staticStyle: { width: "100%" },
                    on: { "tab-click": _vm.changeTypeAction },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  },
                  [
                    _c("el-tab-pane", { attrs: { label: "消费", name: "1" } }),
                    _vm._v(" "),
                    _c("el-tab-pane", { attrs: { label: "持仓", name: "2" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  padding: "0 20px 20px 20px",
                  "overflow-y": "auto",
                  height: "70%",
                },
              },
              _vm._l(_vm.tableData1.data, function (item, index) {
                return _c("div", { key: item.id, staticClass: "l-list" }, [
                  _c("div", { staticClass: "flex align-items-c mt10" }, [
                    !item.avatar
                      ? _c("img", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: "https://java-test-cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.avatar
                      ? _c("img", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: { src: item.avatar, alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "line-height": "32px",
                          "margin-left": "20px",
                        },
                      },
                      [
                        _c("div", [_vm._v(_vm._s(item.nickName))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(item.phone))]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.type == "1"
                    ? _c("div", [_vm._v(_vm._s(item.consumeMoney) + "元")])
                    : _c("div", [_vm._v(_vm._s(item.number) + "个")]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block mt20" },
              [
                _c("el-pagination", {
                  staticStyle: { "text-align": "right" },
                  attrs: {
                    "page-sizes": [20, 40, 60, 80],
                    "page-size": _vm.tableFrom1.pageSize,
                    "current-page": _vm.tableFrom1.pageNum,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData1.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange1,
                    "current-change": _vm.pageChange1,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }