var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "130px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "任务名称：", prop: "appointmentName" } },
                [
                  _c("el-input", {
                    staticClass: "selwidth",
                    attrs: { placeholder: "请输入任务名称" },
                    model: {
                      value: _vm.formValidate.appointmentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "appointmentName", $$v)
                      },
                      expression: "formValidate.appointmentName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "导入名单：" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "myUpload",
                      staticClass: "upload-demo",
                      attrs: {
                        action: "",
                        headers: _vm.myHeaders,
                        "http-request": _vm.uploadRequest,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("导入")]
                      ),
                      _vm._v(" "),
                      _vm.formValidate.num && _vm.uploadFlag
                        ? _c("span", [
                            _vm._v(
                              "导入成功！共导入" +
                                _vm._s(_vm.formValidate.num) +
                                "条"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.formValidate.num && _vm.uploadFlag
                        ? _c("span", [_vm._v("导入失败！请检查表格")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "text" },
                      on: { click: _vm.downLoad },
                    },
                    [_vm._v("下载导入模板")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "预锁仓设置：", prop: "appointmentProducts" },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.formValidate.appointmentProducts,
                        size: "small",
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "图片", prop: "coverPicture" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                      },
                                      attrs: {
                                        src:
                                          (row.coverPicture || row.image) +
                                          "!120a",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "productType", label: "商品类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatProductType")(
                                        row.productType
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "productName", label: "商品名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(row.productName || row.name)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "checkType", label: "销售库存" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.stock) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "hasCount", label: "起售时间" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.buyTime) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      !_vm.$route.query.id
                        ? _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.productDelete(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2841785205
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.formValidate.appointmentProducts.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProducts(1)
                            },
                          },
                        },
                        [_vm._v("添加数字藏品")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.formValidate.appointmentProducts.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProducts(2)
                            },
                          },
                        },
                        [_vm._v("添加数字盲盒")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.formValidate.appointmentProducts.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProducts(3)
                            },
                          },
                        },
                        [_vm._v("添加专辑")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "信息推送：", prop: "toMessageTime" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.formValidate.isToMessage,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "isToMessage", $$v)
                            },
                            expression: "formValidate.isToMessage",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: 0 } }, [
                            _vm._v("站内消息"),
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: 1 } }, [
                            _vm._v("短信群发"),
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: 2 } }, [
                            _vm._v("App推送"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticClass: "selwidth ml20",
                        attrs: {
                          type: "datetime",
                          placeholder: "选择发送时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          disabled: _vm.formValidate.isToMessage.length == 0,
                        },
                        model: {
                          value: _vm.formValidate.toSendTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "toSendTime", $$v)
                          },
                          expression: "formValidate.toSendTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.visibleTitle,
            visible: _vm.visible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.visibleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-list mt20" },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: {
                    size: "small",
                    clearable: "",
                    placeholder: "名称搜索",
                  },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.productForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.productForm, "name", $$v)
                    },
                    expression: "productForm.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.goSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  key: Math.random(),
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.productList.data, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "头像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.coverPicture },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "startTime",
                      label: "起售时间",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "stock", label: "库存", "min-width": "80" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseRow(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.choose ? "取消选择" : "选择")
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.productList.pageSize,
                  "current-page": _vm.productList.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.productList.total,
                },
                on: {
                  "size-change": _vm.productFormSizeChange,
                  "current-change": _vm.productFormpageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }