"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "inventorycrate",
  data: function data() {
    return {
      loading: false,
      listLoading: false,
      rules: {
        warehouse_id: [{
          required: true,
          message: "请选择仓库ID",
          trigger: "change"
        }],
        manage_admin_id: [{
          required: true,
          message: "请选择业务负责人",
          trigger: "change"
        }],
        push_type: [{
          required: true,
          message: "请选择入库类型",
          trigger: "change"
        }]
      },
      formValidate: {
        warehouse_id: "",
        manage_admin_id: "",
        push_type: ""
      },
      goodsData: [],
      tableFrom: {
        page: 1,
        limit: 20,
        cate_id: "",
        keyword: "",
        product_id: "",
        warehouse_id: 0
      },
      invtableFrom: {
        page: 1,
        limit: 20,
        is_push_warehouse: 1
      },
      tableData: {
        data: [],
        total: 0
      },
      invtableData: {
        data: [],
        total: 0
      },
      id: this.$route.query.id || "",
      storeArr: [],
      adminarr: [],
      goodsArr: [],
      goodsIdArr: [],
      BrandList: [],
      attrList: [],
      attr: [],
      tab: 'inv',
      lockid: '',
      locktype: '',
      cateArr: [],
      cateIdArr: [],
      shelfList: [],
      orderno: '',
      mannagename: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _warehouse.getOrderNo)().then(function (res) {
      _this.orderno = res.data.order_no;
    });
    this.getstore();
    this.getadminList();
    this.getBrandListApi();
    (0, _user.getBaseInfo)().then(function (res) {
      _this.label = res.data;
      _this.mannagename = res.data.merchantAdmin.real_name;
      if (res.data.isStaff) {
        _this.formValidate.manage_admin_id = res.data.merchantAdmin.merchant_admin_id;
      }
    }).catch(function (_ref) {
      var message = _ref.message;
    } // 
    );
    this.getList();
    this.getinvent();
  },
  methods: {
    close: function close() {
      this.$router.back();
    },
    pushshelf: function pushshelf() {
      var _this2 = this;
      this.formValidate.push_warehouse_id = this.formValidate.warehouse_id;
      (0, _basic.shelfList)({
        warehouse_id: this.formValidate.push_warehouse_id,
        page: 1,
        limit: 200
      }).then(function (res) {
        _this2.shelfList = res.data.list;
      });
    },
    leavetab: function leavetab() {
      if (this.locktype) {
        this.tab = this.locktype;
        return flase;
      }
    },
    changetab: function changetab(e) {
      if (this.locktype && e.name != this.locktype) {
        return;
      }
      this.tab = e.name;
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this3 = this;
      (0, _product.brandListApi)().then(function (res) {
        _this3.BrandList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getadminList: function getadminList() {
      var _this4 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this4.adminarr = res.data.list;
      });
    },
    getstore: function getstore() {
      var _this5 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this5.storeArr = res.data.list;
      });
    },
    submitForm: function submitForm(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          // let goodsarr = this.goodsIdArr;
          var goodsarr = [];
          _this6.cateArr.forEach(function (item) {
            goodsarr = goodsarr.concat(item.goodsList);
          });
          if (!goodsarr.length) {
            return _this6.$message.error('请选择货品');
          }
          _this6.formValidate.productData = goodsarr;
          _this6.formValidate.push_warehouse_id = _this6.formValidate.warehouse_id;
          (0, _warehouse.createpushOrder)(_this6.formValidate).then(function (res) {
            _this6.loading = false;
            if (res.status == 200) {
              _this6.$message.success("保存成功");
              _this6.$router.back();
            }
          }).catch(function (res) {
            _this6.loading = false;
            // this.$message.error(res.message);
          });
        } else {
          return false;
        }
      });
    },
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
    },
    getinvent: function getinvent() {
      var _this7 = this;
      this.invtableFrom.manage_admin_id = this.formValidate.manage_admin_id;
      (0, _basic.getpushinventoryOrderList)(this.invtableFrom).then(function (res) {
        _this7.invtableData.data = res.data.list;
        _this7.invtableData.total = res.data.count;
        _this7.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    getList: function getList() {
      var _this8 = this;
      (0, _basic.goodsList)(this.tableFrom).then(function (res) {
        _this8.tableData.data = res.data.list;
        _this8.tableData.total = res.data.count;
        _this8.listLoading = false;
      }).catch(function (res) {
        _this8.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    saleGoods: function saleGoods(row, type, eid, inrow) {
      if (this.goodsIdArr.indexOf(row.product_id) > -1) {
        return;
      }
      if (this.lockid && eid != this.lockid) {
        this.$message.error('不能选择不同委托人的货品');
        return;
      }
      if (this.locktype && type != this.locktype) {
        return;
      }
      if (type == 'inv') {
        var index = this.cateIdArr.indexOf(row.cate_parent[0].store_category_id);
        row.retain_price = inrow.retain_price;
        if (index > -1) {
          this.cateArr[index].goodsList.push(row);
        } else {
          this.cateArr.push({
            cate: row.cate_parent[0],
            attribute: inrow.attribute,
            goodsList: [row]
          });
          this.cateIdArr.push(row.cate_parent[0].store_category_id);
        }
      } else {
        var _index = this.cateIdArr.indexOf(row.cate_parent[0].store_category_id);
        if (_index > -1) {
          this.cateArr[_index].goodsList.push(row);
        } else {
          this.cateArr.push({
            cate: row.cate_parent[0],
            attribute: row.attribute,
            goodsList: [row]
          });
          this.cateIdArr.push(row.cate_parent[0].store_category_id);
        }
      }
      // this.cateArr = [];
      // this.cateIdArr = [];
      // let json = {};
      this.locktype = type;
      this.lockid = eid || '';
      if (this.lockid && !this.formValidate.manage_admin_id) {
        this.formValidate.manage_admin_id = this.lockid;
      }
      // this.goodsArr.push(row);
      this.goodsIdArr.push(row.product_id);
    },
    delGoods: function delGoods(index, row, cindex) {
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      // this.goodsArr.splice(nindex, 1);
      this.cateArr[cindex].goodsList.splice(index, 1);
      if (this.cateArr[cindex].goodsList.length == 0) {
        this.cateArr.splice(cindex, 1);
      }
      if (this.goodsIdArr.length <= 0) {
        this.locktype = '';
        this.lockid = '';
      }
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    invpageChange: function invpageChange(page) {
      this.invtableFrom.page = page;
      this.getinvent();
    },
    invhandleSizeChange: function invhandleSizeChange(val) {
      this.invtableFrom.limit = val;
      this.getinvent();
    }
  }
};