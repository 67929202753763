var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "more-switch" },
    [
      _c(
        "el-button",
        {
          staticClass: "mt10 mb20",
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.onAdd },
        },
        [_vm._v("添加文章分类")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            "highlight-current-row": "",
            "row-key": "id",
            "default-expand-all": false,
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "分类名称", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.label + "  [ " + scope.row.id + "  ]")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "type", label: "配置分类说明", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === 1
                      ? _c("div", [_vm._v("产品分类")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分类图片", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: scope.row.url ? scope.row.url : _vm.moren,
                            "preview-src-list": [
                              scope.row.url ? scope.row.url : _vm.moren,
                            ],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "是否显示", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      staticStyle: { width: "130px" },
                      attrs: { "active-text": "显示", "inactive-text": "隐藏" },
                      on: {
                        change: function ($event) {
                          return _vm.onchangeIsShow(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "100", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id, scope.$index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editCateData.show,
            width: "500px",
            "close-on-click-modal": false,
            title: _vm.editCateData.id ? "修改分类" : "添加分类",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editCateData, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.editCateData,
                rules: _vm.rules,
                "label-width": "80px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级分类", prop: "parentId" } },
                [
                  _c("el-cascader", {
                    staticClass: "w200",
                    attrs: {
                      options: _vm.cateLists,
                      props: { expandTrigger: "hover", checkStrictly: true },
                    },
                    model: {
                      value: _vm.editCateData.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCateData, "parentId", $$v)
                      },
                      expression: "editCateData.parentId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.editCateData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCateData, "name", $$v)
                      },
                      expression: "editCateData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "分类图片" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("1")
                      },
                    },
                  },
                  [
                    _vm.editCateData.url
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", {
                            attrs: { src: _vm.editCateData.url + "!120a" },
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-close btndel",
                            on: { click: _vm.handleRemove },
                          }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类类型" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.editCateData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.editCateData, "type", $$v)
                        },
                        expression: "editCateData.type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: 1, label: "产品分类" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否显示" } },
                [
                  _c("el-switch", {
                    staticStyle: { width: "130px" },
                    attrs: { "active-text": "显示", "inactive-text": "隐藏" },
                    model: {
                      value: _vm.editCateData.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCateData, "status", $$v)
                      },
                      expression: "editCateData.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.editCateData.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCateData, "sort", $$v)
                      },
                      expression: "editCateData.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }