"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _marketing = require("@/api/marketing");
var _order = require("@/api/order");
var _settings = require("@/settings");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponUser',
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      Loading: false,
      loadingSeach: false,
      roterPre: _settings.roterPre,
      tableFromIssue: {
        pageNum: 1,
        pageSize: 10,
        phone: '',
        luckyDrawId: '',
        date: ''
      },
      issueData: {
        data: [],
        total: 0
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      uid: '',
      showdetail: false,
      visibleShip: false,
      shipForm: {
        id: '',
        expressCompany: '',
        expressNumber: ''
      },
      shipRules: {
        expressCompany: [{
          required: true,
          message: '请选择快递公司',
          trigger: 'change'
        }],
        expressNumber: [{
          required: true,
          message: '请输入快递单号',
          trigger: 'blur'
        }]
      },
      dcList: [],
      drawList: []
    };
  },
  mounted: function mounted() {
    this.getIssueList();
    this.deliveryCompanyList();
  },
  methods: {
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(40);
    },
    searchDrawAction: function searchDrawAction(name) {
      var _this = this;
      this.loadingSeach = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        (0, _marketing.luckyListApi)({
          pageNum: 1,
          pageSize: 10,
          name: name
        }).then(function (res) {
          _this.drawList = res.rows;
          _this.loadingSeach = false;
        });
      }, 500);
    },
    confirmShipAction: function confirmShipAction(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var d = JSON.parse(JSON.stringify(_this2.shipForm));
          (0, _marketing.luckyToSendApi)(d).then(function () {
            _this2.$message.success('操作成功');
            _this2.visibleShip = false;
            _this2.getIssueList();
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    deliveryCompanyList: function deliveryCompanyList() {
      var _this3 = this;
      (0, _order.deliveryCompanyListApi)({
        pageNum: 1,
        pageSize: 100
      }).then(function (res) {
        // console.log(res)
        _this3.dcList = res.rows;
      }).catch(function (res) {});
    },
    // 列表
    getIssueList: function getIssueList() {
      var _this4 = this;
      this.Loading = true;
      (0, _marketing.luckySysInfoApi)(this.tableFromIssue).then(function (res) {
        _this4.issueData.data = res.rows;
        _this4.issueData.total = res.total;
        _this4.Loading = false;
      }).catch(function (res) {
        _this4.Loading = false;
        // this.$message.error(res.message)
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFromIssue.date = tab;
      this.pageChangeIssue(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      if (e) {
        this.tableFromIssue.startTime = e[0];
        this.tableFromIssue.endTime = e[1];
      } else {
        this.tableFromIssue.startTime = '';
        this.tableFromIssue.endTime = '';
      }
      this.pageChangeIssue(1);
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.pageNum = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.pageSize = val;
      this.getIssueList();
    },
    exportOrder: function exportOrder() {
      var _this5 = this;
      var data = (0, _objectSpread2.default)({}, this.tableFromIssue);
      (0, _marketing.airdropExport)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: teal'
          }, '导出已生成列表'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.userId;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    // 发货
    send: function send(id) {
      this.shipForm = {
        id: id,
        expressCompany: '',
        expressNumber: ''
      };
      this.visibleShip = true;
    },
    editDeliveryAction: function editDeliveryAction(row) {
      this.shipForm.id = row.id;
      this.shipForm.expressCompany = String(row.expressCompany);
      this.shipForm.expressNumber = row.expressNumber;
      this.visibleShip = true;
    }
  }
};