var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "合成" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control ft12",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("风格模板选择"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center flex-wrap" },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "change-type",
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          class: { curr: _vm.is_active(item) },
                          on: {
                            click: function ($event) {
                              return _vm.setStyle(item.value)
                            },
                          },
                        },
                        [
                          _c("label", [
                            _c("img", {
                              staticStyle: { width: "62px" },
                              attrs: { src: item.src },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "name color-gray align-c" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("展示条件"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.content.showType,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "showType", $$v)
                        },
                        expression: "content.showType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("全部")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("未开始"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("进行中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("已结束"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.style !== 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("图片展示方式"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style !== 1
                ? _c(
                    "div",
                    { staticClass: "item-align-center flex-wrap" },
                    _vm._l(_vm.imgStyleData, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "change-type",
                          staticStyle: {
                            "margin-right": "20px",
                            "margin-top": "15px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "img",
                              class: {
                                curr: item.value === _vm.content.img_style,
                              },
                            },
                            [
                              _c("label", [
                                _c("img", { attrs: { src: item.src } }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.content.img_style,
                                      expression: "content.img_style",
                                    },
                                  ],
                                  staticClass: "choose-input",
                                  attrs: { type: "radio", name: "choose" },
                                  domProps: {
                                    value: item.value,
                                    checked: _vm._q(
                                      _vm.content.img_style,
                                      item.value
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.content,
                                        "img_style",
                                        item.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "name color-gray align-c" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.cData.dataType !== "category"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "item-align-center mt20" },
                      [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("数据选择")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.content.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.content, "type", $$v)
                              },
                              expression: "content.type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("系统自动展示"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("手动选择展示"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.content.type === 1
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("系统自动排序条件")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 1
                      ? _c(
                          "div",
                          { staticClass: "item-align-center mt10" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "le-radio-group",
                                model: {
                                  value: _vm.content.system_sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.content, "system_sort", $$v)
                                  },
                                  expression: "content.system_sort",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("按最新发布排序"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("按销售时间排序"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 1 && !_vm.loadMore
                      ? _c(
                          "div",
                          { staticClass: "item-align-center mt20" },
                          [
                            _c(
                              "div",
                              { staticClass: "w80 fontW400 ft14 color-black" },
                              [_vm._v("展示数据")]
                            ),
                            _vm._v(" "),
                            _c("el-input-number", {
                              attrs: { min: 1, max: 50, label: "数量" },
                              model: {
                                value: _vm.content.system_count,
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, "system_count", $$v)
                                },
                                expression: "content.system_count",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 2
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("手动选择合成活动")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.data &&
                    _vm.content.data.length > 0 &&
                    _vm.content.type === 2
                      ? _c(
                          "div",
                          { staticClass: "pt10" },
                          _vm._l(_vm.content.data, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "kuang1 item-align-center mt10",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "item-align-center flex-one" },
                                  [
                                    _c("div", { staticClass: "ft14" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                index === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "go-down-img ml10",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDown(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "go-up-img ml10",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goUp(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "del-img",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 2
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c("img", {
                            staticStyle: {
                              width: "12px",
                              height: "12px",
                              "margin-right": "5px",
                              "margin-top": "2px",
                            },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "fontW500 ft16",
                              on: { click: _vm.handleAdditem },
                            },
                            [_vm._v("手动选择合成活动")]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.page_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_margin", $$v)
                      },
                      expression: "facade.page_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.img_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "img_radius", $$v)
                      },
                      expression: "facade.img_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片下圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.img_bottom_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "img_bottom_radius", $$v)
                      },
                      expression: "facade.img_bottom_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "下圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.status_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_radius", $$v)
                      },
                      expression: "facade.status_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上下间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_bottom_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_bottom_padding", $$v)
                      },
                      expression: "facade.item_bottom_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容底色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.status_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_background", $$v)
                      },
                      expression: "facade.status_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容背景" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "status_bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.status_bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("status_bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.status_bg_image
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.status_bg_image + "!120a",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.font_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "font_weight", $$v)
                        },
                        expression: "facade.font_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_color", $$v)
                      },
                      expression: "facade.title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.title_font_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_font_size", $$v)
                      },
                      expression: "facade.title_font_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.title_font_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_font_weight", $$v)
                        },
                        expression: "facade.title_font_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "状态背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.status_bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_bg_color", $$v)
                      },
                      expression: "facade.status_bg_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景渐变色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.status_bg_color_gradient,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_bg_color_gradient", $$v)
                      },
                      expression: "facade.status_bg_color_gradient",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "状态文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.status_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_color", $$v)
                      },
                      expression: "facade.status_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "状态字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.status_fontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_fontSize", $$v)
                      },
                      expression: "facade.status_fontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "未开始" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "status_not_started")
                        },
                      },
                    },
                    [
                      _vm.facade.status_not_started
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("status_not_started")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.status_not_started
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.status_not_started + "!120a",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "进行中" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "status_started")
                        },
                      },
                    },
                    [
                      _vm.facade.status_started
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("status_started")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.status_started
                        ? _c("img", {
                            attrs: { src: _vm.facade.status_started + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "已结束" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "status_sell_out")
                        },
                      },
                    },
                    [
                      _vm.facade.status_sell_out
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("status_sell_out")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.status_sell_out
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.status_sell_out + "!120a",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "剩余主颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.surplus_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "surplus_color", $$v)
                      },
                      expression: "facade.surplus_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "剩余副颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.surplus_desc_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "surplus_desc_color", $$v)
                      },
                      expression: "facade.surplus_desc_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "剩余文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.surplus_text_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "surplus_text_color", $$v)
                      },
                      expression: "facade.surplus_text_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.style == 2
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("图片尺寸")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "item-align-center" },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", placeholder: "宽" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: _vm.facade.img_width,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "img_width", $$v)
                                },
                                expression: "facade.img_width",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("px"),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { margin: "0 6px" },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", placeholder: "宽" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: _vm.facade.img_height,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "img_height", $$v)
                                },
                                expression: "facade.img_height",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("px"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ComposeList", {
        ref: "course",
        attrs: { showSelect: true, currentData: _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }