"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contractSendApi = contractSendApi;
exports.getBalanceList = getBalanceList;
exports.getComponseListApi = getComponseListApi;
exports.getContractListApi = getContractListApi;
exports.getDeliveryListApi = getDeliveryListApi;
exports.getEntryList = getEntryList;
exports.getExport = getExport;
exports.getExportDetail = getExportDetail;
exports.getInvoiceListApi = getInvoiceListApi;
exports.getPay = getPay;
exports.getPayInfoApi = getPayInfoApi;
exports.getPayOrderList = getPayOrderList;
exports.getWithdrawalList = getWithdrawalList;
exports.getwithdrawal = getwithdrawal;
exports.invoiceAccept = invoiceAccept;
exports.resourceUpdApi = resourceUpdApi;
exports.sendContactNew = sendContactNew;
exports.updateInfoApi = updateInfoApi;
exports.withdrawalApproval = withdrawalApproval;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 获取提现记录 -- 列表
 */
function getWithdrawalList(data) {
  return _request.default.get("/pay/sys/withdrawal/list", data);
}

/**
 * @description 获取支付记录 -- 列表
 */
function getPayOrderList(data) {
  return _request.default.get("/pay/sys/pay/order/list", data);
}

/**
 * @description 余额明细 -- 列表
 */
function getBalanceList(data) {
  return _request.default.get("/open3d/member/sys/member/wallet/list", data);
}

/**
 * @description 入账明细 -- 列表
 */
function getEntryList(data) {
  return _request.default.get("/open3d/member/sys/member/wallet/account", data);
}

/**
 * @description 余额明细导出
 */
function getExport(data) {
  return _request.default.get("/open3d/member/sys/member/wallet/export", data);
}

/**
 * @description 出账入账明细
 */
function getExportDetail(data) {
  return _request.default.get("/open3d/member/sys/member/wallet/account/export", data);
}

/**
 * @description 提现明细
 */
function getwithdrawal(data) {
  return _request.default.get("/pay/sys/withdrawal/export", data);
}

/**
 * @description 支付明细
 */
function getPay(data) {
  return _request.default.get("/pay/sys/pay/order/export", data);
}

/**
 * @description 是否同意体现 /pay/sys/withdrawal/approval
 */
function withdrawalApproval(data) {
  return _request.default.post("pay/sys/withdrawal/approval", data);
}
//获取提现设置
function getPayInfoApi(data) {
  return _request.default.get("/pay/sys/withdrawal/config/info ", data);
}
//修改提现设置

function updateInfoApi(data) {
  return _request.default.put("/pay/sys/withdrawal/config/update", data);
}

// 获取商户主体
function getComponseListApi(data) {
  return _request.default.get("/open3d/system/company/list", data);
}

// 开票记录列表
function getInvoiceListApi(data) {
  return _request.default.get("/open3d/member/sys/member/invoice/list", data);
}
/**
 * @description 确认开票
 */
function invoiceAccept(id, data) {
  return _request.default.post("/open3d/member/sys/member/invoice/accept/".concat(id), data);
}

// 合同申请记录列表
function getContractListApi(data) {
  return _request.default.get("/open3d/member/sys/member/contract/list", data);
}

// 发货
function contractSendApi(data) {
  return _request.default.post("/open3d/member/sys/member/contract/send", data);
}

// 快递公司
function getDeliveryListApi(data) {
  return _request.default.get("/open3d/system/delivery/company/list", data);
}

// 
function resourceUpdApi(data) {
  return _request.default.post("/open3d/resource/oss/upload", data);
}
// 发送合同
function sendContactNew(data) {
  return _request.default.post("/open3d/order/sys/order/updateShip", data);
}