var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        {},
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: { model: _vm.ruleForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则名称：" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    model: {
                      value: _vm.ruleForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "title", $$v)
                      },
                      expression: "ruleForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("Tinymce", {
                ref: "editor",
                attrs: { height: 400 },
                model: {
                  value: _vm.ruleForm.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "message", $$v)
                  },
                  expression: "ruleForm.message",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "common-btns" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAction("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }