var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("p", [_vm._v("群聊管理")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb15" },
        [
          _c(
            "el-input",
            {
              staticClass: "input-with-select",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "搜索群聊" },
              model: {
                value: _vm.searchGroup,
                callback: function ($$v) {
                  _vm.searchGroup = $$v
                },
                expression: "searchGroup",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.getList(1)
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "50px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.groupVisible = true
                },
              },
            },
            [_vm._v("创建群聊")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData.data, stripe: "" },
        },
        _vm._l(_vm.columnData, function (item) {
          return _c("el-table-column", {
            key: item.userId,
            attrs: { prop: item.prop, label: item.label, width: item.width },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      item.prop === "image"
                        ? [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ]
                        : item.prop === "gorupMerNum"
                        ? [
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getManageList(
                                      1,
                                      scope.row.groupId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.gorupMerNum) + "人")]
                            ),
                          ]
                        : item.prop === "applyForGroupNum"
                        ? [
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getApplyList(
                                      1,
                                      scope.row.groupId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      scope.row.applyForGroupNum
                                        ? scope.row.applyForGroupNum
                                        : 0
                                    ) +
                                    "人\n          "
                                ),
                              ]
                            ),
                          ]
                        : item.prop === "nickName"
                        ? [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.nickName) +
                                  "(" +
                                  _vm._s(scope.row.phone) +
                                  ")"
                              ),
                            ]),
                          ]
                        : item.prop === "isCheck"
                        ? [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.isCheck ? "是" : "否")),
                            ]),
                          ]
                        : item.prop === "addCanFriend"
                        ? [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.addCanFriend ? "是" : "否")
                              ),
                            ]),
                          ]
                        : item.prop === "inviteFriend"
                        ? [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.inviteFriend ? "是" : "否")
                              ),
                            ]),
                          ]
                        : item.prop === "handle"
                        ? [
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getMemberList(
                                      1,
                                      scope.row.groupId
                                    )
                                  },
                                },
                              },
                              [_vm._v("成员管理")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getAdminList(
                                      1,
                                      scope.row.groupId
                                    )
                                  },
                                },
                              },
                              [_vm._v("管理员")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editGroup(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定解散吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.dissolution(scope.row.groupId)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      underline: false,
                                      type: "primary",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("解散")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row[item.prop]) +
                                  "\n          "
                              ),
                            ]),
                          ],
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              "page-size": _vm.queryParams.pageSize,
              "current-page": _vm.queryParams.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: !_vm.isEdit ? "创建群聊" : "编辑群聊",
            visible: _vm.groupVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.groupVisible = $event
            },
            close: function ($event) {
              return _vm.closeGroup("ruleForm")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "群名称：", prop: "groupName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "groupName", $$v)
                      },
                      expression: "ruleForm.groupName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "群封面：", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1")
                        },
                      },
                    },
                    [
                      _vm.ruleForm.image
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", { attrs: { src: _vm.ruleForm.image } }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "群主：", prop: "phone" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "搜索客户昵称/手机号/真实姓名",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.userLoading,
                      },
                      model: {
                        value: _vm.ruleForm.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "phone", $$v)
                        },
                        expression: "ruleForm.phone",
                      },
                    },
                    _vm._l(_vm.userList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.userId,
                          attrs: {
                            value: item.userPhone,
                            label: item.nickName,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", {
                                attrs: { src: item.avatar, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "user-r flex-one" }, [
                                _c("div", { staticClass: "line1" }, [
                                  _vm._v(_vm._s(item.nickName)),
                                ]),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(item.userPhone))]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "进群需审核：", required: "" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.ruleForm.isCheck,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isCheck", $$v)
                      },
                      expression: "ruleForm.isCheck",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "群成员加好友：", required: "" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.ruleForm.addCanFriend,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "addCanFriend", $$v)
                      },
                      expression: "ruleForm.addCanFriend",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邀请新用户进群：", required: "" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.ruleForm.inviteFriend,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "inviteFriend", $$v)
                      },
                      expression: "ruleForm.inviteFriend",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "群成员管理",
            visible: _vm.manageVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.manageVisible = $event
            },
            close: function ($event) {
              return _vm.closeDialog("manageVisible")
            },
          },
        },
        [
          _c(
            "el-input",
            {
              staticClass: "input-with-select",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "用户名/手机号" },
              model: {
                value: _vm.managePhone,
                callback: function ($$v) {
                  _vm.managePhone = $$v
                },
                expression: "managePhone",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.getManageList(1)
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.manageLoading,
                  expression: "manageLoading",
                },
              ],
              attrs: { data: _vm.manageData.data, height: "400", stripe: "" },
              on: { "selection-change": _vm.handleSelectManageData },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _vm._l(_vm.manageColumn, function (item) {
                return _c("el-table-column", {
                  key: item.userId,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "image"
                              ? [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ]
                              : item.prop === "handle"
                              ? [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        underline: false,
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeUserGroup(
                                            scope.row.gorupMerId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("移出群聊")]
                                  ),
                                ]
                              : [
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row[item.prop]) +
                                        "\n            "
                                    ),
                                  ]),
                                ],
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-b" },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "25px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: !_vm.multipleManage.length },
                      on: { click: _vm.bathRemoveManage },
                    },
                    [_vm._v("批量移出")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.managePaging.pageSize,
                  "current-page": _vm.managePaging.pageNum,
                  total: _vm.manageData.total,
                },
                on: {
                  "size-change": _vm.handleManageSizeChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.managePaging, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.managePaging, "pageNum", $event)
                  },
                  "current-change": _vm.getManageList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "成员管理",
            "close-on-click-modal": false,
            visible: _vm.memberVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.memberVisible = $event
            },
            close: function ($event) {
              return _vm.closeDialog("memberVisible")
            },
          },
        },
        [
          _c(
            "el-input",
            {
              staticClass: "input-with-select",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "用户名/手机号" },
              model: {
                value: _vm.memberSearch.phoneOrName,
                callback: function ($$v) {
                  _vm.$set(_vm.memberSearch, "phoneOrName", $$v)
                },
                expression: "memberSearch.phoneOrName",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.getMemberList(1)
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "input-with-select",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "藏品名称" },
              model: {
                value: _vm.memberSearch.nftName,
                callback: function ($$v) {
                  _vm.$set(_vm.memberSearch, "nftName", $$v)
                },
                expression: "memberSearch.nftName",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.getMemberList(1)
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.memberLoading,
                  expression: "memberLoading",
                },
              ],
              attrs: { data: _vm.memberData.data, height: "400", stripe: "" },
              on: { "selection-change": _vm.handleSelectMemberData },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _vm._l(_vm.memberColumn, function (item) {
                return _c("el-table-column", {
                  key: item.userId,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "avatar"
                              ? [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      src: scope.row.avatar,
                                      "preview-src-list": [scope.row.avatar],
                                    },
                                  }),
                                ]
                              : item.prop === "nickName"
                              ? [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.nickName) +
                                        "(" +
                                        _vm._s(scope.row.phone) +
                                        ")"
                                    ),
                                  ]),
                                ]
                              : [
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row[item.prop]) +
                                        "\n            "
                                    ),
                                  ]),
                                ],
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-b" },
            [
              _c(
                "div",
                { staticClass: "flex", staticStyle: { "margin-top": "25px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: !_vm.multipleMember.length },
                      on: { click: _vm.bathAddMember },
                    },
                    [_vm._v("添加入群")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.multipleMember.length,
                        type: "primary",
                      },
                      on: { click: _vm.addAdmin },
                    },
                    [_vm._v("添加为群管理")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.memberPaging.pageSize,
                  "current-page": _vm.memberPaging.pageNum,
                  total: _vm.memberData.total,
                },
                on: {
                  "size-change": _vm.handleMemberSizeChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.memberPaging, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.memberPaging, "pageNum", $event)
                  },
                  "current-change": _vm.getMemberList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "入群审核",
            visible: _vm.applicationDialog,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.applicationDialog = $event
            },
            close: function ($event) {
              return _vm.closeDialog("applicationDialog")
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.applicationData.data,
                stripe: "",
                height: "400",
              },
              on: { "selection-change": _vm.handleSelectApplication },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _vm._l(_vm.applicationColumn, function (item) {
                return _c("el-table-column", {
                  key: item.userId,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "image"
                              ? [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ]
                              : item.prop === "handle"
                              ? [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        underline: false,
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleApply(
                                            1,
                                            scope.row.gorupMerId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("通过")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        underline: false,
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleApply(
                                            2,
                                            scope.row.gorupMerId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("拒绝")]
                                  ),
                                ]
                              : [
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row[item.prop]) +
                                        "\n            "
                                    ),
                                  ]),
                                ],
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-b" },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "25px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: !_vm.multipleApplication.length },
                      on: {
                        click: function ($event) {
                          return _vm.batchApply(1)
                        },
                      },
                    },
                    [_vm._v("批量通过")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: !_vm.multipleApplication.length },
                      on: {
                        click: function ($event) {
                          return _vm.batchApply(2)
                        },
                      },
                    },
                    [_vm._v("批量拒绝")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.applicationPaging.pageSize,
                  "current-page": _vm.applicationPaging.pageNum,
                  total: _vm.applicationData.total,
                },
                on: {
                  "size-change": _vm.handleApplicationSizeChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.applicationPaging, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.applicationPaging, "pageNum", $event)
                  },
                  "current-change": _vm.getApplyList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "管理员列表",
            visible: _vm.adminDialog,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.adminDialog = $event
            },
            close: function ($event) {
              return _vm.closeDialog("adminDialog")
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.adminLoading,
                  expression: "adminLoading",
                },
              ],
              attrs: { data: _vm.adminData.data, stripe: "" },
            },
            _vm._l(_vm.adminColumn, function (item) {
              return _c("el-table-column", {
                key: item.userId,
                attrs: {
                  prop: item.prop,
                  height: "400",
                  label: item.label,
                  width: item.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.prop === "image"
                            ? [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: {
                                    src: scope.row.image,
                                    "preview-src-list": [scope.row.image],
                                  },
                                }),
                              ]
                            : item.prop === "handle"
                            ? [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeAdmin(
                                          scope.row.gorupMerId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("取消群管理")]
                                ),
                              ]
                            : [
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row[item.prop]) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-b" },
            [
              _c("div"),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.adminPaging.pageSize,
                  "current-page": _vm.adminPaging.pageNum,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.adminData.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.adminPaging, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.adminPaging, "pageNum", $event)
                  },
                  "current-change": _vm.getAdminList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }