"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _demand = require("@/api/demand");
var _editDemand = _interopRequireDefault(require("./modules/editDemand.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["tableData"],
  components: {
    EditDemand: _editDemand.default
  },
  data: function data() {
    return {
      editDemandVisib: false
    };
  },
  methods: {
    editDemand: function editDemand(row) {
      console.log(row.id);
      this.editDemandVisib = true;
    },
    cancelDemand: function cancelDemand(row) {
      var _this = this;
      this.$emit('changeLoading', true);
      (0, _demand._cancelDemand)(row.id).then(function (res) {
        _this.$message({
          message: "取消成功",
          type: "success"
        });
        _this.$emit("getDemandList", 1);
      }).catch(function (err) {
        console.log(err);
        _this.$emit('changeLoading', false);
      });
    },
    onAudited: function onAudited(row) {
      var _this2 = this;
      this.$emit('changeLoading', true);
      (0, _demand._onAudited)({
        id: row.id,
        status: 1
      }).then(function (res) {
        _this2.$message({
          message: "审核成功",
          type: "success"
        });
        _this2.$emit("getDemandList", 1);
      }).catch(function (err) {
        console.log(err);
        _this2.$emit('changeLoading', false);
      });
    }
  }
};