"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setting = require("@/api/setting");
var _map = _interopRequireDefault(require("@/components/map/map.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Information',
  components: {
    Maps: _map.default
  },
  data: function data() {
    return {
      merData: {},
      // 默认数据
      mapKey: '',
      // 地图key
      address: '',
      submitLoading: false,
      // 提交loading
      rules: {
        logo: [{
          required: true,
          message: '请上传LOGO'
        }],
        image: [{
          required: true,
          message: '请上传店铺头像'
        }],
        info: [{
          required: true,
          message: '请输入商户简介',
          trigger: 'blur'
        }, {
          min: 3,
          max: 200,
          message: '长度在 3 到 200 个字符',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入企业名称',
          trigger: 'blur'
        }],
        englishName: [{
          required: true,
          message: '请输入企业英文名称',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getMapInfo();
  },
  methods: {
    // 地图信息获取
    getCoordinates: function getCoordinates(data) {
      // this.merData.address = data.address;
      this.merData.dimension = data.location.lat || 34.34127;
      this.merData.longitude = data.location.lng || 108.93984;
      console.log(data);
    },
    // 提交/修改
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.submitLoading = true;
          (0, _setting.merchantUpdate)(_this2.merData).then(function (res) {
            console.log(res);
            _this2.submitLoading = false;
            _this2.$message.success('提交成功');
          }).catch(function (rej) {});
        } else {
          _this2.$message('请完善信息后再进行提交');
          _this2.submitLoading = false;
          return false;
        }
      });
    },
    // 查找位置
    onSearch: function onSearch() {
      // console.log(this.$refs)
      this.$refs.mapChild.searchKeyword(this.merData.address);
    },
    // 获取 map key
    getMapInfo: function getMapInfo() {
      var _this3 = this;
      (0, _setting.storeGetInfoApi)().then(function (res) {
        _this3.merData = res.data;
        (0, _setting.storeGetInfoMapKeyApi)().then(function (res) {
          _this3.mapKey = res.data.mapKey;
        });
      }).catch(function (res) {});
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this4 = this;
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(img);
        if (tit === '1' && !num) {
          _this.merData.logo = img[0];
        }
        if (tit === '2' && !num) {
          _this.merData.image = img[0];
        }
        if (tit === '4' && !num) {
          _this.merData.icon = img[0];
        }
        _this4.$forceUpdate();
        console.log(_this4.merData);
      }, tit);
    }
  }
};