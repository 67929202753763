import { render, staticRenderFns } from "./widget.vue?vue&type=template&id=88c93fe2&scoped=true"
import script from "./widget.vue?vue&type=script&lang=js"
export * from "./widget.vue?vue&type=script&lang=js"
import style0 from "./widget.vue?vue&type=style&index=0&id=88c93fe2&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88c93fe2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/java_admin_Rm9R/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('88c93fe2')) {
      api.createRecord('88c93fe2', component.options)
    } else {
      api.reload('88c93fe2', component.options)
    }
    module.hot.accept("./widget.vue?vue&type=template&id=88c93fe2&scoped=true", function () {
      api.rerender('88c93fe2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/decoration/modules/blindOrderList/widget.vue"
export default component.exports