"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _animal = require("@/api/animal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "List",
  data: function data() {
    return {
      listLoading: false,
      landList: {
        data: [],
        total: 0
      },
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      columnData: [{
        label: "ID",
        prop: "id"
      }, {
        label: "土地名称",
        prop: "productName"
      }, {
        label: "用户持有",
        prop: "userCount"
      }, {
        label: "初始肥沃度",
        prop: "initEnergy"
      }, {
        label: "最大肥沃度",
        prop: "maxEnergy"
      }, {
        label: "肥沃度消耗",
        prop: "maxEnergy"
      }, {
        label: "自然成长值",
        prop: "natureGrow"
      }, {
        label: "每天可浇水",
        prop: "waterNum"
      }, {
        label: "浇水补充",
        prop: "growNum"
      }, {
        label: "操作",
        prop: "handle"
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    createLand: function createLand() {
      this.$router.push("landDetail");
    },
    editAnimal: function editAnimal(id) {
      this.$router.push({
        path: "landDetail",
        query: {
          id: id
        }
      });
    },
    getList: function getList() {
      var _this = this;
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      this.listLoading = true;
      this.pageConfig.pageNum = val;
      (0, _animal.getGroundListApi)(this.pageConfig).then(function (res) {
        console.log(res);
        _this.listLoading = false;
        _this.landList.data = res.rows;
        _this.landList.total = res.total;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.getList();
    }
  }
};