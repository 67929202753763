"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var echarts = _interopRequireWildcard(require("echarts/core"));
var _index = _interopRequireDefault(require("../mixin/index"));
var _order = require("@/api/order");
//
//
//
//

// import echarts from "echarts";

// import { PieChart } from "echarts/charts";
// import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
// echarts.use([PieChart, CanvasRenderer]);
require("echarts/theme/macarons"); // echarts theme
var _default = exports.default = {
  mixins: [_index.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    amount: {
      type: Boolean,
      default: true
    },
    date: {
      type: String,
      required: false,
      default: "lately7"
    }
  },
  data: function data() {
    return {
      chart: null,
      newData: "",
      oldData: "",
      Comment: []
    };
  },
  watch: {
    amount: {
      deep: true,
      handler: function handler(val) {
        this.amount = val;
        this.getTurnoverRatio();
      }
    },
    date: {
      deep: true,
      handler: function handler(val) {
        this.date = val;
        this.getTurnoverRatio();
      }
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {});
    // this.getTurnoverRatio();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    getTurnoverRatio: function getTurnoverRatio() {
      var that = this;
      (0, _order.reportChannel)({}).then(function (res) {
        if (res.code === 200) {
          var dataX = res.data.map(function (item, index) {
            if (!item.scource && item.scource !== 0) {
              item["name"] = '其他';
              item["value"] = item.count;
            } else if (item.scource === 0) {
              item["name"] = 'H5';
              item["value"] = item.count;
            } else if (item.scource == 1) {
              item["name"] = 'APP';
              item["value"] = item.count;
            } else if (item.scource == 3) {
              item["name"] = '后台手动创建';
              item["value"] = item.count;
            }
            return item.name;
          });
          that.chart = echarts.init(that.$el, "macarons");
          that.chart.setOption({
            colors: ['#39a1ff', '#4ecb73', '#657798'],
            title: {
              text: "",
              left: "center",
              textStyle: {
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "bold",
                color: "#262626"
              }
            },
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
              left: "right",
              orient: "vertical",
              data: dataX
            },
            series: [{
              name: "渠道",
              type: "pie",
              // roseType: "radius",
              radius: ["40%", "70%"],
              center: ["50%", "50%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center"
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "20",
                  fontWeight: "bold"
                }
              },
              labelLine: {
                show: false
              },
              data: res.data,
              animationEasing: "cubicInOut",
              animationDuration: 2600
            }]
          });
        }
      }).catch(function (res) {
        that.$message.error(res.message);
      });
    }
  }
};