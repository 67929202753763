"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.find");
var _merchant = require("@/api/merchant");
var _saasApi = require("@/api/saasApi");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formState: {
        productType: 6,
        type: 3,
        memberId: "",
        associationId: "",
        temporaryId: "",
        expandInformation: {
          productPackage: {
            productPackageId: "",
            dateNumber: "",
            dateUnit: ""
          },
          selectMatchProductData: [{
            productId: "",
            price: ""
          }],
          customizationProductData: [{
            description: "",
            price: ""
          }],
          discountPrice: {
            remark: "",
            price: ""
          },
          serviceFee: {
            startTime: Date.now(),
            serviceTime: "",
            serviceTimeUnit: "year",
            price: ""
          }
        }
      },
      versionList: [],
      nameOrPhone: "",
      restaurants: [],
      selectSaasProductsList: [],
      productTypeList: [],
      timeList: [],
      rules: {
        memberId: [{
          required: true,
          message: "请选择客户",
          trggern: "change"
        }],
        associationId: [{
          required: true,
          type: "number",
          message: "请选择产品类型和版本",
          trggern: "change"
        }]
      }
    };
  },
  computed: {
    productTypePrice: {
      get: function get() {
        var _this = this;
        var obj = this.timeList.find(function (item) {
          return item.number == _this.formState.expandInformation.productPackage.dateNumber;
        }) || {};
        this.formState.expandInformation.productPackage.dateUnit = obj.dateType || "";
        return obj.price ? +obj.price : 0;
      }
    },
    totalPrice: {
      get: function get() {
        var expandInformation = this.formState.expandInformation;
        var productPrice = expandInformation.selectMatchProductData.reduce(function (cur, item) {
          return cur + +item.price;
        }, 0);
        var customPrice = expandInformation.customizationProductData.reduce(function (cur, item) {
          return cur + +item.price;
        }, 0);
        var discountPrice = +expandInformation.discountPrice.price;
        return this.productTypePrice + productPrice + customPrice + +this.systemPrice - discountPrice;
      }
    },
    servePrice: {
      get: function get() {
        var expandInformation = this.formState.expandInformation;
        return "".concat(expandInformation.serviceFee.price, "\u5143/").concat(expandInformation.serviceFee.serviceTime, "\u5E74");
      }
    },
    systemPrice: {
      get: function get() {
        var associationId = this.formState.associationId;
        var curTypeObj = this.productTypeList.find(function (item) {
          return item.productPackageId == associationId;
        });
        return curTypeObj ? Number(curTypeObj.systemPrice) : 0;
      }
    }
  },
  created: function created() {
    var _this2 = this;
    (0, _merchant.getMerchantList)({
      hasVerified: 2,
      userStatus: 2
    }).then(function (res) {
      _this2.restaurants = res.rows.map(function (item) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
          value: item.name
        });
      });
    }).catch(function (err) {
      console.log(err);
    });
    (0, _saasApi.productPackage)({}).then(function (res) {
      _this2.productTypeList = res.rows;
    }).catch(function (err) {
      console.log(err);
    });
  },
  mounted: function mounted() {},
  watch: {
    "formState.expandInformation.selectMatchProductData": {
      handler: function handler(nval) {
        var _this3 = this;
        this.selectSaasProductsList.forEach(function (item) {
          var obj = nval.find(function (i) {
            return i.productId == item.productId;
          });
          if (obj) item.isShow = false;else item.isShow = true;
        });
        nval.forEach(function (item) {
          if (item.productId) {
            var obj = _this3.selectSaasProductsList.find(function (i) {
              return i.productId == item.productId;
            });
            if (obj) {
              item.price = obj.price;
            }
          }
        });
      },
      deep: true
    },
    "formState.temporaryId": {
      handler: function handler(nval) {
        var _this4 = this;
        this.formState.associationId = "";
        this.formState.expandInformation.productPackage.dateNumber = "";
        var obj = this.productTypeList.find(function (item) {
          return item.productPackageId == nval;
        }) || {};
        // 版本列表
        (0, _saasApi.productPackage)({
          packageName: obj.packageName
        }).then(function (res) {
          _this4.versionList = res.rows;
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    "formState.associationId": {
      handler: function handler(nval) {
        var _this5 = this;
        if (!nval) return;
        this.formState.expandInformation.productPackage.dateNumber = "";
        var obj = this.productTypeList.find(function (item) {
          return item.productPackageId == nval;
        }) || {};
        this.timeList = obj.packagePrice || [];
        this.formState.expandInformation.productPackage.dateNumber = obj.packagePrice[0].number || "";

        // 功能列表
        this.formState.expandInformation.selectMatchProductData = [{
          productId: "",
          price: ""
        }];
        (0, _saasApi.saasProductList)({
          systemType: obj.systemType
        }).then(function (res) {
          res.rows.forEach(function (item) {
            return _this5.$set(item, "isShow", true);
          });
          _this5.selectSaasProductsList = JSON.parse(JSON.stringify(res.rows));
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  },
  methods: {
    addFeature: function addFeature() {
      var emptyObj = this.formState.expandInformation.customizationProductData.find(function (item) {
        return !item.description || !item.price;
      });
      if (emptyObj) {
        this.$message({
          message: "请输入完整",
          type: "warning"
        });
        return;
      }
      this.formState.expandInformation.customizationProductData.push({
        description: "",
        price: ""
      });
    },
    addProduct: function addProduct() {
      var len = this.formState.expandInformation.selectMatchProductData.length;
      if (!this.formState.expandInformation.selectMatchProductData[len - 1].productId) return;
      this.formState.expandInformation.selectMatchProductData.push({
        productId: "",
        price: ""
      });
    },
    deleteProduct: function deleteProduct(item) {
      if (this.formState.expandInformation.selectMatchProductData.length <= 1) {
        this.formState.expandInformation.selectMatchProductData = [{
          productId: "",
          price: ""
        }];
        return;
      }
      this.formState.expandInformation.selectMatchProductData = this.formState.expandInformation.selectMatchProductData.filter(function (itm) {
        return itm.productId != item.productId;
      });
    },
    onSubmit: function onSubmit() {
      var _this6 = this;
      this.$refs.formRef.validate(function (valid) {
        if (valid) {
          /* if (!this.formState.expandInformation.serviceFee.price)
            return this.$message({
              message: "请输入服务价格",
              type: "warning"
            }); */
          _this6.formState.expandInformation.productPackage.productPackageId = _this6.formState.associationId;
          (0, _saasApi._createSaasOrder)(_this6.formState).then(function (res) {
            _this6.$emit("changeDrawerVisib", false);
            _this6.$message({
              message: "创建成功",
              type: "success"
            });
          }).catch(function (err) {
            console.log(err);
          });
        }
      });
    },
    querySearch: function querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString + "")) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter: function createFilter(queryString) {
      return function (item) {
        var bool = (item.phone + "").includes(queryString) || (item.name + "").includes(queryString);
        return bool;
      };
    },
    handleSelect: function handleSelect(item) {
      this.formState.memberId = item.id;
    }
  }
};