"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setting = require("@/api/setting");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        pwd1: '',
        pwd2: '',
        phone: '',
        verificationCode: '' // 验证码
      },
      text: '获取验证码',
      codeNum: 60,
      rules: {
        pwd1: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        pwd2: [{
          required: true,
          message: '请确认密码',
          trigger: 'blur'
        }]
        // verificationCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      disabled: false
    };
  },
  mounted: function mounted() {
    this.storeGetInfo();
  },
  methods: {
    storeGetInfo: function storeGetInfo() {
      var _this = this;
      (0, _setting.storeGetInfoApi)().then(function (res) {
        console.log(res, 34);
        _this.form.phone = res.data.principalPhone;
      });
    },
    getCode: function getCode() {
      var _this2 = this;
      this.disabled = true;
      this.text = this.codeNum;
      this.getCodeNum();
      var timer = setInterval(function () {
        _this2.text--;
        if (_this2.text <= 0) {
          clearInterval(timer);
          _this2.disabled = false;
          _this2.text = '获取验证码';
          _this2.codeNum = 60;
        }
      }, 1000);
    },
    getCodeNum: function getCodeNum() {
      var _this3 = this;
      (0, _system.getCodesNew)({
        phonenumber: this.form.phone
      }).then(function (res) {
        _this3.$message({
          type: 'success',
          message: '获取成功'
        });
      });
    },
    submit: function submit(formName) {
      var _this4 = this;
      if (this.form.pwd1 !== this.form.pwd2) {
        this.$message({
          type: 'warning',
          message: '两次输入密码不一致'
        });
        return;
      }
      if (!this.form.verificationCode) {
        this.$message({
          type: 'warning',
          message: '请输入验证码'
        });
        return;
      }
      this.$refs[formName].validate(function (valid) {
        _this4.form.pwd = _this4.form.pwd1;
        if (valid) {
          (0, _system.editPwd)(_this4.form).then(function (res) {
            _this4.$message({
              type: 'success',
              message: '操作成功'
            });
          });
        }
      });
    }
  }
};