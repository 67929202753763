var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "审批状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("待审批"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("已审批"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [_vm._v("已作废")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.warehouse_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "warehouse_id", $$v)
                                },
                                expression: "tableFrom.warehouse_id",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.storeList, function (item) {
                                return _c("el-radio-button", {
                                  key: item.warehouse_id,
                                  attrs: {
                                    label: item.name,
                                    value: item.warehouse_id,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          staticStyle: { display: "block" },
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(_vm.tableFrom.date)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "date", $$v)
                                },
                                expression: "tableFrom.date",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd",
                              format: "yyyy/MM/dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "审批人：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.tableFrom.product_keyword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tableFrom,
                                    "product_keyword",
                                    $$v
                                  )
                                },
                                expression: "tableFrom.product_keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: { path: _vm.roterPre + "/warehouse/transferscreate" },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("添加调拨单")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "productCount", label: "货品数量" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "调出仓库",
                  "min-width": "120",
                  prop: "outWarehouse.name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "120", label: "出库状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex-box-name" }, [
                          scope.row.out_status == 0
                            ? _c("span", [_vm._v("待出库")])
                            : scope.row.out_status == 1
                            ? _c("span", [_vm._v("已出库")])
                            : scope.row.out_status == -1
                            ? _c("span", [_vm._v("已作废")])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "outAdmin.real_name", label: "出库人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "pushWarehouse.name",
                  label: "调入仓库",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "入库状态", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.push_status == 0
                          ? _c("div", [
                              _vm._v("\n              待入库\n            "),
                            ])
                          : scope.row.push_status == 1
                          ? _c("div", [
                              _vm._v("\n              已入库\n            "),
                            ])
                          : scope.row.push_status == -1
                          ? _c("div", [
                              _vm._v("\n              已作废\n            "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "pushAdmin.real_name", label: "入库人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "单据时间",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  label: "单据编号",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "creator.real_name",
                  label: "制单人",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "80",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.getdetail(scope.row.order_id)
                              },
                            },
                          },
                          [_vm._v("\n            调拨清单\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
            close: function ($event) {},
          },
        },
        [
          _c("el-input", {
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.mask,
              callback: function ($$v) {
                _vm.mask = $$v
              },
              expression: "mask",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(-1)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(1)
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showdetail, width: "800px" },
          on: {
            "update:visible": function ($event) {
              _vm.showdetail = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "detail-box",
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c("div", { staticClass: "detail-left" }, [_vm._v("调拨清单")]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-right" }, [
                _c("img", {
                  attrs: {
                    src:
                      "data:image/png;base64," + _vm.detailjson.bar_code_image,
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "detail-code" }, [
                  _vm._v(_vm._s(_vm.detailjson.bar_code)),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "detail-list" }, [
            _vm.detailjson.outWarehouse
              ? _c("div", [
                  _vm._v(
                    "出库仓库：" + _vm._s(_vm.detailjson.outWarehouse.name)
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [_vm._v("时间：" + _vm._s(_vm.detailjson.create_time))]),
            _vm._v(" "),
            _c("div", [_vm._v("单据编号：" + _vm._s(_vm.detailjson.order_no))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "detail-list" }, [
            _vm.detailjson.pushWarehouse
              ? _c("div", [
                  _vm._v(
                    "入库仓库：" + _vm._s(_vm.detailjson.pushWarehouse.name)
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.detailjson.orderProduct
            ? _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "15px" },
                  attrs: { size: "mini", data: _vm.detailjson.orderProduct },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: scope.row.productItem.image,
                                      "preview-src-list": [
                                        scope.row.productItem.image,
                                      ],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3181304614
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "拍品名称",
                      prop: "productItem.store_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作者", prop: "productItem.brand" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.productItem.brand
                                ? _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          scope.row.productItem.brand.brand_name
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2235058083
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分类" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.productItem.cate_parent &&
                              scope.row.productItem.cate_parent[0]
                                ? _c("div", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          scope.row.productItem.cate_parent[0]
                                            .cate_name
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1214839979
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", { attrs: { label: "标签" } }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "货架" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.shelf
                                ? _c("div", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(scope.row.shelf.shelf_no) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3504163178
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "属性" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                _vm._l(
                                  scope.row.productItem.attrInfo,
                                  function (m, i) {
                                    return _c("span", { key: "oattr_" + i }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(m.attr_name) +
                                          ":" +
                                          _vm._s(m.attr_value_name) +
                                          "\n                  "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1395830656
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "detail-list",
              staticStyle: { "margin-top": "20px" },
            },
            [
              _vm.detailjson.creator
                ? _c("div", [
                    _vm._v(
                      "制单人：" + _vm._s(_vm.detailjson.creator.real_name)
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.detailjson.manageAdmin
                ? _c("div", [
                    _vm._v(
                      "业务负责人：" +
                        _vm._s(_vm.detailjson.manageAdmin.real_name)
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }