var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.radio1,
                  callback: function ($$v) {
                    _vm.radio1 = $$v
                  },
                  expression: "radio1",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "空投列表", name: "0" } }),
                _vm._v(" "),
                _c("el-tab-pane", { attrs: { label: "空投设置", name: "1" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.radio1 == "0"
          ? _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.onAdd },
                  },
                  [_vm._v("创建空投任务")]
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData.data, size: "small" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "id", label: "ID", "min-width": "100" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "投放类型", "min-width": "100" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.type == 1
                                  ? _c("div", [_vm._v("快照")])
                                  : scope.row.type == 2
                                  ? _c("div", [_vm._v("精准")])
                                  : scope.row.type == 3
                                  ? _c("div", [_vm._v("自定义")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2028220294
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "空投名称", "min-width": "100" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v(_vm._s(scope.row.name))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1281056470
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "投放名单", "min-width": "100" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v(_vm._s(scope.row.roster))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2314743868
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "投放内容", "min-width": "160" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return scope.row.airdropContents
                                ? _vm._l(
                                    scope.row.airdropContents,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        _vm._v(
                                          "\n              【" +
                                            _vm._s(
                                              _vm._f("formatType")(item.type)
                                            ) +
                                            "】 " +
                                            _vm._s(item.productName) +
                                            " *\n              " +
                                            _vm._s(item.airdropNum) +
                                            "\n            "
                                        ),
                                      ])
                                    }
                                  )
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "投放人数/已领人数", "min-width": "140" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showuser(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#1890ff" } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(scope.row.allPeople)
                                        ),
                                      ]
                                    ),
                                    _vm._v("/\n              "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#1890ff" } },
                                      [_vm._v(_vm._s(scope.row.alreadyPeople))]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3505899232
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "作品/已领", "min-width": "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.nftNum) +
                                      "/" +
                                      _vm._s(scope.row.nftGetNum)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3651226853
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "盲盒/已领", "min-width": "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.boxNum) +
                                      "/" +
                                      _vm._s(scope.row.boxGetNum)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2493055045
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "积分/已领", "min-width": "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#1890ff" } },
                                    [_vm._v(_vm._s(scope.row.integralNum))]
                                  ),
                                  _vm._v("/\n              "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#1890ff" } },
                                    [_vm._v(_vm._s(scope.row.integralGetNum))]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3628615319
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "优惠券/已领", "min-width": "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.couponNum) +
                                      "/" +
                                      _vm._s(scope.row.couponGetNum)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        750723173
                      ),
                    }),
                    _vm._v(" "),
                    _vm.isShowAddInner
                      ? _c("el-table-column", {
                          attrs: { label: "内丹/已领", "min-width": "120" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.innerNum) +
                                          "/" +
                                          _vm._s(scope.row.innerGetNum)
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            719354277
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "备注", "min-width": "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.remarks) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        368358656
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "状态", "min-width": "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("formatAirdropStatus")(
                                          scope.row.airdropStatus
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2917452971
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "投放时间", "min-width": "100" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.time) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3833116708
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "100",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return scope.row.airdropStatus == 0
                                ? [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(
                                              scope.row.id,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [20, 40, 60, 80],
                        "page-size": _vm.tableFrom.pageSize,
                        "current-page": _vm.tableFrom.pageNum,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.tableData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.pageChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.radio1 == "1"
          ? _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "formValidate",
                    staticClass: "formValidate mt20",
                    attrs: { "label-width": "120px" },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "空投自动到账：" } },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            disabled: _vm.noEdit,
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: _vm.setTimeObj.isAutoGet,
                            callback: function ($$v) {
                              _vm.$set(_vm.setTimeObj, "isAutoGet", $$v)
                            },
                            expression: "setTimeObj.isAutoGet",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10 ft12 color-gray" }, [
                          _vm._v(
                            "开启后忽略“空投回收时间”。修改该设置之前，已创建的空投任务，按修改前的设置"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.setTimeObj.isAutoGet
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "空投收回时间：", prop: "name" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.setTimeObj.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setTimeObj, "type", $$v)
                                  },
                                  expression: "setTimeObj.type",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("无过期时间"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("设置时间"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.setTimeObj.isAutoGet && _vm.setTimeObj.type == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: " " } },
                          [
                            _c("span", [_vm._v("空投后")]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "w100 ml10",
                              attrs: { type: "number" },
                              model: {
                                value: _vm.setTimeObj.afterTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.setTimeObj, "afterTime", $$v)
                                },
                                expression: "setTimeObj.afterTime",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v("小时未领取自动收回"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: " " } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.updateAirdropSets },
                          },
                          [_vm._v("确定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "领取详情", visible: _vm.userflag, width: "700px" },
          on: {
            "update:visible": function ($event) {
              _vm.userflag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: "", size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "手机号：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入手机号", size: "small" },
                      model: {
                        value: _vm.newtableFrom.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.newtableFrom, "phone", $$v)
                        },
                        expression: "newtableFrom.phone",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getuserList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.exportRecord()
                    },
                  },
                },
                [_vm._v("导出\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.getExportFileList(8)
                    },
                  },
                },
                [_vm._v("导出已生成列表")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.newlistLoading,
                      expression: "newlistLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.newtableData.data,
                    size: "small",
                    "row-key": "box_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "phone",
                      label: "手机号",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "空投作品领取", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "400",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.detailData.data },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "productName",
                                        label: "名称",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "toNum",
                                        label: "发放",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "getNum",
                                        label: "已领取",
                                        "min-width": "100",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "reference" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickDetail(scope.row, 1)
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.nftNum) +
                                        "/" +
                                        _vm._s(scope.row.nftGetNum)
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "空投盲盒领取", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "400",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.detailData.data },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "productName",
                                        label: "名称",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "toNum",
                                        label: "发放",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "getNum",
                                        label: "已领取",
                                        "min-width": "100",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "reference" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickDetail(scope.row, 2)
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.boxNum) +
                                        "/" +
                                        _vm._s(scope.row.boxGetNum)
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "空投优惠券领取", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "400",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.detailData.data },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "productName",
                                        label: "名称",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "toNum",
                                        label: "发放",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "getNum",
                                        label: "已领取",
                                        "min-width": "100",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "reference" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickDetail(scope.row, 4)
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.couponNum) +
                                        "/" +
                                        _vm._s(scope.row.couponGetNum)
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "空投积分领取", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "400",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.detailData.data },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "productName",
                                        label: "名称",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "toNum",
                                        label: "发放",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "getNum",
                                        label: "已领取",
                                        "min-width": "100",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "reference" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickDetail(scope.row, 3)
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.integralNum) +
                                        "/" +
                                        _vm._s(scope.row.integralGetNum || 0)
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.isShowAddInner
                    ? _c("el-table-column", {
                        attrs: { label: "空投内丹领取", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        width: "400",
                                        trigger: "click",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { data: _vm.detailData.data },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "productName",
                                              label: "名称",
                                              "min-width": "100",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "toNum",
                                              label: "发放",
                                              "min-width": "100",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "getNum",
                                              label: "已领取",
                                              "min-width": "100",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "reference" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickDetail(
                                                scope.row,
                                                5
                                              )
                                            },
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scope.row.innerNum) +
                                              "/" +
                                              _vm._s(scope.row.innerGetNum || 0)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3555367867
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAbleRecycle
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.reviceBtn(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("回收")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.newtableFrom.pageSize,
                  "current-page": _vm.newtableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.newtableData.total,
                },
                on: {
                  "size-change": _vm.newhandleSizeChange,
                  "current-change": _vm.newpageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "回收", visible: _vm.reviceShow },
          on: {
            "update:visible": function ($event) {
              _vm.reviceShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.radioRecive },
                  model: {
                    value: _vm.recive.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.recive, "status", $$v)
                    },
                    expression: "recive.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("回收全部剩余未领取"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("指定回收")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.recive.status == 2
            ? _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.recive.ids,
                        callback: function ($$v) {
                          _vm.$set(_vm.recive, "ids", $$v)
                        },
                        expression: "recive.ids",
                      },
                    },
                    _vm._l(_vm.reviceData, function (y) {
                      return _c(
                        "el-checkbox",
                        {
                          key: y.productId,
                          attrs: { label: y.productName },
                          on: {
                            change: function ($event) {
                              return _vm.changeCon($event, y)
                            },
                          },
                        },
                        [_vm._v("\n          " + _vm._s(y.productName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.reciveClick } },
                [_vm._v("确认回收")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }