"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.$store.dispatch("user/getMenus", 1);
  //   });
  // },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (["accountManage", "sweepGoods", "orderCollectionList"].includes(to.name)) {
      next();
    } else {
      this.$store.dispatch("user/getMenus").then(function (res) {
        next();
      });
    }
  },
  beforeMount: function beforeMount() {
    this.$store.dispatch("user/getMenus", 1);
  },
  methods: {
    goHome: function goHome() {
      this.$router.push("/merchant/user/userStatistics");
    }
  }
};