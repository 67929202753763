"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.starts-with");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _vuedraggableHelper = require("./vuedraggable-helper");
function buildAttribute(object, propName, value) {
  if (value === undefined) {
    return object;
  }
  object = object || {};
  object[propName] = value;
  return object;
}
function computeVmIndex(vnodes, element) {
  return vnodes.map(function (elt) {
    return elt.elm;
  }).indexOf(element);
}
function _computeIndexes(slots, children, isTransition, footerOffset) {
  if (!slots) {
    return [];
  }
  var elmFromNodes = slots.map(function (elt) {
    return elt.elm;
  });
  var footerIndex = children.length - footerOffset;
  var rawIndexes = (0, _toConsumableArray2.default)(children).map(function (elt, idx) {
    return idx >= footerIndex ? elmFromNodes.length : elmFromNodes.indexOf(elt);
  });
  return isTransition ? rawIndexes.filter(function (ind) {
    return ind !== -1;
  }) : rawIndexes;
}
function emit(evtName, evtData) {
  var _this = this;
  this.$nextTick(function () {
    return _this.$emit(evtName.toLowerCase(), (0, _objectSpread2.default)({}, evtData));
  });
}
function delegateAndEmit(evtName) {
  var _this2 = this;
  return function (evtData) {
    if (_this2.realList !== null) {
      _this2['onDrag' + evtName](evtData);
    }
    emit.call(_this2, evtName, evtData);
  };
}
function isTransitionName(name) {
  return ['transition-group', 'TransitionGroup'].includes(name);
}
function isTransition(slots) {
  if (!slots || slots.length !== 1) {
    return false;
  }
  var _slots = (0, _slicedToArray2.default)(slots, 1),
    componentOptions = _slots[0].componentOptions;
  if (!componentOptions) {
    return false;
  }
  return isTransitionName(componentOptions.tag);
}
function getSlot(slot, scopedSlot, key) {
  return slot[key] || (scopedSlot[key] ? scopedSlot[key]() : undefined);
}
function computeChildrenAndOffsets(children, slot, scopedSlot) {
  var headerOffset = 0;
  var footerOffset = 0;
  var header = getSlot(slot, scopedSlot, 'header');
  if (header) {
    headerOffset = header.length;
    children = children ? [].concat((0, _toConsumableArray2.default)(header), (0, _toConsumableArray2.default)(children)) : (0, _toConsumableArray2.default)(header);
  }
  var footer = getSlot(slot, scopedSlot, 'footer');
  if (footer) {
    footerOffset = footer.length;
    children = children ? [].concat((0, _toConsumableArray2.default)(children), (0, _toConsumableArray2.default)(footer)) : (0, _toConsumableArray2.default)(footer);
  }
  return {
    children: children,
    headerOffset: headerOffset,
    footerOffset: footerOffset
  };
}
function getComponentAttributes($attrs, componentData) {
  var attributes = null;
  var update = function update(name, value) {
    attributes = buildAttribute(attributes, name, value);
  };
  var attrs = Object.keys($attrs).filter(function (key) {
    return key === 'id' || key.startsWith('data-');
  }).reduce(function (res, key) {
    res[key] = $attrs[key];
    return res;
  }, {});
  update('attrs', attrs);
  if (!componentData) {
    return attributes;
  }
  var on = componentData.on,
    props = componentData.props,
    componentDataAttrs = componentData.attrs;
  update('on', on);
  update('props', props);
  Object.assign(attributes.attrs, componentDataAttrs);
  return attributes;
}
var eventsListened = ['Start', 'Add', 'Remove', 'Update', 'End'];
var eventsToEmit = ['Choose', 'Unchoose', 'Sort', 'Filter', 'Clone'];
var readonlyProperties = ['Move'].concat(eventsListened, eventsToEmit).map(function (evt) {
  return 'on' + evt;
});
var draggingElement = null;
var props = {
  options: Object,
  list: {
    type: Array,
    required: false,
    default: null
  },
  value: {
    type: Array,
    required: false,
    default: null
  },
  noTransitionOnDrag: {
    type: Boolean,
    default: false
  },
  clone: {
    type: Function,
    default: function _default(original) {
      return original;
    }
  },
  element: {
    type: String,
    default: 'div'
  },
  tag: {
    type: String,
    default: null
  },
  move: {
    type: Function,
    default: null
  },
  componentData: {
    type: Object,
    required: false,
    default: null
  }
};
var draggableComponent = {
  name: 'draggable',
  inheritAttrs: false,
  props: props,
  data: function data() {
    return {
      transitionMode: false,
      noneFunctionalComponentMode: false
    };
  },
  render: function render(h) {
    var slots = this.$slots.default;
    this.transitionMode = isTransition(slots);
    var _computeChildrenAndOf = computeChildrenAndOffsets(slots, this.$slots, this.$scopedSlots),
      children = _computeChildrenAndOf.children,
      headerOffset = _computeChildrenAndOf.headerOffset,
      footerOffset = _computeChildrenAndOf.footerOffset;
    this.headerOffset = headerOffset;
    this.footerOffset = footerOffset;
    var attributes = getComponentAttributes(this.$attrs, this.componentData);
    return h(this.getTag(), attributes, children);
  },
  created: function created() {
    if (this.list !== null && this.value !== null) {
      _vuedraggableHelper.console.error('Value and list props are mutually exclusive! Please set one or another.');
    }
    if (this.element !== 'div') {
      _vuedraggableHelper.console.warn('Element props is deprecated please use tag props instead. See https://github.com/SortableJS/Vue.Draggable/blob/master/documentation/migrate.md#element-props');
    }
    if (this.options !== undefined) {
      _vuedraggableHelper.console.warn('Options props is deprecated, add sortable options directly as vue.draggable item, or use v-bind. See https://github.com/SortableJS/Vue.Draggable/blob/master/documentation/migrate.md#options-props');
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.noneFunctionalComponentMode = this.getTag().toLowerCase() !== this.$el.nodeName.toLowerCase() && !this.getIsFunctional();
    if (this.noneFunctionalComponentMode && this.transitionMode) {
      throw new Error("Transition-group inside component is not supported. Please alter tag value or remove transition-group. Current tag value: ".concat(this.getTag()));
    }
    var optionsAdded = {};
    eventsListened.forEach(function (elt) {
      optionsAdded['on' + elt] = delegateAndEmit.call(_this3, elt);
    });
    eventsToEmit.forEach(function (elt) {
      optionsAdded['on' + elt] = emit.bind(_this3, elt);
    });
    var attributes = Object.keys(this.$attrs).reduce(function (res, key) {
      res[(0, _vuedraggableHelper.camelize)(key)] = _this3.$attrs[key];
      return res;
    }, {});
    var options = Object.assign({}, this.options, attributes, optionsAdded, {
      onMove: function onMove(evt, originalEvent) {
        return _this3.onDragMove(evt, originalEvent);
      }
    });
    !('draggable' in options) && (options.draggable = '>*');
    this._sortable = new _sortablejs.default(this.rootContainer, options);
    this.computeIndexes();
  },
  beforeDestroy: function beforeDestroy() {
    if (this._sortable !== undefined) this._sortable.destroy();
  },
  computed: {
    rootContainer: function rootContainer() {
      return this.transitionMode ? this.$el.children[0] : this.$el;
    },
    realList: function realList() {
      return this.list ? this.list : this.value;
    }
  },
  watch: {
    options: {
      handler: function handler(newOptionValue) {
        this.updateOptions(newOptionValue);
      },
      deep: true
    },
    $attrs: {
      handler: function handler(newOptionValue) {
        this.updateOptions(newOptionValue);
      },
      deep: true
    },
    realList: function realList() {
      this.computeIndexes();
    }
  },
  methods: {
    getIsFunctional: function getIsFunctional() {
      var fnOptions = this._vnode.fnOptions;
      return fnOptions && fnOptions.functional;
    },
    getTag: function getTag() {
      return this.tag || this.element;
    },
    updateOptions: function updateOptions(newOptionValue) {
      for (var property in newOptionValue) {
        var value = (0, _vuedraggableHelper.camelize)(property);
        if (readonlyProperties.indexOf(value) === -1) {
          this._sortable.option(value, newOptionValue[property]);
        }
      }
    },
    getChildrenNodes: function getChildrenNodes() {
      if (this.noneFunctionalComponentMode) {
        return this.$children[0].$slots.default;
      }
      var rawNodes = this.$slots.default;
      return this.transitionMode ? rawNodes[0].child.$slots.default : rawNodes;
    },
    computeIndexes: function computeIndexes() {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.visibleIndexes = _computeIndexes(_this4.getChildrenNodes(), _this4.rootContainer.children, _this4.transitionMode, _this4.footerOffset);
      });
    },
    getUnderlyingVm: function getUnderlyingVm(htmlElt) {
      var index = computeVmIndex(this.getChildrenNodes() || [], htmlElt);
      if (index === -1) {
        //Edge case during move callback: related element might be
        //an element different from collection
        return null;
      }
      var element = this.realList[index];
      return {
        index: index,
        element: element
      };
    },
    getUnderlyingPotencialDraggableComponent: function getUnderlyingPotencialDraggableComponent(_ref) {
      var vue = _ref.__vue__;
      if (!vue || !vue.$options || !isTransitionName(vue.$options._componentTag)) {
        if (!('realList' in vue) && vue.$children.length === 1 && 'realList' in vue.$children[0]) return vue.$children[0];
        return vue;
      }
      return vue.$parent;
    },
    emitChanges: function emitChanges(evt) {
      var _this5 = this;
      this.$nextTick(function () {
        _this5.$emit('change', evt);
      });
    },
    alterList: function alterList(onList) {
      if (this.list) {
        onList(this.list);
        return;
      }
      var newList = (0, _toConsumableArray2.default)(this.value);
      onList(newList);
      this.$emit('input', newList);
    },
    spliceList: function spliceList() {
      var _arguments = arguments;
      var spliceList = function spliceList(list) {
        return list.splice.apply(list, (0, _toConsumableArray2.default)(_arguments));
      };
      this.alterList(spliceList);
    },
    updatePosition: function updatePosition(oldIndex, newIndex) {
      var updatePosition = function updatePosition(list) {
        return list.splice(newIndex, 0, list.splice(oldIndex, 1)[0]);
      };
      this.alterList(updatePosition);
    },
    getRelatedContextFromMoveEvent: function getRelatedContextFromMoveEvent(_ref2) {
      var to = _ref2.to,
        related = _ref2.related;
      var component = this.getUnderlyingPotencialDraggableComponent(to);
      if (!component) {
        return {
          component: component
        };
      }
      var list = component.realList;
      var context = {
        list: list,
        component: component
      };
      if (to !== related && list && component.getUnderlyingVm) {
        var destination = component.getUnderlyingVm(related);
        if (destination) {
          return Object.assign(destination, context);
        }
      }
      return context;
    },
    getVmIndex: function getVmIndex(domIndex) {
      var indexes = this.visibleIndexes;
      var numberIndexes = indexes.length;
      return domIndex > numberIndexes - 1 ? numberIndexes : indexes[domIndex];
    },
    getComponent: function getComponent() {
      return this.$slots.default[0].componentInstance;
    },
    resetTransitionData: function resetTransitionData(index) {
      if (!this.noTransitionOnDrag || !this.transitionMode) {
        return;
      }
      var nodes = this.getChildrenNodes();
      nodes[index].data = null;
      var transitionContainer = this.getComponent();
      transitionContainer.children = [];
      transitionContainer.kept = undefined;
    },
    onDragStart: function onDragStart(evt) {
      this.context = this.getUnderlyingVm(evt.item);
      evt.item._underlying_vm_ = this.clone(this.context.element);
      draggingElement = evt.item;
    },
    onDragAdd: function onDragAdd(evt) {
      var element = evt.item._underlying_vm_;
      if (element === undefined) {
        return;
      }
      (0, _vuedraggableHelper.removeNode)(evt.item);
      var newIndex = this.getVmIndex(evt.newIndex);
      this.spliceList(newIndex, 0, element);
      this.computeIndexes();
      var added = {
        element: element,
        newIndex: newIndex
      };
      this.emitChanges({
        added: added
      });
    },
    onDragRemove: function onDragRemove(evt) {
      (0, _vuedraggableHelper.insertNodeAt)(this.rootContainer, evt.item, evt.oldIndex);
      if (evt.pullMode === 'clone') {
        (0, _vuedraggableHelper.removeNode)(evt.clone);
        return;
      }
      var oldIndex = this.context.index;
      this.spliceList(oldIndex, 1);
      var removed = {
        element: this.context.element,
        oldIndex: oldIndex
      };
      this.resetTransitionData(oldIndex);
      this.emitChanges({
        removed: removed
      });
    },
    onDragUpdate: function onDragUpdate(evt) {
      (0, _vuedraggableHelper.removeNode)(evt.item);
      (0, _vuedraggableHelper.insertNodeAt)(evt.from, evt.item, evt.oldIndex);
      var oldIndex = this.context.index;
      var newIndex = this.getVmIndex(evt.newIndex);
      this.updatePosition(oldIndex, newIndex);
      var moved = {
        element: this.context.element,
        oldIndex: oldIndex,
        newIndex: newIndex
      };
      this.emitChanges({
        moved: moved
      });
    },
    updateProperty: function updateProperty(evt, propertyName) {
      evt.hasOwnProperty(propertyName) && (evt[propertyName] += this.headerOffset);
    },
    computeFutureIndex: function computeFutureIndex(relatedContext, evt) {
      if (!relatedContext.element) {
        return 0;
      }
      var domChildren = (0, _toConsumableArray2.default)(evt.to.children).filter(function (el) {
        return el.style['display'] !== 'none';
      });
      var currentDOMIndex = domChildren.indexOf(evt.related);
      var currentIndex = relatedContext.component.getVmIndex(currentDOMIndex);
      var draggedInList = domChildren.indexOf(draggingElement) !== -1;
      return draggedInList || !evt.willInsertAfter ? currentIndex : currentIndex + 1;
    },
    onDragMove: function onDragMove(evt, originalEvent) {
      var onMove = this.move;
      if (!onMove || !this.realList) {
        return true;
      }
      var relatedContext = this.getRelatedContextFromMoveEvent(evt);
      var draggedContext = this.context;
      var futureIndex = this.computeFutureIndex(relatedContext, evt);
      Object.assign(draggedContext, {
        futureIndex: futureIndex
      });
      var sendEvt = Object.assign({}, evt, {
        relatedContext: relatedContext,
        draggedContext: draggedContext
      });
      return onMove(sendEvt, originalEvent);
    },
    onDragEnd: function onDragEnd() {
      this.computeIndexes();
      draggingElement = null;
    }
  }
};
if (typeof window !== 'undefined' && 'Vue' in window) {
  window.Vue.component('draggable', draggableComponent);
}
var _default2 = exports.default = draggableComponent;