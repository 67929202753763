var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "medium" },
          on: { click: _vm.createAnimal },
        },
        [_vm._v("创建神兽")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          attrs: { data: _vm.animalList.data, stripe: "" },
        },
        _vm._l(_vm.columnData, function (item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: { prop: item.prop, label: item.label },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      item.prop === "produceOrExchanges"
                        ? [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.accountNum(scope.row.produceOrExchanges)
                                )
                              ),
                            ]),
                          ]
                        : item.prop === "handle"
                        ? [
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAnimal(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ]
                        : [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row[item.prop]) +
                                  "\n          "
                              ),
                            ]),
                          ],
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              "page-size": _vm.pageConfig.pageSize,
              "current-page": _vm.pageConfig.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.animalList.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.getList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }