var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "min-height": "85vh" } },
        [
          _c(
            "div",
            { staticClass: "top-view plr20" },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: {
                    "default-active": _vm.$route.path,
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                _vm._l(_vm.option, function (item) {
                  return _c(
                    "el-menu-item",
                    { key: item.path, attrs: { index: item.path } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }