"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _marketing = require("@/api/marketing");
var _index4 = require("@/utils/index");
var _product = require("@/api/product");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20
      },
      categoryList: [],
      fileTypeList: [{
        id: 1,
        label: "数字藏品"
      }, {
        id: 3,
        label: "数字盲盒"
      }],
      chkName: "",
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      visibleTransCount: false,
      transCountData: {
        data: [],
        total: 0
      },
      transCountParams: {
        pageSize: 20,
        pageNum: 1
      }
    };
  },
  filters: {
    formatType: function formatType(val) {
      var res = "";
      switch (val) {
        case 1:
          res = "作品";
          break;
        case 2:
          res = "系列";
          break;
        case 3:
          res = "盲盒";
          break;
        default:
          break;
      }
      return res;
    },
    formatStatus: function formatStatus(val) {
      var res = "";
      switch (val) {
        case 1:
          res = "寄售";
          break;
        case 2:
          res = "买入";
          break;
        case 3:
          res = "取消寄售";
          break;
        default:
          break;
      }
      return res;
    }
  },
  created: function created() {
    var _this = this;
    this.getList(1);
    (0, _product.storeCategoryListApi)().then(function (res) {
      _this.categoryList = res.data;
    });
  },
  methods: {
    exportOrder: function exportOrder() {
      var _this2 = this;
      (0, _marketing.marketInfoListExportApi)(this.queryParams).then(function (res) {
        _this2.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(5);
    },
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? "" : name;
      this.changeType(!(this.chkName === ""));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = "dan";
        }
      } else {
        this.chkName = "";
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.pageNum);
      if (this.chkName === "dan") {
        this.checkedPage.push(this.queryParams.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this3 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (this.chkName === "duo") {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === "dan") {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this3.checkedIds.indexOf(id);
          if (index === -1) {
            _this3.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this3.checkedIds.indexOf(id);
          if (index > -1) {
            _this3.checkedIds.splice(index, 1);
          }
        });
      }
    },
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === "duo") {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
        }
      } else {
        if (this.chkName === "duo") {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index4.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    goSearch: function goSearch() {
      this.checkedPage = [];
      this.getList(1);
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.setTypeAction();
      (0, _marketing.marketInfoListApi)(this.queryParams).then(function (res) {
        _this4.tableData.data = res.rows;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    downMethod: function downMethod(arr) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u4E0B\u67B6\u6240\u9009\u5BC4\u552E?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _marketing.marketInfoTakeApi)(arr).then(function () {
          _this5.$message.success("操作成功");
          _this5.getList(1);
        }).catch(function (message) {});
      }).catch(function () {});
    },
    downAction: function downAction(row) {
      var arr = [];
      arr.push(row.id);
      this.downMethod(arr);
    },
    batchDownAction: function batchDownAction() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择作品");
      }
      this.downMethod(this.checkedIds);
    },
    transCountAction: function transCountAction(row) {
      var _this6 = this;
      console.log(row, 416);
      this.visibleTransCount = true;
      (0, _marketing.marketRecordApi)(row.objectId, this.transCountParams).then(function (res) {
        _this6.transCountData.data = res.rows;
        _this6.transCountData.total = res.total;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    transCountSizeChange: function transCountSizeChange(val) {
      this.transCountParams.pageSize = val;
      this.getList();
    },
    transCountPageChange: function transCountPageChange(page) {
      this.transCountParams.pageNum = page;
      this.getList("");
    }
  }
};