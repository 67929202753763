"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserList',
  filters: {
    saxFilter: function saxFilter(status) {
      var statusMap = {
        0: '未知',
        1: '男',
        2: '女'
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        'wechat': '微信用户',
        'routine': '小程序用户',
        'h5': 'H5用户'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      moren: require("@/assets/images/f.png"),
      templateRadio: 0,
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        keyword: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    getTemplateRow: function getTemplateRow(idx, row) {
      /* eslint-disable */
      form_create_helper.set(this.$route.query.field, {
        src: row.avatar,
        id: row.uid
      });
      form_create_helper.close(this.$route.query.field);
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.loading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _system.userLstApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message)
        _this.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    }
  }
};