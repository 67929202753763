"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _auth = require("@/utils/auth");
var _moment = _interopRequireDefault(require("moment"));
var _product = require("@/api/product");
var _nft = require("@/api/nft");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AddBlindBox',
  data: function data() {
    return {
      formValidate: {
        name: '',
        products: [],
        excelImportList: [],
        // 导入数据
        type: 1,
        restrictedType: 1
      },
      ruleValidate: {
        name: [{
          required: true,
          message: '请输入优先购名称',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择优先购类型',
          trigger: 'change'
        }],
        restrictedType: [{
          required: true,
          message: '请选择限购规则',
          trigger: 'change'
        }],
        snapshotIds: [{
          required: true,
          message: '请选择关联名单',
          trigger: 'change'
        }]
      },
      tableData: {
        data: [],
        total: 0
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      listLoading: false,
      productForm: {
        types: 1,
        pageNum: 1,
        pageSize: 20,
        status: null,
        startTime: null
      },
      productList: {
        data: [],
        total: 0
      },
      visible: false,
      chooseList: [],
      visibleTitle: '',
      snapshotList: [],
      checkSnapshot: {
        totalNum: '',
        removalNum: ''
      }
    };
  },
  created: function created() {
    var _this = this;
    if (this.$route.query.id) {
      this.getBoxDetail(this.$route.query.id);
    }
    (0, _marketing.marketGetSnapshotApi)().then(function (res) {
      _this.snapshotList = res.data;
    });
  },
  filters: {
    formatProductType: function formatProductType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '数字藏品';
          break;
        case 2:
          res = '数字盲盒';
          break;
        case 3:
          res = '专辑';
          break;
        default:
          break;
      }
      return res;
    }
  },
  methods: {
    uploadRequest: function uploadRequest(file) {
      var formDataFile = new FormData();
      formDataFile.append('file', file.file);
      this.priorityExcelImportApiMethod(formDataFile, true);
    },
    priorityExcelImportApiMethod: function priorityExcelImportApiMethod(formDataFile) {
      var _this2 = this;
      (0, _product.priorityExcelImportApi)(formDataFile, {
        headers: {
          'Content-Type': 'form-data'
        }
      }).then(function (res) {
        _this2.formValidate.rules = res.data;
      }).catch(function (msg) {
        // loading.close();
      });
    },
    // 下载
    downLoad: function downLoad() {
      window.open('https://java-test-cdn.yunzongbu.cn/test/%E4%BC%98%E5%85%88%E8%B4%AD%E8%87%AA%E5%AE%9A%E4%B9%89%E8%A7%84%E5%88%99%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx');
    },
    getBoxDetail: function getBoxDetail(id) {
      (0, _product.snapshotDetailApi)(id).then(function (res) {});
    },
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this3.formValidate));
          if (!data.products.length) {
            _this3.$message({
              type: 'error',
              messgae: '请设置藏品'
            });
            return;
          }
          data.productId = data.products[0].productId;
          data.productType = data.products[0].productType;
          data.firstBuyCountType = data.products[0].firstBuyCountType;
          data.firstBuyCount = data.products[0].firstBuyCount;
          data.aheadBuyTime = data.products[0].aheadBuyTime;
          console.log('data.firstBuyCount', data.firstBuyCount);
          if (!data.firstBuyCount && data.firstBuyCountType == 1 && data.type == 1) {
            _this3.$message.error('请输入优先购数量');
            return false;
          }
          if (!data.aheadBuyTime && _this3.formValidate.type == 1) {
            _this3.$message.error('请选择优先购购买时间');
            return false;
          }
          if (data.type == 2) {
            data.snapshotIds = '';
          } else {
            data.snapshotIds = data.snapshotIds.join(',');
          }
          if (data.hasSms) {
            if (!data.smsSendTime) {
              _this3.$message.error('请选择发送时间');
              return false;
            }
          } else {
            data.smsSendTime = '';
          }
          delete data.products;
          var action = _product.priorityCreateApi;
          if (_this3.$route.query.id) {
            action = _product.priorityUpdateApi;
          }
          var loading = _this3.$loading({
            lock: true,
            text: '保存中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          action(data).then(function (res) {
            loading.close();
            _this3.$message.success('操作成功');
            _this3.$router.back();
          }).catch(function (res) {
            loading.close();
          });
        }
      });
    },
    backAction: function backAction() {
      this.$router.go(-1);
    },
    addProducts: function addProducts(type) {
      this.visible = true;
      this.productForm.types = type;
      this.goSearch();
    },
    goSearch: function goSearch() {
      if (this.productForm.types == 1) {
        this.visibleTitle = '添加数字藏品';
        this.productForm.status = 1;
        this.productForm.startTime = (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss');
        this.nftCollectionListApiMethod();
      } else if (this.productForm.types == 2) {
        this.visibleTitle = '添加数字盲盒';
        this.productForm.status = 2;
        this.productForm.startTime = (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss');
        this.productBoxListApiMethod();
      } else if (this.productForm.types == 3) {
        this.visibleTitle = '添加专辑';
        this.productForm.status = 0;
        this.productForm.startTime = (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss');
        this.nftCollectionAlbumListApiMethod();
      }
    },
    productFormSizeChange: function productFormSizeChange(val) {
      this.productForm.pageSize = val;
      this.goSearch();
    },
    productFormpageChange: function productFormpageChange(val) {
      this.productForm.pageNum = val;
      this.goSearch();
    },
    nftCollectionListApiMethod: function nftCollectionListApiMethod() {
      var _this4 = this;
      (0, _nft.nftCollectionListApi)(this.productForm).then(function (res) {
        _this4.productList.data = res.rows;
        _this4.productList.total = res.total;
        _this4.productList.data.forEach(function (element) {
          element.productType = 1;
          element.choose = false;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    productBoxListApiMethod: function productBoxListApiMethod() {
      var _this5 = this;
      (0, _nft.productBoxListApi)(this.productForm).then(function (res) {
        _this5.productList.data = res.rows;
        _this5.productList.total = res.total;
        _this5.productList.data.forEach(function (element) {
          element.productType = 2;
          element.choose = false;
          element.coverPicture = element.image;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    nftCollectionAlbumListApiMethod: function nftCollectionAlbumListApiMethod() {
      var _this6 = this;
      (0, _nft.nftCollectionAlbumListApi)(this.productForm).then(function (res) {
        _this6.productList.data = res.rows;
        _this6.productList.total = res.total;
        _this6.productList.data.forEach(function (element) {
          element.productType = 3;
          element.choose = false;
          element.coverPicture = element.image;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    chooseRow: function chooseRow(row, index) {
      var obj = {
        productType: row.productType,
        productName: row.productName,
        productId: row.productId,
        coverPicture: row.coverPicture,
        aheadBuyTime: row.aheadBuyTime,
        startTime: row.startTime,
        price: Number(row.price),
        firstBuyCountType: 1
      };
      if (!row.choose) {
        this.chooseList.push(obj);
      } else {
        var _index = this.chooseList.findIndex(function (res) {
          return res.productId == row.productId;
        });
        if (_index != -1) {
          this.chooseList.splice(_index, 1);
        }
      }
      this.productList.data[index].choose = !row.choose;
      this.$forceUpdate();
    },
    confirmAction: function confirmAction() {
      this.formValidate.products = this.chooseList;
      this.visible = false;
    },
    visibleClose: function visibleClose() {
      this.chooseList = [];
    },
    snapshotIdsChange: function snapshotIdsChange(val) {
      var _this7 = this;
      if (val.length) {
        (0, _product.snapshotCheckSnapshotApi)({
          snapshotIds: val.join(',')
        }).then(function (res) {
          _this7.checkSnapshot = res.data;
        });
      }
    },
    productDel: function productDel() {
      this.formValidate.products = [];
    }
  }
};