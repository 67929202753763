var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px 0" } },
    [
      _c(
        "el-form",
        { attrs: { size: "small", inline: "", "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动名称：" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { size: "small", placeholder: "请输入活动名称" },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.tableFrom.activeName,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "activeName", $$v)
                    },
                    expression: "tableFrom.activeName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.goSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动状态：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择活动状态", clearable: "" },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.tableFrom.isStart,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "isStart", $$v)
                    },
                    expression: "tableFrom.isStart",
                  },
                },
                _vm._l(_vm.isStartList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "合成类型：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择合成类型", clearable: "" },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.tableFrom.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "type", $$v)
                    },
                    expression: "tableFrom.type",
                  },
                },
                _vm._l(_vm.typeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("查看导出记录")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "recordsId", label: "ID", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户", "min-width": "150", prop: "nickName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#1890ff", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.nickName) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "合成活动", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.activeName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "合成条件", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          width: "500",
                          trigger: "hover",
                        },
                      },
                      [
                        scope.row.consumeNfts
                          ? _c(
                              "div",
                              _vm._l(scope.row.consumeNfts, function (item) {
                                return _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.productName) +
                                      " * " +
                                      _vm._s(item.number) +
                                      "\n            "
                                  ),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "text",
                              size: "mini",
                            },
                            slot: "reference",
                          },
                          [_vm._v("查看")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "合成玩法", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.ruleSetName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "合成时间",
              "min-width": "150",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.pageSize,
              "current-page": _vm.tableFrom.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }