"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var cmsRouter = {
  path: "".concat(_settings.roterPre, "/digitalAssets"),
  name: "digitalAssets",
  meta: {
    icon: "dashboard",
    title: "内容"
  },
  alwaysShow: true,
  component: _layout.default,
  children: [
  // {
  //   path: "worksList",
  //   name: "worksList",
  //   meta: {
  //     title: "作品管理",
  //     noCache: true
  //   },
  //   component: () => import("@/views/digitalAssets/works/list")
  // },
  {
    path: "worksList",
    name: "worksList",
    meta: {
      title: "作品管理",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/digitalAssets/worksList"));
      });
    }
  }, {
    path: "worksEvide",
    name: "worksEvide",
    meta: {
      title: "作品存证",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/digitalAssets/evidence"));
      });
    }
  }, {
    path: "worksEdit",
    name: "worksEdit",
    meta: {
      title: "作品编辑",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/digitalAssets/works/edit"));
      });
    }
  }, {
    path: "worksIssue",
    name: "worksIssue",
    meta: {
      title: "作品发行",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/digitalAssets/works/issue"));
      });
    }
  }, {
    path: "empowerList",
    name: "empowerList",
    meta: {
      title: "版权发行",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/digitalAssets/works/empowerList"));
      });
    }
  }, {
    path: "transferenceList",
    name: "transferenceList",
    meta: {
      title: "版权出售",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/digitalAssets/works/transferenceList"));
      });
    }
  }, {
    path: "modelingList",
    name: "ModelingList",
    meta: {
      title: "建模列表",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/digitalAssets/modeling/index"));
      });
    }
  }, {
    path: "demand",
    name: "Demand",
    meta: {
      title: "需求管理",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/digitalAssets/demand/index"));
      });
    }
  }]
};
var _default = exports.default = cmsRouter;