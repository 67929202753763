"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _nft = require("@/api/nft");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "blindList",
  data: function data() {
    return {
      SettingMer: _settingMer.default,
      types: "blind",
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        status: "",
        pageNum: 1,
        pageSize: 20,
        name: ""
      },
      showImg: false,
      showImgUrl: ""
    };
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    freedStock: function freedStock(row) {
      var _this = this;
      this.$confirm("确认释放此盲盒？", "提示", {
        confirmButtonText: "释放",
        type: "warning"
      }).then(function () {
        (0, _nft.productBoxFreedStockApi)(row.id).then(function () {
          _this.$message.success("操作成功");
          _this.getList(1);
        });
      }).catch(function (_ref) {
        var message = _ref.message;
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "blind") {
        this.$router.push("/merchant/digitalCollection/blind/list");
        return false;
      } else if (tab.name === "position") {
        this.$router.push("/merchant/digitalCollection/position/list");
        return false;
      } else if (tab.name === "subgift") {
        this.$router.push("/merchant/digitalCollection/subgift/list");
        return false;
      } else if (tab.name === "recovery") {
        this.$router.push("/merchant/digitalCollection/recovery/list");
        return false;
      } else if (tab.name === "ssr") {
        this.$router.push("/merchant/digitalCollection/ssrSet");
        return false;
      }
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    goSearch: function goSearch() {
      this.getList();
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.setTypeAction();
      (0, _nft.productBoxListApi)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.tableData.statistical = res.statistical;
        if (_this2.tableData.statistical !== null) {
          _this2.tableData.statistical.all = _this2.tableData.statistical.freedCount + _this2.tableData.statistical.issuanceCount + _this2.tableData.statistical.noShowCount + _this2.tableData.statistical.showCount + _this2.tableData.statistical.stockZeroCount;
        }
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    addAction: function addAction() {
      this.$router.push("./detail");
    },
    editAction: function editAction(id) {
      this.$router.push("./detail?id=" + id);
    },
    linkAction: function linkAction(id) {
      console.log(process, 280);
      this.copyAction("".concat(_settingMer.default.appUrl, "/pages/nftCollection/nftbox?id=").concat(id));
    },
    QRAction: function QRAction(id) {
      var _this3 = this;
      this.showImg = true;
      (0, _system.createQRCodeApi)({
        url: "/pages/nftCollection/nftbox?id=".concat(id),
        width: 400,
        height: 400
      }).then(function (res) {
        _this3.showImgUrl = res.data.image;
      });
    },
    delAction: function delAction(id) {
      var _this4 = this;
      this.$confirm("确认删除此盲盒？", "提示", {
        confirmButtonText: "删除",
        type: "warning"
      }).then(function () {
        (0, _nft.productBoxDeleteApi)(id).then(function () {
          _this4.$message.success("操作成功");
          _this4.getList(1);
        });
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    editSortAction: function editSortAction(val, index) {
      var _this5 = this;
      // 修改排序
      var item = this.tableData.data[index];
      var params = {
        id: item.id,
        sort: val
      };
      (0, _nft.productBoxUpdateApi)(params).then(function () {
        _this5.$message.success("操作成功");
        _this5.getList(1);
      }).catch(function () {});
    },
    pushAction: function pushAction(id) {
      var _this6 = this;
      this.$confirm("确认发行此盲盒？", "提示", {
        confirmButtonText: "发行",
        type: "warning"
      }).then(function () {
        (0, _nft.productBoxIssueApi)(id).then(function () {
          _this6.$message.success("操作成功");
          _this6.getList(1);
        });
      }).catch(function (_ref3) {
        var message = _ref3.message;
      });
    },
    copyAction: function copyAction(val) {
      var input = document.createElement("input"); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList(1);
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this7 = this;
      var arr = [];
      arr.push(row.id);
      (0, _nft.productBoxShowUpdateApi)({
        productIds: arr,
        showStatus: !row.isShow
      }).then(function (_ref4) {
        var message = _ref4.message;
        _this7.getList(1);
      });
    }
  }
};