"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _asset = require("@/api/asset");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Collector",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      productName: "",
      allConsumeMoney: "",
      allGetMoney: "",
      timeType: "lately7",
      value1: "",
      options: [{
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 30
          },
          data: []
        },
        yAxis: {
          min: 0,
          max: function max(value) {
            //取最大值向上取整为最大刻度
            return Math.ceil(value.max);
          }
        },
        series: [{
          name: "总资产",
          data: [],
          type: "line",
          smooth: true
        }]
      }, {
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 30
          },
          data: []
        },
        yAxis: {
          min: 0,
          max: function max(value) {
            //取最大值向上取整为最大刻度
            return Math.ceil(value.max);
          }
        },
        series: [{
          name: "收益",
          data: [],
          type: "line",
          smooth: true
        }]
      }, {
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 30
          },
          data: []
        },
        yAxis: {
          min: 0,
          max: function max(value) {
            //取最大值向上取整为最大刻度
            return Math.ceil(value.max);
          }
        },
        series: [{
          name: "净买入",
          data: [],
          type: "line",
          smooth: true
        }]
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    assetsExport: function assetsExport() {
      var _this = this;
      var data = {
        productName: this.productName
      };
      if (this.value1) {
        data.timeType = "".concat(this.value1[0], "-").concat(this.value1[1]);
      } else {
        data.timeType = this.timeType;
      }
      (0, _asset.allMemberAssetsExport)(data).then(function (res) {
        console.log(res);
        _this.$message.success("导出成功");
      }).catch(function (err) {
        _this.$message.error("导出失败");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(43);
    },
    getList: function getList() {
      var _this2 = this;
      var data = {
        productName: this.productName
      };
      if (this.value1) {
        data.timeType = "".concat(this.value1[0], "-").concat(this.value1[1]);
      } else {
        data.timeType = this.timeType;
      }
      this.options[0].xAxis.data = [];
      this.options[0].series[0].data = [];
      this.options[1].xAxis.data = [];
      this.options[1].series[0].data = [];
      this.options[2].xAxis.data = [];
      this.options[2].series[0].data = [];
      (0, _asset.allMemberAssets)(data).then(function (res) {
        var myChart1 = _this2.$echarts.init(document.getElementById("main1"));
        var myChart2 = _this2.$echarts.init(document.getElementById("main2"));
        var myChart3 = _this2.$echarts.init(document.getElementById("main3"));
        var _res$data = res.data,
          platformMemberNft1Vos1 = _res$data.platformMemberNft1Vos1,
          platformMemberNft1Vos2 = _res$data.platformMemberNft1Vos2,
          platformMemberNft1Vos3 = _res$data.platformMemberNft1Vos3,
          allConsumeMoney = _res$data.allConsumeMoney,
          allGetMoney = _res$data.allGetMoney;
        console.log(res);
        _this2.allConsumeMoney = allConsumeMoney;
        _this2.allGetMoney = allGetMoney;
        platformMemberNft1Vos1.forEach(function (item) {
          _this2.options[0].xAxis.data.push(item.date);
          _this2.options[0].series[0].data.push(item.assetsMoney || 0);
        });
        platformMemberNft1Vos2.forEach(function (item) {
          _this2.options[1].xAxis.data.push(item.date);
          _this2.options[1].series[0].data.push(item.assetsMoney || 0);
        });
        platformMemberNft1Vos3.forEach(function (item) {
          _this2.options[2].xAxis.data.push(item.date);
          _this2.options[2].series[0].data.push(item.assetsMoney || 0);
        });
        myChart1.setOption(_this2.options[0]);
        myChart2.setOption(_this2.options[1]);
        myChart3.setOption(_this2.options[2]);
      });
    }
  }
};