"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _settings = require("@/settings");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "QuickPhoto",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      userflag: false,
      newlistLoading: true,
      newtableData: {
        data: [],
        total: 0
      },
      newtableFrom: {
        pageNum: 1,
        pageSize: 20,
        id: ''
      },
      visibleTitle: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(9);
    },
    showuser: function showuser(row) {
      this.userflag = true;
      this.newtableFrom.id = row.id;
      this.getuserList(1);
    },
    goSearch: function goSearch() {
      this.getuserList(1);
    },
    getuserList: function getuserList(num) {
      var _this = this;
      this.newlistLoading = true;
      this.newtableFrom.pageNum = num || this.newtableFrom.pageNum;
      (0, _product.lockUserList)(this.newtableFrom).then(function (res) {
        _this.newtableData.data = res.rows;
        _this.newtableData.total = res.total;
        _this.visibleTitle = "\u5171".concat(res.total, "\u4E2A\u7528\u6237");
        _this.newlistLoading = false;
      }).catch(function (res) {
        _this.newlistLoading = false;
        // this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _product.locklist)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    newpageChange: function newpageChange(page) {
      this.newtableFrom.pageNum = page;
      this.getuserList();
    },
    newhandleSizeChange: function newhandleSizeChange(val) {
      this.newtableFrom.pageSize = val;
      this.getuserList();
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push({
        path: _settings.roterPre + "/product/lockadd"
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      this.$router.push({
        path: _settings.roterPre + "/product/lockadd",
        query: {
          id: id
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(id) {
      this.snapshotDeleteApiMethod(id);
    },
    snapshotDeleteApiMethod: function snapshotDeleteApiMethod(id) {
      var _this3 = this;
      this.$confirm('确定删除此任务?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _product.lockdelete)(id).then(function (res) {
          _this3.$message.success('操作成功');
          _this3.getList('');
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      }).catch(function () {});
    },
    // 导出
    exportOrder: function exportOrder(id) {
      var _this4 = this;
      (0, _product.snapshotExportApi)({
        id: id
      }).then(function (res) {
        _this4.$message.success('导出成功');
      });
    }
  }
};