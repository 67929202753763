var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": "4",
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("模版")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("框类型")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [_vm._v("框卡纸")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [_vm._v("场景")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [_vm._v("画框")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-20" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { input: _vm.changeType },
                  model: {
                    value: _vm.categoryId,
                    callback: function ($$v) {
                      _vm.categoryId = $$v
                    },
                    expression: "categoryId",
                  },
                },
                _vm._l(_vm.categorys, function (item, index) {
                  return _c("el-radio-button", { attrs: { label: item.id } }, [
                    _vm._v(_vm._s(item.categoryName)),
                  ])
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  attrs: { type: "primary", size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.model1 = true
                    },
                  },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-box" },
            _vm._l(_vm.lists, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "imgItem" },
                [
                  _c("el-image", {
                    staticClass: "imgSize",
                    attrs: {
                      src: item.imageUrl + "!m640",
                      alt: "",
                      "preview-src-list": _vm.srcList,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "handBox" }, [
                    _c("div", [_vm._v("分类: " + _vm._s(item.categoryName))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          staticStyle: { width: "60px" },
                          on: {
                            blur: function ($event) {
                              return _vm.changeSort(item)
                            },
                          },
                          model: {
                            value: item.sort,
                            callback: function ($$v) {
                              _vm.$set(item, "sort", $$v)
                            },
                            expression: "item.sort",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定删除吗?" },
                          on: {
                            confirm: function ($event) {
                              return _vm.delscence(item)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "handBut" }, [
                                      _vm._v("删除"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default-box m0" },
            [
              _vm.total > 0
                ? _c("el-pagination", {
                    staticClass: "mt20",
                    attrs: {
                      total: _vm.total,
                      "show-sizer": "",
                      "show-elevator": "",
                      "show-total": "",
                      "current-page": _vm.queryParams.pageNum,
                      "page-size": _vm.queryParams.pageSize,
                    },
                    on: {
                      "current-change": _vm.changePage,
                      "size-change": _vm.changeSize,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.model1,
                width: "30%",
                title: "作品集",
                "class-name": "vertical-center-modal",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.model1 = $event
                },
                close: _vm.onClose,
              },
            },
            [
              _c(
                "el-form",
                { ref: "formList", attrs: { model: _vm.formList } },
                [
                  _c("el-form-item", { attrs: { label: "场景图片" } }, [
                    _vm.formList.imageUrl
                      ? _c("div", { staticClass: "upload-list" }, [
                          _c("img", { attrs: { src: _vm.formList.imageUrl } }),
                          _vm._v(" "),
                          _c("div", { staticClass: "upload-list-cover" }, [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              attrs: { size: "20" },
                              on: { click: _vm.handleRemove },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.formList.imageUrl,
                            expression: "!formList.imageUrl",
                          },
                        ],
                        staticClass: "border-h",
                        staticStyle: {
                          width: "58px",
                          height: "58px",
                          "line-height": "58px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onUpload(1)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-camera-solid",
                          attrs: { size: "20" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "240px",
                          "text-align": "center",
                          "font-size": "12px",
                        },
                      },
                      [_vm._v("\n            建议尺寸750*780\n          ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "场景类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          model: {
                            value: _vm.formList.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formList, "categoryId", $$v)
                            },
                            expression: "formList.categoryId",
                          },
                        },
                        _vm._l(_vm.categorys, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.categoryName },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.onClose } }, [
                    _vm._v("取 消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }