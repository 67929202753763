var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "页面基础设置", is_tab: false } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "80px",
            size: "small",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "mt20",
              staticStyle: { color: "#000" },
              attrs: { label: "标题" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  placeholder: "请输入",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.pageInfo.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo, "name", $$v)
                  },
                  expression: "pageInfo.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "mt20",
              staticStyle: { color: "#000" },
              attrs: { label: "分享描述" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  placeholder: "请输入",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.pageInfo.share_txt,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo, "share_txt", $$v)
                  },
                  expression: "pageInfo.share_txt",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
              _vm._v("分享卡片"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "upload-img-view item-flex-center",
                on: {
                  click: function ($event) {
                    return _vm.modalPicTap(1, "share_image")
                  },
                },
              },
              [
                _vm.pageInfo.share_image
                  ? _c(
                      "div",
                      {
                        staticClass: "del-upload-img",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.delImg("share_image")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pageInfo.share_image
                  ? _c("img", {
                      attrs: { src: _vm.pageInfo.share_image + "!120a" },
                    })
                  : _c("img", {
                      staticClass: "upload-icon",
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                        alt: "",
                      },
                    }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("用户登录方式"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one ml20" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "le-radio-group",
                    model: {
                      value: _vm.pageInfo.login_config,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "login_config", $$v)
                      },
                      expression: "pageInfo.login_config",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("提示")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 2 } }, [_vm._v("强制")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 3 } }, [_vm._v("按需")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "mt20",
              staticStyle: { color: "#000" },
              attrs: { label: "背景颜色" },
            },
            [
              _c("colour", {
                attrs: { colour: "#f7f7f7" },
                model: {
                  value: _vm.pageInfo.background,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo, "background", $$v)
                  },
                  expression: "pageInfo.background",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
              _vm._v("背景图片"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "upload-img-view item-flex-center",
                on: {
                  click: function ($event) {
                    return _vm.modalPicTap(1, "bg_image")
                  },
                },
              },
              [
                _vm.pageInfo.bg_image
                  ? _c(
                      "div",
                      {
                        staticClass: "del-upload-img",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.delImg("bg_image")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pageInfo.bg_image
                  ? _c("img", {
                      attrs: { src: _vm.pageInfo.bg_image + "!120a" },
                    })
                  : _c("img", {
                      staticClass: "upload-icon",
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                        alt: "",
                      },
                    }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "背景图设置" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "le-radio-group",
                  model: {
                    value: _vm.pageInfo.bg_image_mode,
                    callback: function ($$v) {
                      _vm.$set(_vm.pageInfo, "bg_image_mode", $$v)
                    },
                    expression: "pageInfo.bg_image_mode",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("宽度固定上下居中"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("满屏上下左右截取"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("宽度固定上下平铺循环展示"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("宽度固定高度自适应顶部展示"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-align-center mt20" },
            [
              _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                _vm._v("隐藏顶部"),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "flex-one",
                staticStyle: { "font-size": "12px", color: "#999" },
              }),
              _vm._v(" "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#009406",
                  "inactive-color": "#cccccc",
                },
                model: {
                  value: _vm.pageInfo.hide_header,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo, "hide_header", $$v)
                  },
                  expression: "pageInfo.hide_header",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-align-center mt20" },
            [
              _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                _vm._v("隐藏顶部标题栏"),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "flex-one",
                staticStyle: { "font-size": "12px", color: "#999" },
              }),
              _vm._v(" "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#009406",
                  "inactive-color": "#cccccc",
                },
                model: {
                  value: _vm.pageInfo.hide_header_view,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo, "hide_header_view", $$v)
                  },
                  expression: "pageInfo.hide_header_view",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-align-center mt20" },
            [
              _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                _vm._v("隐藏底部导航"),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "flex-one",
                staticStyle: { "font-size": "12px", color: "#999" },
              }),
              _vm._v(" "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#009406",
                  "inactive-color": "#cccccc",
                },
                model: {
                  value: _vm.pageInfo.hide_footer,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo, "hide_footer", $$v)
                  },
                  expression: "pageInfo.hide_footer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasCopyright == 1
            ? _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("展示版权"),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "flex-one",
                    staticStyle: { "font-size": "12px", color: "#999" },
                  }),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#009406",
                      "inactive-color": "#cccccc",
                    },
                    model: {
                      value: _vm.pageInfo.show_copyright,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "show_copyright", $$v)
                      },
                      expression: "pageInfo.show_copyright",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }