"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMemberSdkInfoListApi = getMemberSdkInfoListApi;
exports.getSdkAccessStatisticsListApi = getSdkAccessStatisticsListApi;
exports.getSdkApplicationApi = getSdkApplicationApi;
exports.getSdkConfigApi = getSdkConfigApi;
exports.getSdkConfigInfoApi = getSdkConfigInfoApi;
exports.getSdkDataMonitorApi = getSdkDataMonitorApi;
exports.getSdkSpaceListApi = getSdkSpaceListApi;
exports.setSdkConfigApi = setSdkConfigApi;
exports.setSdkStatusApi = setSdkStatusApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * 产品配置
 */
function getSdkConfigApi(data) {
  return _request.default.get('/open3d/product/sys/product/sdk/config', data);
}
/**
 * 获取产品配置详情
 */
function getSdkConfigInfoApi(id) {
  return _request.default.get("/open3d/product/sys/product/sdk/configInfo/".concat(id));
}
/**
 * 修改产品配置详情
 */
function setSdkConfigApi(data) {
  return _request.default.post("/open3d/product/sys/product/sdk/updateProductSdk", data);
}
/**
 * SDK 数据监控
 */
function getSdkDataMonitorApi(data) {
  return _request.default.get("/open3d/product/sys/product/sdk/sdkDataMonitor", data);
}
/**
 * SDK 分页展示空间列表
 */
function getSdkSpaceListApi(data) {
  return _request.default.get("/open3d/product/sys/space/list", data);
}
/**
 * SDK 根据空间id获取访问统计列表
 */
function getSdkAccessStatisticsListApi(data) {
  return _request.default.get("/open3d/product/sys/space/accessStatisticsList", data);
}
/**
 * SDK 应用管理
 */
function getSdkApplicationApi(data) {
  return _request.default.get("/open3d/product/sys/product/sdk/sdkApplication", data);
}
/**
 * SDK 应用管理
 */
function setSdkStatusApi(data) {
  return _request.default.post("/open3d/product/sys/product/sdk/checkSdk", data);
}
/**
 * SDK用户管理
 */
function getMemberSdkInfoListApi(data) {
  return _request.default.get("/open3d/product/sys/product/sdk/memberSdkInfoList", data);
}