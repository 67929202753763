var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-Statistics" },
    [
      _c("header-menu", { attrs: { params: _vm.orderCustomer } }),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0" }, attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "panel-title-menu" },
              [
                _c(
                  "span",
                  {
                    staticClass: "user-s-title",
                    staticStyle: { "font-weight": "bold", margin: "10px 0" },
                  },
                  [_vm._v("快捷入口")]
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "menu-list",
                    attrs: { justify: "space-between" },
                  },
                  _vm._l(_vm.meunlist, function (item, index) {
                    return _c(
                      "el-col",
                      {
                        key: index,
                        staticClass: "menu-list-block menu-list-center",
                        attrs: { span: 4 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "60px",
                              "text-align": "center",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.itemClick(item)
                              },
                            },
                          },
                          [
                            _c("img", { attrs: { src: item.img, alt: "" } }),
                            _vm._v(" "),
                            _c("p", { staticClass: "p-text" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "panel-title-menu" },
              [
                _c(
                  "span",
                  {
                    staticClass: "user-s-title",
                    staticStyle: { "font-weight": "bold", margin: "10px 0" },
                  },
                  [_vm._v("待办事项")]
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "menu-list",
                    attrs: { justify: "space-between" },
                  },
                  _vm._l(_vm.todo, function (item, index) {
                    return _c(
                      "el-col",
                      {
                        key: index,
                        staticClass: "menu-list-block",
                        attrs: { span: 4 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "70px",
                              "text-align": "center",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.itemClick(item)
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "p-text1" }, [
                              _vm._v(_vm._s(item.value)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "p-text" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticClass: "panel-warp",
          staticStyle: { margin: "10px 0" },
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 16 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "panel-title",
                  staticStyle: { background: "#fff", padding: "20px" },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("span", { staticClass: "user-s-title" }, [
                      _vm._v("用户概览"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "align-right", attrs: { span: 20 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changeOverview },
                          model: {
                            value: _vm.form.time1,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "time1", $$v)
                            },
                            expression: "form.time1",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "yesterday" } },
                            [_vm._v("昨天")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "lately7" } },
                            [_vm._v("最近7天")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "lately30" } },
                            [_vm._v("最近30天")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          size: "small",
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "timestamp",
                        },
                        on: { change: _vm.timeChange },
                        model: {
                          value: _vm.form.value1,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "value1", $$v)
                          },
                          expression: "form.value1",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.userSearch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        background: "#fff",
                        height: "360px",
                        position: "relative",
                      },
                    },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-blue",
                                attrs: { span: 22 },
                              },
                              [
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(
                                    "注册用户数量（位）:" +
                                      _vm._s(
                                        _vm.orderCustomerShow.registerCount || 0
                                      )
                                  ),
                                ]),
                                _vm._v(
                                  "\n                环比增长率（%）: " +
                                    _vm._s(
                                      _vm.orderCustomerShow.registerRatio || 0
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "blue-trapezoid bg-trapezoid",
                                attrs: { span: 14 },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.orderCustomerShow.visitorCount || 0
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v("访客"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-green",
                                attrs: { span: 8 },
                              },
                              [
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(
                                    "活跃用户数量（位）:\n                  " +
                                      _vm._s(
                                        _vm.orderCustomerShow.hotUserCount || 0
                                      )
                                  ),
                                ]),
                                _vm._v(
                                  "\n                环比增长率（%）: " +
                                    _vm._s(
                                      _vm.orderCustomerShow.hotUserRatio || 0
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-col", {
                              staticClass: "bg-color bg-green",
                              staticStyle: { height: "100px" },
                              attrs: { span: 6 },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "green-trapezoid bg-trapezoid",
                                attrs: { span: 14 },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderCustomerShow.orderCount || 0
                                    )
                                  ),
                                  _c("br"),
                                  _vm._v("下单"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-gray-dark",
                                attrs: { span: 6 },
                              },
                              [
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(
                                    "充值用户数量（位）:\n                  " +
                                      _vm._s(
                                        _vm.orderCustomerShow
                                          .rechargeUserCount || 0
                                      )
                                  ),
                                ]),
                                _vm._v(
                                  "\n                环比增长率（%）: " +
                                    _vm._s(
                                      _vm.orderCustomerShow.rechargeRatio || 0
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-gray-dark",
                                staticStyle: { "min-height": "100px" },
                                attrs: { span: 6 },
                              },
                              [
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(
                                    "客单价（元）: " +
                                      _vm._s(_vm.orderCustomerShow.sales || 0)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-col", {
                              staticClass: "bg-color bg-gray-dark",
                              staticStyle: { height: "100px" },
                              attrs: { span: 2 },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "gray-dark-trapezoid bg-trapezoid",
                                attrs: { span: 14 },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderCustomerShow
                                        .successfulOrderCount || 0
                                    )
                                  ),
                                  _c("br"),
                                  _vm._v("成交用户"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { "padding-left": "0!important" },
              attrs: { xs: 24, sm: 24, lg: 8 },
            },
            [
              _c(
                "el-row",
                {
                  staticClass: "panel-title",
                  staticStyle: {
                    background: "#fff",
                    padding: "20px 20px 20px",
                  },
                },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", { staticClass: "user-s-title" }, [
                      _vm._v("用户渠道"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c("pie-chart", {
                    ref: "pieChart",
                    attrs: { amount: _vm.isAmount },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 10px" } },
        [
          _c(
            "el-row",
            {
              staticClass: "panel-title",
              staticStyle: { background: "#fff", padding: "20px 20px 20px" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "user-s-title" }, [
                  _vm._v("订单统计"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "align-right", attrs: { span: 16 } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeTime },
                      model: {
                        value: _vm.time2,
                        callback: function ($$v) {
                          _vm.time2 = $$v
                        },
                        expression: "time2",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "lately30" } }, [
                        _vm._v("30天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "lately7" } }, [
                        _vm._v("周"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "month" } }, [
                        _vm._v("月"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "year" } }, [
                        _vm._v("年"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "panel-title", staticStyle: { background: "#fff" } },
            [
              _c("span", { staticClass: "user-s-subtitle" }, [
                _vm._v("订单量趋势"),
              ]),
              _vm._v(" "),
              _c("bar-chart", {
                ref: "barChart",
                attrs: { amount: _vm.isAmount, date: _vm.time2 },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 10px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-row",
            {
              staticClass: "panel-title",
              staticStyle: { background: "#fff", padding: "20px 20px 20px" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "user-s-title" }, [
                  _vm._v("用户统计"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "align-right", attrs: { span: 16 } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeTime4 },
                      model: {
                        value: _vm.time3,
                        callback: function ($$v) {
                          _vm.time3 = $$v
                        },
                        expression: "time3",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "lately30" } }, [
                        _vm._v("30天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "lately7" } }, [
                        _vm._v("周"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "month" } }, [
                        _vm._v("月"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "year" } }, [
                        _vm._v("年"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "panel-title", staticStyle: { background: "#fff" } },
            [
              _c("area-chart", {
                ref: "areaChart",
                attrs: { amount: _vm.isAmount, date: _vm.time3 },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }