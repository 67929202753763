"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _user = require("@/api/user");
var _chatGroup = require("@/api/chatGroup");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "ChatGroup",
  data: function data() {
    return {
      searchGroup: "",
      tableData: {
        data: [],
        total: 0
      },
      userList: [],
      columnData: [{
        label: "群名",
        prop: "groupName"
      }, {
        label: "群封面",
        prop: "image"
      }, {
        label: "群成员",
        prop: "gorupMerNum"
      }, {
        label: "入群申请",
        prop: "applyForGroupNum"
      }, {
        label: "群主",
        prop: "nickName"
      }, {
        label: "创建时间",
        prop: "createTime"
      }, {
        label: "进群审核",
        prop: "isCheck"
      }, {
        label: "群成员加好友",
        prop: "addCanFriend"
      }, {
        label: "邀请新用户进群",
        prop: "inviteFriend"
      }, {
        label: "操作",
        prop: "handle"
      }],
      // -------------------------------------群成员管理------------------------------------------------
      manageData: {
        data: [],
        total: 0
      },
      manageColumn: [{
        label: "用户名",
        prop: "nickName"
      }, {
        label: "手机号",
        prop: "phone"
      }, {
        label: "头像",
        prop: "image"
      }, {
        label: "入群时间",
        prop: "joinTime"
      }, {
        label: "操作",
        prop: "handle"
      }],
      managePaging: {
        pageNum: 1,
        pageSize: 10
      },
      manageLoading: false,
      manageVisible: false,
      multipleManage: [],
      managePhone: "",
      // -------------------------------------成员管理------------------------------------------------
      multipleMember: [],
      memberVisible: false,
      memberData: {
        data: [],
        total: 0
      },
      memberColumn: [{
        label: "用户名",
        prop: "nickName"
      }, {
        label: "头像",
        prop: "avatar"
      }, {
        label: "持仓数量",
        prop: "nftCount"
      }, {
        label: "注册时间",
        prop: "createTime"
      }],
      memberPaging: {
        pageNum: 1,
        pageSize: 10
      },
      memberLoading: false,
      memberSearch: {
        phoneOrName: "",
        nftName: ""
      },
      // --------------------------------------------------入群审核---------------------------------
      applicationDialog: false,
      applicationData: {
        data: [],
        total: 0
      },
      applicationPaging: {
        pageNum: 1,
        pageSize: 10
      },
      applicationColumn: [{
        label: "用户名",
        prop: "nickName"
      }, {
        label: "头像",
        prop: "avatar"
      }, {
        label: "申请时间",
        prop: "joinTime"
      }, {
        label: "申请理由",
        prop: "applyReason"
      }, {
        label: "操作",
        prop: "handle"
      }],
      multipleApplication: [],
      // ----------------------------------------------管理员----------------------------------

      adminData: {
        data: [],
        total: 0
      },
      adminColumn: [{
        label: "头像",
        prop: "image"
      }, {
        label: "用户名",
        prop: "nickName"
      }, {
        label: "手机号",
        prop: "phone"
      }, {
        label: "操作",
        prop: "handle"
      }],
      adminPaging: {
        pageNum: 1,
        pageSize: 10
      },
      adminPhone: "",
      adminLoading: false,
      adminDialog: false,
      // ------------------------------------------------------------------------------------------
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      listLoading: false,
      userLoading: "",
      groupVisible: false,
      isEdit: false,
      rules: {
        groupName: [{
          required: true,
          message: "请输入群名称",
          trigger: "blur"
        }],
        phone: [{
          required: true,
          message: "请选择群主",
          trigger: "change"
        }],
        image: [{
          required: true,
          message: "请选择群封面",
          trigger: "change"
        }]
      },
      ruleForm: {
        image: "",
        groupName: "",
        phone: "",
        isCheck: true,
        addCanFriend: false,
        inviteFriend: false
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    getList: function getList(page) {
      var _this2 = this;
      var data = {
        name: this.searchGroup,
        pageNum: page ? page : this.queryParams.page,
        pageSize: this.queryParams.pageSize
      };
      this.listLoading = true;
      (0, _chatGroup.groupListApi)(data).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    searchUserAction: function searchUserAction(data) {
      var _this3 = this;
      this.userLoading = true;
      var params = {
        nameOrPhone: data
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this3.userLoading = false;
        _this3.userList = res.rows;
      });
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.ruleForm.image = img[0];
      }, tit);
    },
    closeGroup: function closeGroup(formName) {
      this.$refs[formName].resetFields();
      this.$data.ruleForm = JSON.parse(JSON.stringify(this.$options.data().ruleForm) //解决关闭弹框 数据没有清空
      );
      this.groupVisible = false;
      this.isEdit = false;
    },
    editGroup: function editGroup(item) {
      var groupId = item.groupId,
        groupName = item.groupName,
        image = item.image,
        phone = item.phone,
        isCheck = item.isCheck,
        nickName = item.nickName,
        addCanFriend = item.addCanFriend,
        inviteFriend = item.inviteFriend;
      this.userList.push({
        nickName: nickName,
        userPhone: phone
      });
      this.groupId = groupId;
      this.ruleForm.groupName = groupName;
      this.ruleForm.image = image;
      this.ruleForm.phone = phone;
      this.ruleForm.isCheck = isCheck;
      this.ruleForm.inviteFriend = inviteFriend;
      this.ruleForm.addCanFriend = addCanFriend;
      this.isEdit = true;
      this.groupVisible = true;
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!_this4.isEdit) {
            (0, _chatGroup.saveGroupApi)(_this4.ruleForm).then(function (res) {
              _this4.closeGroup("ruleForm");
              _this4.getList(1);
              _this4.$message.success("创建成功");
            });
          } else {
            (0, _chatGroup.updateGroupApi)((0, _objectSpread2.default)({
              groupId: _this4.groupId
            }, _this4.ruleForm)).then(function (res) {
              _this4.groupId = "";
              _this4.closeGroup("ruleForm");
              _this4.getList(1);
              _this4.$message.success("编辑成功");
            });
          }
        } else {
          return false;
        }
      });
    },
    dissolution: function dissolution(id) {
      var _this5 = this;
      (0, _chatGroup.dissolutionApi)(id).then(function (res) {
        _this5.$message.success("解散成功");
        _this5.getList(1);
      });
    },
    handleManageSizeChange: function handleManageSizeChange(val) {
      this.managePaging.pageSize = val;
      this.getManageList();
    },
    getManageList: function getManageList(page, groupId) {
      var _this6 = this;
      if (groupId) {
        this.groupId = groupId;
      }
      var data = {
        phone: this.managePhone,
        groupId: this.groupId,
        pageNum: page ? page : this.managePaging.pageNum,
        pageSize: this.managePaging.pageSize
      };
      this.manageLoading = true;
      this.manageVisible = true;
      (0, _chatGroup.groupUserListApi)(data).then(function (res) {
        _this6.manageLoading = false;
        _this6.manageData.data = res.rows;
        _this6.manageData.total = res.total;
      });
    },
    handleApplicationSizeChange: function handleApplicationSizeChange(val) {
      this.applicationPaging.pageSize = val;
      this.getApplyList();
    },
    getApplyList: function getApplyList(page, groupId) {
      var _this7 = this;
      if (groupId) {
        this.groupId = groupId;
      }
      var data = {
        pageNum: page ? page : this.applicationPaging.pageNum,
        pageSize: this.applicationPaging.pageSize
      };
      (0, _chatGroup.applyForGroupNumApi)(this.groupId, data).then(function (res) {
        _this7.applicationData.data = res.rows;
        _this7.applicationData.total = res.total;
        _this7.applicationDialog = true;
      });
    },
    handleMemberSizeChange: function handleMemberSizeChange(val) {
      this.memberPaging.pageSize = val;
      this.getMemberList();
    },
    getMemberList: function getMemberList(page, groupId) {
      var _this8 = this;
      if (groupId) {
        this.groupId = groupId;
      }
      if (!this.memberVisible) {
        this.memberVisible = true;
      }
      this.memberLoading = true;
      var data = {
        phoneOrName: this.memberSearch.phoneOrName,
        nftName: this.memberSearch.nftName,
        pageNum: page ? page : this.memberPaging.pageNum,
        pageSize: this.memberPaging.pageSize,
        groupId: this.groupId
      };
      (0, _chatGroup.userListApi)(data).then(function (res) {
        _this8.memberData.data = res.rows;
        _this8.memberData.total = res.total;
        _this8.memberLoading = false;
      });
    },
    getAdminList: function getAdminList(page, groupId) {
      var _this9 = this;
      if (groupId) {
        this.groupId = groupId;
      }
      this.adminLoading = true;
      var data = {
        pageNum: page ? page : this.adminPaging.pageNum,
        pageSize: this.adminPaging.pageSize
      };
      (0, _chatGroup.getAllGroupMasterApi)(this.groupId, data).then(function (res) {
        _this9.adminData.data = res.rows;
        _this9.adminData.total = res.total;
        _this9.adminDialog = true;
        _this9.adminLoading = false;
      });
    },
    removeAdmin: function removeAdmin(id) {
      var _this10 = this;
      this.$confirm("确定要移除吗?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var loadingInstance = _elementUi.Loading.service();
        (0, _chatGroup.deleteMasterApi)({
          groupId: _this10.groupId,
          userIds: [id]
        }).then(function (res) {
          _this10.$message.success("移除成功");
          _this10.closeDialog("adminDialog", "need");
          _this10.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this10.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function () {});
    },
    handleSelectManageData: function handleSelectManageData(val) {
      this.multipleManage = val.map(function (item) {
        return item.gorupMerId;
      });
    },
    handleSelectMemberData: function handleSelectMemberData(val) {
      this.multipleMember = val;
    },
    handleSelectApplication: function handleSelectApplication(val) {
      this.multipleApplication = val.map(function (item) {
        return item.gorupMerId;
      });
    },
    batchApply: function batchApply(type) {
      this.handleApply(type, this.multipleApplication);
    },
    handleApply: function handleApply(type, ids) {
      var _this11 = this;
      var data = {
        id: this.groupId,
        type: type
      };
      if (Array.isArray(ids)) {
        data.userId = ids;
      } else {
        data.userId = new Array(ids);
      }
      (0, _chatGroup.applyForGroupJoinApi)(data).then(function (res) {
        _this11.$message.success("操作成功");
        _this11.closeDialog("applicationDialog", "need");
      });
    },
    bathAddMember: function bathAddMember() {
      var _this12 = this;
      (0, _chatGroup.saveUserGroupApi)({
        groupMers: this.multipleMember,
        groupId: this.groupId
      }).then(function (res) {
        _this12.$message.success("添加成功");
        _this12.closeDialog("memberVisible", "need");
      });
    },
    addAdmin: function addAdmin() {
      var _this13 = this;
      this.$confirm("确定要添加为群管理吗?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _chatGroup.addMasterApi)({
          userIds: _this13.multipleMember.map(function (item) {
            return item.userId;
          }),
          groupId: _this13.groupId
        }).then(function (res) {
          _this13.$message.success("添加成功");
          _this13.closeDialog("memberVisible", "need");
        });
      }).catch(function () {});
    },
    bathRemoveManage: function bathRemoveManage() {
      this.removeUserGroup(this.multipleManage);
    },
    closeDialog: function closeDialog(ele, needApi) {
      if (needApi) {
        this.getList(1);
      }
      this.groupId = "";
      this.memberPaging.pageNum = 1;
      this.managePaging.pageNum = 1;
      this.applicationPaging.pageNum = 1;
      this.memberSearch = {
        phoneOrName: "",
        nftName: ""
      };
      this.managePhone = "";
      this[ele] = false;
    },
    removeUserGroup: function removeUserGroup(i) {
      var _this14 = this;
      var data = {
        groupId: this.groupId
      };
      if (Array.isArray(i)) {
        data.groupMerIds = i;
      } else {
        data.groupMerIds = new Array(i);
      }
      this.$confirm("\u786E\u8BA4".concat(data.groupMerIds.length > 1 ? "批量" : "", "\u79FB\u51FA\u5417?"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _chatGroup.removeUserGroupApi)(data).then(function (res) {
          _this14.getManageList(1);
          _this14.closeDialog("manageVisible", "need");
          _this14.$message({
            type: "success",
            message: "移出成功!"
          });
        });
      }).catch(function () {});
    }
  }
};