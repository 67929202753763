var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: _vm.source == "send" ? "委托发货协议" : "收款结算单",
        top: "5vh",
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "print-view" }, [
        _vm.collectMoney.show
          ? _c("div", { attrs: { id: "print1" } }, [
              _c("div", { staticClass: "bor" }, [
                _c("div", { staticClass: "top1 item-flex-center" }, [
                  _c("div", { staticClass: "flex-one" }, [
                    _c("div", { staticStyle: { "font-size": "14px" } }, [
                      _vm._v(
                        _vm._s(_vm.collectMoney.data.merConfig.company_name)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.collectMoney.data.merConfig.company_english_name
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "top2" }, [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.source == "send" ? "委托发货协议" : "收款结算账单"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v("INVOICE")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "data item-flex-center" }, [
                  _c("div", { staticClass: "l" }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.collectMoney.data.venue_info.title)),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v("TITLE")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "r" }, [
                    _c("div", [
                      _vm._v(
                        "买受人姓名：" +
                          _vm._s(
                            _vm.collectMoney.data.userVerifyInfo
                              ? _vm.collectMoney.data.userVerifyInfo.real_name
                              : ""
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v("BUYER'S NAME")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "data item-flex-center" }, [
                  _c("div", { staticClass: "l" }, [
                    _c("div", [
                      _vm._v(
                        "号牌：" +
                          _vm._s(
                            _vm.collectMoney.data.numberPlate
                              ? _vm.collectMoney.data.numberPlate.number
                              : ""
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v("PADDLE NO.")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "r" }, [
                    _c("div", [
                      _vm._v(
                        "买受人手机：" +
                          _vm._s(
                            _vm.collectMoney.data.userInfo &&
                              _vm.collectMoney.data.userInfo.phone
                              ? _vm.collectMoney.data.userInfo.phone
                              : ""
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v("BUYER'S PHONE")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tab" },
                  [
                    _c("div", { staticClass: "tr" }, [
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "8%" } },
                        [
                          _c("div", [_vm._v("图录号")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "english" }, [
                            _vm._v("LOT NO."),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "20%" } },
                        [
                          _c("div", [_vm._v("拍品名称")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "english" }, [
                            _vm._v("DESCRIPTION"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "18%" } },
                        [
                          _c("div", [_vm._v("交易日期")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "english" }, [
                            _vm._v("DATE OF SALE"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "12%" } },
                        [
                          _c("div", [_vm._v("落槌价")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "english" }, [
                            _vm._v("HAMMER PRICE"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "12%" } },
                        [
                          _c("div", [_vm._v("酬金")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "english" }, [
                            _vm._v("REMUNERATION"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "20%" } },
                        [
                          _c("div", [_vm._v("证书、其他费用")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "english" }, [
                            _vm._v("CERTIFICATION & OTHER"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "10%" } },
                        [
                          _c("div", [_vm._v("合计")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "english" }, [
                            _vm._v("SUBTOTAL"),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.collectMoney.data.orderList, function (m, i) {
                      return _c(
                        "div",
                        { key: "order" + i, staticClass: "tr" },
                        [
                          _c(
                            "div",
                            { staticClass: "td", staticStyle: { width: "8%" } },
                            [_c("div", [_vm._v(_vm._s(m.auction_info.lot))])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "20%" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    flex: "1",
                                  },
                                },
                                [
                                  m.album
                                    ? _c("div", [
                                        _vm._v(_vm._s(m.album.album_name)),
                                      ])
                                    : _vm._e(),
                                  _c("div", [
                                    _vm._v(_vm._s(m.auction_info.store_name)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "18%" },
                            },
                            [_c("div", [_vm._v(_vm._s(m.create_time))])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "12%" },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(m.product_price, 2)
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "12%" },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(m.commission_price, 2)
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "20%" },
                            },
                            [
                              _c("div", [
                                m.total_postage && m.delivery_type == 1
                                  ? _c("div", [
                                      _vm._v(
                                        "运费：" +
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.total_postage,
                                              2
                                            )
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                m.admin_discount_price
                                  ? _c("div", [
                                      _vm._v(
                                        "优惠：-" +
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.admin_discount_price,
                                              2
                                            )
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "10%" },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.formatPriceNumber(m.pay_price, 2))
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    _vm.collectMoney.data.orderList.length < 4
                      ? _vm._l(
                          5 - _vm.collectMoney.data.orderList.length,
                          function (m, i) {
                            return _c(
                              "div",
                              { key: "order1" + i, staticClass: "tr nodata" },
                              [
                                _c("div", {
                                  staticClass: "td",
                                  staticStyle: { width: "8%" },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "td",
                                  staticStyle: { width: "20%" },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "td",
                                  staticStyle: { width: "18%" },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "td",
                                  staticStyle: { width: "12%" },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "td",
                                  staticStyle: { width: "12%" },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "td",
                                  staticStyle: { width: "20%" },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "td",
                                  staticStyle: { width: "10%" },
                                }),
                              ]
                            )
                          }
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "tr" }, [
                      _c(
                        "div",
                        { staticClass: "td", staticStyle: { width: "8%" } },
                        [
                          _c("div", [
                            _c("div", [_vm._v("总计")]),
                            _vm._v(" "),
                            _c("div", [_vm._v("TOTAL")]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "td", staticStyle: { width: "20%" } },
                        [_c("div")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "td", staticStyle: { width: "18%" } },
                        [_c("div")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "td", staticStyle: { width: "12%" } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.formatPriceNumber(_vm.total_price, 2))
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "td", staticStyle: { width: "12%" } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(
                                  _vm.collectMoney.data.total_commission_price,
                                  2
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "td", staticStyle: { width: "20%" } },
                        [
                          _c("div", [
                            _vm.collectMoney.data.total_postage
                              ? _c("div", [
                                  _vm._v(
                                    "运费：" +
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          _vm.collectMoney.data.total_postage,
                                          2
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.collectMoney.data.total_admin_discount_price
                              ? _c("div", [
                                  _vm._v(
                                    "优惠：-" +
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          _vm.collectMoney.data
                                            .total_admin_discount_price,
                                          2
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "td", staticStyle: { width: "10%" } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(
                                  _vm.collectMoney.data.total_pay_price,
                                  2
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tr" }, [
                      _c(
                        "div",
                        {
                          staticClass: "td",
                          staticStyle: {
                            width: "100%",
                            "justify-content": "flex-start",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c("div", [_vm._v("人民币（大写）")]),
                              _vm._v(" "),
                              _c("div", [_vm._v("CAPITAL IN RMB")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "40px",
                                "font-size": "18px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.total_pay_price_rmb
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tr" }, [
                      _c(
                        "div",
                        {
                          staticClass: "td",
                          staticStyle: { width: "100%", padding: "40px 5px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex-one",
                              staticStyle: { "margin-left": "10px" },
                            },
                            [_vm._v("单位盖章：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-one" }, [
                            _vm._v("开票人："),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-one" }, [
                            _vm._v(
                              "收款人：" +
                                _vm._s(_vm.collectMoney.data.admin_name)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-one" }, [
                            _vm._v("提货人："),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tr" }, [
                      _c(
                        "div",
                        {
                          staticClass: "td",
                          staticStyle: { width: "100%", padding: "0" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bor-l",
                              staticStyle: {
                                "margin-left": "10px",
                                width: "47%",
                              },
                            },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c("div", { staticClass: "name" }, [
                                  _c("div", [_vm._v("账")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("户")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("名")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("称：")]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.merConfig
                                        .company_name
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex" }, [
                                _c("div", { staticClass: "name" }, [
                                  _c("div", [_vm._v("卡")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("号：")]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.merConfig
                                        .bank_number
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex" }, [
                                _c("div", { staticClass: "name" }, [
                                  _c("div", [_vm._v("开")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("户")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("行：")]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.collectMoney.data.merConfig.bank)
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex" }, [
                                _c("div", { staticClass: "name" }, [
                                  _c("div", [_vm._v("开")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("户")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("行")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("地")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("址：")]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.merConfig
                                        .bank_address
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-one bor-r" }, [
                            _vm._v(
                              _vm._s(
                                _vm.collectMoney.data.merConfig.company_address
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-c mt20" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.collectMoney.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "print",
                  rawName: "v-print",
                  value: _vm.printObj2,
                  expression: "printObj2",
                },
              ],
              attrs: { type: "primary" },
            },
            [_vm._v("确认打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }