"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'marketUser',
  props: {
    visibleUser: {
      type: Boolean,
      default: false
    },
    inviteParams: {
      type: Object,
      default: null
    },
    visibleTitle: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      queryParams: {},
      visibleUserFlag: this.visibleUser,
      visibleUserData: {
        data: [],
        total: 0
      }
    };
  },
  created: function created() {
    this.queryParams = this.inviteParams;
    this.getList(1);
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _user.inviteGetInviteListApi)(this.queryParams).then(function (res) {
        _this.visibleUserData.data = res.rows;
        _this.visibleUserData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    sizeChange: function sizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    visibleClose: function visibleClose() {
      this.$emit('visibleClose', false);
    }
  }
};