var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.showUser, title: "选择用户", width: "800px" },
      on: {
        "update:visible": function ($event) {
          _vm.showUser = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "align-items-c" },
        [
          _c("el-input", {
            staticClass: "w300",
            attrs: { placeholder: "输入昵称/手机号" },
            on: { change: _vm.handleClick },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary" },
              on: { click: _vm.handleClick },
            },
            [_vm._v("筛选")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "userId", label: "ID", "min-width": "60" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: scope.row.avatar,
                            "preview-src-list": [scope.row.avatar],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "昵称", "min-width": "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", {
                        domProps: { textContent: _vm._s(row.nickName) },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "真实姓名", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", {
                        domProps: { textContent: _vm._s(row.realName) },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "手机", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(" " + _vm._s(row.userPhone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "80", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.uid != scope.row.userId
                      ? _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.changeUserAction(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        )
                      : _c("span", [_vm._v("已选择")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.userFrom.pageSize,
              "current-page": _vm.userFrom.pageNum,
              layout: "prev, pager, next",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }