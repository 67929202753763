"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _open3dAccount = require("@/api/open3dAccount");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      itemInfo: {},
      dialogVisible: false,
      queryParams: {
        timeType: "",
        key: "",
        orderType: "",
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0,
        money: 0
      },
      timeType: [],
      listLoading: false,
      timeList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    refund: function refund(row) {
      this.itemInfo = row;
      this.dialogVisible = true;
    },
    exportOrder: function exportOrder() {
      var _this = this;
      (0, _open3dAccount.divideLogExportApi)(this.queryParams).then(function (res) {
        _this.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(83);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList("");
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _open3dAccount.divideLogApi)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.tableData.money = res.statistical.count;
        _this2.listLoading = false;
      }).catch(function (_ref) {
        var message = _ref.message;
        _this2.listLoading = false;
      });
    },
    selectChange: function selectChange(tab) {
      this.getList(1);
    },
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.date = e ? this.timeType.join("-") : "";
      this.queryParams.timeType = this.queryParams.date;
      this.getList(1);
    }
  }
};