var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSelectAuction,
            width: "720px",
            "append-to-body": "",
            "close-on-click-modal": false,
            title: "选择" + (_vm.source == "product" ? "实物商品" : "版权授权"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectAuction = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "名称搜索" },
                on: { "on-enter": _vm.searchArticleAction },
                model: {
                  value: _vm.workName,
                  callback: function ($$v) {
                    _vm.workName = $$v
                  },
                  expression: "workName",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.searchArticleAction($event)
                    },
                  },
                },
                [_vm._v("筛选")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "h400 mt20",
              staticStyle: { "border-top": "1px solid #eee" },
            },
            [
              _c(
                "table",
                { staticClass: "spec-table mt20" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("td", { attrs: { width: "30%", align: "left" } }, [
                        _vm._v("作品信息"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "12%", align: "center" } }, [
                        _vm._v("操作"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.resultData, function (m, i) {
                    return _c("tbody", { key: i, staticClass: "list" }, [
                      _c("tr", { staticClass: "border-t" }, [
                        _c("td", { staticClass: "bgWhite" }, [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c("div", { staticClass: "work-img" }, [
                              _c("img", {
                                attrs: {
                                  src: m.coverPicture + "!120a",
                                  alt: "",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "user-info flex-one ml10",
                                staticStyle: { overflow: "hidden" },
                              },
                              [
                                _c("div", { staticClass: "fWeight500" }, [
                                  _vm._v(_vm._s(m.name)),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _vm.selectedAuctionIdList.indexOf(m.id) == -1
                              ? _c("div", [
                                  m.reserveStock > 0 || _vm.showSelect
                                    ? _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#28ab29" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.auctionSelectedAction(
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择")]
                                      )
                                    : _vm._e(),
                                ])
                              : _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          _vm.auctionCancelAction(
                                            $event,
                                            _vm.selectedAuctionIdList.indexOf(
                                              m.id
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消选择")]
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-e mt20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: { "current-change": _vm.changePage },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.size
            ? _c(
                "div",
                {
                  staticClass: "item-flex-center",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr20",
                      attrs: { type: "primary" },
                      on: { click: _vm.cursorSelect },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showSelectAuction = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }