"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.demandApi = exports._onShowDemand = exports._onAudited = exports._getDemandInfo = exports._getContributeList = exports._cancelDemand = void 0;
var _request = _interopRequireDefault(require("./request"));
var demandApi = exports.demandApi = {
  // 获取需求列表
  _getDemandList: function _getDemandList(data) {
    return _request.default.get("/open3d/product/sys/demand/list", data);
  },
  // 获取需求分类
  getFirstCategoriesApi: function getFirstCategoriesApi() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _request.default.get("/open3d/product/app/category/getFirstCategories", data);
  }
};

// 获取需求详情
var _getDemandInfo = exports._getDemandInfo = function _getDemandInfo(id) {
  return _request.default.get("/open3d/product/sys/demand/info/".concat(id));
};

// 获取投稿人列表
var _getContributeList = exports._getContributeList = function _getContributeList(id) {
  return _request.default.get("/open3d/product/sys/demand/getDemandMemberList/".concat(id));
};

// 取消需求
var _cancelDemand = exports._cancelDemand = function _cancelDemand(id) {
  return _request.default.post("/open3d/product/sys/demand/cancel/".concat(id));
};

// 审核通过
var _onAudited = exports._onAudited = function _onAudited(data) {
  return _request.default.post("/open3d/product/sys/demand/audit", data);
};

// 招标公开/隐藏
var _onShowDemand = exports._onShowDemand = function _onShowDemand(data) {
  return _request.default.post("/open3d/product/sys/demand/switchShow", data);
};