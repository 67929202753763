"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _marketing = require("@/api/marketing");
var _option = require("./option");
var _collectionsLine = _interopRequireDefault(require("./collectionsLine"));
var _transactionLineOne = _interopRequireDefault(require("./transactionLineOne"));
var _transactionLineTwo = _interopRequireDefault(require("./transactionLineTwo"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Statistics",
  components: {
    collectionsLine: _collectionsLine.default,
    transactionLineOne: _transactionLineOne.default,
    transactionLineTwo: _transactionLineTwo.default
  },
  data: function data() {
    return {
      types: "collections",
      option: _option.option,
      timeList: [{
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }],
      queryParams: {
        timeType: "today"
      },
      timeType: [],
      collectionsLineData: null,
      transactionLineOneData: null,
      transactionLineTwoData: null,
      sortTypeList: [{
        id: 1,
        label: "寄售数量"
      }, {
        id: 2,
        label: "成交次数"
      }, {
        id: 3,
        label: "成交金额"
      }],
      listLoading: false,
      bankParams: {
        pageSize: 20,
        pageNum: 1,
        timeType: "",
        sortType: 1
      },
      bankData: {
        data: [],
        total: 0
      },
      viewParams: {
        timeType: "today"
      },
      viewData: {},
      productParams: {
        productName: "",
        timeType: "today"
      },
      productData: {},
      tradeParams: {
        timeType: "today"
      },
      tradeData: {}
    };
  },
  created: function created() {
    this.marketReportRankApiMethod(1);
    this.marketReportViewApiMethod();
    this.marketReportProductApiMethod();
    this.marketReportTradeApiMethod();
  },
  computed: {
    tradeAmount: function tradeAmount() {
      var a = (((this.viewData.tradeAmount / this.viewData.tradeAmountIncrease).toFixed(2) - 1) * 100).toFixed(2);
      return a;
    },
    tradeVolumeIncrease: function tradeVolumeIncrease() {
      var a = (((this.viewData.tradeVolume / this.viewData.tradeVolumeIncrease).toFixed(2) - 1) * 100).toFixed(2);
      return a;
    },
    pendingAmountIncrease: function pendingAmountIncrease() {
      var a = (((this.viewData.pendingAmount / this.viewData.pendingAmountIncrease).toFixed(2) - 1) * 100).toFixed(2);
      return a;
    },
    pendingVolumeIncrease: function pendingVolumeIncrease() {
      var a = (((this.viewData.pendingVolume / this.viewData.pendingVolumeIncrease).toFixed(2) - 1) * 100).toFixed(2);
      return a;
    },
    pendingUserIncrease: function pendingUserIncrease() {
      var a = (((this.viewData.pendingUser / this.viewData.pendingUserIncrease).toFixed(2) - 1) * 100).toFixed(2);
      return a;
    },
    pendingClinchRateIncrease: function pendingClinchRateIncrease() {
      var a = (((this.viewData.pendingClinchRate / this.viewData.pendingClinchRateIncrease).toFixed(2) - 1) * 100).toFixed(2);
      return a;
    }
  },
  methods: {
    goBankSearch: function goBankSearch() {
      this.marketReportRankApiMethod(1);
    },
    onchangeBankTime: function onchangeBankTime(val) {
      this.bankParams.timeType = val[0] + "-" + val[1];
      this.marketReportRankApiMethod();
    },
    onchangeViewTime: function onchangeViewTime(val) {
      this.viewParams.timeType = val[0] + "-" + val[1];
      this.marketReportViewApiMethod();
    },
    onchangeProductTime: function onchangeProductTime(val) {
      this.productParams.timeType = val[0] + "-" + val[1];
      this.marketReportProductApiMethod();
    },
    onchangeTradeTime: function onchangeTradeTime(val) {
      this.tradeParams.timeType = val[0] + "-" + val[1];
      this.marketReportTradeApiMethod();
    },
    selectViewChange: function selectViewChange(tab) {
      this.viewParams.timeType = tab;
      this.marketReportViewApiMethod();
    },
    selectProductChange: function selectProductChange(tab) {
      this.productParams.timeType = tab;
      this.marketReportProductApiMethod();
    },
    selectTradeChange: function selectTradeChange(tab) {
      this.tradeParams.timeType = tab;
      this.marketReportTradeApiMethod();
    },
    marketReportRankApiMethod: function marketReportRankApiMethod(num) {
      var _this = this;
      this.listLoading = true;
      this.bankParams.pageNum = num || this.bankParams.pageNum;
      (0, _marketing.marketReportRankApi)(this.bankParams).then(function (res) {
        _this.bankData.data = res.rows;
        _this.bankData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    marketReportViewApiMethod: function marketReportViewApiMethod() {
      var _this2 = this;
      (0, _marketing.marketReportViewApi)(this.viewParams).then(function (res) {
        _this2.viewData = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    marketReportProductApiMethod: function marketReportProductApiMethod() {
      var _this3 = this;
      (0, _marketing.marketReportProductApi)(this.productParams).then(function (res) {
        _option.option[0].xAxis.data = [];
        _option.option[0].series[0].data = [];
        _option.option[0].series[1].data = [];
        res.data.forEach(function (element) {
          _option.option[0].xAxis.data.push(element.date.substring(element.date.length - 5, element.date.length));
          _option.option[0].series[0].data.push(element.pendingAmount);
          _option.option[0].series[1].data.push(element.cancelAmount);
        });
        _this3.collectionsLineData = _option.option[0];
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    marketReportTradeApiMethod: function marketReportTradeApiMethod() {
      var _this4 = this;
      (0, _marketing.marketReportTradeApi)(this.tradeParams).then(function (res) {
        _option.option[1].xAxis.data = [];
        _option.option[2].xAxis.data = [];
        _option.option[1].series[0].data = [];
        _option.option[1].series[1].data = [];
        _option.option[1].series[2].data = [];
        _option.option[2].series[0].data = [];
        _option.option[2].series[1].data = [];
        _option.option[2].series[2].data = [];
        res.data.forEach(function (element) {
          _option.option[1].xAxis.data.push(element.date.substring(element.date.length - 5, element.date.length));
          _option.option[2].xAxis.data.push(element.date.substring(element.date.length - 5, element.date.length));
          _option.option[1].series[0].data.push(element.pendingVolume);
          _option.option[1].series[1].data.push(element.clinchVolume);
          _option.option[1].series[2].data.push(element.clinchVolume != 0 ? (element.pendingVolume / element.clinchVolume * 100).toFixed(2) : 0);
          _option.option[2].series[0].data.push(element.pendingAmount);
          _option.option[2].series[1].data.push(element.clinchAmount);
          _option.option[2].series[2].data.push(element.clinchAmount != 0 ? (element.pendingAmount / element.clinchAmount * 100).toFixed(2) : 0);
        });
        _this4.transactionLineOneData = _option.option[1];
        _this4.transactionLineTwoData = _option.option[2];
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    }
  }
};