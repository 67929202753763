"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var cmsRouter = {
  path: "".concat(_settings.roterPre, "/cms"),
  name: 'cms',
  meta: {
    icon: 'dashboard',
    title: '内容'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'article',
    name: 'article',
    meta: {
      title: '文章管理',
      noCache: true
    },
    children: [{
      path: 'list',
      name: 'article',
      meta: {
        title: '文章管理',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/cms/article/index'));
        });
      }
    }, {
      path: 'cate',
      name: 'articleCategory',
      meta: {
        title: '文章分类',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/cms/article/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/cms/articleCategory/index'));
        });
      }
    }, {
      path: 'tag',
      name: 'articleTag',
      meta: {
        title: '文章标签',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/cms/article/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/cms/articleTag/index'));
        });
      }
    }],
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cms/index'));
      });
    }
  }, {
    path: 'article/addArticle/:id?',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cms/addArticle/index'));
      });
    },
    name: 'EditArticle',
    meta: {
      title: '文章添加',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/cms/article/list")
    },
    hidden: true
  }]
};
var _default = exports.default = cmsRouter;