"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { mainDataApi } from "@/api/dashboard";
var _default2 = exports.default = {
  props: {
    params: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  watch: {
    params: {
      handler: function handler(val) {
        if (Object.keys(val).length) {
          this.mainData = val;
          this.mainData.todaySalesStatistics = Number(val.todaySalesStatistics);
        }
      },
      deep: true
    }
  },
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      value1: "",
      value2: "",
      decline: 1,
      mainData: {
        todaySalesStatistics: 0
      },
      today: {},
      lastWeekRate: {},
      yesterday: {}
    };
  },
  components: {
    CountTo: _vueCountTo.default
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.$emit("handleSetLineChartData", type);
    }
  }
};