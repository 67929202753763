"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _open3DOrder = require("@/api/open3DOrder");
var _open3dAccount = require("@/api/open3dAccount");
var _finance = require("@/api/finance");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      selectInfo: {},
      recordList: [],
      curType: "",
      //1审核 2 汇款记录 3 审核记录
      verify: {
        price: 0,
        remark: "",
        status: 1
      },
      dialogVisible: false,
      queryParams: {
        type: "",
        payType: "",
        mainBodyId: "",
        timeType: "",
        key: "",
        payOrderSn: "",
        pageNum: 1,
        pageSize: 20,
        flagChnl: ""
      },
      tableData: {
        data: [],
        total: 0
      },
      timeType: [],
      payList: [],
      listLoading: false,
      timeList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }],
      uid: '',
      showdetail: false,
      componseList: [],
      businessTypeList: [{
        type: 1,
        name: "作品订单"
      }, {
        type: 3,
        name: "版权订单"
      }, {
        type: 4,
        name: "支付gas费"
      }, {
        type: 5,
        name: '作品定制'
      }, {
        type: 6,
        name: '图片建模'
      }, {
        type: 7,
        name: '共创会员年费'
      }, {
        type: 8,
        name: 'SDK订单'
      }, {
        type: 9,
        name: '用户余额充值'
      }, {
        type: 10,
        name: '流量充值'
      }, {
        type: 11,
        name: '产权付费'
      }, {
        type: 12,
        name: '模型训练'
      }, {
        type: 13,
        name: '作品订单（含实物）'
      }, {
        type: 14,
        name: '作品定制'
      }, {
        type: 15,
        name: '上链存证'
      }, {
        type: 16,
        name: '直通车会员费用'
      }],
      formState: {},
      remVisible: false
    };
  },
  mounted: function mounted() {
    this.getPayTypeList();
    this.gtComponseList();
    this.getList();
    // if(this.$route.query.payTypeId){
    //   this.queryParams.payTypeId = this.$route.query.payTypeId;
    // }
  },
  // watch: {
  //   '$route': {
  //     handler(to,from)  {
  //       if(to.query.payTypeId != from.query.payTypeId){
  //         this.queryParams.payTypeId = to.query.payTypeId;
  //         this.getList(1);
  //       }
  //     }
  //   }
  // },
  methods: {
    onUpdRemark: function onUpdRemark(row) {
      console.log(row);
      this.formState = JSON.parse(JSON.stringify(row));
      this.remVisible = true;
    },
    onSubmit: function onSubmit() {
      var _this = this;
      (0, _open3dAccount.updateRemarkApi)(this.formState).then(function (res) {
        _this.remVisible = false;
        _this.getList();
      });
    },
    gtComponseList: function gtComponseList() {
      var _this2 = this;
      (0, _finance.getComponseListApi)().then(function (res) {
        _this2.componseList = res.data;
      });
    },
    selectChange: function selectChange(tab) {
      this.getList(1);
    },
    exportOrder: function exportOrder() {
      var _this3 = this;
      (0, _open3dAccount.exportApi)(this.queryParams).then(function (res) {
        _this3.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(71);
    },
    orderIdRecord: function orderIdRecord(row) {
      var _this4 = this;
      (0, _open3dAccount.auditLogApi)({
        orderId: row.orderNo
      }).then(function (res) {
        console.log(res);
        _this4.curType = "3";
        _this4.selectInfo = row;
        _this4.recordList = res.data;
        _this4.dialogVisible = true;
      });
    },
    confirm: function confirm() {
      var _this5 = this;
      if (this.curType === "1") {
        var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({
          orderId: this.selectInfo.orderNo
        }, this.verify), {}, {
          payTypeId: this.selectInfo.payTypeId
        });
        (0, _open3dAccount.confirmApi)(data).then(function (res) {
          _this5.$message.success("提交成功");
          _this5.dialogVisible = false;
          _this5.getList(1);
          _this5.curType = "";
          _this5.selectInfo = {};
        });
      } else {
        this.dialogVisible = false;
        this.curType = "";
        this.selectInfo = {};
      }
    },
    toSelectInfo: function toSelectInfo(type, row) {
      this.curType = type;
      this.selectInfo = row;
      this.verify.price = row.realPrice;
      this.dialogVisible = true;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList("");
    },
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _open3dAccount.getListApi)(this.queryParams).then(function (res) {
        _this6.tableData.data = res.rows;
        _this6.tableData.total = res.total;
        _this6.listLoading = false;
      }).catch(function (_ref) {
        var message = _ref.message;
        _this6.listLoading = false;
      });
    },
    getPayTypeList: function getPayTypeList() {
      var _this7 = this;
      (0, _open3DOrder.getPayType)().then(function (res) {
        // console.log(res)
        _this7.payList = res.data;
      }).catch(function (res) {});
    },
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.date = e ? this.timeType.join("-") : "";
      this.queryParams.timeType = this.queryParams.date;
      this.getList(1);
    },
    // 详情
    onDetails: function onDetails(id) {
      if (!id) return false;
      this.uid = id;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    }
  }
};