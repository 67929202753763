"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      activeName: "first",
      hasSendAndroid: false,
      hasSendIos: false,
      system: {
        name: "",
        template: "",
        hasSendMessage: false
      },
      wechat: {
        id: "",
        hasSendWeChatOfficial: false,
        tempKey: "",
        weChatOfficialTemplateId: "",
        content: ""
      },
      sms: {
        hasSendSms: false,
        context: "",
        title: ""
      }
    };
  },
  mounted: function mounted() {
    this.getInfo();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      (0, _setting.configInfoApi)(this.$route.query.id).then(function (res) {
        console.log(res);
        var _res$data = res.data,
          name = _res$data.name,
          template = _res$data.template,
          hasSendMessage = _res$data.hasSendMessage,
          hasSendSms = _res$data.hasSendSms,
          hasSendWeChatOfficial = _res$data.hasSendWeChatOfficial,
          wechatTemplate = _res$data.wechatTemplate,
          weChatOfficialTemplateId = _res$data.weChatOfficialTemplateId,
          smsTemplateInfo = _res$data.smsTemplateInfo,
          hasSendIos = _res$data.hasSendIos,
          hasSendAndroid = _res$data.hasSendAndroid;
        _this.hasSendAndroid = hasSendAndroid;
        _this.hasSendIos = hasSendIos;
        _this.system.name = name;
        _this.system.template = template;
        _this.system.hasSendMessage = hasSendMessage;
        _this.wechat.id = wechatTemplate.id;
        _this.wechat.tempKey = wechatTemplate.tempKey;
        _this.wechat.weChatOfficialTemplateId = weChatOfficialTemplateId;
        _this.wechat.hasSendWeChatOfficial = hasSendWeChatOfficial;
        _this.wechat.content = wechatTemplate.content;
        _this.sms.hasSendSms = hasSendSms;
        _this.sms.context = smsTemplateInfo.context;
        _this.sms.title = smsTemplateInfo.title;
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      var data = {
        hasSendSms: this.sms.hasSendSms,
        hasSendWeChatOfficial: this.wechat.hasSendWeChatOfficial,
        hasSendMessage: this.system.hasSendMessage,
        hasSendIos: this.hasSendIos,
        hasSendAndroid: this.hasSendAndroid,
        id: this.$route.query.id
      };
      (0, _setting.configUpdateApi)(data).then(function (res) {
        _this2.$router.back();
      });
    }
  }
};