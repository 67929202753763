"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    showSelect: {
      // 库存为空也可选择
      type: Boolean,
      default: false
    },
    needPointsDeductionType: {
      //神兽选择不需要传
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem("identityid") === "11",
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      workName: "",
      searchLoading: false,
      announcer: "",
      searchGoodsId: "",
      source: ""
    };
  },
  filters: {
    formatIsStart: function formatIsStart(val) {
      var res = "";
      switch (val) {
        case 0:
          res = "待开始";
          break;
        case 1:
          res = "进行中";
          break;
        case 2:
          res = "已结束";
          break;
        default:
          break;
      }
      return res;
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow(s) {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (s) {
        this.source = s;
      }
      if (this.resultData.length === 0) {
        this.loadArticle();
      }
      this.showSelectAuction = true;
    },
    searchArticleAction: function searchArticleAction() {
      this.queryParams.name = this.workName;
      this.queryParams.pageNum = 1;
      this.loadArticle();
    },
    loadArticle: function loadArticle(fun) {
      var _this = this;
      var params = this.queryParams;
      if (this.needPointsDeductionType) {
        params.pointsDeductionType = 1;
      }
      params.status = 1;
      var action = _marketing.synthesisListApi;
      if (this.source === "product") {
        action = _marketing.productList;
      }
      action(params).then(function (res) {
        _this.resultData = res.rows;
        _this.total = res.total;
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      console.log(info);
      var data = {
        name: info.activeName,
        id: info.id,
        p_id: info.id,
        image: info.pictureUrl,
        productId: info.id
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.size === "1") {
        this.cursorSelect();
      }
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit("selectMessage", {
        type: "nft",
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.pageSize = size;
      this.queryParams.pageNum = 1;
      this.loadArticle();
    },
    changePage: function changePage(page) {
      this.queryParams.pageNum = page;
      this.loadArticle();
    }
  }
};