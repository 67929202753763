import { render, staticRenderFns } from "./saasPro.vue?vue&type=template&id=684d2b6b&scoped=true"
import script from "./saasPro.vue?vue&type=script&scoped=true&lang=js"
export * from "./saasPro.vue?vue&type=script&scoped=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684d2b6b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/java_admin_Rm9R/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('684d2b6b')) {
      api.createRecord('684d2b6b', component.options)
    } else {
      api.reload('684d2b6b', component.options)
    }
    module.hot.accept("./saasPro.vue?vue&type=template&id=684d2b6b&scoped=true", function () {
      api.rerender('684d2b6b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/saasCloud/productConfig/saasPro.vue"
export default component.exports