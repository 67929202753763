"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find-index");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _product = require("@/api/product");
var _nft = require("@/api/nft");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AddLock',
  filters: {
    formatProductType: function formatProductType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '数字藏品';
          break;
        case 2:
          res = '数字盲盒';
          break;
        case 3:
          res = '专辑';
          break;
        default:
          break;
      }
      return res;
    }
  },
  data: function data() {
    var _this = this;
    return {
      formValidate: {
        appointmentName: '',
        appointmentProducts: [],
        appointmentMembers: [],
        //导入数据,
        isToMessage: [],
        toSendTime: ''
      },
      ruleValidate: {
        appointmentName: [{
          required: true,
          message: '请输入快照名称',
          trigger: 'blur'
        }]
      },
      tableData: {
        data: [],
        total: 0
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          if (_this.formValidate.snapshotRange == 3) {
            return time.getTime() < Date.now() || time.getTime() > Date.now() + 30 * 24 * 3600 * 1000;
          }
          return time.getTime() < Date.now();
        }
      },
      listLoading: false,
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      productForm: {
        types: 1,
        pageNum: 1,
        pageSize: 20
      },
      productList: {
        data: [],
        total: 0
      },
      visible: false,
      chooseList: [],
      visibleTitle: '',
      uploadFlag: false
    };
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import";
    }
  },
  created: function created() {
    if (this.$route.query.id) {
      this.getBoxDetail(this.$route.query.id);
    }
  },
  methods: {
    productDelete: function productDelete(row) {
      var index = this.formValidate.appointmentProducts.findIndex(function (res) {
        return res.id == row.id;
      });
      if (index != -1) {
        this.formValidate.appointmentProducts.splice(index, 1);
      }
    },
    // 下载
    downLoad: function downLoad() {
      window.open('https://cloud-java-test.oss-cn-shanghai.aliyuncs.com/tmp/appendStock.xlsx');
    },
    uploadRequest: function uploadRequest(file) {
      this.uploadFlag = false;
      var formDataFile = new FormData();
      formDataFile.append('file', file.file);
      this.snapshotExcelApiMethod(formDataFile, true);
    },
    snapshotExcelApiMethod: function snapshotExcelApiMethod(formDataFile) {
      var _this2 = this;
      (0, _product.lockImport)(formDataFile, {
        headers: {
          'Content-Type': 'form-data'
        }
      }).then(function (res) {
        _this2.formValidate.appointmentMembers = res.data.appointmentMembers;
        _this2.formValidate.num = res.data.memberNum;
        _this2.uploadFlag = true;
      }).catch(function (msg) {
        // loading.close();
      });
    },
    getBoxDetail: function getBoxDetail(id) {
      var _this3 = this;
      (0, _product.lockdetail)(id).then(function (res) {
        _this3.formValidate = res.data;
        _this3.formValidate.appointmentProducts.forEach(function (item) {
          item.coverPicture = item.pictureUrl;
        });
      });
    },
    submitForm: function submitForm() {
      var _this4 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.formValidate));
          var action = _product.lockadd;
          if (_this4.$route.query.id) {
            data.id = _this4.$route.query.id;
            action = _product.lockEdit;
          }
          action(data).then(function (res) {
            _this4.$message.success('操作成功');
            _this4.$router.back();
          }).catch(function (res) {});
        }
      });
    },
    backAction: function backAction() {
      this.$router.go(-1);
    },
    addProducts: function addProducts(type) {
      this.visible = true;
      this.productForm.types = type;
      this.visibleClose();
      this.goSearch();
    },
    goSearch: function goSearch() {
      if (this.productForm.types == 1) {
        this.visibleTitle = '添加数字藏品';
        this.nftCollectionListApiMethod();
      } else if (this.productForm.types == 2) {
        this.visibleTitle = '添加数字盲盒';
        this.productBoxListApiMethod();
      } else if (this.productForm.types == 3) {
        this.visibleTitle = '添加专辑';
        this.nftCollectionAlbumListApiMethod();
      }
    },
    productFormSizeChange: function productFormSizeChange(val) {
      this.productForm.pageSize = val;
      this.goSearch();
    },
    productFormpageChange: function productFormpageChange(val) {
      this.productForm.pageNum = val;
      this.goSearch();
    },
    nftCollectionListApiMethod: function nftCollectionListApiMethod() {
      var _this5 = this;
      var jsonp = JSON.parse(JSON.stringify(this.productForm));
      jsonp.status = 1;
      jsonp.startTime = this.$formatData(new Date());
      (0, _nft.nftCollectionListApi)(jsonp).then(function (res) {
        _this5.productList.data = res.rows;
        _this5.productList.total = res.total;
        _this5.productList.data.forEach(function (element) {
          element.productType = 1;
          element.choose = false;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    productBoxListApiMethod: function productBoxListApiMethod() {
      var _this6 = this;
      var jsonp = JSON.parse(JSON.stringify(this.productForm));
      jsonp.status = 1;
      (0, _nft.productBoxListApi)(jsonp).then(function (res) {
        _this6.productList.data = res.rows;
        _this6.productList.total = res.total;
        _this6.productList.data.forEach(function (element) {
          element.productType = 2;
          element.choose = false;
          element.coverPicture = element.image;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    nftCollectionAlbumListApiMethod: function nftCollectionAlbumListApiMethod() {
      var _this7 = this;
      (0, _nft.nftCollectionAlbumListApi)(this.productForm).then(function (res) {
        _this7.productList.data = res.rows;
        _this7.productList.total = res.total;
        _this7.productList.data.forEach(function (element) {
          element.productType = 3;
          element.choose = false;
          element.coverPicture = element.image;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    chooseRow: function chooseRow(row, index) {
      var obj = {
        productType: row.productType,
        productName: row.productName,
        productId: row.productId || '',
        relationId: row.productId || '',
        coverPicture: row.coverPicture,
        id: row.id,
        objectId: row.id,
        image: row.coverPicture,
        name: row.productName,
        buyTime: row.startTime,
        checkType: 0,
        stock: row.stock
      };
      if (!row.choose) {
        this.chooseList.push(obj);
      } else {
        var _index = this.chooseList.findIndex(function (res) {
          return res.productId == row.id;
        });
        if (_index != -1) {
          this.chooseList.splice(_index, 1);
        }
      }
      // console.log('chooseList', this.chooseList)
      this.productList.data[index].choose = !row.choose;
      this.confirmAction();
      this.$forceUpdate();
    },
    confirmAction: function confirmAction() {
      this.formValidate.appointmentProducts = this.formValidate.appointmentProducts.concat(this.chooseList);
      var arr = this.formValidate.appointmentProducts.filter(function (element, index, self) {
        return self.findIndex(function (el) {
          return el.productId == element.productId;
        }) === index; // 如果是根据name去重就江id改为name
      });
      this.formValidate.appointmentProducts = arr;
      this.visible = false;
    },
    visibleClose: function visibleClose() {
      this.chooseList = [];
    }
  }
};