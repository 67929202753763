"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _user = require("@/api/user");
var _merchant = require("@/api/merchant");
var _index4 = require("@/utils/index");
var _workNumberTmp = _interopRequireDefault(require("@/components/workNumberTmp.vue"));
var _ChangeUser = _interopRequireDefault(require("@/components/ChangeUser"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import userdetail from "../list/userdetail";
// import commisionOrder from './commisonOrder.vue';
var _default = exports.default = {
  components: {
    WorkNumberTmp: _workNumberTmp.default,
    ChangeUser: _ChangeUser.default
  },
  data: function data() {
    var validateSharingRatio = function validateSharingRatio(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入自动发布分成比例"));
      } else if (isNaN(value)) {
        callback(new Error("分成比例必须为数字值"));
      } else if (value < 0) {
        callback(new Error("分成比例最小设置0%"));
      } else if (value > 100) {
        callback(new Error("分成比例最大设置100%"));
      } else {
        callback();
      }
    };
    return {
      formatPriceNumber: _index4.formatPriceNumber,
      parseTime: _index4.parseTime,
      orderChartType: {},
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        name: "",
        phone: "",
        pageNum: 1,
        pageSize: 10,
        identityId: "",
        status: ""
      },
      editIssuerData: {
        show: false
      },
      rules: {
        name: [{
          required: true,
          message: "请输入作者名称",
          trigger: "blur"
        }],
        idCard: [{
          required: true,
          message: "请输入作者身份证",
          trigger: "blur"
        }, {
          pattern: /^[0-9a-zA-Z]{1,18}$/,
          message: "作者身份证输入不正确"
        }],
        phone: [{
          required: true,
          message: "请输入联系人电话",
          trigger: "blur"
        }, {
          pattern: /^\d{1,11}$/,
          message: "作者手机号输入不正确"
        }],
        sharingRatio: [{
          validator: validateSharingRatio,
          trigger: "blur"
        }]
      },
      bankList: [],
      userList: [],
      uid: "",
      showdetail: false,
      chkName: "",
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      reasonIds: [],
      reason: "",
      showRefuse: false,
      showImg: false,
      showImgUrl: "",
      fileVisible: false,
      fileShow: null,
      identityList: []
    };
  },
  created: function created() {
    var _this = this;
    this.getList(1);
    (0, _merchant.getList)().then(function (res) {
      _this.identityList = res.rows;
    });
  },
  methods: {
    showInfoAction: function showInfoAction(m) {
      if (m.type.indexOf("image/") == 0) {
        this.showImgUrl = m.file;
        this.showImg = true;
      } else {
        window.open(m.file);
      }
    },
    showVideoAction: function showVideoAction(m) {
      // console.log();
      this.fileShow = "https://cdn.yunzongbu.cn/3d/3d.html?gltf=".concat(m.modelFile);
      this.fileVisible = true;
    },
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? "" : name;
      this.changeType(!(this.chkName === ""));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = "dan";
        }
      } else {
        this.chkName = "";
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.tableFrom.pageNum);
      if (this.chkName === "dan") {
        this.checkedPage.push(this.tableFrom.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this2 = this;
      var ids = [];
      this.tableData.data.forEach(function (v) {
        if (v.status == 1) {
          ids.push(v.id);
        }
      });
      if (this.chkName === "duo") {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === "dan") {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this2.checkedIds.indexOf(id);
          if (index === -1) {
            _this2.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this2.checkedIds.indexOf(id);
          if (index > -1) {
            _this2.checkedIds.splice(index, 1);
          }
        });
      }
    },
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === "duo") {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
        }
      } else {
        if (this.chkName === "duo") {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _merchant.getReviewList)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList("");
    },
    delAction: function delAction(m) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u4F5C\u8005?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _user.authorDelApi)(m.id).then(function () {
          _this4.$message.success("操作成功");
          _this4.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showWorkAction: function showWorkAction(m) {
      this.$refs.workNumberTmp.loadInit(m.id, 2);
    },
    showCommisionAction: function showCommisionAction(m) {
      this.$refs.commisionOrder.loadInit(m.id, 2);
    },
    showEditAction: function showEditAction(m, d) {
      var _this5 = this;
      this.editIssuerData = {
        show: true,
        type: m.type,
        id: m.id,
        data: d || null
      };
      (0, _merchant.getReviewDetail)(m.id).then(function (res) {
        res.data.forEach(function (item) {
          if (item.key == 5 || item.key == 15 || item.key == 23) {
            item.image = item.value;
          } else if (item.key == 13) {
            if (item.value) {
              var arrimage = item.value.split(",");
              item.image = arrimage[0];
              item.infoImage = arrimage[1];
            }
          } else if (item.key == 8 || item.key == 11 || item.key == 19 || item.key == 20) {
            if (item.value) {
              item.infovalue = JSON.parse(item.value);
            }
          }
        });
        _this5.editIssuerData.data = res.data;
      }).catch(function () {});
    },
    checkFinishAction: function checkFinishAction(row, id, val) {
      var _this6 = this;
      this.$confirm("\u786E\u8BA4".concat(val === 1 ? "通过" : "拒绝", "\u8BE5\u7533\u8BF7?"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _merchant.reviewMerchant)({
          id: id,
          type: val,
          reason: _this6.reason
        }).then(function () {
          _this6.showRefuse = false;
          _this6.$message.success("操作成功");
          _this6.checkedIds = [];
          _this6.allCheck = false;
          if (_this6.editIssuerData.show) {
            _this6.showEditAction(_this6.editIssuerData, _this6.editIssuerData.data);
          }
          _this6.getList();
        }).catch(function (_ref) {
          var message = _ref.message;
        });
      }).catch(function () {});
    },
    refuseAction: function refuseAction(ids) {
      this.reasonIds = ids;
      this.reason = "";
      this.showRefuse = true;
    },
    batchTgAction: function batchTgAction(val) {
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择创作者");
      }
      this.checkFinishAction("", JSON.parse(JSON.stringify(this.checkedIds)), 2);
    },
    batchJjAction: function batchJjAction() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择创作者");
      }
      this.reasonIds = JSON.parse(JSON.stringify(this.checkedIds));
      this.reason = "";
      this.showRefuse = true;
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.memberId;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    modalPicTap: function modalPicTap(tit, key, key1) {
      var _this7 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this7.editIssuerData.image = img[0];
        } else {
          _this7.editIssuerData.image = img[0];
        }
        _this7.$forceUpdate();
      }, tit);
    },
    delImg: function delImg(key, key1) {
      if (key1) {
        this.editIssuerData.image = "";
      } else {
        this.editIssuerData.image = "";
      }
      this.$forceUpdate();
    }
  }
};