"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts/core"));
var _index = _interopRequireDefault(require("../mixin/index"));
var _order = require("@/api/order");
//
//
//
//

// import echarts from "echarts";

// // import { PieChart } from "echarts/charts";
// import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
// echarts.use([PieChart, CanvasRenderer]);
require("echarts/theme/macarons"); // echarts theme
var _default = exports.default = {
  mixins: [_index.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    amount: {
      type: Boolean,
      default: true
    },
    date: {
      type: String,
      default: "lately7"
    }
  },
  data: function data() {
    return {
      chart: null,
      newData: "",
      oldData: "",
      Comment: [],
      orderCustomer: []
    };
  },
  watch: {
    amount: {
      deep: true,
      handler: function handler(val) {
        this.amount = val;
        this.getTurnoverRatio();
      }
    },
    date: {
      deep: true,
      handler: function handler(val) {
        this.date = val;
        this.getTurnoverRatio();
      }
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {});
    // this.getTurnoverRatio();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    getTurnoverRatio: function getTurnoverRatio() {
      var that = this;
      (0, _order.reportStatistics)({
        timeType: this.date
      }).then(function (res) {
        if (res.code === 200) {
          // that.orderCustomer = res.data;
          var dataList = res.data.map(function (item, index) {
            return item.orderNum;
          });
          var moneyList = res.data.map(function (y, i) {
            return y.payPrice;
          });
          var dateList = res.data.map(function (s, q) {
            return s.date;
          });
          that.chart = echarts.init(that.$el, "macarons");
          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999"
                }
              }
            },
            grid: {
              left: '1%',
              // 默认10%
              right: '0%',
              // 默认10%
              bottom: '8%',
              // 默认60
              containLabel: true
            },
            toolbox: {
              feature: {
                // dataView: { show: true, readOnly: false },
                // magicType: { show: true, type: ["line", "bar"] },
                // restore: { show: true },
                // saveAsImage: { show: true }
              }
            },
            legend: {
              data: ["金额", "订单数"]
            },
            xAxis: [{
              type: "category",
              data: dateList,
              axisLabel: {
                interval: 0,
                rotate: 30
              },
              name: '时间',
              axisPointer: {
                type: "shadow"
              }
            }],
            yAxis: [{
              type: "value",
              name: "金额/元"
              // axisLabel: {
              //   formatter: "{value} ml"
              // }
            }, {
              type: "value",
              name: "数量/单"
              // axisLabel: {
              //   formatter: "{value} °C"
              // }
            }],
            series: [{
              name: "金额",
              type: "bar",
              tooltip: {
                valueFormatter: function valueFormatter(value) {
                  return value + " 元";
                }
              },
              data: moneyList
            }, {
              name: "订单数",
              type: "line",
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function valueFormatter(value) {
                  return value + "单";
                }
              },
              data: dataList
            }]
          };
          that.chart.setOption(option);
        }
      }).catch(function (res) {
        that.$message.error(res.message);
      });
      //       })
    }
  }
};