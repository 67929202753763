"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _demand = require("@/api/demand");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["demandInfoId"],
  data: function data() {
    return {
      contributeList: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.demandInfoId.id && (0, _demand._getContributeList)(this.demandInfoId.id).then(function (res) {
      _this.contributeList = res.data;
    }).catch(function (err) {
      console.log(err);
    });
  }
};