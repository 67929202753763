"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "FileList",
  data: function data() {
    return {
      fileVisible: false,
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 10,
        type: ''
      },
      type: ''
    };
  },
  filters: {
    formatType: function formatType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '退款单';
          break;
        case 2:
          res = '寄售订单';
          break;
        case 3:
          res = '数字盲盒';
          break;
        case 4:
          res = '数藏订单';
          break;
        case 5:
          res = '寄售明细';
          break;
        case 6:
          res = '获得记录';
          break;
        case 7:
          res = '积分明细';
          break;
        case 8:
          res = '空投领取详情';
          break;
        case 9:
          res = '快照名单';
          break;
        case 10:
          res = '上链明细';
          break;
        case 13:
          res = '使用记录';
          break;
        case 14:
          res = '销毁记录';
          break;
        case 17:
          res = '转赠记录';
          break;
        case 20:
          res = '兑换码';
          break;
        case 21:
          res = '兑换码记录';
          break;
        case 22:
          res = '新人专享';
          break;
        case 23:
          res = '余额明细';
          break;
        case 24:
          res = '入账明细';
          break;
        case 25:
          res = '出账明细';
          break;
        case 26:
          res = '支付明细';
          break;
        case 27:
          res = '提现明细';
          break;
        case 28:
          res = '盲盒转赠';
          break;
        case 29:
          res = '分销列表';
          break;
        case 30:
          res = '分销团队成员列表';
          break;
        case 31:
          res = '用户信息';
          break;
        case 32:
          res = '回收记录';
          break;
        case 33:
          res = '合成记录';
          break;
        default:
          break;
      }
      return res;
    },
    exportOrderStatusFilter: function exportOrderStatusFilter(val) {
      var res = '';
      switch (val) {
        case 0:
          res = '进行中';
          break;
        case 1:
          res = '已完成';
          break;
        case -1:
          res = '失败';
          break;
        default:
          break;
      }
      return res;
    }
  },
  methods: {
    exportFileList: function exportFileList(type) {
      var _this = this;
      this.loading = true;
      this.type = type;
      this.tableFrom.type = type;
      (0, _order.exportFileLstApi)(this.tableFrom).then(function (res) {
        _this.fileVisible = true;
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    // 下载
    downLoad: function downLoad(url) {
      window.open(url);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.exportFileList(this.type);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.exportFileList(this.type);
    }
  }
};