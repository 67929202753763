var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container workDetails",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作品类型：", prop: "fileType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.fileTypeChange },
                      model: {
                        value: _vm.workDetail.fileType,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "fileType", $$v)
                        },
                        expression: "workDetail.fileType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("3D模型"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("图片"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("视频"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "4" } }, [
                        _vm._v("音频"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { label: "作品封面：" },
                },
                [
                  _c("div", { staticClass: "color-gray" }, [
                    _vm._v(
                      "图片格式支持：JPG、JPEG、PNG、GIF；文件大小≤15M；图片比例建议1：1"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "coverPicture")
                            },
                          },
                        },
                        [
                          _vm.workDetail.coverPicture
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("coverPicture")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2023131/image/1675135522101940138.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workDetail.coverPicture
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.workDetail.coverPicture + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://java-test-cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex align-items-c",
                          staticStyle: { color: "#333" },
                        },
                        [
                          _c("div", { staticClass: "flex-one" }, [
                            _vm._v("横版"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                effect: "dark",
                                content: "列表页的展示图",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item ml20" }, [
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "infoImage")
                            },
                          },
                        },
                        [
                          _vm.workDetail.infoImage
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("infoImage")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2023131/image/1675135522101940138.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workDetail.infoImage
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.workDetail.infoImage + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://java-test-cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex align-items-c",
                          staticStyle: { color: "#333" },
                        },
                        [
                          _c("div", { staticClass: "flex-one" }, [
                            _vm._v("竖版"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                effect: "dark",
                                content: "列表页的展示图",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.workDetail.fileType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "作品文件", prop: "file" } },
                    [
                      _c("div", { staticClass: "color-gray" }, [
                        _vm._v(
                          "\n          支持glb模型格式，面数控制在50w以下，超过平台将自动压缩，模型文件最大支持30MB，支持JPG、PNG贴图，数量小于16张，请尽量控制分辨率在1024以下，部分高分辨率贴图平台将自动压缩"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploadImg",
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.fileUrl,
                                headers: _vm.myHeaders,
                                "show-file-list": false,
                                "http-request": _vm.uploadAllImg,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.workDetail.file
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("已上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.workDetail.file
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("未上传")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.workDetail.fileType == 1
                            ? "图片源文件："
                            : _vm.workDetail.fileType == 3
                            ? "视频文件："
                            : "音频文件：",
                        prop: "file",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c(
                                "el-upload",
                                {
                                  ref: "uploadImg",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action: _vm.fileUrl,
                                    headers: _vm.myHeaders,
                                    "show-file-list": false,
                                    multiple: "",
                                    "http-request": _vm.uploadALiActionScanFile,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [_vm._v("上传预览版")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workDetail.scanFile &&
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("已上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.workDetail.scanFile &&
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("未上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              ref: "uploadImg",
                              staticClass: "upload-demo",
                              class:
                                _vm.workDetail.fileType != 1 &&
                                _vm.workDetail.fileType != 2
                                  ? "ml20"
                                  : null,
                              attrs: {
                                action: _vm.fileUrl,
                                headers: _vm.myHeaders,
                                "show-file-list": false,
                                multiple: "",
                                "http-request": _vm.uploadALiActionFile,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.workDetail.fileType == 1
                                        ? "上传源文件"
                                        : "上传完整版"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.workDetail.file
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("已上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.workDetail.file
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("未上传")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
              _vm._v(" "),
              _vm.workDetail.fileType != 2
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "showMetohd", label: "详情图片展示：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.workDetail.showMetohd,
                            callback: function ($$v) {
                              _vm.$set(_vm.workDetail, "showMetohd", $$v)
                            },
                            expression: "workDetail.showMetohd",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("动态展示"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("静态展示"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml40",
                          staticStyle: { color: "#606266" },
                        },
                        [_vm._v("(选择动态展示，详情页展示图片3D左右晃动)")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品名称：", prop: "storeName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入作品名称" },
                            model: {
                              value: _vm.workDetail.storeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "storeName", $$v)
                              },
                              expression: "workDetail.storeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品类型：", prop: "categoryId" } },
                        [
                          _c(
                            "el-select",
                            {
                              on: { change: _vm.changeCateId },
                              model: {
                                value: _vm.workDetail.categoryId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "categoryId", $$v)
                                },
                                expression: "workDetail.categoryId",
                              },
                            },
                            _vm._l(_vm.categoryList, function (m, i) {
                              return _c("el-option", {
                                key: "categoryList" + i,
                                attrs: { value: m.id, label: m.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _vm.categorySize.id
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.categorySize.name + "：" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: _vm.categorySize.prompt,
                                  },
                                  model: {
                                    value: _vm.workDetail.size,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.workDetail, "size", $$v)
                                    },
                                    expression: "workDetail.size",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v(_vm._s(_vm.categorySize.unit)),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品标签：", prop: "tags" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标签，多个逗号分隔" },
                            model: {
                              value: _vm.workDetail.tags,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "tags", $$v)
                              },
                              expression: "workDetail.tags",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作者：", prop: "authorId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                placeholder: "选择作者",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.workDetail.authorId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "authorId", $$v)
                                },
                                expression: "workDetail.authorId",
                              },
                            },
                            _vm._l(_vm.authorArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "简介：" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 4 },
                            model: {
                              value: _vm.workDetail.introduction,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "introduction", $$v)
                              },
                              expression: "workDetail.introduction",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情介绍：", prop: "description" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.workDetail.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "description", $$v)
                      },
                      expression: "workDetail.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }