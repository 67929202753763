var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "图片魔方", is_tab: false } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.facade,
            "label-width": "90px",
            size: "small",
            "label-position": "left",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "control-item-title" }, [
            _vm._v("选择样式" + _vm._s(_vm.content.style)),
          ]),
          _vm._v(" "),
          _c("choose", {
            attrs: {
              data: _vm.imgData,
              group: "list_style",
              width: "48px",
              height: "32px",
              padding: "2px 10px",
            },
            model: {
              value: _vm.content.style,
              callback: function ($$v) {
                _vm.$set(_vm.content, "style", $$v)
              },
              expression: "content.style",
            },
          }),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "24px" } }),
          _vm._v(" "),
          _vm.content.style == 7
            ? _c(
                "el-form-item",
                { attrs: { label: "魔方密度" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.content.density,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "density", $$v)
                        },
                        expression: "content.density",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "control-item-h3" },
            [
              _vm._v("\n      布局\n      "),
              _c("div", { staticClass: "tips" }, [
                _vm._v(
                  "\n        选定布局区域，在下方添加图片，建议添加比例一致的图片\n      "
                ),
              ]),
              _vm._v(" "),
              _vm.content.style == 7
                ? _c(
                    "div",
                    { staticClass: "control-item-zoom" },
                    [
                      _c("matrix", {
                        attrs: { "cube-size": _vm.content.density },
                        on: {
                          active: function (e) {
                            return (_vm.activeIndex = e)
                          },
                        },
                        model: {
                          value: _vm.content.data,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "data", $$v)
                          },
                          expression: "content.data",
                        },
                      }),
                    ],
                    1
                  )
                : _c("advertising", {
                    attrs: { size: _vm.content.style },
                    on: {
                      active: function (e) {
                        return (_vm.activeIndex = e)
                      },
                    },
                    model: {
                      value: _vm.content.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.content, "data", $$v)
                      },
                      expression: "content.data",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeIndex > -1 ? _c("el-divider") : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex > -1
            ? _c("div", { staticClass: "attribute-select__photo" }, [
                _c("div", { staticClass: "attribute-select__photo_cover" }, [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      staticStyle: { width: "90px", height: "90px" },
                      on: { click: _vm.uploadIconAction },
                    },
                    [
                      _vm.linkInfo.url
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg($event)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.linkInfo.url
                        ? _c("img", {
                            staticStyle: { width: "90px", height: "90px" },
                            attrs: { src: _vm.linkInfo.url },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "attribute-select__photo_link" }, [
                  _vm.linkInfo.link.name1 || _vm.linkInfo.link.name
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "item-align-center flex-one justify-end ml10 cur-pointer",
                          on: { click: _vm.setCategory },
                        },
                        [
                          _c("div", { staticClass: "ft14" }, [
                            _vm._v(
                              _vm._s(
                                _vm.linkInfo.link.name1 ||
                                  _vm.linkInfo.link.name
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "arrow-img",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                          on: { click: _vm.setCategory },
                        },
                        [
                          _c("div", { staticClass: "ft14" }, [
                            _vm._v("设置跳转链接"),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "arrow-img",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }