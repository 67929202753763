var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "control",
              { attrs: { title: "NFT头图", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "边框宽度" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderWidth", $$v)
                              },
                              expression: "facade.borderWidth",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "playimage"
        ? [
            _c(
              "control",
              { attrs: { title: "头图", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "bindPrice"
        ? [
            _c(
              "control",
              { attrs: { title: "价格", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "边框颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.borderColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderColor", $$v)
                              },
                              expression: "facade.borderColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "reducePrice"
        ? [
            _c(
              "control",
              { attrs: { title: "价格", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("背景图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "bgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.bgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("bgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.bgimage
                              ? _c("img", {
                                  attrs: { src: _vm.facade.bgimage + "!120a" },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "consignee"
        ? [
            _c(
              "control",
              { attrs: { title: "产出概率", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "产出概率颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.topTitleColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "topTitleColor", $$v)
                              },
                              expression: "facade.topTitleColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.nameColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "nameColor", $$v)
                              },
                              expression: "facade.nameColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "选中文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.nameActiveColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "nameActiveColor", $$v)
                              },
                              expression: "facade.nameActiveColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "说明文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.signColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "signColor", $$v)
                              },
                              expression: "facade.signColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "listTitle"
        ? [
            _c(
              "control",
              { attrs: { title: "列表标题", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "playtag"
        ? [
            _c(
              "control",
              { attrs: { title: "合成玩法标签", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "右边距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginRight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginRight", $$v)
                              },
                              expression: "facade.marginRight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "标签圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderRadius", $$v)
                              },
                              expression: "facade.borderRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 32 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.bgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "bgcolor", $$v)
                              },
                              expression: "facade.bgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "选中文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.selectColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "selectColor", $$v)
                              },
                              expression: "facade.selectColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "选中背景颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.selectBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "selectBgcolor", $$v)
                              },
                              expression: "facade.selectBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "边框颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.borderColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderColor", $$v)
                              },
                              expression: "facade.borderColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "productmeta"
        ? [
            _c(
              "control",
              { attrs: { title: "商品列表", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "标题颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.metaColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "metaColor", $$v)
                              },
                              expression: "facade.metaColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.priceColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "priceColor", $$v)
                              },
                              expression: "facade.priceColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "列表圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderRadius", $$v)
                              },
                              expression: "facade.borderRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "左右边距" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginLeft,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginLeft", $$v)
                              },
                              expression: "facade.marginLeft",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#333333" },
                            model: {
                              value: _vm.facade.listBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "listBgcolor", $$v)
                              },
                              expression: "facade.listBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("背景图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "listBgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.listBgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("listBgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.listBgimage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.listBgimage + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "pricepop"
        ? [
            _c(
              "control",
              { attrs: { title: "价格弹窗", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#333333" },
                            model: {
                              value: _vm.facade.bgColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "bgColor", $$v)
                              },
                              expression: "facade.bgColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("背景图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "bgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.bgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("bgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.bgimage
                              ? _c("img", {
                                  attrs: { src: _vm.facade.bgimage + "!120a" },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "背景边框颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#ffffff" },
                            model: {
                              value: _vm.facade.popborderColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "popborderColor", $$v)
                              },
                              expression: "facade.popborderColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "按钮边框颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#ffffff" },
                            model: {
                              value: _vm.facade.borderColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderColor", $$v)
                              },
                              expression: "facade.borderColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "按钮圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderRadius", $$v)
                              },
                              expression: "facade.borderRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "标题大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.nameSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "nameSize", $$v)
                              },
                              expression: "facade.nameSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.priceSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "priceSize", $$v)
                              },
                              expression: "facade.priceSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("立省背景")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "cutbgImage")
                              },
                            },
                          },
                          [
                            _vm.facade.cutbgImage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("cutbgImage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.cutbgImage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.cutbgImage + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "立省文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#ffffff" },
                            model: {
                              value: _vm.facade.cutColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "cutColor", $$v)
                              },
                              expression: "facade.cutColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "按钮1背景色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#333333" },
                            model: {
                              value: _vm.facade.oneBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "oneBgcolor", $$v)
                              },
                              expression: "facade.oneBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("按钮1背景图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "oneBgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.oneBgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("oneBgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.oneBgimage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.oneBgimage + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "按钮2背景色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#333333" },
                            model: {
                              value: _vm.facade.twoBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "twoBgcolor", $$v)
                              },
                              expression: "facade.twoBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("按钮2背景图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "twoBgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.twoBgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("twoBgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.twoBgimage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.twoBgimage + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "按钮3背景色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#333333" },
                            model: {
                              value: _vm.facade.threeBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "threeBgcolor", $$v)
                              },
                              expression: "facade.threeBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("按钮3背景图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "threeBgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.threeBgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("threeBgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.threeBgimage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.threeBgimage + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "按钮4背景色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#333333" },
                            model: {
                              value: _vm.facade.fourBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fourBgcolor", $$v)
                              },
                              expression: "facade.fourBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("按钮4背景图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "fourBgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.fourBgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("fourBgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.fourBgimage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.fourBgimage + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "posterbg"
        ? [
            _c(
              "control",
              { attrs: { title: "海报", is_tab: false } },
              [
                [
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("背景图片")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "upload-img-view item-flex-center",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap(1, "bgimage")
                          },
                        },
                      },
                      [
                        _vm.facade.bgimage
                          ? _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg("bgimage")
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.facade.bgimage
                          ? _c("img", {
                              attrs: { src: _vm.facade.bgimage + "!120a" },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    ),
                  ]),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type == "bubblepop"
        ? [
            _c(
              "control",
              { attrs: { title: "气泡", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("背景图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "bgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.bgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("bgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.bgimage
                              ? _c("img", {
                                  attrs: { src: _vm.facade.bgimage + "!120a" },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#333333" },
                            model: {
                              value: _vm.facade.bgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "bgcolor", $$v)
                              },
                              expression: "facade.bgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.popwidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "popwidth", $$v)
                              },
                              expression: "facade.popwidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "图片大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.imgwidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "imgwidth", $$v)
                              },
                              expression: "facade.imgwidth",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }