var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-button", { staticClass: "mt20", on: { click: _vm.backAction } }, [
        _vm._v("返回"),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm mt20",
          attrs: {
            model: _vm.roleForm,
            rules: _vm.rules,
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色：", prop: "roles" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择角色",
                    clearable: "",
                    size: "small",
                    multiple: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                  model: {
                    value: _vm.roleForm.roleIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.roleForm, "roleIds", $$v)
                    },
                    expression: "roleForm.roleIds",
                  },
                },
                _vm._l(_vm.roleArr, function (item) {
                  return _c("el-option", {
                    key: item.roleId,
                    attrs: { label: item.roleName, value: item.roleId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "管理员姓名：", prop: "nickName" } },
            [
              _c("el-input", {
                attrs: {
                  size: "small",
                  placeholder: "请输入管理员姓名",
                  clearable: "",
                },
                model: {
                  value: _vm.roleForm.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.roleForm, "nickName", $$v)
                  },
                  expression: "roleForm.nickName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "账号：", prop: "userName" } },
            [
              _c("el-input", {
                attrs: {
                  size: "small",
                  placeholder: "请输入账号",
                  clearable: "",
                },
                model: {
                  value: _vm.roleForm.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.roleForm, "userName", $$v)
                  },
                  expression: "roleForm.userName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码：", prop: "phonenumber" } },
            [
              _c("el-input", {
                attrs: {
                  size: "small",
                  placeholder: "请输入手机号码",
                  clearable: "",
                },
                model: {
                  value: _vm.roleForm.phonenumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.roleForm, "phonenumber", $$v)
                  },
                  expression: "roleForm.phonenumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.$route.query.id
            ? _c(
                "el-form-item",
                { attrs: { label: "密码：", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入密码",
                      clearable: "",
                    },
                    model: {
                      value: _vm.roleForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "password", $$v)
                      },
                      expression: "roleForm.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$route.query.id
            ? _c(
                "el-form-item",
                { attrs: { label: "确认密码：", prop: "passwordAgain" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入确认密码",
                      clearable: "",
                    },
                    model: {
                      value: _vm.roleForm.passwordAgain,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "passwordAgain", $$v)
                      },
                      expression: "roleForm.passwordAgain",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否开启：", prop: "status" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": "0",
                  "inactive-value": "1",
                  "active-color": "#13ce66",
                  "inactive-color": "#909399",
                },
                model: {
                  value: _vm.roleForm.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.roleForm, "status", $$v)
                  },
                  expression: "roleForm.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }