"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = _interopRequireDefault(require("@/components/cards/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cardsData: _index.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      orderId: 0,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        order_sn: "",
        status: "",
        date: "",
        page: 1,
        limit: 20,
        type: "4",
        order_type: "1",
        username: "",
        keywords: ''
      },
      orderChartType: {},
      timeVal: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      ids: "",
      tableFromLog: {
        page: 1,
        limit: 10
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      LogLoading: false,
      dialogVisible: false,
      fileVisible: false,
      cardLists: [],
      orderDatalist: null,
      headeNum: [{
        type: 1,
        name: "全部",
        count: 10
      }, {
        type: 2,
        name: "普通订单",
        count: 3
      }, {
        type: 3,
        name: "直播订单",
        count: 1
      }, {
        type: 4,
        name: "核销订单",
        count: 2
      }, {
        type: 5,
        name: "拼团订单",
        count: 0
      }, {
        type: 6,
        name: "秒杀订单",
        count: 6
      }, {
        type: 7,
        name: "砍价订单",
        count: 5
      }]
    };
  },
  mounted: function mounted() {
    this.headerList();
    this.getList(1);
  },
  methods: {
    // 导出
    exportOrder: function exportOrder() {
      var _this = this;
      (0, _order.exportOrderApi)({
        status: this.tableFrom.status,
        date: this.tableFrom.date,
        take_order: 1
      }).then(function (res) {
        /*this.$message.success(res.message);
        this.fileVisible = true;
        this.$refs.exportList.exportFileList();*/
        var h = _this.$createElement;
        _this.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: teal'
          }, '导出已生成列表'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('order');
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.id);
      });
      this.ids = data.join(",");
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _order.takeOrderListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.cardLists = res.data.stat;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    headerList: function headerList() {
      var _this3 = this;
      (0, _order.takeChartApi)().then(function (res) {
        _this3.orderChartType = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    }
  }
};