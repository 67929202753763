"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _saasApi = require("@/api/saasApi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
  props: {
    // productPackageId: { type: Number, default: '' }
  },
  data: function data() {
    return {
      formState: {
        systemType: "",
        productType: "",
        productName: "",
        productCode: "",
        price: "",
        useTime: "",
        useCondition: "",
        content: ""
      },
      rules: {
        productType: [{
          required: true,
          message: "请选择产品类型",
          trigger: "change"
        }],
        productName: [{
          required: true,
          message: "请输入产品名称",
          trigger: "change"
        }],
        productCode: [{
          required: true,
          message: "请输入产品编码",
          trigger: "change"
        }, {
          pattern: /^[a-zA-Z]\d+$/,
          message: "编码格式为字母 + 数字",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请输入产品价格",
          trigger: "change"
        }, {
          pattern: /^[1-9]\d*$/,
          message: "产品价格只能是整数",
          trigger: "change"
        }],
        useTime: [{
          required: true,
          message: "请选择使用时长",
          trigger: "change"
        }],
        useCondition: [{
          required: true,
          message: "请输入开通条件",
          trigger: "change"
        }],
        content: [{
          required: true,
          message: "请输入产品介绍",
          trigger: "change"
        }]
      }
    };
  }
}, "props", ["productTypeList", "editInfo"]), "mounted", function mounted() {
  if (this.editInfo) {
    var _this$editInfo = this.editInfo,
      systemType = _this$editInfo.systemType,
      productType = _this$editInfo.productType,
      productName = _this$editInfo.productName,
      productCode = _this$editInfo.productCode,
      price = _this$editInfo.price,
      useTime = _this$editInfo.useTime,
      useCondition = _this$editInfo.useCondition,
      content = _this$editInfo.content,
      productId = _this$editInfo.productId;
    this.formState.systemType = systemType;
    this.formState.productType = productType + '';
    this.formState.productName = productName;
    this.formState.productCode = productCode;
    this.formState.price = price;
    this.formState.useTime = useTime;
    this.formState.useCondition = useCondition;
    this.formState.content = content;
    this.formState.productId = productId;
  }
  if (this.productTypeList.length) {
    this.formState.systemType = this.productTypeList[0].systemType;
  }
}), "methods", {
  onSubmit: function onSubmit() {
    var _this = this;
    this.$refs.formRef.validate(function (valid) {
      console.log(_this.formState.price);
      if (valid) {
        if (!_this.formState.productId) {
          // 添加
          (0, _saasApi._createSaaSProduct)(_this.formState).then(function (res) {
            _this.$message({
              message: "添加成功",
              type: "success"
            });
            _this.$emit("closeDrawerVisib", false);
          }).catch(function (err) {
            console.log(err);
          });
        } else {
          // 修改
          (0, _saasApi._editSaaSproduct)(_this.formState).then(function (res) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            _this.$emit("closeDrawerVisib", false);
          }).catch(function (err) {
            console.log(err);
          });
        }
      }
    });
  }
});