"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      receiveData: {
        show: false,
        data: null,
        listLoading: true,
        list: [],
        total: 0,
        query: {
          pageNum: 1,
          pageSize: 10
        }
      },
      changeType: '1'
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    changeTypeAction: function changeTypeAction(d) {
      if (Number(d) === 2) {
        this.$router.replace('/merchant/marketing/fanFission/list');
      }
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _marketing.helpListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList('');
    },
    createdAction: function createdAction() {
      this.$router.push('./detail');
    },
    editAction: function editAction(id) {
      this.$router.push('./detail?id=' + id);
    },
    editStatusAction: function editStatusAction(id, val) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4".concat(val === 2 ? '结束' : '删除', "\u8BE5\u6D3B\u52A8?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.fissionSwitchStatusApi)({
          id: id,
          type: val
        }).then(function () {
          _this2.$message.success('操作成功');
          _this2.getList('');
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showReceiveDataAction: function showReceiveDataAction(m) {
      this.receiveData = {
        show: true,
        data: m,
        listLoading: true,
        list: [],
        total: 0,
        query: {
          pageNum: 1,
          pageSize: 10,
          id: m.id
        }
      };
      this.pageChangeNew(1);
    },
    pageChangeNew: function pageChangeNew(page) {
      this.receiveData.query.pageNum = page;
      this.loadReceiveAction();
    },
    loadReceiveAction: function loadReceiveAction() {
      var _this3 = this;
      this.receiveData.listLoading = true;
      (0, _marketing.helpTaskListApi)(this.receiveData.query).then(function (res) {
        _this3.receiveData.total = res.total;
        _this3.receiveData.list = res.rows;
        _this3.receiveData.listLoading = false;
        _this3.receiveData.data = res.statistical;
      }).catch(function () {});
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this4 = this;
      var data = (0, _objectSpread2.default)({}, this.receiveData.query);
      (0, _marketing.assistanceExport)(data).then(function (res) {
        _this4.$message.success('操作成功');
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        // const h = this.$createElement;
        // this.$msgbox({
        //   title: '提示',
        //   message: h('p', null, [
        //     h('span', null, '文件正在生成中，请稍后点击"'),
        //     h('span', { style: 'color: teal' }, '导出已生成列表'),
        //     h('span', null, '"查看~ ')
        //   ]),
        //   confirmButtonText: '我知道了'
        // }).then(action => { });
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      // this.fileVisible = true;
      this.$refs.exportList.exportFileList(34);
    }
  }
};