"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

var writeOffRouter = {
  path: "/writeOff",
  component: _layout.default,
  redirect: "noRedirect",
  name: "核销",
  meta: {
    title: "核销",
    icon: "writeOff"
  },
  children: [{
    path: "record",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/writeOff/index"));
      });
    },
    name: "writeOffRecord",
    meta: {
      title: "核销记录",
      noCache: true
    }
  }]
};
var _default = exports.default = writeOffRouter;