"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _animal = require("@/api/animal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      phone: "",
      listLoading: false,
      list: {
        data: [],
        total: 0
      },
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      statistical: {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      this.listLoading = true;
      this.pageConfig.pageNum = page;
      (0, _animal.getUserInnerListApi)((0, _objectSpread2.default)({
        phone: this.phone
      }, this.pageConfig)).then(function (res) {
        console.log(res);
        _this.listLoading = false;
        _this.list.data = res.rows;
        _this.list.total = res.total;
        _this.statistical = res.statistical;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.getList();
    }
  }
};