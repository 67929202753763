var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  staticStyle: { "margin-top": "-10px" },
                  on: { "tab-click": _vm.changePageAction },
                  model: {
                    value: _vm.tableFrom.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "type", $$v)
                    },
                    expression: "tableFrom.type",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "云总部模板", name: "1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "IP星球模板", name: "2" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c mt10" }, [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticStyle: { "line-height": "36px", width: "70px" } },
                    [_vm._v("业务场景：")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml5" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "medium" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.businessScenario,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "businessScenario", $$v)
                            },
                            expression: "tableFrom.businessScenario",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("拍卖"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("商品"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "3" } }, [
                            _vm._v("作品"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex ml20" }, [
                  _c(
                    "div",
                    { staticStyle: { "line-height": "36px", width: "70px" } },
                    [_vm._v("内容类型：")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml5" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "medium" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.contentLatitudeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "contentLatitudeId", $$v)
                            },
                            expression: "tableFrom.contentLatitudeId",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.weidu.sysAttrValueList,
                            function (item, index) {
                              return _c(
                                "el-radio-button",
                                { key: "" + index, attrs: { label: item.id } },
                                [_vm._v(_vm._s(item.attrValueName))]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c mt20" }, [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticStyle: { "line-height": "36px", width: "70px" } },
                    [_vm._v("内容定义：")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml5" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "medium" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.contentLocalizationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tableFrom,
                                "contentLocalizationId",
                                $$v
                              )
                            },
                            expression: "tableFrom.contentLocalizationId",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.dingwei.sysAttrValueList,
                            function (item, index) {
                              return _c(
                                "el-radio-button",
                                { key: "" + index, attrs: { label: item.id } },
                                [_vm._v(_vm._s(item.attrValueName))]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex ml20" }, [
                  _c(
                    "div",
                    { staticStyle: { "line-height": "36px", width: "70px" } },
                    [_vm._v("画面比例：")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml5" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "medium" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.ratio,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "ratio", $$v)
                            },
                            expression: "tableFrom.ratio",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "9:16" } }, [
                            _vm._v("9:16"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "16:9" } }, [
                            _vm._v("16:9"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "3:4" } }, [
                            _vm._v("3:4"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c mt20" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "36px", width: "70px" } },
                  [_vm._v("作品分类：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml5" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "medium" },
                        on: { change: _vm.changeTwoCategory },
                        model: {
                          value: _vm.tableFrom.firstCategoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "firstCategoryId", $$v)
                          },
                          expression: "tableFrom.firstCategoryId",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "" } }, [
                          _vm._v("全部"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.categoryList, function (item, index) {
                          return _c(
                            "el-radio-button",
                            { key: "" + index, attrs: { label: item.id } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c mt20" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "36px", width: "70px" } },
                  [_vm._v("二级分类：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml5" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "medium" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.secondaryCategoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "secondaryCategoryId", $$v)
                          },
                          expression: "tableFrom.secondaryCategoryId",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "" } }, [
                          _vm._v("全部"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.categoryTwoList, function (item, index) {
                          return _c(
                            "el-radio-button",
                            { key: "" + index, attrs: { label: item.id } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c mt20" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "36px", width: "70px" } },
                  [_vm._v("作品形状：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml5" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "medium" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.workShapeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "workShapeId", $$v)
                          },
                          expression: "tableFrom.workShapeId",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "" } }, [
                          _vm._v("全部"),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.xingzhuang.sysAttrValueList,
                          function (item, index) {
                            return _c(
                              "el-radio-button",
                              { key: "" + index, attrs: { label: item.id } },
                              [_vm._v(_vm._s(item.attrValueName))]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c mt20" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "36px", width: "70px" } },
                  [_vm._v("模版标签：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml5" },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { size: "medium" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tagIdList,
                          callback: function ($$v) {
                            _vm.tagIdList = $$v
                          },
                          expression: "tagIdList",
                        },
                      },
                      _vm._l(_vm.tagList, function (item, index) {
                        return _c(
                          "el-checkbox-button",
                          { key: index, attrs: { label: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c mt20" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "36px", width: "70px" } },
                  [_vm._v("模版状态：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml5" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "medium" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "status", $$v)
                          },
                          expression: "tableFrom.status",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "" } }, [
                          _vm._v("全部"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("上架"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "0" } }, [
                          _vm._v("下架"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "w300",
                          attrs: {
                            placeholder: "请输入组件名称",
                            clearable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "name", $$v)
                            },
                            expression: "tableFrom.name",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml20",
                      attrs: { type: "primary" },
                      on: { click: _vm.onAdd },
                    },
                    [_vm._v("创建视频模版")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "id",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "160" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "封面图", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.coverImage
                          ? _c("div", [
                              _c("img", {
                                staticStyle: {
                                  height: "50px",
                                  width: "50px",
                                  "object-fit": "contain",
                                  display: "block",
                                },
                                attrs: { src: scope.row.coverImage },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "模版名称", "min-width": "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "包含组件", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.components.join(","))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "预览视频", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.videoStatus == 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.previewVideo(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("查看视频")]
                                ),
                              ],
                              1
                            )
                          : scope.row.videoStatus == 1
                          ? _c("div", [_vm._v("生成中")])
                          : scope.row.videoStatus == 0
                          ? _c("div", [_vm._v("待生成")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "type", label: "业务场景", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.businessScenario == 1
                          ? _c("div", [_vm._v("拍卖")])
                          : scope.row.businessScenario == 2
                          ? _c("div", [_vm._v("商品")])
                          : scope.row.businessScenario == 3
                          ? _c("div", [_vm._v("作品")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "type", label: "内容纬度", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.contentLatitude))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "内容定义", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.contentLocalization)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "画面比例", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.ratio))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品分类", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.firstCategoryName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "二级分类", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.secondaryCategoryName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品形状", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.workShape))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "模版标签", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.tagnames.join(",")) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "模版状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? [
                              _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("下架"),
                              ]),
                            ]
                          : scope.row.status == 1
                          ? [
                              _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("上架"),
                              ]),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否推荐", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.isRecommend ? "推荐" : "-") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "更新时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.updateTime) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "200",
                  fixed: "right",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex justify-end" }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "4px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAction(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "4px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyAction(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("复制")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "4px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setAction(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("设置内容")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "4px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editStatusAction(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.status == 0 ? "上架" : "下架"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "4px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showVideo,
            title: "预览视频",
            "append-to-body": "",
            width: _vm.videoRatio == "16:9" ? "800px" : "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVideo = $event
            },
          },
        },
        [
          _c("div", { staticClass: "item-flex-center" }, [
            _c("video", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.videoUrl, controls: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }