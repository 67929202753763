var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-button",
        {
          staticClass: "mb20",
          attrs: { type: "primary", size: "mini" },
          on: { click: _vm.goHome },
        },
        [_vm._v("返回首页")]
      ),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }