"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyRefundApi = applyRefundApi;
exports.cancelApi = cancelApi;
exports.exportApi = exportApi;
exports.getListApi = getListApi;
exports.getPayType = getPayType;
var _request = _interopRequireDefault(require("./request"));
//获取订单版权发行列表
function getListApi(data) {
  return _request.default.get("/open3d/order/sys/order/list", data);
}

//取消订单
function cancelApi(orderId) {
  return _request.default.post("/open3d/order/sys/order/cancel/".concat(orderId));
}
//退款

function applyRefundApi(data) {
  return _request.default.post("/open3d/order/sys/order/applyRefund", data);
}
//订单导出
function exportApi(data) {
  return _request.default.get("/open3d/order/sys/order/export", data);
}

//获取支付方式
function getPayType() {
  return _request.default.get("/open3d/pay/getPayType");
}