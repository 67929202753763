"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _open3d = require("@/api/open3d");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import userdetail from "../user/list/userdetail";
var _default = exports.default = {
  components: {
    fileList: _fileList.default
    // userdetail
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      listLoading: true,
      queryParams: {
        downloadStatus: "",
        textureMode: "",
        userNameOrPhone: "",
        status: "",
        pageNum: 1,
        pageSize: 20
      },
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    watch: function watch(url) {
      window.open("https://artai.com.cn/modeling3d/index.html?glbUrl=".concat(url));
    },
    downLoad: function downLoad(row) {
      var _this = this;
      (0, _open3d.downloadApi)(row.id).then(function (res) {
        fetch(row.zipModelUrl).then(function (response) {
          return response.blob();
        }).then(function (blob) {
          var blobUrl = URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = blobUrl;
          link.download = row.zipModelUrl;
          link.click();
          URL.revokeObjectURL(blobUrl);
          _this.$message.success("下载成功");
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _open3d.getModelListApi)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList("");
    },
    exportOrder: function exportOrder() {
      var _this3 = this;
      (0, _open3d.modelExportApi)(this.queryParams).then(function (res) {
        _this3.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(50);
    }
  }
};