"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var userRouter = {
  path: "".concat(_settings.roterPre, "/ipip"),
  name: "ipip",
  meta: {
    icon: "dashboard",
    title: "商户管理"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "noRedirect",
  children: [{
    path: "spaceList",
    name: "spaceList",
    meta: {
      title: "空间管理",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/ipip/spaceList.vue"));
      });
    }
  }, {
    path: "exhibitList",
    name: "exhibitList",
    meta: {
      title: "展品管理",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/ipip/exhibitList.vue"));
      });
    }
  }, {
    path: "materialList",
    name: "materialList",
    meta: {
      title: "素材管理",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/ipip/materiallist.vue"));
      });
    }
  }, {
    path: "clientList",
    name: "clientList",
    meta: {
      title: "客户列表",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/ipip/clienList.vue"));
      });
    }
  }, {
    path: "maticlabel",
    name: "UserAutoLabel",
    meta: {
      title: "自动标签",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/label"));
      });
    }
  }, {
    path: "printSetting",
    name: "printSetting",
    meta: {
      title: "版画设置",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/ipip/printSetting.vue"));
      });
    }
  }, {
    path: "cardprice",
    name: "cardprice",
    meta: {
      title: "价格设置",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/ipip/cardPrice"));
      });
    }
  }, {
    path: "configList",
    name: "configList",
    meta: {
      title: "产品配置",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/ipip/configList"));
      });
    }
  }]
};
var _default = exports.default = userRouter;