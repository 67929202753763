var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-button",
        {
          staticClass: "mr20",
          attrs: { size: "mini", icon: "el-icon-back" },
          on: { click: _vm.close },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
                inline: "",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        "font-size": "18px",
                      },
                      attrs: { span: 12, offset: 0 },
                    },
                    [_vm._v("创建出库单")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库房", prop: "out_warehouse_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "请选择库房",
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.selectActiveGoods },
                          model: {
                            value: _vm.formValidate.out_warehouse_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formValidate,
                                "out_warehouse_id",
                                $$v
                              )
                            },
                            expression: "formValidate.out_warehouse_id",
                          },
                        },
                        _vm._l(_vm.storeArr, function (item) {
                          return _c("el-option", {
                            key: item.warehouse_id,
                            attrs: {
                              label: item.name,
                              value: item.warehouse_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出库类型", prop: "out_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "出库类型",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formValidate.out_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "out_type", $$v)
                            },
                            expression: "formValidate.out_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "成交出库", value: "order" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "销售出库", value: "sales" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "调拨出库", value: "transfers" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "借出出库", value: "borrow" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "退回出库", value: "return" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "其他出库", value: "other" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "单据编号" } }, [
                    _vm._v(_vm._s(_vm.orderno)),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "制单人" } }, [
                    _vm._v(_vm._s(_vm.mannagename)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-top": "30px" },
                      attrs: {
                        data: _vm.goodsArr,
                        size: "small",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "分类",
                          prop: "product_id",
                          "min-width": "50",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "图片", "min-width": "60" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "demo-image__preview" },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "36px",
                                        height: "36px",
                                      },
                                      attrs: {
                                        src: scope.row.image,
                                        "preview-src-list": [scope.row.image],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "拍品名称",
                          prop: "store_name",
                          "min-width": "140",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.product_id > 0
                                  ? _c("div", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.store_name) +
                                          "\n            "
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: scope.row.store_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "store_name",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.store_name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "保留价", "min-width": "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.retain_price) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "作者", "min-width": "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  scope.row.brand
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.brand.brand_name)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "属性",
                          prop: "order_pay_amount",
                          align: "center",
                          "min-width": "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.attrInfo,
                                function (m, i) {
                                  return _c("span", { key: "attr_" + i }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(m.attr_name) +
                                        ":" +
                                        _vm._s(m.attr_value_name) +
                                        "\n            "
                                    ),
                                  ])
                                }
                              )
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delGoods(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                删除\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "20px", width: "400px" },
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("formValidate")
                            },
                          },
                        },
                        [_vm._v("\n            保存\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "30px" } }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-tabs",
            { attrs: { value: "goods" } },
            [
              _c("el-tab-pane", {
                attrs: { label: "货品档案", name: "goods" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { inline: "", size: "small", "label-width": "110px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "货品搜索：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: {
                        placeholder: "请输入货品名称，关键字，产品编号",
                      },
                      model: {
                        value: _vm.tableFrom.keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "keyword", $$v)
                        },
                        expression: "tableFrom.keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "货品ID：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入货品ID" },
                      model: {
                        value: _vm.tableFrom.product_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "product_id", $$v)
                        },
                        expression: "tableFrom.product_id",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "product_id", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_no",
                  label: "货品编号",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货品图", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "货品名称",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "brand", label: "作者/品牌", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.brand
                          ? _c("div", [
                              _vm._v(
                                "\n               " +
                                  _vm._s(scope.row.brand.brand_name) +
                                  "\n             "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分类", "min-width": "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.storeCategory
                          ? [
                              _c("span", { staticClass: "mr10" }, [
                                _vm._v(
                                  _vm._s(scope.row.storeCategory.cate_name)
                                ),
                              ]),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "属性", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          _vm._l(scope.row.attrInfo, function (m, i) {
                            return _c("span", { key: "attr_" + i }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(m.attr_name) +
                                  ":" +
                                  _vm._s(m.attr_value_name) +
                                  "\n                "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saleGoods(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.goodsIdArr.indexOf(
                                        scope.row.product_id
                                      ) > -1
                                        ? "已选择货品"
                                        : "选择货品"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }