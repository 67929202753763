"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var settingRouter = {
  path: "".concat(_settings.roterPre, "/setting"),
  name: 'system_group',
  meta: {
    icon: 'dashboard',
    title: '权限管理'
  },
  component: _layout.default,
  children: [{
    path: 'systemFeedback',
    name: 'systemFeedback',
    meta: {
      title: '查看意见反馈'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemFeedback/list'));
      });
    }
  }, {
    path: 'systemSensitiveWords',
    name: 'systemSensitiveWords',
    meta: {
      title: '敏感词库'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemSensitiveWords/list'));
      });
    }
  }, {
    path: 'systemRule',
    name: 'setting_rule',
    meta: {
      title: '角色管理'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/index'));
      });
    },
    children: [{
      path: 'systemRole',
      name: 'setting_role',
      meta: {
        title: '角色管理'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemRole/index'));
        });
      }
    }, {
      path: 'addSystemRole',
      name: 'AddSystemRole',
      meta: {
        title: '角色管理'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemRole/add'));
        });
      }
    }, {
      path: 'systemAdmin',
      name: 'setting_systemAdmin',
      meta: {
        title: '管理员管理',
        activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAdmin/index'));
        });
      }
    }, {
      path: 'addSystemAdmin',
      name: 'AddSystemAdmin',
      meta: {
        title: '管理员管理'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAdmin/add'));
        });
      }
    }, {
      path: 'systemPwd',
      name: 'systemPwd',
      meta: {
        title: '操作密码'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemPwd/index'));
        });
      }
    }, {
      path: 'systemLog',
      name: 'setting_systemLog',
      meta: {
        title: '操作日志',
        activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemLog/index'));
        });
      }
    }]
  }]
};
var _default = exports.default = settingRouter;