var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "盲盒封面图：", required: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "showimage")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.image + "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("盒子展示图")]),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "盲盒展示图：" } }, [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "left")
                                },
                              },
                            },
                            [
                              _vm.formValidate.leftimage
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.formValidate.leftimage + "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("盒子左侧图")]),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "right")
                                },
                              },
                            },
                            [
                              _vm.formValidate.rightimage
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.formValidate.rightimage + "!120a",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-error btndel",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleRemove("rightimage")
                                        },
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("盒子右侧图")]),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "top")
                                },
                              },
                            },
                            [
                              _vm.formValidate.topimage
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.formValidate.topimage + "!120a",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-error btndel",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleRemove("topimage")
                                        },
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("盒子顶部图")]),
                                  ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "盲盒名称：", prop: "title" } },
                        [
                          _c("el-input", {
                            staticClass: "selwidth",
                            attrs: { placeholder: "请输入盲盒名称" },
                            model: {
                              value: _vm.formValidate.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "title", $$v)
                              },
                              expression: "formValidate.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售时间：", prop: "sale_time" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "selwidth",
                            attrs: {
                              type: "datetime",
                              placeholder: "选择发售时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.formValidate.sale_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "sale_time", $$v)
                              },
                              expression: "formValidate.sale_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "发售数量：", prop: "total_number" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.formValidate.total_number) +
                              "\n            "
                          ),
                          _c("span", {
                            staticStyle: { "margin-left": "80px" },
                          }),
                          _vm._v(" 前台不展示发售数量\n            "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": "0",
                              "inactive-value": "1",
                              "active-text": "是",
                              "inactive-text": "否",
                            },
                            model: {
                              value: _vm.formValidate.is_show_total_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "is_show_total_number",
                                  $$v
                                )
                              },
                              expression: "formValidate.is_show_total_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预留数量：",
                            prop: "reserved_number",
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "selwidth",
                            attrs: { step: 1 },
                            model: {
                              value: _vm.formValidate.reserved_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "reserved_number",
                                  $$v
                                )
                              },
                              expression: "formValidate.reserved_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "限购数量：",
                            prop: "limit_buy_number",
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "selwidth",
                            attrs: { step: 1 },
                            model: {
                              value: _vm.formValidate.limit_buy_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "limit_buy_number",
                                  $$v
                                )
                              },
                              expression: "formValidate.limit_buy_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售价格：", prop: "sale_price" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selwidth",
                              staticStyle: { "text-align": "center" },
                              on: {
                                change: _vm.changePrice,
                                input: _vm.changePrice,
                              },
                              model: {
                                value: _vm.formValidate.sale_price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "sale_price", $$v)
                                },
                                expression: "formValidate.sale_price",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "转赠规则" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selwidth",
                              attrs: { placeholder: "请选择转赠规则" },
                              model: {
                                value: _vm.formValidate.transfer_config_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "transfer_config_id",
                                    $$v
                                  )
                                },
                                expression: "formValidate.transfer_config_id",
                              },
                            },
                            _vm._l(_vm.transferList, function (m, i) {
                              return _c("el-option", {
                                key: "transfer" + i,
                                attrs: { label: m.title, value: m.config_id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm.formValidate.leftimage &&
                    _vm.formValidate.rightimage &&
                    _vm.formValidate.topimage
                      ? _c("div", { staticClass: "box" }, [
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.leftimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.rightimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.rightimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.leftimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.topimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.topimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.nftid
                ? _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "藏品设置：", required: "" } },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "multipleSelection",
                                  staticClass: "table",
                                  staticStyle: {
                                    width: "100%",
                                    "margin-top": "-10px",
                                  },
                                  attrs: {
                                    "tooltip-effect": "dark",
                                    "row-key": function (row) {
                                      return row.order_id
                                    },
                                    data: _vm.selectTableData.data,
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { width: "80", label: "图片" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-image__preview",
                                                },
                                                [
                                                  _c("el-image", {
                                                    attrs: {
                                                      src:
                                                        scope.row.product
                                                          .image + "!120a",
                                                      "preview-src-list": [
                                                        scope.row.product.image,
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2425103806
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "名称",
                                      "min-width": "100",
                                      prop: "product.store_name",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "隐藏款",
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-value": "1",
                                                      "inactive-value": "0",
                                                      "active-text": "是",
                                                      "inactive-text": "否",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.editBoxGoods(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.is_hide,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "is_hide",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.is_hide",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  scope.row.is_hide == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          staticStyle: {
                                                            width: "60px",
                                                            height: "60px",
                                                          },
                                                          attrs: {
                                                            title: "正方形图片",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalPicTap(
                                                                "1",
                                                                "product",
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          scope.row.hide_image
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                    height:
                                                                      "60px",
                                                                  },
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        scope
                                                                          .row
                                                                          .hide_image +
                                                                        "!120a",
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                    height:
                                                                      "60px",
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "封面图"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      863615422
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                  全部\n                  "
                                                ),
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "1",
                                                    "inactive-value": "0",
                                                    "active-text": "是",
                                                    "inactive-text": "否",
                                                  },
                                                  on: {
                                                    change: _vm.editBoxGoodsAll,
                                                  },
                                                  model: {
                                                    value: _vm.all.issale,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.all,
                                                        "issale",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "all.issale",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1792049874
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "issale",
                                          label: "只在盲盒售卖",
                                          "min-width": "90",
                                          "render-header": _vm.atteLeavetooltip,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-value": "1",
                                                      "inactive-value": "0",
                                                      "active-text": "是",
                                                      "inactive-text": "否",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.editBoxGoods(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.issale,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "issale",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.issale",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          505744932
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "product.stock",
                                      label: "藏品实际库存/盲盒内拆出",
                                      "min-width": "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      scope.row.product.stock
                                                    ) +
                                                    "/" +
                                                    _vm._s(
                                                      scope.row
                                                        .already_release_count
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4111542458
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "投放数量",
                                      "min-width": "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input-number", {
                                                staticStyle: { width: "150px" },
                                                attrs: {
                                                  min: 0,
                                                  step: 1,
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.editBoxGoods(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    return _vm.editBoxGoods(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.stock_number,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "stock_number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.stock_number",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2270015813
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      "min-width": "80",
                                      fixed: "right",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm.disabled
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addissnum(
                                                                scope.$index,
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      增发\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.disabled
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.del(
                                                                scope.$index,
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      删除\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      594113161
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mt20 flex justify-e" },
                                [
                                  _c("el-pagination", {
                                    attrs: {
                                      "page-size": _vm.tabelform.limit,
                                      "current-page": _vm.tabelform.page,
                                      layout:
                                        "total, prev, pager, next, jumper",
                                      total: _vm.selectTableData.total,
                                    },
                                    on: { "current-change": _vm.getselectList },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情介绍" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.formValidate.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "content", $$v)
                      },
                      expression: "formValidate.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _vm.nftid
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitForm("formValidate")
                                    },
                                  },
                                },
                                [_vm._v("提交")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitForm("formValidate")
                                    },
                                  },
                                },
                                [_vm._v("下一步")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.nftid
            ? _c(
                "el-tabs",
                { attrs: { value: "goods" } },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "数字藏品", name: "goods" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.nftid
            ? _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "div",
                    { staticClass: "tab flex align-items-c" },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { placeholder: "请输入商品名称，关键字" },
                          model: {
                            value: _vm.queryParams.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "keyword", $$v)
                            },
                            expression: "queryParams.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-cascader", {
                        staticClass: "w200 ml10",
                        attrs: {
                          options: _vm.categoryList,
                          props: {
                            expandTrigger: "hover",
                            value: "store_category_id",
                            label: "cate_name",
                          },
                        },
                        on: { change: _vm.goSearch },
                        model: {
                          value: _vm.category,
                          callback: function ($$v) {
                            _vm.category = $$v
                          },
                          expression: "category",
                        },
                      }),
                      _vm._v(" "),
                      _vm.category
                        ? _c(
                            "el-button",
                            {
                              staticClass: "ml10",
                              attrs: { type: "primary" },
                              on: { click: _vm.batchAdd },
                            },
                            [_vm._v("批量添加该分类藏品")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-refresh-left",
                          },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("刷新列表")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticClass: "order-table mt20",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData.data,
                        size: "mini",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "作品信息", "min-width": "150" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "work-img" }, [
                                        _c("img", {
                                          attrs: {
                                            src: row.product.image + "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-info flex-one ml10",
                                          staticStyle: { overflow: "hidden" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "fWeight500" },
                                            [
                                              _vm._v(
                                                _vm._s(row.product.store_name)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          row.original_hash
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex align-items-c color-gray",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copyAction(
                                                        row.original_hash
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        "" +
                                                          row.original_hash[0] +
                                                          row.original_hash[1] +
                                                          row.original_hash[2] +
                                                          "****" +
                                                          row.original_hash[
                                                            row.original_hash
                                                              .length - 3
                                                          ] +
                                                          row.original_hash[
                                                            row.original_hash
                                                              .length - 2
                                                          ] +
                                                          row.original_hash[
                                                            row.original_hash
                                                              .length - 1
                                                          ]
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3964129458
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "作品价格", "min-width": "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          row.product.price,
                                          2
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          346358185
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "发行/库存", "min-width": "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(row.issuer_num) +
                                        "/" +
                                        _vm._s(row.product.stock)
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          323463954
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "文件类型", "min-width": "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(row.file_type == 1 ? "图片" : "3D")
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          266060904
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "作品状态", "min-width": "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.status == 0
                                    ? _c("div", { staticClass: "color-lv" }, [
                                        _vm._v("未发行"),
                                      ])
                                    : row.status == 1
                                    ? _c("div", [_vm._v("已发行")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3190064080
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "创建成员", "min-width": "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(row.admin.real_name)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4206136016
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "时间", "min-width": "150" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("div", [
                                    _vm._v("创建：" + _vm._s(row.create_time)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v("销售：" + _vm._s(row.start_time)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2428467933
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "140" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 1 &&
                                  scope.row.product.stock > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectinver(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.orderidArr.indexOf(
                                                  scope.row.product_id
                                                ) > -1
                                                  ? "已选择藏品"
                                                  : "选择藏品"
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3950726027
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20 flex justify-e" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-size": _vm.queryParams.limit,
                          "current-page": _vm.queryParams.page,
                          layout: "total, prev, pager, next, jumper",
                          total: _vm.tableData.total,
                        },
                        on: { "current-change": _vm.getList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }