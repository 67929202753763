"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _merchant = require("@/api/merchant");
var _user = require("@/api/user");
var _saasApi = require("@/api/saasApi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      identityList: [],
      //身份列表
      productList: [],
      //产品列表
      multipleSelection: [],
      //表格选中项
      //遮罩弹窗
      visibleLabel: false,
      labelForm: {
        tagId: [],
        userId: []
      },
      stateForm: {
        name: '',
        companyName: '',
        merchantId: '',
        productPackageId: ''
      },
      tagList: [],
      //表格参数
      queryParams: {
        identityId: "",
        type: "",
        productPackageId: "",
        expireStatus: "",
        keyword: "",
        pageSize: 10,
        pageNum: 1
      },
      //表格 分页
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      // 打开商户
      uid: "",
      showdetail: false,
      sassVisible: false
    };
  },
  created: function created() {
    var _this = this;
    (0, _merchant.getList)().then(function (res) {
      return _this.identityList = res.rows;
    });
    (0, _saasApi.productPackage)().then(function (res) {
      return _this.productList = res.rows;
    });
    (0, _user.labelLstApi)({
      pageNum: 1,
      pageSize: 100
    }).then(function (res) {
      return _this.tagList = res.rows;
    });
  },
  mounted: function mounted() {
    this.getTableList();
  },
  methods: {
    onLogOpen: function onLogOpen(_ref) {
      var merId = _ref.merId;
      (0, _saasApi.loginSaasApi)(merId).then(function (res) {
        var token = res.data.token;
        window.open((res.data.is_test ? "https://saas-merchant.yunzongbu.cn/?token=" : "https://cloud-merchant.yunzongbu.cn/?token=") + token);
      });
    },
    onUpdOpen: function onUpdOpen(row) {
      this.stateForm = {
        name: row.merName,
        companyName: row.companyName,
        merchantId: row.merId,
        productPackageId: row.sysProductPackage ? row.sysProductPackage.productPackageId : '',
        expireDate: row.expireDate ? row.expireDate : ''
      };
      this.sassVisible = true;
    },
    confirmSaaSAction: function confirmSaaSAction(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          console.log(_this2.stateForm);
          (0, _saasApi.merchantUpdate)(_this2.stateForm).then(function () {
            _this2.$message.success("操作成功");
            _this2.sassVisible = false;
            _this2.getTableList();
          });
        }
      });
    },
    getTableList: function getTableList(num) {
      var _this3 = this;
      if (num) this.queryParams.pageNum = num;
      (0, _saasApi.saasMemberList)((0, _objectSpread2.default)({}, this.queryParams)).then(function (res) {
        // console.log(res);
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
      });
    },
    nickNameClick: function nickNameClick(row) {
      //点击商户名称
      this.uid = row.memberId;
      this.showdetail = true;
    },
    //加标签
    batchLabel: function batchLabel(row) {
      if (!row) {
        // 批量
        if (this.multipleSelection.length === 0) return this.$message.warning("请先选择用户");
        var checkIds = this.multipleSelection.map(function (item) {
          return item.memberId;
        });
        this.labelForm.userId = checkIds;
        this.visibleLabel = true;
      } else {
        // 单加
        this.labelForm.userId = row.memberId;
        this.visibleLabel = true;
      }
    },
    // goRouter(name, query = {}) {//有效应用
    //   this.$router.push({ name, query });
    // },
    confirmLabelAction: function confirmLabelAction(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.labelForm.tagId = _this4.labelForm.tagId.join(",");
          (0, _user.addTagApi)(_this4.labelForm).then(function () {
            _this4.$message.success("操作成功");
            if (_this4.labelForm.userId.length > 1) {
              _this4.$refs.multipleTable.clearSelection();
            }
            _this4.visibleLabel = false;
            _this4.getTableList(1);
          });
        }
      });
    },
    pageChange: function pageChange(val) {
      this.getTableList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getTableList(1);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //表格选中
      this.multipleSelection = val;
    }
  }
};