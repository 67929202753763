var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "nav display-flex item-align-center",
        class: { "justify-center": _vm.facade.nav_style === 2 },
        style: {
          backgroundColor: _vm.facade.background_color,
          paddingLeft: _vm.facade.page_padding / 2 + "px",
          height: (_vm.facade.nav_height || 96) / 2 + "px",
        },
      },
      _vm._l(_vm.content.data, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "item",
            class: { ml0: index === 0 },
            style: {
              marginLeft: _vm.facade.item_padding / 2 + "px",
              fontSize: _vm.facade.text_size / 2 + "px",
              fontWeight: _vm.facade.text_style,
              color: _vm.facade.text_color,
              height: "100%",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "txt",
                style: {
                  color: _vm.activeColor(index),
                  fontSize: _vm.activeFontSize(index) / 2 + "px",
                  fontWeight: _vm.activeFontWeight(index),
                  marginTop: _vm.facade.text_margin_top / 2 + "px",
                  lineHeight: _vm.facade.lineHeight / 2 + "px",
                },
              },
              [_vm._v("\n        " + _vm._s(item.title) + "\n      ")]
            ),
            _vm._v(" "),
            _vm.content.active === index
              ? _c("div", {
                  staticClass: "bg",
                  style: { background: _vm.facade.text_active_color },
                })
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content",
        style: { marginTop: (_vm.facade.content_margin || 20) / 2 + "px" },
      },
      [
        _vm.showContent && _vm.showContent.dataType
          ? _c(
              "div",
              [
                _vm.showContent.name === "word"
                  ? _c("Word", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "nftCollection"
                  ? _c("NftCollection", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "nftCollectionAlbum"
                  ? _c("NftCollectionAlbum", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "nftBlindBox"
                  ? _c("Nftblind", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "blindBox"
                  ? _c("BlindBox", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "material"
                  ? _c("NftCollection", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "allPointProducts"
                  ? _c("AllPointProducts", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "nftCollectionMarket"
                  ? _c("NftCollectionMarket", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "Publisher"
                  ? _c("Publisher", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "composeList"
                  ? _c("composeList", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }