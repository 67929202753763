"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponUser',
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  filters: {
    failFilter: function failFilter(status) {
      var statusMap = {
        receive: '自己领取',
        send: '后台发送',
        give: '满赠',
        new: '新人',
        buy: '买赠送'
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: '未使用',
        1: '已使用',
        2: '已过期'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      tableFromIssue: {
        pageNum: 1,
        pageSize: 10,
        couponName: '',
        startTime: '',
        endTime: '',
        memberName: '',
        memberPhone: '',
        status: 1
      },
      issueData: {
        data: [],
        total: 0
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      uid: '',
      showdetail: false
    };
  },
  mounted: function mounted() {
    if (this.$route.query.name) {
      this.tableFromIssue.couponName = this.$route.query.name;
    }
    this.getIssueList();
  },
  methods: {
    // 列表
    getIssueList: function getIssueList() {
      var _this = this;
      this.Loading = true;
      (0, _marketing.receiveListApi)(this.tableFromIssue).then(function (res) {
        _this.issueData.data = res.rows;
        _this.issueData.total = res.total;
        _this.Loading = false;
      }).catch(function (res) {
        _this.Loading = false;
        // this.$message.error(res.message)
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFromIssue.date = tab;
      this.pageChangeIssue(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.tableFromIssue.startTime = this.timeVal ? this.timeVal[0] : '';
      this.tableFromIssue.endTime = this.timeVal ? this.timeVal[1] : '';
      this.pageChangeIssue(1);
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.pageNum = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.pageSize = val;
      this.getIssueList();
    },
    exportOrder: function exportOrder() {
      var _this2 = this;
      var data = (0, _objectSpread2.default)({}, this.tableFromIssue);
      (0, _marketing.useExport)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        _this2.$message.success('操作成功');
        // const h = this.$createElement;
        // this.$msgbox({
        //   title: '提示',
        //   message: h('p', null, [
        //     h('span', null, '文件正在生成中，请稍后点击"'),
        //     h('span', { style: 'color: teal' }, '导出已生成列表'),
        //     h('span', null, '"查看~ ')
        //   ]),
        //   confirmButtonText: '我知道了'
        // }).then(action => { });
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      // this.fileVisible = true;
      this.$refs.exportList.exportFileList(36);
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.memberId;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    }
  }
};