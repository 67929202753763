"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "planeDetail",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      formValidate: {
        type: '',
        productImage: '',
        productName: '',
        count: ''
      },
      ruleValidate: {
        type: [{
          required: true,
          message: "请选择类型"
        }],
        productImage: [{
          required: true,
          message: "请选择图片"
        }],
        productName: [{
          required: true,
          message: "请输入商品名称"
        }],
        count: [{
          required: true,
          message: "请输入商品数量"
        }]
      },
      product: null,
      condition: [{
        image: '',
        name: '',
        neednum: ''
      }],
      conditiontype: '2'
    };
  },
  created: function created() {},
  methods: {
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          var flag = false;
          var json = JSON.parse(JSON.stringify(_this2.formValidate));
          if (json.type == 1) {
            if (!_this2.product) {
              _this2.$message.error('请选择数字藏品');
              return;
            }
            json.productId = _this2.product.p_id;
          }
          (0, _marketing.exchangeTdGoodsCreate)(json).then(function (res) {
            console.log(res);
            _this2.$message.success('创建成功');
            _this2.$router.back();
          });
        } else {
          _this2.$message.error('请完善信息');
        }
      });
    },
    addlist: function addlist(type) {
      this.condition.push({
        image: '',
        name: '',
        neednum: ''
      });
    },
    dellist: function dellist(type, index) {
      if (type == 'output') {
        this.output.splice(index, 1);
      } else if (type == 'condition') {
        this.condition.splice(index, 1);
      } else if (type == 'durability') {
        this.durability.splice(index, 1);
      }
    },
    handleAdditem: function handleAdditem(type, i) {
      this.changeType = type;
      this.changeIndex = i;
      this.$refs.product_nft.changeshow();
    },
    // 修改图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          console.log(img);
          _this.formValidate[num] = img[0];
          _this.$refs.formValidate.clearValidate();
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = '';
    },
    backAction: function backAction() {
      this.$router.back();
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      this.product = data.data[0];
      this.formValidate.productName = this.product.name;
      this.formValidate.productImage = this.product.image;
    }
  }
};