var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _vm.detail
      ? _c(
          "div",
          {
            ref: "toImg",
            staticClass: "con",
            style:
              "background: " +
              (_vm.detail.backgroundColor || "#000") +
              ";color: " +
              (_vm.detail.textColor || "#fff") +
              ";font-family: newYtxFamily;",
            attrs: { id: "toImgMain" },
          },
          [
            _c(
              "div",
              {
                staticClass: "logo item-flex-center",
                class: {
                  pt916: _vm.detail.ratio == "9:16",
                  pt34: _vm.detail.ratio == "3:4",
                },
              },
              [
                _c("img", {
                  attrs: { src: _vm.detail.merLogo + "!450a", alt: "" },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.type == 1
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "name",
                      class: {
                        pt916: _vm.detail.ratio == "9:16",
                        pt34: _vm.detail.ratio == "3:4",
                      },
                    },
                    [_vm._v(_vm._s(_vm.detail.videoIntro))]
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "content pt80 ft58" }, [
                    _vm._v(_vm._s(_vm.detail.auctionTitle)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content ft58" }, [
                    _vm._v("专场:" + _vm._s(_vm.detail.albumName)),
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "content pt20" }, [
                    _vm._v(_vm._s(_vm.detail.lotNumber)),
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "content pt20" }, [
                    _vm._v(_vm._s(_vm.detail.storeName)),
                  ]),
                  _vm._v(" "),
                  _vm.detail.author
                    ? _c(
                        "div",
                        { staticClass: "title item-flex-center pt80" },
                        [_c("div", { staticClass: "c" }, [_vm._v("作者")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.author
                    ? _c("div", { staticClass: "content pt20" }, [
                        _vm._v(_vm._s(_vm.detail.author)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.age
                    ? _c(
                        "div",
                        { staticClass: "title item-flex-center pt80" },
                        [_c("div", { staticClass: "c" }, [_vm._v("年代")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.age
                    ? _c("div", { staticClass: "content pt20" }, [
                        _vm._v(_vm._s(_vm.detail.age)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.size
                    ? _c(
                        "div",
                        { staticClass: "title item-flex-center pt80" },
                        [_c("div", { staticClass: "c" }, [_vm._v("尺寸")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.size
                    ? _c("div", { staticClass: "content pt20" }, [
                        _vm._v(_vm._s(_vm.detail.size)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c("div", { staticClass: "content pt20 ft58" }, [
                    _vm._v(_vm._s(_vm.detail.transactionPrice)),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "name",
                    domProps: { innerHTML: _vm._s(_vm.detail.content) },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "bottom-view",
                      class: {
                        pt916: _vm.detail.ratio == "9:16",
                        pt34: _vm.detail.ratio == "3:4",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "more pt200",
                          class: {
                            pt916: _vm.detail.ratio == "9:16",
                            pt34: _vm.detail.ratio == "3:4",
                          },
                        },
                        [
                          _vm._v(
                            "抖音/微信 搜索“" +
                              _vm._s(_vm.detail.merMiniName) +
                              "”小程序"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "more",
                          class: {
                            pt916: _vm.detail.ratio == "9:16",
                            pt34: _vm.detail.ratio == "3:4",
                          },
                        },
                        [_vm._v("查看更多内容")]
                      ),
                      _vm._v(" "),
                      _vm.detail.isShowCopyright
                        ? _c("div", { staticClass: "copyright" }, [
                            _vm._v("Powered by 艺天下"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              : _vm.type == 2
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "title item-flex-center pt169",
                      class: {
                        pt916: _vm.detail.ratio == "9:16",
                        pt34: _vm.detail.ratio == "3:4",
                      },
                    },
                    [
                      _c("div", { staticClass: "l" }, [_vm._v("·—")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "c ml20 mr20" }, [
                        _vm._v("拍品信息"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "r" }, [_vm._v("—·")]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _c("div", { staticClass: "content pt20" }, [
                    _vm._v(_vm._s(_vm.detail.lotNumber)),
                  ]),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _c("div", { staticClass: "content pt20" }, [
                    _vm._v(_vm._s(_vm.detail.storeName)),
                  ]),
                  _vm._v(" "),
                  _vm._m(6),
                  _vm._v(" "),
                  _vm.detail.startPrice == 9
                    ? _c("div", { staticClass: "content pt20" }, [
                        _vm._v("价格待定"),
                      ])
                    : _vm.detail.startPrice
                    ? _c("div", { staticClass: "content pt20" }, [
                        _vm._v(
                          _vm._s(_vm.detail.feeType) +
                            " " +
                            _vm._s(_vm.detail.startPrice)
                        ),
                      ])
                    : _c("div", { staticClass: "content pt20" }, [
                        _vm._v("无底价"),
                      ]),
                  _vm._v(" "),
                  _vm.detail.referencePrice && _vm.detail.referencePrice != "-"
                    ? [
                        _vm._m(7),
                        _vm._v(" "),
                        _c("div", { staticClass: "content pt20" }, [
                          _vm._v(
                            _vm._s(_vm.detail.feeType) +
                              " " +
                              _vm._s(_vm.detail.referencePrice)
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.albumStartTime
                    ? [
                        _vm._m(8),
                        _vm._v(" "),
                        _c("div", { staticClass: "content pt20" }, [
                          _vm._v(_vm._s(_vm.detail.albumStartTime)),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.albumScheduledShowTime
                    ? [
                        _vm._m(9),
                        _vm._v(" "),
                        _c("div", { staticClass: "content pt20" }, [
                          _vm._v(_vm._s(_vm.detail.albumScheduledShowTime)),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.addressName
                    ? [
                        _vm._m(10),
                        _vm._v(" "),
                        _c("div", { staticClass: "content pt20" }, [
                          _vm._v(_vm._s(_vm.detail.addressName)),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(11),
                  _vm._v(" "),
                  _vm.detail.bondType == 1
                    ? _c("div", { staticClass: "content pt20" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detail.bondPrice
                              ? _vm.detail.feeType + " " + _vm.detail.bondPrice
                              : "免保证金"
                          )
                        ),
                      ])
                    : _vm.detail.bondType == 2
                    ? _c("div", { staticClass: "content pt20" }, [
                        _vm._v("1:" + _vm._s(_vm.detail.bondBidRatio)),
                      ])
                    : _vm.detail.bondType == 3
                    ? _c("div", { staticClass: "content pt20" }, [
                        _vm._v(
                          "1:" +
                            _vm._s(_vm.detail.bondBidRatio) +
                            " 或 " +
                            _vm._s(_vm.detail.feeType) +
                            " " +
                            _vm._s(_vm.detail.bondPrice)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.commission
                    ? [
                        _vm._m(12),
                        _vm._v(" "),
                        _c("div", { staticClass: "content pt20" }, [
                          _vm._v(_vm._s(_vm.detail.commission) + "%"),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _c(
                      "div",
                      [
                        _vm._l(_vm.detail.attrInfo, function (m, i) {
                          return [
                            _c(
                              "span",
                              { key: i },
                              [
                                i !== 0 ? [_vm._v(" / ")] : _vm._e(),
                                _vm._v(
                                  _vm._s(m.attr_name) +
                                    ":" +
                                    _vm._s(m.attr_value_name)
                                ),
                              ],
                              2
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.detail.content) },
                    }),
                  ]),
                  _vm._v(" "),
                  _vm._m(13),
                  _vm._v(" "),
                  _c("div", { staticClass: "content pt20" }, [
                    _vm._v(
                      _vm._s(_vm.detail.auctionTitle) +
                        " - " +
                        _vm._s(_vm.detail.albumName)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content pt20" }, [
                    _vm._v(
                      "本场共有拍品" + _vm._s(_vm.detail.albumTotalNum) + "件"
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "bottom-view",
                      class: {
                        pt916: _vm.detail.ratio == "9:16",
                        pt34: _vm.detail.ratio == "3:4",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "more pt200",
                          class: {
                            pt916: _vm.detail.ratio == "9:16",
                            pt34: _vm.detail.ratio == "3:4",
                          },
                        },
                        [
                          _vm._v(
                            "抖音/微信 搜索“" +
                              _vm._s(_vm.detail.merMiniName) +
                              "”小程序"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "more",
                          class: {
                            pt916: _vm.detail.ratio == "9:16",
                            pt34: _vm.detail.ratio == "3:4",
                          },
                        },
                        [_vm._v("查看更多内容")]
                      ),
                      _vm._v(" "),
                      _vm.detail.isShowCopyright
                        ? _c("div", { staticClass: "copyright" }, [
                            _vm._v("Powered by 艺天下"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "l" }, [_vm._v("·—")]),
      _vm._v(" "),
      _c("div", { staticClass: "c ml20 mr20" }, [_vm._v("拍品信息")]),
      _vm._v(" "),
      _c("div", { staticClass: "r" }, [_vm._v("—·")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("图录号")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("拍品名")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("成交价")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("图录号")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("名称")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("起拍价")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("估价")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("开拍")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("预展")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("地点")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("保证金")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("佣金")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title item-flex-center pt80" }, [
      _c("div", { staticClass: "c" }, [_vm._v("专场介绍")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }