"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'HeaderNotice',
  data: function data() {
    return {
      activeName: 'second',
      messageList: [],
      needList: [],
      count: 0,
      tabPosition: 'right',
      roterPre: _settings.roterPre
    };
  },
  computed: {},
  watch: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    goDetail: function goDetail(item) {
      item.is_read = 1;
      console.log(this.$router);
      this.$router.push({
        path: this.roterPre + '/station/notice',
        query: {
          id: item.notice_log_id
        }
      });
    },
    // 列表
    getList: function getList() {
      // stationNewsList({ is_read: 0 }).then(res => {
      //   this.messageList = res.data.list;
      //   this.count = res.data.count;
      // }).catch(res => {
      // });
    },
    HandleDelete: function HandleDelete(i) {
      this.messageList.splice(i, 1);
    }
  }
};