"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Uptask",
  data: function data() {
    return {
      userType: "uptask",
      gradeDetail: {}
    };
  },
  mounted: function mounted() {
    this.loadDetail();
  },
  methods: {
    loadDetail: function loadDetail() {
      var _this = this;
      (0, _user.getUpGradeInfoApi)().then(function (res) {
        _this.gradeDetail = res.data;
      });
    },
    updateHandle: function updateHandle() {
      var _this2 = this;
      (0, _user.putUpGradeInfoApi)(this.gradeDetail).then(function () {
        _this2.$message.success("操作成功");
        _this2.$router.back();
      }).catch(function (msg) {
        _this2.$message.error(msg);
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "grade") {
        this.$router.push("/merchant/user/grade");
        return false;
      } else if (tab.name === "uptask") {
        this.$router.push("/merchant/user/uptask");
        return false;
      }
    }
  }
};