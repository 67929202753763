"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _finance = require("@/api/finance");
var _entry = _interopRequireDefault(require("./entry.vue"));
var _output = _interopRequireDefault(require("./output.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import DetailedTable from "./detailed.vue";
var _default2 = exports.default = {
  components: {
    // DetailedTable,
    EntryTable: _entry.default,
    OutputTable: _output.default
  },
  props: {
    params: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        type: "2",
        pageSize: 20,
        pageNum: 1,
        orderByColumn: "",
        // 拍序列
        isAsc: "",
        userId: "",
        user: "",
        // 手机号或用户名
        timeType: "" // 时间类型 today yesterday lately7 lately30 month year 或 yyyy/MM/dd-yyyy/MM/dd
      },
      balanceType: "detailed",
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: ""
      },
      // form: {
      //   pageNum: 1,
      //   pageSize: 20,
      //   timeType: ''
      // },
      timeType: [],
      timeList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }]
    };
  },
  mounted: function mounted() {
    console.log(this.params, 162);
    this.form.userId = this.params.row.id;
    this.form.type = this.params.type;
    this.getList();
  },
  methods: {
    exportOrder: function exportOrder() {
      alert(" 暂未开发");
    },
    getExportFileList: function getExportFileList() {
      alert("暂未开发");
    },
    // 请求列表
    getList: function getList() {
      // this.listLoading = true;
      // this.form.pageNum = num || this.form.pageNum;
      console.log(this.form, 196);
      if (this.form.type !== "2") {
        this.getLists();
      } else {
        this.getListCount();
      }
    },
    getLists: function getLists() {
      var _this = this;
      this.tableData.data = [];
      this.tableData.total = 0;
      (0, _finance.getEntryList)(this.form).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        if (res.statistical) {
          _this.tableData.statistical = res.statistical.count;
        } else {
          _this.tableData.statistical = 0;
        }
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    close: function close() {
      console.log(222);
      this.params.drawer = false;
      this.$emit('closed', false);
      this.$store.commit('user/SET_ISCLOSE', false);
      // this.$store.commit()
    },
    open: function open() {
      console.log(this.params, 162);
      this.form.userId = this.params.row.id;
      this.form.type = this.params.type;
      this.getList();
    },
    getListCount: function getListCount() {
      var _this2 = this;
      var obj = {
        type: ''
      };
      this.tableData.data = [];
      this.tableData.total = 0;
      var forms = Object.assign({}, this.form, obj);
      console.log(forms, 201);
      (0, _finance.getBalanceList)(this.form).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        if (res.statistical) {
          _this2.tableData.statistical = res.statistical.count;
        } else {
          _this2.tableData.statistical = 0;
        }
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    selectChange: function selectChange(tab) {
      this.timeType = [];
      this.form.timeType = tab;
      this.getList();
    },
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.form.timeType = e ? this.timeType.join("-") : "";
      this.getList();
    },
    changeTypeAction: function changeTypeAction(tab) {
      console.log(tab, "tab....");
      this.form.type = tab.name;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.form.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.form.pageSize = val;
      this.getList();
    }
  }
};