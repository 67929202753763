var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { staticClass: "mb-20", attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { label: "时间/ID", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "demand-time" }, [
                      _vm._v(_vm._s(row.createTime)),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(row.id))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "需求标题", prop: "title", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "雇主", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("span", { staticClass: "blue-text" }, [
                      _vm._v(_vm._s(row.employerUser)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "模型类型", width: "100", prop: "modelTypeName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "尺寸要求", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s(row.size))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "工期要求", width: "100", prop: "workDay" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "费用预算", prop: "budget", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "补充材料", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "flex-b flex-c" }, [
                      _c("span", [
                        _vm._v(_vm._s(row.fileCount || 0) + " 个附件"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(row.imageCount || 0) + " 张图片"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(row.videoCoutnt || 0) + " 个视频"),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              fixed: "right",
              align: "center",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "div",
                      { staticClass: "flex-b" },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            staticClass: "blue-text",
                            on: {
                              click: function ($event) {
                                return _vm.editDemand(row)
                              },
                            },
                          },
                          [_vm._v("编辑需求")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: "确认通过[" + row.title + "]的审核吗？",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.onAudited(row)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "blue-text",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v("审核通过")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "blue-text" }, [
                          _vm._v("联系雇主"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: "确认取消需求[" + row.title + "]吗？",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.cancelDemand(row)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "blue-text",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v("取消需求")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "编辑需求",
            visible: _vm.editDemandVisib,
            direction: "rtl",
            "append-to-body": "",
            size: "35%",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDemandVisib = $event
            },
          },
        },
        [_c("EditDemand")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }