"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _modules = _interopRequireDefault(require("./modules"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    NftCollection: _modules.default.nftCollection.widget,
    NftDetail: _modules.default.nftDetail.widget,
    NftBoxDetail: _modules.default.nftBoxDetail.widget,
    Compose: _modules.default.compose.widget,
    Blind: _modules.default.blind.widget,
    BlindDetail: _modules.default.blindDetail.widget,
    Login: _modules.default.login.widget
  },
  data: function data() {
    return {
      is_empty: false,
      id: this.$route.query.id || "",
      type: this.$route.query.type || "album_component"
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    dragIndex: {
      get: function get() {
        return this.$store.state.finish.dragIndex;
      },
      set: function set(value) {
        this.$store.commit("finish/setDragIndex", value);
      }
    },
    position: {
      get: function get() {
        return this.$store.state.finish.position;
      },
      set: function set(value) {
        this.$store.commit("finish/setDragPosition", value);
      }
    },
    select: function select() {
      return this.$store.state.finish.index;
    },
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set(value) {
        this.$store.commit("finish/setPagesData", value);
      }
    },
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    coms: function coms() {
      return this.$store.state.finish.coms;
    }
  },
  watch: {
    $route: function $route(val) {
      if (val.name === "basicsDiy" && val.query.type) {
        this.type = val.query.type;
        this.pages = [];
        this.$store.commit("finish/setPageInfo", {
          show: false
        });
        this.loadPageInfo();
      }
    }
  },
  mounted: function mounted() {
    this.loadPageInfo();
  },
  methods: {
    pageInfoNew: function pageInfoNew(index) {
      return Object.assign({}, this.pageInfo, this.pageInfo.type_setting[this.pages[index].content.style]);
    },
    /**
     * 加载数据操作
     * @return {[type]} [description]
     */
    loadPageInfo: function loadPageInfo() {
      var _this = this;
      (0, _system.diypageDetail)({
        type: this.type
      }).then(function (res) {
        if (res.data.content) res.data.content = JSON.parse(res.data.content);else res.data.content = [];
        if (res.data.config) res.data.config = JSON.parse(res.data.config);else res.data.config = {};
        if (res.data.config && _this.type === "nft_box_detail_page_test") {
          if (!res.data.config.open_bg_image) res.data.config.open_bg_image = "";
          if (!res.data.config.open_effect_image) res.data.config.open_effect_image = "";
          if (!res.data.config.openBtnBgColor) res.data.config.openBtnBgColor = "";
          if (!res.data.config.openBtnBorderColor) res.data.config.openBtnBorderColor = "";
          if (!res.data.config.openBtnColor) res.data.config.openBtnColor = "";
          if (!res.data.config.openBtnBgColorSub) res.data.config.openBtnBgColorSub = "";
          if (!res.data.config.openBtnBorderColorSub) res.data.config.openBtnBorderColorSub = "";
          if (!res.data.config.openBtnColorSub) res.data.config.openBtnColorSub = "";
          if (!res.data.config.open_result_image) res.data.config.open_result_image = "";
          if (!res.data.config.open_result_finish_image) res.data.config.open_result_finish_image = "";
          if (!res.data.config.openResultContentBgColor) res.data.config.openResultContentBgColor = "";
          if (!res.data.config.openResultColor) res.data.config.openResultColor = "";
          if (!res.data.config.openResultPriceColor) res.data.config.openResultPriceColor = "";
          if (!res.data.config.openResultBtnBgColor) res.data.config.openResultBtnBgColor = "";
          if (!res.data.config.openResultBtnBorderColor) res.data.config.openResultBtnBorderColor = "";
          if (!res.data.config.openResultBtnColor) res.data.config.openResultBtnColor = "";
          if (!res.data.config.openResultBtnBgColorSub) res.data.config.openResultBtnBgColorSub = "";
          if (!res.data.config.openResultBtnBorderColorSub) res.data.config.openResultBtnBorderColorSub = "";
          if (!res.data.config.openResultBtnColorSub) res.data.config.openResultBtnColorSub = "";
        }
        if (res.data.content) {
          if (_this.type === "nft_detail_page_test" && res.data.content[9] && res.data.content[9].content.type === "notice") {
            // res.data.content.splice(9, 0, {
            //   content: {
            //     type: 'circulationInfo'
            //   },
            //   facade: {
            //     marginTop: 20,
            //     title: '流转信息',
            //     title_image: '',
            //     imgRadius: 40,
            //     nameColor: '#333333',
            //     nameFontSize: 28,
            //     nameFontWeight: 500,
            //     moreText: '全部记录',
            //     moreBgColor: '',
            //     moreColor: '#666666',
            //     moreRadius: 0,
            //     morePaddingTop: 0,
            //     morePaddingLeft: 0,
            //     moreFontSize: 24,
            //     moreFontWeight: 400,
            //     moreIcon: '',
            //     contentMarginTop: 32,
            //     circularColor: '#000000',
            //     lineColor: '#EEEEEE',
            //     mainColor: '#333333',
            //     mainFontSize: 28,
            //     mainFontWeight: 500,
            //     subColor: '#999999',
            //     subFontSize: 24,
            //     subFontWeight: 400
            //   },
            //   name: 'nftDetail',
            //   title: '流转信息'
            // });
          }
          // res.data.content.splice(11, 0, {
          //   content: {
          //     type: "kind"
          //   },
          //   facade: {
          //     title: "赠送实物",
          //     marginTop: 32,
          //     arrowImg: "",
          //     color: "#FFFFFF",
          //     fontSize: 24,
          //     fontWeight: 400
          //   },
          //   name: "nftDetail",
          //   title: "赠送实物"
          // });
          console.log(res.data.content);
          _this.pages = res.data.content;
        }
        if (res.data.config) {
          if (res.data.id) res.data.config.id = res.data.id;
          _this.$store.commit("finish/setPageInfo", res.data.config);
        }
      }).catch(function (message) {
        _this.$message.error(message);
      });
    },
    /**
     * 拖拽激活
     * @param  {[type]} index    [description]
     * @param  {[type]} position [description]
     * @return {[type]}          [description]
     */
    dragActive: function dragActive(index, position) {
      if (this.dragIndex === index && this.position == position && this.coms) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 拖拽过程事件
     * @return {[type]} [description]
     */
    allowDrop: function allowDrop($event) {
      var index = parseInt($event.target.dataset.index);
      if (index || index === 0) {
        this.dragIndex = index;
        // 判断是否为拖拽元素
        if ($event.type === "dragover" && $event.target.dataset) {
          // 判断是否是拖拽区域
          if ($event.target.dataset.type === "widget") {
            // 获取Y轴移动值
            var y = parseFloat($event.offsetY);
            // 由于缩小50%，需要除去双倍
            var h = parseFloat($event.target.clientHeight / 4);
            if (y < h) {
              this.position = "top";
            } else {
              this.position = "down";
            }
          }
        }
      }
      if (index == -2) {
        this.dragIndex = index;
        this.position = "down";
      }
      $event.preventDefault();
    },
    /**
     * 执行放置，只要在范围内
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    drop: function drop() {
      this.$store.dispatch("finish/pushPagesComs");
    },
    draggableStart: function draggableStart() {
      this.$store.commit("finish/setLock", true);
    },
    draggableEnd: function draggableEnd() {
      this.$store.commit("finish/setLock", false);
    },
    selectMessage: function selectMessage(data) {
      console.log(data);
    }
  }
};