var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex" }, [
    _c(
      "div",
      {
        staticClass: "tag flex align-items-c",
        style: {
          borderRadius: _vm.facade.borderRadius / 2 + "px",
          background: _vm.facade.bgColor,
        },
      },
      [
        _c("img", { attrs: { src: _vm.facade.numIcon, alt: "" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "r-num",
            style: {
              borderTopRightRadius: _vm.facade.borderRadius / 2 + "px",
              borderBottomRightRadius: _vm.facade.borderRadius / 2 + "px",
              background: _vm.facade.numberBgColor,
              color: _vm.facade.numberColor,
            },
          },
          [_vm._v("AC001#0900/6000")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }