"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _saasApi = require("@/api/saasApi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["productList", "editPackageInfo"],
  data: function data() {
    return {
      formState: {
        productPackageId: "",
        systemType: "",
        packageName: "",
        versionName: "",
        relationProductId: "",
        packagePrice: null,
        remark: "",
        systemPrice: "" // 添加独立套餐
      },
      rules: {
        packageName: [{
          required: true,
          message: "请选择套餐名称",
          trigger: "change"
        }],
        versionName: [{
          required: true,
          message: "请输入版本名称",
          trigger: "change"
        }],
        relationProductId: [{
          required: true,
          message: "请选择套餐功能",
          trigger: "change"
        }],
        packagePrice: [{
          required: true,
          message: "请输入套餐价格",
          trigger: "change"
        }],
        systemPrice: [{
          required: true,
          message: "请输入系统价格",
          trigger: "change"
        }, {
          pattern: /^[1-9]\d*$/,
          message: "系统价格只能是整数",
          trigger: "change"
        }]
      },
      relationProductList: [{
        productId: "",
        price: ""
      }],
      priceList: [{
        number: 1,
        dateType: "year",
        price: ""
      }, {
        number: 3,
        dateType: "year",
        price: ""
      }, {
        number: 5,
        dateType: "year",
        price: ""
      }],
      indepPriceList: [{
        number: 1,
        dateType: "month",
        price: ""
      }, {
        number: 3,
        dateType: "month",
        price: ""
      }, {
        number: 6,
        dateType: "month",
        price: ""
      }, {
        number: 12,
        dateType: "month",
        price: ""
      }],
      packageNames: [],
      isCreate: false,
      timer: 0
    };
  },
  created: function created() {
    if (this.productList[0].systemType) {
      this.formState.systemType = this.productList[0].systemType;
    }
    this.getPackageNames();
  },
  mounted: function mounted() {
    this.editPackageInfo && this.dataPlayback();
  },
  watch: {
    relationProductList: {
      handler: function handler(nval) {
        var _this = this;
        nval.forEach(function (item) {
          if (item.productId) {
            var obj = _this.productList.find(function (i) {
              return i.productId == item.productId;
            });
            if (obj) {
              item.price = obj.price;
            }
          }
        });
        this.productList.forEach(function (item) {
          var obj = nval.find(function (i) {
            return i.productId == item.productId;
          });
          if (obj) item.isShow = false;else item.isShow = true;
        });
        var arr = nval.filter(function (item) {
          return item.productId;
        }).map(function (item) {
          return item.productId;
        });
        this.formState.relationProductId = arr;
      },
      deep: true
    },
    priceList: {
      handler: function handler(nval) {
        if (Date.now() - this.timer < 300) return;
        this.formState.packagePrice = nval.filter(function (item) {
          return item.price;
        }) || [];
        this.timer = Date.now();
      },
      deep: true
    },
    indepPriceList: {
      handler: function handler(nval) {
        if (Date.now() - this.timer < 300) return;
        this.formState.packagePrice = nval.filter(function (item) {
          return item.price;
        }) || [];
        this.timer = Date.now();
      },
      deep: true
    }
  },
  methods: {
    deleteProduct: function deleteProduct(item) {
      if (this.relationProductList.length <= 1) return;
      this.relationProductList = this.relationProductList.filter(function (itm) {
        return itm.productId != item.productId;
      });
    },
    getPackageNames: function getPackageNames() {
      var _this2 = this;
      (0, _saasApi._getPackageNames)(this.formState.systemType).then(function (res) {
        _this2.packageNames = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    onIsCreate: function onIsCreate() {
      this.isCreate = !this.isCreate;
      this.formState.packageName = "";
    },
    changeSystemType: function changeSystemType() {
      this.getPackageNames();
      if (!this.isCreate) this.formState.packageName = "";
    },
    addFeature: function addFeature() {
      var len = this.relationProductList.length;
      if (!this.relationProductList[len - 1].productId) return;
      this.relationProductList.push({
        productId: "",
        price: ""
      });
    },
    dataPlayback: function dataPlayback() {
      var _this3 = this;
      var _this$editPackageInfo = this.editPackageInfo,
        systemType = _this$editPackageInfo.systemType,
        packageName = _this$editPackageInfo.packageName,
        versionName = _this$editPackageInfo.versionName,
        relationProductItem = _this$editPackageInfo.relationProductItem,
        packagePrice = _this$editPackageInfo.packagePrice,
        remark = _this$editPackageInfo.remark,
        productPackageId = _this$editPackageInfo.productPackageId,
        systemPrice = _this$editPackageInfo.systemPrice;
      this.formState.systemType = systemType;
      this.formState.packageName = packageName;
      this.formState.versionName = versionName;
      this.formState.remark = remark;
      this.formState.productPackageId = productPackageId;
      this.formState.systemPrice = systemPrice;
      // saas系统 时长和价格回显
      if (systemType == 1) {
        if (packagePrice.length) {
          packagePrice.forEach(function (item, index) {
            if (index > 2) return;
            _this3.priceList[index].price = item.price;
          });
        }
      }
      //独立系统 时长和价格回显
      if (systemType == 2) {
        if (packagePrice.length) {
          packagePrice.forEach(function (item, index) {
            if (index > 2) return;
            _this3.indepPriceList[index].price = item.price;
          });
        }
      }
      if (relationProductItem.length) {
        relationProductItem.forEach(function (item, index) {
          if (_this3.relationProductList[index]) {
            _this3.relationProductList.splice(index, 1, {
              productId: item.productId,
              price: item.price
            });
          } else {
            _this3.relationProductList.push({
              productId: item.productId,
              price: item.price
            });
          }
        });
      }
    },
    onSubmit: function onSubmit() {
      var _this4 = this;
      this.$refs.formRef.validate(function (valid) {
        if (valid) {
          if (Array.isArray(_this4.formState.relationProductId)) {
            _this4.formState.relationProductId = _this4.formState.relationProductId.join(",");
          }
          if (!_this4.formState.productPackageId) {
            // 添加
            (0, _saasApi.saasProductCreate)(_this4.formState).then(function (res) {
              _this4.$message({
                message: "添加成功",
                type: "success"
              });
              _this4.$emit("changeDrawerVisib", false);
            }).catch(function (err) {
              console.log(err);
            });
          } else {
            // 编辑
            (0, _saasApi.saasProductUpdate)(_this4.formState).then(function (res) {
              _this4.$message({
                message: "编辑成功",
                type: "success"
              });
              _this4.$emit("changeDrawerVisib", false);
            }).catch(function (err) {
              console.log(err);
            });
          }
        }
      });
    }
  }
};