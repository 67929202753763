"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts/core"));
var _index = _interopRequireDefault(require("../mixin/index"));
var _order = require("@/api/order");
//
//
//
//

// import echarts from "echarts";

// import { PieChart } from "echarts/charts";
// import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
// echarts.use([PieChart, CanvasRenderer]);
require("echarts/theme/macarons"); // echarts theme
var _default = exports.default = {
  mixins: [_index.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    amount: {
      type: Boolean,
      default: true
    },
    date: {
      type: String,
      default: "lately7"
    }
  },
  data: function data() {
    return {
      chart: null,
      newData: "",
      oldData: "",
      Comment: []
    };
  },
  watch: {
    amount: {
      deep: true,
      handler: function handler(val) {
        this.amount = val;
        this.getTurnoverRatio();
      }
    },
    date: {
      deep: true,
      handler: function handler(val) {
        this.date = val;
        this.getTurnoverRatio();
      }
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {});
    // this.getTurnoverRatio();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    getTurnoverRatio: function getTurnoverRatio() {
      console.log("888");
      var that = this;
      (0, _order.memberStatistics)({
        timeType: this.date
      }).then(function (res) {
        if (res.code === 200) {
          console.log(res.data, 82);
          var dateTime = res.data.map(function (item, index) {
            return item.date;
          });
          var countList = res.data.map(function (y, i) {
            return y.count;
          });
          that.chart = echarts.init(that.$el, "macarons");
          var option = {
            tooltip: {
              show: true
            },
            grid: {
              left: '1%',
              // 默认10%
              right: '0%',
              // 默认10%
              bottom: '8%',
              // 默认60
              containLabel: true
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: dateTime,
              name: '时间',
              axisLabel: {
                interval: 0,
                rotate: 30
              }
            },
            yAxis: {
              type: "value",
              name: '用户数量'
            },
            series: [{
              data: countList,
              type: "line",
              tooltip: {
                valueFormatter: function valueFormatter(value) {
                  return "人数:" + value;
                }
              },
              areaStyle: {}
            }]
          };
          that.chart.setOption(option);
        }
      }).catch(function (res) {
        that.$message.error(res.message);
      });
    }
  }
};