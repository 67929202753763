"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      integralData: null,
      integralType: '',
      integralTypeList: []
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    var integralData = localStorage.getItem('integralData');
    if (integralData) {
      this.integralData = JSON.parse(integralData);
    }
    var integralTypeList = localStorage.getItem('integralTypeList');
    if (integralTypeList) {
      this.integralTypeList = JSON.parse(integralTypeList);
    }
    var changeType = localStorage.getItem('changeType');
    if (changeType) {
      this.integralType = Number(changeType);
    } else {
      this.integralType = 0;
    }
    this.getTypeList();
    this.loadStreamInfo();
  },
  methods: {
    changeTypeAction: function changeTypeAction(val) {
      localStorage.setItem('changeType', val);
      this.loadStreamInfo();
    },
    getTypeList: function getTypeList() {
      var _this = this;
      (0, _marketing.integralTypeListApi)().then(function (res) {
        _this.integralTypeList = res.data;
        localStorage.setItem('integralTypeList', JSON.stringify(res.data));
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    changeTab: function changeTab(data) {
      if (data !== this.$route.path) {
        this.$router.push({
          path: data
        });
      }
    },
    loadStreamInfo: function loadStreamInfo() {
      var _this2 = this;
      var data = {};
      if (this.integralType) {
        data.scoreTypeId = this.integralType;
      }
      (0, _marketing.integralConfStatisticsApi)(data).then(function (res) {
        _this2.integralData = res.data;
        localStorage.setItem('integralData', JSON.stringify(res.data));
      });
    }
  }
};