"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AIGCApi = require("@/api/AIGCApi");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "prompt",
  components: {},
  data: function data() {
    return {
      input: '',
      promptText: '',
      chatList: [],
      textChatList: [],
      timeout: null,
      responseIng: false,
      conversationId: ''
    };
  },
  created: function created() {
    this.init();
    this.createWebScoket();
  },
  methods: {
    createWebScoket: function createWebScoket() {
      var that = this;
      var token = (0, _auth.getToken)();
      var ws = new WebSocket("".concat(_settingMer.default.wsSocketBaseUrl, "/ai/talk/sys/ws/").concat(this.$route.query.id, "?token=").concat(token));
      setInterval(function () {
        ws.send(JSON.stringify({
          type: "ping"
        }));
      }, 10000);
      ws.onopen = function () {
        console.log('连接服务端成功了');
      };
      ws.onmessage = function (res) {
        var data = JSON.parse(res.data);
        if (data.type == "PONG") return;
        console.log(data);
        that.textChatList.push(data);
        that.handleSetTimeOutText();
      };
      ws.onclose = function () {
        console.log('连接服务端失败');
        that.createWebScoket();
      };
    },
    handleSetTimeOutText: function handleSetTimeOutText() {
      //生成ai文字
      var that = this;
      var len = that.chatList.length;
      if (this.timeout) return false;
      if (that.textChatList.length <= 0) return false;
      this.timeout = setTimeout(function () {
        if (that.textChatList[0].streamStatus == "end") {
          that.conversationId = that.textChatList[0].conversationId;
          that.responseIng = false;
        } else {
          that.chatList[len - 1].context += "".concat(that.textChatList[0].context || "");
        }
        that.textChatList.splice(0, 1);
        that.timeout = null;
        that.handleSetTimeOutText();
      }, 100);
    },
    init: function init() {
      var _this = this;
      (0, _AIGCApi.getPrompt)(this.$route.query.id).then(function (res) {
        // console.log(res);
        if (res.code == 200) {
          _this.promptText = res.data.prompt;
        }
      });
    },
    onSend: function onSend() {
      if (this.input == '') return;
      this.chatList.push({
        type: 'my',
        context: this.input
      }, {
        type: 'ai',
        context: '',
        status: 'loading'
      });
      this.responseIng = true;
      var message = this.input;
      this.input = '';
      (0, _AIGCApi.fineAdjustment)(this.$route.query.id, {
        message: message,
        conversationId: this.conversationId
      });
    },
    goBack: function goBack() {
      this.$router.push("/merchant/AIGC/numberPer");
    },
    onSubmit: function onSubmit() {
      (0, _AIGCApi.updatePrompt)(this.$route.query.id, {
        prompt: this.promptText
      }).then(function (res) {
        console.log(res);
      });
    }
  }
};