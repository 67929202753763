"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _open3Dgrade = require("@/api/open3Dgrade");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    type: {
      type: String,
      default: "1"
    }
  },
  data: function data() {
    return {
      list: {
        data: [],
        total: 0
      },
      id: "",
      listLoading: false,
      pageConfig: {
        pageNum: 1,
        pageSize: 20
      },
      dialogTableVisible: false,
      info: {},
      columnData: [{
        label: "关键指标",
        prop: "targetName"
      }, {
        label: "动作",
        prop: "actionName"
      }, {
        label: "属性",
        prop: "attributeName"
      }, {
        label: "分账",
        prop: "special"
      }, {
        label: "D1",
        prop: "one"
      }, {
        label: "D2",
        prop: "two"
      }, {
        label: "D3",
        prop: "three"
      }, {
        label: "D4",
        prop: "four"
      }, {
        label: "D5p",
        prop: "five"
      }, {
        label: "D6p",
        prop: "six"
      }, {
        label: "D7p",
        prop: "seven"
      }, {
        label: "D8p",
        prop: "eight"
      }, {
        label: "D9p",
        prop: "nine"
      }, {
        label: "操作",
        prop: "handle",
        position: "right"
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      // this.pageConfig.pageNum = num || this.pageConfig.pageNum;
      (0, _open3Dgrade.getRecommendListApi)({
        type: this.type
      }).then(function (res) {
        _this.list.data = res.data;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    edit: function edit(id) {
      var _this2 = this;
      (0, _open3Dgrade.getRecommendInfoApi)(id).then(function (res) {
        var _res$data = res.data,
          one = _res$data.one,
          two = _res$data.two,
          three = _res$data.three,
          four = _res$data.four,
          five = _res$data.five,
          six = _res$data.six,
          seven = _res$data.seven,
          eight = _res$data.eight,
          nine = _res$data.nine;
        _this2.id = res.data.id;
        var obj = {
          one: one,
          two: two,
          three: three,
          four: four,
          five: five,
          six: six,
          seven: seven,
          eight: eight,
          nine: nine
        };
        var info = {};
        for (var i in obj) {
          info[i] = JSON.parse(obj[i]);
        }
        _this2.info = info;
        _this2.dialogTableVisible = true;
      });
    },
    toJson: function toJson() {},
    save: function save() {
      var _this3 = this;
      var data = {};
      for (var i in this.info) {
        if (this.info[i]) {
          data[i] = JSON.stringify(this.info[i]);
        }
      }
      data.id = this.id;
      (0, _open3Dgrade.updateRecommendInfoApi)(data).then(function (res) {
        _this3.getList();
        _this3.$message.success("编辑成功");
        _this3.dialogTableVisible = false;
      });
    }
  }
};