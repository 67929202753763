var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { height: "100%" } },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [_c("upload-from", { attrs: { changeType: 3 } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }