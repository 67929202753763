"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _open3dAccount = require("@/api/open3dAccount");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      queryParams: {
        payType: "",
        timeType: "",
        approvalStatus: "0",
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0,
        money: 0
      },
      timeType: [],
      listLoading: false,
      multipleSelection: [],
      timeList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }],
      uid: '',
      showdetail: false
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    nickNameClick: function nickNameClick(id) {
      //点击商户名称
      this.uid = id;
      this.showdetail = true;
    },
    approval: function approval(type) {
      var _this = this;
      var data = {
        ids: this.multipleSelection,
        type: type
      };
      this.$confirm("\u786E\u8BA4\u4F7F\u7528".concat(type == 1 ? "杉德代付" : type == 2 ? "银行汇款" : "支付宝", "\u6C47\u6B3E\u5417?"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _open3dAccount.approvalApi)(data).then(function (res) {
          _this.$message.success("汇款成功");
          _this.getList(1);
        });
      }).catch(function () {});
    },
    checkSelectSet: function checkSelectSet(row, index) {
      return row.status == 0;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val.map(function (item) {
        return item.id;
      });
    },
    exportOrder: function exportOrder() {
      var _this2 = this;
      (0, _open3dAccount.withdrawalExportApi)(this.queryParams).then(function (res) {
        _this2.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(82);
    },
    confirm: function confirm() {
      this.dialogVisible = false;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList("");
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _open3dAccount.withdrawalListApi)(this.queryParams).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.tableData.money = res.statistical.count;
        _this3.listLoading = false;
      }).catch(function (_ref) {
        var message = _ref.message;
        _this3.listLoading = false;
      });
    },
    selectChange: function selectChange(tab) {
      this.getList(1);
    },
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.date = e ? this.timeType.join("-") : "";
      this.queryParams.timeType = this.queryParams.date;
      this.getList(1);
    }
  }
};