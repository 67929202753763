"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStyleUpd = exports.updateStyleStatus = exports.updatePrompt = exports.styleDel = exports.productUpdate = exports.productSave = exports.productList = exports.productDel = exports.humanStyleSave = exports.getWorkStyleApi = exports.getStyleListApi = exports.getPrompt = exports.getMemberList = exports.getImageList = exports.getArtistListApi = exports.getArtistInfoApi = exports.fineAdjustment = exports.artistUpdateSave = exports.artistCreateSave = exports._deleteMerber = exports._changeMerberStatus = exports._changeImage = void 0;
var _request = _interopRequireDefault(require("./request"));
//  获取数字人列表
var getMemberList = exports.getMemberList = function getMemberList(state) {
  return _request.default.get("/open3d/sys/digital/human/list", state);
};
var getImageList = exports.getImageList = function getImageList(data) {
  return _request.default.get("/open3d/sys/digital/human/product/imageList", data);
};

// 修改数字人状态
var _changeMerberStatus = exports._changeMerberStatus = function _changeMerberStatus(state) {
  return _request.default.post("/open3d/sys/digital/human/updateStatus", state);
};

// 删除数字人
var _deleteMerber = exports._deleteMerber = function _deleteMerber(id) {
  return _request.default.delete("/open3d/sys/digital/human/sysDel/".concat(id));
};

// 修改二维三维形象
var _changeImage = exports._changeImage = function _changeImage(state) {
  return _request.default.post("/open3d/sys/digital/human/updateDimensionalImage", state);
};

// 获取数字人提示词
var getPrompt = exports.getPrompt = function getPrompt(id) {
  return _request.default.get("/open3d/sys/digital/human/getPrompt/".concat(id));
};

// 获取数字人提示词
var updatePrompt = exports.updatePrompt = function updatePrompt(id, data) {
  return _request.default.put("/open3d/sys/digital/human/updatePrompt/".concat(id), data);
};

// 
var fineAdjustment = exports.fineAdjustment = function fineAdjustment(id, data) {
  return _request.default.post("/open3d/sys/digital/human/fineAdjustment/".concat(id, " "), data);
};

// 
var productList = exports.productList = function productList(data) {
  return _request.default.get("/open3d/sys/digital/human/product/list", data);
};
// 
var productSave = exports.productSave = function productSave(data) {
  return _request.default.post("/open3d/sys/digital/human/product/save", data);
};
// 
var productUpdate = exports.productUpdate = function productUpdate(data) {
  return _request.default.put("/open3d/sys/digital/human/product/update", data);
};
// 
var productDel = exports.productDel = function productDel(id) {
  return _request.default.delete("/open3d/sys/digital/human/product/delete/".concat(id));
};
// 获取数字人的作品风格
var getWorkStyleApi = exports.getWorkStyleApi = function getWorkStyleApi(data) {
  return _request.default.get("/open3d/sys/digital/human/train/list", data);
};
// 更新作品风格的状态
var updateStyleStatus = exports.updateStyleStatus = function updateStyleStatus(data) {
  return _request.default.put("/open3d/sys/digital/human/train/update", data);
};
var getStyleListApi = exports.getStyleListApi = function getStyleListApi(data) {
  return _request.default.get("/open3d/sys/digital/human/style/list", data);
};
var humanStyleSave = exports.humanStyleSave = function humanStyleSave(data) {
  return _request.default.post("/open3d/sys/digital/human/style", data);
};
var updateStyleUpd = exports.updateStyleUpd = function updateStyleUpd(data) {
  return _request.default.put("/open3d/sys/digital/human/style", data);
};
var styleDel = exports.styleDel = function styleDel(id) {
  return _request.default.delete("/open3d/sys/digital/human/style/delete//".concat(id));
};
var getArtistListApi = exports.getArtistListApi = function getArtistListApi(data) {
  return _request.default.get("/open3d/member/sys/artist/list", data);
};
var getArtistInfoApi = exports.getArtistInfoApi = function getArtistInfoApi(data) {
  return _request.default.get("/open3d/member/sys/artist/info", data);
};
var artistCreateSave = exports.artistCreateSave = function artistCreateSave(data) {
  return _request.default.post("/open3d/member/sys/artist/create", data);
};
var artistUpdateSave = exports.artistUpdateSave = function artistUpdateSave(data) {
  return _request.default.post("/open3d/member/sys/artist/update", data);
};