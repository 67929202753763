var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-content" },
    [
      _c("div", { staticClass: "view flex" }, [
        _c("div", { staticClass: "l" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex flex-one",
              staticStyle: { "flex-direction": "column", overflow: "hidden" },
            },
            [
              _c("div", { staticClass: "type mt20" }, [
                _c("div", { staticClass: "t fWeight500" }, [
                  _vm._v("组件类型"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex flex-wrap c mt10" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        class: { curr: "" == _vm.tableFrom.templateType },
                        on: {
                          click: function ($event) {
                            return _vm.changePurpose("templateType", "")
                          },
                        },
                      },
                      [_vm._v("\n              全部")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.categores, function (m, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "item",
                          class: {
                            curr: m.value == _vm.tableFrom.templateType,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changePurpose("templateType", m.value)
                            },
                          },
                        },
                        [_vm._v("\n              " + _vm._s(m.label))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "type mt20" }, [
                _c("div", { staticClass: "t fWeight500" }, [
                  _vm._v("组件比例"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex flex-wrap c mt10" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      class: { curr: "" == _vm.tableFrom.ratio },
                      on: {
                        click: function ($event) {
                          return _vm.changePurpose("ratio", "")
                        },
                      },
                    },
                    [_vm._v("\n              全部")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      class: { curr: "9:16" == _vm.tableFrom.ratio },
                      on: {
                        click: function ($event) {
                          return _vm.changePurpose("ratio", "9:16")
                        },
                      },
                    },
                    [_vm._v("9:16")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      class: { curr: "16:9" == _vm.tableFrom.ratio },
                      on: {
                        click: function ($event) {
                          return _vm.changePurpose("ratio", "16:9")
                        },
                      },
                    },
                    [_vm._v("16:9")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      class: { curr: "3:4" == _vm.tableFrom.ratio },
                      on: {
                        click: function ($event) {
                          return _vm.changePurpose("ratio", "3:4")
                        },
                      },
                    },
                    [_vm._v("3:4")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "temp mt20 flex-one",
                  staticStyle: { overflow: "hidden" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex t fWeight500 align-items-c" },
                    [
                      _c("div", { staticClass: "flex-one" }, [_vm._v("组件")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            staticClass: "w200",
                            attrs: {
                              placeholder: "组件名称搜索",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "name", $$v)
                              },
                              expression: "tableFrom.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "c mt10" }, [
                    _c(
                      "div",
                      { staticClass: "c-view" },
                      _vm._l(_vm.tableData.data, function (m, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "temp-item",
                            on: {
                              click: function ($event) {
                                return _vm.changeComponentAction(m)
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(m.name)),
                            _c("span", { staticClass: "ft12 color-gray ml5" }, [
                              _vm._v("(" + _vm._s(m.ratio) + ")"),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "page" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-size": _vm.tableFrom.pageSize,
                          "current-page": _vm.tableFrom.pageNum,
                          layout: "total, prev, next, jumper",
                          total: _vm.tableData.total,
                        },
                        on: { "current-change": _vm.pageChange },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "preview" }, [
          _c(
            "div",
            {
              staticClass: "top flex",
              class: _vm.useComponent.ratio == "9:16" ? "shu" : "heng",
            },
            [
              _c("div", { staticClass: "pre-component" }, [
                _c("div", { staticClass: "fWeight500" }, [
                  _vm._v("组件预览 "),
                  _vm.useComponent.name
                    ? _c("span", { staticClass: "ft12 color-gray" }, [
                        _vm._v("(" + _vm._s(_vm.useComponent.name) + ")"),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "video" },
                  [
                    _vm.moduleVisible
                      ? _c("VideoComponent", {
                          ref: "videoTimeRef",
                          on: { load: _vm.onLoad },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "use-btn flex" },
                  [
                    _vm.useComponent.id
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.editComponent },
                            },
                            [_vm._v("修改")]
                          ),
                          _vm._v(" "),
                          !_vm.useComponent.webId ||
                          _vm.templateIds.indexOf(_vm.useComponent.webId) == -1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.addTemplateAction },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.useComponent.webId ||
                                        _vm.templateIds.indexOf(
                                          _vm.useComponent.webId
                                        ) == -1
                                        ? "使用"
                                        : "修改"
                                    ) + "组件"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pre-set" }, [
                _c("div", { staticClass: "fWeight500" }, [
                  _vm._v("设置测试数据"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "set-auction",
                    class: _vm.useComponent.ratio == "9:16" ? "" : "flex",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "item mt20" },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "输入商户名搜索",
                              clearable: "",
                              "remote-method": _vm.searchMerAction,
                              loading: _vm.searchLoading,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeSelect("mer")
                              },
                            },
                            model: {
                              value: _vm.testData.merId,
                              callback: function ($$v) {
                                _vm.$set(_vm.testData, "merId", $$v)
                              },
                              expression: "testData.merId",
                            },
                          },
                          _vm._l(_vm.merList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { value: m.mer_id, label: m.mer_name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item mt20",
                        class: _vm.useComponent.ratio == "9:16" ? "" : "ml10",
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "输入拍卖会搜索",
                              clearable: "",
                              "remote-method": _vm.searchVenueAction,
                              loading: _vm.searchLoading,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeSelect("venue")
                              },
                            },
                            model: {
                              value: _vm.testData.venueId,
                              callback: function ($$v) {
                                _vm.$set(_vm.testData, "venueId", $$v)
                              },
                              expression: "testData.venueId",
                            },
                          },
                          _vm._l(_vm.venueList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { value: m.venue_id, label: m.title },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item mt20",
                        class: _vm.useComponent.ratio == "9:16" ? "" : "ml10",
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "输入专场搜索",
                              clearable: "",
                              "remote-method": _vm.searchAlbumAction,
                              loading: _vm.searchLoading,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeSelect("album")
                              },
                            },
                            model: {
                              value: _vm.testData.albumId,
                              callback: function ($$v) {
                                _vm.$set(_vm.testData, "albumId", $$v)
                              },
                              expression: "testData.albumId",
                            },
                          },
                          _vm._l(_vm.albumList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { value: m.album_id, label: m.album_name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item mt20",
                        class: _vm.useComponent.ratio == "9:16" ? "" : "ml10",
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "输入拍品搜索",
                              clearable: "",
                              "remote-method": _vm.searchAuctionAction,
                              loading: _vm.searchLoading,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeSelect("auction")
                              },
                            },
                            model: {
                              value: _vm.testData.auctionId,
                              callback: function ($$v) {
                                _vm.$set(_vm.testData, "auctionId", $$v)
                              },
                              expression: "testData.auctionId",
                            },
                          },
                          _vm._l(_vm.auctionList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: {
                                value: m.product_id,
                                label: m.product.store_name,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "fWeight500",
                    style:
                      _vm.useComponent.ratio == "9:16"
                        ? "margin-top: 40px;"
                        : "margin-top: 20px;",
                  },
                  [_vm._v("其它设置")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "set-auction",
                    class: _vm.useComponent.ratio == "9:16" ? "" : "flex",
                  },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "flex-one" }, [
                        _c("div", { staticClass: "ft14 mt20" }, [
                          _vm._v("字体选择"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "item mt10" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "选择字体",
                                  "popper-class": "custom-select-popper",
                                },
                                model: {
                                  value: _vm.testData.fontId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.testData, "fontId", $$v)
                                  },
                                  expression: "testData.fontId",
                                },
                              },
                              _vm._l(_vm.fontList, function (m, i) {
                                return _c("el-option", {
                                  key: i,
                                  attrs: { value: m.id, label: m.name },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex-one",
                          class:
                            _vm.useComponent.ratio == "9:16" ? "ml20" : "ml10",
                        },
                        [
                          _c("div", { staticClass: "ft14 mt20" }, [
                            _vm._v("字幕字体"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item mt10" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "选择字体",
                                    "popper-class": "custom-select-popper",
                                  },
                                  model: {
                                    value: _vm.testData.subtitleFontId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.testData,
                                        "subtitleFontId",
                                        $$v
                                      )
                                    },
                                    expression: "testData.subtitleFontId",
                                  },
                                },
                                _vm._l(_vm.fontList, function (m, i) {
                                  return _c("el-option", {
                                    key: i,
                                    attrs: { value: m.id, label: m.name },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class:
                          _vm.useComponent.ratio == "9:16" ? "" : "flex ml10",
                      },
                      [
                        _c("div", { staticClass: "mt20 flex-one" }, [
                          _c("div", { staticClass: "ft14" }, [
                            _vm._v("背景音乐"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item mt10" },
                            [
                              _c("el-cascader", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  options: _vm.backgroundMusic,
                                  props: {
                                    multiple: true,
                                    checkStrictly: true,
                                    emitPath: false,
                                    value: "id",
                                    label: "name",
                                  },
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.testData.bgmId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.testData, "bgmId", $$v)
                                  },
                                  expression: "testData.bgmId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "mt20 flex-one",
                            class:
                              _vm.useComponent.ratio == "9:16" ? "" : "ml10",
                          },
                          [
                            _c("div", { staticClass: "ft14" }, [
                              _vm._v("讲解音色"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item mt10" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "选择讲解音色",
                                    },
                                    model: {
                                      value: _vm.testData.voiceId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.testData, "voiceId", $$v)
                                      },
                                      expression: "testData.voiceId",
                                    },
                                  },
                                  _vm._l(_vm.voiceList, function (m, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "preview-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.generationAction },
                      },
                      [_vm._v("预览模版")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "save-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.saveAction },
                      },
                      [_vm._v("保存模版")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "template-list" },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "board-column-content flex",
                    attrs: { list: _vm.templateList },
                    on: { sort: _vm.whetherCombination },
                  },
                  "draggable",
                  _vm.$attrs,
                  false
                ),
                _vm._l(_vm.templateList, function (m, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "template",
                      class: { "has-border": m.bindPrefixComponentId },
                      on: {
                        click: function ($event) {
                          return _vm.editTemplateAction(m)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "img" }, [
                        m.image
                          ? _c("img", {
                              attrs: {
                                src:
                                  m.image +
                                  "?x-oss-process=image/resize,m_lfit,w_200,limit_0",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "name line1" }, [
                        _vm._v(_vm._s(m.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "del",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delTemplateAction(m, i)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "add-ani",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.addAniAction(i)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202481/image/1722490283280638481.png!120a",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      m.bindAfterComponentId
                        ? _c(
                            "div",
                            {
                              staticClass: "unlink",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delTemplateLinkAction(i, "after")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2024717/image/1721216476554713631.png!120a",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      m.bindPrefixComponentId
                        ? _c(
                            "div",
                            {
                              staticClass: "unlink",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delTemplateLinkAction(i, "prefix")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2024717/image/1721216476554713631.png!120a",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mt20",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          m.componentsList && m.componentsList.length
                            ? _vm._l(m.componentsList, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          width: "200",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "100%" },
                                          attrs: { src: item.image },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex align-items-c",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-one line1 ft12",
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "add ft12",
                                                attrs: { href: "javascipt:;" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delComponentAction(
                                                      i,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("移除")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "add",
                              attrs: { href: "javascipt:;" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showComponentAction(m, i)
                                },
                              },
                            },
                            [_vm._v("添加组件")]
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "videoEL",
              staticClass: "video",
              style:
                "margin: 15px 0;" +
                (_vm.previewRatio == "9:16"
                  ? "width: 344px;height: 652px"
                  : "width: 630px;height: 396px"),
            },
            [
              _c("iframe", {
                ref: "previewIframe",
                staticStyle: { border: "none" },
                attrs: {
                  src: "/merchant/video/previewVideo",
                  width: "100%",
                  height: "100%",
                },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showEdit,
            title: "修改模版",
            "append-to-body": "",
            width: "80%",
            top: "7vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEdit = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "edit-component", staticStyle: { height: "80vh" } },
            [
              _c("iframe", {
                ref: "editIframe",
                staticStyle: { border: "none" },
                attrs: { src: _vm.editUrl, width: "100%", height: "100%" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.animationData.show,
            title: "设置出/入场动画",
            "append-to-body": "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.animationData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt10" },
            [
              _c("div", { staticClass: "w100" }, [_vm._v("入场动画：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w200",
                  attrs: { clearable: "", placeholder: "选择入场动画" },
                  model: {
                    value: _vm.animationData.entranceAnimation,
                    callback: function ($$v) {
                      _vm.$set(_vm.animationData, "entranceAnimation", $$v)
                    },
                    expression: "animationData.entranceAnimation",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: "bluropen", label: "模糊开幕" },
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: "slightshow", label: "缓慢清晰开幕" },
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: "withcircleopen", label: "聚光灯打开" },
                  }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: "open", label: "开幕" } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: "whiteshow", label: "渐显开幕" },
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: "wipecross", label: "交叉开幕" },
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: "slightrectshow", label: "方形开幕" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("div", { staticClass: "w100" }, [_vm._v("出场动画：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w200",
                  attrs: { clearable: "", placeholder: "选择出场动画" },
                  model: {
                    value: _vm.animationData.appearanceAnimation,
                    callback: function ($$v) {
                      _vm.$set(_vm.animationData, "appearanceAnimation", $$v)
                    },
                    expression: "animationData.appearanceAnimation",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: "blurclose", label: "模糊闭幕" },
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: "black", label: "曝光降低" },
                  }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: "close", label: "闭幕" } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: "withcircleclose", label: "聚光灯关闭" },
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: "disappear", label: "滑动消失" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("div", { staticClass: "w100" }, [_vm._v("动画时长：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "请输入时长" },
                model: {
                  value: _vm.animationData.animationTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.animationData, "animationTime", $$v)
                  },
                  expression: "animationData.animationTime",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt20 mb10" }, [
            _c("div", { staticClass: "w100" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("el-button", { on: { click: _vm.closeSetAnimation } }, [
                  _vm._v("取消"),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml10",
                    attrs: { type: "primary" },
                    on: { click: _vm.confirmSetAnimation },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.addComponent.show,
            title: "添加组件",
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addComponent, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "add-component" }, [
            _c(
              "div",
              [
                _c("el-input", {
                  staticClass: "w200",
                  attrs: { placeholder: "组件名称搜索", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getComponentList(1)
                    },
                  },
                  model: {
                    value: _vm.addComponent.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.addComponent, "name", $$v)
                    },
                    expression: "addComponent.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "c mt10" }, [
              _c(
                "div",
                { staticClass: "c-view" },
                _vm._l(_vm.addComponent.data, function (m, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "temp-item flex align-items-c",
                      staticStyle: {
                        padding: "10px 0",
                        "border-bottom": "1px solid #eee",
                      },
                    },
                    [
                      _c("div", { staticClass: "c-img" }, [
                        _c("img", {
                          attrs: {
                            src:
                              m.image +
                              "?x-oss-process=image/resize,w_200/format,webp",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "desc flex-one" }, [
                        _vm._v("\n              " + _vm._s(m.name)),
                        _c("span", { staticClass: "ft12 color-gray ml5" }, [
                          _vm._v("(" + _vm._s(m.ratio) + ")"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.addComponent.id != m.id
                        ? _c("div", { staticClass: "chiose" }, [
                            _vm.addComponent.hasIds.indexOf(m.id) != -1
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        _vm.unchangeComponentsAction(
                                          _vm.addComponent.hasIds.indexOf(m.id)
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("取消选择")]
                                )
                              : _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.changeComponentsAction(m)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page mt20" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-size": _vm.addComponent.pageSize,
                    "current-page": _vm.addComponent.pageNum,
                    layout: "total, prev, pager, next, jumper",
                    total: _vm.addComponent.total,
                  },
                  on: { "current-change": _vm.pageComponentChange },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "back flex align-items-c cur" }, [
      _c("div", { staticClass: "fWeight500" }, [_vm._v("视频模版列表")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "back flex align-items-c cur" }, [
      _c("div", { staticClass: "fWeight500" }, [_vm._v("模版预览")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }