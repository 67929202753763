"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'marketUser',
  props: {
    visibleUser: {
      type: Boolean,
      default: false
    },
    inviteParams: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      listLoading: false,
      queryParams: {},
      visibleUserFlag: this.visibleUser,
      visibleUserData: {
        data: [],
        total: 0
      },
      visibleTitle: ''
    };
  },
  filters: {
    formatType: function formatType(val) {
      var res = '';
      switch (val) {
        case 0:
          res = '待审核';
          break;
        case 1:
          res = '已提现';
          break;
        case 2:
          res = '已拒绝';
          break;
        default:
          break;
      }
      return res;
    }
  },
  created: function created() {
    this.queryParams = this.inviteParams;
    this.getList(1);
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _user.inviteWithDrawListApi)(this.queryParams).then(function (res) {
        _this.visibleUserData.data = res.rows;
        _this.visibleUserData.total = res.total;
        _this.visibleTitle = "\u5171".concat(res.total, "\u7B14\u63D0\u73B0\u8BB0\u5F55\uFF0C\u5171").concat(_this.queryParams.alreadyMoney, "\u5143");
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    sizeChange: function sizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    visibleClose: function visibleClose() {
      this.$emit('visibleCloseAlready', false);
    }
  }
};