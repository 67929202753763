"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UserList",
  data: function data() {
    return {
      opened: false,
      type: 1,
      day: 1,
      autoCloseOrderTime: 3,
      id: "",
      needLockHours: "",
      lockCount: "",
      lockHours: "",
      userType: "new_user_set",
      hasLoginHuakuai: true,
      hasOneHuakuai: true,
      hasTwoHuakuai: true
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.memberInfoApi)().then(function (res) {
      _this.day = res.data.day || 1;
      _this.autoCloseOrderTime = res.data.autoCloseOrderTime || 3;
      _this.type = res.data.type;
      _this.id = res.data.id;
      _this.lockHours = res.data.lockHours;
      _this.lockCount = res.data.lockCount;
      _this.needLockHours = res.data.needLockHours;
      _this.hasLoginHuakuai = res.data.hasLoginHuakuai;
      _this.hasOneHuakuai = res.data.hasOneHuakuai;
      _this.hasTwoHuakuai = res.data.hasTwoHuakuai;
    }).catch(function () {});
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      if (this.type === 2 && this.day <= 0) {
        this.$message.error("请输入正确的天数");
        return false;
      }
      if (!this.lockHours || !this.lockCount || !this.needLockHours || !Number(this.needLockHours)) {
        this.$message.error("请正确设置用户锁定设置");
        return false;
      }
      (0, _system.setMemberInfoApi)({
        day: this.day,
        autoCloseOrderTime: this.autoCloseOrderTime,
        id: this.id,
        type: this.type,
        lockHours: this.lockHours,
        lockCount: this.lockCount,
        needLockHours: this.needLockHours,
        hasLoginHuakuai: this.hasLoginHuakuai,
        hasOneHuakuai: this.hasOneHuakuai,
        hasTwoHuakuai: this.hasTwoHuakuai
      }).then(function (res) {
        _this2.$message.success("操作成功");
      }).catch(function () {});
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "all") {
        this.$router.push("/merchant/user/list");
        return false;
      } else if (tab.name === "label") {
        this.$router.push("/merchant/user/label");
        return false;
      } else if (tab.name === "authentication") {
        this.$router.push("/merchant/user/verify");
        return false;
      } else if (tab.name === "canel") {
        this.$router.push("/merchant/user/cancellation");
        return false;
      } else if (tab.name === "regiest_set") {
        this.$router.push("/merchant/user/regiest_set");
        return false;
      } else if (tab.name === "new_user_set") {
        this.$router.push("/merchant/user/new_user_set");
        return false;
      }
    }
  }
};