var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  staticStyle: { "margin-top": "-10px" },
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.useType,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "useType", $$v)
                    },
                    expression: "tableFrom.useType",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "作品使用", name: "1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "知识库使用", name: "2" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "sass视频模板使用", name: "3" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("添加属性")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData.data, size: "mini" } },
            [
              _c("el-table-column", { attrs: { label: "id", prop: "id" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "属性名称",
                  prop: "attrName",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "属性类型",
                  prop: "chooseType",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.chooseType == 1
                          ? _c("span", [_vm._v("单选")])
                          : scope.row.chooseType == 2
                          ? _c("span", [_vm._v("多选")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "100%" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加属性",
            visible: _vm.dialogEditAttrVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditAttrVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "属性名称", prop: "attrName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.attrName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "attrName", $$v)
                      },
                      expression: "ruleForm.attrName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "属性类型", prop: "chooseType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.chooseType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "chooseType", $$v)
                        },
                        expression: "ruleForm.chooseType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("单选")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("多选")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "选择标签类型" },
                      model: {
                        value: _vm.ruleForm.useType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "useType", $$v)
                        },
                        expression: "ruleForm.useType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: 1, label: "作品使用" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: 2, label: "知识库使用" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: 3, label: "sass视频模板使用" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.chooseType == 2
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "最少选择", prop: "attrName" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          model: {
                            value: _vm.ruleForm.minChooseNum,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "minChooseNum",
                                _vm._n($$v)
                              )
                            },
                            expression: "ruleForm.minChooseNum",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10" }, [
                          _vm._v("几个选项（0或者不填为不设置）"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "最多选择", prop: "attrName" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          model: {
                            value: _vm.ruleForm.maxChooseNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "maxChooseNum", $$v)
                            },
                            expression: "ruleForm.maxChooseNum",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10" }, [
                          _vm._v("几个选项（0或者不填为不设置）"),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "属性值" } }, [
                _c(
                  "div",
                  { staticClass: "attr-value-content" },
                  _vm._l(_vm.formValidate, function (m, i) {
                    return _c("div", { key: i, staticClass: "attr-value" }, [
                      _c(
                        "div",
                        { staticClass: "attr-value-left" },
                        [
                          _c(
                            "el-form",
                            { attrs: { "label-width": "100px" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "属性值名称" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: m.attrValueName,
                                      callback: function ($$v) {
                                        _vm.$set(m, "attrValueName", $$v)
                                      },
                                      expression: "m.attrValueName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "选中前图片" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      staticStyle: { background: "#fff" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap(
                                            "1",
                                            "beforeChooseImage",
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [
                                      m.beforeChooseImage
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "pictrue",
                                              staticStyle: { margin: "0" },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: m.beforeChooseImage,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "选中后图片" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      staticStyle: { background: "#fff" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap(
                                            "1",
                                            "afterChooseImage",
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [
                                      m.afterChooseImage
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "pictrue",
                                              staticStyle: { margin: "0" },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: m.afterChooseImage,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "attr-value-right" }, [
                        _c("div", { on: { click: _vm.addAttr } }, [
                          _c("i", {
                            staticClass: "el-icon-plus",
                            staticStyle: { "font-size": "20px" },
                          }),
                        ]),
                        _vm._v(" "),
                        i > 0
                          ? _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeAttr(i)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-minus",
                                  staticStyle: { "font-size": "20px" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i > 0
                          ? _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goUpAttrValAction(i)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-top",
                                  staticStyle: { "font-size": "20px" },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt20",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogEditAttrVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }