"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _sdk = require("@/api/sdk");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _requestCount = _interopRequireDefault(require("./components/requestCount.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MonitoringList',
  components: {
    userdetail: _userdetail.default,
    requestCount: _requestCount.default
  },
  data: function data() {
    return {
      identityTypeList: [{
        value: 1,
        label: '产品'
      }, {
        value: 2,
        label: '程序员'
      }, {
        value: 3,
        label: '商务'
      }, {
        value: 4,
        label: '其他'
      }],
      enterpriseTypeList: [{
        value: 1,
        label: '个人'
      }, {
        value: 2,
        label: '企业'
      }],
      modeTypeList: [{
        value: 1,
        label: '平台'
      }, {
        value: 2,
        label: 'SaaS'
      }],
      productTypeList: [{
        value: 1,
        label: '入门'
      }, {
        value: 2,
        label: '进阶'
      }, {
        value: 3,
        label: '精英'
      }, {
        value: 4,
        label: '至尊'
      }],
      expireStatusList: [{
        value: 0,
        label: '不可用'
      }, {
        value: 1,
        label: '正常状态'
      }, {
        value: 2,
        label: '即将过期'
      }, {
        value: 3,
        label: '已经过期'
      }],
      searchForm: {
        identityType: '',
        enterpriseType: '',
        modeType: 2,
        productType: '',
        expireStatus: '',
        nameOrPhone: '',
        appName: ''
      },
      pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      list: [],
      listLoading: false,
      uid: '',
      showdetail: false,
      appid: '',
      showAppdetail: false
    };
  },
  watch: {
    searchForm: {
      handler: function handler(val) {
        this.getList(1);
      },
      deep: true
    }
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    // 获取列表
    getList: function getList(reset) {
      var _this = this;
      if (reset) {
        this.pages.pageNum = 1;
        console.log('重置分页条数');
      }
      // const data = this.searchForm;
      this.listLoading = true;
      (0, _sdk.getSdkDataMonitorApi)((0, _objectSpread2.default)({}, this.pages)).then(function (res) {
        if (res.code === 200) {
          _this.pages.total = res.total;
          _this.list = res.rows;
        }
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    changePage: function changePage(page) {
      this.pages.pageNum = page;
      this.getList(1);
    },
    goRouter: function goRouter(name) {
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.$router.push({
        name: name,
        query: query
      });
    },
    nickNameClick: function nickNameClick(id) {
      this.uid = id;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    appIdClick: function appIdClick(id) {
      this.appid = id;
      this.showAppdetail = true;
    },
    appdetailclose: function appdetailclose() {
      this.showAppdetail = false;
    }
  }
};