"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      types: "ssr",
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        status: "",
        pageNum: 1,
        pageSize: 20,
        name: ""
      },
      showflag: false,
      showdetail: {
        id: "",
        name: '',
        icon: ''
      },
      rules: {}
    };
  },
  created: function created() {
    this.getList(1);
  },
  methods: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
    addAction: function addAction() {},
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _marketing.ssrList)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "blind") {
        this.$router.push("/merchant/digitalCollection/blind/list");
        return false;
      } else if (tab.name === "position") {
        this.$router.push("/merchant/digitalCollection/position/list");
        return false;
      } else if (tab.name === "subgift") {
        this.$router.push("/merchant/digitalCollection/subgift/list");
        return false;
      } else if (tab.name === "recovery") {
        this.$router.push("/merchant/digitalCollection/recovery/list");
        return false;
      } else if (tab.name === "ssr") {
        this.$router.push("/merchant/digitalCollection/ssrSet");
        return false;
      }
    }
  }, "addAction", function addAction() {
    this.showdetail = {
      id: "",
      name: '',
      icon: ''
    };
    this.showflag = true;
  }), "editAction", function editAction(row) {
    this.showflag = true;
    this.showdetail = {
      id: row.id,
      name: row.name,
      icon: row.icon
    };
  }), "delAction", function delAction(id) {
    var _this3 = this;
    this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u6D88\u606F?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(function () {
      (0, _marketing.ssrDelete)(id).then(function () {
        _this3.$message.success("删除成功");
        _this3.getList();
      }).catch(function (message) {});
    }).catch(function () {});
  }), "submitForm", function submitForm() {
    var _this4 = this;
    var json = JSON.parse(JSON.stringify(this.showdetail));
    var action = _marketing.ssrAdd;
    if (json.id) {
      action = _marketing.ssrEdit;
    }
    action(json).then(function (res) {
      _this4.getList();
      _this4.showflag = false;
    });
  }), "modalPicTap", function modalPicTap(tit) {
    var _this = this;
    this.$modalUpload(function (img) {
      _this.showdetail.icon = img[0];
    }, tit);
  })
};