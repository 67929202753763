var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout", staticStyle: { background: "#f7f7f7" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            {
              staticStyle: {
                position: "fixed",
                left: "0",
                top: "0",
                right: "0",
              },
              attrs: { height: "64px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-align-center",
                  staticStyle: {
                    width: "1345px",
                    margin: "0 auto",
                    height: "64px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "page-back item-align-center",
                      on: { click: _vm.backAction },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/b8b0d796b5afef0149e76265443f1cbd.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("返回")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-shu" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-name item-align-center" }, [
                    _c("div", { staticClass: "l" }, [_vm._v("基础页面")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/6390304e322470c6b1a238158de1ccf4.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "r" }, [
                      _vm._v(_vm._s(_vm.pageInfo.name || "")),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "page-btn item-align-center" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "large" },
                          on: { click: _vm.handlePageSave },
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: { click: _vm.handleCancelAction },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: { click: _vm.handlePageSetting },
                        },
                        [_vm._v("预览")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-main",
            {
              staticStyle: {
                "padding-top": "64px",
                "box-sizing": "border-box",
                width: "1345px",
                margin: "0 auto",
              },
            },
            [
              _c(
                "div",
                { staticClass: "layout-tool" },
                [
                  _c(
                    "el-scrollbar",
                    { style: _vm.scrollHeight },
                    [
                      _c(
                        "el-collapse",
                        {
                          attrs: {
                            value: [
                              "basics",
                              "advanced",
                              "sales",
                              "other",
                              "marketing",
                            ],
                          },
                        },
                        [
                          _c("div", { staticClass: "align-c fWeight500" }, [
                            _vm._v("组件配置"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex align-items-c left-item justify-b",
                              class: { curr: _vm.editType == "nft_component" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeType("nft_component")
                                },
                              },
                            },
                            [
                              _c("div", [_vm._v("NFT组件")]),
                              _vm._v(" "),
                              _vm.editType == "nft_component"
                                ? _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/f87e60793182f42c0c8e0a6a025ded80.png",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/4f7d02dd04462554701052b457452fa5.png",
                                    },
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex align-items-c left-item justify-b",
                              class: {
                                curr: _vm.editType == "nft_new_detail_page",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeType("nft_new_detail_page")
                                },
                              },
                            },
                            [
                              _c("div", [_vm._v("NFT详情")]),
                              _vm._v(" "),
                              _vm.editType == "nft_new_detail_page"
                                ? _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/f87e60793182f42c0c8e0a6a025ded80.png",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/4f7d02dd04462554701052b457452fa5.png",
                                    },
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex align-items-c left-item justify-b",
                              class: {
                                curr:
                                  _vm.editType == "nft_box_detail_page_test",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeType(
                                    "nft_box_detail_page_test"
                                  )
                                },
                              },
                            },
                            [
                              _c("div", [_vm._v("NFT盲盒详情")]),
                              _vm._v(" "),
                              _vm.editType == "nft_box_detail_page_test"
                                ? _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/f87e60793182f42c0c8e0a6a025ded80.png",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/4f7d02dd04462554701052b457452fa5.png",
                                    },
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex align-items-c left-item justify-b",
                              class: { curr: _vm.editType == "compose_page" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeType("compose_page")
                                },
                              },
                            },
                            [
                              _c("div", [_vm._v("合成活动配置")]),
                              _vm._v(" "),
                              _vm.editType == "compose_page"
                                ? _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/f87e60793182f42c0c8e0a6a025ded80.png",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/4f7d02dd04462554701052b457452fa5.png",
                                    },
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex align-items-c left-item justify-b",
                              class: { curr: _vm.editType == "login" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeType("login")
                                },
                              },
                            },
                            [
                              _c("div", [_vm._v("登录页配置")]),
                              _vm._v(" "),
                              _vm.editType == "login"
                                ? _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/f87e60793182f42c0c8e0a6a025ded80.png",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211215/4f7d02dd04462554701052b457452fa5.png",
                                    },
                                  }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-body" },
                [
                  _c(
                    "el-scrollbar",
                    {
                      ref: "scrollbar",
                      style: _vm.scrollHeight,
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handlePackage($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "layout-phone",
                          style: {
                            minHeight: "765px",
                            backgroundColor: _vm.pageInfo.background,
                            backgroundImage:
                              "url(" + _vm.pageInfo.bg_image + ")",
                            backgroundRepeat:
                              _vm.pageInfo.bg_image_mode === 3
                                ? "repeat-y"
                                : "no-repeat",
                            backgroundPosition:
                              _vm.pageInfo.bg_image_mode == 5
                                ? "center bottom"
                                : _vm.pageInfo.bg_image_mode == 3 ||
                                  _vm.pageInfo.bg_image_mode == 4
                                ? "center top"
                                : "center",
                            backgroundSize:
                              _vm.pageInfo.bg_image_mode == 2
                                ? "cover"
                                : "375px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "layout-phone-body",
                              attrs: { id: "guide002" },
                            },
                            [
                              _vm.editType == "login"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "item-flex-center",
                                      staticStyle: {
                                        height: "44px",
                                        color: "#fff",
                                        "font-size": "17px",
                                        "font-weight": "500",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                登录\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editType === "nft_detail_page" ||
                              _vm.editType === "nft_new_detail_page"
                                ? [
                                    _vm.pageInfo.back_image
                                      ? _c("div", { staticClass: "back-top" }, [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                _vm.pageInfo.back_image +
                                                "!120a",
                                              alt: "",
                                            },
                                          }),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "share-top" }, [
                                      _vm.pageInfo.share_image
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.pageInfo.share_image +
                                                "!120a",
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.pageInfo.collection_image
                                        ? _c("img", {
                                            staticClass: "ml10",
                                            attrs: {
                                              src:
                                                _vm.pageInfo.collection_image +
                                                "!120a",
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c("router-view", { ref: "childView" }),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.editType === "nft_detail_page" ||
                  _vm.editType === "nft_new_detail_page"
                    ? _c(
                        "div",
                        {
                          staticClass: "footer",
                          style:
                            "background: " +
                            _vm.pageInfo.bottom_background +
                            ";box-shadow: none;height: 90px;",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "btn-bottom flex align-items-c justify-b",
                              style:
                                "background: " +
                                _vm.pageInfo.bottom_background +
                                ";",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "l",
                                  style: "color: " + _vm.pageInfo.priceColor,
                                },
                                [
                                  _vm._v("\n              ￥"),
                                  _c("span", [_vm._v("100")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "r flex align-items-c" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn",
                                      style:
                                        "background: " +
                                        _vm.pageInfo.btnBgColor +
                                        ";border-color: " +
                                        _vm.pageInfo.btnBgColor +
                                        ";color: " +
                                        _vm.pageInfo.btnColor +
                                        ";border-radius:" +
                                        _vm.pageInfo.btnRadius / 2 +
                                        "px;",
                                    },
                                    [
                                      _vm._v(
                                        "\n                购买\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "hengtiao" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editType === "compose_page"
                    ? _c(
                        "div",
                        {
                          staticClass: "footer",
                          style:
                            "background: " +
                            _vm.pageInfo.bottom_background +
                            ";box-shadow: none;height: 90px;",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "btn-bottom flex align-items-c justify-b",
                              style:
                                "background: " +
                                _vm.pageInfo.bottom_background +
                                ";",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "l",
                                  style: "color: " + _vm.pageInfo.priceColor,
                                },
                                [
                                  _vm._v("\n              ￥"),
                                  _c("span", [_vm._v("100")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "r flex align-items-c" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn",
                                      style:
                                        "background: " +
                                        _vm.pageInfo.btnBgColor +
                                        ";border-color: " +
                                        _vm.pageInfo.btnBgColor +
                                        ";color: " +
                                        _vm.pageInfo.btnColor +
                                        ";border-radius:" +
                                        _vm.pageInfo.btnRadius / 2 +
                                        "px;",
                                    },
                                    [
                                      _vm._v(
                                        "\n                立即支付\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "hengtiao" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editType === "nft_box_detail_page" ||
                  _vm.editType === "nft_box_detail_page_test"
                    ? _c(
                        "div",
                        {
                          staticClass: "footer",
                          style:
                            "background: " +
                            _vm.pageInfo.bottom_background +
                            ";box-shadow: none;height: 90px;",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "btn-bottom flex align-items-c justify-b",
                              style:
                                "background: " +
                                _vm.pageInfo.bottom_background +
                                ";",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "l",
                                  style: "color: " + _vm.pageInfo.priceColor,
                                },
                                [
                                  _vm._v("\n              ￥"),
                                  _c("span", [_vm._v("100")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "r flex align-items-c" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "qd mr10",
                                      style: "color: " + _vm.pageInfo.txtColor,
                                    },
                                    [
                                      _vm._v(
                                        "\n                4.12 09:00 开售\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn",
                                      style:
                                        "background: " +
                                        _vm.pageInfo.btnBgColor +
                                        ";border-color: " +
                                        _vm.pageInfo.btnBgColor +
                                        ";color: " +
                                        _vm.pageInfo.btnColor,
                                    },
                                    [
                                      _vm._v(
                                        "\n                立即拆盒\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "hengtiao" }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "layout-attribute display-flex",
                  attrs: { id: "guide004" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _vm.index >= 0
                        ? _c(
                            "el-scrollbar",
                            { style: _vm.scrollHeight },
                            [
                              _vm.modules[_vm.attribute]
                                ? _c(_vm.modules[_vm.attribute].set_attribute, {
                                    tag: "component",
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.index == -1
                        ? _c(
                            "el-scrollbar",
                            { style: _vm.scrollHeight },
                            [
                              _vm.editType == "nft_component"
                                ? [
                                    _c("nftAttribute", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "nft_detail_page"
                                ? [
                                    _c("nftPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "nft_new_detail_page"
                                ? [
                                    _c("nftPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "nft_box_detail_page"
                                ? [
                                    _c("nftBoxPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "nft_box_detail_page_test"
                                ? [
                                    _c("nftBoxPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "compose_page"
                                ? [
                                    _c("composePage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "login"
                                ? [
                                    _c("loginPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }