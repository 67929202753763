var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c("h3", [_vm._v("创建神兽")]),
          _vm._v(" "),
          _c("ul", [
            _c(
              "li",
              { staticClass: "align-items-c" },
              [
                _c("span", [_vm._v("*神兽关联")]),
                _vm._v(" "),
                !_vm.form.productName
                  ? _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: { click: _vm.selectAnimal },
                      },
                      [_vm._v("\n          选择\n        ")]
                    )
                  : _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-image", {
                          staticClass: "w50 h50 mr5",
                          attrs: { src: _vm.form.productImage, alt: "" },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: { click: _vm.selectAnimal },
                          },
                          [_vm._v("更改")]
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "align-items-c" }, [
              _c(
                "div",
                [
                  _c("span", [_vm._v("*初始灵力值")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0 },
                    model: {
                      value: _vm.form.initialEnergy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "initialEnergy", $$v)
                      },
                      expression: "form.initialEnergy",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("span", [_vm._v("*初始生命值")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0 },
                    model: {
                      value: _vm.form.lifeEnergy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "lifeEnergy", $$v)
                      },
                      expression: "form.lifeEnergy",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "align-items-c" }, [
              _c(
                "div",
                [
                  _c("span", [_vm._v("*最大灵力值")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0, min: 1 },
                    model: {
                      value: _vm.form.maxEnergy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "maxEnergy", $$v)
                      },
                      expression: "form.maxEnergy",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("span", [_vm._v("*最大生命值")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0, min: 1 },
                    model: {
                      value: _vm.form.maxLife,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "maxLife", $$v)
                      },
                      expression: "form.maxLife",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "align-items-c" }, [
              _c(
                "div",
                [
                  _c("span", [_vm._v("*灵力值消耗")]),
                  _vm._v("\n          每"),
                  _c("el-input-number", {
                    staticClass: "ml10 mr10 w100",
                    attrs: { min: 1, controls: false, precision: 0 },
                    model: {
                      value: _vm.form.consumeEnergy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "consumeEnergy", $$v)
                      },
                      expression: "form.consumeEnergy",
                    },
                  }),
                  _vm._v("秒消耗1点\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("span", [_vm._v("*生命值消耗")]),
                  _vm._v("\n          每"),
                  _c("el-input-number", {
                    staticClass: "ml10 mr10 w100",
                    attrs: { min: 1, controls: false, precision: 0 },
                    model: {
                      value: _vm.form.consumeLife,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "consumeLife", $$v)
                      },
                      expression: "form.consumeLife",
                    },
                  }),
                  _vm._v("小时消耗1点\n        "),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("*产出")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "custom-table" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.outPutData } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: { change: _vm.changeMaterialType },
                                    model: {
                                      value: scope.row.materialType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "materialType", $$v)
                                      },
                                      expression: "scope.row.materialType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "产出项", value: 1 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "数组藏品", value: 2 },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    scope.row.image
                                      ? _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image,
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.name)),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectOutPut(
                                                  scope.row.materialType,
                                                  "outPut"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    选择\n                  "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产出速度" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v("\n                每"),
                                _c("el-input-number", {
                                  staticClass: "w100 ml5 mr5",
                                  attrs: {
                                    controls: false,
                                    min: 1,
                                    precision: 0,
                                  },
                                  model: {
                                    value: scope.row.account,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "account", $$v)
                                    },
                                    expression: "scope.row.account",
                                  },
                                }),
                                _vm._v("秒/个\n              "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: { click: _vm.deleteOutOut },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.outPutData.length
                    ? _c(
                        "el-link",
                        {
                          attrs: { underline: false, type: "primary" },
                          on: { click: _vm.addOutPut },
                        },
                        [_vm._v("+添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("*补充灵力值")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "custom-table" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.energyData } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeEnergy(scope.$index)
                                      },
                                    },
                                    model: {
                                      value: scope.row.materialType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "materialType", $$v)
                                      },
                                      expression: "scope.row.materialType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "产出项", value: 1 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "数字藏品", value: 2 },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    scope.row.image
                                      ? _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image,
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.name)),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectOutPut(
                                                  scope.row.materialType,
                                                  "energy",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    选择\n                  "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "可补充灵力值" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  staticClass: "w100",
                                  attrs: {
                                    controls: false,
                                    precision: 0,
                                    min: 1,
                                  },
                                  model: {
                                    value: scope.row.account,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "account", $$v)
                                    },
                                    expression: "scope.row.account",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteEnergy(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false, type: "primary" },
                      on: { click: _vm.addEnergy },
                    },
                    [_vm._v("+添加")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("*补充生命值")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "custom-table" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.lifeData } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeLife(scope.$index)
                                      },
                                    },
                                    model: {
                                      value: scope.row.materialType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "materialType", $$v)
                                      },
                                      expression: "scope.row.materialType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "产出项", value: 1 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "数字藏品", value: 2 },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    scope.row.image
                                      ? _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image,
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.name)),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectOutPut(
                                                  scope.row.materialType,
                                                  "life",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    选择\n                  "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "可补充生命值值" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  staticClass: "w100",
                                  attrs: {
                                    controls: false,
                                    precision: 0,
                                    min: 1,
                                  },
                                  model: {
                                    value: scope.row.account,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "account", $$v)
                                    },
                                    expression: "scope.row.account",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteLife(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false, type: "primary" },
                      on: { click: _vm.addLife },
                    },
                    [_vm._v("+添加")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c("el-button", { attrs: { type: "" }, on: { click: _vm.goBack } }, [
            _vm._v("返回"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择产出项",
            visible: _vm.produceDialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.produceDialog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.produceData } },
            [
              _c("el-table-column", { attrs: { label: "Id", prop: "id" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          attrs: { src: scope.row.image, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户持有" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v(_vm._s(scope.row.allNum))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "未领取上限", prop: "maxAccount" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.selectProduce(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: {
          "current-data": [],
          size: "1",
          showSelect: true,
          needPointsDeductionType: false,
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }