"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var modelType;
var sdkRouter = {
  path: "".concat(_settings.roterPre, "/sdk"),
  name: 'sdk',
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/sdk/clientList"),
  meta: {
    icon: 'dashboard',
    title: 'SDK管理'
  },
  children: [{
    path: 'clientList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sdk/clientList.vue'));
      });
    },
    name: 'SdkClientList',
    meta: {
      title: '客户管理',
      icon: 'dashboard'
    }
  }, {
    path: 'appList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sdk/appList.vue'));
      });
    },
    name: 'SdkAppList',
    meta: {
      title: '应用管理',
      icon: 'dashboard',
      activeMenu: "".concat(_settings.roterPre, "/sdk/appList?modelType=")
    },
    beforeEnter: function beforeEnter(to, form, next) {
      modelType = to.query.modelType;
      to.meta.activeMenu = "".concat(_settings.roterPre, "/sdk/appList?modelType=").concat(modelType);
      next();
    }
  }, {
    path: 'monitoringList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sdk/monitoringList.vue'));
      });
    },
    name: 'SdkMonitoringList',
    meta: {
      title: '数据监测',
      icon: 'dashboard'
    }
  }, {
    path: 'product',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contain.vue'));
      });
    },
    name: 'SdkProduct',
    meta: {
      title: '产品配置',
      icon: 'dashboard'
    },
    redirect: "".concat(_settings.roterPre, "/sdk/product/list"),
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sdk/productList/list.vue'));
        });
      },
      name: 'SdkProductList',
      meta: {
        title: '产品配置'
      }
    }, {
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sdk/productList/info.vue'));
        });
      },
      name: 'SdkProductInfo',
      meta: {
        title: '产品配置'
      }
    }]
  }]
};
var _default = exports.default = sdkRouter;