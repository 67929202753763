var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", [
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addAction } },
              [_vm._v("添加敏感词")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData.data, size: "small" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "敏感词",
                    "min-width": "300",
                    prop: "luckyName",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("div", [_vm._v(_vm._s(scope.row.text))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "80", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(
                                    scope.row.id,
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-e" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [20, 40, 60, 80],
                    "page-size": _vm.tableFrom.pageSize,
                    "current-page": _vm.tableFrom.pageNum,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.pageChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }