var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visibleUserFlag,
        "close-on-click-modal": false,
        title: _vm.visibleTitle,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visibleUserFlag = $event
        },
        close: _vm.visibleClose,
      },
    },
    [
      _c(
        "el-input",
        {
          staticClass: "w200",
          attrs: { size: "small", placeholder: "搜索用户" },
          on: { change: _vm.goSearch },
          model: {
            value: _vm.queryParams.nameOrPhone,
            callback: function ($$v) {
              _vm.$set(_vm.queryParams, "nameOrPhone", $$v)
            },
            expression: "queryParams.nameOrPhone",
          },
        },
        [
          _c("el-button", {
            attrs: { slot: "append", icon: "el-icon-search", size: "small" },
            on: { click: _vm.goSearch },
            slot: "append",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticClass: "order-table table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.visibleUserData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "头像" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: row.avatar },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(row.nickName) + " " + _vm._s(row.phone)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "交易笔数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.orderNum))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "注册时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "page-sizes": [20, 40, 60, 80],
          "page-size": _vm.queryParams.pageSize,
          "current-page": _vm.queryParams.pageNum,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.visibleUserData.total,
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.pageChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }