"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _animal = require("@/api/animal");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "CardSlot",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      list: {
        data: [],
        total: 0
      },
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      listLoading: false,
      addSlotDialog: false,
      form: {
        slotName: "",
        sort: 1,
        needNum: 0,
        productId: "",
        productName: "",
        productImage: "",
        type: 1
      },
      produceData: [],
      produceDialog: false
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      console.log(val);
      this.pageConfig.pageNum = val;
      this.listLoading = true;
      (0, _animal.getSlotListApi)(this.pageConfig).then(function (res) {
        _this.listLoading = false;
        _this.list.data = res.rows;
        _this.list.total = res.total;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.getList();
    },
    addSlot: function addSlot() {
      this.addSlotDialog = true;
    },
    changeProduct: function changeProduct() {
      this.form.productId = "";
      this.form.productImage = "";
      this.form.productName = "";
    },
    selectProduct: function selectProduct() {
      var _this2 = this;
      if (this.form.type == 1) {
        var loadingInstance = _elementUi.Loading.service();
        (0, _animal.produceListApi)().then(function (res) {
          _this2.produceData = res.rows;
          _this2.produceDialog = true;
          _this2.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (_) {
          _this2.$nextTick(function () {
            loadingInstance.close();
          });
        });
      } else {
        this.$refs["product_nft"].changeshow();
      }
    },
    selectMessage: function selectMessage(data) {
      var _data$data$ = data.data[0],
        productId = _data$data$.productId,
        image = _data$data$.image,
        name = _data$data$.name;
      this.form.productId = productId;
      this.form.productImage = image;
      this.form.productName = name;
    },
    selectProduce: function selectProduce(row) {
      var id = row.id,
        image = row.image,
        name = row.name;
      this.form.productId = id;
      this.form.productImage = image;
      this.form.productName = name;
      this.produceDialog = false;
    },
    closeDialog: function closeDialog() {
      this.$data.form = JSON.parse(JSON.stringify(this.$options.data().form) //解决关闭弹框 数据没有清空
      );
      this.addSlotDialog = false;
      this.getList();
    },
    submit: function submit() {
      var _this3 = this;
      if (!this.form.slotName) {
        return this.$message.error("请填写卡槽名称");
      }
      if (!this.form.productId) {
        return this.$message.error("请完善解锁条件");
      }
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.handleSlotApi)(this.form).then(function (res) {
        _this3.$nextTick(function () {
          if (_this3.form.id) {
            _this3.$message.success("编辑成功");
          } else {
            _this3.$message.success("保存成功");
          }
          _this3.closeDialog();
          _this3.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function (err) {
        _this3.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    editSlot: function editSlot(id) {
      var _this4 = this;
      (0, _animal.slotInfoApi)(id).then(function (res) {
        _this4.form = res.data;
        _this4.addSlotDialog = true;
      });
    }
  }
};