"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementIcons = _interopRequireDefault(require("./element-icons"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Index',
  data: function data() {
    return {
      elementIcons: _elementIcons.default,
      iconVal: '',
      modals2: false,
      list: []
    };
  },
  mounted: function mounted() {
    this.list = this.elementIcons;
  },
  methods: {
    generateElementIconCode: function generateElementIconCode(symbol) {
      return "<i class=\"el-icon-".concat(symbol, "\" />");
    },
    handleClipboard: function handleClipboard(text, event, n) {
      this.iconChange(n);
    },
    // 搜索
    upIcon: function upIcon(n) {
      var arrs = [];
      for (var i = 0; i < this.elementIcons.length; i++) {
        if (this.elementIcons[i].indexOf(n) !== -1) {
          arrs.push(this.elementIcons[i]);
          this.list = arrs;
        }
      }
    },
    iconChange: function iconChange(n) {
      /* eslint-disable */
      form_create_helper.set(this.$route.query.field, n);
      form_create_helper.close('icon');
    }
  }
};