"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var userRouter = {
  path: "".concat(_settings.roterPre, "/merchant"),
  name: "merchant",
  meta: {
    icon: "dashboard",
    title: "商户管理"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "noRedirect",
  children: [{
    path: "manage",
    name: "manage",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/merchant/contain.vue"));
      });
    },
    redirect: "".concat(_settings.roterPre, "/merchant/manage/list"),
    children: [{
      path: "list",
      name: "merchantList",
      meta: {
        title: "商户列表",
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/merchant/list.vue"));
        });
      }
    }, {
      path: "setting",
      name: "merchantSetting",
      meta: {
        title: "实名认证",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/merchant/manage/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/merchant/setting.vue"));
        });
      }
    }, {
      path: "review",
      name: "merchantreview",
      meta: {
        title: "商户审核",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/merchant/manage/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/merchant/review.vue"));
        });
      }
    }]
  }, {
    path: "maticlabel",
    name: "UserAutoLabel",
    meta: {
      title: "自动标签",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/label"));
      });
    }
  }]
};
var _default = exports.default = userRouter;