"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "planeDetail",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      formValidate: {
        type: '',
        image: '',
        name: '',
        prizeNum: ''
      },
      ruleValidate: {
        type: [{
          required: true,
          message: "请选择类型"
        }],
        image: [{
          required: true,
          message: "请选择图片"
        }],
        name: [{
          required: true,
          message: "请输入商品名称"
        }],
        prizeNum: [{
          required: true,
          message: "请输入商品数量"
        }]
      },
      product: null,
      condition: [{
        image: '',
        name: '',
        neednum: '',
        type: 1
      }],
      conditiontype: true
    };
  },
  created: function created() {
    // this.id = this.$route.query.id || '';
    // if (this.id) {
    //     this.getPlaneDetail();
    // }
    // getPlaneDetail()
  },
  methods: {
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          var flag = false;
          _this2.condition.forEach(function (item, index) {
            if (!item.productId && item.type == 1) {
              flag = true;
            } else if (item.type == 2) {
              item.name = 'Y积分';
            }
          });
          if (flag) {
            return _this2.$message.error('请完善兑换条件或者删除无用的条件');
          }
          var json = JSON.parse(JSON.stringify(_this2.formValidate));
          json.fighterMiningExchangePrizes = [{
            name: json.name || _this2.product.name || '',
            image: json.image || _this2.product.image || '',
            type: json.type,
            prizeNum: json.prizeNum,
            productId: json.type == 1 ? _this2.product.p_id : '',
            objectId: json.type == 1 ? _this2.product.id : ''
          }];
          json.fighterMiningExchangeNeeds = _this2.condition;
          json.isAllNeed = _this2.conditiontype;
          (0, _marketing.exchangeGoodsCreate)(json).then(function (res) {
            console.log(res);
            _this2.$message.success('创建成功');
            _this2.$router.back();
          });
        } else {
          _this2.$message.error('请完善信息');
        }
      });
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      var product = data.data[0];
      if (this.changeType == "output") {
        this.output[this.changeIndex].image = product.image;
        this.output[this.changeIndex].name = product.name;
        this.output[this.changeIndex].stock = product.stock;
        this.output[this.changeIndex].id = product.id;
      } else if (this.changeType == "condition") {
        this.condition[this.changeIndex].image = product.image;
        this.condition[this.changeIndex].name = product.name;
        // this.condition[this.changeIndex].id = product.id;
        this.condition[this.changeIndex].objectId = product.id;
        this.condition[this.changeIndex].productId = product.p_id;
      } else if (this.changeType == "durability") {
        this.durability[this.changeIndex].image = product.image;
        this.durability[this.changeIndex].name = product.name;
        this.durability[this.changeIndex].id = product.id;
      } else if (this.changeType == "product") {
        this.product = product;
        this.formValidate.product_id = product.id;
        this.formValidate.product_image = product.image;
        this.formValidate.product_name = product.name;
        this.formValidate.image = product.image;
        // this.formValidate.product_num = product.stock;
      }
    },
    addlist: function addlist(type) {
      this.condition.push({
        image: '',
        name: '',
        neednum: ''
      });
    },
    dellist: function dellist(type, index) {
      if (type == 'output') {
        this.output.splice(index, 1);
      } else if (type == 'condition') {
        this.condition.splice(index, 1);
      } else if (type == 'durability') {
        this.durability.splice(index, 1);
      }
    },
    handleAdditem: function handleAdditem(type, i) {
      this.changeType = type;
      this.changeIndex = i;
      this.$refs.product_nft.changeshow();
    },
    // 修改图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          console.log(img);
          _this.formValidate[num] = img[0];
          _this.$refs.formValidate.clearValidate();
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = '';
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};