"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'comre',
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      showdetail: false,
      row: '',
      uid: '',
      isStartList: [{
        id: 1,
        name: '进行中'
      }, {
        id: 2,
        name: '已结束'
      }],
      typeList: [{
        id: 1,
        name: '作品'
      }, {
        id: 2,
        name: '盲盒'
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.userId;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this = this;
      (0, _marketing.synthesisRecordListExportApi)(this.tableFrom).then(function (res) {
        _this.$message.success('操作成功');
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(33);
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _marketing.synthesisRecordListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    }
  }
};