var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "tab flex align-items-c mt10" },
          [
            _c(
              "el-input",
              {
                staticClass: "w200",
                attrs: { size: "small", placeholder: "作品/专辑/盲盒" },
                on: { change: _vm.goSearch },
                model: {
                  value: _vm.queryParams.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "name", $$v)
                  },
                  expression: "queryParams.name",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    icon: "el-icon-search",
                    size: "small",
                  },
                  on: { click: _vm.goSearch },
                  slot: "append",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-input",
              {
                staticClass: "w200 ml10",
                attrs: { size: "small", placeholder: "卖家昵称/手机" },
                on: { change: _vm.goSearch },
                model: {
                  value: _vm.queryParams.user,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "user", $$v)
                  },
                  expression: "queryParams.user",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    icon: "el-icon-search",
                    size: "small",
                  },
                  on: { click: _vm.goSearch },
                  slot: "append",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-cascader", {
              staticClass: "w200 ml10",
              attrs: {
                size: "small",
                placeholder: "作品分类",
                clearable: "",
                options: _vm.categoryList,
                props: { expandTrigger: "hover", value: "id", label: "label" },
              },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.queryParams.categoryId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "categoryId", $$v)
                },
                expression: "queryParams.categoryId",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "w200 ml10",
                attrs: {
                  size: "small",
                  placeholder: "作品类型",
                  clearable: "",
                },
                on: { change: _vm.goSearch },
                model: {
                  value: _vm.queryParams.fileType,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "fileType", $$v)
                  },
                  expression: "queryParams.fileType",
                },
              },
              _vm._l(_vm.fileTypeList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { value: item.id, label: item.label },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "w200 ml10",
              attrs: { size: "small", placeholder: "起始编号" },
              model: {
                value: _vm.queryParams.startNumber,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "startNumber", $$v)
                },
                expression: "queryParams.startNumber",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v(" 至 ")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "结尾编号" },
              model: {
                value: _vm.queryParams.endNumber,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "endNumber", $$v)
                },
                expression: "queryParams.endNumber",
              },
            }),
            _vm._v(" "),
            _c("el-button", {
              attrs: { slot: "append", icon: "el-icon-search", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.getList(1)
                },
              },
              slot: "append",
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt10 mb10" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.exportOrder },
              },
              [_vm._v("导出")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "text" },
                on: { click: _vm.getExportFileList },
              },
              [_vm._v("查看导出记录")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "order-table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  size: "mini",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popover",
                            {
                              staticClass: "tabPop",
                              attrs: {
                                placement: "top-start",
                                width: "100",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "spBlock onHand",
                                    class: { check: _vm.chkName === "dan" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandle("dan", scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("选中本页")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                attrs: {
                                  slot: "reference",
                                  value:
                                    (_vm.chkName === "dan" &&
                                      _vm.checkedPage.indexOf(
                                        _vm.queryParams.pageNum
                                      ) > -1) ||
                                    _vm.chkName === "duo",
                                },
                                on: { change: _vm.changeType },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            attrs: {
                              value:
                                _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                                (_vm.chkName === "duo" &&
                                  _vm.noChecked.indexOf(scope.row.id) === -1),
                            },
                            on: {
                              change: function (v) {
                                return _vm.changeOne(v, scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "作品图片" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: { src: row.image },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "作品名称" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.name))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm._f("formatType")(row.type))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "作品编号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.number))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "流转次数" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.transCountAction(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.transCount) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "链类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [_vm._v(_vm._s(row.blockchainTypeName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "卖家" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.nickName))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "卖家手机号", "min-width": "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.mobile))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "寄售价格" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.price))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "寄售时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "寄售时长", "min-width": "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.marketTime))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", fixed: "right", width: "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downAction(row)
                                    },
                                  },
                                },
                                [_vm._v("下架")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block flex justify-e" },
              [
                _c(
                  "div",
                  { staticClass: "flex-one mt20 align-items-c flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mr10",
                        attrs: {
                          type: "primary",
                          icon: "ios-search",
                          label: "default",
                          size: "small",
                        },
                        on: { click: _vm.batchDownAction },
                      },
                      [_vm._v("批量下架")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [20, 40, 60, 80],
                    "page-size": _vm.queryParams.pageSize,
                    "current-page": _vm.queryParams.pageNum,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.pageChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.visibleTransCount
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleTransCount,
                "close-on-click-modal": false,
                title: "寄售记录",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleTransCount = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  staticClass: "order-table table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.transCountData.data,
                    size: "small",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "用户名称" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_c("div", [_vm._v(_vm._s(row.nickName))])]
                          },
                        },
                      ],
                      null,
                      false,
                      2005233179
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "时间" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                          },
                        },
                      ],
                      null,
                      false,
                      753080258
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "状态" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm._f("formatStatus")(row.status))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2608449449
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "金额" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_c("div", [_vm._v(_vm._s(row.price))])]
                          },
                        },
                      ],
                      null,
                      false,
                      2826940926
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.transCountParams.pageSize,
                  "current-page": _vm.transCountParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.transCountData.total,
                },
                on: {
                  "size-change": _vm.transCountSizeChange,
                  "current-change": _vm.transCountPageChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }