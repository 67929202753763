"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "marketUser",
  props: {
    visibleUser: {
      type: Boolean,
      default: false
    },
    productId: {
      type: [Number, String],
      default: null
    }
  },
  data: function data() {
    return {
      checked: false,
      listLoading: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      visibleUserData: {
        data: [],
        total: 0
      },
      multipleSelection: [],
      radio1: ""
    };
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.queryParams.productId = this.productId;
      this.queryParams.status = this.radio1;
      (0, _marketing.marketUserListApi)(this.queryParams).then(function (res) {
        _this.visibleUserData.data = res.rows;
        _this.visibleUserData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    sizeChange: function sizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    userClose: function userClose() {
      this.$emit("userClose");
    },
    flitterData: function flitterData(arr) {
      var spanOneArr = [];
      var concatOne = 0;
      arr.forEach(function (item, index) {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          if (item.userId === arr[index - 1].userId) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr
      };
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (column.type === "selection") {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
      if (columnIndex === 1 || columnIndex === 2) {
        // 判断哪一列
        var _row = this.flitterData(this.visibleUserData.data).one[rowIndex];
        var _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    batchTake: function batchTake() {
      this.showHandle(this.multipleSelection);
    },
    bathMullock: function bathMullock() {
      var _this2 = this;
      (0, _marketing.mullock)(this.multipleSelection).then(function (res) {
        _this2.getList();
        _this2.$message.success("操作成功");
      });
    },
    showHandle: function showHandle(id) {
      var _this3 = this;
      var arr = [];
      if (Array.isArray(id)) {
        arr = id;
      } else {
        arr.push(id);
      }
      (0, _marketing.marketInfoTakeApi)(arr).then(function (res) {
        _this3.getList();
        _this3.$message.success("操作成功");
      });
    },
    clockHandle: function clockHandle(id) {
      var _this4 = this;
      (0, _marketing.marketLockApi)(id).then(function (res) {
        _this4.getList();
        _this4.$message.success("操作成功");
      });
    },
    changMultiple: function changMultiple(val) {
      console.log(val);
      if (val) {
        this.$refs.multipleTable.toggleAllSelection();
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val.map(function (item) {
        return item.id;
      });
    }
  }
};