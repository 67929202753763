var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox articleDetails" },
    [
      _c("el-button", { on: { click: _vm.back } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "form",
              attrs: {
                model: _vm.formValidate,
                "label-width": "120px",
                rules: _vm.ruleValidate,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "dividerTitle" },
                [
                  _c("span", { staticClass: "title mr10" }, [
                    _vm._v("文章信息"),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "标题：",
                            prop: "noticeTitle",
                            "label-for": "title",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              "element-id": "title",
                            },
                            model: {
                              value: _vm.formValidate.noticeTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "noticeTitle", $$v)
                              },
                              expression: "formValidate.noticeTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "作者：",
                            prop: "author",
                            "label-for": "author",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              maxLength: "32",
                              "element-id": "author",
                            },
                            model: {
                              value: _vm.formValidate.author,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "author", $$v)
                              },
                              expression: "formValidate.author",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "文章分类：",
                            "label-for": "categoryId",
                            prop: "categoryId",
                          },
                        },
                        [
                          _c("el-cascader", {
                            attrs: {
                              clearable: "",
                              options: _vm.treeData,
                              props: {
                                expandTrigger: "hover",
                                value: "id",
                                label: "label",
                              },
                            },
                            model: {
                              value: _vm.formValidate.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "categoryId", $$v)
                              },
                              expression: "formValidate.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "跳转地址：",
                            prop: "officialUrl",
                            "label-for": "officialUrl",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "设置跳转链接" },
                            on: { focus: _vm.setCategory },
                            model: {
                              value: _vm.formValidate.officialUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "officialUrl", $$v)
                              },
                              expression: "formValidate.officialUrl",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "文章标签：", prop: "tagId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.formValidate.tagId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "tagId", $$v)
                                },
                                expression: "formValidate.tagId",
                              },
                            },
                            _vm._l(_vm.tagList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.tagName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "图文封面：", prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dividerTitle" },
                [
                  _c("span", { staticClass: "title" }, [_vm._v("文章内容")]),
                  _vm._v(" "),
                  _c("el-divider"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "文章内容：", prop: "noticeContent" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.formValidate.noticeContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "noticeContent", $$v)
                      },
                      expression: "formValidate.noticeContent",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dividerTitle" },
                [
                  _c("span", { staticClass: "title" }, [_vm._v("其他设置")]),
                  _vm._v(" "),
                  _c("el-divider"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否显示：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "status", $$v)
                                },
                                expression: "formValidate.status",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "radio", attrs: { label: 1 } },
                                [_vm._v("显示")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不显示"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否分享：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.isTop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "isTop", $$v)
                                },
                                expression: "formValidate.isTop",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "radio", attrs: { label: 1 } },
                                [_vm._v("置顶")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不置顶"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "submission",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onsubmit("formValidate")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }