"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AttrList',
  data: function data() {
    return {
      props: {
        value: 'store_brand_category_id',
        label: 'cate_name',
        children: 'children',
        emitPath: false
      },
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        useType: '1'
      },
      imgList: [],
      brandCategory: [],
      dialogEditAttrVisible: false,
      ruleForm: {
        attrName: '',
        chooseType: 1,
        maxChooseNum: '',
        // 最大可选择数量
        minChooseNum: '',
        // 最小选择数量
        useType: 1
      },
      rules: {
        attrName: [{
          required: true,
          message: '请输入属性名称',
          trigger: 'blur'
        }],
        chooseType: [{
          required: true,
          message: '请选择属性类型',
          trigger: 'change'
        }]
      },
      formValidate: [{
        beforeChooseImage: '',
        afterChooseImage: '',
        attrValueName: ''
      }],
      editId: '',
      btnLoading: false
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _product.attrListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.editId = '';
      this.formValidate = [{
        id: '',
        attrId: '',
        beforeChooseImage: '',
        afterChooseImage: '',
        attrValueName: ''
      }];
      this.ruleForm = {
        attrName: '',
        chooseType: 1,
        maxChooseNum: '',
        // 最大可选择数量
        minChooseNum: '',
        // 最小选择数量
        useType: Number(this.tableFrom.useType)
      };
      this.dialogEditAttrVisible = true;
      // this.$modalForm(attrCreateApi()).then(() => this.getList());
    },
    // 编辑
    onEdit: function onEdit(m) {
      this.editId = m.id;
      this.formValidate = JSON.parse(JSON.stringify(m.sysAttrValueList));
      this.ruleForm = {
        attrName: m.attrName,
        chooseType: m.chooseType,
        maxChooseNum: m.maxChooseNum,
        // 最大可选择数量
        minChooseNum: m.minChooseNum,
        // 最小选择数量
        useType: m.useType
      };
      this.dialogEditAttrVisible = true;
      // this.$modalForm(attrCreateApi()).then(() => this.getList());
    },
    modalPicTap: function modalPicTap(tit, key, index) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.formValidate[index][key] = img[0];
      }, tit);
    },
    addAttr: function addAttr() {
      this.formValidate = this.formValidate.concat([{
        beforeChooseImage: '',
        afterChooseImage: '',
        attrValueName: ''
      }]);
    },
    removeAttr: function removeAttr(i) {
      this.formValidate.splice(i, 1);
    },
    goUpAttrValAction: function goUpAttrValAction(i) {
      this.formValidate.splice(i - 1, 0, this.formValidate[i]);
      this.formValidate.splice(i + 1, 1);
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var attrVal = JSON.parse(JSON.stringify(_this3.formValidate));
          if (attrVal.length <= 0) {
            _this3.$message.error('请设置属性值');
            return false;
          }
          var data = {
            attrName: _this3.ruleForm.attrName,
            chooseType: _this3.ruleForm.chooseType,
            maxChooseNum: Number(_this3.ruleForm.maxChooseNum),
            minChooseNum: Number(_this3.ruleForm.minChooseNum),
            useType: _this3.ruleForm.useType,
            attrValues: attrVal
          };
          if (data.minChooseNum > data.maxChooseNum) {
            _this3.$message.error('最少选择数量不可大于最大数量');
            return false;
          }
          _this3.btnLoading = true;
          if (_this3.editId) {
            (0, _product.attrUpdateApi)(_this3.editId, data).then(function () {
              _this3.getList();
              _this3.btnLoading = false;
              _this3.dialogEditAttrVisible = false;
            }).catch(function (msg) {
              _this3.$message.error(msg);
              _this3.btnLoading = false;
            });
            return false;
          }
          (0, _product.attrCreateApi)(data).then(function () {
            _this3.getList();
            _this3.btnLoading = false;
            _this3.dialogEditAttrVisible = false;
          }).catch(function (msg) {
            _this3.$message.error(msg);
            _this3.btnLoading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};