"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _setting = require("@/api/setting");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _marketing = require("@/api/marketing");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _detail = _interopRequireDefault(require("./detail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "retail",
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default,
    retaildetail: _detail.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      listLoading: true,
      tableFrom: {
        type: '',
        timeType: '',
        nameOrPhone: '',
        pageNum: 1,
        pageSize: 20
      },
      timeVal: [],
      fromList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }],
      createForm: {
        adminId: '',
        userId: '',
        isSaveBefore: '',
        dividedScale: '',
        isOpenRemark: null
      },
      createRules: {
        adminId: [{
          required: true,
          message: '请选择绑定员工',
          trigger: 'change'
        }],
        userId: [{
          required: true,
          message: '请选择分销员',
          trigger: 'change'
        }],
        isSaveBefore: [{
          required: true,
          message: '请选择是否保留之前数据',
          trigger: 'change'
        }],
        dividedScale: [{
          required: true,
          message: '请输入佣金比例',
          trigger: 'blur'
        }]
      },
      uid: '',
      showadd: false,
      showEdit: false,
      userTitle: '',
      adduid: "",
      userList: [],
      adminList: [],
      searchLoading: false,
      showdetail: false,
      showretaildetail: false,
      changestatusflag: false,
      changestatusRow: {
        otherIsSave: '1'
      },
      orderFlag: false,
      orderData: {
        data: [],
        total: 0
      },
      orderParams: {
        pageNum: 1,
        pageSize: 10,
        type: '',
        userId: '',
        timeType: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
    this.searchAdminAction();
  },
  methods: {
    changeretailstatus: function changeretailstatus(row, type, otherIsSave) {
      var _this = this;
      var str = '';
      if (type == 0) {
        str = '解除分销';
      } else if (type == 1) {
        str = '删除';
      } else {
        str = '启用';
      }
      this.$confirm("\u786E\u5B9A".concat(str, "\u5417\uFF1F")).then(function () {
        (0, _marketing.distributorDeleteOrStartApi)({
          id: row.id,
          type: type,
          otherIsSave: otherIsSave
        }).then(function (res) {
          _this.getList();
        });
      });
    },
    retailstatus: function retailstatus(row, type) {
      this.changestatusRow.id = row.id;
      this.changestatusRow.type = type;
      this.changestatusflag = true;
    },
    handlechangestatus: function handlechangestatus() {
      this.changestatusflag = false;
      this.changeretailstatus(this.changestatusRow, this.changestatusRow.type, this.changestatusRow.otherIsSave);
    },
    showteamDetail: function showteamDetail(row) {
      console.log(row, 344);
      this.showretaildetail = true;
      this.uid = row.userId;
    },
    changeadduser: function changeadduser() {
      this.showadd = true;
      this.showEdit = true;
      this.userTitle = '添加分销员';
    },
    searchUserAction: function searchUserAction(data) {
      var _this2 = this;
      var params = {
        nameOrPhone: data,
        pageNum: 1,
        pageSize: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this2.userList = res.rows;
      });
    },
    searchAdminAction: function searchAdminAction() {
      var _this3 = this;
      var params = {
        // nickName: data,
        pageNum: 1,
        pageSize: 10
      };
      (0, _setting.adminListApi)(params).then(function (res) {
        _this3.adminList = res.rows;
      });
    },
    handleadd: function handleadd(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.createForm));
          var action = _marketing.distributorCreateApi;
          data.isOpenRemark = data.isOpenRemark ? 1 : 0;
          if (data.id) {
            action = _marketing.distributorUpdateApi;
          }
          action(data).then(function (res) {
            _this4.showadd = false;
            _this4.getList(1);
          });
        }
      });
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    retaildetailclose: function retaildetailclose() {
      this.showretaildetail = false;
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.userId;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    editHandle: function editHandle(row) {
      var _this5 = this;
      this.showadd = true;
      this.showEdit = false;
      this.userTitle = '编辑分销员';
      this.createForm.id = row.id;
      (0, _marketing.getRetailUserById)({
        id: row.id
      }).then(function (res) {
        _this5.createForm.dividedScale = res.data.dividedScale;
        _this5.createForm.isOpenRemark = res.data.isOpenRemark ? true : false;
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.timeType = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num ? num : this.tableFrom.pageNum;
      (0, _marketing.distributorListApi)(this.tableFrom).then(function (res) {
        _this6.tableData.data = res.rows;
        _this6.tableData.total = res.total;
        _this6.tableData.statistical = res.statistical;
        _this6.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    retaildetailrefash: function retaildetailrefash() {
      console.log("刷新");
      this.getList();
    },
    // 导出
    exportRecord: function exportRecord() {
      var _this7 = this;
      // this.tableFrom
      (0, _marketing.redeemCodeExport)(this.tableFrom).then(function (res) {
        _this7.$message.success('操作成功');
      });
    },
    // 导出列表
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(29);
    },
    // getExportFileList1() {
    //   this.$refs.exportList.exportFileList1(30);
    // },
    showOrderList: function showOrderList(row) {
      this.orderFlag = true;
      this.distributorOrderListApiMethod(row);
    },
    sizeChange: function sizeChange(val) {
      this.orderParams.pageSize = val;
      this.distributorOrderListApiMethod();
    },
    pageChange1: function pageChange1(page) {
      this.orderParams.pageNum = page;
      this.distributorOrderListApiMethod();
    },
    distributorOrderListApiMethod: function distributorOrderListApiMethod(row, timeType) {
      var _this8 = this;
      this.orderParams.type = 1;
      if (row) {
        this.orderParams.userId = row.userId;
      }
      if (timeType) {
        this.orderParams.timeType = timeType;
      }
      (0, _marketing.distributorOrderListApi)(this.orderParams).then(function (res) {
        _this8.orderData.data = res.rows;
        _this8.orderData.total = res.total;
      });
    }
  }
};