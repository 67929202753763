"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.review = review;
exports.reviewslist = reviewslist;
var _request = _interopRequireDefault(require("./request"));
/**
 * 审核列表
 */
function reviewslist(data) {
  return _request.default.get('erp/audit/lst', data);
}
/**
 * 审核
 */
function review(id, data) {
  return _request.default.post("erp/audit/switchStatus/".concat(id), data);
}