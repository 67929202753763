"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approvalApi = approvalApi;
exports.auditLogApi = auditLogApi;
exports.confirmApi = confirmApi;
exports.divideLogApi = divideLogApi;
exports.divideLogExportApi = divideLogExportApi;
exports.exportApi = exportApi;
exports.getListApi = getListApi;
exports.refundAuditApi = refundAuditApi;
exports.refundExportApi = refundExportApi;
exports.refundListApi = refundListApi;
exports.updateRemarkApi = updateRemarkApi;
exports.withdrawalExportApi = withdrawalExportApi;
exports.withdrawalListApi = withdrawalListApi;
var _request = _interopRequireDefault(require("./request"));
function getListApi(data) {
  return _request.default.get("/open3d/pay/sys/pay/order/list", data);
}
//审核

function confirmApi(data) {
  return _request.default.post("/open3d/pay/sys/pay/order/confirm", data);
}
//审核记录

function auditLogApi(data) {
  return _request.default.get("/open3d/pay/sys/pay/order/auditLog", data);
}

//入金导出

function exportApi(data) {
  return _request.default.get("/open3d/pay/sys/pay/order/export", data);
}

//获取提现记录
function withdrawalListApi(data) {
  return _request.default.get("/open3d/pay/sys/withdrawal/list", data);
}
//提现审批

function approvalApi(data) {
  return _request.default.post("/open3d/pay/sys/withdrawal/approval", data);
}

//导出提现记录
function withdrawalExportApi(data) {
  return _request.default.get("/open3d/pay/sys/withdrawal/export", data);
}

//退款单列表、平台出金-退款列表

function refundListApi(data) {
  return _request.default.get("/open3d/pay/sys/refund/record/refundList", data);
}
//导出退款单
function refundExportApi(data) {
  return _request.default.get("/open3d/pay/sys/refund/record/export", data);
}

//退款
function refundAuditApi(data) {
  return _request.default.post("/open3d/pay/sys/refund/record/audit", data);
}
//分账
function divideLogApi(data) {
  return _request.default.get("/open3d/pay/sys/pay/orderDivideLog/list", data);
}
//分账导出
function divideLogExportApi(data) {
  return _request.default.get("/open3d/pay/sys/pay/orderDivideLog/export", data);
}
//修改备注
function updateRemarkApi(data) {
  return _request.default.put("/open3d/pay/sys/pay/order/updateRemark", data);
}