var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "满足条件", "min-width": "120", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("连续签到")]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "w100 ml5",
                          attrs: { controls: false, disabled: $index == 0 },
                          model: {
                            value: row.dayTime,
                            callback: function ($$v) {
                              _vm.$set(row, "dayTime", $$v)
                            },
                            expression: "row.dayTime",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml5" }, [_vm._v("天")]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品类型", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-select",
                      {
                        staticClass: "w200",
                        attrs: { disabled: $index == 0 },
                        model: {
                          value: row.prizeType,
                          callback: function ($$v) {
                            _vm.$set(row, "prizeType", $$v)
                          },
                          expression: "row.prizeType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { value: 1, label: "数字藏品" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: 2, label: "数字盲盒" },
                        }),
                        _vm._v(" "),
                        _c("el-option", { attrs: { value: 3, label: "积分" } }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: 4, label: "优惠券" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品", "min-width": "200", prop: "isStart" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.prizeType != 3
                      ? _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            scope.row.image
                              ? _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    "margin-right": "5px",
                                  },
                                  attrs: { src: scope.row.image + "!120a" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.name
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "line1 mr10",
                                    staticStyle: { "max-width": "80%" },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(scope.row.name) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeProduct(scope.$index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.name ? "更改" : "选择"))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.prizeType == 3
                      ? _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c(
                              "div",
                              { staticClass: "w100" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "积分类型" },
                                    model: {
                                      value: scope.row.objectId,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "objectId", $$v)
                                      },
                                      expression: "scope.row.objectId",
                                    },
                                  },
                                  _vm._l(_vm.typeList, function (m, i) {
                                    return _c("el-option", {
                                      key: "type" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "w200 ml10",
                              attrs: { placeholder: "请输入积分" },
                              model: {
                                value: scope.row.scoreNum,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "scoreNum", $$v)
                                },
                                expression: "scope.row.scoreNum",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v("积分"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品数量", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.prizeType != 3
                      ? _c(
                          "div",
                          [
                            _c("el-input-number", {
                              staticClass: "w100",
                              attrs: {
                                controls: false,
                                placeholder: "请输入获得奖品数量",
                              },
                              model: {
                                value: row.prizeNum,
                                callback: function ($$v) {
                                  _vm.$set(row, "prizeNum", $$v)
                                },
                                expression: "row.prizeNum",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "", "min-width": "200", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    $index == 0
                      ? _c(
                          "div",
                          [
                            _c("span", [_vm._v("每邀请一人并开通钱包可获得")]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false, precision: 0 },
                              model: {
                                value: row.inviteScoreNum,
                                callback: function ($$v) {
                                  _vm.$set(row, "inviteScoreNum", $$v)
                                },
                                expression: "row.inviteScoreNum",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("最多")]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false, precision: 0 },
                              model: {
                                value: row.maxNum,
                                callback: function ($$v) {
                                  _vm.$set(row, "maxNum", $$v)
                                },
                                expression: "row.maxNum",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "操作",
                "min-width": "80",
                align: "center",
                fixed: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.$index != 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(
                                    scope.row.id,
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: { click: _vm.addRuleAction },
                    },
                    [_vm._v("添加规则")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex align-items-c" },
        [
          _c("el-switch", {
            attrs: { "active-color": "#13ce66", "inactive-color": "#ccc" },
            model: {
              value: _vm.rule.isOpen,
              callback: function ($$v) {
                _vm.$set(_vm.rule, "isOpen", $$v)
              },
              expression: "rule.isOpen",
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "ml10" }, [_vm._v("开启看广告得积分")]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.rule.isOpen
        ? _c(
            "div",
            { staticClass: "mt20 flex align-items-c" },
            [
              _c("span", [_vm._v("广告厂商")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w200 ml5",
                  attrs: { placeholder: "选择广告厂商" },
                  model: {
                    value: _vm.rule.adChannel,
                    callback: function ($$v) {
                      _vm.$set(_vm.rule, "adChannel", $$v)
                    },
                    expression: "rule.adChannel",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 0, label: "优量汇" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 1, label: "穿山甲" } }),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml20" }, [_vm._v("广告APPID")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200 ml5",
                model: {
                  value: _vm.rule.appId,
                  callback: function ($$v) {
                    _vm.$set(_vm.rule, "appId", $$v)
                  },
                  expression: "rule.appId",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml20" }, [_vm._v("广告ID")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200 ml5",
                model: {
                  value: _vm.rule.posId,
                  callback: function ($$v) {
                    _vm.$set(_vm.rule, "posId", $$v)
                  },
                  expression: "rule.posId",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.rule.isOpen
        ? _c(
            "div",
            { staticClass: "mt20 flex align-items-c" },
            [
              _c("span", [_vm._v("每日可观看")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w100 ml5",
                attrs: { controls: false },
                model: {
                  value: _vm.rule.number,
                  callback: function ($$v) {
                    _vm.$set(_vm.rule, "number", $$v)
                  },
                  expression: "rule.number",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml5" }, [_vm._v("次，每次获得")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w100 ml5" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "积分类型" },
                      model: {
                        value: _vm.rule.scoreTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.rule, "scoreTypeId", $$v)
                        },
                        expression: "rule.scoreTypeId",
                      },
                    },
                    _vm._l(_vm.typeList, function (m, i) {
                      return _c("el-option", {
                        key: "type" + i,
                        attrs: { value: m.id, label: m.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w100 ml10",
                attrs: { placeholder: "请输入积分", controls: false },
                model: {
                  value: _vm.rule.scoreNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.rule, "scoreNum", $$v)
                  },
                  expression: "rule.scoreNum",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml5" }, [_vm._v("积分")]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
            [_vm._v("保存签到规则")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Coupon", {
        ref: "coupon",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }