var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index === 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item.image, fit: "cover" },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "content-box-bottom" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "text color-black",
                        style:
                          "color: " +
                          _vm.facade.name_color +
                          ";font-size: " +
                          _vm.facade.text_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.text_style +
                          ";",
                      },
                      [_vm._v("\n            " + _vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sign",
                          style:
                            "color: " +
                            _vm.facade.time_color +
                            ";font-size: " +
                            _vm.facade.time_text_size / 2 +
                            "px;font-weight: " +
                            _vm.facade.time_text_style +
                            ";",
                        },
                        [_vm._v("\n              " + _vm._s(item.sign))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        style:
                          "font-size: " +
                          _vm.facade.home_page_size / 2 +
                          "px;color: " +
                          _vm.facade.home_page_color +
                          ";padding: " +
                          _vm.facade.home_page_pTop / 2 +
                          "px " +
                          _vm.facade.home_page_pLeft / 2 +
                          "px;\n          border: 1px solid " +
                          _vm.facade.home_page_bColor +
                          ";border-radius: " +
                          _vm.facade.home_page_radius / 2 +
                          "px",
                      },
                      [_vm._v("主页")]
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2 && _vm.content.img_style != 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "two-list",
                class: { mt0: index < 2, mr0: index % 2 !== 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  marginRight: _vm.facade.item_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                  width:
                    (750 -
                      _vm.facade.page_padding -
                      _vm.facade.page_padding -
                      _vm.facade.item_padding) /
                      4 +
                    "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    style: {
                      width:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding) /
                          4 +
                        "px",
                      height:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding) /
                          4 +
                        "px",
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "content-box-bottom" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        style:
                          "color: " +
                          _vm.facade.name_color +
                          ";font-size: " +
                          _vm.facade.text_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.text_style +
                          ";",
                      },
                      [_vm._v("\n            " + _vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sign",
                          style:
                            "color: " +
                            _vm.facade.time_color +
                            ";font-size: " +
                            _vm.facade.time_text_size / 2 +
                            "px;font-weight: " +
                            _vm.facade.time_text_style +
                            ";",
                        },
                        [_vm._v("\n              " + _vm._s(item.sign))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        style:
                          "font-size: " +
                          _vm.facade.home_page_size / 2 +
                          "px;color: " +
                          _vm.facade.home_page_color +
                          ";padding: " +
                          _vm.facade.home_page_pTop / 2 +
                          "px " +
                          _vm.facade.home_page_pLeft / 2 +
                          "px;\n          border: 1px solid " +
                          _vm.facade.home_page_bColor +
                          ";border-radius: " +
                          _vm.facade.home_page_radius / 2 +
                          "px",
                      },
                      [_vm._v("主页")]
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 4
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "list-horizontal",
                class: { mt0: index < 1 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                !_vm.facade.no_show_img
                  ? _c(
                      "div",
                      {
                        staticClass: "list-h-left",
                        style: {
                          width: _vm.facade.img_width / 2 + "px",
                          height: _vm.facade.img_height / 2 + "px",
                        },
                      },
                      [
                        _c(
                          "el-image",
                          {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: item.image,
                              fit:
                                _vm.content.img_style === 2
                                  ? "contain"
                                  : "cover",
                            },
                            on: { error: _vm.error },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "list-h-right",
                    style: _vm.facade.no_show_img
                      ? "padding-bottom: 30px;"
                      : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        style:
                          "color: " +
                          _vm.facade.name_color +
                          ";font-size: " +
                          _vm.facade.text_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.text_style +
                          ";",
                      },
                      [_vm._v("\n          " + _vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tags-box" },
                      _vm._l(item.tags, function (m, i) {
                        return _c("div", { key: i, staticClass: "tag" }, [
                          _vm._v(_vm._s(m)),
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sign",
                          style:
                            "color: " +
                            _vm.facade.time_color +
                            ";font-size: " +
                            _vm.facade.time_text_size / 2 +
                            "px;font-weight: " +
                            _vm.facade.time_text_style +
                            ";",
                        },
                        [_vm._v("\n            2022-06-14 11:47")]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    {
                      style:
                        "margin-right: 10px;font-size: " +
                        _vm.facade.home_page_size / 2 +
                        "px;color: " +
                        _vm.facade.home_page_color +
                        ";padding: " +
                        _vm.facade.home_page_pTop / 2 +
                        "px " +
                        _vm.facade.home_page_pLeft / 2 +
                        "px;\n        border: 1px solid " +
                        _vm.facade.home_page_bColor +
                        ";border-radius: " +
                        _vm.facade.home_page_radius / 2 +
                        "px",
                    },
                    [_vm._v("主页")]
                  ),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }