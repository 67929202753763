var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "身份：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.identitySetId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "identitySetId", $$v)
                                },
                                expression: "tableFrom.identitySetId",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.identityList, function (m, i) {
                                return _c(
                                  "el-radio-button",
                                  { key: i, attrs: { label: m.id } },
                                  [_vm._v(_vm._s(m.identityName))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          staticStyle: { display: "block" },
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(_vm.tableFrom.date)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.timeType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "timeType", $$v)
                                },
                                expression: "tableFrom.timeType",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd",
                              format: "yyyy/MM/dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: " " } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "用户名称/手机号",
                                size: "small",
                              },
                              model: {
                                value: _vm.tableFrom.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keyword", $$v)
                                },
                                expression: "tableFrom.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: " " } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "空间名称", size: "small" },
                              model: {
                                value: _vm.tableFrom.spaceName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "spaceName", $$v)
                                },
                                expression: "tableFrom.spaceName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: " " } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "模型名称", size: "small" },
                              model: {
                                value: _vm.tableFrom.modelName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "modelName", $$v)
                                },
                                expression: "tableFrom.modelName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showadd = true
                        },
                      },
                    },
                    [_vm._v("帮用户管理空间")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "空间id", "min-width": "100", prop: "id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "空间封面图", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "80px !important",
                                height: "80px !important",
                              },
                              attrs: {
                                src: scope.row.spaceImage + "!120a",
                                fit: "cover",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "空间名称", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.spaceName))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(scope.row.updateTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "展品", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v("共" + _vm._s(scope.row.exhibitCount) + "个"),
                        ]),
                        _vm._v(" "),
                        scope.row.exhibit3dCount
                          ? _c("div", [
                              _vm._v(
                                "3D展品：" +
                                  _vm._s(scope.row.exhibit3dCount) +
                                  "个"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.exhibitImageCount
                          ? _c("div", [
                              _vm._v(
                                "图片展品：" +
                                  _vm._s(scope.row.exhibitImageCount) +
                                  "个"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.exhibitVideoCount
                          ? _c("div", [
                              _vm._v(
                                "视频展品：" +
                                  _vm._s(scope.row.exhibitVideoCount) +
                                  "个"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.exhibitMusicCount
                          ? _c("div", [
                              _vm._v(
                                "音频展品：" +
                                  _vm._s(scope.row.exhibitMusicCount) +
                                  "个"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "素材", "min-width": "100", prop: "type_text" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sourceMaterialImageCount
                          ? _c("div", [
                              _vm._v(
                                "图片：" +
                                  _vm._s(scope.row.sourceMaterialImageCount) +
                                  "张"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.sourceMaterialVideoCount
                          ? _c("div", [
                              _vm._v(
                                "视频：" +
                                  _vm._s(scope.row.sourceMaterialVideoCount) +
                                  "个"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.sourceMaterialMusicCount
                          ? _c("div", [
                              _vm._v(
                                "音频：" +
                                  _vm._s(scope.row.sourceMaterialMusicCount) +
                                  "个"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "空间模型", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.modelName) +
                              ",授#" +
                              _vm._s(scope.row.number) +
                              "/" +
                              _vm._s(scope.row.issuedCount)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n            面积" +
                              _vm._s(scope.row.modelSize) +
                              "平米，默认展位" +
                              _vm._s(scope.row.boothCount) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "模型版本号", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "模型版本号V" + _vm._s(scope.row.modelVersion)
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.upgradableVersion
                          ? _c("div", [
                              _vm._v(
                                "\n            升级新版本V" +
                                  _vm._s(scope.row.upgradableVersion) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "80",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex-box" }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-right": "10px" } },
                            [
                              _vm._v(
                                "\n              开启推荐\n              "
                              ),
                              _c("el-switch", {
                                attrs: {
                                  value: row.hasRecommended ? true : false,
                                },
                                on: {
                                  change: function (val) {
                                    return _vm.onHasRecommended(val, row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-popconfirm", {
                                attrs: { title: "确定删除吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteSpace(row.id)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "reference",
                                      fn: function () {
                                        return [
                                          _c("el-button", {
                                            attrs: {
                                              title: "删除空间",
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.mask,
              callback: function ($$v) {
                _vm.mask = $$v
              },
              expression: "mask",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showreview = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(-1)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(1)
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择客户登录",
            visible: _vm.showadd,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "成员：", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择用户",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.adduid,
                        callback: function ($$v) {
                          _vm.adduid = $$v
                        },
                        expression: "adduid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.userId, label: m.nickName },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  "border-radius": "50%",
                                  "margin-right": "12px",
                                },
                                attrs: { src: m.avatar, alt: "" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "line-height": "18px",
                                  },
                                },
                                [
                                  _c("div", [_vm._v(_vm._s(m.nickName))]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(m.userPhone))]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleadd } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }