"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: null,
      listLoading: true,
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  mounted: function mounted() {
    this.load();
  },
  methods: {
    load: function load() {
      var _this = this;
      this.listLoading = true;
      (0, _accounts.getSMSSendRecordApi)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.list = response.data.data;
        _this.listLoading = false;
      }).catch(function (message) {
        _this.listLoading = false;
        _this.list = [];
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    }
  }
};