var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "r-title" }, [_vm._v("送拍记录")]),
    _vm._v(" "),
    _c("div", { staticClass: "count-box" }, [
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(_vm._s(_vm.formatPriceNumber(_vm.countjson.productCount))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("送拍(件)")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(_vm._s(_vm.formatPriceNumber(_vm.countjson.dealProductCount))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("成交(件)")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.dealOrderTotalPrice))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("成交额")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(_vm._s(_vm.formatPriceNumber(_vm.countjson.dealAveragePrice))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("平均单价")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.refundProductCount))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("退回(件)")]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { padding: "20px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "multipleSelection",
            staticClass: "table",
            staticStyle: { width: "100%" },
            attrs: {
              "tooltip-effect": "dark",
              "row-key": function (row) {
                return row.order_id
              },
              data: _vm.tableData.data,
              size: "mini",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                label: "交易时间",
                "min-width": "140",
              },
            }),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: { "min-width": "600" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.entrustOrderProduct,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "enp_" + index,
                              staticClass: "flex-box",
                              class: {
                                "flex-box-border-bottom":
                                  index <
                                  scope.row.entrustOrderProduct.length - 1,
                              },
                            },
                            [
                              _c("div", { staticClass: "flex-box-name" }, [
                                _vm._v(_vm._s(item.album.album_name)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-box-image" }, [
                                _c(
                                  "div",
                                  { staticClass: "demo-image__preview" },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: item.product.image,
                                        "preview-src-list": [
                                          item.product.image,
                                        ],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-box-autor" }, [
                                _vm._v(_vm._s(item.auction.lot)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-box-name" }, [
                                _vm._v(_vm._s(item.product.store_name)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex-box-price" },
                                [
                                  item.auction_status == 1
                                    ? [_vm._v("进行中")]
                                    : item.auction_status == -1
                                    ? [_vm._v("违约")]
                                    : item.auction_status == 2
                                    ? [_vm._v("预展中")]
                                    : item.auction_status == 3
                                    ? [_vm._v("保留价待确认")]
                                    : item.auction_status == 4
                                    ? [_vm._v("流拍")]
                                    : item.auction_status == 5
                                    ? [_vm._v("已中拍 未付款")]
                                    : item.auction_status == 6
                                    ? [_vm._v("已完成")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-box-price" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        item.now_price + "",
                                        2
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        }
                      )
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", { staticClass: "flex-box-name" }, [
                      _vm._v("专场"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box-image" }, [
                      _vm._v("图片"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box-autor" }, [
                      _vm._v("LOT"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box-name" }, [
                      _vm._v("拍品名称"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box-price" }, [
                      _vm._v("拍品状态"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box-price" }, [
                      _vm._v("成交价"),
                    ]),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [6, 20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }