var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _vm._v("\n      创建时间：\n      "),
              _c(
                "el-radio-group",
                {
                  staticClass: "mr20",
                  attrs: { type: "button", size: "small" },
                  on: {
                    change: function ($event) {
                      return _vm.selectChange(_vm.searchForm.timeType)
                    },
                  },
                  model: {
                    value: _vm.searchForm.timeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "timeType", $$v)
                    },
                    expression: "searchForm.timeType",
                  },
                },
                _vm._l(_vm.timeList, function (item, i) {
                  return _c(
                    "el-radio-button",
                    { key: i, attrs: { label: item.val } },
                    [_vm._v(_vm._s(item.text) + "\n            ")]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("el-date-picker", {
                staticStyle: { width: "250px" },
                attrs: {
                  "value-format": "yyyy/MM/dd HH:mm:ss",
                  format: "yyyy/MM/dd HH:mm:ss",
                  size: "small",
                  type: "datetimerange",
                  placement: "bottom-end",
                  "start-placeholder": "选择开始时间",
                  "end-placeholder": "选择结束时间",
                },
                on: { change: _vm.onchangeTime },
                model: {
                  value: _vm.timeType,
                  callback: function ($$v) {
                    _vm.timeType = $$v
                  },
                  expression: "timeType",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px", "margin-right": "20px" },
                  attrs: { size: "small", placeholder: "客户名称/手机号" },
                  model: {
                    value: _vm.searchForm.phoneOrName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "phoneOrName", $$v)
                    },
                    expression: "searchForm.phoneOrName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px" },
                  attrs: { size: "small", placeholder: "数字人" },
                  model: {
                    value: _vm.searchForm.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "keyword", $$v)
                    },
                    expression: "searchForm.keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list, size: "small" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { width: "50", type: "selection" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", width: "70", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.avatar,
                              expression: "scope.row.avatar",
                            },
                          ],
                          staticStyle: {
                            width: "36px",
                            height: "36px",
                            display: "block",
                            margin: "0 auto",
                          },
                          attrs: { src: scope.row.avatar + "!120a" },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !scope.row.avatar,
                              expression: "!scope.row.avatar",
                            },
                          ],
                          staticStyle: {
                            width: "36px",
                            height: "36px",
                            border: "1px #eee solid",
                            margin: "0 auto",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "nickName", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "版权人", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.nickNameClick(row.userId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.name))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "虚拟形象" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v("\n          -\n        ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "声音克隆" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v("\n          -\n        ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "humanDefinition",
                  label: "角色定义",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "tags", label: "沟通训练", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        !!row.knowledgeShow
                          ? _c("div", [
                              _c("div", [
                                _vm._v(
                                  "作品资料 " +
                                    _vm._s(row.knowledgeShow.worksNum) +
                                    " 件"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "文字资料 " +
                                    _vm._s(row.knowledgeShow.charactersNum) +
                                    " 篇"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "社交对话 " +
                                    _vm._s(row.knowledgeShow.dialogueNum) +
                                    " 篇"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "绘画训练" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onWorkStyle(row.id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.trainDrawCount))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.status == 0,
                                expression: "row.status == 0",
                              },
                            ],
                            staticClass: "color-error",
                          },
                          [_vm._v("待审核")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.status == 1,
                                expression: "row.status == 1",
                              },
                            ],
                            staticClass: "color-warning",
                          },
                          [_vm._v("制作中")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.status == 2,
                                expression: "row.status == 2",
                              },
                            ],
                            staticClass: "color-success",
                          },
                          [_vm._v("已上线")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.status == -1,
                                expression: "row.status == -1",
                              },
                            ],
                            staticClass: "color-info",
                          },
                          [_vm._v("已取消")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.status == 1 || row.status == 2,
                                expression:
                                  "row.status == 1 || row.status == 2",
                              },
                            ],
                            staticClass: "m0 p0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onPrompt(row.id)
                              },
                            },
                          },
                          [_vm._v("编辑提示词")]
                        ),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定上线吗？" },
                          on: {
                            confirm: function ($event) {
                              return _vm.onChangeStatus(row, 2)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.status == 1,
                                            expression: "row.status == 1",
                                          },
                                        ],
                                        staticClass: "m0 p0",
                                        attrs: { type: "text", size: "small" },
                                      },
                                      [_vm._v("上线")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定下线吗？" },
                          on: {
                            confirm: function ($event) {
                              return _vm.onChangeStatus(row, 3)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.status == 2,
                                            expression: "row.status == 2",
                                          },
                                        ],
                                        staticClass: "m0 p0",
                                        attrs: { type: "text", size: "small" },
                                      },
                                      [_vm._v("下线")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定通过审核吗？" },
                          on: {
                            confirm: function ($event) {
                              return _vm.onChangeStatus(row, 1)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.status == 0,
                                            expression: "row.status == 0",
                                          },
                                        ],
                                        staticClass: "m0 p0",
                                        attrs: { type: "text", size: "small" },
                                      },
                                      [_vm._v("通过")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定拒绝审核吗？" },
                          on: {
                            confirm: function ($event) {
                              return _vm.onChangeStatus(row, -1)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.status == 0,
                                            expression: "row.status == 0",
                                          },
                                        ],
                                        staticClass: "m0 p0",
                                        attrs: { type: "text", size: "small" },
                                      },
                                      [_vm._v("拒绝")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定删除吗？" },
                          on: {
                            confirm: function ($event) {
                              return _vm.onDelete(row)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.status == -1,
                                            expression: "row.status == -1",
                                          },
                                        ],
                                        staticClass: "m0 p0",
                                        attrs: { type: "text", size: "small" },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.batchLabel()
                        },
                      },
                    },
                    [_vm._v("批量加标签")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.pages.pageSize,
                  "current-page": _vm.pages.pageNum,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: { "current-change": _vm.changePage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleLabel
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleLabel,
                "close-on-click-modal": false,
                center: "",
                title: "修改标签",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleLabel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "labelForm",
                  attrs: {
                    size: "small",
                    model: _vm.labelForm,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户标签", prop: "tagId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: "请选择用户标签",
                          },
                          model: {
                            value: _vm.labelForm.tagId,
                            callback: function ($$v) {
                              _vm.$set(_vm.labelForm, "tagId", $$v)
                            },
                            expression: "labelForm.tagId",
                          },
                        },
                        _vm._l(_vm.tagList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmLabelAction("labelForm")
                        },
                      },
                    },
                    [_vm._v("\n        确定\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showdetail
        ? _c("userdetail", {
            attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
            on: { close: _vm.userdetailclose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }