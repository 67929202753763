"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.allConfigListApi = allConfigListApi;
exports.allConfigUpdateApi = allConfigUpdateApi;
exports.attachmentCreateApi = attachmentCreateApi;
exports.attachmentDeleteApi = attachmentDeleteApi;
exports.attachmentListApi = attachmentListApi;
exports.attachmentUpdateApi = attachmentUpdateApi;
exports.bankListApi = bankListApi;
exports.bidRangeCreate = bidRangeCreate;
exports.bidRangeDel = bidRangeDel;
exports.bidRangeEdit = bidRangeEdit;
exports.bidRangeList = bidRangeList;
exports.categoryApi = categoryApi;
exports.changeConfigClassifyStatus = changeConfigClassifyStatus;
exports.changeConfigSettingStatus = changeConfigSettingStatus;
exports.classifyDelApi = classifyDelApi;
exports.configClassKeysSave = configClassKeysSave;
exports.configClassifyLst = configClassifyLst;
exports.configSettingLst = configSettingLst;
exports.copyApi = copyApi;
exports.createClassifyTableApi = createClassifyTableApi;
exports.createConfigClassifyTable = createConfigClassifyTable;
exports.createConfigSettingTable = createConfigSettingTable;
exports.createGroupData = createGroupData;
exports.createGroupTable = createGroupTable;
exports.createQRCodeApi = createQRCodeApi;
exports.createQRCodeMiniApi = createQRCodeMiniApi;
exports.createScheme = createScheme;
exports.createVideoGoodsApi = createVideoGoodsApi;
exports.delClassifyTableApi = delClassifyTableApi;
exports.delDiypageDetailApi = delDiypageDetailApi;
exports.deleteGroupData = deleteGroupData;
exports.diypageCreate = diypageCreate;
exports.diypageDetail = diypageDetail;
exports.diypageEdit = diypageEdit;
exports.diypageList = diypageList;
exports.diypageSetDefaultApi = diypageSetDefaultApi;
exports.downloadexcel = downloadexcel;
exports.editClassifyTableApi = editClassifyTableApi;
exports.editPwd = editPwd;
exports.editVideoGoodsApi = editVideoGoodsApi;
exports.editVideoGoodsPriceApi = editVideoGoodsPriceApi;
exports.editVideoGoodsStatusApi = editVideoGoodsStatusApi;
exports.editVideoGoodsUrlApi = editVideoGoodsUrlApi;
exports.editheaderwidth = editheaderwidth;
exports.exportexcel = exportexcel;
exports.expressListApi = expressListApi;
exports.feedbackDelApi = feedbackDelApi;
exports.feedbackListApi = feedbackListApi;
exports.formatLstApi = formatLstApi;
exports.getApi = getApi;
exports.getAreaApi = getAreaApi;
exports.getCityApi = getCityApi;
exports.getCodes = getCodes;
exports.getCodesNew = getCodesNew;
exports.getConfigClassKeys = getConfigClassKeys;
exports.getConfigForm = getConfigForm;
exports.getCustomHeadder = getCustomHeadder;
exports.getDomain = getDomain;
exports.getUploadOssStsToken = getUploadOssStsToken;
exports.getVideoClassApi = getVideoClassApi;
exports.getVideoGoodsDetailApi = getVideoGoodsDetailApi;
exports.getVideoGoodsListApi = getVideoGoodsListApi;
exports.gettableheader = gettableheader;
exports.groupDataLst = groupDataLst;
exports.groupDetail = groupDetail;
exports.groupLst = groupLst;
exports.importexcel = importexcel;
exports.memberInfoApi = memberInfoApi;
exports.menuCreateApi = menuCreateApi;
exports.menuDeleteApi = menuDeleteApi;
exports.menuListApi = menuListApi;
exports.menuUpdateApi = menuUpdateApi;
exports.picDeleteApi = picDeleteApi;
exports.picNameEditApi = picNameEditApi;
exports.picNameUpdateApi = picNameUpdateApi;
exports.sensitivewordAddApi = sensitivewordAddApi;
exports.sensitivewordDelApi = sensitivewordDelApi;
exports.sensitivewordListApi = sensitivewordListApi;
exports.serviceChatListApi = serviceChatListApi;
exports.serviceChatUidListApi = serviceChatUidListApi;
exports.serviceCreateApi = serviceCreateApi;
exports.serviceDeleteApi = serviceDeleteApi;
exports.serviceListApi = serviceListApi;
exports.serviceStatusApi = serviceStatusApi;
exports.serviceUpdateApi = serviceUpdateApi;
exports.setCustomHeader = setCustomHeader;
exports.setMemberInfoApi = setMemberInfoApi;
exports.settingDelApi = settingDelApi;
exports.sharePosterImgApi = sharePosterImgApi;
exports.stationNewsList = stationNewsList;
exports.stationNoticeRead = stationNoticeRead;
exports.unreadCountApi = unreadCountApi;
exports.updateConfigClassifyTable = updateConfigClassifyTable;
exports.updateConfigSettingTable = updateConfigSettingTable;
exports.updateGroupData = updateGroupData;
exports.updateGroupTable = updateGroupTable;
exports.userListApi = userListApi;
exports.userLstApi = userLstApi;
var _request = _interopRequireDefault(require("./request"));
// 装修 - 自定义页面列表
function diypageList(data) {
  return _request.default.get("system/page/list", data);
}
//复制一个页面
function copyApi(id) {
  return _request.default.post("system/page/copy/".concat(id));
}
// 装修 - 设置自定义页面为首页
function diypageSetDefaultApi(id) {
  return _request.default.put("system/page/setIndex/".concat(id));
}

// 装修 - 自定义页面详情
function diypageDetail(data) {
  return _request.default.get("system/page/info", data);
}

// 装修 - 删除自定义页面
function delDiypageDetailApi(id) {
  return _request.default.delete("system/page/delete/".concat(id));
}

// 装修 - 自定义页面创建
function diypageCreate(data) {
  return _request.default.post("system/page/save", data);
}

// 装修 - 自定义页面修改
function diypageEdit(data) {
  return _request.default.put("system/page/update", data);
}

// 装修 - 所有页面设置
function allConfigListApi() {
  return _request.default.get("system/page/config/allList");
}

// 装修 - 页面设置 - 设置
function allConfigUpdateApi(data) {
  return _request.default.post("system/page/config/update", data);
}

/**
 * @description 素材库 -- 所有分类
 */
function formatLstApi() {
  return _request.default.get("system/file/category/treeList");
}

/**
 * @description 配置分类 -- 添加分类
 */
function createClassifyTableApi(data) {
  return _request.default.post("system/file/category", data);
}

/**
 * @description 配置分类 -- 修改分类
 */
function editClassifyTableApi(data) {
  return _request.default.put("system/file/category", data);
}

/**
 * @description 配置分类 -- 删除分类
 */
function delClassifyTableApi(id) {
  return _request.default.delete("system/file/category/".concat(id));
}

/**
 * @description 图片列表
 */
function attachmentListApi(data) {
  return _request.default.get("system/file/list", data);
}

/**
 * @description 银行列表
 */
function bankListApi() {
  return _request.default.get("system/bank/list");
}

// 获取阿里云上传令牌
function getUploadOssStsToken(data) {
  return _request.default.get("open3d/resource/oss", data);
}

/**
 * @description 配置分类 -- 编辑表单
 * @param {Object} param params {Object} 传值参数
 */
function updateConfigClassifyTable(id) {
  return _request.default.get("config/classify/update/table/" + id);
}
/**
 * @description 配置分类 -- 添加表单
 */
function createConfigClassifyTable() {
  return _request.default.get("config/classify/create/table");
}
/**
 * @description 配置分类 -- 列表
 */
function configClassifyLst(page, limit) {
  return _request.default.get("config/classify/lst", {
    page: page,
    limit: limit
  });
}
/**
 * @description 配置分类 -- 修改状态
 */
function changeConfigClassifyStatus(id, status) {
  return _request.default.post("config/classify/status/" + id, {
    status: status
  });
}

/**
 * @description 配置分类 -- 删除
 */
function classifyDelApi(id) {
  return _request.default.delete("config/classify/delete/".concat(id));
}

/**
 * @description 配置 -- 删除
 */
function settingDelApi(id) {
  return _request.default.delete("config/setting/delete/".concat(id));
}
function updateConfigSettingTable(id) {
  return _request.default.get("config/setting/update/table/" + id);
}
function createConfigSettingTable() {
  return _request.default.get("config/setting/create/table");
}
function configSettingLst(page, limit) {
  return _request.default.get("config/setting/lst", {
    page: page,
    limit: limit
  });
}
function changeConfigSettingStatus(id, status) {
  return _request.default.post("config/setting/status/" + id, {
    status: status
  });
}
function groupLst(page, limit) {
  return _request.default.get("group/lst", {
    page: page,
    limit: limit
  });
}
function createGroupTable() {
  return _request.default.get("group/create/table");
}
function updateGroupTable(id) {
  return _request.default.get("group/update/table/" + id);
}
/**
 * @description 数据列表 -- 表格表头
 * @param {Object} param params {Object} 传值参数
 */
function groupDetail(id) {
  return _request.default.get("group/detail/" + id);
}
/**
 * @description 数据列表 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function groupDataLst(groupId, page, limit) {
  return _request.default.get("group/data/lst/" + groupId, {
    page: page,
    limit: limit
  });
}
/**
 * @description 数据列表 -- 新增表单
 * @param {Object} param params {Object} 传值参数
 */
function createGroupData(groupId) {
  return _request.default.get("group/data/create/table/" + groupId);
}
/**
 * @description 数据列表 -- 编辑表单
 * @param {Object} param params {Object} 传值参数
 */
function updateGroupData(groupId, id) {
  return _request.default.get("group/data/update/table/".concat(groupId, "/").concat(id));
}
/**
 * @description 数据列表 -- 删除
 * @param {Object} param params {Object} 传值参数
 */
function deleteGroupData(id) {
  return _request.default.delete("group/data/delete/" + id);
}
/**
 * @description 菜单管理 -- 列表
 */
function menuListApi(data) {
  return _request.default.get("system/menu/lst", data);
}
/**
 * @description 菜单管理 -- 新增表单
 */
function menuCreateApi() {
  return _request.default.get("system/menu/create/form");
}
/**
 * @description 菜单管理 -- 编辑表单
 */
function menuUpdateApi(id) {
  return _request.default.get("system/menu/update/form/".concat(id));
}
/**
 * @description 菜单管理 -- 删除
 */
function menuDeleteApi(id) {
  return _request.default.delete("system/menu/delete/".concat(id));
}
/**
 * @description 附件分类 -- 添加分类
 */
function attachmentCreateApi() {
  return _request.default.get("system/attachment/category/create/form");
}
/**
 * @description 附件分类 -- 编辑分类
 */
function attachmentUpdateApi(id) {
  return _request.default.get("system/attachment/category/update/form/".concat(id));
}
/**
 * @description 附件分类 -- 编辑名称
 */
function picNameUpdateApi(id, data) {
  return _request.default.get("system/attachment/update/".concat(id, "/form"), data);
}
/**
 * @description 附件分类 -- 编辑名称升级
 */
function picNameEditApi(data) {
  return _request.default.put("/system/file", data);
}
/**
 * @description 附件分类 -- 删除分类
 */
function attachmentDeleteApi(id) {
  return _request.default.delete("system/attachment/category/delete/".concat(id));
}
/**
 * @description 图片列表 -- 删除
 */
function picDeleteApi(data) {
  return _request.default.delete("/system/file", data);
}
/**
 * @description 图片列表 -- 修改附件分类
 */
function categoryApi(data) {
  return _request.default.put("/system/file/batchUpdate", data);
}
/**
 * @description 客服管理 -- 创建表单
 */
function serviceCreateApi() {
  return _request.default.get("service/create/form");
}
/**
 * @description 客服管理 -- 编辑表单
 */
function serviceUpdateApi(id) {
  return _request.default.get("service/update/form/".concat(id));
}
/**
 * @description 客服管理 -- 列表
 */
function serviceListApi(data) {
  return _request.default.get("service/list", data);
}
/**
 * @description 客服管理 -- 修改状态
 */
function serviceStatusApi(id, status) {
  return _request.default.post("service/status/".concat(id), {
    status: status
  });
}
/**
 * @description 客服管理 -- 删除
 */
function serviceDeleteApi(id) {
  return _request.default.delete("service/delete/".concat(id));
}
/**
 * @description 客服管理 -- 用户列表
 */
function userLstApi(data) {
  return _request.default.get("user/list", data);
}
/**
 * @description 客服管理 -- 用户列表
 */
function userListApi(data) {
  return _request.default.get("user/list", data);
}
/**
 * @description 客服管理 -- 用户列表
 */
function serviceChatListApi(id, data) {
  return _request.default.get("service/".concat(id, "/user"), data);
}
/**
 * @description 客服管理 -- 客服与用户的聊天记录
 */
function serviceChatUidListApi(id, uid, data) {
  return _request.default.get("service/".concat(id, "/").concat(uid, "/lst"), data);
}
/**
 * @description 站内消息 -- 列表
 */
function stationNewsList(data) {
  return _request.default.get("notice/lst", data);
}
/**
 * @description 站内消息列表 -- 设为已读
 */
function stationNoticeRead(id) {
  return _request.default.post("notice/read/".concat(id));
}
/**
 * @description 消息 -- 未读数量
 */
function unreadCountApi() {
  return _request.default.get("notice/unread_count");
}

// 获取H5域名
function getDomain() {
  return _request.default.get("system/h5/getDomain");
}

// 创建小程序外部链接
function createScheme(data) {
  return _request.default.post("wechat/mini/createScheme", data);
}

// 拍品设置 - 竞价阶梯列表
function bidRangeList() {
  return _request.default.get("auction/bidRange/list");
}

// 拍品设置 - 创建竞价阶梯
function bidRangeCreate(id, data) {
  return _request.default.post("auction/bidRange/create", data);
}

// 拍品设置 - 修改竞价阶梯
function bidRangeEdit(id, data) {
  return _request.default.post("auction/bidRange/update/".concat(id), data);
}

// 拍品设置 - 删除竞价阶梯
function bidRangeDel(id) {
  return _request.default.post("auction/bidRange/del/".concat(id));
}

// 自定义字段配置 - 查询
function getConfigForm(key) {
  return _request.default.get("config/form/".concat(key));
}

// 自定义字段配置 - 查询
function getConfigClassKeys(key) {
  return _request.default.get("config/getConfigClassKeys/".concat(key));
}

// 自定义字段配置 - 创建
function configClassKeysSave(key, data) {
  return _request.default.post("config/save/".concat(key), data);
}

// 自定义顶部字段配置
function getCustomHeadder(data) {
  return _request.default.get("field/configIndex", data);
}
// 自定义顶部保存字段
function setCustomHeader(data) {
  return _request.default.post("field/config", data);
}
// 获取表格自定一头部
function gettableheader(data) {
  return _request.default.get("field/getField", data);
}
// 修改头部宽度
function editheaderwidth(data) {
  return _request.default.post("field/columnWidth", data);
}

// 创建二维码
function createQRCodeApi(data) {
  return _request.default.post("system/qrcode/crate", data);
}

// 修改头部宽度
function createQRCodeMiniApi(data) {
  return _request.default.post("wechat/mini/createQRcode", data);
}
// 导出excel
function exportexcel(data) {
  return _request.default.post("excel/export", data);
}
// 导入excel
function importexcel(data) {
  return _request.default.post("excel/import", data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
// 下载excel
function downloadexcel(data) {
  return _request.default.get("excel/templateDownload", data);
}

// 下载excel
function getAreaApi() {
  return _request.default.get("system/city/lst");
}

// 快递公司列表
function expressListApi() {
  return _request.default.get("store/order/expressList");
}

// 获取视频号商品列表
function getVideoGoodsListApi(data) {
  return _request.default.get("wechat/videoShopProduct/lst", data);
}

// 获取视频号商品详情
function getVideoGoodsDetailApi(id) {
  return _request.default.get("wechat/videoShopProduct/detail/".concat(id));
}
// 创建视频号商品
function createVideoGoodsApi(data) {
  return _request.default.post("wechat/videoShopProduct/create", data);
}
// 编辑视频号商品
function editVideoGoodsApi(id, data) {
  return _request.default.post("wechat/videoShopProduct/update/".concat(id), data);
}
// 修改视频号商品跳转链接
function editVideoGoodsUrlApi(id, data) {
  return _request.default.post("wechat/videoShopProduct/updatePath/".concat(id), data);
}
// 修改视频号商品状态
function editVideoGoodsStatusApi(id, data) {
  return _request.default.post("wechat/videoShopProduct/switchStatus/".concat(id), data);
}

// 修改视频号商品价格
function editVideoGoodsPriceApi(id, data) {
  return _request.default.post("wechat/videoShopProduct/updatePrice/".concat(id), data);
}

// 获取视频号分类
function getVideoClassApi() {
  return _request.default.get("wechat/videoShopProduct/category");
}

// 获取三级城市
function getCityApi(data) {
  return _request.default.get("system/city/getlist", data);
}

/**
 * 获取分享海报
 */
function sharePosterImgApi(data) {
  return _request.default.post("system/qrcode/crate", data);
}

/**
 * 获取短信验证码 resource/sms/code
 */
function getCodes(data) {
  return _request.default.get("resource/sms/code", data);
}

/**
 * 获取短信验证码 resource/sms/code
 */
function getCodesNew(data) {
  return _request.default.get("/resource/sms/sys/code", data);
}

/**
 * 设置操作密码 /system/sys/operatepwd/editPwd
 */
function editPwd(data) {
  return _request.default.post("system/sys/operatepwd/editPwd", data);
}

/**
 * 意见反馈 列表
 */
function feedbackListApi(data) {
  return _request.default.get("/system/feedback/list", data);
}

/**
 * 设置操作密码 /system/sys/operatepwd/editPwd
 */
function feedbackDelApi(data) {
  return _request.default.delete("/open3d/system/feedback/del", data);
}

/**
 * @description 敏感词
 */
function sensitivewordListApi(data) {
  return _request.default.get("/system/sys/sensitiveword/list", data);
}

/**
 * @description 敏感词 - 删除
 */
function sensitivewordDelApi(id) {
  return _request.default.delete("/system/sys/sensitiveword/delete/".concat(id));
}

/**
 * @description 敏感词 - 新增
 */
function sensitivewordAddApi(data) {
  return _request.default.post("/system/sys/sensitiveword/save", data);
}

/**
 * @description 获取新用户设置
 */
function memberInfoApi(data) {
  return _request.default.get("/member/sys/new/member/config/info");
}
/**
 * @description 修改新用户设置
 */
function setMemberInfoApi(data) {
  return _request.default.post("/member/sys/new/member/config/update", data);
}

/**
 * @description 获取信息
 */
function getApi(data) {
  return _request.default.get("https://cloud-api.yunzongbu.cn/platform/getApiDomainInfo", data);
}