var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "用户NFT详情", is_tab: false } },
    [
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.facade,
              "label-width": "80px",
              size: "mini",
              "label-position": "left",
            },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "item-align-center mt20" }, [
              _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                _vm._v("背景图片"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "upload-img-view item-flex-center",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap(1, "m_bg_image")
                    },
                  },
                },
                [
                  _vm.facade.m_bg_image
                    ? _c(
                        "div",
                        {
                          staticClass: "del-upload-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg("m_bg_image")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.facade.m_bg_image
                    ? _c("img", {
                        attrs: { src: _vm.facade.m_bg_image + "!120a" },
                      })
                    : _c("img", {
                        staticClass: "upload-icon",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                          alt: "",
                        },
                      }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "背景颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#f7f7f7" },
                  model: {
                    value: _vm.facade.m_bg,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "m_bg", $$v)
                    },
                    expression: "facade.m_bg",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "左右边距" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 375 },
                  model: {
                    value: _vm.facade.page_margin,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "page_margin", $$v)
                    },
                    expression: "facade.page_margin",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "高度" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 375 },
                  model: {
                    value: _vm.facade.m_height,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "m_height", $$v)
                    },
                    expression: "facade.m_height",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "圆角" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 200 },
                  model: {
                    value: _vm.facade.m_border_radio,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "m_border_radio", $$v)
                    },
                    expression: "facade.m_border_radio",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "内容间距" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value: _vm.facade.item_padding,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "item_padding", $$v)
                    },
                    expression: "facade.item_padding",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "积分颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.facade.m_integral_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "m_integral_color", $$v)
                    },
                    expression: "facade.m_integral_color",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "积分字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 40 },
                  model: {
                    value: _vm.facade.m_fontSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "m_fontSize", $$v)
                    },
                    expression: "facade.m_fontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "等级颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.facade.m_level_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "m_level_color", $$v)
                    },
                    expression: "facade.m_level_color",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "等级字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 40 },
                  model: {
                    value: _vm.facade.m_level_fontSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "m_level_fontSize", $$v)
                    },
                    expression: "facade.m_level_fontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字风格" } },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "le-radio-group",
                    model: {
                      value: _vm.facade.m_level_font_w,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "m_level_font_w", $$v)
                      },
                      expression: "facade.m_level_font_w",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("常规")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "item-align-center mt20" }, [
              _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                _vm._v("右侧图标"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "upload-img-view item-flex-center",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap(1, "m_right_icon")
                    },
                  },
                },
                [
                  _vm.facade.m_right_icon
                    ? _c(
                        "div",
                        {
                          staticClass: "del-upload-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg("m_right_icon")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.facade.m_right_icon
                    ? _c("img", {
                        attrs: { src: _vm.facade.m_right_icon + "!120a" },
                      })
                    : _c("img", {
                        staticClass: "upload-icon",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                          alt: "",
                        },
                      }),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }