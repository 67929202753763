"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _open3d = require("@/api/open3d");
var _product = require("@/api/product");
var _worksDetail = _interopRequireDefault(require("./module/worksDetail"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    WorksDetail: _worksDetail.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      listLoading: true,
      types: 'album',
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        levelOneId: '',
        levelTwoId: '',
        type: 1,
        fileType: ''
      },
      categoryIndex: 0,
      categoryList: [],
      showDetail: false,
      fileVisible: false,
      fileTypes: null,
      fileShow: null,
      uid: '',
      showdetail: false,
      showWorksId: '',
      attrList: [],
      changeAttr: [],
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _product.storeCategoryListApi)().then(function (res) {
      _this.categoryList = res.data;
      _this.categoryIndex = 0;
      _this.tableFrom.levelOneId = res.data[_this.categoryIndex].id;
      _this.getAttr(res.data[_this.categoryIndex].children.map(function (m) {
        return m.id;
      }).join(','));
      _this.getList();
    });
  },
  methods: {
    changePageAction: function changePageAction(tab) {
      if (tab.name === "works") {
        this.$router.push("./empowerList");
        return false;
      }
    },
    changeOneCategoryAction: function changeOneCategoryAction(val) {
      this.tableFrom.levelTwoId = '';
      this.categoryIndex = val;
      this.changeAttr = [];
      this.tableFrom.searchData = [];
      this.tableFrom.levelOneId = this.categoryList[this.categoryIndex].id;
      this.getAttr(this.categoryList[this.categoryIndex].children.map(function (m) {
        return m.id;
      }).join(','));
      this.getList(1);
    },
    changeTwoCategoryAction: function changeTwoCategoryAction(val) {
      this.tableFrom.levelTwoId = val;
      this.tableFrom.searchData = [];
      this.changeAttr = [];
      this.getAttr(val);
      this.getList();
    },
    getAttr: function getAttr(attr) {
      var _this2 = this;
      (0, _product.categoryGetAttrApi)({
        categoryId: attr
      }).then(function (res) {
        _this2.attrList = res.data;
      });
    },
    clearAttrAction: function clearAttrAction(i) {
      var cAttr = JSON.parse(JSON.stringify(this.changeAttr));
      cAttr[i] = null;
      var sData = [];
      cAttr.forEach(function (m) {
        if (m) {
          sData.push(m);
        }
      });
      if (sData.length > 0) {
        this.tableFrom.searchData = sData;
      } else {
        this.tableFrom.searchData = [];
      }
      this.getList(1);
      this.changeAttr = cAttr;
    },
    changeAttrAction: function changeAttrAction(m, i) {
      var cAttr = JSON.parse(JSON.stringify(this.changeAttr));
      if (cAttr[i] && cAttr[i].attrValueIds) {
        var index = cAttr[i].attrValueIds.indexOf(m.id);
        if (index === -1) {
          cAttr[i].attrValueIds.push(m.id);
        } else {
          cAttr[i].attrValueIds.splice(index, 1);
          if (cAttr[i].attrValueIds.length <= 0) {
            cAttr[i] = null;
          }
        }
      } else {
        cAttr[i] = {
          attrId: m.attrId,
          attrValueIds: [m.id]
        };
      }
      var sData = [];
      cAttr.forEach(function (m) {
        if (m) {
          sData.push(m);
        }
      });
      if (sData.length > 0) {
        this.tableFrom.searchData = sData;
      } else {
        this.tableFrom.searchData = [];
      }
      this.getList(1);
      this.changeAttr = cAttr;
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _open3d.transferenceList)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList('');
    },
    showVideoAction: function showVideoAction(m) {
      var _this4 = this;
      // console.log();
      if (m.fileType == 2) {
        this.fileShow = "https://cdn.yunzongbu.cn/3d/3d.html?gltf=".concat(m.file.replace(/https:\/\/java-test-cdn.yunzongbu.cn/g, "https://cloud-java-test.oss-cn-shanghai.aliyuncs.com"));
      } else {
        this.fileShow = m.file;
      }
      this.fileTypes = m.fileType;
      this.fileVisible = true;
      if (this.fileTypes === 3) {
        this.$nextTick(function () {
          _this4.loadVideo();
        });
      }
    },
    // 详情
    onDetails: function onDetails(id) {
      if (!id) return false;
      this.uid = id;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    loadVideo: function loadVideo() {
      var w = 550;
      this.player = TCPlayer('id_test_video', {
        width: w,
        height: w / 16 * 9,
        autoplay: true
      });
      this.player.src(this.fileShow);
    },
    closeBeforeAction: function closeBeforeAction(done) {
      var _this5 = this;
      if (this.fileTypes == 3) {
        if (this.player) {
          this.player.dispose();
          this.player = null;
        }
      }
      setTimeout(function () {
        _this5.fileTypes = 0;
      }, 500);
      done();
    },
    showDetailAction: function showDetailAction(m) {
      this.showWorksId = m.nftId;
      this.showDetail = true;
    },
    goEditAction: function goEditAction(id) {
      this.$router.push('/merchant/digitalAssets/worksEdit?id=' + id);
    },
    goIssueAction: function goIssueAction(id) {
      this.$router.push('/merchant/digitalAssets/worksIssue?id=' + id);
    },
    delAction: function delAction(m) {
      this.$confirm('确认删除作品？', '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      }).then(function () {
        // nftCollectionDelApi(id).then(() => {
        //   this.$message.success('操作成功');
        //   this.getList();
        // });
      }).catch(function (_ref) {
        var message = _ref.message;
      });
    },
    editSortAction: function editSortAction(val, index) {
      var _this6 = this;
      // 修改排序
      var item = this.tableData.data[index];
      var params = {
        nftId: item.nftId,
        sort: val
      };
      (0, _open3d.updateSortApi)(params).then(function () {
        _this6.$message.success('操作成功');
        _this6.getList();
      }).catch(function () {});
    },
    // 选择
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.tableFrom.pageNum);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.tableFrom.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this7 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this7.checkedIds.indexOf(id);
          if (index === -1) {
            _this7.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this7.checkedIds.indexOf(id);
          if (index > -1) {
            _this7.checkedIds.splice(index, 1);
          }
        });
      }
    },
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    batchUp: function batchUp(type) {
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.nftCollectionListCheckApiFun(type, this.checkedIds);
    },
    nftCollectionListCheckApiFun: function nftCollectionListCheckApiFun(t, ids) {
      var _this8 = this;
      (0, _open3d.updateopenidDProductApi)({
        productIds: ids,
        type: t
      }).then(function (res) {
        _this8.allCheck = false;
        _this8.checkedIds = [];
        _this8.$message.success('操作成功');
        _this8.getList();
      }).catch(function () {});
    }
  }
};