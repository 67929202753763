"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _writeOff = require("@/api/writeOff");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Record",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      status: "all",
      listLoading: false,
      search: {
        ticketName: "",
        memberNameOrPhone: ""
      },
      tableData: {
        data: [],
        total: 0
      },
      columnData: [{
        label: "门票名称",
        prop: "ticketName",
        width: "100"
      }, {
        label: "用户名",
        prop: "userName"
      }, {
        label: "用户手机",
        prop: "phone"
      }, {
        label: "卡券ID",
        prop: "memberTicketId",
        width: "200"
      }, {
        label: "购买时间",
        prop: "buyTime",
        width: "200"
      }, {
        label: "有效截止时间",
        prop: "endTime",
        width: "200"
      }, {
        label: "核销时间",
        prop: "useTime",
        width: "250"
      }, {
        label: "核销员",
        prop: "checkerName",
        width: "200"
      }, {
        label: "状态",
        prop: "status"
      }, {
        label: "核销次数",
        prop: "surplusNum"
      }, {
        label: "操作",
        prop: "handle",
        position: "right"
      }],
      queryParams: {
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  computed: {
    expire: function expire() {
      return function (item) {
        return new Date().getTime() - new Date(item.endTime) > 0 && item.status === 10;
      };
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var pageNum = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var data = {
        pageNum: pageNum,
        pageSize: this.queryParams.pageSize
      };
      if (this.status !== "all") {
        data.status = this.status;
      }
      if (this.search.ticketName) {
        data.ticketName = this.search.ticketName;
      }
      if (this.search.memberNameOrPhone) {
        data.memberNameOrPhone = this.search.memberNameOrPhone;
      }
      this.listLoading = true;
      console.log(this.queryParams);
      (0, _writeOff.getRecordList)(data).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (err) {
        _this.listLoading = false;
      });
    },
    assetsExport: function assetsExport() {
      var _this2 = this;
      var data = {};
      if (this.status !== "all") {
        data.status = this.status;
      }
      if (this.search.ticketName) {
        data.ticketName = this.search.ticketName;
      }
      if (this.search.memberNameOrPhone) {
        data.memberNameOrPhone = this.search.memberNameOrPhone;
      }
      (0, _writeOff.exportData)(data).then(function (res) {
        _this2.$message.success("导出成功");
      });
      // if (this.value1) {
      //   data.timeType = `${this.value1[0]}-${this.value1[1]}`;
      // } else {
      //   data.timeType = this.timeType;
      // }
      // allMemberAssetsExport(data)
      //   .then(res => {
      //     console.log(res);
      //     this.$message.success("导出成功");
      //   })
      //   .catch(err => {
      //     this.$message.error("导出失败");
      //   });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(45);
    },
    checkTicket: function checkTicket(id) {
      var _this3 = this;
      (0, _writeOff.checkTicket)(id).then(function (res) {
        _this3.$message.success("核销成功");
        _this3.getList(1);
      }).catch(function (err) {});
    }
  }
};