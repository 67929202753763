"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkorder = checkorder;
exports.createTransferOrder = createTransferOrder;
exports.createoutOrder = createoutOrder;
exports.createpushOrder = createpushOrder;
exports.delcheckorder = delcheckorder;
exports.delwarehouse = delwarehouse;
exports.editrecordstatus = editrecordstatus;
exports.failwarehouse = failwarehouse;
exports.finishcheckorder = finishcheckorder;
exports.getOrderNo = getOrderNo;
exports.getbarcode = getbarcode;
exports.getcheckorderlist = getcheckorderlist;
exports.getcheckrecordlist = getcheckrecordlist;
exports.getinventorygoods = getinventorygoods;
exports.getnogoods = getnogoods;
exports.getwarehouseDetail = getwarehouseDetail;
exports.giveshelf = giveshelf;
exports.intowarehouse = intowarehouse;
exports.outwarehouse = outwarehouse;
exports.startcheck = startcheck;
exports.upgoodscert = upgoodscert;
exports.uptaskcert = uptaskcert;
exports.warehouselist = warehouselist;
var _request = _interopRequireDefault(require("./request"));
/**
 * 库存单列表
 */
function warehouselist(data) {
  return _request.default.get('erp/warehouse/order/lst', data);
}
/**
 * 入位
 */
function giveshelf(id, data) {
  return _request.default.post("erp/warehouse/order/product/putIn/".concat(id), data);
}
/**
 * 仓库订单详情
 */
function getwarehouseDetail(id) {
  return _request.default.get("erp/warehouse/order/detail/".concat(id));
}
/**
 * 入库
 */
function intowarehouse(id, data) {
  return _request.default.post("erp/warehouse/order/putIn/".concat(id), data);
}
/**
 * 出库
 */
function outwarehouse(id, data) {
  return _request.default.post("erp/warehouse/order/takeOut/".concat(id), data);
}
/**
 * 失效invalid
 */
function failwarehouse(id, data) {
  return _request.default.post("erp/warehouse/order/invalid/".concat(id), data);
}
/**
 * 删除invalid
 */
function delwarehouse(id) {
  return _request.default.post("erp/warehouse/order/delete/".concat(id));
}
/**
 * 创建调拨单
 */
function createTransferOrder(data) {
  return _request.default.post("erp/warehouse/order/createTransfers", data);
}
/**
 * 获取货品标签码
 */
function getbarcode(data) {
  return _request.default.get("erp/product/barcode", data);
}
/**
 * 盘点任务列表
 */
function getcheckorderlist(data) {
  return _request.default.get("erp/stockTake/task/lst", data);
}
/**
 * 创建盘点任务
 */
function checkorder(data) {
  return _request.default.post("erp/stockTake/task/create", data);
}
/**
 * 完成盘点任务
 */
function finishcheckorder(id) {
  return _request.default.post("erp/stockTake/task/end/".concat(id));
}
/**
 * 删除盘点任务
 */
function delcheckorder(id) {
  return _request.default.post("erp/stockTake/task/delete/".concat(id));
}
/**
 * 盘点记录列表
 */
function getcheckrecordlist(data) {
  return _request.default.get("erp/stockTake/record/lst", data);
}
/**
 * 修改盘点状态
 */
function editrecordstatus(data) {
  return _request.default.post("erp/stockTake/record/switchStatus", data);
}
/**
 * 创建入库单
 */
function createpushOrder(data) {
  return _request.default.post("erp/warehouse/order/createPush", data);
}
/**
 * 创建出库单
 */
function createoutOrder(data) {
  return _request.default.post("erp/warehouse/order/createOut", data);
}
/**
 * 获取委托清单待入库商品
 */
function getinventorygoods(data) {
  return _request.default.get("erp/entrust/order/product/getWaitPushWarehouseLst", data);
}
/**
 * 获取入库编号
 */
function getOrderNo(data) {
  return _request.default.get('getOrderNo', data);
}
/**
 * 上传盘点凭证
 */
function upgoodscert(id, data) {
  return _request.default.post("erp/stockTake/record/uploadCredentials/".concat(id), data);
}
// 开始盘点
function startcheck(id) {
  return _request.default.post("erp/stockTake/task/start/".concat(id));
}
/**
 * 上传任务凭证
 */
function uptaskcert(id, data) {
  return _request.default.post("erp/stockTake/task/uploadCredentials/".concat(id), data);
}
// 获取编号商品
function getnogoods(data) {
  return _request.default.get("erp/product/storeNoByProduct", data);
}