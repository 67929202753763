"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
var _system = require("@/api/system");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      SettingMer: _settingMer.default,
      params: {
        drawer: false
      },
      type: '1',
      title: '',
      checked: false,
      listLoading: false,
      showImg: false,
      showImgUrl: '',
      tableData: {
        data: [],
        total: 0
      },
      tableData1: {
        data: [],
        total: 0
      },
      timeArr: [],
      form: {
        id: '',
        topName: '',
        startTime: '',
        endTime: '',
        isEveryDay: false
      },
      isRank: false,
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      lateralTableData: {
        // 消费排行侧滑列表
        data: [],
        total: 0
      },
      tableFrom1: {
        pageNum: 1,
        pageSize: 20
      },
      objRow: {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 侧滑显示数据
    changeTypeAction: function changeTypeAction(val) {
      console.log(val, 152);
      this.type = val.name;
      this.tableData1.data = [];
      this.tableData1.total = 0;
      this.lookLeaderboard();
    },
    lookLeaderboard: function lookLeaderboard() {
      var _this = this;
      var param = Object.assign({}, {
        type: this.type
      }, this.tableFrom1, {
        id: this.objRow.id
      });
      (0, _user.consumeGetTopList)(param).then(function (res) {
        _this.tableData1.data = res.rows;
        _this.tableData1.total = res.total;
      });
    },
    lookShow: function lookShow(row) {
      this.objRow = row;
      this.params.drawer = true;
      this.lookLeaderboard();
    },
    create: function create() {
      this.title = '创建';
      this.isRank = true;
      this.timeArr = [];
      this.form = {
        id: '',
        topName: '',
        startTime: '',
        endTime: '',
        isEveryDay: false
      };
    },
    getList: function getList() {
      var _this2 = this;
      (0, _user.getConsumeTopInfo)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
      });
    },
    linkAction: function linkAction(id) {
      this.copyAction("".concat(_settingMer.default.appUrl, "/users/consumer?id=").concat(id));
    },
    copyAction: function copyAction(val) {
      var input = document.createElement('input'); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    QRAction: function QRAction(id) {
      var _this3 = this;
      this.showImg = true;
      (0, _system.createQRCodeApi)({
        url: "/users/consumer?id=".concat(id),
        width: 400,
        height: 400
      }).then(function (res) {
        _this3.showImgUrl = res.data.image;
      });
    },
    // 侧滑分页
    handleSizeChange1: function handleSizeChange1(val) {
      this.tableFrom1.pageSize = val;
      this.lookLeaderboard();
    },
    pageChange1: function pageChange1(val) {
      this.tableFrom1.pageNum = val;
      this.lookLeaderboard();
    },
    // 页面分页
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    // 编辑
    onEdit: function onEdit(row) {
      var _this4 = this;
      this.isRank = true;
      this.title = '编辑';
      (0, _user.consumeGetInfoById)({
        id: row.id
      }).then(function (res) {
        _this4.timeArr = [res.data.startTime, res.data.endTime];
        _this4.form = res.data;
      });
    },
    submit: function submit() {
      var _this5 = this;
      if (!this.timeArr.length) {
        this.$message({
          type: 'warning',
          message: '请选择时间'
        });
        return;
      }
      if (!this.form.topName) {
        this.$message({
          type: 'warning',
          message: '请输入名称'
        });
        return;
      }
      if (this.form.id) {
        var arrApi = _user.consumeEdit;
      } else {
        var arrApi = _user.consumeCreate;
      }
      var _this$timeArr = (0, _slicedToArray2.default)(this.timeArr, 2);
      this.form.startTime = _this$timeArr[0];
      this.form.endTime = _this$timeArr[1];
      arrApi(this.form).then(function (res) {
        var msg = _this5.form.id ? '编辑' : '添加';
        _this5.$message({
          type: 'success',
          message: msg + '成功'
        });
        _this5.getList();
        _this5.isRank = false;
      });
    },
    // 删除
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      (0, _user.consumeDel)({
        id: row.id
      }).then(function (res) {
        _this6.$message({
          type: 'success',
          message: '已删除'
        });
        _this6.getList();
      });
    },
    open: function open() {
      this.params.drawer = true;
    },
    close: function close() {
      this.params.drawer = false;
    }
  }
};