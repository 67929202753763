"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var timingOrderRouter = {
  path: "".concat(_settings.roterPre, "/timing"),
  name: "Timing",
  meta: {
    icon: "",
    title: "定时下单"
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/timing/index"));
      });
    },
    name: "TimingOrder",
    meta: {
      title: "账户管理",
      noCache: true
    }
  }]
};
var _default = exports.default = timingOrderRouter;