"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SystemRole',
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      visiblePassword: false,
      passwordForm: {
        userId: '',
        password: '',
        passwordAgain: ''
      },
      passwordRules: {
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        passwordAgain: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    confirmPasswordAction: function confirmPasswordAction(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this.passwordForm.password != _this.passwordForm.passwordAgain) {
            _this.$message.error('密码和确认密码不一致');
            return false;
          }
          (0, _setting.adminPasswordApi)(_this.passwordForm).then(function () {
            _this.$message.success('操作成功');
            _this.visiblePassword = false;
            _this.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _setting.adminListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      (0, _setting.adminStatusApi)({
        userId: row.userId,
        status: row.status
      }).then(function (_ref) {
        var message = _ref.message;
        _this3.$message.success('操作成功');
        _this3.getList(1);
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push('/merchant/setting/systemRule/addSystemAdmin');
    },
    // 编辑
    onEdit: function onEdit(id) {
      this.$router.push({
        path: '/merchant/setting/systemRule/addSystemAdmin',
        query: {
          id: id
        }
      });
    },
    // 修改密码表单
    onPassword: function onPassword(id) {
      this.visiblePassword = true;
      this.passwordForm = {
        password: "",
        passwordAgain: ""
      };
      this.passwordForm.userId = id;
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u7BA1\u7406\u5458?", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _setting.adminDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this4.$message.success('操作成功');
          _this4.getList(1);
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      }).catch(function () {});
    }
  }
};