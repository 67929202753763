var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("待出库"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("已出库"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [_vm._v("已作废")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.warehouse_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "warehouse_id", $$v)
                                },
                                expression: "tableFrom.warehouse_id",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.storeList, function (item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.out_warehouse_id,
                                    attrs: {
                                      label: item.warehouse_id,
                                      value: item.warehouse_id,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "出库类型：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "type", $$v)
                                },
                                expression: "tableFrom.type",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("成交出库"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("销售出库"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v("调拨出库"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "3" } }, [
                                _vm._v("借出出库"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "出库单号：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.tableFrom.order_no,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "order_no", $$v)
                                },
                                expression: "tableFrom.order_no",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "关键词：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "委托人名称/手机号",
                                size: "small",
                              },
                              model: {
                                value: _vm.tableFrom.product_keyword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tableFrom,
                                    "product_keyword",
                                    $$v
                                  )
                                },
                                expression: "tableFrom.product_keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path:
                                "" + _vm.roterPre + "/warehouse/createoutorder",
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("创建出库单")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.exportOrder },
                        },
                        [_vm._v("导出")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: { click: _vm.getExportFileList },
                        },
                        [_vm._v("导出记录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: { "min-width": "700" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(
                          scope.row.orderProduct,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: "enp_" + index,
                                staticClass: "flex-box",
                                class: {
                                  "flex-box-border-bottom":
                                    index < scope.row.orderProduct.length - 1,
                                },
                              },
                              [
                                _c("div", { staticClass: "flex-box-image" }, [
                                  _c(
                                    "div",
                                    { staticClass: "demo-image__preview" },
                                    [
                                      _c("el-image", {
                                        attrs: {
                                          src: item.productItem.image + "!120a",
                                          "preview-src-list": [
                                            item.productItem.image,
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-box-name" }, [
                                  _vm._v(_vm._s(item.productItem.bar_code)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-box-name" }, [
                                  _vm._v(_vm._s(item.productItem.store_name)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-box-autor" }, [
                                  item.productItem.brand
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.productItem.brand.brand_name
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-box-autor" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-box-name" }, [
                                  scope.row.out_status == 0
                                    ? _c("span", [_vm._v("待出库")])
                                    : scope.row.out_status == 1
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row.typeText) +
                                            "出库\n                "
                                        ),
                                      ])
                                    : scope.row.out_status == -1
                                    ? _c("span", [_vm._v("已作废")])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-box-name" }, [
                                  _vm._v(_vm._s(item.shelf_id || "  ")),
                                ]),
                              ]
                            )
                          }
                        )
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", { staticClass: "flex-box-image" }, [
                        _vm._v("图片"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-box-name" }, [
                        _vm._v("编号"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-box-name" }, [
                        _vm._v("商品名称"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-box-autor" }, [
                        _vm._v("作者/品牌"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-box-autor" }, [
                        _vm._v("买家名称"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-box-name" }, [
                        _vm._v("出库状态"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-box-name" }, [
                        _vm._v("货架"),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "outAdmin.real_name",
                  label: "出库员",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "出库单号",
                  "min-width": "100",
                  prop: "bar_code",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "制单时间",
                  "min-width": "150",
                  prop: "create_time",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "制单人",
                  "min-width": "150",
                  prop: "creator.real_name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "仓库",
                  "min-width": "150",
                  prop: "outWarehouse.name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "80",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.out_status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getIntoshelfCard(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            出库凭证\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.out_status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.losewarehouseorder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("作废")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.out_status == -1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delwarehouseorder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.out_status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.outhouse(scope.row)
                                  },
                                },
                              },
                              [_vm._v("出库")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.getdetail(scope.row.order_id)
                              },
                            },
                          },
                          [_vm._v("打印出库单")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.urlshow
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": function () {
                _vm.urlshow = false
              },
              "url-list": _vm.urlshowList,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "打印标签",
            visible: _vm.showlabel,
            direction: "rtl",
            size: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showlabel = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("span", [_vm._v("\n              打印标签\n          ")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.bindPrint },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "printTable" } },
            _vm._l(_vm.labelarr, function (item, index) {
              return _c(
                "div",
                { key: "label" + index, staticClass: "printbox" },
                [
                  _c("div", { staticClass: "print-image" }, [
                    _c("img", {
                      attrs: {
                        src: "data:image/png;base64," + item.bar_code_image,
                        alt: "",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "print-code" }, [
                    _vm._v(_vm._s(item.bar_code)),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "出库", visible: _vm.showouthouse, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showouthouse = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "100px",
                model: _vm.house,
                rules: _vm.rules,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "凭证", prop: "image" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    attrs: { title: "750*750px" },
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("1")
                      },
                    },
                  },
                  [
                    _vm.house.image
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", { attrs: { src: _vm.house.image } }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.currentrow.type == "order" &&
              _vm.currentrow.transport_type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "物流公司" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择物流公司",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.house.delivery_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.house, "delivery_name", $$v)
                            },
                            expression: "house.delivery_name",
                          },
                        },
                        _vm._l(_vm.expressList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentrow.type == "order" &&
              _vm.currentrow.transport_type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "物流单号" } },
                    [
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: {
                          placeholder: "请输入物流单号",
                          size: "normal",
                          clearable: "",
                        },
                        model: {
                          value: _vm.house.delivery_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.house, "delivery_id", $$v)
                          },
                          expression: "house.delivery_id",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "w200",
                    attrs: {
                      placeholder: "请输入备注",
                      size: "normal",
                      clearable: "",
                      type: "textarea",
                      rows: 3,
                    },
                    model: {
                      value: _vm.house.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.house, "remark", $$v)
                      },
                      expression: "house.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.cursorouthouse } }, [
                    _vm._v("确认出库"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showdetail, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.showdetail = $event
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "printorder" } },
            [
              _c("div", { staticClass: "detail-box" }, [
                _c("div", { staticClass: "detail-left" }, [
                  _vm._v("货品出库单"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "detail-right" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "data:image/png;base64," +
                        _vm.detailjson.bar_code_image,
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-code" }, [
                    _vm._v(_vm._s(_vm.detailjson.bar_code)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-list" }, [
                _vm.detailjson.pushWarehouse
                  ? _c("div", [
                      _vm._v(
                        "仓库：" + _vm._s(_vm.detailjson.pushWarehouse.name)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm._v("出库时间：" + _vm._s(_vm.detailjson.create_time)),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("单据编号：" + _vm._s(_vm.detailjson.order_no)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-list" }, [
                _c("div", [
                  _vm._v("出库类型：" + _vm._s(_vm.detailjson.typeText)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("运输方式：")]),
                _vm._v(" "),
                _c("div", [_vm._v("送货人：")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ptd",
                  staticStyle: {
                    "margin-top": "20px",
                    "font-weight": "bold",
                    "border-bottom": "1px solid #eeeeee",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("图片"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "15%" } }, [
                    _vm._v("拍品名称"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("作者"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("分类"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "13%" } }, [
                    _vm._v("标签"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("货架"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "20%" } }, [
                    _vm._v("属性"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.detailjson.orderProduct, function (item, index) {
                return _c(
                  "div",
                  {
                    staticClass: "ptd",
                    staticStyle: {
                      "margin-top": "20px",
                      "border-bottom": "1px solid #eeeeee",
                      "padding-bottom": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "10%" } },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: item.productItem.image,
                            "preview-src-list": [item.productItem.image],
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "15%" } }, [
                      _vm._v(_vm._s(item.productItem.store_name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.productItem.brand
                        ? _c("span", [
                            _vm._v(_vm._s(item.productItem.brand.brand_name)),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.productItem.cate_parent
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.productItem.cate_parent[0].cate_name
                                ) +
                                "\n         "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "13%" } }, [
                      item.productItem &&
                      item.productItem.label &&
                      item.productItem.label.length
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.productItem.label.join(",")) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.shelf
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.shelf.shelf_no) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { width: "20%" } },
                      _vm._l(item.productItem.attrInfo, function (m, i) {
                        return _c("span", { key: "oattr_" + i }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(m.attr_name) +
                              ":" +
                              _vm._s(m.attr_value_name) +
                              "\n          "
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "20px" },
                },
                [
                  _vm.detailjson.creator
                    ? _c("div", [
                        _vm._v(
                          "制单人：" + _vm._s(_vm.detailjson.creator.real_name)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailjson.manageAdmin
                    ? _c("div", [
                        _vm._v(
                          "业务负责人：" +
                            _vm._s(_vm.detailjson.manageAdmin.real_name)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [_vm._v("出库员签字：")]),
                ]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.bindPrintorder },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showimage, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showimage = $event
            },
          },
        },
        _vm._l(_vm.imagearr, function (item, index) {
          return _c("img", {
            key: "image" + index,
            staticStyle: { width: "200px" },
            attrs: { src: item, alt: "" },
          })
        }),
        0
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }