"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMasterApi = addMasterApi;
exports.applyForGroupJoinApi = applyForGroupJoinApi;
exports.applyForGroupNumApi = applyForGroupNumApi;
exports.deleteMasterApi = deleteMasterApi;
exports.dissolutionApi = dissolutionApi;
exports.getAllGroupMasterApi = getAllGroupMasterApi;
exports.groupListApi = groupListApi;
exports.groupUserListApi = groupUserListApi;
exports.removeUserGroupApi = removeUserGroupApi;
exports.saveGroupApi = saveGroupApi;
exports.saveUserGroupApi = saveUserGroupApi;
exports.updateGroupApi = updateGroupApi;
exports.userListApi = userListApi;
var _request = _interopRequireDefault(require("./request"));
//群组列表
function groupListApi(data) {
  return _request.default.get("/member/sys/im/member/group/list", data);
}

//创建群组
function saveGroupApi(data) {
  return _request.default.post("/member/sys/im/member/group/save", data);
}

//编辑群组
function updateGroupApi(data) {
  return _request.default.put("/member/sys/im/member/group/update", data);
}
//解散群组
function dissolutionApi(id) {
  return _request.default.put("/member/sys/im/member/group/dissolution/".concat(id));
}
//获取群成员列表

function groupUserListApi(data) {
  return _request.default.get("/member/sys/im/member/group/groupUserList", data);
}

//获取成员列表
function userListApi(data) {
  return _request.default.get("/member/sys/im/member/group/userList", data);
}
//移出群聊
function removeUserGroupApi(data) {
  return _request.default.delete("/member/sys/im/member/group/removeUserGroup", data);
}
//添加用户群聊
function saveUserGroupApi(data) {
  return _request.default.post("/member/sys/im/member/group/saveUserGroup", data);
}

//获取审核列表
function applyForGroupNumApi(groupId, data) {
  return _request.default.get("/member/sys/im/member/group/applyForGroupNum/".concat(groupId), data);
}

//批量审核
function applyForGroupJoinApi(data) {
  return _request.default.post("/member/sys/im/member/group/applyForGroupJoin", data);
}
//添加管理员
function addMasterApi(data) {
  return _request.default.post("/member/sys/im/member/group/addMaster", data);
}
//获取群管理员列表
function getAllGroupMasterApi(groupId, data) {
  return _request.default.get("/member/sys/im/member/group/getAllGroupMaster/".concat(groupId), data);
}
//移除管理员
function deleteMasterApi(data) {
  return _request.default.delete("/member/sys/im/member/group/deleteMaster", data);
}