"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _sourceMap = require("source-map");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      tdgameflag: false,
      tabeldata: [],
      parseTime: _index.parseTime,
      lastime: "",
      editTableData: [],
      showEditFlag: false,
      formValidate: {
        agreement: "",
        //协议
        caption: "" //玩法说明
      }
    };
  },
  mounted: function mounted() {
    this.getConfigDetail();
  },
  methods: {
    changegameflag: function changegameflag(e) {
      //   setGameTowerSwitch({ status: this.tdgameflag }).then(() => {
      //     this.$message.success("修改成功");
      //   });
      this.setConfig();
    },
    editSwitch: function editSwitch() {
      this.showEditFlag = true;
    },
    getConfigDetail: function getConfigDetail() {
      var _this2 = this;
      (0, _marketing.getGameTowerConfig)().then(function (res) {
        console.log(res);
        _this2.tdgameflag = res.data.hasStart;
        _this2.tabeldata = res.data.towerDefenseTowers;
        _this2.editTableData = JSON.parse(JSON.stringify(res.data.towerDefenseTowers));
        _this2.formValidate.caption = res.data.caption || "";
        _this2.formValidate.agreement = res.data.agreement || "";
        _this2.lastime = res.data.last_time;
        _this2.id = res.data.id;
      });
    },
    setConfig: function setConfig() {
      var _this3 = this;
      var json = {};
      json.id = this.id;
      json.hasStart = Number(this.tdgameflag);
      json.towerDefenseTowers = this.editTableData;
      json.caption = this.formValidate.caption;
      json.agreement = this.formValidate.agreement;
      (0, _marketing.setGameTowerConfig)(json).then(function (res) {
        _this3.$message.success("保存成功");
        _this3.showEditFlag = false;
        _this3.tabeldata = JSON.parse(JSON.stringify(_this3.editTableData));
        // this.getConfigDetail();
      });
    },
    // 修改图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          console.log(img);
          _this.editTableData[num].attackPicture = img[0];
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = "";
    },
    changeprobility: function changeprobility() {
      var all = 100;
      this.editTableData.forEach(function (item, index) {
        if (index > 0) {
          all -= item.monsterOdds;
        }
      });
      this.editTableData[0].monsterOdds = all.toFixed(2);
    }
  }
};