"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
var _word = _interopRequireDefault(require("../word"));
var _nftCollection = _interopRequireDefault(require("../nftCollection"));
var _nftBlindBox = _interopRequireDefault(require("../nftBlindBox"));
var _blindBox = _interopRequireDefault(require("../blindBox"));
var _nftCollectionMarket = _interopRequireDefault(require("../nftCollectionMarket"));
var _nftCollectionAlbum = _interopRequireDefault(require("../nftCollectionAlbum"));
var _allPointProducts = _interopRequireDefault(require("../allPointProducts"));
var _Publisher = _interopRequireDefault(require("../Publisher"));
var _composeList = _interopRequireDefault(require("../composeList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Word: _word.default.element,
    NftCollection: _nftCollection.default.element,
    Nftblind: _nftBlindBox.default.element,
    BlindBox: _blindBox.default.element,
    NftCollectionMarket: _nftCollectionMarket.default.element,
    NftCollectionAlbum: _nftCollectionAlbum.default.element,
    AllPointProducts: _allPointProducts.default.element,
    Publisher: _Publisher.default.element,
    composeList: _composeList.default.element
  },
  props: {
    facade: {
      type: [Object, Array]
    },
    content: {
      type: [Object, Array]
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    showContent: function showContent() {
      if (this.content.data[this.content.active] && this.content.data[this.content.active].link) {
        return this.content.data[this.content.active].link;
      } else {
        return {};
      }
    }
  },
  methods: {
    activeColor: function activeColor(index) {
      if (this.content.active === index) {
        return this.facade.text_active_color;
      } else {
        return this.facade.text_color;
      }
    },
    activeFontSize: function activeFontSize(index) {
      if (this.content.active === index) {
        return this.facade.active_text_size;
      } else {
        return this.facade.text_size;
      }
    },
    activeFontWeight: function activeFontWeight(index) {
      if (this.content.active === index) {
        return this.facade.active_text_style;
      } else {
        return this.facade.text_style;
      }
    }
  }
};