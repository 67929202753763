"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _workNumberTmp = _interopRequireDefault(require("@/components/workNumberTmp.vue"));
var _ChangeUser = _interopRequireDefault(require("@/components/ChangeUser"));
var _userdetail = _interopRequireDefault(require("../list/userdetail"));
var _commisonOrder = _interopRequireDefault(require("./commisonOrder.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    WorkNumberTmp: _workNumberTmp.default,
    ChangeUser: _ChangeUser.default,
    userdetail: _userdetail.default,
    commisionOrder: _commisonOrder.default
  },
  data: function data() {
    var validateSharingRatio = function validateSharingRatio(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入自动发布分成比例'));
      } else if (isNaN(value)) {
        callback(new Error('分成比例必须为数字值'));
      } else if (value < 0) {
        callback(new Error('分成比例最小设置0%'));
      } else if (value > 100) {
        callback(new Error('分成比例最大设置100%'));
      } else {
        callback();
      }
    };
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      orderChartType: {},
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        name: '',
        phone: '',
        pageNum: 1,
        pageSize: 10
      },
      editIssuerData: {
        show: false,
        name: '',
        sharingRatio: '',
        idCard: '',
        phone: '',
        bankId: '',
        openBankName: '',
        bankNumber: '',
        memberId: '',
        memberName: '',
        memberPhone: '',
        detailed: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入作者名称',
          trigger: 'blur'
        }],
        idCard: [{
          required: true,
          message: '请输入作者身份证',
          trigger: 'blur'
        }, {
          pattern: /^[0-9a-zA-Z]{1,18}$/,
          message: '作者身份证输入不正确'
        }],
        phone: [{
          required: true,
          message: '请输入联系人电话',
          trigger: 'blur'
        }, {
          pattern: /^\d{1,11}$/,
          message: '作者手机号输入不正确'
        }],
        sharingRatio: [{
          validator: validateSharingRatio,
          trigger: 'blur'
        }]
      },
      bankList: [],
      userList: [],
      uid: '',
      showdetail: false
    };
  },
  created: function created() {
    var _this = this;
    (0, _system.bankListApi)().then(function (res) {
      _this.bankList = res.rows;
    }).catch(function () {});
    this.getList(1);
  },
  methods: {
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _user.authorListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList('');
    },
    delAction: function delAction(m) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u4F5C\u8005?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.authorDelApi)(m.id).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showWorkAction: function showWorkAction(m) {
      this.$refs.workNumberTmp.loadInit(m.id, 2);
    },
    showCommisionAction: function showCommisionAction(m) {
      this.$refs.commisionOrder.loadInit(m.id, 2);
    },
    showEditAction: function showEditAction(m) {
      this.editIssuerData = {
        show: true,
        name: m.name,
        idCard: m.idCard,
        phone: m.phone,
        bankId: m.bankId ? "".concat(m.bankId) : '',
        openBankName: m.openBankName,
        bankNumber: m.bankNumber,
        memberId: m.memberId,
        memberName: m.memberName,
        memberPhone: m.memberPhone,
        image: m.image,
        id: m.id,
        detailed: m.detailed,
        sharingRatio: "".concat(m.sharingRatio)
      };
    },
    showAddAction: function showAddAction() {
      this.editIssuerData = {
        show: true,
        name: '',
        idCard: '',
        phone: '',
        bankId: '',
        openBankName: '',
        bankNumber: '',
        memberId: '',
        memberName: '',
        memberPhone: '',
        detailed: '',
        sharingRatio: ''
      };
    },
    saveAction: function saveAction(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.editIssuerData));
          _this4.$confirm("\u786E\u8BA4".concat(_this4.editIssuerData.id ? '修改' : '添加', "\u4F5C\u8005?"), '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            var action = _user.authorCreateApi;
            if (_this4.editIssuerData.id) {
              action = _user.authorUpdateApi;
            }
            action(data).then(function () {
              _this4.$message.success('操作成功');
              _this4.getList();
              _this4.editIssuerData.show = false;
            }).catch(function (_ref) {
              var message = _ref.message;
            });
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    editUserAction: function editUserAction() {
      this.$refs['changeUser'].init(this.editIssuerData.memberId ? this.editIssuerData.memberId : '');
    },
    changeUidAction: function changeUidAction(data) {
      this.editIssuerData.memberId = data.userId;
      this.editIssuerData.memberName = data.nickName;
      this.editIssuerData.memberPhone = data.userPhone;
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.memberId;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    modalPicTap: function modalPicTap(tit, key, key1) {
      var _this5 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this5.editIssuerData.image = img[0];
        } else {
          _this5.editIssuerData.image = img[0];
        }
        _this5.$forceUpdate();
      }, tit);
    },
    delImg: function delImg(key, key1) {
      if (key1) {
        this.editIssuerData.image = '';
      } else {
        this.editIssuerData.image = '';
      }
      this.$forceUpdate();
    }
  }
};