"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _ipip = require("@/api/ipip");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: [],
      editflag: false,
      selectrow: {
        name: "",
        price: "",
        quarterPrice: "",
        yearPrice: "",
        description: ""
      },
      imgList: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      (0, _ipip.cardPriceApi)().then(function (res) {
        _this.list = res.rows;
      });
    },
    showEdit: function showEdit(row) {
      this.selectrow = JSON.parse(JSON.stringify(row));
      this.editflag = true;
      this.imgList = "";
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      if (!this.selectrow.price) {
        this.$message({
          message: "请输入月价格",
          type: "error"
        });
        return;
      }
      if (this.imgList.length > 0) {
        this.selectrow.image = this.imgList[0];
      }
      (0, _ipip.updateCardPriceApi)(this.selectrow).then(function () {
        _this2.editflag = false;
        _this2.getList();
      });
    },
    modalPicTap: function modalPicTap(tit) {
      var that = this;
      this.$modalUpload(function (img) {
        that.imgList = [].concat((0, _toConsumableArray2.default)(that.imgList), (0, _toConsumableArray2.default)(img));
      }, 0);
    },
    handleRemove: function handleRemove(index) {
      this.imgList.splice(index, 1);
    }
  }
};