"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _withdrawal = _interopRequireDefault(require("./components/withdrawal.vue"));
var _refund = _interopRequireDefault(require("./components/refund.vue"));
var _shareAccount = _interopRequireDefault(require("./components/shareAccount.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    withdrawal: _withdrawal.default,
    refund: _refund.default,
    shareAccount: _shareAccount.default
  },
  data: function data() {
    return {
      activeName: "first"
    };
  }
};