"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _marketing = require("@/api/marketing");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _settings = require("@/settings");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "planeList",
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      props: {
        value: "store_brand_category_id",
        label: "cate_name",
        children: "children",
        emitPath: false
      },
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        towerDefenseAttackId: this.$route.query.rounds_id,
        phone: '',
        hasFail: this.$route.query.status == 2 ? true : false
      },
      imgList: [],
      brandCategory: [],
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      showImg: false,
      showImgUrl: "",
      parseTime: _index.parseTime,
      resultflag: false,
      resultData: {
        data: [],
        total: 0
      },
      resultFrom: {
        page: 1,
        limit: 20
      },
      showdetail: false,
      uid: ""
    };
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import";
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 下载
    downLoad: function downLoad() {
      window.open(_settingMer.default.https + "/excel/templateDownload?type=import_store_brand&token=" + (0, _auth.getToken)());
    },
    // 上传成功
    handleSuccess: function handleSuccess(response) {
      if (response.status === 200) {
        this.$message.success("上传成功");
        this.getList();
      } else {
        this.$message.error(response.message);
      }
      this.$refs.uploadImg.clearFiles();
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _marketing.attackDataDetails)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageResultChange: function pageResultChange(page) {
      this.resultFrom.page = page;
      this.getHistoryList();
    },
    handleSizeResultChange: function handleSizeResultChange(val) {
      this.resultFrom.limit = val;
      this.getHistoryList();
    },
    showpreview: function showpreview(item) {
      this.resultflag = true;
      this.resultFrom.uid = item.uid;
      this.resultFrom.aircraft_user_id = item.aircraft_user_id;
      this.getHistoryList();
    },
    showuser: function showuser(item) {
      this.uid = item.memberId;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    getHistoryList: function getHistoryList() {
      var _this2 = this;
      (0, _marketing.userhasPlateHistory)(this.resultFrom).then(function (res) {
        _this2.resultData.data = res.data.list;
        _this2.resultData.total = res.data.count;
      });
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push({
        path: _settings.roterPre + "/marketing/planedetail"
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      this.$router.push({
        path: _settings.roterPre + "/marketing/planedetail",
        query: {
          id: id
        }
      });
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this3 = this;
      this.tableFrom.export_type = "towerDefensePutRoundsSettlementList";
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this3.$createElement;
        _this3.$msgbox({
          title: "提示",
          message: h("p", null, [h("span", null, '文件正在生成中，请稍后点击"'), h("span", {
            style: "color: teal"
          }, "导出记录"), h("span", null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("towerDefensePutRoundsSettlementList");
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};