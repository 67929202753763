"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _settings = require("@/settings");
var _system = require("@/api/system");
var _setting = require("@/api/setting");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _marketing = require("@/api/marketing");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "retail",
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
    // retaildetail
  },
  data: function data() {
    return {
      moren: require("@/assets/images/f.png"),
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      mask: "",
      changestatusflag: false,
      restatus: "",
      dtotal: {},
      sendflag: false,
      sendJson: {
        deliveryCompanyId: "",
        deliveryId: ""
      },
      dcList: [],
      showdetail: false
    };
  },
  mounted: function mounted() {
    this.getList();
    this.deliveryCompanyList();
  },
  methods: {
    showsend: function showsend(row) {
      this.row = row;
      this.sendflag = true;
      this.sendJson = {
        deliveryCompanyId: "",
        deliveryId: ""
      };
    },
    deliveryCompanyList: function deliveryCompanyList() {
      var _this = this;
      (0, _order.deliveryCompanyListApi)({
        pageNum: 1,
        pageSize: 20
      }).then(function (res) {
        // console.log(res)
        _this.dcList = res.rows;
      }).catch(function (res) {});
    },
    cursorSend: function cursorSend() {
      var _this2 = this;
      var json = JSON.parse(JSON.stringify(this.sendJson));
      json.id = this.row.id;
      json.type = 1;
      (0, _marketing.exchangeTdGoodsSend)(json).then(function () {
        _this2.$message.success("操作成功");
        _this2.sendflag = false;
        _this2.getList();
      });
    },
    getadminList: function getadminList() {
      var _this3 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this3.adminarr = res.data.list;
      });
    },
    retailstatus: function retailstatus(row) {
      this.restatus = "0";
      this.rerow = row;
      this.changestatusflag = true;
    },
    searchUserAction: function searchUserAction(data) {
      var _this4 = this;
      var params = {
        keyword: data,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this4.userList = res.data.list;
      });
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.memberId;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.join_data = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.join_data = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num ? num : this.tableFrom.pageNum;
      (0, _marketing.exchangeTdGoodsRecord)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.rows;
        _this5.tableData.total = res.total;
        _this5.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    retaildetailrefash: function retaildetailrefash() {
      console.log("刷新");
      this.getList();
    },
    // 导出
    exportRecord: function exportRecord() {
      var _this6 = this;
      this.tableFrom.export_type = "salesman";
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        /*this.$message.success(res.message);
          this.$refs.exportList.exportFileList();*/
        var h = _this6.$createElement;
        _this6.$msgbox({
          title: "提示",
          message: h("p", null, [h("span", null, '文件正在生成中，请稍后点击"'), h("span", {
            style: "color: teal"
          }, "导出已生成列表"), h("span", null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 导出列表
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList("salesman");
    }
  }
};