var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "神兽列表", name: "first" } },
            [_c("List")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "产出设置", name: "second" } },
            [_c("Produce", { ref: "Produce" })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "卡槽设置", name: "third" } },
            [_c("CardSlot")],
            1
          ),
          _vm._v(" "),
          !_vm.showLand
            ? _c(
                "el-tab-pane",
                { attrs: { label: "土地设置", name: "landList" } },
                [_c("LandList")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.showLand
            ? _c(
                "el-tab-pane",
                { attrs: { label: "守卫和肥料", name: "guard" } },
                [_c("Guard")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "游戏换肤", name: "GameImg" } },
            [_c("GameImg")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "数据统计", name: "dataStatistics" } },
            [_c("DataStatistics")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }