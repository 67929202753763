"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var AIGCRoutes = {
  path: "".concat(_settings.roterPre, "/AIGC"),
  name: "AIGC",
  meta: {
    icon: "",
    title: "AIGC"
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: "numberPer",
    name: "NumberPerson",
    meta: {
      title: "数字人",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/AIGC/numberPerson"));
      });
    },
    children: []
  }, {
    path: "worksSet",
    name: "WorksSetting",
    meta: {
      title: "作品库配置",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/AIGC/worksSetting"));
      });
    },
    children: []
  }, {
    path: "numberStyle",
    name: "NumberStyle",
    meta: {
      title: "艺术风格管理",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/AIGC/numberStyle"));
      });
    },
    children: []
  }, {
    path: "prompt",
    name: "prompt",
    meta: {
      title: "提示词编辑",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/AIGC/prompt"));
      });
    },
    children: []
  }, {
    path: "workStyle",
    name: "workStyle",
    meta: {
      title: "编辑作品风格",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/AIGC/workStyle"));
      });
    },
    children: []
  }, {
    path: "workStyleNew",
    name: "workStyleNew",
    meta: {
      title: "作品风格",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/AIGC/workStyleNew"));
      });
    }
  }]
};
var _default = exports.default = AIGCRoutes;