var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "公告", is_tab: false } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.content,
            "label-width": "90px",
            size: "mini",
            "label-position": "left",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "control-item-title" }, [
            _vm._v("文字设置"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.content.data, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "kuang1 item-align-center mt10" },
                [
                  index === 0
                    ? _c(
                        "div",
                        {
                          staticClass: "go-down-img",
                          on: {
                            click: function ($event) {
                              return _vm.goDown(index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  index > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "go-up-img",
                          on: {
                            click: function ($event) {
                              return _vm.goUp(index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "del-img",
                      on: {
                        click: function ($event) {
                          return _vm.handleDelete(index)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-view ml4" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { type: "text", placeholder: "设置文字" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                          },
                        },
                        model: {
                          value: item.title,
                          callback: function ($$v) {
                            _vm.$set(item, "title", $$v)
                          },
                          expression: "item.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  item.link.name1 || item.link.name
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "item-align-center flex-one justify-end ml10 cur-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.setCategory(index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "ft14" }, [
                            _vm._v(_vm._s(item.link.name1 || item.link.name)),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "arrow-img",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.setCategory(index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "ft14" }, [
                            _vm._v("设置跳转链接"),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "arrow-img",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("img", {
              staticStyle: {
                width: "12px",
                height: "12px",
                "margin-right": "5px",
                "margin-top": "2px",
              },
              attrs: {
                src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "fontW500 ft16",
                on: { click: _vm.handleAdditem },
              },
              [_vm._v("新增一条文字")]
            ),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "control-item-title" }, [
            _vm._v("样式设置"),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "页面边距" } },
            [
              _c("slider", {
                attrs: { min: 0, max: 100 },
                model: {
                  value: _vm.facade.page_margin,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "page_margin", $$v)
                  },
                  expression: "facade.page_margin",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "圆角" } },
            [
              _c("slider", {
                attrs: { min: 0, max: 100 },
                model: {
                  value: _vm.facade.radius,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "radius", $$v)
                  },
                  expression: "facade.radius",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "阴影" } },
            [
              _c("colour", {
                attrs: { colour: "#FFFFFF" },
                model: {
                  value: _vm.facade.item_shadow,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "item_shadow", $$v)
                  },
                  expression: "facade.item_shadow",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "阴影大小" } },
            [
              _c("slider", {
                attrs: { min: 0, max: 100 },
                model: {
                  value: _vm.facade.item_shadow_size,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "item_shadow_size", $$v)
                  },
                  expression: "facade.item_shadow_size",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "文字颜色" } },
            [
              _c("colour", {
                attrs: { colour: "#f58e20" },
                model: {
                  value: _vm.facade.title_color,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "title_color", $$v)
                  },
                  expression: "facade.title_color",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "背景颜色" } },
            [
              _c("colour", {
                attrs: { colour: "#fff5e1" },
                model: {
                  value: _vm.facade.background_color,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "background_color", $$v)
                  },
                  expression: "facade.background_color",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "左侧边距" } },
            [
              _c("slider", {
                attrs: { min: 0, max: 375 },
                model: {
                  value: _vm.facade.page_padding,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "page_padding", $$v)
                  },
                  expression: "facade.page_padding",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "内容间距" } },
            [
              _c("slider", {
                attrs: { min: 0, max: 375 },
                model: {
                  value: _vm.facade.item_padding,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "item_padding", $$v)
                  },
                  expression: "facade.item_padding",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "control-item-title" }, [
            _vm._v("滚动设置"),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "滚动方式" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "le-radio-group",
                  model: {
                    value: _vm.facade.roll_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "roll_type", $$v)
                    },
                    expression: "facade.roll_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("横向滚动")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("纵向滚动")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "control-item-title" }, [
            _vm._v("图标设置"),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "公告图标" } }, [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "upload-img-view item-flex-center",
                  staticStyle: { width: "90px", height: "90px" },
                  on: {
                    click: function ($event) {
                      return _vm.uploadIconAction("icon")
                    },
                  },
                },
                [
                  _vm.content.icon
                    ? _c(
                        "div",
                        {
                          staticClass: "del-upload-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg("icon")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.content.icon
                    ? _c("img", {
                        staticStyle: { width: "90px", height: "90px" },
                        attrs: { src: _vm.content.icon },
                      })
                    : _c("img", {
                        staticClass: "upload-icon",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                          alt: "",
                        },
                      }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "tips" }, [
              _c(
                "span",
                { staticClass: "resetBtn", on: { click: _vm.resetIcon } },
                [_vm._v("恢复默认")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "control-item-title" }, [
            _vm._v("右侧文字"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "kuang1 item-align-center" }, [
            _c(
              "div",
              {
                staticClass: "input-view",
                staticStyle: { "margin-left": "0" },
              },
              [
                _c("el-input", {
                  staticStyle: { width: "140px" },
                  attrs: { type: "text", placeholder: "设置文字" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                    },
                  },
                  model: {
                    value: _vm.content.right_text,
                    callback: function ($$v) {
                      _vm.$set(_vm.content, "right_text", $$v)
                    },
                    expression: "content.right_text",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.content.right_link &&
            (_vm.content.right_link.name1 || _vm.content.right_link.name)
              ? _c(
                  "div",
                  {
                    staticClass:
                      "item-align-center flex-one justify-end ml10 cur-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.setCategory("", "right_link")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "ft14" }, [
                      _vm._v(
                        _vm._s(
                          _vm.content.right_link.name1 ||
                            _vm.content.right_link.name
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "arrow-img",
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                        alt: "",
                      },
                    }),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.setCategory("", "right_link")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "ft14" }, [
                      _vm._v("设置跳转链接"),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "arrow-img",
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                        alt: "",
                      },
                    }),
                  ]
                ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt10", attrs: { label: "右侧图标" } },
            [
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    staticStyle: { width: "40px", height: "40px" },
                    on: {
                      click: function ($event) {
                        return _vm.uploadIconAction("right_icon")
                      },
                    },
                  },
                  [
                    _vm.content.right_icon
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delImg("right_icon")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.right_icon
                      ? _c("img", {
                          staticStyle: { width: "40px", height: "40px" },
                          attrs: { src: _vm.content.right_icon },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "tips ml10" }, [
                  _vm._v(" 建议尺寸：80px*80px"),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "文字颜色" } },
            [
              _c("colour", {
                attrs: { colour: "#f58e20" },
                model: {
                  value: _vm.facade.right_title_color,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "right_title_color", $$v)
                  },
                  expression: "facade.right_title_color",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "左侧边距" } },
            [
              _c("slider", {
                attrs: { min: 0, max: 375 },
                model: {
                  value: _vm.facade.right_padding,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "right_padding", $$v)
                  },
                  expression: "facade.right_padding",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }