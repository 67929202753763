var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        inline: "",
                        size: "small",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签名称：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入标签名称" },
                              model: {
                                value: _vm.tableFrom.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keyword", $$v)
                                },
                                expression: "tableFrom.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签类型：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "type", $$v)
                                },
                                expression: "tableFrom.type",
                              },
                            },
                            _vm._l(_vm.labelTypeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("设置自动标签")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "ID",
                  prop: "label_rule_id",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "标签名称",
                  prop: "label.label_name",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标签类型", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.type === 0 ? "订单数" : "订单金额")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标签规则", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.min === "0.00" && scope.row.max === "0.00"
                          ? _c("span", [_vm._v("从未支付的客户")])
                          : _c("span", [
                              scope.row.type === 1
                                ? _c("span", [
                                    _vm._v(
                                      "订单金额在" +
                                        _vm._s(scope.row.min) +
                                        " - " +
                                        _vm._s(scope.row.max) +
                                        "的客户"
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "支付订单数在" +
                                        _vm._s(Math.trunc(scope.row.min)) +
                                        " - " +
                                        _vm._s(Math.trunc(scope.row.max)) +
                                        "的客户"
                                    ),
                                  ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "客户数", prop: "user_num", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "更新时间", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.update_time
                                ? scope.row.update_time
                                : "未更新"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onUpdate(scope.row.label_rule_id)
                              },
                            },
                          },
                          [_vm._v("更新")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.label_rule_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: { model: _vm.formValidate, "label-width": "120px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.initCount },
                      model: {
                        value: _vm.formValidate.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "type", $$v)
                        },
                        expression: "formValidate.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("订单次数标签"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("订单金额标签"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签名称：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标签名称" },
                    model: {
                      value: _vm.formValidate.label_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "label_name", $$v)
                      },
                      expression: "formValidate.label_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formValidate.type === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "消费金额设置：" } },
                    [
                      _c("el-input", {
                        staticClass: "number_input",
                        attrs: { type: "number", min: 1 },
                        model: {
                          value: _vm.formValidate.min,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "min", $$v)
                          },
                          expression: "formValidate.min",
                        },
                      }),
                      _vm._v("元\n        "),
                      _c("span", [_vm._v("-")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "number_input",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "number", min: _vm.formValidate.min },
                        model: {
                          value: _vm.formValidate.max,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "max", $$v)
                          },
                          expression: "formValidate.max",
                        },
                      }),
                      _vm._v("元\n      "),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "消费次数设置：" } },
                    [
                      _c("el-input", {
                        staticClass: "number_input",
                        attrs: {
                          type: "number",
                          value: Math.trunc(_vm.formValidate.min),
                          min: 1,
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.number($event)
                          },
                        },
                        model: {
                          value: _vm.formValidate.min,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "min", _vm._n($$v))
                          },
                          expression: "formValidate.min",
                        },
                      }),
                      _vm._v("次\n        "),
                      _c("span", [_vm._v("-")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "number_input",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "number", min: _vm.formValidate.min },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.number($event)
                          },
                        },
                        model: {
                          value: _vm.formValidate.max,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "max", _vm._n($$v))
                          },
                          expression: "formValidate.max",
                        },
                      }),
                      _vm._v("次\n      "),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }