"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _demand = require("@/api/demand");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["demandInfoId"],
  data: function data() {
    return {
      demandInfo: {
        demandDesc: '',
        demandLogs: []
      }
    };
  },
  created: function created() {
    var _this = this;
    this.demandInfoId.id && (0, _demand._getDemandInfo)(this.demandInfoId.id).then(function (res) {
      _this.demandInfo = res.data;
    }).catch(function (err) {
      console.log(err);
    });
  },
  computed: {
    demandDescList: {
      get: function get() {
        return this.demandInfo.demandDesc.split("\n");
      }
    },
    files: {
      get: function get() {
        return JSON.parse(this.demandInfo.files || "[]");
      }
    }
  }
};