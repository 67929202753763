"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _animal = require("@/api/animal");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AnimalDetail",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      selectType: "",
      form: {
        productId: "",
        productName: "",
        productImage: "",
        backGroundImage: "",
        initEnergy: 1,
        maxEnergy: 1,
        consumeEnergy: 1,
        natureGrow: 1,
        waterNum: 1,
        consumeWater: 1,
        growNum: 1
      },
      outPutData: [],
      selectIndex: -1
    };
  },
  mounted: function mounted() {
    if (this.$route.query.id) {
      this.getInfo(this.$route.query.id);
    }
  },
  methods: {
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.form.backGroundImage = img[0];
      }, tit);
    },
    getInfo: function getInfo(id) {
      var _this2 = this;
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.getGroundInfoApi)(id).then(function (res) {
        console.log(res);
        var _res$data = res.data,
          productId = _res$data.productId,
          productImage = _res$data.productImage,
          productName = _res$data.productName,
          backGroundImage = _res$data.backGroundImage,
          consumeEnergy = _res$data.consumeEnergy,
          initEnergy = _res$data.initEnergy,
          maxEnergy = _res$data.maxEnergy,
          natureGrow = _res$data.natureGrow,
          waterNum = _res$data.waterNum,
          consumeWater = _res$data.consumeWater,
          growNum = _res$data.growNum,
          seedProductId = _res$data.seedProductId,
          seedName = _res$data.seedName,
          seedImage = _res$data.seedImage,
          matureGrow = _res$data.matureGrow,
          savageryGroundPlants = _res$data.savageryGroundPlants;
        _this2.form.productId = productId;
        _this2.form.productImage = productImage;
        _this2.form.productName = productName;
        _this2.form.backGroundImage = backGroundImage;
        _this2.form.maxEnergy = maxEnergy;
        _this2.form.initEnergy = initEnergy;
        _this2.form.consumeEnergy = consumeEnergy;
        _this2.form.waterNum = waterNum;
        _this2.form.natureGrow = natureGrow;
        _this2.form.consumeWater = consumeWater;
        _this2.form.growNum = growNum;
        _this2.outPutData.push({
          seedProductId: seedProductId,
          seedName: seedName,
          seedImage: seedImage,
          matureGrow: matureGrow,
          savageryGroundPlants: savageryGroundPlants
          // plantProductId,
          // plantImage,
          // plantName
        });
        _this2.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this2.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    goBack: function goBack() {
      this.$router.push("/merchant/animals/manageMent?name=landList");
    },
    deleteItem: function deleteItem(idx) {
      this.outPutData[0].savageryGroundPlants.splice(idx, 1);
    },
    selectAnimal: function selectAnimal() {
      this.$refs["product_nft"].changeshow();
      this.selectType = "selectAnimal";
    },
    addOutPut: function addOutPut() {
      this.outPutData.push({
        seedProductId: "",
        seedName: "",
        seedImage: "",
        matureGrow: 1,
        savageryGroundPlants: []
      });
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      var _data$data$ = data.data[0],
        productId = _data$data$.productId,
        image = _data$data$.image,
        name = _data$data$.name;
      if (this.selectType === "addSeed") {
        var idx = this.outPutData.findIndex(function (item) {
          return item.productId == productId;
        });
        if (idx > -1) {
          return this.$message.error("已存在,请重新选择");
        } else {
          this.outPutData[this.selectIndex].seedProductId = productId;
          this.outPutData[this.selectIndex].seedImage = image;
          this.outPutData[this.selectIndex].seedName = name;
        }
      } else if (this.selectType === "addManor") {
        var _idx = this.outPutData[this.selectIndex].savageryGroundPlants.findIndex(function (item) {
          return item.plantProductId == productId;
        });
        if (_idx > -1) {
          return this.$message.error("已存在,请重新选择");
        }
        this.outPutData[this.selectIndex].savageryGroundPlants.push({
          plantProductId: productId,
          plantImage: image,
          plantName: name
        });
      } else if (this.selectType === "selectAnimal") {
        this.form.productId = productId;
        this.form.productImage = image;
        this.form.productName = name;
      }
      this.selectType = "";
      this.selectIndex = -1;
    },
    selectOutPut: function selectOutPut(type, idx) {
      console.log(idx);
      if (idx > -1) {
        this.selectIndex = idx;
      }
      if (type === "seed") {
        this.selectType = "addSeed";
      } else {
        this.selectType = "addManor";
      }
      this.$refs["product_nft"].changeshow();
    },
    deleteOutOut: function deleteOutOut(idx) {
      this.outPutData.splice(idx, 1);
    },
    submit: function submit() {
      var _this3 = this;
      // handleAnimalsApi
      console.log(this.form);
      var err = "";
      if (!this.form.productId) {
        err = "请选择神兽";
      }
      if (!this.outPutData.length) {
        err = "请添加产出";
      }
      var idx = this.outPutData.findIndex(function (item) {
        return item.productId != "";
      });
      var idx2 = this.outPutData[0].savageryGroundPlants.findIndex(function (item) {
        return item.plantProductId != "";
      });
      if (idx == -1 || idx2 == -1) {
        err = "请补全信息";
      }
      if (err) {
        return this.$message.error(err);
      }
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.form), this.outPutData[0]);
      if (this.$route.query.id) {
        data.id = this.$route.query.id;
      }
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.handleGroundApi)(data).then(function (res) {
        _this3.$message.success("添加成功");
        _this3.goBack();
        _this3.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this3.$nextTick(function () {
          loadingInstance.close();
        });
      });
    }
  }
};