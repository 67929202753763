"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminCreateApi = adminCreateApi;
exports.adminDeleteApi = adminDeleteApi;
exports.adminDetailApi = adminDetailApi;
exports.adminListApi = adminListApi;
exports.adminLogApi = adminLogApi;
exports.adminPasswordApi = adminPasswordApi;
exports.adminStatusApi = adminStatusApi;
exports.adminUpdateApi = adminUpdateApi;
exports.appPackageListApi = appPackageListApi;
exports.appRenewCreateApi = appRenewCreateApi;
exports.appUseVersionApi = appUseVersionApi;
exports.configInfoApi = configInfoApi;
exports.configUpdateApi = configUpdateApi;
exports.getAgreement = getAgreement;
exports.getBalanceApi = getBalanceApi;
exports.getConfigListApi = getConfigListApi;
exports.getroleListApi = getroleListApi;
exports.menuRoleApi = menuRoleApi;
exports.menuTreeApi = menuTreeApi;
exports.menuTreeSelectApi = menuTreeSelectApi;
exports.merchantUpdate = merchantUpdate;
exports.roleCreateApi = roleCreateApi;
exports.roleDeleteApi = roleDeleteApi;
exports.roleDetailApi = roleDetailApi;
exports.roleMenuTreeselectApi = roleMenuTreeselectApi;
exports.roleStatusApi = roleStatusApi;
exports.roleUpdateApi = roleUpdateApi;
exports.setAgreement = setAgreement;
exports.storeGetInfoApi = storeGetInfoApi;
exports.storeGetInfoMapKeyApi = storeGetInfoMapKeyApi;
exports.storeUpdateApi = storeUpdateApi;
exports.uploadAppPackageApi = uploadAppPackageApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 角色管理 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function menuRoleApi(data) {
  return _request.default.get("system/role/list", data);
}

/**
 * @description 获取菜单下拉树列表
 */
function menuTreeSelectApi() {
  return _request.default.get("system/menu/treeselect");
}

/**
 * @description 获取菜单
 */
function menuTreeApi(data) {
  return _request.default.get("system/menu/getRoutersV2", data);
}

/**
 * @description 角色管理 -- 新增
 * @param {Object} param params {Object} 传值参数
 */
function roleCreateApi(data) {
  return _request.default.post("system/role", data);
}

/**
 * @description 角色管理 -- 获取下拉列表
 * @param {Object} param params {Object} 传值参数
 */
function getroleListApi() {
  return _request.default.get("system/role/optionselect");
}

/**
 * @description 角色管理 -- 编辑
 * @param {Object} param params {Object} 传值参数
 */
function roleUpdateApi(data) {
  return _request.default.put("system/role", data);
}

/**
 * @description 角色管理 -- 详情
 * @param {Object} param params {Object} 传值参数
 */
function roleDetailApi(roleId) {
  return _request.default.get("system/role/".concat(roleId));
}

/**
 * @description 角色管理 -- 加载对应角色菜单列表树
 * @param {Object} param params {Object} 传值参数
 */
function roleMenuTreeselectApi(roleId) {
  return _request.default.get("system/menu/roleMenuTreeselect/".concat(roleId));
}

/**
 * @description 角色管理 -- 删除
 * @param {Object} param params {Object} 传值参数
 */
function roleDeleteApi(roleIds) {
  return _request.default.delete("system/role/".concat(roleIds));
}

/**
 * @description 角色管理 -- 修改状态
 * @param {Object} param params {Object} 传值参数
 */
function roleStatusApi(data) {
  return _request.default.put("system/role/changeStatus", data);
}
/**
 * @description 管理员 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function adminListApi(data) {
  return _request.default.get("system/user/list", data);
}

/**
 * @description 管理员 -- 新增
 * @param {Object} param params {Object} 传值参数
 */
function adminCreateApi(data) {
  return _request.default.post("system/user", data);
}

/**
 * @description 管理员 -- 详情
 * @param {Object} param params {Object} 传值参数
 */
function adminDetailApi(userId) {
  return _request.default.get("system/user/".concat(userId));
}

/**
 * @description 管理员 -- 编辑
 * @param {Object} param params {Object} 传值参数
 */
function adminUpdateApi(data) {
  return _request.default.put("system/user", data);
}

/**
 * @description 管理员 -- 删除
 * @param {Object} param params {Object} 传值参数
 */
function adminDeleteApi(userId) {
  return _request.default.delete("system/user/".concat(userId));
}

/**
 * @description 管理员 -- 修改状态
 * @param {Object} param params {Object} 传值参数
 */
function adminStatusApi(data) {
  return _request.default.put("system/user/changeStatus", data);
}
/**
 * @description 管理员 -- 修改密码表单
 * @param {Object} param params {Object} 传值参数
 */
function adminPasswordApi(data) {
  return _request.default.put("system/user/resetPwd", data);
}
/**
 * @description 操作日志 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function adminLogApi(data) {
  return _request.default.get("system/admin/log", data);
}
/**
 * @description 自提 -- 详情
 * @param {Object} param params {Object} 传值参数
 */
function storeGetInfoApi() {
  return _request.default.get("system/sys/store/info");
}

/**
 * @description 地图mapkey
 * @param {Object} param params {Object} 传值参数
 */
function storeGetInfoMapKeyApi() {
  return _request.default.get("system/map/getConfig");
}

/**
 * @description 修改商户基本信息
 */
function merchantUpdate(data) {
  return _request.default.put("system/sys/store/update", data);
}

/**
 * @description 自提 -- 提交
 * @param {Object} param params {Object} 传值参数
 */
function storeUpdateApi(data) {
  return _request.default.post("take/update", data);
}

/**
 * @description 获取协议
 * @param {Object} param params {Object} 传值参数
 */
function getAgreement(data) {
  return _request.default.get("/system/sys/agreement/getAgreement", data);
}

/**
 * @description 保存协议
 * @param {Object} param params {Object} 传值参数
 */
function setAgreement(data) {
  return _request.default.post("/system/sys/agreement/setAgreement", data);
}

/**
 * @description 创建自动更新
 */
function appRenewCreateApi(data) {
  return _request.default.post("/appRenew/create", data);
}

// 上传apk包
function uploadAppPackageApi(data) {
  return _request.default.post("/appRenew/uploadAppPackage", data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

// app版本列表
function appPackageListApi(data) {
  return _request.default.get("/system/sys/renew/list", data);
}

// app 使用版本
function appUseVersionApi(id) {
  return _request.default.post("/system/sys/renew/useVersion/".concat(id));
}

//获取站内用户剩余资费提示

function getBalanceApi(data) {
  return _request.default.get("/system/nft/account/getBalance", data);
}
//获取通知配置

function getConfigListApi(data) {
  return _request.default.get("/open3d/system/sys/notice/config/list", data);
}

//修改配置
function configUpdateApi(data) {
  return _request.default.put("/open3d/system/sys/notice/config/update", data);
}
//配置详情
function configInfoApi(id) {
  return _request.default.get("/open3d/system/sys/notice/config/info/".concat(id));
}