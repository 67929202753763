"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
var _merchant = require("@/api/merchant");
var _system = require("@/api/system");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      SettingMer: _settingMer.default,
      params: {
        drawer: false
      },
      type: "1",
      title: "",
      checked: false,
      listLoading: false,
      showImg: false,
      showImgUrl: "",
      tableData: {
        data: [],
        total: 0
      },
      tableData1: {
        data: [],
        total: 0
      },
      timeArr: [],
      form: {
        id: "",
        topName: "",
        startTime: "",
        endTime: "",
        isEveryDay: false
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      lateralTableData: {
        // 消费排行侧滑列表
        data: [],
        total: 0
      },
      tableFrom1: {
        pageNum: 1,
        pageSize: 20
      },
      objRow: {},
      visibleIden: false,
      addUserForm: {
        id: "",
        identityName: "",
        sort: "",
        chooseType: '2',
        type: [],
        toExamine: false
      },
      userRules: {
        identityName: [{
          required: true,
          message: "请输入身份名称",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "请输入排序",
          trigger: "blur"
        }]
      },
      settingflag: false,
      settingLeftList: [],
      settingRightList: [],
      settingKeyArr: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 侧滑显示数据
    changeTypeAction: function changeTypeAction(val) {
      console.log(val, 152);
      this.type = val.name;
      this.tableData1.data = [];
      this.tableData1.total = 0;
      this.lookLeaderboard();
    },
    lookLeaderboard: function lookLeaderboard() {
      var _this = this;
      var param = Object.assign({}, {
        type: this.type
      }, this.tableFrom1, {
        id: this.objRow.id
      });
      (0, _user.consumeGetTopList)(param).then(function (res) {
        _this.tableData1.data = res.rows;
        _this.tableData1.total = res.total;
      });
    },
    lookShow: function lookShow(row) {
      this.objRow = row;
      this.params.drawer = true;
      this.lookLeaderboard();
    },
    create: function create() {
      this.title = "创建身份";
      this.visibleIden = true;
      this.addUserForm = {
        id: "",
        identityName: "",
        sort: "",
        chooseType: '2',
        type: [],
        toExamine: false
      };
    },
    getList: function getList() {
      var _this2 = this;
      (0, _merchant.getList)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
      });
    },
    linkAction: function linkAction(id) {
      this.copyAction("".concat(_settingMer.default.appUrl, "/users/consumer?id=").concat(id));
    },
    copyAction: function copyAction(val) {
      var input = document.createElement("input"); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    QRAction: function QRAction(id) {
      var _this3 = this;
      this.showImg = true;
      (0, _system.createQRCodeApi)({
        url: "/users/consumer?id=".concat(id),
        width: 400,
        height: 400
      }).then(function (res) {
        _this3.showImgUrl = res.data.image;
      });
    },
    // 侧滑分页
    handleSizeChange1: function handleSizeChange1(val) {
      this.tableFrom1.pageSize = val;
      this.lookLeaderboard();
    },
    pageChange1: function pageChange1(val) {
      this.tableFrom1.pageNum = val;
      this.lookLeaderboard();
    },
    // 页面分页
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    // 编辑
    onEdit: function onEdit(row) {
      this.visibleIden = true;
      this.title = "编辑身份";
      this.addUserForm = row;
      //   consumeGetInfoById({ id: row.id }).then((res) => {
      //     this.form = res.data;
      //   });
    },
    // 删除
    handleDelete: function handleDelete(row) {
      var _this4 = this;
      (0, _user.consumeDel)({
        id: row.id
      }).then(function (res) {
        _this4.$message({
          type: "success",
          message: "已删除"
        });
        _this4.getList();
      });
    },
    open: function open() {
      this.params.drawer = true;
    },
    close: function close() {
      this.params.drawer = false;
    },
    confirmUserAction: function confirmUserAction(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var api = _merchant.createIdentity;
          if (_this5.addUserForm.id) {
            api = _merchant.editIdentity;
          }
          _this5.addUserForm.chooseType = _this5.addUserForm.type.join(",");
          api(_this5.addUserForm).then(function () {
            _this5.$message.success("操作成功");
            _this5.visibleUser = false;
            _this5.visibleIden = false;
            _this5.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    lookSetting: function lookSetting(row, type) {
      var _this6 = this;
      this.settingtype = type;
      (0, _merchant.getIdenSettingDetail)(row.id, {
        type: type
      }).then(function (res) {
        _this6.settingLeftList = res.data.informationVos;
        var rightlist = res.data.memberIdentitySetInformationVos || [];
        rightlist.forEach(function (item, index) {
          item.sort = item.sort || index + 1;
        });
        _this6.settingRightList = rightlist;
        _this6.settingKeyArr = _this6.settingRightList.map(function (item) {
          return item.key;
        });
        _this6.settingflag = true;
        _this6.settingid = row.id;
        _this6.$forceUpdate();
      });
    },
    changeSetting: function changeSetting(event, row) {
      console.log(event);
      console.log(row);
      if (event) {
        this.settingRightList.push({
          key: row.key,
          name: row.name,
          customName: row.customName,
          isMust: 0
        });
      } else {
        var that = this;
        for (var i = 0; i < this.settingRightList.length; i++) {
          if (that.settingRightList[i].key == row.key) {
            that.settingRightList.splice(i, 1);
            break;
          }
        }
      }
    },
    savesetting: function savesetting() {
      var _this7 = this;
      (0, _merchant.setIdenSettingDetail)({
        id: this.settingid,
        informationVoList: this.settingRightList,
        type: this.settingtype
      }).then(function (res) {
        _this7.$message.success('保存成功');
        _this7.settingflag = false;
        _this7.getList();
      });
    },
    changesort: function changesort() {
      var settingrightList = JSON.parse(JSON.stringify(this.settingRightList));
    }
  }
};