"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
var _automaticOrder = require("@/api/automaticOrder");
var _timing = require("@/api/timing");
var _marketing = require("@/api/marketing");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "TimingOrder",
  data: function data() {
    return {
      selectUser: {},
      curStatus: {},
      name: "",
      listTableData: {
        data: [],
        total: 0
      },
      listPageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      listLoading: "",
      userDialog: false,
      userPageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      userTableData: {
        data: [],
        total: 0
      },
      userName: "",
      userLoading: false,
      addCollectionDialog: false,
      marketProducts: [],
      collection: [],
      collectionDialog: false,
      collectionName: "",
      collectionLoading: false,
      collectionConfig: {
        pageNum: 1,
        pageSize: 10
      },
      collectionData: {
        data: [],
        total: 0
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    handle: function handle() {
      var _this = this;
      var loadingInstance = _elementUi.Loading.service();
      (0, _timing.startApi)().then(function (res) {
        _this.getList();
        _this.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    addAccount: function addAccount() {
      this.getUserList(1);
    },
    closeDialog: function closeDialog(ele, needApi) {
      console.log(ele);
      if (ele) {
        this[ele] = false;
        this.userName = "";
        this.userPageConfig.pageNum = 1;
        this.collectionConfig.pageNum = 1;
        this.selectUser = {};
      }
      if (ele === "addCollectionDialog") {
        this.marketProducts = [];
      }
      this.collectionName = "";
      if (needApi) {
        this.getList(1);
      }
    },
    getList: function getList(page) {
      var _this2 = this;
      var data = {
        name: this.name,
        pageNum: page ? page : this.listPageConfig.pageNum,
        pageSize: this.listPageConfig.pageSize
      };
      this.listLoading = true;
      (0, _timing.listApi)(data).then(function (res) {
        _this2.listTableData.total = res.total;
        _this2.listTableData.data = res.rows;
        _this2.curStatus = res.statistical || {};
        _this2.listLoading = false;
      });
    },
    deleteOrder: function deleteOrder(id) {
      var _this3 = this;
      this.$confirm("确认删除吗?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var loadingInstance = _elementUi.Loading.service();
        (0, _timing.deleteOrderApi)(id).then(function (res) {
          _this3.$message.success("删除成功");
          _this3.closeDialog();
          _this3.getList();
          _this3.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this3.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function () {});
    },
    getUserList: function getUserList(page) {
      var _this4 = this;
      var data = {
        keyword: this.userName,
        pageNum: page ? page : this.userPageConfig.pageNum,
        pageSize: this.userPageConfig.pageSize
      };
      this.userLoading = true;
      (0, _automaticOrder.listByClearApi)(data).then(function (res) {
        _this4.userLoading = false;
        _this4.userTableData.total = res.total;
        _this4.userTableData.data = res.rows;
        if (!_this4.userDialog) {
          _this4.userDialog = true;
        }
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPageConfig.pageSize = val;
      this.getList();
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.userPageConfig.pageSize = val;
      this.getUserList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.userTable.clearSelection();
        this.$refs.userTable.toggleRowSelection(val.pop());
        return;
      }
      if (val[0]) {
        this.selectUser = val[0];
      } else {
        this.selectUser = {};
      }
    },
    addUser: function addUser() {
      var _this5 = this;
      if (this.selectUser.id) {
        var loadingInstance = _elementUi.Loading.service();
        (0, _timing.OrderMemberApi)(this.selectUser.id).then(function (res) {
          _this5.$message.success("添加成功");
          _this5.curStatus.lockordermember = _this5.selectUser.userPhone;
          _this5.closeDialog("userDialog");
          _this5.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this5.$nextTick(function () {
            loadingInstance.close();
          });
        });
      } else {
        this.$message.error("请选择用户");
      }
    },
    getCollectionList: function getCollectionList(page) {
      var _this6 = this;
      var data = {
        type: 1,
        status: true,
        name: this.collectionName,
        pageNum: page || this.collectionConfig.pageNum,
        pageSize: this.collectionConfig.pageSize
      };
      this.collectionLoading = true;
      (0, _marketing.marketProductListApi)(data).then(function (res) {
        _this6.collectionLoading = false;
        if (!_this6.collectionDialog) {
          _this6.collectionDialog = true;
        }
        _this6.collectionData.data = res.rows;
        _this6.collectionData.total = res.total;
      });
    },
    showCollection: function showCollection() {
      this.collection = (0, _toConsumableArray2.default)(this.marketProducts);
      this.getCollectionList(1);
    },
    addCollection: function addCollection() {
      var _this7 = this;
      this.collection.forEach(function (item) {
        _this7.marketProducts.push((0, _objectSpread2.default)({
          maxPrice: 0
        }, item));
      });
      this.closeDialog("collectionDialog");
    },
    selectCollection: function selectCollection(item, type) {
      if (type) {
        this.collection.push(item);
      } else {
        var idx = this.collection.findIndex(function (i) {
          return i.id === item.id;
        });
        this.collection.splice(idx, 1);
      }
    },
    deleteCollection: function deleteCollection(_ref) {
      var id = _ref.id;
      this.marketProducts = this.marketProducts.filter(function (item) {
        return item.id != id;
      });
    },
    batchSubmit: function batchSubmit() {
      var _this8 = this;
      var marketProducts = [];
      this.marketProducts.forEach(function (item) {
        marketProducts.push({
          maxPrice: item.maxPrice,
          productId: item.productId,
          productName: item.name,
          image: item.image
        });
      });
      (0, _timing.orderApi)(marketProducts).then(function (res) {
        _this8.$message.success("提交成功");
        _this8.closeDialog("addCollectionDialog");
        _this8.getList(1);
      });
      console.log(marketProducts);
    }
  }
};