"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _index = _interopRequireDefault(require("./index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Tag from '@/components/tag.vue';
var _default2 = exports.default = {
  components: {
    popup: _index.default
    // Tag
  },
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: ''
    },
    index: {
      type: Number
    },
    /**
     * 禁用选项
     * @type {Object}
     */
    forbidden: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    selectData: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        var data = JSON.parse(JSON.stringify(value));
        this.$emit('input', data);
      }
    },
    isHome: function isHome() {
      return this.index === 0;
    }
  },
  methods: {
    getTipsInfo: function getTipsInfo(item) {
      if (item.extend) {
        if (item.index == 2) {
          if (item.param.name) {
            if (item.param.name.length > 4) {
              return item.param.name.slice(0, 4) + '...';
            } else {
              return item.param.name;
            }
          }
        }
        if (item.index == 3) {
          if (item.param.address) {
            if (item.param.address.length > 4) {
              return item.param.address.slice(0, 4) + '...';
            } else {
              return item.param.address;
            }
          }
        }
        if (item.index == 7) {
          if (item.param.title) {
            if (item.param.title.length > 4) {
              return item.param.title.slice(0, 4) + '...';
            } else {
              return item.param.title;
            }
          }
        }
        if (item.index === 10) {
          if (item.param.name) {
            if (item.param.name.length > 4) {
              return item.param.name.slice(0, 4) + '...';
            } else {
              return item.param.name;
            }
          }
        }
        if (item.index === 11) {
          if (item.param.appId) {
            if (item.param.appId.length > 4) {
              return item.param.appId.slice(0, 4) + '...';
            } else {
              return item.param.appId;
            }
          }
        }
        if (item.index === 12) {
          if (item.param.url) {
            if (item.param.url.length > 4) {
              return item.param.url.slice(0, 4) + '...';
            } else {
              return item.param.url;
            }
          }
        }
      }
      return item.name;
    },
    handleClose: function handleClose() {
      this.selectData = {};
    },
    /**
     * 处理确认事件
     * @return {[type]} [description]
     */
    handleConfirm: function handleConfirm(value) {
      var _this = this;
      this.$nextTick(function () {
        _this.selectData = value;
      });
    },
    stop: function stop(event) {
      if (this.isHome) event.stopPropagation();
    }
  }
};