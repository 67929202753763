var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _vm.facade.showType != 2
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "top-img",
                      style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "item-flex-center",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: _vm.facade.imgStatic
                                ? "no-d3-wrap"
                                : "d3-wrap",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "rotatef" },
                                [
                                  _c("el-image", {
                                    staticClass: "img",
                                    style: {
                                      borderWidth:
                                        (_vm.facade.borderWidth
                                          ? _vm.facade.borderWidth
                                          : 0) /
                                          2 +
                                        "px",
                                    },
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                                      fit: "contain",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              : _c("div", { staticClass: "top-img" }, [_vm._m(0)]),
          ]
        : _vm.content.type === "nameArea"
        ? [
            _c(
              "div",
              {
                staticClass: "top-name-view",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.facade.marginLeft / 2 +
                  "px 0 " +
                  _vm.facade.marginLeft / 2 +
                  "px;background: " +
                  _vm.facade.background +
                  ";padding: 0 " +
                  _vm.facade.paddingLeft / 2 +
                  "px " +
                  _vm.facade.paddingbottom / 2 +
                  "px " +
                  _vm.facade.paddingLeft / 2 +
                  "px;\n    border-radius:" +
                  _vm.facade.topRadius / 2 +
                  "px " +
                  _vm.facade.topRadius / 2 +
                  "px " +
                  _vm.facade.bottomRadius / 2 +
                  "px " +
                  _vm.facade.bottomRadius / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    class: {
                      "flex-direction item-flex-center":
                        _vm.facade.layout == "center",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-one",
                        staticStyle: { overflow: "hidden" },
                      },
                      [
                        _c(
                          "div",
                          {
                            style:
                              "padding-top: " +
                              _vm.facade.nameTop / 2 +
                              "px;font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;line-height: " +
                              (_vm.facade.nameFontSize + 14) / 2 +
                              "px;color: " +
                              _vm.facade.nameColor +
                              ";font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";text-align: " +
                              _vm.facade.layout +
                              ";",
                          },
                          [
                            _vm._v(
                              "\n            DIGITAL BELIEVERS\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            class: {
                              "item-flex-center": _vm.facade.layout == "center",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tag flex align-items-c",
                                style:
                                  "margin-top: " +
                                  _vm.facade.tabTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.tabFontSize / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.tabFongWeight +
                                  ";border:1px solid " +
                                  _vm.facade.tabColor +
                                  ";",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "background:" +
                                      _vm.facade.tabColor +
                                      ";color: " +
                                      _vm.facade.tabBgColor,
                                  },
                                  [
                                    _vm._v(
                                      "\n                限量\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background:" +
                                      _vm.facade.tabBgColor +
                                      ";color: " +
                                      _vm.facade.tabColor,
                                  },
                                  [
                                    _vm._v(
                                      "\n                1000份\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "desc",
                                style:
                                  "margin-top: " +
                                  _vm.facade.tabTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.tabFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.tabColor +
                                  ";font-weight: " +
                                  _vm.facade.tabFongWeight +
                                  ";background:" +
                                  _vm.facade.tabBgColor +
                                  ";border:1px solid " +
                                  _vm.facade.tabBorder +
                                  ";",
                              },
                              [_vm._v("\n              盛世首创\n            ")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.facade.btnText
                      ? _c(
                          "div",
                          {
                            staticClass: "btn flex align-items-c",
                            style:
                              "margin-top: " +
                              _vm.facade.btnTop / 2 +
                              "px;font-size: " +
                              _vm.facade.btnFontSize / 2 +
                              "px;color: " +
                              _vm.facade.btnColor +
                              ";font-weight: " +
                              _vm.facade.btnFongWeight +
                              ";background:" +
                              _vm.facade.btnBgColor +
                              ";border:1px solid " +
                              _vm.facade.btnBorder +
                              ";height: " +
                              _vm.facade.btnHeight / 2 +
                              "px;border-radius: " +
                              _vm.facade.btnRadius / 2 +
                              "px;",
                          },
                          [
                            _vm.facade.btnImg
                              ? _c("img", { attrs: { src: _vm.facade.btnImg } })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(_vm.facade.btnText))]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "advantage flex align-items-c",
                    style:
                      "margin-top: " +
                      _vm.facade.advantageTop / 2 +
                      "px;color: " +
                      _vm.facade.descColor +
                      ";",
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._m(4),
                    _vm._v(" "),
                    _vm._m(5),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "desc",
                    style:
                      "margin-top: " +
                      _vm.facade.descTop / 2 +
                      "px;font-size: " +
                      _vm.facade.descFontSize / 2 +
                      "px;color: " +
                      _vm.facade.descColor +
                      ";font-weight: " +
                      _vm.facade.descFontWeight +
                      ";line-height:" +
                      _vm.facade.descLineHeight / 2 +
                      "px;",
                  },
                  [
                    _vm._v(
                      "\n        未来，人类对数码设备，脑机接口的极度依赖，沉溺于虚拟世界元宇宙而抵触现实。由此产生的新宗教，统称为数字信徒。脑机接口的极度依赖。\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "share"
        ? [
            _vm.pageInfo.show_share == 1 && _vm.pageInfo.share_area == 1
              ? _c(
                  "div",
                  {
                    staticClass: "top-img item-flex-center",
                    style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
                  },
                  [
                    _c("div", { staticClass: "share-icon" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.facade.share_icon + "!120a",
                          alt: "",
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        : _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style:
                  "padding-top: " +
                  _vm.facade.marginTop / 2 +
                  "px;background: " +
                  _vm.facade.nft_bg_color +
                  ";\n    border-top-left-radius:" +
                  _vm.facade.nft_border_top_radius / 2 +
                  "px;border-top-right-radius:" +
                  _vm.facade.nft_border_top_radius / 2 +
                  "px;\n    border-bottom-left-radius:" +
                  _vm.facade.nft_border_bottom_radius / 2 +
                  "px;border-bottom-right-radius:" +
                  _vm.facade.nft_border_bottom_radius / 2 +
                  "px;\n    line-height: " +
                  _vm.facade.nft_height / 2 +
                  "px",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "name",
                    style:
                      "color: " +
                      _vm.facade.color +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";",
                  },
                  [_vm._v("\n        刺杀罪恶之文化侵蚀\n      ")]
                ),
              ]
            ),
          ]
        : _vm.content.type === "tag"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tags item-flex-center flex-wrap",
                    style: "font-size: " + _vm.facade.btn_font_size / 2 + "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        style:
                          "color: " +
                          _vm.facade.main_btn_color +
                          ";background: " +
                          _vm.facade.main_btn_bg_color +
                          ";border-radius: " +
                          _vm.facade.btn_radius / 2 +
                          "px",
                      },
                      [_vm._v("\n          红色文律\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        style:
                          "color: " +
                          _vm.facade.sub_btn_color +
                          ";background: " +
                          _vm.facade.sub_btn_bg_color +
                          ";border-radius: " +
                          _vm.facade.btn_radius / 2 +
                          "px",
                      },
                      [_vm._v("\n          文化复兴\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "num",
                        style:
                          "color: " +
                          _vm.facade.stock_color +
                          ";font-size: " +
                          _vm.facade.stock_font_size / 2 +
                          "px;",
                      },
                      [_vm._v("\n          限量500份\n        ")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "applicationProcess"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.facade.title) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("div", {
                        staticClass: "line",
                        style: {
                          height: _vm.facade.lineHeight / 2 + "px",
                          background: _vm.facade.lineColor,
                          top: (32 + _vm.facade.spotHeight / 2) / 2 + "px",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "step flex" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex-one item",
                            style:
                              "padding-top: " +
                              (_vm.facade.spotHeight + 32 + 18) / 2 +
                              "px;",
                          },
                          [
                            _c("div", {
                              staticClass: "spot",
                              style: {
                                height: _vm.facade.spotHeight / 2 + "px",
                                width: _vm.facade.spotHeight / 2 + "px",
                                borderRadius: _vm.facade.spotHeight / 4 + "px",
                                background: _vm.facade.spotColor,
                                marginLeft: -(_vm.facade.spotHeight / 4) + "px",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "t",
                                style:
                                  "line-height: " +
                                  (_vm.facade.mainFontSize + 12) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.mainFontWeight +
                                  ";font-size: " +
                                  _vm.facade.mainFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.mainColor,
                              },
                              [
                                _vm._v(
                                  "\n                开放申购\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "s",
                                style:
                                  "line-height: " +
                                  (_vm.facade.subFontSize + 8) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.subFontWeight +
                                  ";font-size: " +
                                  _vm.facade.subFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.subColor,
                              },
                              [
                                _vm._v(
                                  "\n                10-09\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "s",
                                style:
                                  "line-height: " +
                                  (_vm.facade.subFontSize + 8) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.subFontWeight +
                                  ";font-size: " +
                                  _vm.facade.subFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.subColor,
                              },
                              [
                                _vm._v(
                                  "\n                12:00\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one item",
                            style:
                              "padding-top: " +
                              (_vm.facade.spotHeight + 32 + 18) / 2 +
                              "px;",
                          },
                          [
                            _c("div", {
                              staticClass: "spot",
                              style: {
                                height: _vm.facade.spotHeight / 2 + "px",
                                width: _vm.facade.spotHeight / 2 + "px",
                                borderRadius: _vm.facade.spotHeight / 4 + "px",
                                background: _vm.facade.spotColor,
                                marginLeft: -(_vm.facade.spotHeight / 4) + "px",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "t",
                                style:
                                  "line-height: " +
                                  (_vm.facade.mainFontSize + 12) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.mainFontWeight +
                                  ";font-size: " +
                                  _vm.facade.mainFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.mainColor,
                              },
                              [
                                _vm._v(
                                  "\n                申购结束\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "s",
                                style:
                                  "line-height: " +
                                  (_vm.facade.subFontSize + 8) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.subFontWeight +
                                  ";font-size: " +
                                  _vm.facade.subFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.subColor,
                              },
                              [
                                _vm._v(
                                  "\n                10-10\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "s",
                                style:
                                  "line-height: " +
                                  (_vm.facade.subFontSize + 8) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.subFontWeight +
                                  ";font-size: " +
                                  _vm.facade.subFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.subColor,
                              },
                              [
                                _vm._v(
                                  "\n                12:00\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one item",
                            style:
                              "padding-top: " +
                              (_vm.facade.spotHeight + 32 + 18) / 2 +
                              "px;",
                          },
                          [
                            _c("div", {
                              staticClass: "spot",
                              style: {
                                height: _vm.facade.spotHeight / 2 + "px",
                                width: _vm.facade.spotHeight / 2 + "px",
                                borderRadius: _vm.facade.spotHeight / 4 + "px",
                                background: _vm.facade.spotColor,
                                marginLeft: -(_vm.facade.spotHeight / 4) + "px",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "t",
                                style:
                                  "line-height: " +
                                  (_vm.facade.mainFontSize + 12) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.mainFontWeight +
                                  ";font-size: " +
                                  _vm.facade.mainFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.mainColor,
                              },
                              [
                                _vm._v(
                                  "\n                中签及付款\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "s",
                                style:
                                  "line-height: " +
                                  (_vm.facade.subFontSize + 8) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.subFontWeight +
                                  ";font-size: " +
                                  _vm.facade.subFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.subColor,
                              },
                              [
                                _vm._v(
                                  "\n                10-11\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "s",
                                style:
                                  "line-height: " +
                                  (_vm.facade.subFontSize + 8) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.subFontWeight +
                                  ";font-size: " +
                                  _vm.facade.subFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.subColor,
                              },
                              [
                                _vm._v(
                                  "\n                12:00\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one item",
                            style:
                              "padding-top: " +
                              (_vm.facade.spotHeight + 32 + 18) / 2 +
                              "px;",
                          },
                          [
                            _c("div", {
                              staticClass: "spot",
                              style: {
                                height: _vm.facade.spotHeight / 2 + "px",
                                width: _vm.facade.spotHeight / 2 + "px",
                                borderRadius: _vm.facade.spotHeight / 4 + "px",
                                background: _vm.facade.spotColor,
                                marginLeft: -(_vm.facade.spotHeight / 4) + "px",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "t",
                                style:
                                  "line-height: " +
                                  (_vm.facade.mainFontSize + 12) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.mainFontWeight +
                                  ";font-size: " +
                                  _vm.facade.mainFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.mainColor,
                              },
                              [
                                _vm._v(
                                  "\n                开放购买\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "s",
                                style:
                                  "line-height: " +
                                  (_vm.facade.subFontSize + 8) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.subFontWeight +
                                  ";font-size: " +
                                  _vm.facade.subFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.subColor,
                              },
                              [
                                _vm._v(
                                  "\n                10-12\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "s",
                                style:
                                  "line-height: " +
                                  (_vm.facade.subFontSize + 8) / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.subFontWeight +
                                  ";font-size: " +
                                  _vm.facade.subFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.subColor,
                              },
                              [
                                _vm._v(
                                  "\n                12:00\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "winnerList"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                        _vm._v(" "),
                        _vm.facade.showMore
                          ? _c(
                              "div",
                              {
                                staticClass: "more flex align-items-c",
                                style:
                                  "font-weight: " +
                                  _vm.facade.moreFontWeight +
                                  ";font-size: " +
                                  _vm.facade.moreFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.moreColor +
                                  ";padding: " +
                                  _vm.facade.morePaddingTop / 2 +
                                  "px " +
                                  _vm.facade.morePaddingLeft / 2 +
                                  "px;background: " +
                                  _vm.facade.moreBgColor +
                                  ";border-radius: " +
                                  _vm.facade.moreRadius / 2 +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.facade.moreText) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.facade.title) + "\n        "
                        ),
                        _vm.facade.showMore
                          ? _c(
                              "div",
                              {
                                staticClass: "more flex align-items-c",
                                style:
                                  "font-weight: " +
                                  _vm.facade.moreFontWeight +
                                  ";font-size: " +
                                  _vm.facade.moreFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.moreColor +
                                  ";padding: " +
                                  _vm.facade.morePaddingTop / 2 +
                                  "px " +
                                  _vm.facade.morePaddingLeft / 2 +
                                  "px;background: " +
                                  _vm.facade.moreBgColor +
                                  ";border-radius: " +
                                  _vm.facade.moreRadius / 2 +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.facade.moreText) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "content" },
                      _vm._l(3, function (m) {
                        return _c(
                          "div",
                          {
                            key: "list" + m,
                            staticClass: "name-list flex",
                            style:
                              "font-weight: " +
                              _vm.facade.fontWeight +
                              ";font-size: " +
                              _vm.facade.fontSize / 2 +
                              "px;color: " +
                              _vm.facade.color +
                              ";line-height: " +
                              _vm.facade.lineHeight / 2 +
                              "px;",
                          },
                          [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v("186****5152"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "line1" }, [
                              _vm._v("186****5152"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "line1" }, [
                              _vm._v("186****5152"),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "syntheticConditions"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                        _vm._v(" "),
                        _vm.facade.showBtn
                          ? _c(
                              "div",
                              {
                                staticClass: "more flex align-items-c",
                                style:
                                  "font-weight: " +
                                  _vm.facade.btnFontWeight +
                                  ";font-size: " +
                                  _vm.facade.btnFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.btnColor +
                                  ";padding: " +
                                  _vm.facade.btnPaddingTop / 2 +
                                  "px " +
                                  _vm.facade.btnPaddingLeft / 2 +
                                  "px;background: " +
                                  _vm.facade.btnBgColor +
                                  ";border-radius: " +
                                  _vm.facade.btnRadius / 2 +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.facade.btnText) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.facade.title) + "\n        "
                        ),
                        _vm.facade.showBtn
                          ? _c(
                              "div",
                              {
                                staticClass: "more flex align-items-c",
                                style:
                                  "font-weight: " +
                                  _vm.facade.btnFontWeight +
                                  ";font-size: " +
                                  _vm.facade.btnFontSize / 2 +
                                  "px;color: " +
                                  _vm.facade.btnColor +
                                  ";padding: " +
                                  _vm.facade.btnPaddingTop / 2 +
                                  "px " +
                                  _vm.facade.btnPaddingLeft / 2 +
                                  "px;background: " +
                                  _vm.facade.btnBgColor +
                                  ";border-radius: " +
                                  _vm.facade.btnRadius / 2 +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.facade.btnText) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "synthetic-conditions" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "name line1",
                          style:
                            "font-weight: " +
                            _vm.facade.nameFontWeight +
                            ";font-size: " +
                            _vm.facade.nameFontSize / 2 +
                            "px;color: " +
                            _vm.facade.nameColor +
                            ";margin-top: " +
                            _vm.facade.nameMarginTop / 2 +
                            "px;",
                        },
                        [
                          _vm._v(
                            "\n              A Digital Belivevers\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "need flex",
                          style:
                            "margin-top: " +
                            _vm.facade.needMarginTop / 2 +
                            "px;",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "need-view flex",
                              style:
                                "border-color: " +
                                _vm.facade.needBorderColor +
                                ";background: " +
                                _vm.facade.needBgColor +
                                ";color: " +
                                _vm.facade.needColor,
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "l",
                                  style:
                                    "border-color: " +
                                    _vm.facade.needBorderColor +
                                    ";",
                                },
                                [
                                  _vm._v(
                                    "\n                  A+B+C+D需要500\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "r",
                                  style:
                                    "background: " +
                                    _vm.facade.needReachBgColor +
                                    ";",
                                },
                                [
                                  _vm._v(
                                    "\n                  持有614\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        style:
                          "margin-left: " +
                          _vm.pageInfo.item_padding / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style:
                              "border-radius: " +
                              _vm.facade.imgRadius / 2 +
                              "px;",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style:
                              "font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;color: " +
                              _vm.facade.nameColor +
                              ";margin-top: " +
                              _vm.facade.nameMarginTop / 2 +
                              "px;",
                          },
                          [
                            _vm._v(
                              "\n              B Digital Belivevers\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "need flex",
                            style:
                              "margin-top: " +
                              _vm.facade.needMarginTop / 2 +
                              "px;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A+B需要5\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "r" }, [
                                  _vm._v("持有1"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        style:
                          "margin-left: " +
                          _vm.pageInfo.item_padding / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style:
                              "border-radius: " +
                              _vm.facade.imgRadius / 2 +
                              "px;",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style:
                              "font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;color: " +
                              _vm.facade.nameColor +
                              ";margin-top: " +
                              _vm.facade.nameMarginTop / 2 +
                              "px;",
                          },
                          [
                            _vm._v(
                              "\n              C Digital Belivevers\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "need flex",
                            style:
                              "margin-top: " +
                              _vm.facade.needMarginTop / 2 +
                              "px;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A+B+C需要500\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background: " +
                                      _vm.facade.needReachBgColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  持有614\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.facade.title) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;",
                  },
                  [
                    !_vm.facade.hideStock
                      ? _c("div", { staticClass: "item flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              style: "color: " + _vm.facade.color + ";",
                            },
                            [_vm._v("库存剩余")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "r",
                              style:
                                "color: " +
                                _vm.facade.otherColor +
                                ";text-align: " +
                                (_vm.facade.descLayout || "left"),
                            },
                            [_vm._v("\n            16,155\n          ")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.facade.hideIssuesNumber
                      ? _c("div", { staticClass: "item flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              style: "color: " + _vm.facade.color + ";",
                            },
                            [_vm._v("发行数量")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "r",
                              style:
                                "color: " +
                                _vm.facade.otherColor +
                                ";text-align: " +
                                (_vm.facade.descLayout || "left"),
                            },
                            [_vm._v("\n            100,000\n          ")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.facade.hideSuperLink
                      ? _c("div", { staticClass: "item flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              style: "color: " + _vm.facade.color + ";",
                            },
                            [_vm._v("超级链标识")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "r flex align-items-c",
                              style:
                                "color: " +
                                _vm.facade.otherColor +
                                ";justify-content: " +
                                (_vm.facade.descLayout == "right"
                                  ? "flex-end"
                                  : "flex-start"),
                            },
                            [
                              _c("span", [_vm._v("aj2435234SD532KD345UK324")]),
                              _vm._v(" "),
                              _vm.facade.super_link_img
                                ? _c("img", {
                                    staticClass: "super-link-icon",
                                    attrs: { src: _vm.facade.super_link_img },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.facade.hideIssuer
                      ? _c("div", { staticClass: "item flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              style: "color: " + _vm.facade.color + ";",
                            },
                            [_vm._v("发行方")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "r flex align-items-c",
                              style:
                                "color: " +
                                _vm.facade.otherColor +
                                ";justify-content: " +
                                (_vm.facade.descLayout == "right"
                                  ? "flex-end"
                                  : "flex-start"),
                            },
                            [
                              _c("span", [_vm._v("开放平台")]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "super-link-icon",
                                attrs: {
                                  src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221020/image/1666238185534501357.png",
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "coupon"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "coupon flex align-items-c",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _c("div", { staticClass: "flex align-items-c flex-one" }, [
                      _c("img", {
                        attrs: { src: _vm.facade.couponImg, alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml10" }, [_vm._v("满500减30")]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "ml20",
                        attrs: { src: _vm.facade.couponImg, alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml10" }, [_vm._v("9折券")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", [_vm._v("领券")]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: _vm.facade.receiveImg, alt: "" },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "kind"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "common-title",
                    staticStyle: { padding: "0" },
                    style:
                      "color: " +
                      _vm.pageInfo.titleColor +
                      ";font-size: " +
                      _vm.pageInfo.titleFontSize / 2 +
                      "px;",
                  },
                  [_vm._v("\n        " + _vm._s(_vm.facade.title) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "kind-content",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "arrow-img",
                      attrs: { src: _vm.facade.arrowImg },
                    }),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "author"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.facade.title) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "author-content" }, [
                  _c("div", { staticClass: "author flex align-items-c" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style:
                          "border-radius: " + _vm.facade.imgRadius / 2 + "px;",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "name flex-one",
                        style: {
                          color: _vm.facade.nameColor,
                          fontWeight: _vm.facade.nameFontWeight,
                          fontSize: _vm.facade.nameFontSize / 2 + "px",
                        },
                      },
                      [_vm._v("\n            刘晓蹂\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        style:
                          "font-weight: " +
                          _vm.facade.btnFontWeight +
                          ";font-size: " +
                          _vm.facade.btnFontSize / 2 +
                          "px;color: " +
                          _vm.facade.btnColor +
                          ";padding: " +
                          _vm.facade.btnPaddingTop / 2 +
                          "px " +
                          _vm.facade.btnPaddingLeft / 2 +
                          "px;background: " +
                          _vm.facade.btnBgColor +
                          ";border-radius: " +
                          _vm.facade.btnRadius / 2 +
                          "px;",
                      },
                      [_vm._v("\n            关注\n          ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "author-desc",
                      style: {
                        color: _vm.facade.contentColor,
                        fontWeight: _vm.facade.contentFontWeight,
                        fontSize: _vm.facade.contentFontSize / 2 + "px",
                        marginTop: _vm.facade.contentMarginTop / 2 + "px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          草间弥生(Yayoi\n          Kusama)，出生于日本长野县松本市，毕业于日本长野县松本女子学校。在1956年移居美国纽约市，并开始展露她占有领导地位的前卫艺术创\n        "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.facade.title) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";",
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n          数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满14周岁的中国大陆用户购买。\n        "
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type == "nftCollectionList"
        ? [
            _c(
              "div",
              {
                staticClass: "content-box",
                style:
                  "padding:  0 " +
                  _vm.facade.page_margin / 2 +
                  "px;background: " +
                  _vm.facade.background_color +
                  ";font-weight: " +
                  _vm.facade.font_weight,
              },
              _vm._l(3, function (item, index) {
                return _c("div", { key: index, staticClass: "list flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "r-view flex",
                      class: { mt0: index == 0 },
                      style:
                        "margin-top:" +
                        _vm.facade.item_margin / 2 +
                        "px;border-top-right-radius: " +
                        _vm.facade.img_radius / 2 +
                        "px;border-top-left-radius: " +
                        _vm.facade.img_radius / 2 +
                        "px;border-bottom-left-radius: " +
                        _vm.facade.status_radius / 2 +
                        "px;border-bottom-right-radius: " +
                        _vm.facade.status_radius / 2 +
                        "px;background: " +
                        _vm.facade.status_background +
                        " url(" +
                        _vm.facade.status_bg_image +
                        ") repeat left center;background-size: 100%;",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "img" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src:
                                index == 0
                                  ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                  : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                              fit:
                                _vm.facade.img_style === 2
                                  ? "contain"
                                  : "cover",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "img-r" }, [
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style:
                              "color: " +
                              _vm.facade.title_color +
                              ";font-size: " +
                              _vm.facade.title_font_size / 2 +
                              "px;font-weight: " +
                              _vm.facade.title_font_weight +
                              ";",
                          },
                          [
                            _vm._v(
                              "\n              坠落·光在前方照亮的地方\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "count",
                            style:
                              "color: " +
                              _vm.facade.stock_color +
                              ";font-size: " +
                              _vm.facade.stock_font_size / 2 +
                              "px;",
                          },
                          [_vm._v("\n              限量500份\n            ")]
                        ),
                      ]),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]
        : _vm.content.type === "circulationInfo"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "more flex align-items-c",
                            style:
                              "font-weight: " +
                              _vm.facade.moreFontWeight +
                              ";font-size: " +
                              _vm.facade.moreFontSize / 2 +
                              "px;color: " +
                              _vm.facade.moreColor +
                              ";padding: " +
                              _vm.facade.morePaddingTop / 2 +
                              "px " +
                              _vm.facade.morePaddingLeft / 2 +
                              "px;background: " +
                              _vm.facade.moreBgColor +
                              ";border-radius: " +
                              _vm.facade.moreRadius / 2 +
                              "px;",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.facade.moreText) +
                                "\n          "
                            ),
                            _vm.facade.moreIcon
                              ? _c("img", {
                                  attrs: { src: _vm.facade.moreIcon, alt: "" },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.facade.title) +
                            "\n\n        "
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "more flex align-items-c",
                            style:
                              "font-weight: " +
                              _vm.facade.moreFontWeight +
                              ";font-size: " +
                              _vm.facade.moreFontSize / 2 +
                              "px;color: " +
                              _vm.facade.moreColor +
                              ";padding: " +
                              _vm.facade.morePaddingTop / 2 +
                              "px " +
                              _vm.facade.morePaddingLeft / 2 +
                              "px;background: " +
                              _vm.facade.moreBgColor +
                              ";border-radius: " +
                              _vm.facade.moreRadius / 2 +
                              "px;",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.facade.moreText) +
                                "\n          "
                            ),
                            _vm.facade.moreIcon
                              ? _c("img", {
                                  attrs: { src: _vm.facade.moreIcon, alt: "" },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "record-list",
                    style:
                      "font-weight: " +
                      _vm.facade.mainFontWeight +
                      ";font-size: " +
                      _vm.facade.mainFontSize / 2 +
                      "px;color: " +
                      _vm.facade.mainColor +
                      ";padding-top: " +
                      _vm.facade.contentMarginTop / 2 +
                      "px;",
                  },
                  [
                    _c("div", { staticClass: "record" }, [
                      _c("div", { staticClass: "l" }, [
                        _c("div", {
                          staticClass: "yuan",
                          style:
                            "border-color: " + _vm.facade.circularColor + ";",
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "xian",
                          style: "background: " + _vm.facade.lineColor + ";",
                        }),
                        _vm._v(" "),
                        _vm._m(7),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "lb",
                            style:
                              "font-weight: " +
                              _vm.facade.subFontWeight +
                              ";font-size: " +
                              _vm.facade.subFontSize / 2 +
                              "px;color: " +
                              _vm.facade.subColor +
                              ";",
                          },
                          [
                            _vm._v(
                              "\n              2022-10-12 13:09:12\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "r" }, [_vm._v("取消寄售")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "record" }, [
                      _c("div", { staticClass: "l" }, [
                        _c("div", {
                          staticClass: "yuan",
                          style:
                            "border-color: " + _vm.facade.circularColor + ";",
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "xian",
                          style: "background: " + _vm.facade.lineColor + ";",
                        }),
                        _vm._v(" "),
                        _vm._m(8),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "lb",
                            style:
                              "font-weight: " +
                              _vm.facade.subFontWeight +
                              ";font-size: " +
                              _vm.facade.subFontSize / 2 +
                              "px;color: " +
                              _vm.facade.subColor +
                              ";",
                          },
                          [
                            _vm._v(
                              "\n              2022-10-12 13:09:12\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "r" }, [
                        _vm._v("寄售 ￥1200.00"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "record" }, [
                      _c("div", { staticClass: "l" }, [
                        _c("div", {
                          staticClass: "yuan",
                          style:
                            "border-color: " + _vm.facade.circularColor + ";",
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "xian",
                          style: "background: " + _vm.facade.lineColor + ";",
                        }),
                        _vm._v(" "),
                        _vm._m(9),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "lb",
                            style:
                              "font-weight: " +
                              _vm.facade.subFontWeight +
                              ";font-size: " +
                              _vm.facade.subFontSize / 2 +
                              "px;color: " +
                              _vm.facade.subColor +
                              ";",
                          },
                          [
                            _vm._v(
                              "\n              2022-10-12 13:09:12\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "r" }, [
                        _vm._v("买入 ￥1000.00"),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "notice"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.facade.title) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";",
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "\n          数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满14周岁的中国大陆用户购买。数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何商业用途。本商品一经售出，不支持退换。本商品源文件不支持本地下载。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用1。\n        "
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "playimage"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "item-flex-center",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("el-image", {
                      staticClass: "img",
                      style: {
                        borderWidth:
                          (_vm.facade.borderWidth
                            ? _vm.facade.borderWidth
                            : 0) /
                            2 +
                          "px",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                        fit: "contain",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm.content.type == "playtag"
        ? [
            _c(
              "div",
              {
                staticClass: "top-name-view",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.facade.marginLeft / 2 +
                  "px 0 " +
                  _vm.facade.marginLeft / 2 +
                  "px;background: " +
                  _vm.facade.background +
                  ";padding: 0 " +
                  _vm.facade.paddingLeft / 2 +
                  "px " +
                  _vm.facade.paddingbottom / 2 +
                  "px " +
                  _vm.facade.paddingLeft / 2 +
                  "px;\n    border-radius:" +
                  _vm.facade.topRadius / 2 +
                  "px " +
                  _vm.facade.topRadius / 2 +
                  "px " +
                  _vm.facade.bottomRadius / 2 +
                  "px " +
                  _vm.facade.bottomRadius / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tag-time item-flex-center",
                    style:
                      "padding-top:" + _vm.facade.timeMarginTop / 2 + "px;",
                  },
                  [
                    _vm.facade.tag_icon
                      ? _c("div", { staticClass: "img" }, [
                          _c("img", {
                            attrs: { src: _vm.facade.tag_icon, alt: "" },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "time",
                        style:
                          "color: " +
                          _vm.facade.timeColor +
                          ";font-size: " +
                          _vm.facade.timeFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n          2022-05-04 12:00:00 至 05-05 12:00:00\n        "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tagbox",
                    style: "padding-top:" + _vm.facade.tagMarginTop / 2 + "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        style:
                          "color: " +
                          _vm.facade.color +
                          ";font-size: " +
                          _vm.facade.fontSize / 2 +
                          "px;margin-right:" +
                          _vm.facade.marginRight / 2 +
                          "px;background-color: " +
                          _vm.facade.bgcolor +
                          ";border-radius: " +
                          _vm.facade.borderRadius / 2 +
                          "px;border-color: " +
                          _vm.facade.borderColor +
                          ";",
                      },
                      [_vm._v("\n          未选中玩法\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        style:
                          "color: " +
                          _vm.facade.selectColor +
                          ";font-size: " +
                          _vm.facade.fontSize / 2 +
                          "px;background-color: " +
                          _vm.facade.selectBgcolor +
                          ";border-radius: " +
                          _vm.facade.borderRadius / 2 +
                          "px;border-color: " +
                          _vm.facade.selectBgcolor +
                          ";",
                      },
                      [_vm._v("\n          选中玩法\n        ")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    style:
                      "color: " +
                      _vm.facade.descColor +
                      ";font-size: " +
                      _vm.facade.descFontSize / 2 +
                      "px;line-height: " +
                      _vm.facade.descLineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.descFontWeight +
                      ";padding-top:" +
                      _vm.facade.descMarginTop / 2 +
                      "px;",
                  },
                  [
                    _vm._v(
                      "\n        玩法说明：未来，人类对数码设备，脑机接口的极度依赖，沉溺于虚拟世界元宇宙而抵触现实。由此产生的新宗教，统称为数字信徒。脑机接口的极度依赖。\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type == "composemeta"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.facade.title) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "compose-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "compose-list",
                      style:
                        "border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;background: " +
                        _vm.facade.listBackground +
                        ";padding: " +
                        _vm.facade.listPadding / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compose-left",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221122/image/1669047881340194450.jpg!120a",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-right",
                          style:
                            "margin-left: " +
                            _vm.facade.listPadding / 2 +
                            "px;",
                        },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "compose-title flex-one line1",
                                style: "color:" + _vm.facade.nameColor,
                              },
                              [
                                _vm._v(
                                  "\n                A 商品标题\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "r-change flex align-items-c",
                                style: "color:" + _vm.facade.changeColor,
                              },
                              [
                                _c("span", [_vm._v("选择")]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "change-img",
                                  attrs: { src: _vm.facade.addImage },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "need flex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A+B+C需要500\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background: " +
                                      _vm.facade.needReachBgColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  持有614\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-list",
                      style:
                        "border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;background: " +
                        _vm.facade.listBackground +
                        ";padding: " +
                        _vm.facade.listPadding / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compose-left",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221122/image/1669047881340194450.jpg!120a",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-right",
                          style:
                            "margin-left: " +
                            _vm.facade.listPadding / 2 +
                            "px;",
                        },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "compose-title flex-one line1",
                                style: "color:" + _vm.facade.nameColor,
                              },
                              [
                                _vm._v(
                                  "\n                B 商品标题\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "r-change flex align-items-c",
                                style: "color:" + _vm.facade.changeColor,
                              },
                              [
                                _c("span", [_vm._v("已选择2个")]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "change-img",
                                  attrs: { src: _vm.facade.addImage },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "need flex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A+B+C需要500\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background: " +
                                      _vm.facade.needReachBgColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  持有614\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-list",
                      style:
                        "border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;background: " +
                        _vm.facade.listBackground +
                        ";padding: " +
                        _vm.facade.listPadding / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compose-left",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221122/image/1669047881340194450.jpg!120a",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-right",
                          style:
                            "margin-left: " +
                            _vm.facade.listPadding / 2 +
                            "px;",
                        },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "compose-title flex-one line1",
                                style: "color:" + _vm.facade.nameColor,
                              },
                              [
                                _vm._v(
                                  "\n                C 商品标题\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "need flex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A+B+C需要500\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background: " +
                                      _vm.facade.needReachBgColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  持有614\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "go-buy" }, [
                              _c(
                                "div",
                                { style: "color: " + _vm.facade.btnPopColor },
                                [_vm._v("数量不足")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "btn",
                                  style:
                                    "background: " +
                                    _vm.facade.btnBgColor +
                                    ";color: " +
                                    _vm.facade.btnColor,
                                },
                                [
                                  _vm._v(
                                    "\n                  去购买\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-pop",
                      style: "color:" + _vm.facade.popTitleColor,
                    },
                    [
                      _vm._v(
                        "\n          已选2件/条件已满足可立即合成\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-pop-desc",
                      style: "color:" + _vm.facade.popColor,
                    },
                    [_vm._v("\n          可随机合成列表中的一项\n        ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "compose-resultimage" }, [
                    _c("img", {
                      attrs: { src: _vm.facade.resultImage, alt: "" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-list",
                      style:
                        "border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;background: " +
                        _vm.facade.listBackground +
                        ";padding: " +
                        _vm.facade.listPadding / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compose-left",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221122/image/1669047881340194450.jpg!120a",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-right",
                          style:
                            "margin-left: " +
                            _vm.facade.listPadding / 2 +
                            "px;",
                        },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "compose-title flex-one line1",
                                style:
                                  "color:" +
                                  _vm.facade.nameColor +
                                  ";padding-top: 5px;",
                              },
                              [
                                _vm._v(
                                  "\n                商品标题\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "count flex" }, [
                            _c(
                              "div",
                              { style: "color: " + _vm.facade.txtColor + ";" },
                              [
                                _vm._v("\n                剩余"),
                                _c(
                                  "span",
                                  {
                                    style:
                                      "color: " + _vm.facade.numColor + ";",
                                  },
                                  [_vm._v("15699")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { style: "color: " + _vm.facade.txtColor + ";" },
                              [
                                _vm._v("\n                单次合成"),
                                _c(
                                  "span",
                                  {
                                    style:
                                      "color: " + _vm.facade.numColor + ";",
                                  },
                                  [_vm._v("2")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { style: "color: " + _vm.facade.txtColor + ";" },
                              [
                                _vm._v("\n                可合成数量"),
                                _c(
                                  "span",
                                  {
                                    style:
                                      "color: " + _vm.facade.numColor + ";",
                                  },
                                  [_vm._v("2/2")]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "item-flex-center", staticStyle: { width: "100%" } },
      [
        _c("img", {
          staticClass: "img",
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "img" }, [
        _c("img", {
          attrs: {
            src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221125/image/1669382228077203165.png",
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("实物赠送")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "img" }, [
        _c("img", {
          attrs: {
            src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221125/image/1669382228077203165.png",
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("互动体验")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "img" }, [
        _c("img", {
          attrs: {
            src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221125/image/1669382228077203165.png",
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("生成头像")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "img" }, [
        _c("img", {
          attrs: {
            src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221125/image/1669382228077203165.png",
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("360°鉴赏")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "img" }, [
        _c("img", {
          attrs: {
            src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221125/image/1669382228077203165.png",
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("区块链证书")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        staticClass: "main-img",
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("一双很漂亮的鞋")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t" }, [_c("span", [_vm._v("数字信徒")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t" }, [_c("span", [_vm._v("数字信徒")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t" }, [_c("span", [_vm._v("数字信徒")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }