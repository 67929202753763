"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _menuComponent = _interopRequireDefault(require("@/views/component/menuComponent.vue"));
//
//
//
//
var _default = exports.default = {
  components: {
    menuComponent: _menuComponent.default
  },
  data: function data() {
    return {
      tabsList: [{
        label: '客户列表',
        path: '/merchant/saas/saasUser/index'
      }, {
        label: '统计报表',
        path: '/merchant/saas/saasUser/statement'
      }]
    };
  },
  computed: {},
  mounted: function mounted() {},
  methods: {}
};