var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container workDetails",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品类型：", prop: "fileType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.editFlag },
                      on: { change: _vm.fileTypeChange },
                      model: {
                        value: _vm.workDetail.fileType,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "fileType", $$v)
                        },
                        expression: "workDetail.fileType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("图片"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [_vm._v("3D")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("视频"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "4" } }, [
                        _vm._v("音频"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w120 fontW400 ml40 ft14",
                  staticStyle: { color: "#606266", display: "inline-block" },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v("商品封面：\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center mb20",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "image")
                        },
                      },
                    },
                    [
                      _vm.workDetail.image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.workDetail.image
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: _vm.workDetail.image + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ft14" },
                    [
                      _vm._v("\n          列表页\n          "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "列表页的展示图",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center mb20 ml10",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "infoImage")
                        },
                      },
                    },
                    [
                      _vm.workDetail.infoImage
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("infoImage")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.workDetail.infoImage
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: _vm.workDetail.infoImage + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ft14" },
                    [
                      _vm._v("\n          详情页\n          "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "详情页展示图，如不传则使用列表页图",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ft14 ml20",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _vm._v(
                    "\n        (图片格式支持：JPG、JPEG、PNG、GIF；文件大小≤15M；图片比例建议1：1)\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.workDetail.fileType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商品文件", prop: "file" } },
                    [
                      _c("div", { staticClass: "flex align-items-c" }, [
                        _c(
                          "div",
                          { staticClass: "upload-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "92px", height: "32px" },
                                attrs: { type: "primary" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      position: "relative",
                                      "font-size": "12px",
                                      left: "-4px",
                                      top: "-6px",
                                    },
                                  },
                                  [_vm._v("选择文件夹")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  ref: "file",
                                  staticStyle: {
                                    opacity: "0",
                                    cursor: "pointer",
                                    position: "relative",
                                    top: "-12px",
                                    left: "-85px",
                                    width: "92px",
                                    height: "32px",
                                  },
                                  attrs: {
                                    id: "file1",
                                    disabled: _vm.editFlag,
                                    type: "file",
                                    webkitdirectory: "",
                                    directory: "",
                                    multiple: "multiple",
                                  },
                                  on: { change: _vm.uploadAllImg },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.workDetail.file
                          ? _c(
                              "span",
                              { staticClass: "ml10 color-gray ft12" },
                              [_vm._v("已上传")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.workDetail.file
                          ? _c(
                              "span",
                              { staticClass: "ml10 color-gray ft12" },
                              [_vm._v("未上传")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10 color-gray ft12" }, [
                          _vm._v("(请上传包含 .gltf 文件的文件夹)"),
                        ]),
                      ]),
                    ]
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "商品文件：", prop: "file" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c(
                                "el-upload",
                                {
                                  ref: "uploadImg",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action: _vm.fileUrl,
                                    headers: _vm.myHeaders,
                                    "show-file-list": false,
                                    multiple: "",
                                    "http-request": _vm.uploadALiActionScanFile,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [_vm._v("上传预览版")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workDetail.scanFile &&
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c("span", { staticClass: "color-gray ft12" }, [
                                _vm._v("已上传"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.workDetail.scanFile &&
                          _vm.workDetail.fileType != 1 &&
                          _vm.workDetail.fileType != 2
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("未上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              ref: "uploadImg",
                              staticClass: "upload-demo",
                              class:
                                _vm.workDetail.fileType != 1 &&
                                _vm.workDetail.fileType != 2
                                  ? "ml20"
                                  : null,
                              attrs: {
                                action: _vm.fileUrl,
                                headers: _vm.myHeaders,
                                "show-file-list": false,
                                multiple: "",
                                "http-request": _vm.uploadALiActionFile,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.workDetail.fileType == 1
                                        ? "上传源文件"
                                        : "上传完整版"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.workDetail.file
                            ? _c("span", { staticClass: "color-gray ft12" }, [
                                _vm._v("已上传"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.workDetail.file
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("未上传")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
              _vm._v(" "),
              _vm.workDetail.fileType != 2
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "showMetohd", label: "详情图片展示" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.workDetail.showMetohd,
                            callback: function ($$v) {
                              _vm.$set(_vm.workDetail, "showMetohd", $$v)
                            },
                            expression: "workDetail.showMetohd",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("动态展示"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("静态展示"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml40",
                          staticStyle: { color: "#606266" },
                        },
                        [_vm._v("(选择动态展示，详情页展示图片3D左右晃动)")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称", prop: "storeName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入商品名称",
                              disabled: _vm.editFlag,
                            },
                            model: {
                              value: _vm.workDetail.storeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "storeName", $$v)
                              },
                              expression: "workDetail.storeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属分类", prop: "categoryId" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              clearable: "",
                              options: _vm.categoryList,
                              props: {
                                expandTrigger: "hover",
                                value: "id",
                                label: "label",
                              },
                            },
                            model: {
                              value: _vm.workDetail.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "categoryId", $$v)
                              },
                              expression: "workDetail.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品标签", prop: "tagIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                multiple: "",
                                placeholder: "请选择所属标签",
                              },
                              model: {
                                value: _vm.workDetail.tagIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "tagIds", $$v)
                                },
                                expression: "workDetail.tagIds",
                              },
                            },
                            _vm._l(_vm.tagList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品属性", prop: "attr" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: { placeholder: "商品属性", clearable: "" },
                              model: {
                                value: _vm.workDetail.attr,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "attr", $$v)
                                },
                                expression: "workDetail.attr",
                              },
                            },
                            _vm._l(_vm.attrList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { value: item.id, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "供应方", prop: "issuerId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                placeholder: "请选择供应方",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.workDetail.issuerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "issuerId", $$v)
                                },
                                expression: "workDetail.issuerId",
                              },
                            },
                            _vm._l(_vm.issuerArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticClass: "mt10",
                              attrs: { placeholder: "分账比例（数字）" },
                              model: {
                                value: _vm.workDetail.issuerSharingRatio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "issuerSharingRatio",
                                    $$v
                                  )
                                },
                                expression: "workDetail.issuerSharingRatio",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "品牌", prop: "authorId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                placeholder: "请选择品牌",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.workDetail.authorId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "authorId", $$v)
                                },
                                expression: "workDetail.authorId",
                              },
                            },
                            _vm._l(_vm.authorArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticClass: "mt10",
                              attrs: { placeholder: "分账比例（数字）" },
                              model: {
                                value: _vm.workDetail.authorSharingRatio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "authorSharingRatio",
                                    $$v
                                  )
                                },
                                expression: "workDetail.authorSharingRatio",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品编号", prop: "numberPrefix" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入商品编号",
                              disabled: _vm.editFlag,
                            },
                            model: {
                              value: _vm.workDetail.numberPrefix,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "numberPrefix", $$v)
                              },
                              expression: "workDetail.numberPrefix",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "库存", prop: "stock" } },
                        [
                          _c(
                            "div",
                            { staticClass: "align-items-c flex" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入库存",
                                  disabled: _vm.workDetail.isShow,
                                },
                                model: {
                                  value: _vm.workDetail.stock,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "stock",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "workDetail.stock",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售时间", prop: "startTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: "请选择发售时间",
                              align: "right",
                              disabled: _vm.workDetail.isIssuance,
                            },
                            model: {
                              value: _vm.workDetail.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "startTime", $$v)
                              },
                              expression: "workDetail.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "限购数量",
                            prop: "purchaseQuantity",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "每个用户的限购数量",
                            },
                            model: {
                              value: _vm.workDetail.purchaseQuantity,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workDetail,
                                  "purchaseQuantity",
                                  _vm._n($$v)
                                )
                              },
                              expression: "workDetail.purchaseQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "单次限购", prop: "oneTimeBuyLimit" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "单个订单可购数量",
                            },
                            model: {
                              value: _vm.workDetail.oneTimeBuyLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workDetail,
                                  "oneTimeBuyLimit",
                                  _vm._n($$v)
                                )
                              },
                              expression: "workDetail.oneTimeBuyLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售价格", prop: "price" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: "number",
                                placeholder: "请输入发售价格",
                                disabled: _vm.editFlag,
                              },
                              on: { input: _vm.changePrice },
                              model: {
                                value: _vm.workDetail.price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "price", _vm._n($$v))
                                },
                                expression: "workDetail.price",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠价格", prop: "vipPrice" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: "number",
                                placeholder: "请输入优惠价格",
                                disabled: _vm.editFlag,
                              },
                              on: { input: _vm.changePrice1 },
                              model: {
                                value: _vm.workDetail.vipPrice,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "vipPrice",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "workDetail.vipPrice",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分抵扣：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.workDetail.pointsDeductionType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "pointsDeductionType",
                                    $$v
                                  )
                                },
                                expression: "workDetail.pointsDeductionType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("抵扣"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("兑换"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "积分消耗：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("span", [_vm._v("积分类型：")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w200" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "选择积分类型" },
                                    model: {
                                      value: _vm.workDetail.scoreTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "scoreTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.scoreTypeId",
                                    },
                                  },
                                  _vm._l(_vm.typeList, function (m, i) {
                                    return _c("el-option", {
                                      key: "type" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("积分数量："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticClass: "w200",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.workDetail.pointsDeduction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "pointsDeduction",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.pointsDeduction",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("积分"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("动态积分数量"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "ml10" },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#ccc",
                                  },
                                  model: {
                                    value: _vm.workDetail.hasAutoScore,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "hasAutoScore",
                                        $$v
                                      )
                                    },
                                    expression: "workDetail.hasAutoScore",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w200 ml20" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: !_vm.workDetail.hasAutoScore,
                                    },
                                    model: {
                                      value: _vm.workDetail.autoScoreType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "autoScoreType",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.autoScoreType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        value: 1,
                                        label: "按寄售市场最低价",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        value: 2,
                                        label: "按寄售市场均价",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticClass: "w200 ml10",
                                attrs: {
                                  type: "number",
                                  disabled: !_vm.workDetail.hasAutoScore,
                                },
                                model: {
                                  value: _vm.workDetail.autoCoefficient,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "autoCoefficient",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.autoCoefficient",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("倍"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分使用人群：" } },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              { staticStyle: { width: "400px" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "选择名单",
                                      clearable: "",
                                      multiple: "",
                                    },
                                    model: {
                                      value: _vm.workDetail.airdropIds,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "airdropIds",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.airdropIds",
                                    },
                                  },
                                  _vm._l(_vm.nameListsList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        label: item.name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "ml10",
                                staticStyle: {
                                  color: "#606266",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("（不选为全部用户可用）")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-row"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "绑定数藏" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { input: _vm.changeBandingNft },
                              model: {
                                value: _vm.workDetail.isBandingNft,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "isBandingNft", $$v)
                                },
                                expression: "workDetail.isBandingNft",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不绑定"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("绑定数藏  实物确定收货后,自动空投数藏"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.workDetail.isBandingNft == 1 &&
                          !_vm.selectCollection.length
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "el-icon-plus",
                                  attrs: { type: "text" },
                                  on: { click: _vm.addCollection },
                                },
                                [
                                  _vm._v(
                                    "\n              选择数字藏品\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectCollection.length
                            ? _c(
                                "el-table",
                                { attrs: { data: _vm.selectCollection } },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "图片" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("img", {
                                                staticClass: "w50 h50",
                                                attrs: {
                                                  src: scope.row
                                                    .bandProductImage,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1709232285
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "商品名称",
                                      prop: "bandProductName",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { label: "操作" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    underline: false,
                                                    type: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.selectCollection = []
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2686513759
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情介绍", prop: "description" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.workDetail.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "description", $$v)
                      },
                      expression: "workDetail.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1", showSelect: true },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }