"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: [{
        name: 'NFT组件配置',
        id: 315,
        link: '',
        editLink: '/merchant/basics/page?type=nft_component'
      }, {
        name: 'NFT详情配置',
        id: 315,
        link: '/pages/nftCollection/index?id=',
        editLink: '/merchant/basics/page?type=nft_new_detail_page'
      }, {
        name: 'NFT盲盒配置',
        id: 315,
        link: '/pages/nftCollection/nftbox?id=',
        editLink: '/merchant/basics/page?type=nft_box_detail_page_test'
      }, {
        name: '合成活动配置',
        id: 315,
        link: '/pages/marketing/compose/compose?id=',
        editLink: '/merchant/basics/page?type=compose_page'
      }, {
        name: '个人中心页',
        id: 315,
        link: '/pages/users/index',
        editLink: '/merchant/mine/page'
      }, {
        name: '登录页',
        id: 315,
        link: '/login',
        editLink: '/merchant/basics/page?type=login'
      }],
      listLoading: false,
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  mounted: function mounted() {
    // this.load();
  },
  methods: {
    load: function load() {
      this.listLoading = true;
      // diypageList(this.queryParams).then((response) => {
      //   this.total = response.data.count;
      //   this.list = response.data.list;
      //   this.listLoading = false;
      // });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    createdDiy: function createdDiy() {
      this.$router.push({
        path: '/merchant/diy/page'
      });
    },
    editDiy: function editDiy(link) {
      this.$router.push({
        path: link
      });
    }
  }
};