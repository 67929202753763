"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.number.constructor");
var _frame = require("@/api/frame");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "addFrame",
  data: function data() {
    return {
      imgInfo: {},
      hList: [{
        label: '15*20cm',
        width: 15,
        height: 20
      }, {
        label: '28*35cm',
        width: 28,
        height: 35
      }, {
        label: '30*40cm',
        width: 30,
        height: 40
      }, {
        label: '45*60cm',
        width: 45,
        height: 60
      }, {
        label: '60*80cm',
        width: 60,
        height: 80
      }, {
        label: '75*100cm',
        width: 75,
        height: 100
      }, {
        label: '100*132cm',
        width: 100,
        height: 132
      }, {
        label: '107*142cm',
        width: 107,
        height: 142
      }],
      sList: [{
        label: '20*15cm',
        width: 20,
        height: 15
      }, {
        label: '35*28cm',
        width: 35,
        height: 28
      }, {
        label: '40*30cm',
        width: 40,
        height: 30
      }, {
        label: '60*45cm',
        width: 60,
        height: 45
      }, {
        label: '80*60cm',
        width: 80,
        height: 60
      }, {
        label: '100*75cm',
        width: 100,
        height: 75
      }, {
        label: '132*100cm',
        width: 132,
        height: 100
      }, {
        label: '142*107cm',
        width: 142,
        height: 107
      }],
      zList: [{
        label: '30*30cm',
        width: 30,
        height: 30
      }, {
        label: '40*40cm',
        width: 40,
        height: 40
      }, {
        label: '50*50cm',
        width: 50,
        height: 50
      }, {
        label: '60*60cm',
        width: 60,
        height: 60
      }, {
        label: '70*70cm',
        width: 70,
        height: 70
      }, {
        label: '80*80cm',
        width: 80,
        height: 80
      }, {
        label: '90*90cm',
        width: 90,
        height: 90
      }, {
        label: '100*100cm',
        width: 100,
        height: 100
      }],
      frameList: [],
      dragRow: {},
      domWidth: 0,
      top: 0,
      left: 0,
      comNum: 5
      // sizeVal: 100,
    };
  },
  created: function created() {
    this.imgInfo = this.$route.params;
  },
  mounted: function mounted() {
    var _this = this;
    console.log(this.imgInfo);
    if (this.imgInfo.image) {
      this.frameList = this.imgInfo.pos.map(function (item) {
        var obj = JSON.parse(item.info);
        _this.comNum = Number(obj.comNum);
        return {
          label: obj.label,
          startWid: Number(obj.startWid),
          startHei: Number(obj.startHei),
          width: item.w,
          height: item.h,
          top: item.y,
          left: item.x
        };
      });
      this.$nextTick(function () {
        _this.getSize();
      });
    }
  },
  methods: {
    getSize: function getSize() {
      this.domWidth = this.$refs.flexRight.clientWidth - 40;
      var rect = this.$refs.draggableBox.getBoundingClientRect();
      this.top = rect.top;
      this.left = rect.left;
    },
    onDragStart: function onDragStart(e, row) {
      this.dragRow = row;
      this.isItem = false;
      e.dataTransfer.effectAllowed = "move";
    },
    onDragover: function onDragover(event) {
      event.preventDefault();
    },
    onDrop: function onDrop(e) {
      e.preventDefault();
      if (Object.keys(this.dragRow).length == 0) return false;
      if (this.frameList.length > 4) {
        this.$message.error("最多只能加五副");
        return false;
      }
      var pageX = e.pageX,
        pageY = e.pageY;
      var model = {
        label: this.dragRow.label,
        startWid: this.dragRow.width,
        startHei: this.dragRow.height,
        width: this.dragRow.width * this.comNum,
        height: this.dragRow.height * this.comNum,
        top: pageY - this.top - 30,
        left: pageX - this.left
      };
      this.frameList.push(model);
      this.dragRow = {};
    },
    handleStart: function handleStart(e, row, index) {
      var that = this;
      var isDragging = false;
      var docuDom = this.$refs.draggableBox;
      var _ref = [row.top, row.left],
        top = _ref[0],
        left = _ref[1];
      // 获取鼠标落下时的坐标
      var _ref2 = [e.clientX, e.clientY],
        cursorX = _ref2[0],
        cursorY = _ref2[1];
      var moveHandler = function moveHandler(e) {
        if (!isDragging) return;
        // 鼠标新坐标
        var _ref3 = [e.clientX, e.clientY],
          x = _ref3[0],
          y = _ref3[1];
        // 鼠标相对偏移量
        var offsetX = x - cursorX,
          offsetY = y - cursorY;
        // 元素新位置
        var nextLeft = left + offsetX,
          nextTop = top + offsetY;
        that.frameList[index] = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
          top: nextTop,
          left: nextLeft
        });
        that.$forceUpdate();
      };
      var _upHandler = function upHandler() {
        isDragging = false;
        docuDom.style.cursor = 'grab';
        document.removeEventListener('mousemove', moveHandler);
        document.removeEventListener('mouseup', _upHandler);
      };
      isDragging = true;
      docuDom.style.cursor = 'grabbing';
      document.addEventListener('mousemove', moveHandler);
      document.addEventListener('mouseup', _upHandler);
    },
    addSize: function addSize() {
      var _this2 = this;
      this.comNum = this.comNum + 1;
      this.frameList.forEach(function (item) {
        item.width = item.startWid * _this2.comNum;
        item.height = item.startHei * _this2.comNum;
      });
      this.$forceUpdate();
    },
    reduceSize: function reduceSize() {
      var _this3 = this;
      this.comNum = this.comNum - 1;
      this.frameList.forEach(function (item) {
        item.width = item.startWid * _this3.comNum;
        item.height = item.startHei * _this3.comNum;
      });
      this.$forceUpdate();
    },
    onSave: function onSave() {
      var _this4 = this;
      var model = {
        wide: this.$refs.draggableImg.clientWidth,
        high: this.$refs.draggableImg.clientHeight,
        image: this.imgInfo.image,
        type: this.frameList.length,
        pos: this.frameList.map(function (item) {
          return {
            w: item.width,
            h: item.height,
            x: item.left,
            y: item.top,
            info: JSON.stringify({
              label: item.label,
              startWid: item.startWid,
              startHei: item.startHei,
              comNum: _this4.comNum
            })
          };
        })
      };
      if (this.imgInfo.id) {
        model.id = this.imgInfo.id;
        (0, _frame._updateModel)(model).then(function (res) {
          _this4.$message.success("操作成功");
          _this4.backAction();
        });
      } else {
        (0, _frame._createModel)(model).then(function (res) {
          _this4.$message.success("操作成功");
          _this4.backAction();
        });
      }
    },
    modalPicTap: function modalPicTap() {
      var that = this;
      this.$modalUpload(function (img) {
        that.imgInfo.image = img[0];
        that.$forceUpdate();
        that.$nextTick(function () {
          that.getSize();
        });
      });
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};