var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "订单信息",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _vm.orderDatalist
                    ? _c(
                        "div",
                        { staticClass: "description" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("用户信息"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "用户昵称：" +
                                  _vm._s(
                                    _vm.orderDatalist.user
                                      ? _vm.orderDatalist.user.nickname
                                      : ""
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "收货人：" + _vm._s(_vm.orderDatalist.real_name)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "联系电话：" +
                                  _vm._s(_vm.orderDatalist.user_phone)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "收货地址：" +
                                  _vm._s(_vm.orderDatalist.user_address)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c("div", { staticClass: "title" }, [
                            _vm._v("收货信息"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "订单编号：" +
                                  _vm._s(_vm.orderDatalist.order_sn)
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "description-term flex align-items-c",
                                staticStyle: { display: "flex" },
                              },
                              [
                                _vm._v("订单状态："),
                                _vm.orderDatalist.status == -2
                                  ? [_c("div", [_vm._v("已取消")])]
                                  : _vm.orderDatalist.status == -1
                                  ? [_c("div", [_vm._v("已退款")])]
                                  : _vm.orderDatalist.is_refund !== 0
                                  ? [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("售后中")]
                                      ),
                                    ]
                                  : _vm.orderDatalist.status == -3 ||
                                    _vm.orderDatalist.status == 0
                                  ? [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("待结算")]
                                      ),
                                    ]
                                  : _vm.orderDatalist.status == -4
                                  ? [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("转账待审核")]
                                      ),
                                    ]
                                  : _vm.orderDatalist.delivery_type == 2
                                  ? [_c("div", [_vm._v("待自提")])]
                                  : _vm.orderDatalist.delivery_type == 3
                                  ? [_c("div", [_vm._v("待他人提货")])]
                                  : _vm.orderDatalist.status == 1
                                  ? [_c("div", [_vm._v("待发货")])]
                                  : _vm.orderDatalist.status == 2
                                  ? [_c("div", [_vm._v("待收货")])]
                                  : _vm.orderDatalist.status == 3
                                  ? [_c("div", [_vm._v("待评价")])]
                                  : _vm.orderDatalist.status == 4
                                  ? [_c("div", [_vm._v("已完成")])]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "商品总数：" +
                                  _vm._s(_vm.orderDatalist.total_num)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "商品总价：" +
                                  _vm._s(_vm.orderDatalist.total_price)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "交付邮费：" +
                                  _vm._s(_vm.orderDatalist.total_postage)
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.orderDatalist.coupon_id &&
                            _vm.orderDatalist.coupon_id !== "0"
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "优惠券优惠金额：" +
                                      _vm._s(_vm.orderDatalist.coupon_price)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDatalist.use_integral_num
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "使用" +
                                      _vm._s(
                                        _vm.orderDatalist.use_integral_num
                                      ) +
                                      "积分减免：" +
                                      _vm._s(
                                        _vm.orderDatalist
                                          .integral_discount_price
                                      )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "实际支付：" +
                                  _vm._s(
                                    _vm.orderDatalist.finalOrder
                                      ? parseFloat(
                                          _vm.orderDatalist.finalOrder.pay_price
                                        ) +
                                          parseFloat(
                                            _vm.orderDatalist.pay_price
                                          )
                                      : _vm.orderDatalist.pay_price
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "创建时间：" +
                                  _vm._s(_vm.orderDatalist.create_time)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "支付方式：" +
                                  _vm._s(
                                    _vm._f("payTypeFilter")(
                                      _vm.orderDatalist.pay_type
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "商家备注：" + _vm._s(_vm.orderDatalist.remark)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.orderDatalist.delivery_type === "1"
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("物流信息"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "快递公司：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_name
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "\n              快递单号：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_id
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: { click: _vm.openLogistics },
                                        },
                                        [_vm._v("物流查询")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: { click: _vm.editLogistics },
                                        },
                                        [_vm._v("修改物流")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.orderDatalist.delivery_type === "2"
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("配送信息"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "送货人姓名：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_name
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "送货人电话：" +
                                          _vm._s(_vm.orderDatalist.delivery_id)
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.orderDatalist.mark
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("用户备注"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [_vm._v(_vm._s(_vm.orderDatalist.mark))]
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流查询",
            visible: _vm.dialogLogistics,
            width: "350px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLogistics = $event
            },
          },
        },
        [
          _vm.orderDatalist
            ? _c("logistics-from", {
                attrs: {
                  "order-datalist": _vm.orderDatalist,
                  result: _vm.result,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }