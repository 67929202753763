"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order");
var _open3DOrder = require("@/api/open3DOrder");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  filters: {
    formatStatus: function formatStatus(val) {
      var res = '';
      switch (val) {
        case -3:
          res = '售后中';
          break;
        case -2:
          res = '已退款';
          break;
        case -1:
          res = '已取消';
          break;
        case 0:
          res = '待付款';
          break;
        case 1:
          res = '已完成';
          break;
        case 2:
          res = '支付审核中';
          break;
        case 3:
          res = '待交付';
          break;
        case 4:
          res = '已完成';
          break;
        default:
          break;
      }
      return res;
    }
  },
  data: function data() {
    return {
      formatPriceNumber: _utils.formatPriceNumber,
      showdetail: false,
      uid: '',
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      listLoading: true,
      queryParams: {
        productType: '',
        status: '',
        timeType: '',
        pageNum: 1,
        pageSize: 20,
        hasInvoice: '',
        hasContract: ''
      },
      pointsDeductionList: [
      // { value: null, label: '不限积分抵扣' },
      {
        value: true,
        label: '使用积分'
      }, {
        value: false,
        label: '未使用积分'
      }],
      couponDeductionList: [{
        value: null,
        label: '不限优惠券抵扣'
      }, {
        value: true,
        label: '使用优惠券'
      }, {
        value: false,
        label: '未使用优惠券'
      }],
      timeType: [],
      timeList: [{
        text: '全部',
        val: ''
      }, {
        text: '今天',
        val: 'today'
      }, {
        text: '昨天',
        val: 'yesterday'
      }, {
        text: '最近7天',
        val: 'lately7'
      }, {
        text: '最近30天',
        val: 'lately30'
      }, {
        text: '本月',
        val: 'month'
      }, {
        text: '本年',
        val: 'year'
      }],
      payList: [],
      chkName: '',
      visibleRefund: false,
      refundForm: {
        orderId: '',
        refundPrice: '',
        refundReasonWapExplain: ''
      },
      refundRules: {
        refundPrice: [{
          required: true,
          message: '请输入退款金额',
          trigger: 'blur'
        }]
      },
      refundRow: null,
      visibleShip: false,
      shipTitle: '',
      shipForm: {
        id: '',
        deliveryCompanyId: '',
        deliveryId: ''
      },
      shipRules: {
        deliveryCompanyId: [{
          required: true,
          message: '请选择快递公司',
          trigger: 'change'
        }],
        deliveryId: [{
          required: true,
          message: '请输入快递单号',
          trigger: 'blur'
        }]
      },
      dcList: []
    };
  },
  mounted: function mounted() {
    this.getList(1);
    this.getPayTypeList();
    // this.deliveryCompanyList();
  },
  methods: {
    confirmShipAction: function confirmShipAction(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _order.shipOrderApi)(_this.shipForm).then(function () {
            _this.$message.success('操作成功');
            _this.visibleShip = false;
            _this.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    confirmRefundAction: function confirmRefundAction(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (Number(_this2.refundForm.refundPrice) > Number(_this2.refundForm.totalPrice)) {
            _this2.$message.error('退款金额不能大于订单金额');
            return false;
          }
          _this2.refundForm.refundPrice = Number(_this2.refundForm.refundPrice);
          (0, _open3DOrder.applyRefundApi)(_this2.refundForm).then(function () {
            _this2.$message.success('操作成功');
            _this2.visibleRefund = false;
            if (formName === 'refundForm') {
              _this2.getList();
            } else {
              _this2.getList(1);
            }
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    exportOrder: function exportOrder() {
      var _this3 = this;
      (0, _open3DOrder.exportApi)(this.queryParams).then(function (res) {
        _this3.$message.success('操作成功');
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(105);
    },
    receiptCopy: function receiptCopy(row) {
      var text = "\u6536\u4EF6\u4EBA\uFF1A".concat(row.receiptName, "\uFF0C \u624B\u673A\uFF1A").concat(row.receiptPhone, "\uFF0C\u5730\u5740\uFF1A").concat(row.receiptAddress);
      this.copy(text);
    },
    deliveryCopy: function deliveryCopy(row) {
      var text = "\u5FEB\u9012\u5355\u53F7\uFF1A".concat(row.deliveryId);
      this.copy(text);
    },
    copy: function copy(copyText) {
      var input = document.createElement('input'); // 直接构建input
      input.value = copyText; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('操作成功');
    },
    getPayTypeList: function getPayTypeList() {
      var _this4 = this;
      (0, _open3DOrder.getPayType)().then(function (res) {
        // console.log(res)
        _this4.payList = res.data;
      }).catch(function (res) {});
    },
    deliveryCompanyList: function deliveryCompanyList() {
      var _this5 = this;
      (0, _order.deliveryCompanyListApi)({
        pageNum: 1,
        pageSize: 20
      }).then(function (res) {
        // console.log(res)
        _this5.dcList = res.rows;
      }).catch(function (res) {});
    },
    // 取消订单
    cancelOrderAction: function cancelOrderAction(id) {
      var _this6 = this;
      this.$confirm('确定取消此订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.merchantOrderCloseListApi)(id).then(function (res) {
          _this6.$message.success('操作成功');
          _this6.getList(1);
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      }).catch(function () {});
    },
    // 退款
    refundAction: function refundAction(row) {
      this.refundRow = row;
      this.refundForm = {
        orderId: row.id,
        refundPrice: '',
        totalPrice: row.totalPrice
      };
      this.visibleRefund = true;
    },
    selectChange: function selectChange(tab) {
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.date = e ? this.timeType.join('-') : '';
      this.queryParams.timeType = this.queryParams.date;
      this.getList(1);
    },
    // 发货
    send: function send(id) {
      this.shipForm = {
        id: id,
        deliveryCompanyId: '',
        deliveryId: ''
      };
      this.visibleShip = true;
    },
    editDeliveryAction: function editDeliveryAction(row) {
      this.shipForm.id = row.id;
      this.visibleShip = true;
      this.shipForm.deliveryCompanyId = String(row.deliveryCompanyId);
      this.shipForm.deliveryId = row.deliveryId;
    },
    // 列表
    getList: function getList(num) {
      var _this7 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _order.merchantOrderListApi)(this.queryParams).then(function (res) {
        _this7.tableData.data = res.rows;
        _this7.tableData.total = res.total;
        _this7.tableData.statistical = res.statistical;
        // if (this.tableData.statistical !== null) {
        //   this.tableData.statistical['all'] =
        //     this.tableData.statistical.overCount +
        //     this.tableData.statistical.closeCount +
        //     this.tableData.statistical.waitCount +
        //     this.tableData.statistical.refundCount;
        // }
        // this.tableData.data.forEach(element => {
        //   element.payType = this.payTypeMethod(element.payType);
        // });
        _this7.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    payTypeMethod: function payTypeMethod(payType) {
      var payTypeText = '';
      this.payList.forEach(function (item) {
        if (item.id == payType) {
          payTypeText = item.name;
        }
      });
      return payTypeText;
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList('');
    },
    showDetail: function showDetail(uid) {
      this.uid = uid;
      this.showdetail = true;
    }
  }
};