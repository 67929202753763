"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _mealDetail = _interopRequireDefault(require("./mealDetail.vue"));
var _saasApi = require("@/api/saasApi");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    mealDetail: _mealDetail.default
  },
  data: function data() {
    return {
      queryParams: {
        systemType: 1,
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
      tableData: [],
      productList: [],
      editPackageInfo: null,
      drawerVisib: false
    };
  },
  created: function created() {
    this.getTableList();
  },
  mounted: function mounted() {},
  methods: {
    getTableList: function getTableList() {
      var _this = this;
      (0, _saasApi.productPackage)(this.queryParams).then(function (res) {
        if (res.rows.length) {
          _this.total = res.total;
          _this.tableData = res.rows.map(function (item) {
            var productStr = item.relationProductItem.map(function (item) {
              return item.productName;
            }).join("+");
            var priceStr = item.packagePrice.map(function (item) {
              var str = "".concat(item.price, "\u5143-").concat(item.number, "\u5E74");
              return str;
            }).join(", ");
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              productStr: productStr,
              priceStr: priceStr
            });
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    addProject: function addProject(row) {
      var _this2 = this;
      (0, _saasApi.saasProductList)({
        systemType: 1
      }).then(function (res) {
        _this2.drawerVisib = true;
        res.rows.forEach(function (item) {
          return _this2.$set(item, 'isShow', true);
        });
        _this2.productList = (0, _utils.deepClone)(res.rows);
        _this2.editPackageInfo = row ? (0, _utils.deepClone)(row) : null;
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeDrawerVisib: function changeDrawerVisib(bool) {
      this.drawerVisib = bool;
      this.getTableList();
    },
    pageChange: function pageChange(val) {
      this.queryParams.pageNum = val;
      this.getTableList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNum = 1;
      this.getTableList();
    }
  }
};