var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": "2",
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("模版")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("框类型")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [_vm._v("框卡纸")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [_vm._v("场景")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [_vm._v("画框")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-20" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { input: _vm.changeType },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("画框类型"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("场景类型"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-20" },
            [
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "请输入类型名称" },
                model: {
                  value: _vm.tagName,
                  callback: function ($$v) {
                    _vm.tagName = $$v
                  },
                  expression: "tagName",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  attrs: { type: "primary", size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addtag($event)
                    },
                  },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default-box" },
            [
              _c(
                "el-table",
                {
                  staticClass: "mb-20",
                  attrs: { data: _vm.lists, border: "" },
                },
                [
                  _c("el-table-column", { attrs: { label: "ID", prop: "id" } }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "标签名称", prop: "categoryName" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "排序" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "60px" },
                              on: {
                                blur: function ($event) {
                                  return _vm.changeSort(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.sort,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sort", $$v)
                                },
                                expression: "scope.row.sort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-popconfirm", {
                              attrs: { title: "确定删除吗?" },
                              on: {
                                confirm: function ($event) {
                                  return _vm.deltag(scope.row)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "m2",
                                            attrs: {
                                              type: "error",
                                              size: "small",
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  total: _vm.total,
                  "page-size": _vm.pageConfig.pageSize,
                  "show-sizer": "show-sizer",
                  "show-total": "show-total",
                  "show-elevator": "show-elevator",
                  "current-page": _vm.pageConfig.pageNum,
                },
                on: {
                  "size-change": _vm.changePageSize,
                  "current-change": _vm.changePage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }