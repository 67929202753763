"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _animal = require("@/api/animal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "GameImg",
  components: {
    colour: _colour.default
  },
  data: function data() {
    return {
      ruleForm: {
        homeBg: "",
        homeTop: "",
        homeTopArrowRight: "",
        innerBg: "",
        innerDecoration: "",
        innerBtn: "",
        incubationBg: "",
        incubationBack: "",
        incubationProgress: "",
        incubationSlotBg: "",
        incubationSlotList: "",
        incubationSlotListNoWrapper: "",
        incubationSlotAdd: "",
        incubationSlotUnlock: "",
        incubationSlotBtnBg: "",
        incubationSlotUnlockBg: "",
        incubationSlotInfoBg: "",
        incubationSlotDelist: "",
        incubationSlotSupplement: "",
        incubationPopup: "",
        incubationClose: "",
        incubationTitleBg: "",
        incubationRuleBg: "",
        incubationRule: "",
        incubationRecordBg: "",
        saleTop: "",
        saleBottom: "",
        saleSelectedTop: "",
        saleSelectedBottom: "",
        saleClose: "",
        saleNumberSub: "",
        saleNumberAdd: "",
        animalEmpty: "",
        noticeOnResale: ""
      }
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _animal.getSkinApi)().then(function (res) {
      _this2.ruleForm = res.data;
    });
  },
  methods: {
    modalPicTap: function modalPicTap(key, index) {
      console.log(key);
      var _this = this;
      this.$modalUpload(function (img) {
        _this.ruleForm[key] = img[0];
      }, 1);
    },
    save: function save() {
      var _this3 = this;
      (0, _animal.updateOrCreateApi)(this.ruleForm).then(function (res) {
        _this3.$message.success("保存成功");
      });
    }
  }
};