var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "r-title" }, [_vm._v("参拍记录")]),
    _vm._v(" "),
    _c("div", { staticClass: "echart-box" }, [
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart1", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("浏览类目占比")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart2", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("参拍类目占比")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart3", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("成交类目占比")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "count-box" }, [
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(_vm._s(_vm.formatPriceNumber(_vm.countjson.bidCount))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("出价(次)")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionOrderCount))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("中拍(件)")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(
              _vm.formatPriceNumber(_vm.countjson.auctionOrderSumTotalPrice)
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("成交额")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionAveragePrice))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("平均单价")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionOrderSumPayPrice))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("实际支付")]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { padding: "20px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticClass: "order-table",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData.data,
              "span-method": _vm.objectSpanMethod,
              size: "mini",
              "highlight-current-row": "",
              "cell-class-name": _vm.addTdClass,
            },
            on: { "sort-change": _vm.changeSortAction },
          },
          [
            _c("el-table-column", {
              key: "4",
              attrs: { label: "专场", "min-width": "130", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            scope.row.album ? scope.row.album.album_name : ""
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "7",
              attrs: { label: "图片", "min-width": "60", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("img", {
                        staticStyle: {
                          width: "40px",
                          height: "40px",
                          display: "block",
                        },
                        attrs: {
                          src: scope.row.orderProductOne
                            ? scope.row.orderProductOne.cart_info.product
                                .image + "!120a"
                            : scope.row.orderProduct[0].cart_info.product
                                .image + "!120a",
                          alt: "",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "8",
              attrs: { label: "LOT", "min-width": "60", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            scope.row.orderProductOne
                              ? scope.row.orderProductOne.cart_info.product.lot
                              : scope.row.orderProduct[0].cart_info.product.lot
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "9",
              attrs: { label: "拍品名称", "min-width": "140", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            scope.row.orderProductOne
                              ? scope.row.orderProductOne.cart_info.product
                                  .store_name
                              : scope.row.orderProduct[0].cart_info.product
                                  .store_name
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "12",
              attrs: { label: "订单状态", "min-width": "80", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status == -2
                        ? [_c("div", [_vm._v("已取消")])]
                        : scope.row.status == -1
                        ? [_c("div", [_vm._v("已退款")])]
                        : scope.row.is_refund !== 0
                        ? [
                            _c("div", { staticStyle: { color: "red" } }, [
                              _vm._v("售后中"),
                            ]),
                          ]
                        : scope.row.status == -3 || scope.row.status == 0
                        ? [
                            _c("div", { staticStyle: { color: "red" } }, [
                              _vm._v("待结算"),
                            ]),
                          ]
                        : scope.row.status == -4
                        ? [
                            _c("div", { staticStyle: { color: "red" } }, [
                              _vm._v("转账待审核"),
                            ]),
                          ]
                        : scope.row.delivery_type == 2
                        ? [_c("div", [_vm._v("待自提")])]
                        : scope.row.delivery_type == 3
                        ? [_c("div", [_vm._v("待他人提货")])]
                        : scope.row.status == 1
                        ? [_c("div", [_vm._v("待发货")])]
                        : scope.row.status == 2
                        ? [_c("div", [_vm._v("待收货")])]
                        : scope.row.status == 3
                        ? [_c("div", [_vm._v("待评价")])]
                        : scope.row.status == 4
                        ? [_c("div", [_vm._v("已完成")])]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "13",
              attrs: { label: "落槌价", "min-width": "90", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              scope.row.orderProductOne
                                ? scope.row.orderProductOne.product_price
                                : scope.row.orderProduct[0].product_price,
                              2
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [6, 20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }