"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _open3Dgrade = require("@/api/open3Dgrade");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      timeType: [],
      timeList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }],
      teamAllMoney: 0,
      teamOrderNum: 0,
      teamPeopleNum: 0,
      listLoading: false,
      list: {
        data: [],
        total: 0
      },
      forTList: [],
      queryParams: {
        builderType: "",
        orderType: "",
        forType: "",
        timeType: "",
        nameOrPhone: "",
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getForList({});
  },
  methods: {
    onChange: function onChange() {
      this.getForList({
        orderType: this.queryParams.orderType
      });
      this.getList(1);
    },
    getForList: function getForList(model) {
      var _this = this;
      (0, _open3Dgrade.getConsumeOrderType)(model).then(function (res) {
        var keyArr = Object.keys(res.data);
        var arr = [];
        keyArr.forEach(function (item) {
          var obj = {
            name: res.data[item],
            value: item
          };
          arr.push(obj);
        });
        _this.forTList = arr;
      });
    },
    getList: function getList(num) {
      var _this2 = this;
      this.queryParams.pageNum = num ? num : this.queryParams.pageNum;
      this.listLoading = true;
      (0, _open3Dgrade.getCoreBuilderContributeApi)((0, _objectSpread2.default)({}, this.queryParams)).then(function (res) {
        var _res$statistical = res.statistical,
          teamAllMoney = _res$statistical.teamAllMoney,
          teamOrderNum = _res$statistical.teamOrderNum,
          teamPeopleNum = _res$statistical.teamPeopleNum;
        _this2.teamAllMoney = teamAllMoney;
        _this2.teamOrderNum = teamOrderNum;
        _this2.teamPeopleNum = teamPeopleNum;
        _this2.list.data = res.rows;
        _this2.list.total = res.total;
        _this2.listLoading = false;
      });
    },
    exportOrder: function exportOrder() {
      var _this3 = this;
      (0, _open3Dgrade.getCoreBuilderContributeExportApi)((0, _objectSpread2.default)({}, this.queryParams)).then(function (res) {
        _this3.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(29);
    },
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.date = e ? this.timeType.join("-") : "";
      this.queryParams.timeType = this.queryParams.date;
      this.getList(1);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList("");
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    }
  }
};