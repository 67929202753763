"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

var assetRouter = {
  path: "/asset",
  component: _layout.default,
  redirect: "noRedirect",
  name: "Asset",
  meta: {
    title: "Asset",
    icon: "asset"
  },
  children: [{
    path: "plant",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/asset/plant"));
      });
    },
    name: "AssetPlant",
    meta: {
      title: "平台资产",
      noCache: true
    }
  }, {
    path: "collector",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/asset/collector"));
      });
    },
    name: "AssetCollector",
    meta: {
      title: "藏家资产",
      noCache: true
    }
  }]
};
var _default = exports.default = assetRouter;