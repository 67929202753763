"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _nft = require("@/api/nft");
var _index4 = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'positionList',
  data: function data() {
    return {
      types: 'position',
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        status: ''
      },
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      visibleBlind: false,
      blindForm: {
        ids: [],
        remark: ''
      },
      blindRules: {
        remark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    blindReturnAll: function blindReturnAll() {
      this.clearBlindForm();
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择盲盒');
      }
      this.visibleBlind = true;
      this.blindForm.ids = this.checkedIds;
    },
    clearBlindForm: function clearBlindForm() {
      this.blindForm.ids = [];
      this.blindForm.remark = '';
    },
    confirmBlindAction: function confirmBlindAction(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _nft.productMemberBoxReclaimApi)(_this.blindForm).then(function () {
            _this.$message.success('操作成功');
            _this.visibleBlind = false;
            _this.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.pageNum);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.queryParams.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this2 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this2.checkedIds.indexOf(id);
          if (index === -1) {
            _this2.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this2.checkedIds.indexOf(id);
          if (index > -1) {
            _this2.checkedIds.splice(index, 1);
          }
        });
      }
    },
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === 'blind') {
        this.$router.push('/merchant/digitalCollection/blind/list');
        return false;
      } else if (tab.name === 'position') {
        this.$router.push('/merchant/digitalCollection/position/list');
        return false;
      } else if (tab.name === 'subgift') {
        this.$router.push('/merchant/digitalCollection/subgift/list');
        return false;
      } else if (tab.name === 'recovery') {
        this.$router.push('/merchant/digitalCollection/recovery/list');
        return false;
      } else if (tab.name === "ssr") {
        this.$router.push("/merchant/digitalCollection/ssrSet");
        return false;
      }
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index4.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.setTypeAction();
      (0, _nft.productMemberBoxListApi)(this.queryParams).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.tableData.statistical = res.statistical;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    blindReturn: function blindReturn(id) {
      this.clearBlindForm();
      this.visibleBlind = true;
      var arr = [];
      arr.push(id);
      this.blindForm.ids = arr;
    },
    onchangeTime: function onchangeTime(val) {
      if (val) {
        this.queryParams.startTime = val[0];
        this.queryParams.endTime = val[1];
        this.getList(1);
      } else {
        this.queryParams.startTime = '';
        this.queryParams.endTime = '';
        this.getList(1);
      }
    },
    copyAction: function copyAction(val) {
      var input = document.createElement('input'); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    }
  }
};