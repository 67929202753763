"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var marketingRouter = {
  path: "".concat(_settings.roterPre, "/marketing"),
  name: 'marketing',
  meta: {
    icon: 'dashboard',
    title: '营销玩法'
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: 'noRedirect',
  children: [{
    path: 'coupon',
    name: 'Coupon',
    meta: {
      title: '优惠券',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'CouponList',
      meta: {
        title: '优惠劵列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponList/index'));
        });
      }
    }, {
      path: 'user',
      name: 'CouponUser',
      meta: {
        title: '领取记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponUser/index'));
        });
      }
    }, {
      path: 'send',
      name: 'CouponSend',
      meta: {
        title: '优惠券发送记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponSend/index'));
        });
      }
    }, {
      path: 'use',
      name: 'CouponUse',
      meta: {
        title: '使用记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponUse/index'));
        });
      }
    }]
  }, {
    path: 'coupon/creatCoupon',
    name: 'CreatCoupon',
    meta: {
      title: '添加优惠劵',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponList/creatCoupon'));
      });
    }
  }, {
    path: 'help',
    name: 'helpIndex',
    meta: {
      title: '助力有奖',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/help/index'));
      });
    },
    children: [{
      path: 'list',
      name: "helpList",
      meta: {
        title: '助力有奖活动',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/help/list'));
        });
      }
    }, {
      path: 'detail',
      name: "helpDetail",
      meta: {
        title: '助力有奖详情',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/help/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/help/detail'));
        });
      }
    }, {
      path: 'ranking',
      name: "helpRanking",
      meta: {
        title: '助力有奖排行',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/help/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/help/ranking'));
        });
      }
    }]
  }, {
    path: 'integral',
    name: 'Integral',
    meta: {
      title: '积分',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/index'));
      });
    },
    children: [{
      path: 'survey',
      name: 'IntegralSurvey',
      meta: {
        title: '积分概况',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/survey'));
        });
      }
    }, {
      path: 'detailed',
      name: 'IntegralDetailed',
      meta: {
        title: '积分明细',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/detailed'));
        });
      }
    }, {
      path: 'receiving',
      name: 'IntegralReceiving',
      meta: {
        title: '发放记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/receiving'));
        });
      }
    }, {
      path: 'use',
      name: 'IntegralUse',
      meta: {
        title: '使用记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/use'));
        });
      }
    }, {
      path: 'ranking',
      name: 'IntegralRanking',
      meta: {
        title: '积分排行',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/ranking'));
        });
      }
    }, {
      path: 'type',
      name: 'IntegralType',
      meta: {
        title: '积分类型',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/type"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/type'));
        });
      }
    }]
  }, {
    path: 'compose',
    name: 'compose',
    meta: {
      title: '合成活动',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/index'));
      });
    },
    children: [{
      path: 'list',
      name: "composeList",
      meta: {
        title: '合成活动',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/composeList'));
        });
      }
    }, {
      path: 'recordlist',
      name: "composeRecordList",
      meta: {
        title: '合成活动记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/compose/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/composeRecord'));
        });
      }
    }]
  }, {
    path: 'compose/create',
    name: "composeCreate",
    meta: {
      title: '合成活动详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/compose/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/composeAdd'));
      });
    }
  }, {
    path: 'luckDraw',
    name: 'luckDraw',
    meta: {
      title: '抽奖活动',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/luckDraw/index'));
      });
    },
    children: [{
      path: 'list',
      name: "luckDrawList",
      meta: {
        title: '抽奖活动',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/luckDraw/list'));
        });
      }
    }, {
      path: 'recordlist',
      name: "composeRecordList",
      meta: {
        title: '参与记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/luckDraw/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/luckDraw/luckRecord'));
        });
      }
    }]
  }, {
    path: 'luckDraw/detail',
    name: "composeCreate",
    meta: {
      title: '抽奖活动详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/luckDraw/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/luckDraw/detail'));
      });
    }
  }, {
    path: 'vote',
    name: 'vote',
    meta: {
      title: '投票活动',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/vote/index'));
      });
    },
    children: [{
      path: 'list',
      name: "voteList",
      meta: {
        title: '投票活动',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/vote/list'));
        });
      }
    }, {
      path: 'recordlist',
      name: "voteRecordList",
      meta: {
        title: '参与记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/vote/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/vote/luckRecord'));
        });
      }
    }]
  }, {
    path: 'vote/detail',
    name: "voteCreate",
    meta: {
      title: '投票活动详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/vote/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/vote/detail'));
      });
    }
  }, {
    path: 'signIn',
    name: 'signIn',
    meta: {
      title: '签到',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/signIn/index'));
      });
    },
    children: [{
      path: 'list',
      name: "signIneList",
      meta: {
        title: '签到',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/signIn/list'));
        });
      }
    }, {
      path: 'recordlist',
      name: "signInRecordList",
      meta: {
        title: '领取记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/signIn/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/signIn/record'));
        });
      }
    }]
  }, {
    path: 'retail/list',
    name: 'retailList',
    meta: {
      title: '分销体系',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/retail/list'));
      });
    }
  }, {
    path: 'sms/task',
    name: 'massTexting',
    meta: {
      title: '群发短信',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/index'));
      });
    }
  }, {
    path: 'sms/template',
    name: 'mass_sms_template',
    meta: {
      title: '短信内容模板',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/sms_template'));
      });
    }
  }, {
    path: 'sms/sign',
    name: 'mass_sms_sign',
    meta: {
      title: '短信签名',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/sms_sign'));
      });
    }
  }, {
    path: 'sms/notice',
    name: 'sms_notice',
    meta: {
      title: '站内群发',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/notice'));
      });
    }
  }, {
    path: 'sms/service',
    name: 'sms_service',
    meta: {
      title: '艺天下服务号',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/sms_service'));
      });
    }
  }, {
    path: 'newExclusive',
    name: "newExclusive",
    meta: {
      title: '新人专享'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/newExclusive/index'));
      });
    }
  }, {
    path: 'conversionCode/conversionCodeAdd',
    name: 'conversionCodeAdd',
    meta: {
      title: '新增兑换码',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/conversionCode/index")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/conversionCode/codeVersion/index'));
      });
    }
  }, {
    path: 'conversionCode',
    name: "conversionCode",
    meta: {
      title: '兑换码'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/conversionCode/index'));
      });
    }
  },
  // pointsExchangeMall
  {
    path: 'pointsExchangeMall',
    name: "pointsExchangeMall",
    meta: {
      title: '积分兑换商城',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/pointsExchangeMall/index")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/pointsExchangeMall/index'));
      });
    }
  }, {
    path: 'physical',
    name: 'digitalCollection_physical',
    meta: {
      title: '积分商城'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/physical/index'));
      });
    }
  }, {
    path: 'physical/detail',
    name: 'digitalCollection_detail',
    meta: {
      title: '创建积分商品',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/physical/detail")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/physical/detail'));
      });
    }
  }, {
    path: 'pointsExchangeMall/createMall',
    name: "pointsExchangeMall",
    meta: {
      title: '新增积分兑换',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/pointsExchangeMall/createMall/index")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/pointsExchangeMall/createMall/index'));
      });
    }
  }, {
    path: 'playgame',
    name: "playGame",
    meta: {
      title: '战机挖矿',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/index'));
      });
    },
    children: [{
      path: 'planelist',
      name: 'planelist',
      meta: {
        title: '战机列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/planeList'));
        });
      }
    }, {
      path: 'exchangeGoodsList',
      name: 'exchangeGoodsList',
      meta: {
        title: '兑换商城',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/exchangeGoodsList'));
        });
      }
    }, {
      path: 'exchangeGoodsHistory',
      name: 'exchangeGoodsHistory',
      meta: {
        title: '兑换记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/exchangeGoodsHistory'));
        });
      }
    }, {
      path: 'class',
      name: 'planeclass',
      meta: {
        title: '分类',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/class'));
        });
      }
    }]
  }, {
    path: 'planedetail',
    name: 'planedetail',
    meta: {
      title: '创建战机',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/planedetail'));
      });
    }
  }, {
    path: 'userplane',
    name: 'userplane',
    meta: {
      title: '用户持有情况',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/userPlane'));
      });
    }
  }, {
    path: 'exchangeGoodsAdd',
    name: 'exchangeGoodsAdd',
    meta: {
      title: '创建兑换商品',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/exchangeGoodsAdd'));
      });
    }
  }, {
    path: 'attackDetail',
    name: 'attackDetail',
    meta: {
      title: '进攻数据',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/attackDetail'));
      });
    }
  }, {
    path: 'playgametd',
    name: 'playgametd',
    meta: {
      title: '塔防',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/index'));
      });
    },
    children: [{
      path: 'tdconfig',
      name: 'tdconfig',
      meta: {
        title: '塔防配置',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/tdConfig'));
        });
      }
    }, {
      path: 'canusecollection',
      name: 'canusecollection',
      meta: {
        title: '可投放藏品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/canUsecollection'));
        });
      }
    }, {
      path: 'attackdata',
      name: 'attackdata',
      meta: {
        title: '进攻数据',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/attackData'));
        });
      }
    }, {
      path: 'exchangeGoodsList',
      name: 'exchangeGoodsList',
      meta: {
        title: '兑换商城',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/exchangeGoodsList'));
        });
      }
    }, {
      path: 'exchangeGoodsHistory',
      name: 'exchangeGoodsHistory',
      meta: {
        title: '兑换记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/exchangeGoodsHistory'));
        });
      }
    }, {
      path: 'rank',
      name: 'rank',
      meta: {
        title: '保卫积分排行',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/rank'));
        });
      }
    }]
  }, {
    path: 'tdexchangeGoodsAdd',
    name: 'tdexchangeGoodsAdd',
    meta: {
      title: '创建兑换商品',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/exchangeGoodsAdd'));
      });
    }
  }]
};
var _default = exports.default = marketingRouter;