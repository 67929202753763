var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        [
          _c("div", { staticClass: "top-view" }, [
            _c(
              "div",
              { staticStyle: { padding: "0 20px" } },
              [
                _vm.$route.path !=
                  "/merchant/setting/systemRule/addSystemRole" &&
                _vm.$route.path != "/merchant/setting/systemRule/addSystemAdmin"
                  ? _c(
                      "el-menu",
                      {
                        staticClass: "el-menu-demo default-tabs mt10",
                        attrs: {
                          "default-active": _vm.$route.path,
                          mode: "horizontal",
                        },
                        on: { select: _vm.changeTab },
                      },
                      [
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index: "/merchant/setting/systemRule/systemRole",
                            },
                          },
                          [_vm._v("角色管理")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index: "/merchant/setting/systemRule/systemAdmin",
                            },
                          },
                          [_vm._v("管理员管理")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index: "/merchant/setting/systemRule/systemPwd",
                            },
                          },
                          [_vm._v("操作密码")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }