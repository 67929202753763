"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "CouponUser",
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  props: {
    integralType: [String, Number]
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      tableFromIssue: {
        pageNum: 1,
        pageSize: 10,
        coupon_name: "",
        type: 2,
        date: "",
        name: "",
        phone: ""
      },
      issueData: {
        data: [],
        total: 0
      },
      timeVal: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      uid: "",
      showdetail: false
    };
  },
  watch: {
    integralType: function integralType(val, oldVal) {
      this.getIssueList();
    }
  },
  mounted: function mounted() {
    // this.getIssueList();
  },
  methods: {
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(13);
    },
    derive: function derive() {
      var _this = this;
      var data = {
        user: this.tableFromIssue.user
      };
      (0, _marketing.scoreExport)(data).then(function (res) {
        _this.$message.success("导出成功");
      });
    },
    // 列表
    getIssueList: function getIssueList() {
      var _this2 = this;
      if (this.integralType) {
        this.tableFromIssue.scoreTypeId = this.integralType;
      } else {
        this.tableFromIssue.scoreTypeId = "";
      }
      this.Loading = true;
      (0, _marketing.integralReceivingRecordListApi)(this.tableFromIssue).then(function (res) {
        _this2.issueData.data = res.rows;
        _this2.issueData.total = res.total;
        _this2.Loading = false;
      }).catch(function (res) {
        _this2.Loading = false;
        // this.$message.error(res.message)
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFromIssue.date = tab;
      this.pageChangeIssue(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      if (e) {
        this.tableFromIssue.startTime = e[0];
        this.tableFromIssue.endTime = e[1];
      } else {
        this.tableFromIssue.startTime = "";
        this.tableFromIssue.endTime = "";
      }
      this.pageChangeIssue(1);
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.pageNum = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.pageSize = val;
      this.getIssueList();
    },
    exportOrder: function exportOrder() {
      var _this3 = this;
      var data = (0, _objectSpread2.default)({}, this.tableFromIssue);
      (0, _marketing.exportTransactionListApi)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this3.$createElement;
        _this3.$msgbox({
          title: "提示",
          message: h("p", null, [h("span", null, '文件正在生成中，请稍后点击"'), h("span", {
            style: "color: teal"
          }, "导出已生成列表"), h("span", null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.userId;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    }
  }
};