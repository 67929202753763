var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px", "margin-right": "20px" },
                  attrs: { size: "small", placeholder: "客户名称/手机号" },
                  model: {
                    value: _vm.searchForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "name", $$v)
                    },
                    expression: "searchForm.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("新建")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableList, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  width: "80",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", width: "70", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.avatar,
                              expression: "scope.row.avatar",
                            },
                          ],
                          staticStyle: {
                            width: "36px",
                            height: "36px",
                            display: "block",
                            margin: "0 auto",
                          },
                          attrs: { src: scope.row.avatar },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !scope.row.avatar,
                              expression: "!scope.row.avatar",
                            },
                          ],
                          staticStyle: {
                            width: "36px",
                            height: "36px",
                            border: "1px #eee solid",
                            margin: "0 auto",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "姓名", width: "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "", label: "简介", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(scope.row.intro) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "m0 p0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.pages.pageSize,
                  "current-page": _vm.pages.pageNum,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: { "current-change": _vm.changePage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleLabel
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleLabel,
                "close-on-click-modal": false,
                center: "",
                title: _vm.title,
                width: "390px",
                top: "5vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleLabel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "labelForm",
                  attrs: {
                    size: "small",
                    model: _vm.labelForm,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-height": "70vh",
                        "overflow-y": "auto",
                        "padding-right": "20px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { size: "small", placeholder: "名称" },
                            model: {
                              value: _vm.labelForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.labelForm, "name", $$v)
                              },
                              expression: "labelForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "头像" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1")
                              },
                            },
                          },
                          [
                            _vm.labelForm.avatar
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.labelForm.avatar },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.labelForm.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.labelForm, "sex", $$v)
                                },
                                expression: "labelForm.sex",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "男" } }, [
                                _vm._v("男"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "女" } }, [
                                _vm._v("女"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "国籍" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { size: "small", placeholder: "国籍" },
                            model: {
                              value: _vm.labelForm.nation,
                              callback: function ($$v) {
                                _vm.$set(_vm.labelForm, "nation", $$v)
                              },
                              expression: "labelForm.nation",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "籍贯" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { size: "small", placeholder: "籍贯" },
                            model: {
                              value: _vm.labelForm.native,
                              callback: function ($$v) {
                                _vm.$set(_vm.labelForm, "native", $$v)
                              },
                              expression: "labelForm.native",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "年代" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { size: "small", placeholder: "年代" },
                            model: {
                              value: _vm.labelForm.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.labelForm, "year", $$v)
                              },
                              expression: "labelForm.year",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "毕业学校" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { size: "small", placeholder: "毕业学校" },
                            model: {
                              value: _vm.labelForm.school_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.labelForm, "school_name", $$v)
                              },
                              expression: "labelForm.school_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生平经历" } },
                        _vm._l(
                          _vm.labelForm.history_record,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _c("div", { staticClass: "boxLine" }, [
                                _c(
                                  "div",
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "mb10",
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        "value-format": "yyyy-MM-dd",
                                        format: "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "选择日期",
                                      },
                                      model: {
                                        value: item.name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        size: "small",
                                        placeholder: "内容",
                                      },
                                      model: {
                                        value: item.content,
                                        callback: function ($$v) {
                                          _vm.$set(item, "content", $$v)
                                        },
                                        expression: "item.content",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    index == 0
                                      ? _c("el-button", {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-plus",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickIem()
                                            },
                                          },
                                        })
                                      : _c("el-button", {
                                          attrs: {
                                            type: "danger",
                                            icon: "el-icon-delete",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDeleIem(
                                                "history_record",
                                                index
                                              )
                                            },
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖项" } },
                        _vm._l(
                          _vm.labelForm.award_list,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _c("div", { staticClass: "boxLine" }, [
                                _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      staticClass: "mb10",
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        size: "small",
                                        placeholder: "获奖国家",
                                      },
                                      model: {
                                        value: item.award_city,
                                        callback: function ($$v) {
                                          _vm.$set(item, "award_city", $$v)
                                        },
                                        expression: "item.award_city",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "mb10",
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        size: "small",
                                        placeholder: "获奖地区",
                                      },
                                      model: {
                                        value: item.award_area,
                                        callback: function ($$v) {
                                          _vm.$set(item, "award_area", $$v)
                                        },
                                        expression: "item.award_area",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-date-picker", {
                                      staticClass: "mb10",
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        "value-format": "yyyy-MM-dd",
                                        format: "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "选择日期",
                                      },
                                      model: {
                                        value: item.name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "mb10",
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        size: "small",
                                        placeholder: "内容",
                                      },
                                      model: {
                                        value: item.content,
                                        callback: function ($$v) {
                                          _vm.$set(item, "content", $$v)
                                        },
                                        expression: "item.content",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        size: "small",
                                        placeholder: "获奖级别",
                                      },
                                      model: {
                                        value: item.award_level,
                                        callback: function ($$v) {
                                          _vm.$set(item, "award_level", $$v)
                                        },
                                        expression: "item.award_level",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    index == 0
                                      ? _c("el-button", {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-plus",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickIem()
                                            },
                                          },
                                        })
                                      : _c("el-button", {
                                          attrs: {
                                            type: "danger",
                                            icon: "el-icon-delete",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDeleIem(
                                                "award_list",
                                                index
                                              )
                                            },
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "简介" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "textarea",
                              autosize: "",
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.labelForm.intro,
                              callback: function ($$v) {
                                _vm.$set(_vm.labelForm, "intro", $$v)
                              },
                              expression: "labelForm.intro",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit("labelForm")
                        },
                      },
                    },
                    [_vm._v("\n        确定\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }