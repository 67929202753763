"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
var _systemForm = require("@/api/systemForm");
var _video = require("@/api/video");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Firstbuy',
  filters: {
    formatType: function formatType(val) {
      var res = '';
      switch (val) {
        case 'specialPresentation':
          res = '专场';
          break;
        case 'battles':
          res = '战报';
          break;
        case 'auction':
          res = '拍卖会';
          break;
        case 'lots':
          res = '拍品';
          break;
        case 'lotPicture':
          res = '拍品图片';
          break;
        case 'titles':
          res = '片头';
          break;
        case 'trailer':
          res = '片尾';
          break;
        default:
          break;
      }
      return res;
    },
    formatCategory: function formatCategory(val) {
      var res = '';
      switch (val) {
        case 'specialPresentation':
          res = '专场介绍';
          break;
        case 'specialAuctionIntroduction':
          res = '专场拍品介绍';
          break;
        case 'auctionIntroduction':
          res = '拍卖会介绍';
          break;
        case 'lotDescription':
          res = '拍品介绍';
          break;
        case 'lotPicture':
          res = '拍品图片';
          break;
        case 'titles':
          res = '片头';
          break;
        case 'trailer':
          res = '片尾';
          break;
        default:
          break;
      }
      return res;
    }
  },
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      detail: {},
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        source: 1,
        type: '1',
        businessScenario: '',
        contentLatitudeId: '',
        contentLocalizationId: '',
        workShapeId: '',
        pictureScaleId: '',
        firstCategoryId: '',
        secondaryCategoryId: '',
        ratio: '',
        tagIds: [],
        status: ''
      },
      roterPre: _settings.roterPre,
      showVideo: false,
      videoRatio: '16:9',
      videoUrl: '',
      types: [{
        value: 'specialPresentation',
        label: '专场'
      }, {
        value: 'battles',
        label: '战报'
      }, {
        value: 'auction',
        label: '拍卖会'
      }, {
        value: 'lots',
        label: '拍品'
      }, {
        value: 'lotPicture',
        label: '拍品图片'
      }, {
        value: 'titles',
        label: '片头'
      }, {
        value: 'trailer',
        label: '片尾'
      }],
      categores: [{
        value: 'specialPresentation',
        label: '专场介绍'
      }, {
        value: 'specialAuctionIntroduction',
        label: '专场拍品介绍'
      }, {
        value: 'auctionIntroduction',
        label: '拍卖会介绍'
      }, {
        value: 'lotDescription',
        label: '拍品介绍'
      }, {
        value: 'festivalSolarTerm',
        label: '节日节气'
      }, {
        value: 'introductionToSolicitation',
        label: '征集介绍'
      }, {
        value: 'companyIntroduction',
        label: '企业介绍'
      }, {
        value: 'authorIntroduction',
        label: '作者介绍'
      }, {
        value: 'titles',
        label: '片头'
      }, {
        value: 'trailer',
        label: '片尾'
      }],
      categoryList: [],
      categoryTwoList: [],
      tagList: [],
      attrList: [],
      xingzhuang: {},
      bili: {},
      dingwei: {},
      weidu: {},
      tagIdList: []
    };
  },
  created: function created() {
    var _this = this;
    (0, _product.storeCategoryListApi)({
      type: 2
    }).then(function (res) {
      _this.categoryList = res.data;
    });
    (0, _systemForm.labelLstApi)({
      type: 2
    }).then(function (res) {
      _this.tagList = res.rows;
    });
    (0, _product.attrListApi)({
      useType: 3
    }).then(function (res) {
      res.rows.forEach(function (m) {
        if (m.attrName === '作品形状') {
          _this.xingzhuang = Object.assign({}, m);
        } else if (m.attrName === '画面比例') {
          _this.bili = Object.assign({}, m);
        } else if (m.attrName === '内容定义') {
          _this.dingwei = Object.assign({}, m);
        } else if (m.attrName === '内容类型') {
          _this.weidu = Object.assign({}, m);
        }
      });
    });
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    changeTwoCategory: function changeTwoCategory() {
      var ids = this.categoryList.map(function (m) {
        return m.id;
      });
      var index = ids.indexOf(this.tableFrom.firstCategoryId);
      if (index !== -1) {
        this.categoryTwoList = this.categoryList[index].children;
      } else {
        this.categoryTwoList = '';
        this.tableFrom.secondaryCategoryId = '';
      }
      this.getList(1);
    },
    changePageAction: function changePageAction() {
      this.getList(1);
    },
    previewVideo: function previewVideo(item) {
      this.videoRatio = item.ratio;
      this.videoUrl = item.previewingVideo;
      this.showVideo = true;
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      this.tableFrom.tagIds = this.tagIdList.join(',');
      (0, _video.templateListAPI)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push('./templateDetail');
    },
    editAction: function editAction(item) {
      this.$router.push('./templateDetail?id=' + item.id);
    },
    setAction: function setAction(id) {
      window.open("/merchant/video/templateData?id=".concat(id));
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u7EC4\u4EF6?", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _video.templateDelAPI)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      }).catch(function () {});
    },
    editStatusAction: function editStatusAction(row) {
      var _this4 = this;
      (0, _video.templateEditStatusAPI)({
        id: row.id,
        status: Number(!row.status)
      }).then(function () {
        _this4.$message.success('操作成功');
        _this4.getList();
      });
    },
    // 删除
    copyAction: function copyAction(id) {
      var _this5 = this;
      (0, _video.templateCopyAPI)(id).then(function (_ref3) {
        var message = _ref3.message;
        _this5.$message.success('操作成功');
        _this5.getList();
      }).catch(function (_ref4) {
        var message = _ref4.message;
      });
    }
  }
};