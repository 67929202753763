var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "页面基本配置", is_tab: false } },
    [
      _vm.facade && _vm.facade.show
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "背景颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#f7f7f7" },
                      model: {
                        value: _vm.facade.background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "background", $$v)
                        },
                        expression: "facade.background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("背景图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.bg_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.bg_image + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }