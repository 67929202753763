"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _review = require("@/api/review");
var _ipip = require("@/api/ipip");
var _settings = require("@/settings");
var _merchant = require("@/api/merchant");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "review",
  data: function data() {
    return {
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        date: "",
        pageNum: 1,
        pageSize: 20
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      // tableFromLog: {
      //   pageNum: 1,
      //   pageSize: 20
      // },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: "",
        mark: ""
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      showreview: false,
      mask: "",
      identityList: [],
      showadd: false,
      adduid: "",
      userList: [],
      searchLoading: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getList(1);
    // getIpToken('1703410707087843328')
    (0, _merchant.getList)().then(function (res) {
      _this.identityList = res.rows;
    });
  },
  methods: {
    // 删除空间
    deleteSpace: function deleteSpace(id) {
      var _this2 = this;
      console.log(id);
      (0, _ipip._deleteSpace)(id).then(function (res) {
        console.log(res);
        if (res.code == 200) {
          _this2.$message({
            message: "删除成功",
            type: "success"
          });
          _this2.getList(1);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 控制推荐按钮
    onHasRecommended: function onHasRecommended(val, row) {
      var _this3 = this;
      console.log(val, row.id);
      (0, _ipip._onHasRecommended)({
        hasRecommended: val,
        id: row.id
      }).then(function (res) {
        _this3.$message({
          message: "操作成功",
          type: "success"
        });
        _this3.getList(1);
      }).catch(function (err) {
        console.log(err);
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this4 = this;
      var params = {
        nameOrPhone: data,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this4.userList = res.rows;
      });
    },
    handleadd: function handleadd() {
      var _this5 = this;
      (0, _ipip.getIpToken)(this.adduid).then(function (res) {
        _this5.showadd = false;
        var url = "";
        if (/test/g.test(location.hostname)) {
          url = "https://test.artart.cn";
        } else {
          url = "https://artart.cn";
        }
        window.open(url + "/login/tokenAuth?token=" + res.data.token);
      });
    },
    revieworder: function revieworder(row) {
      this.handlerow = row;
      this.showreview = true;
      this.mask = "";
    },
    handlereview: function handlereview(state) {
      var _this6 = this;
      if (state == -1 && !this.mask) {
        this.$message.error("请填写拒绝备注");
        return;
      }
      (0, _review.review)(this.handlerow.audit_id, {
        status: state,
        remark: this.mask
      }).then(function (res) {
        _this6.$message.success("操作成功");
        _this6.getList();
        _this6.showreview = false;
      }).catch(function (err) {
        _this6.$message.error(err.message);
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // pageChangeLog(page) {
    //   this.tableFromLog.pageNum = page;
    //   this.getList("");
    // },
    // handleSizeChangeLog(val) {
    //   this.tableFromLog.pageSize = val;
    //   this.getList("");
    // },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this7 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num ? num : this.tableFrom.pageNum;
      (0, _ipip.getSpaceList)(this.tableFrom).then(function (res) {
        //   this.orderChartType = res.data.stat;
        _this7.tableData.data = res.rows;
        _this7.tableData.total = res.total;
        _this7.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    }
  }
};