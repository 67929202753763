var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("header-notice"),
                _vm._v(" "),
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "platformLabel" }, [
            _vm._v(_vm._s(_vm.label.name)),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("span", { staticClass: "el-dropdown-link fontSize" }, [
                _vm._v("\n        " + _vm._s(_vm.account) + "\n        "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goPassword($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("修改密码"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.visiblePassword
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visiblePassword,
                "close-on-click-modal": false,
                title: "修改密码",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visiblePassword = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "passwordForm",
                  attrs: {
                    size: "small",
                    model: _vm.passwordForm,
                    rules: _vm.passwordRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "password" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.passwordForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.passwordForm, "password", $$v)
                          },
                          expression: "passwordForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "确认密码", prop: "passwordAgain" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.passwordForm.passwordAgain,
                          callback: function ($$v) {
                            _vm.$set(_vm.passwordForm, "passwordAgain", $$v)
                          },
                          expression: "passwordForm.passwordAgain",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmPasswordAction("passwordForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }