"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _title = _interopRequireDefault(require("./title"));
var _button = _interopRequireDefault(require("./button"));
var _banner = _interopRequireDefault(require("./banner"));
var _tag = _interopRequireDefault(require("./tag"));
var _img = _interopRequireDefault(require("./img"));
var _coupon = _interopRequireDefault(require("./coupon"));
var _default = exports.default = {
  title: _title.default,
  button: _button.default,
  banner: _banner.default,
  tag: _tag.default,
  img: _img.default,
  coupon: _coupon.default
};