var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "优惠券名称：", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: {
                              placeholder: "请输入优惠券名称",
                              disabled: _vm.status != 0,
                            },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发放数量：", prop: "count" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w100",
                            attrs: {
                              disabled: _vm.id ? true : false,
                              placeholder: "数量",
                            },
                            model: {
                              value: _vm.ruleForm.count,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "count", _vm._n($$v))
                              },
                              expression: "ruleForm.count",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [_vm._v("张")]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型：", prop: "type" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: { disabled: _vm.status != 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.editAction("base")
                                },
                              },
                              model: {
                                value: _vm.ruleForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "type", $$v)
                                },
                                expression: "ruleForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "满减券" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 2, label: "立减券" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 3, label: "折扣券" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.type == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "满：", prop: "base" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                attrs: {
                                  disabled: _vm.status != 0,
                                  placeholder: "金额",
                                },
                                model: {
                                  value: _vm.ruleForm.quota,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "quota", _vm._n($$v))
                                  },
                                  expression: "ruleForm.quota",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("元，减"),
                              ]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "w100 ml10",
                                attrs: {
                                  disabled: _vm.status != 0,
                                  placeholder: "金额",
                                },
                                model: {
                                  value: _vm.ruleForm.base,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "base", _vm._n($$v))
                                  },
                                  expression: "ruleForm.base",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm.ruleForm.type == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "立减：", prop: "base" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c w300" },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                attrs: {
                                  disabled: _vm.status != 0,
                                  placeholder: "金额",
                                },
                                model: {
                                  value: _vm.ruleForm.base,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "base", _vm._n($$v))
                                  },
                                  expression: "ruleForm.base",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm.ruleForm.type == 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "折扣：", prop: "base" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c w300" },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                attrs: {
                                  disabled: _vm.status != 0,
                                  placeholder: "金额",
                                },
                                model: {
                                  value: _vm.ruleForm.base,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "base", _vm._n($$v))
                                  },
                                  expression: "ruleForm.base",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("%"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "有效期：", prop: "failureType" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.status != 0 },
                          on: {
                            change: function ($event) {
                              return _vm.editAction("failureType")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.failureType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "failureType", $$v)
                            },
                            expression: "ruleForm.failureType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: 1 } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  disabled:
                                    _vm.ruleForm.failureType == 2 ||
                                    _vm.status != 0,
                                },
                                model: {
                                  value: _vm.ruleForm.times,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "times", $$v)
                                  },
                                  expression: "ruleForm.times",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { label: 2 } },
                            [
                              _c("span", [_vm._v("领取后")]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "w100 ml10",
                                attrs: {
                                  type: "number",
                                  placeholder: "天数",
                                  oninput:
                                    "value = value.replace(/^(0+)|[^\\d]+/g, '')",
                                  disabled:
                                    _vm.ruleForm.failureType == 1 ||
                                    _vm.status != 0,
                                },
                                model: {
                                  value: _vm.ruleForm.effectiveDay,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "effectiveDay",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.effectiveDay",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("内有效"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "领券限制：" } }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.status != 0 },
                        model: {
                          value: _vm.ruleForm.getLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "getLimit", $$v)
                          },
                          expression: "ruleForm.getLimit",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("不限制"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { attrs: { label: 1 } },
                          [
                            _c("span", [_vm._v("每人限领")]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "w100 ml10",
                              attrs: {
                                placeholder: "数量",
                                disabled:
                                  _vm.status != 0 ||
                                  _vm.ruleForm.getLimit === 0,
                              },
                              model: {
                                value: _vm.ruleForm.getLimit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "getLimit",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "ruleForm.getLimit",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10" }, [_vm._v("张")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10 color-gray" }, [
                              _vm._v("（0或者不填为不限制）"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用限制：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { disabled: _vm.status != 0 },
                      model: {
                        value: _vm.ruleForm.mutex,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "mutex", $$v)
                        },
                        expression: "ruleForm.mutex",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 1 } }, [
                        _vm._v("不与会员权益共同使用"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: 2 } }, [
                        _vm._v("不与积分共同使用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取条件：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.getCondition,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "getCondition", $$v)
                        },
                        expression: "ruleForm.getCondition",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("开通Dao会员"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "人群限制：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.status != 0 },
                      model: {
                        value: _vm.ruleForm.crowdLimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "crowdLimit", $$v)
                        },
                        expression: "ruleForm.crowdLimit",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("全部用户"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("关联名单"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.crowdLimit == 2
                ? _c("el-form-item", { attrs: { label: " " } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              placeholder: "选择快照名单",
                              clearable: "",
                              multiple: "",
                            },
                            model: {
                              value: _vm.ruleForm.snapshotIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "snapshotIds", $$v)
                              },
                              expression: "ruleForm.snapshotIds",
                            },
                          },
                          _vm._l(_vm.nameListsList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { value: item.id, label: item.name },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          staticClass: "ml10",
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                          },
                          model: {
                            value: _vm.ruleForm.hasAutoSend,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "hasAutoSend", $$v)
                            },
                            expression: "ruleForm.hasAutoSend",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10" }, [
                          _vm._v("自动发放同时短信通知用户"),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用须知：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "700px" },
                    attrs: {
                      "show-word-limit": "",
                      maxlength: "1000",
                      rows: "4",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "使用范围：" } }, [
                _c(
                  "div",
                  { staticClass: "tab" },
                  [
                    _c("div", { staticClass: "tr flex align-items-c" }, [
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "100%" } },
                        [_vm._v("符合如下条件可使用")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.use_range, function (m, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "tr flex align-items-c" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "20%" },
                            },
                            [
                              i == 2
                                ? _c("div", [_vm._v("版权授权")])
                                : i == 3
                                ? _c("div", [_vm._v("数字盲盒")])
                                : i == 1
                                ? _c("div", [_vm._v("版权转让")])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "80%" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.status != 0 },
                                  on: {
                                    change: function (val) {
                                      return _vm.changeTypeAction(val, i)
                                    },
                                  },
                                  model: {
                                    value: m.type,
                                    callback: function ($$v) {
                                      _vm.$set(m, "type", $$v)
                                    },
                                    expression: "m.type",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("不使用"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("全部" + _vm._s(m.keyName)),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("指定" + _vm._s(m.keyName)),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              m.type == 2
                                ? [
                                    _c("PopoverTable", {
                                      attrs: { "show-data": m.data },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ml10",
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.status != 0,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showChangeAction(i)
                                          },
                                        },
                                      },
                                      [_vm._v("选择" + _vm._s(m.keyName))]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAction("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "nft",
        attrs: { "current-data": _vm.use_range[2].data },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "nft_box",
        attrs: { "current-data": _vm.use_range[3].data },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("SearchAlbum", {
        ref: "SearchAlbum",
        attrs: { "current-data": _vm.use_range[1].data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }