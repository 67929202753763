var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex align-items-c flex-col relative",
        staticStyle: { width: "100%", height: "100%" },
      },
      [
        _c("div", {
          staticStyle: { width: "100%", flex: "1" },
          attrs: { id: "player-wrapper" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }