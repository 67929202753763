var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("div", { staticClass: "drawer-content" }, [
        _c("div", { staticClass: "top-img flex" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "t" }, [_vm._v("横版封面")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "img" },
              [
                _c("el-image", {
                  attrs: {
                    src: _vm.detail.coverPicture + "!m640",
                    fit: "cover",
                    "preview-src-list": [_vm.detail.coverPicture],
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item ml10" }, [
            _c("div", { staticClass: "t" }, [_vm._v("竖版封面")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "img shu" },
              [
                _c("el-image", {
                  attrs: {
                    src: _vm.detail.infoImage + "!m640",
                    fit: "cover",
                    "preview-src-list": [_vm.detail.infoImage],
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-info" },
        [
          _c("div", { staticClass: "d-title" }, [_vm._v("基础信息：")]),
          _vm._v(" "),
          _c("div", { staticClass: "common-bor flex flex-wrap" }, [
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("作品文件：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.showVideoAction },
                    },
                    [_vm._v("查看源文件")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("作者：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.showUserAction(_vm.detail.authorUserId)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.detail.authorName))]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("发行机构：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _c("div", [_vm._v(_vm._s(_vm.detail.issuerName))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("作品类型：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(
                  _vm._s(
                    _vm.detail.cateAttrInfo
                      ? _vm.detail.cateAttrInfo.modelTypeName
                      : ""
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("作品名称：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one line1" }, [
                _vm._v(_vm._s(_vm.detail.storeName)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("面积/尺寸：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(
                  _vm._s(_vm.detail.size) +
                    _vm._s(
                      _vm.detail.cateAttrInfo
                        ? _vm.detail.cateAttrInfo.size.unit
                        : ""
                    )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("作品ID：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(_vm._s(_vm.detail.nftId)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("创建人：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(_vm._s(_vm.detail.createBy)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("创建时间：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(_vm._s(_vm.detail.createTime)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("发行状态：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(_vm._s(_vm.detail.isIssuance ? "已发行" : "未发行")),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("版权持有：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.showUserAction(_vm.detail.userId)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.detail.userName))]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("销售状态：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _vm.detail.status == 10
                    ? [_vm._v("销售中")]
                    : _vm.detail.status == 20
                    ? [_vm._v("已售罄")]
                    : _vm.detail.status == 30
                    ? [_vm._v("已下架")]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item all flex align-items-c",
                staticStyle: { padding: "10px" },
              },
              [
                _c("div", { staticClass: "l" }, [_vm._v("简介：")]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-one" }, [
                  _vm._v(_vm._s(_vm.detail.introduction)),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.detail.isRecord
            ? [
                _c("div", { staticClass: "d-title" }, [_vm._v("版权信息：")]),
                _vm._v(" "),
                _c("div", { staticClass: "common-bor flex flex-wrap" }, [
                  _c("div", { staticClass: "item flex align-items-c" }, [
                    _c("div", { staticClass: "l" }, [_vm._v("备案单位：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-one" }, [
                      _vm._v(_vm._s(_vm.detail.recordCompany)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item flex align-items-c" }, [
                    _c("div", { staticClass: "l" }, [_vm._v("DCI身份证：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v(_vm._s(_vm.detail.dci)),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item flex align-items-c" }, [
                    _c("div", { staticClass: "l" }, [_vm._v("认证时间：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-one" }, [
                      _vm._v(_vm._s(_vm.detail.recordTime)),
                    ]),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-title" }, [_vm._v("发售信息：")]),
          _vm._v(" "),
          _c("div", { staticClass: "common-bor flex flex-wrap" }, [
            _c("div", { staticClass: "item biserial flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("上链发行：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(_vm._s(_vm.detail.blockchainType)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item biserial flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("作品哈希：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c("el-button", { attrs: { type: "text" } }, [
                    _vm._v(_vm._s(_vm.detail.hash)),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item biserial flex align-items-c" }, [
              _c("div", { staticClass: "l" }, [_vm._v("价格/总量：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatPriceNumber(Number(_vm.detail.price || 0), 2)
                  ) +
                    "元/" +
                    _vm._s(_vm.formatPriceNumber(_vm.detail.issuedCount || 0)) +
                    "份"
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm.detail.type == 2
              ? _c("div", { staticClass: "item biserial flex align-items-c" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("发售/库存：")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one" },
                    [
                      _vm._v(
                        _vm._s(_vm.detail.issuedCount || 0) +
                          "/" +
                          _vm._s(_vm.detail.stock) +
                          " "
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: { click: _vm.holderAction },
                        },
                        [
                          _vm._v(
                            "查看持仓" + _vm._s(_vm.detail.positionUserCount)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.detail.type == 2
              ? _c("div", { staticClass: "item biserial flex align-items-c" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("预留/库存：")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one" },
                    [
                      _vm._v(
                        _vm._s(_vm.detail.reserveStock) +
                          "/" +
                          _vm._s(_vm.detail.reservedQuantity) +
                          " "
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: { click: _vm.holderAction },
                        },
                        [
                          _vm._v(
                            "查看持仓" + _vm._s(_vm.detail.positionUserCount)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.detail.blockchainTime
              ? _c(
                  "div",
                  { staticClass: "item biserial all flex align-items-c" },
                  [
                    _c("div", { staticClass: "l" }, [_vm._v("上链时间：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-one" }, [
                      _vm._v(_vm._s(_vm.detail.blockchainTime)),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.detail.cateAttrInfo &&
          _vm.detail.cateAttrInfo.attrs &&
          _vm.detail.cateAttrInfo.attrs.length
            ? [
                _c("div", { staticClass: "d-title" }, [_vm._v("属性标签：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "common-bor flex flex-wrap" },
                  _vm._l(_vm.detail.cateAttrInfo.attrs, function (m, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "item biserial flex align-items-c",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "l", staticStyle: { width: "auto" } },
                          [_vm._v(_vm._s(m.attrName) + "：")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-one" }, [
                          _vm._v(_vm._s(m.attrValueNames.join("、"))),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-title" }, [_vm._v("详情介绍：")]),
          _vm._v(" "),
          _c("div", { staticClass: "d-message" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.detail.description) },
            }),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm.visibleHolder
        ? _c("Holder", {
            attrs: {
              visibleHolder: _vm.visibleHolder,
              types: 2,
              productId: _vm.productId,
            },
            on: { holderClose: _vm.holderClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item biserial flex align-items-c" }, [
      _c("div", { staticClass: "l" }, [_vm._v("作品编号：")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-one" }, [_vm._v("AC01#1-#100")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }