var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.ruleForm, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "图片上传：", required: "" } },
                [
                  _c("div", { staticClass: "flex flex-wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item flex align-items-c flex-direction mr20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("homeBg", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.homeBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.homeBg + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [_vm._v("首页背景")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("homeTop", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.homeTop
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.homeTop + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [_vm._v("首页顶部")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("homeTopArrowRight", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.homeTopArrowRight
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.homeTopArrowRight +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("首页顶部右箭头"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("innerBg", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.innerBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.innerBg + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("内丹详情背景"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("innerDecoration", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.innerDecoration
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.innerDecoration + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("内丹详情装饰图"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("innerBtn", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.innerBtn
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.innerBtn + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("内丹详情按钮背景"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationBg", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.incubationBg + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [_vm._v("孵化背景")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationBack", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationBack
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationBack + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("孵化返回按钮"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationProgress", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationProgress
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationProgress +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [_vm._v("孵化进度")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationSlotBg", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotBg + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [_vm._v("卡槽背景")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationSlotList", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotList
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotList +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [_vm._v("卡槽列表")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(
                                  "incubationSlotListNoWrapper",
                                  ""
                                )
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotListNoWrapper
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm
                                          .incubationSlotListNoWrapper +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("卡槽列表无神兽占位背景"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationSlotAdd", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotAdd
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotAdd +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("卡槽列表添加"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(
                                  "incubationSlotUnlock",
                                  ""
                                )
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotUnlock
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotUnlock +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("卡槽列表解锁"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(
                                  "incubationSlotBtnBg",
                                  ""
                                )
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotBtnBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotBtnBg +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("卡槽底部按钮背景"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(
                                  "incubationSlotUnlockBg",
                                  ""
                                )
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotUnlockBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotUnlockBg +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("卡槽解锁背景"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(
                                  "incubationSlotInfoBg",
                                  ""
                                )
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotInfoBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotInfoBg +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("卡槽详情背景"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(
                                  "incubationSlotDelist",
                                  ""
                                )
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotDelist
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotDelist +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("卡槽详情下架"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(
                                  "incubationSlotSupplement",
                                  ""
                                )
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationSlotSupplement
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationSlotSupplement +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("卡槽详情补充"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationPopup", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationPopup
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationPopup + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("弹窗底部按钮"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationClose", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationClose
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationClose + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("弹窗关闭按钮"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationTitleBg", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationTitleBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationTitleBg +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("弹窗title背景"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationRuleBg", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationRuleBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationRuleBg + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [_vm._v("规则背景")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationRule", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationRule
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationRule + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [_vm._v("规则图片")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("incubationRecordBg", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.incubationRecordBg
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.incubationRecordBg +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("孵化记录背景"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("saleTop", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.saleTop
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.saleTop + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("售卖上箭头"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("saleBottom", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.saleBottom
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.saleBottom + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("售卖下箭头"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("saleSelectedTop", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.saleSelectedTop
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.saleSelectedTop + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("售卖选中上箭头"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("saleSelectedBottom", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.saleSelectedBottom
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.saleSelectedBottom +
                                        "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("售卖选中下箭头"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("saleClose", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.saleClose
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.saleClose + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("售卖关闭按钮"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("saleNumberSub", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.saleNumberSub
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.saleNumberSub + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("售卖数量减"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("saleNumberAdd", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.saleNumberAdd
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.saleNumberAdd + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("售卖数量加"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-item mr20 flex align-items-c flex-direction",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "正方形图片" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("animalEmpty", "")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.animalEmpty
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.ruleForm.animalEmpty + "!120a",
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v("为空占位图"),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: { label: "颜色渐变：", required: "" },
                },
                [
                  _c("ul", { staticStyle: { margin: "0" } }, [
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("首页tab渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.homeTabStartLinear,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "homeTabStartLinear", $$v)
                            },
                            expression: "ruleForm.homeTabStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.homeTabEndLinear,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "homeTabEndLinear", $$v)
                            },
                            expression: "ruleForm.homeTabEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("首页tabs渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.homeTabsStartLinear,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "homeTabsStartLinear", $$v)
                            },
                            expression: "ruleForm.homeTabsStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.homeTabsEndLinear,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "homeTabsEndLinear", $$v)
                            },
                            expression: "ruleForm.homeTabsEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("首页列表状态渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.homeListStatusStartLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "homeListStatusStartLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.homeListStatusStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.homeListStatusEndLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "homeListStatusEndLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.homeListStatusEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("首页列表底部渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.homeListBottomStartLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "homeListBottomStartLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.homeListBottomStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.homeListBottomEndLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "homeListBottomEndLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.homeListBottomEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("内丹仓库按钮渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerBtnStartLinear,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "innerBtnStartLinear", $$v)
                            },
                            expression: "ruleForm.innerBtnStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerBtnEndLinear,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "innerBtnEndLinear", $$v)
                            },
                            expression: "ruleForm.innerBtnEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("内丹仓库弹窗渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerPopupStartLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "innerPopupStartLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.innerPopupStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerPopupEndLinear,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "innerPopupEndLinear", $$v)
                            },
                            expression: "ruleForm.innerPopupEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("出售内丹按钮渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerSaleBtnStartLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "innerSaleBtnStartLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.innerSaleBtnStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerSaleBtnEndLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "innerSaleBtnEndLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.innerSaleBtnEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("出售内丹输入框渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerSaleIptStartLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "innerSaleIptStartLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.innerSaleIptStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerSaleIptEndLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "innerSaleIptEndLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.innerSaleIptEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("在售内丹列表渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerSaleListStartLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "innerSaleListStartLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.innerSaleListStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.innerSaleListEndLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "innerSaleListEndLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.innerSaleListEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("确认购买弹窗渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.confirmBuyStartLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "confirmBuyStartLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.confirmBuyStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.confirmBuyEndLinear,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "confirmBuyEndLinear", $$v)
                            },
                            expression: "ruleForm.confirmBuyEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "flex" },
                      [
                        _c("span", [_vm._v("确认购买弹窗按钮渐变：")]),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.confirmBuyBtnStartLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "confirmBuyBtnStartLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.confirmBuyBtnStartLinear",
                          },
                        }),
                        _vm._v(" "),
                        _c("colour", {
                          staticStyle: { width: "25%" },
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.ruleForm.confirmBuyBtnEndLinear,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "confirmBuyBtnEndLinear",
                                $$v
                              )
                            },
                            expression: "ruleForm.confirmBuyBtnEndLinear",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转卖协议名称" } },
                [
                  _c("el-input", {
                    staticClass: "w200",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.ruleForm.noticeOnResale,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "noticeOnResale", $$v)
                      },
                      expression: "ruleForm.noticeOnResale",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }