"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var payTypeId;
var financeRouter = {
  path: "".concat(_settings.roterPre, "/finance"),
  name: "finance",
  meta: {
    icon: "dashboard",
    title: "财务管理"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "noRedirect",
  children: [{
    path: "balance",
    name: "Balance",
    meta: {
      title: "站内余额"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/balance/index.vue"));
      });
    }
  }, {
    path: "deposit",
    name: "Deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/open3dAccount/deposit.vue"));
      });
    },
    meta: {
      title: "平台入金",
      activeMenu: "".concat(_settings.roterPre, "/finance/deposit?payTypeId=")
    },
    beforeEnter: function beforeEnter(to, form, next) {
      payTypeId = to.query.payTypeId;
      to.meta.activeMenu = "".concat(_settings.roterPre, "/finance/deposit?payTypeId=").concat(payTypeId);
      next();
    }
  }, {
    path: "withdrawal_details",
    name: "WithdrawalDetails",
    meta: {
      title: "提现明细"
    },
    // component: () => import("@/views/finance/withdrawalDetails/index.vue")
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/open3dAccount/cashOut/index.vue"));
      });
    }
  }, {
    path: "payment_order",
    name: "PaymentOrder",
    meta: {
      title: "支付订单"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/paymentOrder/index.vue"));
      });
    }
  }, {
    path: "contract",
    name: "Contract",
    meta: {
      title: "合同管理"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/contract/index.vue"));
      });
    }
  }, {
    path: "invoice",
    name: "Invoice",
    meta: {
      title: "发票管理"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/invoice/index.vue"));
      });
    }
  }]
};
var _default = exports.default = financeRouter;