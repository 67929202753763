"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var frameRouter = {
  path: "".concat(_settings.roterPre, "/frame"),
  name: "frame",
  meta: {
    title: "画框管理"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/frame/frameList"),
  children: [{
    path: "frameList",
    name: "frameList",
    meta: {
      title: "模版",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/frame/frameList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/frame/frameList.vue"));
      });
    }
  }, {
    path: "frameType",
    name: "frameType",
    meta: {
      title: "框类型",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/frame/frameList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/frame/frameType.vue"));
      });
    }
  }, {
    path: "framePaper",
    name: "framePaper",
    meta: {
      title: "框卡纸",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/frame/frameList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/frame/framePaper.vue"));
      });
    }
  }, {
    path: "frameScence",
    name: "frameScence",
    meta: {
      title: "场景",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/frame/frameList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/frame/frameScence.vue"));
      });
    }
  }, {
    path: "frameFrame",
    name: "frameFrame",
    meta: {
      title: "画框",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/frame/frameList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/frame/frameFrame.vue"));
      });
    }
  }, {
    path: "addFrame",
    name: "addFrame",
    meta: {
      title: "模版操作",
      cacheable: false,
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/frame/addFrame")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/frame/addFrame.vue"));
      });
    }
  }]
};
var _default = exports.default = frameRouter;