"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _asset = require("@/api/asset");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Plant",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      productName: "",
      allAssets: "",
      allMoney: "",
      timeType: "lately7",
      value1: "",
      options: {
        title: {
          text: "单位(万)"
        },
        legend: {
          data: ["流通市值", "总市值"]
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 30
          },
          data: []
        },
        yAxis: {
          min: 0,
          max: function max(value) {
            //取最大值向上取整为最大刻度
            return Math.ceil(value.max);
          }
        },
        series: [{
          name: "流通市值",
          data: [],
          type: "line",
          smooth: true
        }, {
          name: "总市值",
          data: [],
          type: "line",
          smooth: true
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    assetsExport: function assetsExport() {
      var _this = this;
      var data = {
        productName: this.productName
      };
      if (this.value1) {
        data.timeType = "".concat(this.value1[0], "-").concat(this.value1[1]);
      } else {
        data.timeType = this.timeType;
      }
      (0, _asset.allAssetsExport)(data).then(function (res) {
        console.log(res);
        _this.$message.success("导出成功");
      }).catch(function (err) {
        _this.$message.error("导出失败");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(42);
    },
    getList: function getList() {
      var _this2 = this;
      var data = {
        productName: this.productName
      };
      if (this.value1) {
        data.timeType = "".concat(this.value1[0], "-").concat(this.value1[1]);
      } else {
        data.timeType = this.timeType;
      }
      this.options.xAxis.data = [];
      this.options.series[0].data = [];
      this.options.series[1].data = [];
      (0, _asset.allAssets)(data).then(function (res) {
        var _res$data = res.data,
          listDateVoList = _res$data.listDateVoList,
          allAssets = _res$data.allAssets,
          allMoney = _res$data.allMoney;
        _this2.allAssets = (Number(allAssets) / 10000).toFixed(0);
        _this2.allMoney = (Number(allMoney) / 10000).toFixed();
        var myChart = _this2.$echarts.init(document.getElementById("main"));
        listDateVoList.forEach(function (item) {
          _this2.options.xAxis.data.push(item.date);
          _this2.options.series[0].data.push((item.allAssets / 10000).toFixed(2));
          _this2.options.series[1].data.push((item.allMoney / 10000).toFixed(2));
        });
        myChart.setOption(_this2.options);
      });
    },
    changeTime: function changeTime() {
      console.log("1");
    }
  }
};