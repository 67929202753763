"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _modules = _interopRequireDefault(require("./modules"));
var _right_btn = _interopRequireDefault(require("./modules/right_btn.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _system = require("@/api/system");
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _attribute = _interopRequireDefault(require("../decoration/modules/footer/attribute"));
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    rightBtn: _right_btn.default,
    Title: _modules.default.title.element,
    Button: _modules.default.button.element,
    Banner: _modules.default.banner.element,
    Tag: _modules.default.tag.element,
    Img: _modules.default.img.element,
    Coupon: _modules.default.coupon.element,
    colour: _colour.default,
    slider: _slider.default,
    footerAttribute: _attribute.default
  },
  data: function data() {
    return {
      modules: _modules.default,
      fullHeight: document.documentElement.clientHeight,
      showSet: false,
      setKey: '',
      setTitle: '',
      title: {
        header_background: '#CD0000',
        header_color: 'white',
        header_text_size: 32,
        fontWeight: 500,
        slide_style_no_change: true,
        slide_header_background: '#CD0000',
        slide_header_color: 'white',
        slide_header_text_size: 32,
        slide_fontWeight: 500
      },
      button: {
        style: 1,
        frontBgColor: '',
        frontBorderColor: '#dd0000',
        frontColor: '#dd0000',
        frontFontWeight: 500,
        frontFontSize: 32,
        frontRadius: 8,
        afterBgColor: '#dd0000',
        afterBorderColor: '#dd0000',
        afterColor: '#FFFFFF',
        afterFontWeight: 500,
        afterFontSize: 32,
        afterRadius: 8,
        frontBgImg: '',
        afterBgImg: ''
      },
      banner: {
        style: 1,
        // 风格模板选择  1  单张滑动  2 左右露出  3 右侧露出
        background_color: '',
        img_radius: 0,
        // 图上圆角
        img_bottom_radius: 0,
        // 图下圆角
        item_padding: 30,
        // 容器边距
        chamfer_style: 1,
        // 倒角样式
        indicator_style: 2,
        // 指示器样式  1 远点  2 长条
        indicator_align: 'center',
        // 指示器对齐
        indicator_vertical_align: 'top',
        // 指示器位置
        indicator_color: '#dd0000',
        // 指示器颜色
        img_style: 2
      },
      tag: {
        borderRadius: 8,
        bgColor: '#E6CB95',
        numberBgColor: '#1E1E1E',
        numberColor: '#E6CB95',
        numIcon: 'https://saas.cdn.yunzongbu.cn/image/20220526/d1cb644f41240f93cd41baa553080794.png'
      },
      img: {
        topLeftRadius: 0,
        topRightRadius: 0,
        bottomLeftRadius: 0,
        bottomRightRadius: 0,
        img_style: 2
      },
      coupon: {
        background: '#ffffff',
        radius: 16,
        title_color: 'black',
        sub_title_color: '#000000',
        gradient_start: '#FF6C6C',
        gradient_end: '#E33838',
        price_font_size: 48,
        price_font_weight: 500,
        proce_font_color: '#ffffff',
        symbol_font_size: 24,
        content_border_color: '#eeeeee',
        content_background: '#ffffff',
        content_margin: 8,
        name_font_size: 28,
        name_font_weight: 500,
        name_color: '#666666',
        status1_color: '#999999',
        status2_color: '#F76262',
        desc_font_size: 24,
        desc_font_weight: 500,
        desc_color: '#FB7575',
        message_font_size: 24,
        message_font_weight: 400,
        message_color: '#999999',
        more_color: '#0088FF',
        time_font_size: 20,
        time_font_weight: 400,
        time_color: '#999999'
      },
      settingData: {},
      otherData: {
        color: '#000000',
        specialColor: '#dd0000',
        priceColor: '#DD0000',
        pageMargin: 30,
        fontWeight: 400,
        order_tab_new: []
      },
      footer_data: {
        footerSetting: {
          no_change: true,
          footer_type: 1,
          footer_background: '#FFFFFF',
          footer_text_color: '#000000',
          footer_active_color: '#000000',
          footer_text_size: 18,
          footer_margin: 40,
          use_type: 2,
          content: [{
            url: '',
            url_after: '',
            title: '导航文字',
            link: {}
          }, {
            url: '',
            url_after: '',
            title: '导航文字',
            link: {}
          }, {
            url: '',
            url_after: '',
            title: '导航文字',
            link: {}
          }, {
            url: '',
            url_after: '',
            title: '导航文字',
            link: {}
          }, {
            url: '',
            url_after: '',
            title: '导航文字',
            link: {}
          }]
        }
      }
    };
  },
  computed: {
    scrollHeight: function scrollHeight() {
      return {
        height: parseInt(this.fullHeight) - 79 + 'px'
      };
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.allConfigListApi)().then(function (res) {
      var data = res.data;
      if (data.title_style) {
        _this.title = JSON.parse(data.title_style);
      }
      if (data.button_style) {
        var d = JSON.parse(data.button_style);
        !d.frontBgImg ? d.frontBgImg = '' : '';
        !d.afterBgImg ? d.afterBgImg = '' : '';
        _this.button = d;
      }
      if (data.footer_data) {
        _this.footer_data = JSON.parse(data.footer_data);
      }
      if (data.banner_style) {
        _this.banner = JSON.parse(data.banner_style);
      }
      if (data.tag_style) {
        _this.tag = JSON.parse(data.tag_style);
      }
      // if (data.img_raduis_style) {
      //   this.img = JSON.parse(data.img_raduis_style);
      // }
      if (data.coupon_data) {
        _this.coupon = JSON.parse(data.coupon_data);
      }
      if (data.other_data) {
        var oData = JSON.parse(data.other_data);
        // if (oData.order_tab) delete oData.order_tab;
        if (!oData.order_tab_new) oData.order_tab_new = [0, 1, 2, 3];
        // console.log(oData);
        _this.otherData = oData;
      }
    }).catch(function () {});
  },
  methods: {
    showSetAction: function showSetAction(key) {
      this.setKey = key;
      this.settingData = (0, _objectSpread2.default)({}, JSON.parse(JSON.stringify(this[key])));
      switch (key) {
        case 'title':
          this.setTitle = '标题栏风格设置';
          break;
        case 'button':
          this.setTitle = '按钮风格设置';
          break;
        case 'footer_data':
          this.setTitle = '底部导航设置';
          break;
        case 'banner':
          this.setTitle = '轮播图风格设置';
          break;
        case 'tag':
          this.setTitle = '标签风格设置';
          break;
        case 'img':
          this.setTitle = '图片圆角设置';
          break;
        case 'coupon':
          this.setTitle = '优惠券通用设置';
          break;
      }
      this.showSet = true;
    },
    saveAction: function saveAction(key) {
      var k = '';
      var data = {};
      switch (key) {
        case 'title':
          k = 'title_style';
          break;
        case 'button':
          k = 'button_style';
          break;
        case 'footer_data':
          k = 'footer_data';
          break;
        case 'banner':
          k = 'banner_style';
          break;
        case 'tag':
          k = 'tag_style';
          break;
        case 'img':
          k = 'img_raduis_style';
          break;
        case 'coupon':
          k = 'coupon_data';
          break;
      }
      if (k === 'footer_data') {
        data[k] = JSON.stringify(this.formatJsonStr(JSON.stringify(this.settingData)));
      } else {
        data[k] = JSON.stringify(this.settingData);
      }
      console.log(data, 612);
      (0, _system.allConfigUpdateApi)(data).then().catch(function () {});
      this[key] = (0, _objectSpread2.default)({}, this.settingData);
      this.showSet = false;
    },
    saveOtherAction: function saveOtherAction() {
      var _this2 = this;
      console.log(this.formatJsonStr(this.otherData));
      (0, _system.allConfigUpdateApi)({
        other_data: JSON.stringify(this.formatJsonStr(this.otherData))
      }).then(function () {
        _this2.$message.success('保存成功');
      }).catch(function () {});
    },
    handleClose: function handleClose() {
      var _this3 = this;
      this.$confirm("\u60A8\u8FD8\u672A\u4FEE\u6539\uFF0C\u786E\u8BA4\u5173\u95ED\uFF1F", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.showSet = false;
      }).catch(function () {});
    },
    formatJsonStr: function formatJsonStr(str) {
      if (str === null || str === '{}' || str === undefined) {
        return str;
      }
      try {
        var json = {};
        if (typeof str === 'string') {
          json = JSON.parse(str);
        } else {
          json = str;
        }
        for (var k in json) {
          var kv = json[k];
          try {
            // 数组
            if (Array.isArray(kv)) {
              try {
                // json字符串处理
                var sub = kv;
                for (var i = 0; i < sub.length; i++) {
                  if ((0, _typeof2.default)(sub[i]) === 'object') {
                    sub[i] = this.formatJsonStr(sub[i]);
                  }
                }
                json[k] = sub;
              } catch (e) {
                console.log(e);
              }
              continue;
            }
            if (kv && typeof kv === 'string') {
              // if(k.indexOf('_f')>-1){
              //   delete json[k];
              // }
              if ((kv.indexOf('#') !== -1 || kv.indexOf('rgb') !== -1) && k.indexOf('_f') === -1) {
                var kvf = k + '_f';
                json[kvf] = (0, _index.setRgbaTo16)(kv);
              }
            }
            if ((0, _typeof2.default)(kv) === 'object') {
              json[k] = this.formatJsonStr(kv);
            }
          } catch (e2) {}
        }
        return json;
      } catch (e) {}
      return str;
    }
  }
};