var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "keep-alive",
        {
          attrs: {
            include: _vm.cachedViews,
            exclude: [
              "frameList",
              "frameType",
              "framePaper",
              "frameScence",
              "frameFrame",
            ],
          },
        },
        [_c("router-view", { key: _vm.key })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }