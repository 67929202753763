var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "djb" },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.goBack } },
              [_vm._v("返回")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.onSubmit },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "djb" }, [
          _c(
            "div",
            { staticClass: "leftBox" },
            [
              _c("div", [_vm._v("提示词")]),
              _vm._v(" "),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 20,
                  placeholder: "请输入内容",
                },
                model: {
                  value: _vm.promptText,
                  callback: function ($$v) {
                    _vm.promptText = $$v
                  },
                  expression: "promptText",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "rightBox" }, [
            _c("div", [_vm._v("调试")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dialogueBor" },
              _vm._l(_vm.chatList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "chatBox",
                    class: {
                      myBox: item.type == "my",
                      aiBox: item.type == "ai",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          myContCla: item.type == "my",
                          aiContCla: item.type == "ai",
                        },
                      },
                      [_vm._v(_vm._s(item.context))]
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.responseIng,
                    placeholder: "请输入内容",
                  },
                  model: {
                    value: _vm.input,
                    callback: function ($$v) {
                      _vm.input = $$v
                    },
                    expression: "input",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: _vm.responseIng,
                      size: "small",
                    },
                    on: { click: _vm.onSend },
                  },
                  [_vm._v("发送")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }