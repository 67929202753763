var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": "5",
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("模版")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("框类型")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [_vm._v("框卡纸")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [_vm._v("场景")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [_vm._v("画框")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-20" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { input: _vm.changeType },
                  model: {
                    value: _vm.categoryId,
                    callback: function ($$v) {
                      _vm.categoryId = $$v
                    },
                    expression: "categoryId",
                  },
                },
                _vm._l(_vm.categorys, function (item, index) {
                  return _c("el-radio-button", { attrs: { label: item.id } }, [
                    _vm._v(_vm._s(item.categoryName)),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "default-box mt10" }, [
            _c("div", { staticClass: "default-setting" }, [
              _c("div", { staticClass: "logo-r" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "w200",
                        on: { change: _vm.changeTypeStatus },
                        model: {
                          value: _vm.typeStatus,
                          callback: function ($$v) {
                            _vm.typeStatus = $$v
                          },
                          expression: "typeStatus",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { value: "0", label: "全部" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "1", label: "横幅" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "2", label: "竖幅" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "3", label: "斗方" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ml20",
                        attrs: { type: "primary", size: "small" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.addFrame($event)
                          },
                        },
                      },
                      [_vm._v("添加")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-box" },
            _vm._l(_vm.lists, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "imgItem" },
                [
                  _c("el-image", {
                    staticClass: "imgSize",
                    attrs: {
                      src: item.imgUrl + "!m640",
                      alt: "",
                      "preview-src-list": _vm.srcList,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "handBox" }, [
                    _c("div", [
                      _vm._v(
                        "分类: " +
                          _vm._s(item.categoryName) +
                          " / 类型: \n            "
                      ),
                      item.categoryType == 0
                        ? _c("span", [_vm._v("全部")])
                        : item.categoryType == 1
                        ? _c("span", [_vm._v("横幅")])
                        : item.categoryType == 2
                        ? _c("span", [_vm._v("竖幅")])
                        : item.categoryType == 3
                        ? _c("span", [_vm._v("斗方")])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "span",
                          {
                            staticClass: "handBut",
                            on: {
                              click: function ($event) {
                                return _vm.handle(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定删除吗?" },
                          on: {
                            confirm: function ($event) {
                              return _vm.deleteFrame(item)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "handBut" }, [
                                      _vm._v("删除"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default-box" },
            [
              _vm.total > 0
                ? _c("el-pagination", {
                    staticClass: "mt20",
                    attrs: {
                      total: _vm.total,
                      "show-sizer": "",
                      "show-elevator": "",
                      "show-total": "",
                      "current-page": _vm.queryParams.pageNum,
                      "page-size": _vm.queryParams.pageSize,
                    },
                    on: {
                      "current-change": _vm.changePage,
                      "size-change": _vm.changePageSize,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.frameflag, width: "40%", title: "画框" },
              on: {
                "update:visible": function ($event) {
                  _vm.frameflag = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "formList",
                  attrs: { "label-width": "120px", model: _vm.formList },
                },
                [
                  _c("el-form-item", { attrs: { label: "展示图片" } }, [
                    _vm.formList.imgUrl
                      ? _c("div", { staticClass: "upload-list" }, [
                          _c("img", { attrs: { src: _vm.formList.imgUrl } }),
                          _vm._v(" "),
                          _c("div", { staticClass: "upload-list-cover" }, [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              attrs: { size: "20" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRemove("imgUrl")
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.formList.imgUrl,
                            expression: "!formList.imgUrl",
                          },
                        ],
                        staticClass: "border-h",
                        staticStyle: {
                          width: "58px",
                          height: "58px",
                          "line-height": "58px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onUpload("imgUrl")
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-camera-solid",
                          attrs: { size: "20" },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          model: {
                            value: _vm.formList.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formList, "categoryId", $$v)
                            },
                            expression: "formList.categoryId",
                          },
                        },
                        _vm._l(_vm.categorys, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.categoryName },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          model: {
                            value: _vm.formList.categoryType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formList, "categoryType", $$v)
                            },
                            expression: "formList.categoryType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "全部" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 1, label: "横幅" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 2, label: "竖幅" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 3, label: "斗方" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formList.categoryId != 33
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "左上角图片" } },
                            [
                              _vm.formList.leftUpImg
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.leftUpImg },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "leftUpImg"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.leftUpImg,
                                      expression: "!formList.leftUpImg",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("leftUpImg")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左上角的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.leftUpWidth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "leftUpWidth", $$v)
                                  },
                                  expression: "formList.leftUpWidth",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左上角的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.leftUpHeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "leftUpHeight", $$v)
                                  },
                                  expression: "formList.leftUpHeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右上角的图片" } },
                            [
                              _vm.formList.rightUpImg
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.rightUpImg },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "rightUpImg"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.rightUpImg,
                                      expression: "!formList.rightUpImg",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("rightUpImg")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右上角的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.rightUpWidth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "rightUpWidth", $$v)
                                  },
                                  expression: "formList.rightUpWidth",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右上角的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.rightUpHeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "rightUpHeight", $$v)
                                  },
                                  expression: "formList.rightUpHeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角图片" } },
                            [
                              _vm.formList.leftDownImg
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.leftDownImg },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "leftDownImg"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.leftDownImg,
                                      expression: "!formList.leftDownImg",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("leftDownImg")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.leftDownWidth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "leftDownWidth", $$v)
                                  },
                                  expression: "formList.leftDownWidth",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.leftDownHeight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "leftDownHeight",
                                      $$v
                                    )
                                  },
                                  expression: "formList.leftDownHeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右下角的图片" } },
                            [
                              _vm.formList.rightDownImg
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.rightDownImg },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "rightDownImg"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.rightDownImg,
                                      expression: "!formList.rightDownImg",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("rightDownImg")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.rightDownWidth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "rightDownWidth",
                                      $$v
                                    )
                                  },
                                  expression: "formList.rightDownWidth",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.rightDownHeight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "rightDownHeight",
                                      $$v
                                    )
                                  },
                                  expression: "formList.rightDownHeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上边框图片" } },
                            [
                              _vm.formList.upImg
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.upImg },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove("upImg")
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.upImg,
                                      expression: "!formList.upImg",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("upImg")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上边框的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.upHeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "upHeight", $$v)
                                  },
                                  expression: "formList.upHeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上边框的阴影高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.upShadowHeight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "upShadowHeight",
                                      $$v
                                    )
                                  },
                                  expression: "formList.upShadowHeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下边框图片" } },
                            [
                              _vm.formList.downImg
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.downImg },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove("downImg")
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.downImg,
                                      expression: "!formList.downImg",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("downImg")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下边框的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.downHeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "downHeight", $$v)
                                  },
                                  expression: "formList.downHeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下边框的阴影高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.downShadowHeight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "downShadowHeight",
                                      $$v
                                    )
                                  },
                                  expression: "formList.downShadowHeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左边框图片" } },
                            [
                              _vm.formList.leftImg
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.leftImg },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove("leftImg")
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.leftImg,
                                      expression: "!formList.leftImg",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("leftImg")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左边框的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.leftWidth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "leftWidth", $$v)
                                  },
                                  expression: "formList.leftWidth",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左边框的阴影宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.leftShadowWidth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "leftShadowWidth",
                                      $$v
                                    )
                                  },
                                  expression: "formList.leftShadowWidth",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右边框图片" } },
                            [
                              _vm.formList.rightImg
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.rightImg },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "rightImg"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.rightImg,
                                      expression: "!formList.rightImg",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("rightImg")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右边框的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.rightWidth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "rightWidth", $$v)
                                  },
                                  expression: "formList.rightWidth",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右边框的阴影宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.rightShadowWidth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "rightShadowWidth",
                                      $$v
                                    )
                                  },
                                  expression: "formList.rightShadowWidth",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.frameflag = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }