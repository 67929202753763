"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.search");
var _modules = _interopRequireDefault(require("./modules"));
var _vuedraggable = _interopRequireDefault(require("../../libs/vuedraggable.js"));
var _covermask = _interopRequireDefault(require("../../components/customPage/covermask.vue"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Title: _modules.default.title.widget,
    Video: _modules.default.video.widget,
    Banner: _modules.default.banner.widget,
    Search: _modules.default.search.widget,
    Rubik: _modules.default.rubik.widget,
    Nav: _modules.default.nav.widget,
    Blank: _modules.default.blank.widget,
    Notice: _modules.default.notice.widget,
    Tag: _modules.default.tag.widget,
    Word: _modules.default.word.widget,
    NftCollection: _modules.default.nftCollection.widget,
    nftCollectionMarket: _modules.default.nftCollectionMarket.widget,
    NftBlindBox: _modules.default.nftBlindBox.widget,
    BlindBox: _modules.default.blindBox.widget,
    NftCollectionAlbum: _modules.default.nftCollectionAlbum.widget,
    textView: _modules.default.textView.widget,
    NewExclusive: _modules.default.newExclusive.widget,
    Publisher: _modules.default.Publisher.widget,
    AllPointProducts: _modules.default.allPointProducts.widget,
    ComposeList: _modules.default.composeList.widget,
    vuedraggable: _vuedraggable.default,
    Covermask: _covermask.default
  },
  data: function data() {
    return {
      is_empty: false,
      id: this.$route.query.id || ""
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    dragIndex: {
      get: function get() {
        return this.$store.state.finish.dragIndex;
      },
      set: function set(value) {
        this.$store.commit("finish/setDragIndex", value);
      }
    },
    position: {
      get: function get() {
        return this.$store.state.finish.position;
      },
      set: function set(value) {
        this.$store.commit("finish/setDragPosition", value);
      }
    },
    select: function select() {
      return this.$store.state.finish.index;
    },
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set(value) {
        this.$store.commit("finish/setPagesData", value);
      }
    },
    coms: function coms() {
      return this.$store.state.finish.coms;
    }
  },
  mounted: function mounted() {
    this.loadPageInfo();
  },
  methods: {
    /**
     * 加载数据操作
     * @return {[type]} [description]
     */
    loadPageInfo: function loadPageInfo() {
      var _this = this;
      // setTimeout(() => {
      // }, 500)

      if (this.id) {
        (0, _system.diypageDetail)({
          id: this.id
        }).then(function (res) {
          console.log(res);
          var configData = JSON.parse(res.data.config);
          var data = {
            name: res.data.name,
            id: res.data.id,
            status: res.data.status,
            updated_time: res.data.updated_time,
            visit_number: res.data.visit_number,
            merchant_id: res.data.merchant_id,
            content: JSON.parse(res.data.content),
            background: configData.background,
            login_config: configData.login_config || 3,
            hide_header: configData.hide_header || false,
            hide_header_view: configData.hide_header_view || false,
            headerSetting: configData.headerSetting,
            bg_image: configData.bg_image,
            bg_image_mode: configData.bg_image_mode,
            share_image: configData.share_image,
            hide_footer: configData.hide_footer || false,
            show_copyright: configData.show_copyright || false,
            share_txt: configData.share_txt || "",
            footerSetting: configData.footerSetting
          };
          _this.pages = data.content;
          _this.$store.commit("finish/setPageInfo", data);
        }).catch(function () {});
      } else {
        var data = {
          background: "#F7F7F7",
          hide_header: false,
          // 是否展示标题栏
          hide_header_view: false,
          login_config: 3,
          headerSetting: {
            header_background: "#ffffff",
            header_color: "black",
            header_text_size: 30,
            hide_back_btn: true,
            header_air: false,
            logo: "",
            logo_slide: "",
            logo_position: 1,
            logo_left_margin: 32,
            open_header_search: false,
            header_search_text: "",
            header_search_background_color: "#F5F5F5",
            header_search_margin_left: 0,
            header_search_width_style: 400,
            header_search_border_color: "#F5F5F5",
            // 输入框颜色
            header_search_text_color: "#999999",
            header_search_text_style: 400,
            // 400 常规 500 加粗 300加细
            header_search_text_size: 24,
            // 文字字号
            header_search_icon_color: "#999999",
            header_search_border_radius: 32,
            // 1 方框 2圆角
            header_search_type: 1
          },
          // 标题栏配置

          bg_image: "",
          //  背景图片
          bg_image_mode: 1,
          // 背景图片缩略模式
          share_image: "",
          //  分享卡片图片
          share_txt: "",
          //  分享卡片标题

          hide_footer: false,
          show_copyright: false,
          footerSetting: {
            footer_type: 1,
            footer_background: "#FFFFFF",
            footer_text_color: "#000000",
            footer_active_color: "#000000",
            footer_text_size: 18,
            footer_margin: 40,
            use_type: 1,
            content: [{
              url: "",
              url_after: "",
              title: "导航文字",
              link: {}
            }, {
              url: "",
              url_after: "",
              title: "导航文字",
              link: {}
            }, {
              url: "",
              url_after: "",
              title: "导航文字",
              link: {}
            }, {
              url: "",
              url_after: "",
              title: "导航文字",
              link: {}
            }, {
              url: "",
              url_after: "",
              title: "导航文字",
              link: {}
            }]
          },
          content: "[]",
          created_time: 1632577555,
          deleted_time: null,
          goods_number: 0,
          is_deleted: 0,
          id: "",
          name: "自定义页面",
          status: 0,
          title: "1632577470131",
          updated_time: 1632745632,
          visit_number: 0
        };
        this.pages = JSON.parse(data.content);
        this.$store.commit("finish/setPageInfo", data);
      }
    },
    /**
     * 拖拽激活
     * @param  {[type]} index    [description]
     * @param  {[type]} position [description]
     * @return {[type]}          [description]
     */
    dragActive: function dragActive(index, position) {
      if (this.dragIndex === index && this.position == position && this.coms) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 拖拽过程事件
     * @return {[type]} [description]
     */
    allowDrop: function allowDrop($event) {
      var index = parseInt($event.target.dataset.index);
      if (index || index === 0) {
        this.dragIndex = index;
        // 判断是否为拖拽元素
        if ($event.type === "dragover" && $event.target.dataset) {
          // 判断是否是拖拽区域
          if ($event.target.dataset.type === "widget") {
            // 获取Y轴移动值
            var y = parseFloat($event.offsetY);
            // 由于缩小50%，需要除去双倍
            var h = parseFloat($event.target.clientHeight / 4);
            if (y < h) {
              this.position = "top";
            } else {
              this.position = "down";
            }
          }
        }
      }
      if (index == -2) {
        this.dragIndex = index;
        this.position = "down";
      }
      $event.preventDefault();
    },
    /**
     * 执行放置，只要在范围内
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    drop: function drop() {
      this.$store.dispatch("finish/pushPagesComs");
    },
    draggableStart: function draggableStart() {
      this.$store.commit("finish/setLock", true);
    },
    draggableEnd: function draggableEnd() {
      this.$store.commit("finish/setLock", false);
    },
    selectMessage: function selectMessage(data) {
      console.log(data);
    }
  }
};