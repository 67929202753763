"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Article',
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      rowList: {},
      visible: false,
      editId: '',
      dialogTable: [],
      roterPre: _settings.roterPre,
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    showJoinAction: function showJoinAction(m) {
      var _this = this;
      this.editId = m.id;
      this.visible = true;
      (0, _marketing.voteDetailApi)(m.id).then(function (res) {
        console.log(res);
        _this.dialogTable = res.data.voteItems;
      }).catch();
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(39);
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _marketing.voteListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _marketing.voteDelApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    },
    // 删除
    stopAction: function stopAction(id, idx) {
      var _this4 = this;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u63D0\u524D\u7ED3\u675F?\u6B64\u64CD\u4F5C\u4E0D\u53EF\u9006\uFF01", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.voteBeforeOverApi)(id).then(function () {
          _this4.$message.success('操作成功');
          _this4.getList();
        }).catch(function (_ref3) {
          var message = _ref3.message;
        });
      }).catch(function () {});
    },
    handleStop: function handleStop(id) {
      var _this5 = this;
      this.$confirm("\u6295\u7968\u786E\u8BA4\u540E\uFF0C\u6240\u6709\u7528\u6237\u6295\u7968\u6240\u4F7F\u7528\u7684\u85CF\u54C1\u5C06\u8FDB\u884C\u9500\u6BC1\u5E76\u4E0D\u53EF\u6062\u590D\u3002\u8BF7\u786E\u8BA4?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.voteCommitApi)(id).then(function () {
          _this5.$message.success('操作成功');
          _this5.getList();
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      }).catch(function () {});
    },
    // 导出
    exportOrder: function exportOrder(id) {
      var _this6 = this;
      (0, _marketing.voteExportApi)({
        id: id
      }).then(function (res) {
        _this6.$message.success('导出成功，稍后请在导出记录里下载');
      });
    }
  }
};