"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var optionsData = [{
  value: '1',
  label: '显示'
}, {
  value: '0',
  label: '隐藏'
}];
var _default = exports.default = {
  name: 'Service',
  data: function data() {
    return {
      isChat: false,
      loadingChat: false,
      dialogTableVisible: false,
      optionsData: optionsData,
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        keyword: '',
        status: ''
      },
      tableChatFrom: {
        page: 1,
        limit: 8
      },
      tableChatData: {
        data: [],
        total: 0
      },
      tableServiceData: {
        data: [],
        total: 0
      },
      serviceId: 0,
      uid: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _system.serviceListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message)
        _this.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 聊天记录列表
    goList: function goList(id) {
      this.serviceId = id;
      this.dialogTableVisible = true;
      this.tableChatFrom.page = 1;
      this.getListChat();
      this.isChat = true;
    },
    goBack: function goBack() {
      this.tableChatFrom.page = 1;
      this.getListChat();
      this.isChat = true;
    },
    check: function check(uid) {
      this.uid = uid;
      this.serviceChatUidList(uid);
      this.isChat = false;
    },
    // 聊天记录列表
    getListChat: function getListChat() {
      var _this2 = this;
      this.loadingChat = true;
      (0, _system.serviceChatListApi)(this.serviceId, this.tableChatFrom).then(function (res) {
        _this2.tableChatData.data = res.data.list;
        _this2.tableChatData.total = res.data.count;
        _this2.loadingChat = false;
      }).catch(function (res) {
        // this.$message.error(res.message)
        _this2.loadingChat = false;
      });
    },
    // 聊天记录列表
    serviceChatUidList: function serviceChatUidList(uid) {
      var _this3 = this;
      this.loadingChat = true;
      (0, _system.serviceChatUidListApi)(this.serviceId, uid, this.tableChatFrom).then(function (res) {
        _this3.tableChatData.data = [];
        _this3.tableServiceData.data = res.data.list;
        _this3.tableServiceData.total = res.data.count;
        _this3.loadingChat = false;
      }).catch(function (res) {
        // this.$message.error(res.message)
        _this3.loadingChat = false;
      });
    },
    pageChangeChat: function pageChangeChat(page) {
      this.tableChatFrom.page = page;
      this.isChat ? this.getListChat() : this.serviceChatUidList(this.uid);
    },
    handleSizeChangeChat: function handleSizeChangeChat(val) {
      this.tableChatFrom.limit = val;
      this.getListChat();
      this.isChat ? this.getListChat() : this.serviceChatUidList(this.uid);
    },
    // 添加
    onAdd: function onAdd() {
      var _this4 = this;
      this.$modalForm((0, _system.serviceCreateApi)()).then(function () {
        return _this4.getList();
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this5 = this;
      this.$modalForm((0, _system.serviceUpdateApi)(id)).then(function () {
        return _this5.getList();
      });
    },
    onDel: function onDel(id, idx) {
      var _this6 = this;
      this.$modalSure('删除该客服吗').then(function () {
        (0, _system.serviceDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this6.$message.success(message);
          _this6.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this6.$message.error(message);
        });
      });
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this7 = this;
      (0, _system.serviceStatusApi)(row.service_id, row.status).then(function (_ref3) {
        var message = _ref3.message;
        _this7.$message.success(message);
        _this7.getList();
      }).catch(function (_ref4) {
        var message = _ref4.message;
        _this7.$message.error(message);
      });
    }
  }
};