"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.to-string");
var _automaticOrder = require("@/api/automaticOrder");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "OrderList",
  data: function data() {
    return {
      searchForm: {
        keyword: "",
        orderNo: "",
        productName: ""
      },
      payArr: [],
      activeName: "1",
      orderList: {
        data: [],
        total: 0
      },
      listLoading: false,
      pageConfig: {
        pageNum: 1,
        pageSize: 10
      },
      saleDialog: false,
      saleParams: {
        price: 0,
        payTypes: []
      },
      selectList: [],
      currentId: []
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getPay();
  },
  methods: {
    getPay: function getPay() {
      var _this = this;
      (0, _automaticOrder.getPayApi)().then(function (res) {
        console.log(res);
        _this.payArr = res.data || [];
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      var _this$saleParams = this.saleParams,
        price = _this$saleParams.price,
        payTypes = _this$saleParams.payTypes;
      if (!price) {
        return this.$message.warning("寄售价格不能为0");
      }
      if (!payTypes.length) {
        return this.$message.warning("请选择支付渠道");
      }
      var data = {
        price: price,
        payTypes: payTypes.toString(),
        memberNftIds: this.currentId.length ? this.currentId : this.selectList
      };
      this.$confirm("确认寄售吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var loadingInstance = _elementUi.Loading.service();
        (0, _automaticOrder.marketApi)(data).then(function (res) {
          _this2.$message.success("寄售成功");
          _this2.closeDialog("saleDialog");
          _this2.$nextTick(function () {
            loadingInstance.close();
          });
          _this2.pageConfig.pageNum = 1;
          _this2.getList(1);
        }).catch(function (err) {
          _this2.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function (_ref) {
        var message = _ref.message;
      });
    },
    handleClick: function handleClick() {
      this.pageConfig.pageNum = 1;
      this.getList();
    },
    getList: function getList(page) {
      var _this3 = this;
      var data = {
        pageNum: page || this.pageConfig.pageNum,
        pageSize: this.pageConfig.pageSize,
        hasMarket: this.activeName == 1 ? false : true
      };
      this.listLoading = true;
      (0, _automaticOrder.autoMemberListApiV2)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), data)).then(function (res) {
        _this3.listLoading = false;
        _this3.orderList.total = res.total;
        _this3.orderList.data = res.rows;
      }).catch(function (err) {
        _this3.listLoading = false;
      });
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      if (this.activeName == 1) {
        this.selectList = val.map(function (item) {
          return item.id;
        });
      } else {
        this.selectList = val.map(function (item) {
          return item.marketId;
        });
      }
    },
    changeChannel: function changeChannel() {},
    submit: function submit() {},
    batchHandle: function batchHandle() {
      if (this.activeName == 1) {
        this.saleDialog = true;
      } else {
        this.cancel(this.selectList);
      }
    },
    cancel: function cancel(id) {
      var _this4 = this;
      this.$confirm("确认取消寄售吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _automaticOrder.cancelApi)({
          marketIds: id
        }).then(function (res) {
          _this4.$message.success("取消成功");
          _this4.pageConfig.pageNum = 1;
          _this4.getList(1);
        }).catch(function (err) {});
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    closeDialog: function closeDialog(ele) {
      if (ele) {
        this[ele] = false;
        this.saleParams = {
          price: 0,
          payTypes: []
        };
      }
    }
  }
};