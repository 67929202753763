"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _menuComponent = _interopRequireDefault(require("@/views/component/menuComponent.vue"));
//
//
//
//
var _default = exports.default = {
  components: {
    menuComponent: _menuComponent.default
  },
  data: function data() {
    return {
      tabsList: [{
        label: 'SaaS产品',
        path: '/merchant/saas/saasProduct/index'
      }, {
        label: 'SaaS套餐',
        path: '/merchant/saas/saasProduct/setMeal'
      }, {
        label: '独立系统',
        path: '/merchant/saas/saasProduct/system'
      }, {
        label: '系统版本',
        path: '/merchant/saas/saasProduct/edition'
      }]
    };
  },
  computed: {},
  mounted: function mounted() {},
  methods: {}
};