"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var userRouter = {
  path: "".concat(_settings.roterPre, "/user"),
  name: "user",
  meta: {
    icon: "dashboard",
    title: "用户管理"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "noRedirect",
  children: [{
    path: "list",
    name: "UserList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/list/userIndex"));
      });
    },
    redirect: "".concat(_settings.roterPre, "/user/list/index"),
    children: [{
      path: "index",
      name: "UserListIndex",
      meta: {
        title: "用户列表",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/user/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/user/list"));
        });
      }
    }, {
      path: "verify",
      name: "UserVerify",
      meta: {
        title: "实名认证",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/user/list/index")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/user/list/userVerify"));
        });
      }
    }, {
      path: "label",
      name: "UserLabel",
      meta: {
        title: "用户标签",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/user/list/index")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/user/group"));
        });
      }
    }, {
      path: "cancellation",
      name: "cancellation",
      meta: {
        title: "注销申请",
        activeMenu: "".concat(_settings.roterPre, "/user/list/index")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/user/cancellation/index"));
        });
      }
    }, {
      path: "new_user_set",
      name: "new_user_set",
      meta: {
        title: "注册配置",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/user/list/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/user/list/new_user_set"));
        });
      }
    }]
  }, {
    path: "group",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/group"));
      });
    },
    name: "UserGroup",
    meta: {
      title: "用户分组",
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/user/list")
    }
  }, {
    path: "maticlabel",
    name: "UserAutoLabel",
    meta: {
      title: "自动标签",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/label"));
      });
    }
  },
  // {
  //   path: "list",
  //   name: "UserList",
  //   meta: { title: "用户列表", noCache: true },
  //   component: () => import("@/views/user/list")
  // },
  {
    path: "issuer",
    name: "UserIssuer",
    meta: {
      title: "发行方管理"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/issuer/list"));
      });
    }
  }, {
    path: "userStatistics",
    name: "userStatistics",
    meta: {
      title: "用户统计",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/userStatistics"));
      });
    }
  }, {
    path: "author",
    name: "UserAuthor",
    meta: {
      title: "作者管理"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/author/list"));
      });
    }
  }, {
    path: "creator",
    name: "UserCreator",
    meta: {
      title: "创作者审核"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/creator/list"));
      });
    }
  }, {
    path: "grade",
    name: "Grade",
    meta: {
      title: "用户等级"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/list/grade"));
      });
    }
  }, {
    path: "builder",
    name: "Builder",
    meta: {
      title: "builder"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/Dao/builderList.vue"));
      });
    }
  }, {
    path: "referralReward",
    name: "ReferralReward",
    meta: {
      title: "推荐奖励"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/Dao/referralReward.vue"));
      });
    }
  }, {
    path: "contribute",
    name: "contribute",
    meta: {
      title: "贡献测量"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/Dao/contrIndex.vue"));
      });
    }
    // component: () => import("@/views/Dao/contribute.vue")
  }, {
    path: "addGrade",
    name: "AddGrade",
    meta: {
      title: "设置等级"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/list/addGrade"));
      });
    }
  }, {
    path: "uptask",
    name: "Uptask",
    meta: {
      title: "升级任务"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/list/uptask"));
      });
    }
  }, {
    path: "invitation",
    name: "Invitation",
    meta: {
      title: "用户邀请"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/list/invitation"));
      });
    }
  }, {
    path: "invitationSet",
    name: "InvitationSet",
    meta: {
      title: "邀请设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/list/invitationSet"));
      });
    }
  }, {
    path: "consumerRankings",
    name: "consumerRankings",
    meta: {
      title: "消费排行"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/consumerRankings/index"));
      });
    }
  }, {
    path: "regiest_set",
    name: "Userregiest_set",
    meta: {
      title: "注册配置",
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/user/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/list/regiest_set"));
      });
    }
  }]
};
var _default = exports.default = userRouter;