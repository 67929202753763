var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { height: "calc(100vh - 124px)" } },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "printTit", staticStyle: { "margin-top": "0" } },
            [_vm._v("印刷价格")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list, size: "small" },
            },
            _vm._l(_vm.columnList, function (item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: { label: item.size, width: "120", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "请输入价格",
                              size: "small",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.onInputBlur(item)
                              },
                            },
                            model: {
                              value: item.price,
                              callback: function ($$v) {
                                _vm.$set(item, "price", $$v)
                              },
                              expression: "item.price",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "printTit", staticStyle: { "margin-top": "30px" } },
            [
              _vm._v("画框价格 \n          "),
              _c("i", {
                staticClass: "el-icon-circle-plus cameraIconfont",
                staticStyle: { "margin-left": "20px", cursor: "pointer" },
                on: { click: _vm.addFrame },
              }),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.frameList, function (item, index) {
            return _c("div", { key: index, staticClass: "flexBox" }, [
              _c(
                "div",
                {
                  staticClass: "upLoadPicBox",
                  staticStyle: { "margin-right": "20px" },
                  on: {
                    click: function ($event) {
                      return _vm.updOpen(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "upLoad" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: item.frameImage, alt: "" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "frameTit" }, [
                    _vm._v(_vm._s(item.frameName)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "0", flex: "1" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.list, size: "small" },
                    },
                    _vm._l(item.sizeForFrames, function (ele, i) {
                      return _c("el-table-column", {
                        key: i,
                        attrs: {
                          label: ele.sizeName,
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      placeholder: "请输入价格",
                                      size: "small",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.onInputBlurOne(ele)
                                      },
                                    },
                                    model: {
                                      value: ele.price,
                                      callback: function ($$v) {
                                        _vm.$set(ele, "price", $$v)
                                      },
                                      expression: "ele.price",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.visibleLabel
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleLabel,
                "close-on-click-modal": false,
                center: "",
                title: "添加画框",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleLabel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "labelForm",
                  attrs: {
                    size: "small",
                    model: _vm.formState,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "画框名称", prop: "frameName" } },
                    [
                      _c("el-input", {
                        staticClass: "w300",
                        attrs: { placeholder: "请输入画框名称" },
                        model: {
                          value: _vm.formState.frameName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formState, "frameName", $$v)
                          },
                          expression: "formState.frameName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "画框图片", prop: "frameImage" } },
                    [
                      _c("div", { staticClass: "upBbb" }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap()
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "upLoad" }, [
                              _vm.formState.frameImage
                                ? _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: {
                                      src: _vm.formState.frameImage,
                                      alt: "",
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }