var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearfix" },
        [
          _c(
            "el-form",
            { attrs: { size: "small", inline: "", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "type", $$v)
                        },
                        expression: "queryParams.type",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("企业"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("个人"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "身份认证：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.identityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "identityId", $$v)
                        },
                        expression: "queryParams.identityId",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.identityList, function (m, i) {
                        return _c(
                          "el-radio-button",
                          { key: i, attrs: { label: m.id } },
                          [_vm._v(_vm._s(m.identityName))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开通服务：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.serviceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "serviceType", $$v)
                        },
                        expression: "queryParams.serviceType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("IPIP星球空间"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("云总部SaaS"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("独立系统"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "4" } }, [
                        _vm._v("Open3D"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "5" } }, [
                        _vm._v("ChainID"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "6" } }, [
                        _vm._v("云代拍API"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "7" } }, [
                        _vm._v("有证API"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "访问时间：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      size: "small",
                      type: "datetimerange",
                    },
                    on: { change: _vm.onchangeTime },
                    model: {
                      value: _vm.queryParams.timeVal,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "timeVal", $$v)
                      },
                      expression: "queryParams.timeVal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "客户名称/手机号" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.queryParams.phoneOrName,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "phoneOrName", $$v)
                },
                expression: "queryParams.phoneOrName",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "SaaS商户Id" },
              model: {
                value: _vm.queryParams.sassMerId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "sassMerId", $$v)
                },
                expression: "queryParams.sassMerId",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { width: "50" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        staticClass: "tabPop",
                        attrs: {
                          placement: "top-start",
                          width: "100",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "spBlock onHand",
                              class: { check: _vm.chkName === "dan" },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandle("dan", scope.$index)
                                },
                              },
                            },
                            [_vm._v("选中本页")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-checkbox", {
                          attrs: {
                            slot: "reference",
                            value:
                              (_vm.chkName === "dan" &&
                                _vm.checkedPage.indexOf(
                                  _vm.queryParams.pageNum
                                ) > -1) ||
                              _vm.chkName === "duo",
                          },
                          on: { change: _vm.changeType },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: {
                        value:
                          _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                          (_vm.chkName === "duo" &&
                            _vm.noChecked.indexOf(scope.row.id) === -1),
                      },
                      on: {
                        change: function (v) {
                          return _vm.changeOne(v, scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "160" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: { src: scope.row.avatar, fit: "cover" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商户名称", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.nickNameClick(row.userId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(row.nickName) + "(" + _vm._s(row.name) + ")"
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "手机号", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.phone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "认证类型", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(_vm._s(row.identityNames.join(",")))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "身份", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.type))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "开通服务", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.serviceNames))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "认证时间", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "150",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.setLabel(scope.row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.setLabel(scope.row)
                          },
                        },
                      },
                      [_vm._v("标签")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.loginSass(scope.row)
                          },
                        },
                      },
                      [_vm._v("登录商户账号")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("div", { staticClass: "flex-one align-items-c flex" }),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              "page-size": Number(_vm.queryParams.pageSize),
              "current-page": Number(_vm.queryParams.pageNum),
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleUser
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleUser,
                "close-on-click-modal": false,
                center: "",
                title: "添加用户",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleUser = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "addUserForm",
                  attrs: {
                    size: "small",
                    model: _vm.addUserForm,
                    rules: _vm.userRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户头像", prop: "avatar" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "item-align-center mb20",
                          staticStyle: {
                            display: "inline-block",
                            color: "#606266",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upload-img-view item-flex-center",
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap(1, "image")
                                },
                              },
                            },
                            [
                              _vm.addUserForm.avatar
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "del-upload-img",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delImg("image")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2023131/image/1675135522101940138.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.addUserForm.avatar
                                ? _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: {
                                      src: _vm.addUserForm.avatar + "!120a",
                                    },
                                  })
                                : _c("div", {
                                    staticClass: "upload-icon",
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px",
                                      background: "#eaeef4",
                                    },
                                  }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户名", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addUserForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.addUserForm, "name", $$v)
                          },
                          expression: "addUserForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "phone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addUserForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.addUserForm, "phone", $$v)
                          },
                          expression: "addUserForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.addUserForm.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.addUserForm, "sex", $$v)
                            },
                            expression: "addUserForm.sex",
                          },
                        },
                        [_vm._v("男")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.addUserForm.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.addUserForm, "sex", $$v)
                            },
                            expression: "addUserForm.sex",
                          },
                        },
                        [_vm._v("女")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmUserAction("addUserForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleLabel
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleLabel,
                "close-on-click-modal": false,
                center: "",
                title: "修改标签",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleLabel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "labelForm",
                  attrs: {
                    size: "small",
                    model: _vm.labelForm,
                    rules: _vm.labelRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户标签", prop: "tagId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: "请选择用户标签",
                          },
                          model: {
                            value: _vm.labelForm.tagId,
                            callback: function ($$v) {
                              _vm.$set(_vm.labelForm, "tagId", $$v)
                            },
                            expression: "labelForm.tagId",
                          },
                        },
                        _vm._l(_vm.tagList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmLabelAction("labelForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }