var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px 0" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { path: _vm.roterPre + "/marketing/vote/detail" },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("创建投票")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("查看导出记录")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投票名称", "min-width": "150", prop: "name" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "参与人数", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showJoinAction(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.numberParticipants))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动时间", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(scope.row.startTime) +
                          " - " +
                          _vm._s(scope.row.endTime)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "min-width": "100", prop: "isStart" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c("div", [_vm._v("待开始")])
                      : scope.row.status == 1
                      ? _c("div", [_vm._v("进行中")])
                      : scope.row.status == -1
                      ? _c("div", [_vm._v("已结束")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "120", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  _vm.roterPre +
                                  "/marketing/vote/detail?id=" +
                                  scope.row.id,
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == -1 && !scope.row.hasCommit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleStop(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("投票确认")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == -1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.exportOrder(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("导出名单")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status != 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.stopAction(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("提前结束")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.pageSize,
              "current-page": _vm.tableFrom.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "投票详情",
            visible: _vm.visible,
            "show-close": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex align-items-c",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-one fWeight500",
                  staticStyle: { "font-size": "18px" },
                },
                [_vm._v("投票详情")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.exportOrder(_vm.editId)
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { size: "small", type: "text" },
                      on: { click: _vm.getExportFileList },
                    },
                    [_vm._v("查看导出记录")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.dialogTable, function (m, i) {
            return _c("div", { key: "dialogTable" + i, staticClass: "mb20" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(_vm._s(i + 1) + "、" + _vm._s(m.title)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                _vm._l(m.voteItemContexts, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "voteItemContexts" + index + i,
                      staticClass: "flex align-items-c mt10",
                    },
                    [
                      item.image
                        ? _c("div", { staticClass: "img mr10" }, [
                            _c("img", {
                              staticStyle: { width: "50px" },
                              attrs: { src: item.image + "!120a" },
                            }),
                          ])
                        : _c("div", {
                            staticClass: "img mr10",
                            staticStyle: { width: "50px" },
                          }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mr10" }, [
                        _vm._v(_vm._s(item.sort) + "、" + _vm._s(item.title)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w300" },
                        [
                          _c("el-progress", {
                            attrs: {
                              "show-text": false,
                              percentage: item.proportion,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml10 ft12" }, [
                        _vm._v(
                          _vm._s(item.count) +
                            "（" +
                            _vm._s(item.proportion) +
                            "%）"
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }