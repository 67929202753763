"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Firstbuy',
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      roterPre: _settings.roterPre
    };
  },
  filters: {
    formatType: function formatType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '按名单';
          break;
        case 2:
          res = '自定义规则';
          break;
        default:
          break;
      }
      return res;
    },
    formatProductType: function formatProductType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '作品';
          break;
        case 2:
          res = '盲盒';
          break;
        case 3:
          res = '专辑';
          break;
        default:
          break;
      }
      return res;
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _product.priorityListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push({
        path: _settings.roterPre + '/product/addFirstBuy'
      });
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u4F18\u5148\u8D2D?", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _product.priorityDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this2.$message.success('操作成功');
          _this2.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      }).catch(function () {});
    }
  }
};