var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navigation",
      style: { "background-color": _vm.facade.background_color },
    },
    [
      _c(
        "div",
        {
          staticClass: "nav",
          class: {
            "item-align-center": _vm.content.style === 2,
            "display-flex": _vm.content.style === 1,
            "justify-center": _vm.facade.nav_style === 2,
          },
          style: {
            paddingLeft: _vm.facade.page_padding / 2 + "px",
            paddingRight:
              (_vm.facade.nav_style === 2 ? _vm.facade.page_padding / 2 : 0) +
              "px",
          },
        },
        _vm._l(_vm.navigationData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item",
              class: { "flex-one": _vm.facade.nav_style === 2 },
              style:
                index > 0 && _vm.facade.nav_style === 1
                  ? "margin-left:" +
                    parseInt(_vm.facade.item_padding / 2, 10) +
                    "px;"
                  : "",
            },
            [
              _vm.content.style === 1
                ? _c(
                    "div",
                    {
                      staticClass: "img item-flex-center",
                      style: { height: _vm.facade.img_height / 2 + "px" },
                    },
                    [
                      item.url
                        ? _c("img", {
                            style: {
                              width: _vm.facade.img_width / 2 + "px",
                              height: _vm.facade.img_height / 2 + "px",
                            },
                            attrs: { src: item.url, alt: "" },
                          })
                        : _c(
                            "div",
                            {
                              staticClass: "img-view item-flex-center",
                              style: {
                                width: _vm.facade.img_width / 2 + "px",
                                height: _vm.facade.img_height / 2 + "px",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "txt",
                  style: {
                    color:
                      _vm.facade.is_hover && index == _vm.facade.hover_index - 1
                        ? _vm.facade.hover_color
                        : _vm.facade.text_color,
                    fontWeight:
                      _vm.facade.is_hover && index == _vm.facade.hover_index - 1
                        ? 500
                        : _vm.facade.text_style,
                    fontSize: parseInt(_vm.facade.text_size / 2, 10) + "px",
                  },
                },
                [
                  _vm._v(_vm._s(item.title) + " "),
                  _vm.facade.is_hover && index == _vm.facade.hover_index - 1
                    ? _c("div", {
                        staticClass: "bg",
                        style: { background: _vm.facade.hover_color },
                      })
                    : _vm._e(),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }