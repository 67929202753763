"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.additionalIssuanceApi = additionalIssuanceApi;
exports.authorListApi = authorListApi;
exports.downloadApi = downloadApi;
exports.editWorksDetailApi = editWorksDetailApi;
exports.empowerListApi = empowerListApi;
exports.getModelListApi = getModelListApi;
exports.getWorksDetailApi = getWorksDetailApi;
exports.getWorksEvidenListApi = getWorksEvidenListApi;
exports.getWorksListApi = getWorksListApi;
exports.getWorksNewListApi = getWorksNewListApi;
exports.issuedWorksApi = issuedWorksApi;
exports.issuedWorksUpdateApi = issuedWorksUpdateApi;
exports.modelExportApi = modelExportApi;
exports.productDelApi = productDelApi;
exports.transferenceList = transferenceList;
exports.updateSortApi = updateSortApi;
exports.updateopenidDProductApi = updateopenidDProductApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 作品列表
 */
function getWorksListApi(data) {
  return _request.default.post("open3d/product/sys/product/v2/list", data);
}
function getWorksNewListApi(data) {
  return _request.default.get("/open3d/product/sys/exhibit/list", data);
}
function getWorksEvidenListApi(data) {
  return _request.default.get("/open3d/product/sys/exhibit/evidenceList", data);
}

/**
 * @description 作品详情
 */
function getWorksDetailApi(id) {
  return _request.default.get("/open3d/product/sys/product/v2/info/".concat(id));
}

/**
 * @description 修改作品
 */
function editWorksDetailApi(data) {
  return _request.default.post("/open3d/product/sys/product/v2/update", data);
}

/**
 * @description 作者列表
 */
function authorListApi(data) {
  return _request.default.get("/open3d/product/sys/author/list", data);
}

/**
 * @description 发行作品
 */
function issuedWorksApi(data) {
  return _request.default.post("/open3d/product/sys/product/v2/issued", data);
}

/**
 * @description 发行后编辑作品
 */
function issuedWorksUpdateApi(data) {
  return _request.default.post("/open3d/product/sys/product/v2/issuedUpdate", data);
}

/**
 * @description 作品授权列表
 */
function empowerListApi(data) {
  return _request.default.post("/open3d/product/sys/product/v2/empowerList", data);
}

/**
 * @description 版权出售列表
 */
function transferenceList(data) {
  return _request.default.post("/open3d/product/sys/product/v2/transferenceList", data);
}

/**
 * @description 修改排序
 */
function updateSortApi(data) {
  return _request.default.post("/open3d/product/sys/product/v2/updateSort", data);
}

/**
 * @description 修改状态
 */
function updateopenidDProductApi(data) {
  return _request.default.post("/open3d/product/sys/product/v2/updateProduct", data);
}
/**
 * @description 删除
 */
function productDelApi(nftId) {
  return _request.default.get("/open3d/product/sys/product/v2/delete/".concat(nftId));
}

/**
 * @description 增发
 */
function additionalIssuanceApi(data) {
  return _request.default.post("/open3d/product/sys/product/v2/additionalIssuance", data);
}
/**
 * @description 建模列表
 */

function getModelListApi(data) {
  return _request.default.get("/open3d/product/sys/model/list", data);
}
//导出
function modelExportApi(data) {
  return _request.default.get("/open3d/product/sys/model/export", data);
}
//下载

function downloadApi(id) {
  return _request.default.post("/open3d/product/sys/model/download/".concat(id));
}