var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onAdd },
                    },
                    [_vm._v("新增字体")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "id",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "字体名称", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "排序", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticClass: "w80",
                          on: {
                            change: function (val) {
                              return _vm.editSortAction(val, scope.row)
                            },
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "120",
                  fixed: "right",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex justify-end" }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "4px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAction(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "4px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showVideo,
            title: "预览视频",
            "append-to-body": "",
            width: _vm.videoRatio == "16:9" ? "800px" : "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVideo = $event
            },
          },
        },
        [
          _c("div", { staticClass: "item-flex-center" }, [
            _c("video", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.videoUrl, controls: "" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detail.show,
            title: "新增字体",
            "append-to-body": "",
            width: "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.detail, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.detail,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-input", {
                    staticClass: "w200",
                    attrs: { placeholder: "输入字体名称" },
                    model: {
                      value: _vm.detail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "name", $$v)
                      },
                      expression: "detail.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c("el-input", {
                    staticClass: "w200",
                    attrs: { placeholder: "输入排序" },
                    model: {
                      value: _vm.detail.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "sort", $$v)
                      },
                      expression: "detail.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.uploadLoading,
                          expression: "uploadLoading",
                        },
                      ],
                      staticClass: "upload-demo",
                      attrs: {
                        drag: "",
                        action: "",
                        "show-file-list": false,
                        "http-request": function (file) {
                          return _vm.uploadAllImg(file)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.detail.url
                    ? _c("div", { staticClass: "flex mt10" }, [
                        _c("div", { staticClass: "flex-one" }, [
                          _vm._v(_vm._s(_vm.uploadName)),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-delete cur",
                          on: {
                            click: function ($event) {
                              _vm.detail.url = ""
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.detail.btnLoading },
                      on: {
                        click: function ($event) {
                          _vm.detail.show = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.detail.btnLoading,
                        type: "primary",
                      },
                      on: { click: _vm.confirmAction },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }