var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "战机名称：", prop: "fighterName" } },
                [
                  _c("el-input", {
                    staticClass: "selwidth",
                    attrs: { placeholder: "请输入战机名称" },
                    model: {
                      value: _vm.formValidate.fighterName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "fighterName", $$v)
                      },
                      expression: "formValidate.fighterName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "战机图片：",
                    prop: "fighterImage",
                    required: "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      attrs: { title: "正方形图片" },
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "fighterImage")
                        },
                      },
                    },
                    [
                      _vm.formValidate.fighterImage
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.fighterImage + "!120a",
                              },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("战机图片")]),
                          ]),
                    ]
                  ),
                  _vm._v("\n        比例1:1,png透明图片\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "星球名称", prop: "planetName" } },
                [
                  _c("el-input", {
                    staticClass: "selwidth",
                    attrs: { placeholder: "请输入星球名称" },
                    model: {
                      value: _vm.formValidate.planetName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "planetName", $$v)
                      },
                      expression: "formValidate.planetName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "星球图片：",
                    prop: "planetImage",
                    required: "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      attrs: { title: "正方形图片" },
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "planetImage")
                        },
                      },
                    },
                    [
                      _vm.formValidate.planetImage
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.planetImage + "!120a",
                              },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("星球图片")]),
                          ]),
                    ]
                  ),
                  _vm._v("\n        比例1:1,png透明图片\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类", prop: "classifyId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "value-key": "",
                        placeholder: "",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: function ($event) {} },
                      model: {
                        value: _vm.formValidate.classifyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "classifyId", $$v)
                        },
                        expression: "formValidate.classifyId",
                      },
                    },
                    _vm._l(_vm.classList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.classifyName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "耐久度", prop: "hitPoint" } },
                [
                  _c("el-input-number", {
                    staticClass: "selwidth",
                    attrs: {
                      min: 1,
                      controls: true,
                      placeholder: "请输入耐久度",
                    },
                    model: {
                      value: _vm.formValidate.hitPoint,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "hitPoint", $$v)
                      },
                      expression: "formValidate.hitPoint",
                    },
                  }),
                  _vm._v(
                    "\n        耐久度只能输入数字,即每架战机的可可采矿次数\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "成功率", prop: "rateOfSuccess" } },
                [
                  _c("el-input-number", {
                    staticClass: "selwidth",
                    attrs: {
                      size: "normal",
                      min: 1,
                      max: 100,
                      step: 1,
                      controls: true,
                      "controls-position": "both",
                    },
                    model: {
                      value: _vm.formValidate.rateOfSuccess,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "rateOfSuccess", $$v)
                      },
                      expression: "formValidate.rateOfSuccess",
                    },
                  }),
                  _vm._v("\n        %\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "挖矿时长", prop: "fighterMiningTime" } },
                [
                  _c("el-input-number", {
                    staticClass: "selwidth",
                    attrs: {
                      min: 1,
                      placeholder: "请输入挖矿时长",
                      controls: false,
                    },
                    model: {
                      value: _vm.formValidate.fighterMiningTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "fighterMiningTime", $$v)
                      },
                      expression: "formValidate.fighterMiningTime",
                    },
                  }),
                  _vm._v("\n         分钟\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产出", required: "" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.output, border: "", stripe: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "类型", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: scope.row.type,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "type", $$v)
                                      },
                                      expression: "scope.row.type",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-option",
                                      { attrs: { label: "藏品", value: 1 } },
                                      [_vm._v("藏品")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-option",
                                      { attrs: { label: "积分", value: 2 } },
                                      [_vm._v("积分")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "名称", "min-width": "140" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.type == 1
                                  ? [
                                      scope.row.name
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-image__preview",
                                                  staticStyle: {
                                                    "flex-shrink": "0",
                                                    "margin-right": "6px",
                                                  },
                                                },
                                                [
                                                  _c("el-image", {
                                                    attrs: {
                                                      src:
                                                        scope.row.image +
                                                        "!120a",
                                                      "preview-src-list": [
                                                        scope.row.aircraft_img,
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "name" },
                                                [_vm._v(_vm._s(scope.row.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "default",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleAdditem(
                                                        "output",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("更换")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "default",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleAdditem(
                                                        "output",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择")]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  : scope.row.type == 2
                                  ? [
                                      _vm._v(
                                        "\n                Y积分\n              "
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "库存", "min-width": "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.type == 1
                                  ? [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(scope.row.stock || "") +
                                          "\n              "
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产出数量", "min-width": "140" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 1,
                                    step: 1,
                                    controls: true,
                                    "controls-position": "both",
                                  },
                                  model: {
                                    value: scope.row.giveNum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "giveNum", $$v)
                                    },
                                    expression: "scope.row.giveNum",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产出概率", "min-width": "140" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    disabled: scope.$index == 0,
                                    step: 1,
                                    controls: true,
                                    "controls-position": "both",
                                    max: 100,
                                  },
                                  on: {
                                    change: function () {
                                      _vm.changerate()
                                    },
                                  },
                                  model: {
                                    value: scope.row.probability,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "probability", $$v)
                                    },
                                    expression: "scope.row.probability",
                                  },
                                }),
                                _vm._v("\n              %\n            "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.$index > 0
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "default",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.dellist(
                                                "output",
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.addlist("output")
                            },
                          },
                        },
                        [_vm._v("+添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._v("\n            全部产出\n            "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": true,
                              "inactive-value": false,
                            },
                            model: {
                              value: _vm.outtype,
                              callback: function ($$v) {
                                _vm.outtype = $$v
                              },
                              expression: "outtype",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "兑换条件", required: "" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.condition, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "名称", "min-width": "140" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.name
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                            staticStyle: {
                                              "flex-shrink": "0",
                                              "margin-right": "6px",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image + "!120a",
                                                "preview-src-list": [
                                                  scope.row.aircraft_img,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(_vm._s(scope.row.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "condition",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("更换")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "condition",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "需要数量" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 1,
                                    step: 1,
                                    controls: true,
                                    "controls-position": "both",
                                  },
                                  model: {
                                    value: scope.row.needNum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "needNum", $$v)
                                    },
                                    expression: "scope.row.needNum",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.$index > 0
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "default",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.dellist(
                                                "condition",
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.addlist("condition")
                            },
                          },
                        },
                        [_vm._v("+添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._v("\n            需满足全部条件\n            "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": true,
                              "inactive-value": false,
                            },
                            model: {
                              value: _vm.conditiontype,
                              callback: function ($$v) {
                                _vm.conditiontype = $$v
                              },
                              expression: "conditiontype",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "补充耐久度" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.durability, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.name
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                            staticStyle: {
                                              "flex-shrink": "0",
                                              "margin-right": "6px",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image + "!120a",
                                                "preview-src-list": [
                                                  scope.row.aircraft_img,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(_vm._s(scope.row.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "durability",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("更换")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "durability",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "需要数量" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 1,
                                    step: 1,
                                    controls: true,
                                    "controls-position": "both",
                                  },
                                  model: {
                                    value: scope.row.needNum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "needNum", $$v)
                                    },
                                    expression: "scope.row.needNum",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "default" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.dellist(
                                          "durability",
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.addlist("durability")
                            },
                          },
                        },
                        [_vm._v("+添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._v("\n            需满足全部条件\n            "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": true,
                              "inactive-value": false,
                            },
                            model: {
                              value: _vm.durabilitytype,
                              callback: function ($$v) {
                                _vm.durabilitytype = $$v
                              },
                              expression: "durabilitytype",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm.formValidate.fighterStrengthens.length > 0
            ? _vm._l(
                _vm.formValidate.fighterStrengthens,
                function (item, index) {
                  return _c(
                    "el-form",
                    {
                      key: index,
                      ref: "customForm",
                      refInFor: true,
                      attrs: {
                        model: item,
                        rules: _vm.rules,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "强化名称", prop: "strengthenName" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "selwidth",
                            attrs: { placeholder: "请输入强化名称" },
                            model: {
                              value: item.strengthenName,
                              callback: function ($$v) {
                                _vm.$set(item, "strengthenName", $$v)
                              },
                              expression: "item.strengthenName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "挖矿时长",
                            prop: "fighterMiningTime",
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "selwidth",
                            attrs: {
                              min: 1,
                              placeholder: "请输入挖矿时长",
                              controls: false,
                            },
                            model: {
                              value: item.fighterMiningTime,
                              callback: function ($$v) {
                                _vm.$set(item, "fighterMiningTime", $$v)
                              },
                              expression: "item.fighterMiningTime",
                            },
                          }),
                          _vm._v("\n           分钟\n        "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "成功率", prop: "rateOfSuccess" } },
                        [
                          _c("el-input-number", {
                            staticClass: "selwidth",
                            attrs: {
                              size: "normal",
                              min: 1,
                              max: 100,
                              step: 1,
                              controls: true,
                              "controls-position": "both",
                            },
                            model: {
                              value: _vm.formValidate.rateOfSuccess,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "rateOfSuccess", $$v)
                              },
                              expression: "formValidate.rateOfSuccess",
                            },
                          }),
                          _vm._v("\n          %\n        "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产出", required: "" } },
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: item.fighterMiningPrizeList,
                                border: "",
                                stripe: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "类型", "min-width": "100" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-select",
                                            {
                                              model: {
                                                value: scope.row.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.type",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    label: "藏品",
                                                    value: 1,
                                                  },
                                                },
                                                [_vm._v("藏品")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    label: "积分",
                                                    value: 2,
                                                  },
                                                },
                                                [_vm._v("积分")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "名称", "min-width": "140" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.type == 1
                                            ? [
                                                scope.row.name
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "demo-image__preview",
                                                            staticStyle: {
                                                              "flex-shrink":
                                                                "0",
                                                              "margin-right":
                                                                "6px",
                                                            },
                                                          },
                                                          [
                                                            _c("el-image", {
                                                              attrs: {
                                                                src:
                                                                  scope.row
                                                                    .image +
                                                                  "!120a",
                                                                "preview-src-list":
                                                                  [
                                                                    scope.row
                                                                      .aircraft_img,
                                                                  ],
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "default",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleAdditem(
                                                                  "fighterMiningPrizeList",
                                                                  scope.$index,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("更换")]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "default",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleAdditem(
                                                                  "fighterMiningPrizeList",
                                                                  scope.$index,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("选择")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            : scope.row.type == 2
                                            ? [
                                                _vm._v(
                                                  "\n                  Y积分\n                "
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "库存", "min-width": "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.type == 1
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      scope.row.stock || ""
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产出数量",
                                  "min-width": "140",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input-number", {
                                            attrs: {
                                              min: 1,
                                              step: 1,
                                              controls: true,
                                              "controls-position": "both",
                                            },
                                            model: {
                                              value: scope.row.giveNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "giveNum",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.giveNum",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产出概率",
                                  "min-width": "140",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input-number", {
                                            attrs: {
                                              disabled: scope.$index == 0,
                                              step: 1,
                                              controls: true,
                                              "controls-position": "both",
                                              max: 100,
                                            },
                                            on: {
                                              change: function () {
                                                _vm.changerate(index)
                                              },
                                            },
                                            model: {
                                              value: scope.row.probability,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "probability",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.probability",
                                            },
                                          }),
                                          _vm._v(
                                            "\n                %\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "操作", "min-width": "100" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return scope.$index > 0
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "default",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.dellist(
                                                        "fighterMiningPrizeList",
                                                        scope.$index,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          : undefined
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "default" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addlist(
                                        "fighterMiningPrizeList",
                                        index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("+添加")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _vm._v(
                                    "\n              全部产出\n              "
                                  ),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": true,
                                      "inactive-value": false,
                                    },
                                    model: {
                                      value: item.isAllGive,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isAllGive", $$v)
                                      },
                                      expression: "item.isAllGive",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "强化条件", required: "" } },
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: item.exchangeConditionList,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "名称", "min-width": "140" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.name
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "demo-image__preview",
                                                      staticStyle: {
                                                        "flex-shrink": "0",
                                                        "margin-right": "6px",
                                                      },
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        attrs: {
                                                          src:
                                                            scope.row.image +
                                                            "!120a",
                                                          "preview-src-list": [
                                                            scope.row
                                                              .aircraft_img,
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "name" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(scope.row.name)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "default",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleAdditem(
                                                            "exchangeConditionList",
                                                            scope.$index,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("更换")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "default",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleAdditem(
                                                            "exchangeConditionList",
                                                            scope.$index,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("选择")]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "需要数量" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input-number", {
                                            attrs: {
                                              min: 1,
                                              step: 1,
                                              controls: true,
                                              "controls-position": "both",
                                            },
                                            model: {
                                              value: scope.row.needNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "needNum",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.needNum",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "强化成功率" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input-number", {
                                            attrs: {
                                              step: 1,
                                              controls: true,
                                              "controls-position": "both",
                                              max: 100,
                                            },
                                            on: {
                                              change: function () {
                                                _vm.changerate(index)
                                              },
                                            },
                                            model: {
                                              value: scope.row.strengthenRate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "strengthenRate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.strengthenRate",
                                            },
                                          }),
                                          _vm._v(
                                            "\n                %\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return scope.$index > 0
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "default",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.dellist(
                                                        "exchangeConditionList",
                                                        scope.$index,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          : undefined
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "default" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addlist("condition", index)
                                    },
                                  },
                                },
                                [_vm._v("+添加")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small ", plain: "" },
              on: { click: _vm.addEnhancedFighter },
            },
            [_vm._v("创建强化战机")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "submit",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formValidate")
                },
              },
            },
            [_vm._v("\n      提交\n    ")]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }