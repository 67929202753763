var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mealBox" },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formState,
            rules: _vm.rules,
            size: "small",
            inline: "",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { staticClass: "formBlock", attrs: { prop: "systemType" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { type: "button" },
                  on: { input: _vm.changeSystemType },
                  model: {
                    value: _vm.formState.systemType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "systemType", $$v)
                    },
                    expression: "formState.systemType",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    {
                      attrs: {
                        disabled: _vm.formState.systemType == 2,
                        label: "1",
                      },
                    },
                    [_vm._v("SaaS系统")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-button",
                    {
                      attrs: {
                        disabled: _vm.formState.systemType == 1,
                        label: "2",
                      },
                    },
                    [_vm._v("独立系统")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formBlock",
              attrs: { label: "套餐名称：", prop: "packageName" },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCreate,
                    expression: "isCreate",
                  },
                ],
                staticClass: "inputWidth",
                attrs: { size: "small", placeholder: "请输入套餐名称" },
                model: {
                  value: _vm.formState.packageName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "packageName", $$v)
                  },
                  expression: "formState.packageName",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCreate,
                      expression: "!isCreate",
                    },
                  ],
                  staticClass: "inputWidth",
                  attrs: { placeholder: "请选择套餐名称" },
                  model: {
                    value: _vm.formState.packageName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "packageName", $$v)
                    },
                    expression: "formState.packageName",
                  },
                },
                _vm._l(_vm.packageNames, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onIsCreate } },
                [_vm._v(_vm._s(_vm.isCreate ? "选择已有名称" : "创建新名称"))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formBlock",
              attrs: { label: "版本名称：", prop: "versionName" },
            },
            [
              _c("el-input", {
                staticClass: "inputWidth",
                attrs: { size: "small", placeholder: "请输入版本名称" },
                model: {
                  value: _vm.formState.versionName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "versionName", $$v)
                  },
                  expression: "formState.versionName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formState.systemType == 2
            ? _c(
                "el-form-item",
                {
                  staticClass: "formBlock",
                  attrs: { label: "系统价格：", prop: "systemPrice" },
                },
                [
                  _c("el-input", {
                    staticClass: "inputWidth",
                    attrs: { size: "small", placeholder: "请输入系统价格" },
                    model: {
                      value: _vm.formState.systemPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.formState, "systemPrice", $$v)
                      },
                      expression: "formState.systemPrice",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formBlock",
              attrs: { label: "套餐功能：", prop: "relationProductId" },
            },
            [
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-plus", circle: "" },
                on: { click: _vm.addFeature },
              }),
              _vm._v(" "),
              _vm._l(_vm.relationProductList, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { "margin-top": "20px" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "inputWidth",
                        attrs: { placeholder: "选择功能" },
                        model: {
                          value: item.productId,
                          callback: function ($$v) {
                            _vm.$set(item, "productId", $$v)
                          },
                          expression: "item.productId",
                        },
                      },
                      _vm._l(_vm.productList, function (itm) {
                        return _c("el-option", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: itm.isShow,
                              expression: "itm.isShow",
                            },
                          ],
                          key: itm.productId,
                          attrs: {
                            label: itm.productName,
                            value: itm.productId,
                          },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-circle-close",
                      on: {
                        click: function ($event) {
                          return _vm.deleteProduct(item)
                        },
                      },
                    }),
                    _vm._v(" "),
                    item.price
                      ? _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v("产品价格：" + _vm._s(item.price) + "元"),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.formState.systemType == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "套餐价格：", prop: "packagePrice" } },
                [
                  _vm._v("\n      1年："),
                  _c("el-input", {
                    staticClass: "inputMinWidth",
                    attrs: {
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      size: "small",
                      placeholder: "请输入金额",
                    },
                    model: {
                      value: _vm.priceList[0].price,
                      callback: function ($$v) {
                        _vm.$set(_vm.priceList[0], "price", $$v)
                      },
                      expression: "priceList[0].price",
                    },
                  }),
                  _vm._v("\n      3年："),
                  _c("el-input", {
                    staticClass: "inputMinWidth",
                    attrs: {
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      size: "small",
                      placeholder: "请输入金额",
                    },
                    model: {
                      value: _vm.priceList[1].price,
                      callback: function ($$v) {
                        _vm.$set(_vm.priceList[1], "price", $$v)
                      },
                      expression: "priceList[1].price",
                    },
                  }),
                  _vm._v("\n      5年："),
                  _c("el-input", {
                    staticClass: "inputMinWidth",
                    attrs: {
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      size: "small",
                      placeholder: "请输入金额",
                    },
                    model: {
                      value: _vm.priceList[2].price,
                      callback: function ($$v) {
                        _vm.$set(_vm.priceList[2], "price", $$v)
                      },
                      expression: "priceList[2].price",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.formState.systemType == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "技术维护费：", prop: "packagePrice" } },
                [
                  _vm._v("\n      1个月："),
                  _c("el-input", {
                    staticClass: "inputMinWidth",
                    attrs: {
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      size: "small",
                      placeholder: "请输入金额",
                    },
                    model: {
                      value: _vm.indepPriceList[0].price,
                      callback: function ($$v) {
                        _vm.$set(_vm.indepPriceList[0], "price", $$v)
                      },
                      expression: "indepPriceList[0].price",
                    },
                  }),
                  _vm._v("\n      3个月："),
                  _c("el-input", {
                    staticClass: "inputMinWidth",
                    attrs: {
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      size: "small",
                      placeholder: "请输入金额",
                    },
                    model: {
                      value: _vm.indepPriceList[1].price,
                      callback: function ($$v) {
                        _vm.$set(_vm.indepPriceList[1], "price", $$v)
                      },
                      expression: "indepPriceList[1].price",
                    },
                  }),
                  _vm._v("\n      6个月："),
                  _c("el-input", {
                    staticClass: "inputMinWidth",
                    attrs: {
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      size: "small",
                      placeholder: "请输入金额",
                    },
                    model: {
                      value: _vm.indepPriceList[2].price,
                      callback: function ($$v) {
                        _vm.$set(_vm.indepPriceList[2], "price", $$v)
                      },
                      expression: "indepPriceList[2].price",
                    },
                  }),
                  _vm._v("\n      12个月："),
                  _c("el-input", {
                    staticClass: "inputMinWidth",
                    attrs: {
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      size: "small",
                      placeholder: "请输入金额",
                    },
                    model: {
                      value: _vm.indepPriceList[3].price,
                      callback: function ($$v) {
                        _vm.$set(_vm.indepPriceList[3], "price", $$v)
                      },
                      expression: "indepPriceList[3].price",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formArea",
              attrs: { label: "备注说明：", prop: "remark" },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", resize: "none", rows: "8" },
                model: {
                  value: _vm.formState.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "remark", $$v)
                  },
                  expression: "formState.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "mr15",
          attrs: { type: "primary", label: "default", size: "small" },
          on: { click: _vm.onSubmit },
        },
        [_vm._v("保存")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }