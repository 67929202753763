"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var auctionRouter = {
  path: "".concat(_settings.roterPre, "/pageManagement/decoration"),
  name: 'decoration',
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/pageManagement/decoration/diy/page"),
  meta: {
    title: '页面装修',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'diy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/customMain'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/pageManagement/decoration/diy/page"),
    meta: {
      title: '拍卖会',
      noCache: true
    },
    children: [{
      path: 'page',
      name: 'pageDiy',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/decoration/customPage'));
        });
      },
      hidden: true
    }]
  }]
};
var _default = exports.default = auctionRouter;