var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-content", staticStyle: { height: "100%" } },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-bottom": "120px" },
              attrs: {
                "label-width": "90px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 30 },
                    model: {
                      value: _vm.facade.borderRadius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "borderRadius", $$v)
                      },
                      expression: "facade.borderRadius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.bgColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "bgColor", $$v)
                      },
                      expression: "facade.bgColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "编号背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.numberBgColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "numberBgColor", $$v)
                      },
                      expression: "facade.numberBgColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "编号颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.numberColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "numberColor", $$v)
                      },
                      expression: "facade.numberColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "margin-bottom": "18px" },
                },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("编号图标"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex-one flex align-items-c",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.uploadIconAction("numIcon")
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "a-img",
                        attrs: { src: _vm.facade.numIcon },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ft12 color-gray ml20" }, [
                    _vm._v("1:1正方形图片"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }