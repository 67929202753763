"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _sdk = require("@/api/sdk");
var _statistics = _interopRequireDefault(require("./statistics.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'RequestCount',
  components: {
    statisticsVue: _statistics.default
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'rtl'
    },
    appId: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {
      pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      list: [],
      activeTab: '1',
      tabs: [{
        name: '1',
        label: '使用中空间'
      }, {
        name: '-1',
        label: '已删除空间'
      }],
      show: this.drawer,
      listLoading: false,
      spaceId: '',
      modelName: '',
      showDetails: false
    };
  },
  watch: {
    drawer: function drawer(val) {
      if (val !== this.show) {
        this.show = val;
      }
    },
    activeTab: function activeTab() {
      this.getSdkSpaceList(1);
    }
  },
  created: function created() {
    this.appId && this.getSdkSpaceList();
  },
  methods: {
    getSdkSpaceList: function getSdkSpaceList(reset) {
      var _this = this;
      if (reset) {
        this.pages.pageNum = 1;
      }
      this.listLoading = true;
      (0, _sdk.getSdkSpaceListApi)((0, _objectSpread2.default)((0, _objectSpread2.default)({
        appId: this.appId
      }, this.pages), {}, {
        type: this.activeTab
      })).then(function (res) {
        _this.list = res.rows;
        _this.listLoading = false;
      }).catch(function (err) {
        _this.listLoading = false;
        console.log(err);
      });
    },
    changePage: function changePage(page) {
      this.pages.pageNum = page;
      this.getSdkSpaceList(1);
    },
    close: function close() {
      this.$emit('close');
    },
    requestCountClick: function requestCountClick(id, name) {
      this.spaceId = id;
      this.modelName = name;
      this.showDetails = true;
    },
    appdetailclose: function appdetailclose() {
      this.showDetails = false;
    }
  }
};