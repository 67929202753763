var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "w120 fontW400 ml40 mr10 ft14",
                  staticStyle: { color: "#606266", display: "inline-block" },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("* ")]),
                  _vm._v("专辑封面："),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center mb20",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "image")
                        },
                      },
                    },
                    [
                      _vm.workDetail.image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.workDetail.image
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: _vm.workDetail.image + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ft14" },
                    [
                      _vm._v("列表页\n          "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "列表页的展示图",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center mb20 ml10",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "infoImage")
                        },
                      },
                    },
                    [
                      _vm.workDetail.infoImage
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("infoImage")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.workDetail.infoImage
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: _vm.workDetail.infoImage + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ft14" },
                    [
                      _vm._v("详情页\n          "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "详情页展示图，如不传则使用列表页图",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "专辑名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入专辑名称" },
                    model: {
                      value: _vm.workDetail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "name", $$v)
                      },
                      expression: "workDetail.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "专辑标签：", prop: "labelIds" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: { multiple: "", placeholder: "请选择标签" },
                      model: {
                        value: _vm.workDetail.labelIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "labelIds", $$v)
                        },
                        expression: "workDetail.labelIds",
                      },
                    },
                    _vm._l(_vm.tagList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发售时间：", prop: "startTime" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "请选择发售时间",
                      align: "right",
                    },
                    model: {
                      value: _vm.workDetail.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "startTime", $$v)
                      },
                      expression: "workDetail.startTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "限购数量：", prop: "purchaseLimit" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { type: "number", placeholder: "数量不超过20000份" },
                    model: {
                      value: _vm.workDetail.purchaseLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "purchaseLimit", _vm._n($$v))
                      },
                      expression: "workDetail.purchaseLimit",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情介绍：", prop: "detail" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.workDetail.detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "detail", $$v)
                      },
                      expression: "workDetail.detail",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }