"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.getUrlKey = getUrlKey;
exports.removeToken = removeToken;
exports.setToken = setToken;
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// import vm from '@/main'

var TokenKey = 'merchantToken';
function getToken() {
  if (getUrlKey('token')) {
    return getUrlKey('token');
  }
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  // vm.closeNotice()
  return _jsCookie.default.remove(TokenKey);
}
function getUrlKey(name) {
  return decodeURIComponent((new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(/\+/g, "%20")) || null;
}