var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.content.type === "logo"
        ? [
            _c(
              "div",
              {
                staticClass: "item-flex-center",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "item-flex-center",
                    style:
                      "width: " +
                      _vm.facade.width / 2 +
                      "px; height: " +
                      _vm.facade.height / 2 +
                      "px;",
                  },
                  [
                    _vm.facade.logo && _vm.facade.show
                      ? _c("el-image", {
                          staticClass: "img",
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { src: _vm.facade.logo + "!120a" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "inp"
        ? [
            _c("div", { style: "height:" + _vm.facade.marginTop / 2 + "px" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "login-tab-box",
                style:
                  "padding: 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "login-tab active",
                    style: "color: " + _vm.facade.tabSelectColor + ";",
                  },
                  [
                    _vm._v("验证码登录 "),
                    _c("div", {
                      staticClass: "line",
                      style: "background: " + _vm.facade.tabSelectColor + ";",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "login-tab",
                    style: "color: " + _vm.facade.tabColor + ";",
                  },
                  [
                    _vm._v("密码登录 "),
                    _c("div", {
                      staticClass: "line",
                      style: "background: " + _vm.facade.tabColor + ";",
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item-flex-center",
                style:
                  "padding: 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px;",
              },
              [
                _c("div", { staticClass: "flex-one" }, [
                  _c(
                    "div",
                    {
                      staticClass: "inp flex align-items-c",
                      style: {
                        background: _vm.facade.bgColor,
                        color: _vm.facade.color,
                        height: _vm.facade.height / 2 + "px",
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        borderRadius: _vm.facade.inpRadius / 2 + "px",
                        padding: "0 " + 48 / 2 + "px",
                        boxShadow: _vm.facade.inpShadow
                          ? "0 0 " +
                            _vm.facade.inpShadowSize / 2 +
                            "px " +
                            _vm.facade.inpShadow
                          : "",
                      },
                    },
                    [_vm._v("输入手机号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "inp flex align-items-c",
                      style: {
                        background: _vm.facade.bgColor,
                        color: _vm.facade.color,
                        height: _vm.facade.height / 2 + "px",
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        borderRadius: _vm.facade.inpRadius / 2 + "px",
                        padding: "0 " + 48 / 2 + "px",
                        boxShadow: _vm.facade.inpShadow
                          ? "0 0 " +
                            _vm.facade.inpShadowSize / 2 +
                            "px " +
                            _vm.facade.inpShadow
                          : "",
                        marginTop: _vm.facade.itemMargin / 2 + "px",
                      },
                    },
                    [
                      _c("div", { staticClass: "flex-one" }, [
                        _vm._v("验证码"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          style:
                            "color: " +
                            _vm.facade.sendColor +
                            ";font-size: " +
                            _vm.facade.sendFontSize / 2 +
                            "px;",
                        },
                        [_vm._v("发送验证码")]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "btn"
        ? [
            _c(
              "div",
              {
                staticClass: "item-flex-center",
                style:
                  "padding: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.page_margin / 2 +
                  "px 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px;",
              },
              [
                _c("div", { staticClass: "flex-one" }, [
                  _c(
                    "div",
                    {
                      staticClass: "inp item-flex-center",
                      style: {
                        background:
                          _vm.facade.bgColor +
                          " url(" +
                          _vm.facade.backgroundImg +
                          ") no-repeat",
                        backgroundSize: "100%",
                        color: _vm.facade.color,
                        height: _vm.facade.height / 2 + "px",
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        fontWeight: _vm.facade.fontWeight,
                        borderRadius: _vm.facade.radius / 2 + "px",
                        boxShadow: _vm.facade.shadow
                          ? "0 0 " +
                            _vm.facade.shadowSize / 2 +
                            "px " +
                            _vm.facade.shadow
                          : "",
                      },
                    },
                    [_vm._v("登录")]
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "agreement"
        ? [
            _c(
              "div",
              {
                staticClass: "agreement flex",
                style:
                  "padding: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.page_margin / 2 +
                  "px 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px;",
              },
              [
                _c("img", {
                  style:
                    "margin-top: " +
                    (_vm.facade.agreement_font_size + 8 - 32) / 4 +
                    "px;",
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20220429/adb79bc3fef946d7b3dab5e5f837a843.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    style:
                      "color: " +
                      _vm.facade.agreement_color +
                      ";font-size: " +
                      _vm.facade.agreement_font_size / 2 +
                      "px;line-height: " +
                      (_vm.facade.agreement_font_size + 8) / 2 +
                      "px;margin-left: " +
                      4 / 2 +
                      "px;",
                  },
                  [
                    _vm._v(
                      "未注册的手机号将自动创建账号，登录即代表您同意并接受 "
                    ),
                    _c(
                      "span",
                      { style: "color: " + _vm.facade.agreement_link_color },
                      [_vm._v("用户隐私协议")]
                    ),
                    _vm._v(" 和 "),
                    _c(
                      "span",
                      { style: "color: " + _vm.facade.agreement_link_color },
                      [_vm._v("服务协议")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }