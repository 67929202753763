var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    _vm._l(_vm.contributeList, function (item) {
      return _c("div", { key: item.id, staticClass: "item" }, [
        _c(
          "div",
          { staticClass: "person" },
          [
            _c("el-avatar", { attrs: { size: 60, src: item.authorImage } }),
            _vm._v(" "),
            _c("div", { staticClass: "tag" }, [_vm._v("私信")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "detail" }, [
          _c("div", { staticClass: "person-info" }, [
            _c("div", { staticClass: "person-name" }, [
              _vm._v(_vm._s(item.authorName)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info flex" }, [
              _c("div", { staticClass: "text" }, [
                _c("span", [_vm._v("工作室所在地：" + _vm._s(item.cityName))]),
                _vm._v(" "),
                _c("span", [_vm._v("链账户：" + _vm._s(item.chainId))]),
                _vm._v(" "),
                _c("span", [_vm._v("擅长：" + _vm._s(item.authorTags))]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "contribute-info" }, [
            _c("div", { staticClass: "info-title" }, [
              _c("span", [
                _vm._v("预计工期：" + _vm._s(item.expectedCompleted) + "天"),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("预算价格：" + _vm._s(item.expenseBudget) + "元"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              [
                _vm._v("\n          投稿说明:\n          "),
                _vm._l(
                  item.submissionInstructions.split("\n") || [],
                  function (dsc, index) {
                    return _c("li", { key: index, staticClass: "dec-item" }, [
                      _vm._v("\n            " + _vm._s(dsc) + "\n          "),
                    ])
                  }
                ),
              ],
              2
            ),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }