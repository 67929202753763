var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", inline: "", "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.sendStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "sendStatus", $$v)
                            },
                            expression: "tableFrom.sendStatus",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部 "),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("未发货"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("已发货"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "用户：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.tableFrom.phoneOrNickName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "phoneOrNickName", $$v)
                            },
                            expression: "tableFrom.phoneOrNickName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "数字藏品：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.tableFrom.productName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "productName", $$v)
                            },
                            expression: "tableFrom.productName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "multipleSelection",
          staticClass: "table",
          staticStyle: { width: "100%" },
          attrs: {
            "tooltip-effect": "dark",
            "row-key": function (row) {
              return row.id
            },
            data: _vm.tableData.data,
            size: "mini",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: scope.row.avatar
                              ? scope.row.avatar
                              : _vm.moren,
                            "preview-src-list": [scope.row.avatar || _vm.moren],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "昵称", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#1890ff", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(row.userId)
                          },
                        },
                      },
                      [
                        _vm._v("\n          " + _vm._s(row.userName)),
                        row.userPhone
                          ? _c("span", [
                              _vm._v("(" + _vm._s(row.userPhone) + ")"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换类型", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.type == 2 ? "实物商品" : "数字藏品") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换内容", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.luckyPrizeName) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换条件", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.exchangeConsumes,
                    function (item, index) {
                      return _c("div", { key: "c" + index }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.name) +
                            "*" +
                            _vm._s(item.number) +
                            "\n        "
                        ),
                      ])
                    }
                  )
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "收货信息", "min-width": "180" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.type == 2
                      ? [
                          _c("div", [
                            _vm._v(
                              _vm._s(scope.row.peopleName) +
                                " " +
                                _vm._s(scope.row.peoplePhone)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(scope.row.address))]),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货信息", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v("快递公司：" + _vm._s(scope.row.expressCompany)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("快递单号：" + _vm._s(scope.row.expressNumber)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换时间", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.createTime) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "80",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showsend(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  scope.row.isToSend == 1 ? "修改发货" : "发货"
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "发货", visible: _vm.sendflag },
          on: {
            "update:visible": function ($event) {
              _vm.sendflag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择快递公司",
                        size: "small",
                        clearable: "",
                      },
                      model: {
                        value: _vm.sendJson.deliveryCompanyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendJson, "deliveryCompanyId", $$v)
                        },
                        expression: "sendJson.deliveryCompanyId",
                      },
                    },
                    _vm._l(_vm.dcList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入快递公司" },
                    model: {
                      value: _vm.sendJson.deliveryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendJson, "deliveryId", $$v)
                      },
                      expression: "sendJson.deliveryId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.sendflag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.cursorSend } },
                [_vm._v("确认发货")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }