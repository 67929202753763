var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": "1",
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("模版")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("框类型")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [_vm._v("框卡纸")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [_vm._v("场景")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [_vm._v("画框")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-20" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { input: _vm.changeType },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("一幅"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("二幅"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "3" } }, [
                    _vm._v("三幅"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "4" } }, [
                    _vm._v("四幅"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "5" } }, [
                    _vm._v("五幅"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  attrs: { type: "primary", size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onAdd($event)
                    },
                  },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-box" },
            _vm._l(_vm.lists, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "imgItem" },
                [
                  _c("el-image", {
                    staticClass: "imgSize",
                    attrs: {
                      src: item.image + "!m640",
                      fit: "cover",
                      alt: "",
                      "preview-src-list": _vm.srcList,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "handBox" }, [
                    _c("div", { staticClass: "handItem" }, [
                      _vm._v(
                        "ID: " +
                          _vm._s(item.id) +
                          " / 使用: " +
                          _vm._s(item.useTimes)
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "span",
                          {
                            staticClass: "handBut",
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(item)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定删除吗?" },
                          on: {
                            confirm: function ($event) {
                              return _vm.del(item)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "handBut" }, [
                                      _vm._v("删除"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default-box" },
            [
              _vm.total > 0
                ? _c("el-pagination", {
                    staticClass: "mt20",
                    attrs: {
                      total: _vm.total,
                      "show-sizer": "",
                      "show-elevator": "",
                      "show-total": "",
                      "current-page": _vm.queryParams.pageNum,
                      "page-size": _vm.queryParams.pageSize,
                    },
                    on: {
                      "current-change": _vm.changePage,
                      "size-change": _vm.changePageSize,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.model1,
                width: "30%",
                title: "作品集",
                "class-name": "vertical-center-modal",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.model1 = $event
                },
                close: _vm.onClose,
              },
            },
            [
              _c(
                "el-form",
                { ref: "formList", attrs: { model: _vm.formList } },
                [
                  _c("el-form-item", { attrs: { label: "画框图片" } }, [
                    _vm.formList.image
                      ? _c("div", { staticClass: "upload-list" }, [
                          _c("img", { attrs: { src: _vm.formList.image } }),
                          _vm._v(" "),
                          _c("div", { staticClass: "upload-list-cover" }, [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              attrs: { size: "20" },
                              on: { click: _vm.handleRemove },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.formList.image,
                            expression: "!formList.image",
                          },
                        ],
                        staticClass: "border-h",
                        staticStyle: {
                          width: "58px",
                          height: "58px",
                          "line-height": "58px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onUpload(1)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-camera-solid",
                          attrs: { size: "20" },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "画框类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          on: { change: _vm.change },
                          model: {
                            value: _vm.formList.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formList, "type", $$v)
                            },
                            expression: "formList.type",
                          },
                        },
                        _vm._l(_vm.frameTypeList, function (item, index) {
                          return _c("el-option", {
                            key: item,
                            attrs: { value: index + 1, label: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.formList.pos, function (item, index) {
                    return _c(
                      "el-form-item",
                      { key: index, attrs: { label: "绘画坐标" } },
                      [
                        _vm._v("\n          x："),
                        _c("el-input", {
                          staticStyle: { width: "50px" },
                          attrs: { type: "number" },
                          model: {
                            value: item.x,
                            callback: function ($$v) {
                              _vm.$set(item, "x", $$v)
                            },
                            expression: "item.x",
                          },
                        }),
                        _vm._v("\n          y："),
                        _c("el-input", {
                          staticStyle: { width: "50px" },
                          attrs: { type: "number" },
                          model: {
                            value: item.y,
                            callback: function ($$v) {
                              _vm.$set(item, "y", $$v)
                            },
                            expression: "item.y",
                          },
                        }),
                        _vm._v("\n          w："),
                        _c("el-input", {
                          staticStyle: { width: "50px" },
                          attrs: { type: "number" },
                          model: {
                            value: item.w,
                            callback: function ($$v) {
                              _vm.$set(item, "w", $$v)
                            },
                            expression: "item.w",
                          },
                        }),
                        _vm._v("\n          h："),
                        _c("el-input", {
                          staticStyle: { width: "50px" },
                          attrs: { type: "number" },
                          model: {
                            value: item.h,
                            callback: function ($$v) {
                              _vm.$set(item, "h", $$v)
                            },
                            expression: "item.h",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.onClose } }, [
                    _vm._v("取 消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }