"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Article",
  data: function data() {
    return {
      rowList: {},
      visible: false,
      dialogTable: [],
      roterPre: _settings.roterPre,
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  filters: {
    formatIsStart: function formatIsStart(val) {
      var res = '';
      switch (val) {
        case 0:
          res = '待开始';
          break;
        case 1:
          res = '进行中';
          break;
        case 2:
          res = '已结束';
          break;
        default:
          break;
      }
      return res;
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    clickNum: function clickNum(row) {
      this.rowList = row;
      this.visible = true;
      this.getComposeInfos();
    },
    getComposeInfos: function getComposeInfos() {
      var _this = this;
      (0, _marketing.getComposeInfo)({
        id: this.rowList.id
      }).then(function (res) {
        _this.dialogTable = res.data;
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _marketing.synthesisListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _marketing.synthesisDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    }
  }
};