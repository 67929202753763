var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox open3d" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              staticClass: "filter",
              attrs: {
                size: "small",
                "label-width": "85px",
                inline: true,
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作品格式：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: { change: _vm.changeCategoryAction },
                      model: {
                        value: _vm.fromState.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.fromState, "type", $$v)
                        },
                        expression: "fromState.type",
                      },
                    },
                    [
                      _vm._l(_vm.categoryList, function (m, i) {
                        return [
                          m.type == 10
                            ? [
                                _c(
                                  "el-radio-button",
                                  { key: i, attrs: { label: m.id } },
                                  [_vm._v(_vm._s(m.label))]
                                ),
                              ]
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发行状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: {
                        change: function ($event) {
                          return _vm.onResh()
                        },
                      },
                      model: {
                        value: _vm.fromState.isIssue,
                        callback: function ($$v) {
                          _vm.$set(_vm.fromState, "isIssue", $$v)
                        },
                        expression: "fromState.isIssue",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("已发行"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("待发行"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "存证状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: {
                        change: function ($event) {
                          return _vm.onResh()
                        },
                      },
                      model: {
                        value: _vm.fromState.isEvidence,
                        callback: function ($$v) {
                          _vm.$set(_vm.fromState, "isEvidence", $$v)
                        },
                        expression: "fromState.isEvidence",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("已发行"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("待发行"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作品分类：" } },
                [
                  _vm.categoryList && _vm.categoryList.length > 0
                    ? [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "medium" },
                            on: {
                              change: function ($event) {
                                return _vm.onResh()
                              },
                            },
                            model: {
                              value: _vm.fromState.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.fromState, "categoryId", $$v)
                              },
                              expression: "fromState.categoryId",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "" } }, [
                              _vm._v("全部"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.categoryList[_vm.categoryIndex].children,
                              function (m, i) {
                                return [
                                  m.type == 10
                                    ? [
                                        _c(
                                          "el-radio-button",
                                          { key: i, attrs: { label: m.id } },
                                          [_vm._v(_vm._s(m.label))]
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "作品名称", size: "medium" },
                  model: {
                    value: _vm.fromState.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.fromState, "name", $$v)
                    },
                    expression: "fromState.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.onResh()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200 ml10",
                  attrs: { placeholder: "作者名称", size: "medium" },
                  model: {
                    value: _vm.fromState.authorName,
                    callback: function ($$v) {
                      _vm.$set(_vm.fromState, "authorName", $$v)
                    },
                    expression: "fromState.authorName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.onResh()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200 ml10",
                  attrs: { placeholder: "版权方名称", size: "medium" },
                  model: {
                    value: _vm.fromState.ownerName,
                    callback: function ($$v) {
                      _vm.$set(_vm.fromState, "ownerName", $$v)
                    },
                    expression: "fromState.ownerName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.onResh()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", "min-width": "170" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品预览", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "demo-image__preview item-flex-center",
                          },
                          [
                            _c("el-image", {
                              attrs: {
                                src: row.coverImage,
                                fit: "contain",
                                "preview-src-list": [row.coverImage],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "文件格式", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.type == 1
                          ? _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("3D"),
                            ])
                          : row.type == 2
                          ? _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("图片"),
                            ])
                          : row.type == 3
                          ? _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("视频"),
                            ])
                          : row.type == 4
                          ? _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("音频"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品分类", prop: "", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品名称", "min-width": "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              title: "作品名称",
                              width: "400",
                              trigger: "hover",
                              content: row.name,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "line2",
                                staticStyle: { cursor: "pointer" },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(row.name))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "作品尺寸",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.basicAttr ? row.basicAttr.size : "")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创作时间",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.basicAttr ? row.basicAttr.year : "")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "作品材质",
                  prop: "",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.basicAttr ? row.basicAttr.material : "")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "作品标签",
                  prop: "",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              title: "作品简介",
                              width: "400",
                              trigger: "hover",
                              content: row.tagNames
                                ? row.tagNames.join(",")
                                : "",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "line2",
                                staticStyle: { cursor: "pointer" },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    row.tagNames ? row.tagNames.join(",") : ""
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "作品介绍",
                  "min-width": "200",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              title: "作品简介",
                              width: "400",
                              trigger: "hover",
                              content: row.introduce,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "line2",
                                staticStyle: { cursor: "pointer" },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(row.introduce))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.pageSize,
                  "current-page": _vm.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }