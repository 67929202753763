var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-button",
        {
          staticClass: "mr20",
          attrs: { size: "mini", icon: "el-icon-back" },
          on: { click: _vm.close },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
                inline: "",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        "font-size": "18px",
                      },
                      attrs: { span: 12, offset: 0 },
                    },
                    [_vm._v("创建调拨单")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转出库房", prop: "out_warehouse_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择库房",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.selectoutp },
                      model: {
                        value: _vm.formValidate.out_warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "out_warehouse_id", $$v)
                        },
                        expression: "formValidate.out_warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeArr, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转入库房", prop: "push_warehouse_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择库房",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.push_warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "push_warehouse_id", $$v)
                        },
                        expression: "formValidate.push_warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeArr, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "业务负责人", prop: "manage_admin_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择业务负责人",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.manage_admin_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "manage_admin_id", $$v)
                        },
                        expression: "formValidate.manage_admin_id",
                      },
                    },
                    _vm._l(_vm.adminarr, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "multipleSelection",
                  staticClass: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "tooltip-effect": "dark",
                    "row-key": function (row) {
                      return row.order_id
                    },
                    data: _vm.goodsArr,
                    size: "mini",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "货品图" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "36px",
                                    height: "36px",
                                  },
                                  attrs: {
                                    src: scope.row.image,
                                    "preview-src-list": [scope.row.image],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "编号", prop: "bar_code" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "名称", prop: "store_name" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "货架", prop: "shelf.shelf_no" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作者/品牌", prop: "brand" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.brand
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.brand.brand_name)),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "属性", prop: "order_pay_amount" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(scope.row.attrInfo, function (m, i) {
                            return _c("span", { key: "attr_" + i }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(m.attr_name) +
                                  ":" +
                                  _vm._s(m.attr_value_name) +
                                  "\n              "
                              ),
                            ])
                          })
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "20px", width: "400px" },
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("\n          保存\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "30px" } }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-tabs",
            { attrs: { value: "goods" } },
            [
              _c("el-tab-pane", {
                attrs: { label: "货品档案", name: "goods" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { inline: "", size: "small", "label-width": "110px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "货品搜索：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: {
                        placeholder: "请输入货品名称，关键字，产品编号",
                      },
                      model: {
                        value: _vm.tableFrom.keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "keyword", $$v)
                        },
                        expression: "tableFrom.keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "货品ID：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入货品ID" },
                      model: {
                        value: _vm.tableFrom.product_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "product_id", $$v)
                        },
                        expression: "tableFrom.product_id",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "货品ID", prop: "product_id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货品图" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "编号", prop: "bar_code" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "store_name", label: "货品名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作者/品牌", prop: "brand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.brand
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.brand.brand_name)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "属性", prop: "order_pay_amount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.attrInfo, function (m, i) {
                        return _c("span", { key: "attr_" + i }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(m.attr_name) +
                              ":" +
                              _vm._s(m.attr_value_name) +
                              "\n              "
                          ),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saleGoods(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.goodsIdArr.indexOf(
                                        scope.row.product_id
                                      ) > -1
                                        ? "已选择货品"
                                        : "选择货品"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.goodsIdArr.indexOf(scope.row.product_id) > -1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelSelect(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              取消选择\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }