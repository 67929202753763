var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 2
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(5, function (item, index) {
            return _c("div", { key: index, staticClass: "list flex" }, [
              _c(
                "div",
                {
                  staticClass: "r-view flex",
                  class: { mt0: index == 0 },
                  style:
                    "margin-top:" +
                    _vm.facade.item_margin / 2 +
                    "px;border-top-right-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-top-left-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-bottom-left-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;border-bottom-right-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;background: " +
                    _vm.facade.status_background +
                    " url(" +
                    _vm.facade.status_bg_image +
                    ") repeat left center;background-size: 100%;",
                },
                [
                  _c(
                    "div",
                    { staticClass: "img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "74px", height: "70px" },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                          fit: _vm.facade.img_style === 2 ? "fill" : "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "img-r" }, [
                    _c(
                      "div",
                      {
                        staticClass: "name line1",
                        style:
                          "color: " +
                          _vm.facade.title_color +
                          ";font-size: " +
                          _vm.facade.title_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.title_font_weight +
                          ";",
                      },
                      [_vm._v("坠落·光在前方照亮的地方")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "count",
                        style:
                          "color: " +
                          _vm.facade.stock_color +
                          ";font-size: " +
                          _vm.facade.stock_font_size / 2 +
                          "px;",
                      },
                      [_vm._v("限量500份")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "price",
                        style:
                          "color: " +
                          _vm.facade.price_color +
                          ";background:" +
                          _vm.facade.price_bg +
                          ";font-weight:" +
                          _vm.facade.price_font_weight,
                      },
                      [_vm._v("购买")]
                    ),
                  ]),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }