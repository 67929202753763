"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      rowList: {},
      visible: false,
      imgList: [],
      roterPre: _settings.roterPre,
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    addAction: function addAction() {
      var _this = this;
      this.$prompt('多个敏感词(，)分隔', '请输入敏感词', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _system.sensitivewordAddApi)({
          text: value
        }).then(function (res) {
          _this.$message.success('添加成功!');
          _this.getList();
        });
      }).catch(function () {});
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _system.sensitivewordListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _system.sensitivewordDelApi)(id).then(function (_ref2) {
          var message = _ref2.message;
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (_ref3) {
          var message = _ref3.message;
        });
      });
    }
  }
};