"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = _interopRequireWildcard(require("@/settings"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var title = _settings.default.title;
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      title: title,
      logo: ''
    };
  },
  created: function created() {
    this.getInfo();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      (0, _setting.storeGetInfoApi)().then(function (res) {
        _this.logo = res.data.logo;
      });
    }
  }
};