"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: {
        data: []
      },
      loading: false
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    goDetail: function goDetail(_ref) {
      var id = _ref.id;
      this.$router.push("/merchant/systemForm/configDetail?id=".concat(id));
    },
    changeStatus: function changeStatus(val, row, type) {
      var _this = this;
      console.log(val);
      console.log(row);
      var id = row.id,
        hasSendWeChatOfficial = row.hasSendWeChatOfficial,
        hasSendSms = row.hasSendSms,
        hasSendMessage = row.hasSendMessage,
        hasSendIos = row.hasSendIos,
        hasSendAndroid = row.hasSendAndroid;
      var data = {
        id: id,
        hasSendWeChatOfficial: hasSendWeChatOfficial,
        hasSendSms: hasSendSms,
        hasSendMessage: hasSendMessage,
        hasSendIos: hasSendIos,
        hasSendAndroid: hasSendAndroid
      };
      data[type] = val;
      (0, _setting.configUpdateApi)(data).then(function (res) {
        _this.getList();
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      (0, _setting.getConfigListApi)().then(function (res) {
        _this2.list.data = res.data;
        _this2.loading = false;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    objectSpanMethod: function objectSpanMethod(_ref2) {
      var row = _ref2.row,
        column = _ref2.column,
        rowIndex = _ref2.rowIndex,
        columnIndex = _ref2.columnIndex;
      var columnArr = [0];
      var jobArr = [];
      var tableData = this.list.data;
      for (var k in tableData) {
        jobArr.push(tableData[k].businessSectorType);
      }
      var obj = {};
      for (var i = 0; i < jobArr.length; i++) {
        if (obj[jobArr[i]] == undefined) {
          obj[jobArr[i]] = 1;
        } else {
          obj[jobArr[i]]++;
        }
      }
      var spanArr = []; //每一行就是一条数据，该数组每一个值就是当前行的rowspan数
      var doneArr = []; //保存被合并了的id，如果再出现，表示被合并
      for (var _i = 0; _i < jobArr.length; _i++) {
        //看看个数
        if (doneArr.includes(jobArr[_i])) {
          //如果这个id被合并过了，说明刚刚上方有一样的id，这一项就是被合并的
          spanArr.push(0);
        } else {
          //如果没合并，就往下合并，spanArr里推入这个id的个数，在obj中就有
          spanArr.push(obj[jobArr[_i]]);
          //别忘了合并完把id放进doneArr中
          doneArr.push(jobArr[_i]);
        }
      }
      if (columnArr.includes(columnIndex)) {
        //遍历合并个数的数组
        for (var _i2 = 0; _i2 < spanArr.length; _i2++) {
          //如果是该行，行合并个数就是这一项的值，列不合并，所以是1
          if (rowIndex == _i2) {
            return {
              rowspan: spanArr[_i2],
              colspan: 1
            };
          }
        }
      }
    }
  }
};