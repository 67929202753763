var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "code-v" },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "-20px" } },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.changeTypeVal },
                  model: {
                    value: _vm.radio1,
                    callback: function ($$v) {
                      _vm.radio1 = $$v
                    },
                    expression: "radio1",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "新人专享", name: "0" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "参与记录", name: "1" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.radio1 == "0"
            ? _c(
                "div",
                { staticClass: "tab" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.products, function (m, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "tr flex align-items-c ft12" },
                      [
                        _c(
                          "div",
                          { staticClass: "td", staticStyle: { width: "10%" } },
                          [
                            _c(
                              "el-select",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.editBuyTypeAction($event, i)
                                  },
                                },
                                model: {
                                  value: m.type,
                                  callback: function ($$v) {
                                    _vm.$set(m, "type", $$v)
                                  },
                                  expression: "m.type",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: "1", label: "数字藏品" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: "2", label: "数字盲盒" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: "3", label: "优惠券" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: "4", label: "积分" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "td item-flex-center",
                            staticStyle: { width: "10%" },
                          },
                          [
                            m.image
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: m.image + "!120a",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                  },
                                  [_c("i", { staticClass: "el-icon-picture" })]
                                ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "td",
                            staticStyle: { width: "20%", "text-align": "left" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(m.name || "") +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "td flex align-items-c",
                            staticStyle: { width: "30%", "text-align": "left" },
                          },
                          [
                            m.type == 4
                              ? _c(
                                  "div",
                                  { staticClass: "w100 mr10" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "积分类型" },
                                        model: {
                                          value: m.scoreTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(m, "scoreTypeId", $$v)
                                          },
                                          expression: "m.scoreTypeId",
                                        },
                                      },
                                      _vm._l(_vm.typeList, function (m, i) {
                                        return _c("el-option", {
                                          key: "type" + i,
                                          attrs: { value: m.id, label: m.name },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "w100",
                              attrs: { type: "number", placeholder: "" },
                              model: {
                                value: m.count,
                                callback: function ($$v) {
                                  _vm.$set(m, "count", $$v)
                                },
                                expression: "m.count",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v(
                                _vm._s(
                                  m.type == "3"
                                    ? "张"
                                    : m.type == "4"
                                    ? "积分"
                                    : ""
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "td",
                            staticStyle: { width: "10%", "text-align": "left" },
                          },
                          [
                            m.type != "3" && m.type != "4"
                              ? _c("el-switch", {
                                  model: {
                                    value: m.hasVipPrice,
                                    callback: function ($$v) {
                                      _vm.$set(m, "hasVipPrice", $$v)
                                    },
                                    expression: "m.hasVipPrice",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "td", staticStyle: { width: "20%" } },
                          [
                            m.type != "4"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleBuyAdditem(m.type, i)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delBuyAction(i)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tr flex align-items-c" }, [
                    _c(
                      "div",
                      {
                        staticClass: "td",
                        staticStyle: { width: "100%", "text-align": "left" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.addBuyAction },
                          },
                          [_vm._v("新增")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.radio1 == "0"
            ? _c("div", [
                _c("h4", [_vm._v("新人判定条件")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "save-btn" },
                  [
                    _vm._v("\n        未下过单：\n        "),
                    _c("el-switch", {
                      model: {
                        value: _vm.memberObject.hasOrder,
                        callback: function ($$v) {
                          _vm.$set(_vm.memberObject, "hasOrder", $$v)
                        },
                        expression: "memberObject.hasOrder",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "save-btn" },
                  [
                    _vm._v("\n        注册时间小于：\n        "),
                    _c("el-input", {
                      staticClass: "w200",
                      attrs: { type: "number", placeholder: "请输入内容" },
                      model: {
                        value: _vm.memberObject.registerDay,
                        callback: function ($$v) {
                          _vm.$set(_vm.memberObject, "registerDay", $$v)
                        },
                        expression: "memberObject.registerDay",
                      },
                    }),
                    _vm._v("天\n      "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "save-btn" },
                  [
                    _vm._v("\n        是否开启新人专享：\n        "),
                    _c("el-switch", {
                      model: {
                        value: _vm.memberObject.hasUse,
                        callback: function ($$v) {
                          _vm.$set(_vm.memberObject, "hasUse", $$v)
                        },
                        expression: "memberObject.hasUse",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "save-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.save },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.radio1 == "1"
            ? _c(
                "div",
                {},
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _vm._v("\n        手机号：\n        "),
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: { type: "number", placeholder: "请输入内容" },
                        model: {
                          value: _vm.phone,
                          callback: function ($$v) {
                            _vm.phone = $$v
                          },
                          expression: "phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex align-items-c",
                      staticStyle: { "margin-top": "20px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.exportRecord(22)
                            },
                          },
                        },
                        [_vm._v("导出\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.getExportFileList(22)
                            },
                          },
                        },
                        [_vm._v("导出已生成列表")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", padding: "20px" },
                      attrs: {
                        data: _vm.tableData,
                        size: "small",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "用户名称", "min-width": "120" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row.memberNickName) +
                                        " - " +
                                        _vm._s(scope.row.memberPhone)
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1101751077
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productName",
                          label: "作品名称",
                          "min-width": "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "oldPrice",
                          label: "原价",
                          "min-width": "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "vipPrice",
                          label: "新人价",
                          "min-width": "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "count",
                          label: "数量",
                          "min-width": "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "交易时间",
                          "min-width": "120",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block" },
                    [
                      _c("el-pagination", {
                        staticClass: "mt20",
                        staticStyle: { "text-align": "right" },
                        attrs: {
                          "page-sizes": [20, 40, 60, 80],
                          "page-size": _vm.tableFrom.pageSize,
                          "current-page": _vm.tableFrom.pageNum,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.tableFrom.total,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.pageChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("Nft", {
            ref: "product_nft",
            attrs: { "current-data": [], size: "1" },
            on: { selectMessage: _vm.selectMessage },
          }),
          _vm._v(" "),
          _c("Nftblind", {
            ref: "product_nft_blind",
            attrs: { "current-data": [], size: "1" },
            on: { selectMessage: _vm.selectMessage },
          }),
          _vm._v(" "),
          _c("Productblind", {
            ref: "blind_box",
            attrs: { "current-data": [], size: "1" },
            on: { selectMessage: _vm.selectMessage },
          }),
          _vm._v(" "),
          _c("Couponslist", {
            ref: "coupons",
            attrs: { "current-data": [], size: "1" },
            on: { selectMessage: _vm.selectMessage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tr flex align-items-c ft13" }, [
      _c("div", { staticClass: "th", staticStyle: { width: "10%" } }, [
        _vm._v("类型"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "th", staticStyle: { width: "10%" } }, [
        _vm._v("图片"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "th",
          staticStyle: { width: "20%", "text-align": "left" },
        },
        [_vm._v("名称")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "th",
          staticStyle: { width: "30%", "text-align": "left" },
        },
        [_vm._v("发放总量")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "th",
          staticStyle: { width: "10%", "text-align": "left" },
        },
        [_vm._v("\n          优惠价购买\n        ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "th", staticStyle: { width: "20%" } }, [
        _vm._v("操作"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }