var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              attrs: {
                size: "small",
                "label-width": "80px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员等级" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.builderType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "builderType", $$v)
                        },
                        expression: "queryParams.builderType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部 "),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("普通 "),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("核心 "),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("创始 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分账类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.queryParams.orderType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "orderType", $$v)
                        },
                        expression: "queryParams.orderType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部 "),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("供应订单 "),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("销售订单 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "订单类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.forType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "forType", $$v)
                        },
                        expression: "queryParams.forType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部 "),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.forTList, function (item, index) {
                        return _c(
                          "el-radio-button",
                          { attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "width100", attrs: { label: "时间选择：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "mr20",
                      attrs: { type: "button", size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "timeType", $$v)
                        },
                        expression: "queryParams.timeType",
                      },
                    },
                    _vm._l(_vm.timeList, function (item, i) {
                      return _c(
                        "el-radio-button",
                        { key: i, attrs: { label: item.val } },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      "value-format": "yyyy/MM/dd HH:mm:ss",
                      format: "yyyy/MM/dd HH:mm:ss",
                      size: "small",
                      type: "datetimerange",
                      placement: "bottom-end",
                      "start-placeholder": "选择开始时间",
                      "end-placeholder": "选择结束时间",
                    },
                    on: { change: _vm.onchangeTime },
                    model: {
                      value: _vm.timeType,
                      callback: function ($$v) {
                        _vm.timeType = $$v
                      },
                      expression: "timeType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "姓名/手机号", size: "small" },
                  model: {
                    value: _vm.queryParams.nameOrPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "nameOrPhone", $$v)
                    },
                    expression: "queryParams.nameOrPhone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("查看导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: { src: scope.row.avatar },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "昵称", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v(_vm._s(row.nickName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "levelName", label: "等级" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "团队成员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v(_vm._s(row.teamNum))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单数" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v(_vm._s(row.teamOrderNum))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "销售额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v(_vm._s(row.saleAllMoney))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v(_vm._s(row.dividedMoney))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c justify-b" },
            [
              _c("ul", { staticClass: "flex align-items-c" }, [
                _c(
                  "li",
                  [
                    _c("span", [_vm._v("订单数")]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      { attrs: { type: "primary", underline: false } },
                      [_vm._v(_vm._s(_vm.teamOrderNum))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticStyle: { margin: "0 20px" } },
                  [
                    _c("span", [_vm._v("销售额")]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      { attrs: { type: "primary", underline: false } },
                      [_vm._v(_vm._s(_vm.teamAllMoney))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("span", [_vm._v("团队成员总数")]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      { attrs: { type: "primary", underline: false } },
                      [_vm._v(_vm._s(_vm.teamPeopleNum))]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-pagination", {
                staticStyle: { margin: "0" },
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.queryParams.pageSize,
                  "current-page": _vm.queryParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.list.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }