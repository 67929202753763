"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _nft = require("@/api/nft");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      tableFrom: {
        type: 1,
        id: '',
        pageNum: 1,
        pageSize: 10,
        key: '',
        seriesId: ''
      },
      total: 0,
      list: [],
      showNftSale: false,
      albumList: []
    };
  },
  methods: {
    loadInit: function loadInit(value, t) {
      var _this = this;
      (0, _nft.nftCollectionAlbumListApi)({
        pageSize: 100,
        pageNum: 1
      }).then(function (res) {
        _this.albumList = res.rows;
      }).catch(function () {});
      this.list = [];
      this.total = 0;
      this.tableFrom = {
        type: t,
        id: value,
        pageNum: 1,
        pageSize: 10,
        key: '',
        seriesId: ''
      };
      this.showNftSale = true;
      this.load();
    },
    load: function load() {
      var _this2 = this;
      var data = JSON.parse(JSON.stringify(this.tableFrom));
      (0, _nft.getProductSplitListApi)(data).then(function (res) {
        _this2.list = res.rows;
        _this2.total = res.total;
      }).catch(function () {});
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.load('');
    },
    changeData: function changeData() {
      this.tableFrom.pageNum = 1;
      this.load('');
    }
  }
};