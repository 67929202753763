var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        [
          _c("div", { staticClass: "top-view" }, [
            _c(
              "div",
              { staticStyle: { padding: "0 20px" } },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo default-tabs mt10",
                    attrs: {
                      "default-active": _vm.$route.path,
                      mode: "horizontal",
                    },
                    on: { select: _vm.changeTab },
                  },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "/merchant/marketing/integral/survey" },
                      },
                      [_vm._v("积分概况")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/marketing/integral/detailed",
                        },
                      },
                      [_vm._v("积分明细")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/marketing/integral/receiving",
                        },
                      },
                      [_vm._v("发放记录")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      { attrs: { index: "/merchant/marketing/integral/use" } },
                      [_vm._v("使用记录")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/marketing/integral/ranking",
                        },
                      },
                      [_vm._v("积分排行")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      { attrs: { index: "/merchant/marketing/integral/type" } },
                      [_vm._v("积分类型")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/systemForm/systemAgreement/detail?t=73",
                        },
                      },
                      [_vm._v("积分攻略")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "change-type pt10" },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeTypeAction },
                  model: {
                    value: _vm.integralType,
                    callback: function ($$v) {
                      _vm.integralType = $$v
                    },
                    expression: "integralType",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 0 } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.integralTypeList, function (m, i) {
                    return _c(
                      "el-radio-button",
                      { key: "type" + i, attrs: { label: m.id } },
                      [_vm._v(_vm._s(m.name))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "top-data flex" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(
                  _vm._s(
                    _vm.integralData ? _vm.integralData.usableScore || 0 : 0
                  )
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("可用积分")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(
                  _vm._s(
                    _vm.integralData ? _vm.integralData.freezeScore || 0 : 0
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "b item-flex-center" },
                [
                  _c("div", [_vm._v("锁定期内积分")]),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        width: "200",
                        trigger: "hover",
                        content:
                          "可售后的订单积分收入会有相应的锁定时间，超过锁定时间时该积分解除锁定",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "ml5",
                        staticStyle: {
                          width: "16px",
                          display: "block",
                          cursor: "pointer",
                        },
                        attrs: {
                          slot: "reference",
                          src: "https://saas.cdn.yunzongbu.cn/image/20220811/1307507f3dd1c346da76315a8070adbe.png",
                        },
                        slot: "reference",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(
                  _vm._s(
                    _vm.integralData ? _vm.integralData.totalSendScore || 0 : 0
                  )
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("累计发放积分")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(
                  _vm._s(_vm.integralData ? _vm.integralData.usedScore || 0 : 0)
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("累计消耗积分")]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [
              _c("router-view", {
                key: _vm.key,
                attrs: { "integral-type": _vm.integralType },
                on: {
                  setInfo: _vm.loadStreamInfo,
                  setTypeInfo: _vm.getTypeList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }