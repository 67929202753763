"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.articleAddApi = articleAddApi;
exports.articleCreateApi = articleCreateApi;
exports.articleDeleApi = articleDeleApi;
exports.articleDeleteApi = articleDeleteApi;
exports.articleDetailApi = articleDetailApi;
exports.articleDetailInfoApi = articleDetailInfoApi;
exports.articleEditApi = articleEditApi;
exports.articleListApi = articleListApi;
exports.articleLstApi = articleLstApi;
exports.articleStatuseApi = articleStatuseApi;
exports.articleTagCreatedApi = articleTagCreatedApi;
exports.articleTagListApi = articleTagListApi;
exports.articleTagUpdateApi = articleTagUpdateApi;
exports.articleUpdateApi = articleUpdateApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 文章分类 -- 列表
 */
function articleListApi(data) {
  return _request.default.get('system/sys/notice/category/treeselect', data);
}
/**
 * @description 文章标签 -- 列表
 */
function articleTagListApi(data) {
  return _request.default.get('/system/notice/tag/getList', data);
}
/**
 * @description 文章标签 -- 创建
 */
function articleTagCreatedApi(data) {
  return _request.default.post('/system/notice/tag/create', data);
}
/**
 * @description 文章标签 -- 修改
 */
function articleTagUpdateApi(data) {
  return _request.default.post("/system/notice/tag/edit", data);
}

/**
 * @description 文章分类 -- 新增表单
 */
function articleCreateApi(data) {
  return _request.default.post('system/sys/notice/category/save', data);
}
/**
 * @description 文章分类 -- 编辑表单
 */
function articleUpdateApi(data) {
  return _request.default.put("system/sys/notice/category/update", data);
}
/**
 * @description 文章分类 -- 分类详情
 */
function articleDetailInfoApi(data) {
  return _request.default.get("system/sys/notice/category/info", data);
}
/**
 * @description 文章分类 -- 删除
 */
function articleDeleteApi(id) {
  return _request.default.delete("system/sys/notice/category/delete/".concat(id));
}
/**
 * @description 文章分类 -- 修改开启状态
 */
function articleStatuseApi(id, status) {
  return _request.default.post("system/article/category/status/".concat(id), {
    status: status
  });
}
/**
 * @description 文章管理 -- 列表
 */
function articleLstApi(data) {
  return _request.default.get('system/notice/list', data);
}
/**
 * @description 文章管理 -- 详情
 */
function articleDetailApi(noticeId) {
  return _request.default.get("system/notice/".concat(noticeId));
}
/**
 * @description 文章管理 -- 添加
 */
function articleAddApi(data) {
  return _request.default.post('system/notice', data);
}
/**
 * @description 文章管理 -- 编辑
 */
function articleEditApi(data) {
  return _request.default.put("system/notice", data);
}
/**
 * @description 文章管理 -- 删除
 */
function articleDeleApi(noticeIds) {
  return _request.default.delete("system/notice/".concat(noticeIds));
}