"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setting = require("@/api/setting");
//
//
//
//
//
//
var _default = exports.default = {
  name: "App",
  data: function data() {
    return {
      notify: "",
      timer: ""
    };
  },
  created: function created() {
    var changeFavicon = function changeFavicon(link) {
      var $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = link;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.href = link;
        document.head.appendChild($favicon);
      }
    };
    (0, _setting.storeGetInfoApi)().then(function (res) {
      if (res.data.icon) {
        changeFavicon(res.data.icon);
      }
      localStorage.setItem("hasCopyright", res.data.hasCopyright ? "1" : "0");
      localStorage.setItem("ossBeforeUrl", res.data.ossBeforeUrl);
    });
    console.log("进来了");
  },
  mounted: function mounted() {},
  methods: {}
};