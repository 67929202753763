var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-form",
              {
                attrs: { size: "small", "label-width": "0px" },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "width100", attrs: { label: " " } },
                  [
                    _c("span", { staticClass: "fWeight500" }, [
                      _vm._v("兑换类型："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.pageChangeIssue(1)
                          },
                        },
                        model: {
                          value: _vm.tableFromIssue.redeemType,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFromIssue, "redeemType", $$v)
                          },
                          expression: "tableFromIssue.redeemType",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "" } }, [
                          _vm._v("全部"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("兑换码"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "2" } }, [
                          _vm._v("积分兑换"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: " " } }, [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("span", { staticClass: "fWeight500" }, [
                        _vm._v("用户名称："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200 mr20",
                          attrs: { placeholder: "用户名称/手机号" },
                          model: {
                            value: _vm.tableFromIssue.nickNameOrPhone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tableFromIssue,
                                "nickNameOrPhone",
                                $$v
                              )
                            },
                            expression: "tableFromIssue.nickNameOrPhone",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.pageChangeIssue(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "fWeight500" }, [
                        _vm._v("兑换码名称："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200 mr20",
                          attrs: { placeholder: "兑换码名称" },
                          model: {
                            value: _vm.tableFromIssue.codeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFromIssue, "codeName", $$v)
                            },
                            expression: "tableFromIssue.codeName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.pageChangeIssue(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.Loading,
                    expression: "Loading",
                  },
                ],
                staticClass: "mt20",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.issueData.data,
                  size: "small",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "title",
                    label: "用户信息",
                    "min-width": "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetails(scope.row.memberId)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  scope.row.memberNickName +
                                    "/" +
                                    scope.row.memberPhone
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "兑换方式", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.redeemType == 1
                                  ? "兑换码"
                                  : "积分兑换"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "兑换条件", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.redeemType == 1
                                  ? scope.row.code
                                  : "" +
                                      scope.row.buyScore +
                                      scope.row.scoreTypeName
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "兑换类型", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type == 1
                            ? _c("div", [_vm._v("数字藏品")])
                            : scope.row.type == 2
                            ? _c("div", [_vm._v("数字魔盒")])
                            : scope.row.type == 0
                            ? _c("div", [_vm._v("实物商品")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "兑换名称", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row.productName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "快递信息", "min-width": "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.deliverStatus != -1
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "color-red" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.deliverStatus == 0
                                          ? "未发货"
                                          : "已发货"
                                      ) + " "
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ml10",
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editDeliveryAction(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.deliverStatus == 0
                                              ? "点击发货"
                                              : "修改物流"
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                scope.row.deliveryCompany &&
                                scope.row.deliveryId
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.deliveryCompany) +
                                          " " +
                                          _vm._s(scope.row.deliveryId)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.receiptName
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.receiptName) +
                                          " " +
                                          _vm._s(scope.row.receiptPhone)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.address
                                  ? _c("div", [
                                      _vm._v(_vm._s(scope.row.address)),
                                    ])
                                  : _vm._e(),
                              ]
                            : _c("div", [_vm._v(" - ")]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "兑换时间", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-pagination", {
                  staticClass: "mt20",
                  staticStyle: { "text-align": "right" },
                  attrs: {
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.tableFromIssue.pageSize,
                    "current-page": _vm.tableFromIssue.pageNum,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.issueData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChangeIssue,
                    "current-change": _vm.pageChangeIssue,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.visibleShip
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleShip,
                "close-on-click-modal": false,
                title: "发货",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleShip = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "shipForm",
                  staticClass: "mt20",
                  attrs: {
                    size: "small",
                    model: _vm.shipForm,
                    rules: _vm.shipRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "快递公司", prop: "deliveryCompanyName" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择快递公司",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.shipForm.deliveryCompanyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.shipForm, "deliveryCompanyName", $$v)
                            },
                            expression: "shipForm.deliveryCompanyName",
                          },
                        },
                        _vm._l(_vm.dcList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号", prop: "deliveryId" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.shipForm.deliveryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipForm, "deliveryId", $$v)
                          },
                          expression: "shipForm.deliveryId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmShipAction("shipForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showdetail
        ? _c("userdetail", {
            attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
            on: {
              close: function ($event) {
                _vm.showdetail = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }