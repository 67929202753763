var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container workDetails",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.noEdit },
                      model: {
                        value: _vm.workDetail.nftType,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "nftType", $$v)
                        },
                        expression: "workDetail.nftType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("数字版权授权"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("数字版权转让"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "适应类型：", prop: "categoryId" } },
                        [
                          _c(
                            "el-select",
                            {
                              on: { change: _vm.getAttrAction },
                              model: {
                                value: _vm.categoryId,
                                callback: function ($$v) {
                                  _vm.categoryId = $$v
                                },
                                expression: "categoryId",
                              },
                            },
                            _vm._l(_vm.categoryList, function (m, i) {
                              return _c("el-option", {
                                key: "categoryList" + i,
                                attrs: { value: m.id, label: m.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.attrList, function (m, i) {
                    return _c(
                      "el-col",
                      { key: i, attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: m.attrName + "：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "w300",
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择" + m.attrName,
                                },
                                model: {
                                  value: m.attrValueIds,
                                  callback: function ($$v) {
                                    _vm.$set(m, "attrValueIds", $$v)
                                  },
                                  expression: "m.attrValueIds",
                                },
                              },
                              _vm._l(
                                m.sysAttrValueList,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: "attr" + i + index,
                                    attrs: {
                                      label: item.attrValueName,
                                      value: item.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作者：", prop: "authorId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                placeholder: "选择作者",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.workDetail.authorId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "authorId", $$v)
                                },
                                expression: "workDetail.authorId",
                              },
                            },
                            _vm._l(_vm.authorArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "版权方：", prop: "authorId" } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.workDetail.userName))]),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticClass: "w200 ml20",
                              attrs: { placeholder: "分账比例" },
                              model: {
                                value: _vm.workDetail.chargeRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "chargeRate", $$v)
                                },
                                expression: "workDetail.chargeRate",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticClass: "w200",
                              attrs: { placeholder: "版税比例" },
                              model: {
                                value: _vm.workDetail.commissionRatio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "commissionRatio",
                                    $$v
                                  )
                                },
                                expression: "workDetail.commissionRatio",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上链选择：",
                            prop: "blockchainTypeId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: { placeholder: "请选择链方" },
                              model: {
                                value: _vm.workDetail.blockchainTypeId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "blockchainTypeId",
                                    $$v
                                  )
                                },
                                expression: "workDetail.blockchainTypeId",
                              },
                            },
                            _vm._l(_vm.blockchainArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "转赠规则：",
                            prop: "transferRuleId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: { placeholder: "请选择转赠规则" },
                              model: {
                                value: _vm.workDetail.transferRuleId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "transferRuleId",
                                    $$v
                                  )
                                },
                                expression: "workDetail.transferRuleId",
                              },
                            },
                            _vm._l(_vm.transferList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "作品编号：", prop: "numberPrefix" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入作品编号" },
                            model: {
                              value: _vm.workDetail.numberPrefix,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "numberPrefix", $$v)
                              },
                              expression: "workDetail.numberPrefix",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品标签：", prop: "tags" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标签，多个逗号分隔" },
                            model: {
                              value: _vm.workDetail.tags,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "tags", $$v)
                              },
                              expression: "workDetail.tags",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _vm.workDetail.nftType == 2
                ? [
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "padding-top": "20px",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "发行总量：", prop: "stock" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "align-items-c flex" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入发行总量",
                                        disabled: _vm.noEdit,
                                      },
                                      model: {
                                        value: _vm.workDetail.stock,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workDetail,
                                            "stock",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "workDetail.stock",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "custom-item",
                                attrs: { label: "预留数量：" },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "custom-radio-group",
                                    attrs: {
                                      disabled: _vm.noEdit && _vm.isShow,
                                    },
                                    model: {
                                      value: _vm.workDetail.reservedType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "reservedType",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.reservedType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("正常预留"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("指定编号"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.workDetail.reservedType === 0,
                                      expression:
                                        "workDetail.reservedType === 0",
                                    },
                                  ],
                                  staticClass: "ml20",
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    disabled: _vm.noEdit && _vm.isShow,
                                    type: "number",
                                    placeholder:
                                      "请输入预留数量(数量不超过发行总量)",
                                  },
                                  model: {
                                    value: _vm.workDetail.reservedQuantity,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "reservedQuantity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "workDetail.reservedQuantity",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.workDetail.reservedType === 1,
                                      expression:
                                        "workDetail.reservedType === 1",
                                    },
                                  ],
                                  staticClass: "ml20",
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    disabled: _vm.noEdit && _vm.isShow,
                                    placeholder: "多个编号用逗号隔开",
                                  },
                                  model: {
                                    value: _vm.workDetail.reservedNumbers,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "reservedNumbers",
                                        $$v
                                      )
                                    },
                                    expression: "workDetail.reservedNumbers",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml10 color-gray" }, [
                                  _vm._v(
                                    "（用于盲盒、合成、赠品、空投、预留）"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "限购数量：",
                                  prop: "purchaseQuantity",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: "每个用户的限购数量",
                                  },
                                  model: {
                                    value: _vm.workDetail.purchaseQuantity,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "purchaseQuantity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "workDetail.purchaseQuantity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "单次限购：",
                                  prop: "oneTimeBuyLimit",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: "单个订单可购数量",
                                  },
                                  model: {
                                    value: _vm.workDetail.oneTimeBuyLimit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "oneTimeBuyLimit",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "workDetail.oneTimeBuyLimit",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售价格：", prop: "price" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请输入发售价格",
                                type: "number",
                                oninput:
                                  "if(value.length>6)value=value.slice(0,6)",
                              },
                              model: {
                                value: _vm.workDetail.price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "price", $$v)
                                },
                                expression: "workDetail.price",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠价格：", prop: "vipPrice" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              oninput:
                                "if(value.length>6)value=value.slice(0,6)",
                              placeholder: "输入价格",
                            },
                            model: {
                              value: _vm.workDetail.vipPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workDetail,
                                  "vipPrice",
                                  _vm._n($$v)
                                )
                              },
                              expression: "workDetail.vipPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售时间：", prop: "startTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: "请选择发售时间",
                              align: "right",
                              disabled: _vm.noEdit,
                            },
                            model: {
                              value: _vm.workDetail.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "startTime", $$v)
                              },
                              expression: "workDetail.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "购买限制：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function ($event) {
                                    _vm.workDetail.snapshotId = ""
                                  },
                                },
                                model: {
                                  value: _vm.workDetail.crowdRestrictionType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "crowdRestrictionType",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.crowdRestrictionType",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c" },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("不限制"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("新用户可购买"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("人群可购买"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mr20",
                                        staticStyle: { width: "250px" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "选择名单",
                                              clearable: "",
                                              disabled:
                                                _vm.workDetail
                                                  .crowdRestrictionType != 2,
                                            },
                                            model: {
                                              value: _vm.workDetail.snapshotId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.workDetail,
                                                  "snapshotId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workDetail.snapshotId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.nameListsList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  value: item.id,
                                                  label: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("自定义人群数量"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-upload",
                                      {
                                        ref: "uploadImg",
                                        staticClass: "upload-demo mr10",
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          action: "#",
                                          limit: 1,
                                          "show-file-list": false,
                                          "http-request": function (file) {
                                            return _vm.airdropExcels(file)
                                          },
                                          disabled:
                                            _vm.workDetail
                                              .crowdRestrictionType != 3,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                              disabled:
                                                _vm.workDetail
                                                  .crowdRestrictionType != 3,
                                            },
                                          },
                                          [_vm._v("导入名单")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          size: "mini",
                                          disabled:
                                            _vm.workDetail
                                              .crowdRestrictionType != 3,
                                        },
                                        on: { click: _vm.downLoad },
                                      },
                                      [_vm._v("下载导入模板")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.workDetail.crowdRestrictionType == 3 &&
                            _vm.workDetail.crowdRestrictions &&
                            _vm.workDetail.crowdRestrictions.length
                              ? _c(
                                  "div",
                                  { staticClass: "flex align-items-c ml20" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "color-gray ft12" },
                                      [
                                        _vm._v(
                                          "已导入" +
                                            _vm._s(
                                              _vm.workDetail.crowdRestrictions
                                                .length
                                            ) +
                                            "人 数量" +
                                            _vm._s(_vm.crowdRestrictionsTotal)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ml10",
                                        attrs: {
                                          type: "text",
                                          size: "mini",
                                          disabled:
                                            _vm.workDetail
                                              .crowdRestrictionType != 3,
                                        },
                                        on: { click: _vm.delUploadAction },
                                      },
                                      [_vm._v("已导入 删除名单")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分抵扣：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.workDetail.pointsDeductionType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "pointsDeductionType",
                                    $$v
                                  )
                                },
                                expression: "workDetail.pointsDeductionType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("抵扣"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("兑换"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "积分消耗：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("span", [_vm._v("积分类型：")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w200" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "选择积分类型" },
                                    model: {
                                      value: _vm.workDetail.scoreTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "scoreTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.scoreTypeId",
                                    },
                                  },
                                  _vm._l(_vm.typeList, function (m, i) {
                                    return _c("el-option", {
                                      key: "type" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("积分数量："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticClass: "w200",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.workDetail.pointsDeduction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "pointsDeduction",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.pointsDeduction",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("积分"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("动态积分数量"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "ml10" },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#ccc",
                                  },
                                  model: {
                                    value: _vm.workDetail.hasAutoScore,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "hasAutoScore",
                                        $$v
                                      )
                                    },
                                    expression: "workDetail.hasAutoScore",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w200 ml20" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: !_vm.workDetail.hasAutoScore,
                                    },
                                    model: {
                                      value: _vm.workDetail.autoScoreType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "autoScoreType",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.autoScoreType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        value: 1,
                                        label: "按寄售市场最低价",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        value: 2,
                                        label: "按寄售市场均价",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticClass: "w200 ml10",
                                attrs: {
                                  type: "number",
                                  disabled: !_vm.workDetail.hasAutoScore,
                                },
                                model: {
                                  value: _vm.workDetail.autoCoefficient,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "autoCoefficient",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.autoCoefficient",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("倍"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分使用人群：" } },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              { staticStyle: { width: "400px" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "选择名单",
                                      clearable: "",
                                      multiple: "",
                                    },
                                    model: {
                                      value: _vm.workDetail.airdropIds,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "airdropIds",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.airdropIds",
                                    },
                                  },
                                  _vm._l(_vm.nameListsList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        label: item.name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "ml10",
                                staticStyle: {
                                  color: "#606266",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("（不选为全部用户可用）")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "赠送实物：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#ccc",
                              },
                              model: {
                                value: _vm.workDetail.hasGiveProduct,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "hasGiveProduct",
                                    $$v
                                  )
                                },
                                expression: "workDetail.hasGiveProduct",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w300" },
                              [
                                _c("el-input", {
                                  staticClass: "ml10",
                                  attrs: {
                                    placeholder: "提示文案",
                                    disabled: !_vm.workDetail.hasGiveProduct,
                                  },
                                  model: {
                                    value: _vm.workDetail.giveProductPrompt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workDetail,
                                        "giveProductPrompt",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "workDetail.giveProductPrompt",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("藏品操作："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  disabled: !_vm.workDetail.hasGiveProduct,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.workDetail.hasRecover,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workDetail, "hasRecover", $$v)
                                  },
                                  expression: "workDetail.hasRecover",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("保留"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("回收"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v(
                                "（打开后，订单支付后会提示用户输入收货地址，线下邮寄实物。）"
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "赠送优惠券：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#ccc",
                              },
                              model: {
                                value: _vm.workDetail.hasGiveCoupon,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "hasGiveCoupon", $$v)
                                },
                                expression: "workDetail.hasGiveCoupon",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticClass: "ml10",
                                attrs: {
                                  disabled: !_vm.workDetail.hasGiveCoupon,
                                },
                                model: {
                                  value: _vm.workDetail.couponId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workDetail, "couponId", $$v)
                                  },
                                  expression: "workDetail.couponId",
                                },
                              },
                              _vm._l(_vm.couponList, function (m, i) {
                                return _c("el-option", {
                                  key: "coupon" + i,
                                  attrs: { value: m.id, label: m.name },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "line-height": "36px",
                        color: "#606266",
                        "font-size": "14px",
                      },
                      attrs: { span: 16 },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "ml10",
                          attrs: { disabled: !_vm.workDetail.hasGiveCoupon },
                          model: {
                            value: _vm.workDetail.couponRule,
                            callback: function ($$v) {
                              _vm.$set(_vm.workDetail, "couponRule", $$v)
                            },
                            expression: "workDetail.couponRule",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("仅赠送一次"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("每次购买均赠送"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "赠送门票：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#ccc",
                              },
                              model: {
                                value: _vm.workDetail.hasGiveTicket,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workDetail, "hasGiveTicket", $$v)
                                },
                                expression: "workDetail.hasGiveTicket",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ml10 flex align-items-c",
                                staticStyle: { width: "180px" },
                              },
                              [
                                _c("span", [_vm._v("门票名称：")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex-one" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        disabled: !_vm.workDetail.hasGiveTicket,
                                      },
                                      model: {
                                        value: _vm.workDetail.ticketName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workDetail,
                                            "ticketName",
                                            $$v
                                          )
                                        },
                                        expression: "workDetail.ticketName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v("赠送数量："),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "ml10 w100",
                              attrs: {
                                precision: 0,
                                controls: false,
                                placeholder: "输入数量",
                                disabled: !_vm.workDetail.hasGiveTicket,
                              },
                              model: {
                                value: _vm.workDetail.giveTicketNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "giveTicketNum",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "workDetail.giveTicketNum",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v("单张可核销次数："),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "ml10 w100",
                              attrs: {
                                controls: false,
                                precision: 0,
                                placeholder: "输入次数",
                                disabled: !_vm.workDetail.hasGiveTicket,
                              },
                              model: {
                                value: _vm.workDetail.ticketUseNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "ticketUseNum",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "workDetail.ticketUseNum",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ml10 flex align-items-c",
                                staticStyle: { width: "580px" },
                              },
                              [
                                _c("span", { staticClass: "ml10" }, [
                                  _vm._v("门票有效期："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex-one" },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "datetimerange",
                                        "range-separator": "至",
                                        "start-placeholder": "选择开始时间",
                                        "end-placeholder": "选择结束时间",
                                        "value-format": "timestamp",
                                        disabled: !_vm.workDetail.hasGiveTicket,
                                      },
                                      on: { change: _vm.onchangeTime },
                                      model: {
                                        value: _vm.workDetail.validityPeriod,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workDetail,
                                            "validityPeriod",
                                            $$v
                                          )
                                        },
                                        expression: "workDetail.validityPeriod",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "抽签购：" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ccc",
                            },
                            model: {
                              value: _vm.workDetail.hasDrawLots,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "hasDrawLots", $$v)
                              },
                              expression: "workDetail.hasDrawLots",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "申购时间：" } }, [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "flex align-items-c",
                              staticStyle: { width: "480px" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-one" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetimerange",
                                      "range-separator": "至",
                                      "start-placeholder": "选择开始时间",
                                      "end-placeholder": "选择结束时间",
                                      "value-format": "timestamp",
                                      disabled: !_vm.workDetail.hasDrawLots,
                                    },
                                    on: { change: _vm.onchangeTime },
                                    model: {
                                      value: _vm.workDetail.drawLotsTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workDetail,
                                          "drawLotsTime",
                                          $$v
                                        )
                                      },
                                      expression: "workDetail.drawLotsTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [
                            _vm._v("中签及付款时间："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w200" },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择时间",
                                  "value-format": "timestamp",
                                  disabled: !_vm.workDetail.hasDrawLots,
                                },
                                model: {
                                  value: _vm.workDetail.luckyNumberTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workDetail,
                                      "luckyNumberTime",
                                      $$v
                                    )
                                  },
                                  expression: "workDetail.luckyNumberTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "中签数量：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("el-input-number", {
                              staticClass: "w200",
                              attrs: {
                                controls: false,
                                placeholder: "输入数量",
                                disabled: !_vm.workDetail.hasDrawLots,
                              },
                              model: {
                                value: _vm.workDetail.luckyNumberCount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workDetail,
                                    "luckyNumberCount",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "workDetail.luckyNumberCount",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }