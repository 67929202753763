"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _setting = require("@/api/setting");
var _product = require("@/api/product");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formValidate: {},
      adminarr: [],
      rules: {
        out_warehouse_id: [{
          required: true,
          message: "请选择仓库",
          trigger: "change"
        }],
        push_warehouse_id: [{
          required: true,
          message: "请选择仓库",
          trigger: "change"
        }],
        manage_admin_id: [{
          required: true,
          message: "请选择业务负责人",
          trigger: "cahnge"
        }]
      },
      storeArr: [],
      loading: false,
      multipleSelection: [],
      goodsArr: [],
      tableData: {
        data: [],
        total: 0
      },
      selectFlag: false,
      tableFrom: {
        product_name: '',
        product_id: '',
        page: 1,
        limit: 20
      },
      goodsIdArr: []
    };
  },
  mounted: function mounted() {
    this.getadminList();
    this.getstore();
  },
  methods: {
    close: function close() {
      this.$router.back();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this.formValidate.push_warehouse_id == _this.formValidate.out_warehouse_id) {
            _this.$message.error('不能选择同一仓库');
            return;
          }
          _this.loading = true;
          (0, _warehouse.createTransferOrder)({
            push_warehouse_id: _this.formValidate.push_warehouse_id,
            out_warehouse_id: _this.formValidate.out_warehouse_id,
            manage_admin_id: _this.formValidate.manage_admin_id,
            productData: _this.goodsArr
          }).then(function (res) {
            if (res.status == 200) {
              _this.$router.back();
            }
          }).catch(function (err) {
            _this.$message.error(err.message);
          });
        } else {
          return false;
        }
      });
    },
    selectoutp: function selectoutp() {
      this.selectActiveGoods();
    },
    getadminList: function getadminList() {
      var _this2 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this2.adminarr = res.data.list;
      });
    },
    getstore: function getstore() {
      var _this3 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this3.storeArr = res.data.list;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectActiveGoods: function selectActiveGoods() {
      this.selectFlag = true;
      this.getList();
    },
    getList: function getList() {
      var _this4 = this;
      (0, _basic.goodswarehouseList)({
        warehouse_id: this.formValidate.out_warehouse_id,
        product_name: this.tableFrom.product_name,
        product_id: this.product_id
      }).then(function (res) {
        console.log(res);
        res.data.list.forEach(function (item) {
          item.selected = false;
        });
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      });
    },
    closeSelect: function closeSelect() {
      this.selectFlag = false;
    },
    saleGoods: function saleGoods(row, index) {
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      var newrow = JSON.parse(JSON.stringify(row));
      this.goodsArr.push(newrow);
      this.goodsIdArr.push(row.product_id);
    },
    delGoods: function delGoods(index, row) {
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    }
  }
};