var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "type", $$v)
                    },
                    expression: "tableFrom.type",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: {
                      name: item.type.toString(),
                      label: item.name + "(" + item.count + ")",
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.mer_cate_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "mer_cate_id", $$v)
                                },
                                expression: "tableFrom.mer_cate_id",
                              },
                            },
                            _vm._l(_vm.merCateList, function (item) {
                              return _c("el-option", {
                                key: item.store_category_id,
                                attrs: {
                                  label: item.cate_name,
                                  value: item.store_category_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否为礼包：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.is_gift_bag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "is_gift_bag", $$v)
                                },
                                expression: "tableFrom.is_gift_bag\t",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "否", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键字搜索：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入商品名称，关键字" },
                              model: {
                                value: _vm.tableFrom.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keyword", $$v)
                                },
                                expression: "tableFrom.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "" + _vm.roterPre + "/product/list/addProduct",
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("添加商品")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand demo-table-expand1",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品分类：" } },
                              [
                                props.row.storeCategory
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          props.row.storeCategory.cate_name
                                        )
                                      ),
                                    ]
                                  : _c("span", [_vm._v("-")]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "品牌：" } }, [
                              _c("span", { staticClass: "mr10" }, [
                                _vm._v(
                                  _vm._s(
                                    props.row.brand
                                      ? props.row.brand.brand_name
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "市场价格：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.ot_price)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "成本价：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.cost)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "收藏：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.care_count)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            props.row.attr_info &&
                            props.row.attr_info.length > 0
                              ? _vm._l(props.row.attr_info, function (m, i) {
                                  return _c(
                                    "el-form-item",
                                    { key: i, attrs: { label: m.attr_name } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(m.attr_value)
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.tableFrom.type === "7"
                              ? _c(
                                  "el-form-item",
                                  {
                                    key: "1",
                                    attrs: { label: "未通过原因：" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(props.row.refusal)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "product_id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "商品名称",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "商品售价", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sales", label: "销量", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "库存", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "排序", "min-width": "70" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "促销" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.buy_limit_num) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              Number(_vm.tableFrom.type) < 5
                ? _c("el-table-column", {
                    key: "1",
                    attrs: {
                      prop: "status",
                      label: "状态",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "上架",
                                  "inactive-text": "下架",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onchangeIsShow(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.mer_status,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "mer_status", $$v)
                                  },
                                  expression: "scope.row.mer_status",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2045393787
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "left",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyXcxLink(
                                            scope.row,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序链接\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyH5Link(
                                            scope.row,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  H5链接\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(1, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序码\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(2, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  二维码\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(3, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序海报\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(4, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  二维码海报\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 m0 plr2",
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v("\n              推广链接\n            ")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.type != 5
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path:
                                      _vm.roterPre +
                                      "/product/list/addProduct/" +
                                      scope.row.product_id,
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "text", size: "small" } },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showSkuAction(scope.row.sku)
                              },
                            },
                          },
                          [_vm._v("查看规格")]
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.type === "5"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRestore(
                                      scope.row.product_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("恢复商品")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type !== "1" &&
                        _vm.tableFrom.type !== "3" &&
                        _vm.tableFrom.type !== "4"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.product_id,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.tableFrom.type === "5"
                                      ? "删除"
                                      : "加入回收站"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: { title: "查看规格", visible: _vm.showSku, size: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.showSku = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "sku-list" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.skuList, size: "mini" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "sku",
                      label: "规格名称",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(scope.row.sku ? scope.row.sku : "默认")
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "price", label: "价格", "min-width": "80" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ot_price",
                      label: "原价",
                      "min-width": "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stock",
                      label: "剩余库存",
                      "min-width": "80",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImg,
            "before-close": _vm.beforeCloseImg,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }