var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "playimage"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [_vm._m(0)]
            ),
          ]
        : _vm.content.type === "bindPrice"
        ? [
            _c("div", { staticClass: "blind-price-box" }, [
              _c(
                "div",
                {
                  staticClass: "price-box-content",
                  style:
                    "margin-top: " +
                    _vm.facade.marginTop / 2 +
                    "px;border-color:" +
                    _vm.facade.borderColor +
                    ";box-shadow: 0 0 10px " +
                    _vm.facade.borderColor +
                    ";color:" +
                    _vm.facade.color,
                },
                [
                  _vm._v("200 "),
                  _c(
                    "span",
                    {
                      staticClass: "price-box-content-sign",
                      style: "color:" + _vm.facade.color,
                    },
                    [_vm._v("元 / 个")]
                  ),
                ]
              ),
            ]),
          ]
        : _vm.content.type === "open"
        ? [
            _c("div", { staticClass: "bg-img" }, [
              _c("img", {
                staticClass: "animate0",
                attrs: { src: _vm.facade.xuan_image, alt: "" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "content-image" }, [
                _c("img", {
                  attrs: { src: _vm.facade.open_title_image, alt: "" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content-price" }, [
                _c(
                  "div",
                  {
                    staticClass: "price-box",
                    style:
                      "border-color:" +
                      _vm.facade.price_border_color +
                      ";background-color:" +
                      _vm.facade.price_bg_color +
                      ";background-image:url(" +
                      _vm.facade.price_bg_image +
                      ");color:" +
                      _vm.facade.price_color,
                  },
                  [
                    _c("div", { staticClass: "price-sign" }, [_vm._v("￥")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "price-num" }, [_vm._v("1.00")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "product-image",
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "product-name",
                  style:
                    "color:" +
                    _vm.facade.goods_color +
                    ";text-shadow: 0px 0px 4px " +
                    _vm.facade.goods_shadow_color +
                    ";",
                },
                [_vm._v("商品名称")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "product-open",
                  style:
                    "background-color:" +
                    _vm.facade.open_button_background +
                    ";color:" +
                    _vm.facade.open_button_title_color +
                    ";border-color:" +
                    _vm.facade.open_button_border_background +
                    ";border-radius:" +
                    _vm.facade.open_button_radius / 2 +
                    "px",
                },
                [_vm._v("剩余1次 立即开箱")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "product-refresh",
                  style:
                    "background-color:" +
                    _vm.facade.xuan_open_button_background +
                    ";color:" +
                    _vm.facade.xuan_open_button_title_color +
                    ";border-color:" +
                    _vm.facade.xuan_open_button_border_background +
                    ";border-radius:" +
                    _vm.facade.xuan_open_button_radius / 2 +
                    "px",
                },
                [_vm._v("炫耀一下")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "product-sign",
                  style: "color:" + _vm.facade.go_color,
                },
                [_vm._v("哈！去看看我的宝贝")]
              ),
            ]),
          ]
        : _vm.content.type === "waitopen"
        ? [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "content-image" }, [
                _c("img", {
                  attrs: { src: _vm.facade.wait_open_title_image, alt: "" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "product-open lbutton" }, [
                _vm._v("立即开箱"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "product-refresh",
                  style:
                    "background-color:" +
                    _vm.facade.zan_open_button_background +
                    ";color:" +
                    _vm.facade.zan_open_button_title_color +
                    ";border-color:" +
                    _vm.facade.zan_open_button_border_background +
                    ";border-radius:" +
                    _vm.facade.zan_open_button_radius / 2 +
                    "px",
                },
                [_vm._v("暂不开箱")]
              ),
            ]),
          ]
        : _vm.content.type == "posterbg"
        ? [
            _c("img", {
              staticStyle: { width: "300px" },
              attrs: { src: _vm.facade.bgimage, alt: "" },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "item-flex-center",
        staticStyle: {
          width: "150px",
          height: "150px",
          "margin-left": "112.5px",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "100%", height: "100%" },
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
            alt: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }