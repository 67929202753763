var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "top-view" }, [
          _c(
            "div",
            { staticStyle: { "margin-top": "-10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.createdAction },
                },
                [_vm._v("创建助力有奖活动")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  size: "small",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "id", label: "ID", "min-width": "100" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "title",
                    label: "活动名称",
                    "min-width": "100",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "活动状态", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.status == 0
                            ? _c("div", [_vm._v("未开始")])
                            : scope.row.status == 1
                            ? _c("div", [_vm._v("进行中")])
                            : scope.row.status == 2
                            ? _c("div", [_vm._v("已结束")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发起人数", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showReceiveDataAction(scope.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(scope.row.forHelpNum)
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "助力人数", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.formatPriceNumber(scope.row.helpNum))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "成功领奖", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(scope.row.successNum)
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "活动时间", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v("开始时间：" + _vm._s(scope.row.startTime)),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("结束时间 " + _vm._s(scope.row.endTime)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "奖品", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "text",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showReceiveDataAction(scope.row)
                                },
                              },
                              slot: "reference",
                            },
                            [_vm._v("查看奖品\n            ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建时间",
                    prop: "createTime",
                    "min-width": "80",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12 p5-0",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAction(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              scope.row.status == 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12 p5-0",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editStatusAction(
                                            scope.row.id,
                                            2
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("结束")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12 p5-0",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editStatusAction(
                                            scope.row.id,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除\n              ")]
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [20, 40, 60, 80],
                    "page-size": _vm.tableFrom.pageSize,
                    "current-page": _vm.tableFrom.pageNum,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.pageChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "70%",
            visible: _vm.receiveData.show,
            "append-to-body": "",
            title: "领取记录",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.receiveData, "show", $event)
            },
          },
        },
        [
          _vm.receiveData.data
            ? _c("div", { staticClass: "receive-data flex align-items-c" }, [
                _c("div", { staticClass: "flex align-items-c flex-one" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("发起人数：")]),
                    _c("span", { staticStyle: { color: "#28ab29" } }, [
                      _vm._v(_vm._s(_vm.receiveData.data["发起人数"])),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("助力人数：")]),
                    _c("span", { staticStyle: { color: "#28ab29" } }, [
                      _vm._v(_vm._s(_vm.receiveData.data["助力人数"])),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("领奖人数：")]),
                    _c("span", { staticStyle: { color: "#28ab29" } }, [
                      _vm._v(_vm._s(_vm.receiveData.data["领奖人数"])),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.exportOrder },
                      },
                      [_vm._v("导出")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.getExportFileList },
                      },
                      [_vm._v("导出已生成列表")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "receive-list mt20" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.receiveData.listLoading,
                      expression: "receiveData.listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.receiveData.list,
                    size: "small",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "用户", "min-width": "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.nickName) +
                                  " "
                              ),
                              scope.row.phone
                                ? _c("span", [
                                    _vm._v("(" + _vm._s(scope.row.phone) + ")"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "助力人数", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(scope.row.toHelpNum)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "实名人数", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(scope.row.realNum || 0)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "开通钱包人数", "min-width": "95" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.openWalletNum || 0
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "购买次数", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.formatPriceNumber(scope.row.buyNum))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "任务完成", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.getPrizeNum || 0
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "已领取奖品", "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "400",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          color: "#000",
                                        },
                                        attrs: {
                                          data: scope.row.alreadyGetDetails,
                                          "highlight-current-row": "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: { label: "奖品类型" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    params.row.prizeType ==
                                                    "product_nft"
                                                      ? _c("div", [
                                                          _vm._v("数字藏品"),
                                                        ])
                                                      : params.row.prizeType ==
                                                        "product_nft_blind"
                                                      ? _c("div", [
                                                          _vm._v("数字盲盒"),
                                                        ])
                                                      : params.row.prizeType ==
                                                        "integral"
                                                      ? _c("div", [
                                                          _vm._v("积分"),
                                                        ])
                                                      : params.row.prizeType ==
                                                        "coupon"
                                                      ? _c("div", [
                                                          _vm._v("优惠券"),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "奖品名称",
                                            "min-width": "200",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "num" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            params.row.prizeName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "数量",
                                            "min-width": "200",
                                            prop: "number",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "mini",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(_vm._s(scope.row.alreadyGetNum))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "待领取奖品", "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "400",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          color: "#000",
                                        },
                                        attrs: {
                                          data: scope.row.waitToGetDetails,
                                          "highlight-current-row": "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: { label: "奖品类型" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    params.row.prizeType ==
                                                    "product_nft"
                                                      ? _c("div", [
                                                          _vm._v("数字藏品"),
                                                        ])
                                                      : params.row.prizeType ==
                                                        "product_nft_blind"
                                                      ? _c("div", [
                                                          _vm._v("数字盲盒"),
                                                        ])
                                                      : params.row.prizeType ==
                                                        "integral"
                                                      ? _c("div", [
                                                          _vm._v("积分"),
                                                        ])
                                                      : params.row.prizeType ==
                                                        "coupon"
                                                      ? _c("div", [
                                                          _vm._v("优惠券"),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "奖品名称",
                                            "min-width": "200",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "num" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            params.row.prizeName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "数量",
                                            "min-width": "200",
                                            prop: "number",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "mini",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(_vm._s(scope.row.waitToGetNum))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "参与时间", "min-width": "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.createTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "block mt20",
                  staticStyle: { "text-align": "right" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.receiveData.query.pageSize,
                      "current-page": _vm.receiveData.query.pageNum,
                      total: _vm.receiveData.total,
                    },
                    on: { "current-change": _vm.pageChangeNew },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }