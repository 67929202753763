var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "mt20" },
      [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("创建盲盒")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData.data,
              size: "small",
              "row-key": "box_id",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "box_id", label: "ID", "min-width": "60" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "80", label: "图片" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "demo-image__preview" },
                        [
                          _c("el-image", {
                            attrs: {
                              src: scope.row.image + "!120a",
                              "preview-src-list": [scope.row.image],
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "盲盒名称", prop: "title", "min-width": "150" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "status", label: "状态", "min-width": "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status == 1
                        ? _c("div", [
                            _vm._v("\n            发售中\n          "),
                          ])
                        : scope.row.status == -1
                        ? _c("div", [_vm._v("已下架")])
                        : _c("div", [_vm._v("未发售")]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "start_time",
                label: "发行状态",
                "min-width": "100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          style:
                            scope.row.issue_status == 0 ? "color:#28ab29" : "",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.issue_status == 1
                                  ? "已发行"
                                  : "未发行"
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "sale_time",
                label: "发售时间",
                "min-width": "150",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "已售出/发售数量", "min-width": "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(scope.row.sold_number) +
                            "/" +
                            _vm._s(scope.row.total_number)
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "nft_product_count",
                label: "数字藏品数量",
                "min-width": "100",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "100", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onEdit(scope.row.box_id)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _vm._v(" "),
                      scope.row.status == -1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.changestatus(scope.row.box_id, 1)
                                },
                              },
                            },
                            [_vm._v("上架")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.changestatus(scope.row.box_id, -1)
                                },
                              },
                            },
                            [_vm._v("下架")]
                          ),
                      _vm._v(" "),
                      scope.row.issue_status == 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeIssueStatus(
                                    scope.row.box_id,
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("发行")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.issue_status == 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.previewAction(scope.row.box_id)
                                },
                              },
                            },
                            [_vm._v("预览")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(
                                scope.row.box_id,
                                scope.$index
                              )
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("file-list", { ref: "exportList" }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.showImg,
              "close-on-click-modal": false,
              center: "",
              title: "查看二维码",
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showImg = $event
              },
            },
          },
          [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.showImgUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }