"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ipip = require("@/api/ipip");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "printSetting",
  data: function data() {
    return {
      visibleLabel: false,
      list: [{}],
      columnList: [],
      frameList: [],
      formState: {},
      rules: {
        frameName: [{
          required: true,
          message: "请输入画框名称",
          trigger: "blur"
        }],
        frameImage: [{
          required: true,
          message: '请上传画框图片',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {
    this.init();
    this.frameInit();
  },
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var _this = this;
      (0, _ipip.sizeList)().then(function (res) {
        _this.columnList = res.data;
      });
    },
    frameInit: function frameInit() {
      var _this2 = this;
      (0, _ipip.getFrameList)().then(function (res) {
        _this2.frameList = res.data;
      });
    },
    onInputBlur: function onInputBlur(row) {
      var model = {
        price: row.price,
        id: row.id,
        type: 1
      };
      this.updHander(model);
    },
    onInputBlurOne: function onInputBlurOne(row) {
      var model = {
        price: row.price,
        id: row.frameId,
        type: 2
      };
      this.updHander(model);
    },
    updHander: function updHander(model) {
      var _this3 = this;
      (0, _ipip.printUpdateApi)(model).then(function (res) {
        _this3.init();
        _this3.frameInit();
        _this3.visibleLabel = false;
      });
    },
    onSubmit: function onSubmit() {
      var _this4 = this;
      this.$refs.labelForm.validate(function (valid) {
        if (valid) {
          if (_this4.formState.id) {
            _this4.updHander(_this4.formState);
            return false;
          }
          (0, _ipip.frameAdd)(_this4.formState).then(function (res) {
            console.log(res);
            _this4.visibleLabel = false;
            _this4.$message.success("操作成功");
            _this4.frameInit();
          });
        }
      });
    },
    updOpen: function updOpen(row) {
      this.formState = {
        id: row.sizeForFrames[0].frameId,
        type: 3,
        frameImage: row.frameImage,
        frameName: row.frameName
      };
      this.visibleLabel = true;
    },
    addFrame: function addFrame() {
      this.formState = {};
      this.visibleLabel = true;
    },
    modalPicTap: function modalPicTap() {
      var that = this;
      this.$modalUpload(function (img) {
        that.formState.frameImage = img[0];
        that.$forceUpdate();
      });
    },
    modalPicTapUpd: function modalPicTapUpd(row) {
      var that = this;
      this.$modalUpload(function (img) {
        row.frameImage = img[0];
        var model = {
          id: row.sizeForFrames[0].frameId,
          type: 3,
          frameImage: img[0]
        };
        that.updHander(model);
      });
    }
  }
};