var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { staticClass: "mb-20", attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { label: "时间/ID", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "demand-time" }, [
                      _vm._v(_vm._s(row.createTime)),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(row.id))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "需求标题", prop: "title", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "雇主", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("span", { staticClass: "blue-text" }, [
                      _vm._v(_vm._s(row.employerUser)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "服务商", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("span", { staticClass: "blue-text" }, [
                      _vm._v(_vm._s(row.demandMemberHitInfo.hitUserName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "模型类型", width: "100", prop: "modelTypeName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "协议工期", width: "100", prop: "workDay" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "协议费用", prop: "budget", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "签署协议", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "flex-b flex-c" }, [
                      _c("span", [
                        _vm._v(_vm._s(row.signFileCount) + " 个附件"),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(row.signTime))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "赏金托管", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "flex-b flex-c" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            row.demandMemberHitInfo.hasTrusteeshipPrice
                              ? "已托管"
                              : "未托管"
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      row.demandMemberHitInfo.hasTrusteeshipPrice
                        ? _c("span", [
                            _vm._v(
                              _vm._s(row.demandMemberHitInfo.trusteeshipTime)
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "交付状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: row.deliveryStatus == 0,
                            expression: "row.deliveryStatus == 0",
                          },
                        ],
                      },
                      [_vm._v("制作中")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: row.deliveryStatus == 1,
                            expression: "row.deliveryStatus == 1",
                          },
                        ],
                      },
                      [_vm._v("交付中")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: row.deliveryStatus == 2,
                            expression: "row.deliveryStatus == 2",
                          },
                        ],
                      },
                      [_vm._v("已交付")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "交付内容", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "flex-b flex-c" }, [
                      _c("span", [
                        _vm._v(_vm._s(row.fileCount || 0) + " 个附件"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(row.imageCount || 0) + " 张图片"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(row.videoCoutnt || 0) + " 个视频"),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              fixed: "right",
              align: "center",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", { staticClass: "flex-b" }, [
                      _c("span", { staticClass: "blue-text" }, [
                        _vm._v("确认交付"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "blue-text" }, [
                        _vm._v("联系雇主"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          staticClass: "blue-text",
                        },
                        [_vm._v("匹配派单")]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }