var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearfix" },
        [
          _c(
            "el-form",
            { attrs: { size: "small", inline: "", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户渠道：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.source,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "source", $$v)
                        },
                        expression: "queryParams.source",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("微信用户"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("小程序用户"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("APP用户"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("H5用户"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实名认证：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.hasVerified,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "hasVerified", $$v)
                        },
                        expression: "queryParams.hasVerified",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("未实名"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("已实名"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.userStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "userStatus", $$v)
                        },
                        expression: "queryParams.userStatus",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("封禁"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("锁单"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "访问时间：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      size: "small",
                      type: "datetimerange",
                    },
                    on: { change: _vm.onchangeTime },
                    model: {
                      value: _vm.queryParams.timeVal,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "timeVal", $$v)
                      },
                      expression: "queryParams.timeVal",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户标签：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择用户标签", clearable: "" },
                      on: { change: _vm.tagIdChange },
                      model: {
                        value: _vm.queryParams.tagId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "tagId", $$v)
                        },
                        expression: "queryParams.tagId",
                      },
                    },
                    _vm._l(_vm.tagList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户等级：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择用户等级", clearable: "" },
                      on: { change: _vm.tagIdChange },
                      model: {
                        value: _vm.queryParams.memberLevelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "memberLevelId", $$v)
                        },
                        expression: "queryParams.memberLevelId",
                      },
                    },
                    _vm._l(_vm.gradeList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.memberLevelName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "客户名称/手机号" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.queryParams.nameOrPhone,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "nameOrPhone", $$v)
                },
                expression: "queryParams.nameOrPhone",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "发行购买次数" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.queryParams.firstBuyNum,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "firstBuyNum", $$v)
                },
                expression: "queryParams.firstBuyNum",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "寄售购买次数" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.queryParams.consignmentBuyNum,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "consignmentBuyNum", $$v)
                },
                expression: "queryParams.consignmentBuyNum",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "寄售挂单次数" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.queryParams.consignmentNum,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "consignmentNum", $$v)
                },
                expression: "queryParams.consignmentNum",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "区块链账户地址" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.queryParams.blockLinkName,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "blockLinkName", $$v)
                },
                expression: "queryParams.blockLinkName",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-button",
            {
              staticClass: "mr15",
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                type: "primary",
                icon: "ios-search",
                label: "default",
                size: "small",
              },
              on: { click: _vm.adduser },
            },
            [_vm._v("添加用户")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportOrder },
            },
            [_vm._v("导出用户")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "text" },
              on: { click: _vm.getExportFileList },
            },
            [_vm._v("查看导出记录")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { width: "50" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        staticClass: "tabPop",
                        attrs: {
                          placement: "top-start",
                          width: "100",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "spBlock onHand",
                              class: { check: _vm.chkName === "dan" },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandle("dan", scope.$index)
                                },
                              },
                            },
                            [_vm._v("选中本页")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-checkbox", {
                          attrs: {
                            slot: "reference",
                            value:
                              (_vm.chkName === "dan" &&
                                _vm.checkedPage.indexOf(
                                  _vm.queryParams.pageNum
                                ) > -1) ||
                              _vm.chkName === "duo",
                          },
                          on: { change: _vm.changeType },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: {
                        value:
                          _vm.checkedIds.indexOf(scope.row.userId) > -1 ||
                          (_vm.chkName === "duo" &&
                            _vm.noChecked.indexOf(scope.row.userId) === -1),
                      },
                      on: {
                        change: function (v) {
                          return _vm.changeOne(v, scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "userId", label: "ID", "min-width": "160" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: { src: scope.row.avatar, fit: "cover" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "等级", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.memberLevel))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户名称", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.nickNameClick(row.userId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.nickName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "手机号", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.userPhone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "邀请人", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.nickNameClick(row.inviterId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.inviterName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "邀请人手机号", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.inviterPhone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分销员", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.nickNameClick(row.retailUserId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.retailUserName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "注册渠道", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatSource")(row.source))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户标签", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.memberTags
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              row.memberTags
                                .map(function (m) {
                                  return m.name
                                })
                                .join(",")
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatUserStatus")(row.userStatus))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "实名状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.nickNameClick(row.userId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("formatHasVerified")(row.hasVerified))
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "区块链开户", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.blockLinkName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "购买授权", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.nickNameClick(row.userId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.firstBuyNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "购买版权", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.nickNameClick(row.userId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.copyrightBuyNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "注册时间", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "150",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.userStatus === 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "m0 p0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.setStatus(scope.row)
                              },
                            },
                          },
                          [_vm._v("禁用")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "m0 p0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.setStatus(scope.row)
                              },
                            },
                          },
                          [_vm._v("解禁")]
                        ),
                    _vm._v(" "),
                    scope.row.isLock
                      ? _c(
                          "el-button",
                          {
                            staticClass: "m0 p0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.unlockAction(scope.row)
                              },
                            },
                          },
                          [_vm._v("解锁\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.setLabel(scope.row)
                          },
                        },
                      },
                      [_vm._v("标签")]
                    ),
                    _vm._v(" "),
                    _c("div"),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.presentIntegral(scope.row)
                          },
                        },
                      },
                      [_vm._v("积分变更\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.getCoupon(scope.row)
                          },
                        },
                      },
                      [_vm._v("赠送优惠券\n          ")]
                    ),
                    _vm._v(" "),
                    _c("div"),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.nickNameClick(scope.row.userId)
                          },
                        },
                      },
                      [_vm._v("资产分析")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.unlockCity(scope.row)
                          },
                        },
                      },
                      [_vm._v("解除城市锁定\n          ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block flex justify-e" },
        [
          _c(
            "div",
            { staticClass: "flex-one mt20 align-items-c flex" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: {
                    type: "primary",
                    icon: "ios-search",
                    label: "default",
                    size: "small",
                  },
                  on: { click: _vm.batchLabel },
                },
                [_vm._v("\n          批量加标签")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: {
                    type: "primary",
                    icon: "ios-search",
                    label: "default",
                    size: "small",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchUserStatusAction(false)
                    },
                  },
                },
                [_vm._v("批量禁用")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: {
                    type: "primary",
                    icon: "ios-search",
                    label: "default",
                    size: "small",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchUserStatusAction(true)
                    },
                  },
                },
                [_vm._v("批量解禁")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: {
                    type: "primary",
                    icon: "ios-search",
                    label: "default",
                    size: "small",
                  },
                  on: { click: _vm.batchLockTypeAction },
                },
                [_vm._v("批量解锁")]
              ),
              _vm._v(" "),
              _vm.checkedIds.length > 0 || _vm.allCheck
                ? _c("el-alert", {
                    staticClass: "w200",
                    staticStyle: { height: "32px" },
                    attrs: {
                      title: _vm.allCheck
                        ? "已选择  " + _vm.tableData.total + "  项"
                        : "已选择  " + _vm.checkedIds.length + "  项",
                      type: "info",
                      "show-icon": "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "mt20",
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.queryParams.pageSize,
              "current-page": _vm.queryParams.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? _c("userdetail", {
            attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
            on: { close: _vm.userdetailclose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleUser
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleUser,
                "close-on-click-modal": false,
                center: "",
                title: "添加用户",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleUser = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "addUserForm",
                  attrs: {
                    size: "small",
                    model: _vm.addUserForm,
                    rules: _vm.userRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户头像", prop: "avatar" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "item-align-center mb20",
                          staticStyle: {
                            display: "inline-block",
                            color: "#606266",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upload-img-view item-flex-center",
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap(1, "image")
                                },
                              },
                            },
                            [
                              _vm.addUserForm.avatar
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "del-upload-img",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delImg("image")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2023131/image/1675135522101940138.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.addUserForm.avatar
                                ? _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: {
                                      src: _vm.addUserForm.avatar + "!120a",
                                    },
                                  })
                                : _c("div", {
                                    staticClass: "upload-icon",
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px",
                                      background: "#eaeef4",
                                    },
                                  }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户名", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addUserForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.addUserForm, "name", $$v)
                          },
                          expression: "addUserForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "phone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addUserForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.addUserForm, "phone", $$v)
                          },
                          expression: "addUserForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.addUserForm.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.addUserForm, "sex", $$v)
                            },
                            expression: "addUserForm.sex",
                          },
                        },
                        [_vm._v("男")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.addUserForm.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.addUserForm, "sex", $$v)
                            },
                            expression: "addUserForm.sex",
                          },
                        },
                        [_vm._v("女")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmUserAction("addUserForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleLabel
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleLabel,
                "close-on-click-modal": false,
                center: "",
                title: "修改标签",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleLabel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "labelForm",
                  attrs: {
                    size: "small",
                    model: _vm.labelForm,
                    rules: _vm.labelRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户标签", prop: "tagId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: "请选择用户标签",
                          },
                          model: {
                            value: _vm.labelForm.tagId,
                            callback: function ($$v) {
                              _vm.$set(_vm.labelForm, "tagId", $$v)
                            },
                            expression: "labelForm.tagId",
                          },
                        },
                        _vm._l(_vm.tagList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmLabelAction("labelForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleUserStatus
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleUserStatus,
                "close-on-click-modal": false,
                center: "",
                title: "选择封禁选项",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleUserStatus = $event
                },
              },
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.userStatusForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.userStatusForm, "status", $$v)
                    },
                    expression: "userStatusForm.status",
                  },
                },
                [_vm._v("账号封禁")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.userStatusForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.userStatusForm, "status", $$v)
                    },
                    expression: "userStatusForm.status",
                  },
                },
                [_vm._v("功能封禁")]
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-checkbox-group",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.userStatusForm.status == "2",
                      expression: "userStatusForm.status == '2'",
                    },
                  ],
                  model: {
                    value: _vm.userStatusForm.types,
                    callback: function ($$v) {
                      _vm.$set(_vm.userStatusForm, "types", $$v)
                    },
                    expression: "userStatusForm.types",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "1" } }, [
                    _vm._v("购买授权"),
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: "2" } }, [
                    _vm._v("购买版权"),
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: "3" } }, [
                    _vm._v("产品服务"),
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: "4" } }, [
                    _vm._v("图片建模"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmUserStatusAction },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "z-index": "999" },
          attrs: {
            title: "积分变动",
            visible: _vm.integralDialog,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.integralDialog = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.integralNum,
                callback: function ($$v) {
                  _vm.integralNum = $$v
                },
                expression: "integralNum",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("积分变更")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 3 } }, [_vm._v("减扣积分")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w100 ml10",
                  attrs: { placeholder: "积分类型" },
                  model: {
                    value: _vm.objs.scoreTypeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.objs, "scoreTypeId", $$v)
                    },
                    expression: "objs.scoreTypeId",
                  },
                },
                _vm._l(_vm.typeList, function (m, i) {
                  return _c("el-option", {
                    key: "type" + i,
                    attrs: { value: m.id, label: m.name },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w150",
                attrs: { type: "number", placeholder: "积分数量" },
                model: {
                  value: _vm.objs.number,
                  callback: function ($$v) {
                    _vm.$set(_vm.objs, "number", $$v)
                  },
                  expression: "objs.number",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.userChange(_vm.integralNum)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "z-index": "999" },
          attrs: {
            title: "赠送优惠券",
            visible: _vm.GiveAwayCoupons,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.GiveAwayCoupons = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "", placeholder: "请选择优惠券" },
              model: {
                value: _vm.objs.couponId,
                callback: function ($$v) {
                  _vm.$set(_vm.objs, "couponId", $$v)
                },
                expression: "objs.couponId",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    "margin-right": "10px",
                  },
                },
                [_vm._v("数量")]
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w150",
                attrs: {
                  type: "number",
                  placeholder: "输入数量",
                  size: "mini",
                },
                model: {
                  value: _vm.objs.number,
                  callback: function ($$v) {
                    _vm.$set(_vm.objs, "number", $$v)
                  },
                  expression: "objs.number",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.userChange(2)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }