"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var auctionRouter = {
  path: "".concat(_settings.roterPre, "/digitalCollection"),
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
  meta: {
    title: '数字藏品',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'works',
    name: 'digitalCollectionWorks',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
    meta: {
      title: '数字藏品',
      noCache: true
    },
    children: [{
      path: 'album',
      name: 'digitalCollection_album_list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/albumList'));
        });
      },
      meta: {
        title: '专辑管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list")
      }
    }, {
      path: 'list',
      name: 'digitalCollection_list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/workList'));
        });
      },
      meta: {
        title: '数字藏品',
        noCache: true
      }
    }, {
      path: 'pass_list',
      name: 'digitalCollection_pass_list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/passList'));
        });
      },
      meta: {
        title: '通证列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list")
      }
    }, {
      path: 'recovery_record',
      name: 'digitalCollection_recoveryRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/recoveryRecord'));
        });
      },
      meta: {
        title: '回收记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list")
      }
    }, {
      path: 'destroy_record',
      name: 'digitalCollection_destroyRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/destroyRecord'));
        });
      },
      meta: {
        title: '销毁记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list")
      }
    }, {
      path: 'gift_record',
      name: 'digitalCollection_giftRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/giftRecord'));
        });
      },
      meta: {
        title: '赠与记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list")
      }
    }, {
      path: 'deliver_list',
      name: 'digitalCollection_deliverList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/deliverList'));
        });
      },
      meta: {
        title: '空投列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list")
      }
    }, {
      path: 'nft_rule',
      name: 'digitalCollection_nft_rule',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/rule'));
        });
      },
      meta: {
        title: '配置',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/nft_rule")
      }
    }]
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/works/detail"),
    name: 'digitalCollection_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/workDetail'));
      });
    },
    meta: {
      title: '数字藏品详情',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/works/album_detail"),
    name: 'digitalCollection_album_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/albumDetail'));
      });
    },
    meta: {
      title: '数字藏品详情',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/blind/list"),
    name: 'blind_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/blindList'));
      });
    },
    meta: {
      title: '盲盒管理',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/position/list"),
    name: 'position_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/positionList'));
      });
    },
    meta: {
      title: '持仓明细',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/subgift/list"),
    name: 'subgift_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/subgiftList'));
      });
    },
    meta: {
      title: '转赠记录',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/recovery/list"),
    name: 'recovery_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/recoveryList'));
      });
    },
    meta: {
      title: '回收记录',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/blockchain/list"),
    name: 'blockchain_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/blockchainList'));
      });
    },
    meta: {
      title: '上链明细',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/record/list"),
    name: 'record_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/recordList'));
      });
    },
    meta: {
      title: '回收记录',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/destroy/list"),
    name: 'destroy_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/destroyList'));
      });
    },
    meta: {
      title: '销毁记录',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/givento/list"),
    name: 'givento_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/giventoList'));
      });
    },
    meta: {
      title: '转赠记录',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/blind/detail"),
    name: 'blind_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/blindDetail'));
      });
    },
    meta: {
      title: '盲盒详情',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/transfer_rule"),
    name: 'digitalCollection_transferRule',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/transferRule'));
      });
    },
    meta: {
      title: '转赠规则',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/ssrSet"),
    name: 'digitalCollection_ssrSet',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/ssrSet'));
      });
    },
    meta: {
      title: '稀有度设置',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/blockchain"),
    name: 'digitalCollection_blockchain',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/blockchain'));
      });
    },
    meta: {
      title: '区块链',
      noCache: true
    }
  }]
};
var _default = exports.default = auctionRouter;