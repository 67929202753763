"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _demand = require("@/api/demand");
var _audit = _interopRequireDefault(require("./audit.vue"));
var _bidding = _interopRequireDefault(require("./bidding.vue"));
var _winBid = _interopRequireDefault(require("./winBid.vue"));
var _canceled = _interopRequireDefault(require("./canceled.vue"));
var _finished = _interopRequireDefault(require("./finished.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Audit: _audit.default,
    Bidding: _bidding.default,
    Winbid: _winBid.default,
    Canceled: _canceled.default,
    Finished: _finished.default
  },
  data: function data() {
    return {
      formState: {
        status: 0,
        pageNum: 1,
        pageSize: 10,
        modelType: "",
        userNameOrPhone: "",
        deliveryStatus: ""
      },
      status: 0,
      modelType: "",
      userNameOrPhone: "",
      tableData: [],
      total: 0,
      modelTypeList: [],
      statusList: [{
        name: "待审核",
        value: 0
      }, {
        name: "招标中",
        value: 1
      }, {
        name: "已中标",
        value: 2
      }, {
        name: "已取消",
        value: -1
      }, {
        name: "已完成",
        value: 3
      }],
      loading: false
    };
  },
  computed: {},
  created: function created() {
    var _this = this;
    this.getDemandList();
    _demand.demandApi.getFirstCategoriesApi().then(function (res) {
      _this.modelTypeList = res.data;
    }).catch(function (err) {
      console.log(err);
    });
  },
  mounted: function mounted() {},
  methods: {
    getDemandList: function getDemandList(num) {
      var _this2 = this;
      this.loading = true;
      num && (this.formState.pageNum = 1);
      _demand.demandApi._getDemandList(this.formState).then(function (res) {
        _this2.total = res.total;
        _this2.tableData = res.rows.map(function (item) {
          var files = JSON.parse(item.files);
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
            workDay: item.workDay + "天",
            budget: item.budget + "元",
            files: files,
            demandMemberHitInfo: item.demandMemberHitInfo ? item.demandMemberHitInfo : {}
          });
        });
        _this2.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this2.loading = false;
      });
    },
    changeLoading: function changeLoading(loading) {
      this.loading = loading;
    },
    onSearch: function onSearch() {
      if (this.userNameOrPhone == this.formState.userNameOrPhone) return;
      this.formState.pageNum = 1;
      this.formState.userNameOrPhone = this.userNameOrPhone;
      this.getDemandList();
    },
    changeStatus: function changeStatus(val) {
      if (val == 3) {
        this.formState.status = 3;
        /*  this.formState.deliveryStatus = 2; */
      } else {
        this.formState.status = val;
        /* this.formState.deliveryStatus = ""; */
      }
      this.formState.pageNum = 1;
      this.getDemandList();
    },
    changeModelType: function changeModelType(val) {
      this.formState.modelType = val;
      this.formState.pageNum = 1;
      this.getDemandList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.formState.pageNum = 1;
      this.formState.pageSize = val;
      this.getDemandList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.formState.pageNum = val;
      this.getDemandList();
    }
  }
};