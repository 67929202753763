var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "min-height": "85vh" } },
        [
          _c(
            "el-form",
            { attrs: { size: "small", inline: "", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "时间选择：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function () {
                          _vm.timeType = []
                          _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "timeType", $$v)
                        },
                        expression: "queryParams.timeType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "today" } }, [
                        _vm._v("今天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "yesterday" } }, [
                        _vm._v("昨天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "lately7" } }, [
                        _vm._v("最近7天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "lately30" } }, [
                        _vm._v("最近30天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "month" } }, [
                        _vm._v("本月"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "year" } }, [
                        _vm._v("本年"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      "value-format": "yyyy/MM/dd HH:mm:ss",
                      format: "yyyy/MM/dd HH:mm:ss",
                      size: "small",
                      type: "datetimerange",
                      placement: "bottom-end",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.onchangeTime },
                    model: {
                      value: _vm.timeType,
                      callback: function ($$v) {
                        _vm.timeType = $$v
                      },
                      expression: "timeType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "合同类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.hasPaper,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "hasPaper", $$v)
                        },
                        expression: "queryParams.hasPaper",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("电子合同"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("纸质合同"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开票状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("未申请邮寄"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("已申请邮寄"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("已邮寄"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公司主体：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.companyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "companyId", $$v)
                        },
                        expression: "queryParams.companyId",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.companyList, function (item, index) {
                        return _c(
                          "el-radio-button",
                          { key: index, attrs: { label: item.id } },
                          [_vm._v(_vm._s(item.nameAs))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "inputWidth",
                  attrs: { size: "small", placeholder: "客户名称/手机号" },
                  on: {
                    change: function ($event) {
                      return _vm.getTableList(1)
                    },
                  },
                  model: {
                    value: _vm.queryParams.memberNameOrPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "memberNameOrPhone", $$v)
                    },
                    expression: "queryParams.memberNameOrPhone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getTableList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "合同号",
                  prop: "contractNo",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "申请日期", prop: "createTime" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "合同周期", prop: "period" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "客户", prop: "postRecipient" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "邮寄状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              title: "邮寄信息",
                              width: "400",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", { staticClass: "diaItem" }, [
                              _c("span", [_vm._v("收件人：")]),
                              _vm._v(
                                " " + _vm._s(scope.row.postRecipient) + " "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "diaItem" }, [
                              _c("span", [_vm._v("联系电话：")]),
                              _vm._v(" " + _vm._s(scope.row.postPhone) + " "),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "diaItem" }, [
                              _c("span", [_vm._v("收件地址：")]),
                              _vm._v(" " + _vm._s(scope.row.postAddress) + " "),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "diaItem" }, [
                              _c("span", [_vm._v("快递公司：")]),
                              _vm._v(
                                " " + _vm._s(scope.row.deliveryName) + " "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "diaItem" }, [
                              _c("span", [_vm._v("快递单号：")]),
                              _vm._v(
                                " " + _vm._s(scope.row.trackingNumber) + " "
                              ),
                            ]),
                            _vm._v(" "),
                            scope.row.status == 2
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [_vm._v("已邮寄")]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        scope.row.status != 2
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.status == 0
                                    ? "未申请邮寄"
                                    : "已申请邮寄"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "m0 p0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row)
                              },
                            },
                          },
                          [_vm._v("查看合同")]
                        ),
                        _vm._v(" "),
                        row.status != 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0 p0",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onVerify(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.status == 2 ? "修改邮寄" : "邮寄"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("div", { staticClass: "boBox" }, [
                _vm._v("合同份数: " + _vm._s(_vm.statistical.count) + "份"),
              ]),
              _vm._v(" "),
              _c("el-pagination", {
                staticClass: "mt20",
                attrs: {
                  "page-sizes": [10, 20, 40, 80],
                  "page-size": _vm.queryParams.pageSize,
                  "current-page": _vm.queryParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "邮寄合同",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("收件人：")]),
            _vm._v(" " + _vm._s(_vm.formState.postRecipient) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("联系电话：")]),
            _vm._v(" " + _vm._s(_vm.formState.postPhone) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("收件地址：")]),
            _vm._v(" " + _vm._s(_vm.formState.postAddress) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("备注：")]),
            _vm._v(" " + _vm._s(_vm.formState.reason) + " "),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { size: "small", inline: "", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "inputWidth",
                      attrs: {
                        placeholder: "请选择快递公司",
                        size: "small",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formState.deliveryName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formState, "deliveryName", $$v)
                        },
                        expression: "formState.deliveryName",
                      },
                    },
                    _vm._l(_vm.deliveryList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递单号：" } },
                [
                  _c("el-input", {
                    staticClass: "inputWidth",
                    attrs: { size: "small", placeholder: "请输入快递单号" },
                    model: {
                      value: _vm.formState.trackingNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.formState, "trackingNumber", $$v)
                      },
                      expression: "formState.trackingNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }