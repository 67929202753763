"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _user = require("@/api/user");
var _accounts = require("@/api/accounts");
var _finance = require("@/api/finance");
var _index = require("@/utils/index");
var _auctionrecord = _interopRequireDefault(require("./auctionrecord"));
var _upauctionrecord = _interopRequireDefault(require("./upauctionrecord"));
var _nft = require("@/api/nft");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserDetails',
  components: {
    auctionrecord: _auctionrecord.default,
    upauctionrecord: _upauctionrecord.default,
    fileList: _fileList.default
  },
  props: {
    uid: {
      type: [Number, String],
      default: null
    },
    row: {
      type: Object,
      default: null
    },
    showdetail: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      integralDialog: false,
      showview: false,
      moren: require('@/assets/images/f.png'),
      loading: false,
      columns: [],
      Visible: false,
      list: [{
        val: '0',
        label: '消费记录'
      }, {
        val: '3',
        label: '持有优惠券'
      }],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 6,
        payType: ''
      },
      nftTableData: {
        data: [],
        total: 0
      },
      nftTableFrom: {
        pageNum: 1,
        pageSize: 6,
        nftType: ''
      },
      psInfo: null,
      type: '0',
      drawtype: 'detail',
      userdetail: {},
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      nftListLoading: false,
      userjson: {},
      showusercard: false,
      showImg: false,
      imgSrc: '',
      payTypeList: [],
      getTypeArr: [{
        id: 1,
        name: '购买'
      }, {
        id: 2,
        name: '空投'
      }, {
        id: 3,
        name: '免费领取'
      }, {
        id: 4,
        name: '寄售购买'
      }, {
        id: 5,
        name: '合成'
      }]
    };
  },
  filters: {
    formatProductype: function formatProductype(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '作品订单';
          break;
        case 2:
          res = '盲盒订单';
          break;
        case 3:
          res = '寄售订单';
          break;
        case 4:
          res = '退款';
          break;
        default:
          break;
      }
      return res;
    },
    formatType: function formatType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '盲盒';
          break;
        case 2:
          res = '作品';
          break;
        default:
          break;
      }
      return res;
    },
    formatGetType: function formatGetType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '购买';
          break;
        case 2:
          res = '空投';
          break;
        case 3:
          res = '免费领取';
          break;
        case 4:
          res = '寄售购买';
          break;
        case 5:
          res = '合成';
          break;
        case 6:
          res = '兑换码';
          break;
        case 7:
          res = '拉新互助领取';
          break;
        default:
          break;
      }
      return res;
    }
  },
  mounted: function mounted() {
    var _this = this;
    console.log(this.uid);
    if (this.uid) {
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _user.getUserDetail)({
        id: this.uid
      }).then(function (res) {
        _this.userdetail = res.data;
        _this.showview = true;
        loading.close();
      });
      this.tableFrom.userId = this.uid;
      this.nftTableFrom.memberId = this.uid;
      this.getNft();
      this.getUserWalletHistoryList();
      (0, _accounts.payTypeListApi)().then(function (res) {
        _this.payTypeList = res.data;
        _this.payTypeList.unshift({
          name: '全部',
          type: ''
        });
      }).catch(function (res) {});
    }
  },
  methods: {
    changedrawtype: function changedrawtype(type) {
      this.drawtype = type;
    },
    showvimage: function showvimage(item) {
      this.userjson = item;
      this.showusercard = true;
    },
    changeshowdetail: function changeshowdetail() {
      this.$emit('close');
    },
    changeType: function changeType(key) {
      this.type = key;
      this.tableFrom.pageNum = 1;
      this.getInfo(key);
    },
    getInfo: function getInfo(key) {
      var _this2 = this;
      this.loading = true;
      switch (key) {
        case '0':
          (0, _user.userOrderApi)(this.uid, this.tableFrom).then(function (res) {
            _this2.tableData.data = res.data.list;
            _this2.tableData.total = res.data.count;
            _this2.columns = [{
              title: '订单ID',
              key: 'order_id',
              minWidth: 250
            }, {
              title: '收货人',
              key: 'real_name',
              minWidth: 90
            }, {
              title: '商品数量',
              key: 'total_num',
              minWidth: 80
            }, {
              title: '商品总价',
              key: 'total_price',
              minWidth: 90
            }, {
              title: '实付金额',
              key: 'pay_price',
              minWidth: 90
            }, {
              title: '交易完成时间',
              key: 'pay_time',
              minWidth: 160
            }];
            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
          break;
        case '3':
          (0, _user.userCouponApi)(this.uid, this.tableFrom).then(function (res) {
            _this2.tableData.data = res.data.list;
            _this2.tableData.total = res.data.count;
            _this2.columns = [{
              title: '优惠券名称',
              key: 'coupon_title',
              minWidth: 120
            }, {
              title: '面值',
              key: 'coupon_price',
              minWidth: 120
            }, {
              title: '最低消费额',
              key: 'use_min_price',
              minWidth: 120
            }, {
              title: '兑换时间',
              key: 'use_time',
              minWidth: 120
            }];
            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
          break;
      }
    },
    getUserWalletHistoryList: function getUserWalletHistoryList() {
      var _this3 = this;
      (0, _finance.getEntryList)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getUserWalletHistoryList();
      // this.getInfo(this.type)
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getUserWalletHistoryList();
      // this.getInfo(this.type)
    },
    nftPageChange: function nftPageChange(page) {
      this.nftTableFrom.pageNum = page;
      this.getNft();
    },
    nftHandleSizeChange: function nftHandleSizeChange(val) {
      this.nftTableFrom.pageNum = 1;
      this.nftTableFrom.pageSize = val;
      this.getNft();
    },
    getNft: function getNft() {
      var _this4 = this;
      this.nftListLoading = true;
      (0, _nft.memberBoxListApi)(this.nftTableFrom).then(function (res) {
        _this4.nftTableData.data = res.rows;
        _this4.nftTableData.total = res.total;
        _this4.nftListLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.nftListLoading = false;
      });
    },
    getHeader: function getHeader() {
      this.psInfo = this.row;
    },
    showImgAction: function showImgAction(img) {
      this.imgSrc = img;
      this.showImg = true;
    },
    onchangeTimeWatter: function onchangeTimeWatter(val) {
      if (val) {
        this.tableFrom.timeType = "".concat(val[0], "-").concat(val[1]);
        this.getUserWalletHistoryList(1);
      } else {
        this.tableFrom.timeType = "";
        this.getUserWalletHistoryList(1);
      }
    },
    onchangeTime: function onchangeTime(val) {
      if (val) {
        this.nftTableFrom.startTime = val[0];
        this.nftTableFrom.endTime = val[1];
        this.getList(1);
      } else {
        this.nftTableFrom.startTime = '';
        this.nftTableFrom.endTime = '';
        this.getList(1);
      }
    },
    // 导出
    exportOrder: function exportOrder(key, dataKey) {
      var _this5 = this;
      var data = JSON.parse(JSON.stringify(this[dataKey]));
      var action;
      if (key === 1) {
        action = _finance.getExportDetail;
      } else if (key === 2) {
        action = _user.memberBoxExportApi;
      }
      action(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: teal'
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList(key) {
      this.fileVisible = true;
      if (key === 1) {
        this.$refs.exportList.exportFileList('60');
      } else if (key === 2) {
        this.$refs.exportList.exportFileList('75');
      }
    },
    copy: function copy(copyText) {
      var input = document.createElement("input"); // 直接构建input
      input.value = copyText; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("操作成功");
    }
  }
};