"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTicketChecker = addTicketChecker;
exports.allList = allList;
exports.checkTicket = checkTicket;
exports.delTicketChecker = delTicketChecker;
exports.exportData = exportData;
exports.getRecordList = getRecordList;
var _request = _interopRequireDefault(require("./request"));
//添加核销员
function addTicketChecker(id) {
  return _request.default.post("/member/sys/member/info/addTicketChecker/".concat(id), {});
}

//删除核销员
function delTicketChecker(id) {
  return _request.default.post("/member/sys/member/info/delTicketChecker/".concat(id), {});
}
//核销员列表
function allList(data) {
  return _request.default.get("/member/sys/member/info/allList", data);
}

//核销记录列表
function getRecordList(data) {
  return _request.default.get("/product/sys/product/ticket/getList", data);
}

//手动核销
function checkTicket(id) {
  return _request.default.post("/product/sys/product/ticket/checkTicket/".concat(id), {});
}

//导出
function exportData(data) {
  return _request.default.get("/product/sys/product/ticket/export", data);
}