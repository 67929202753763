"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import CreatAttr from './creatAttr'
var _default = exports.default = {
  name: 'ProductAttr',
  data: function data() {
    return {
      formDynamic: {
        template_name: '',
        template_value: []
      },
      tableFrom: {
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    add: function add() {
      var _this = this;
      this.$modalAttr(Object.assign({}, _this.formDynamic), function () {
        _this.getList();
        _this.formDynamic = {
          template_name: '',
          template_value: []
        };
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _product.templateListApi)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          if (typeof item.template_value == 'string') {
            item.template_value = JSON.parse(item.template_value);
          }
        });
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _product.attrDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success(message);
          _this3.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this3.$message.error(message);
        });
      });
    },
    onEdit: function onEdit(val) {
      var _this = this;
      this.$modalAttr(JSON.parse(JSON.stringify(val)), function () {
        _this.getList();
        this.formDynamic = {
          template_name: '',
          template_value: []
        };
      });
    }
  }
};