var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "logo"
        ? [
            _c(
              "control",
              { attrs: { title: "LOGO区域", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "是否展示" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.facade.show,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "show", $$v)
                              },
                              expression: "facade.show",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "LOGO宽高" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "70px" },
                            attrs: {
                              max: 750,
                              controls: false,
                              placeholder: "宽",
                            },
                            model: {
                              value: _vm.facade.width,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "width", $$v)
                              },
                              expression: "facade.width",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("×")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticStyle: { width: "70px" },
                            attrs: { controls: false, placeholder: "高" },
                            model: {
                              value: _vm.facade.height,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "height", $$v)
                              },
                              expression: "facade.height",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("LOGO图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "logo")
                              },
                            },
                          },
                          [
                            _vm.facade.logo
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("logo")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.logo
                              ? _c("img", {
                                  attrs: { src: _vm.facade.logo + "!120a" },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "inp"
        ? [
            _c(
              "control",
              { attrs: { title: "分享设置", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.bgColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "bgColor", $$v)
                              },
                              expression: "facade.bgColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "高度" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.height,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "height", $$v)
                              },
                              expression: "facade.height",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.inpRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "inpRadius", $$v)
                              },
                              expression: "facade.inpRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "阴影" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#999999" },
                            model: {
                              value: _vm.facade.inpShadow,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "inpShadow", $$v)
                              },
                              expression: "facade.inpShadow",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "阴影大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 50 },
                            model: {
                              value: _vm.facade.inpShadowSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "inpShadowSize", $$v)
                              },
                              expression: "facade.inpShadowSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "间距" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 200 },
                            model: {
                              value: _vm.facade.itemMargin,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "itemMargin", $$v)
                              },
                              expression: "facade.itemMargin",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "验证码大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 200 },
                            model: {
                              value: _vm.facade.sendFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "sendFontSize", $$v)
                              },
                              expression: "facade.sendFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "验证码颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#999999" },
                            model: {
                              value: _vm.facade.sendColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "sendColor", $$v)
                              },
                              expression: "facade.sendColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "选项卡文字" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#999999" },
                            model: {
                              value: _vm.facade.tabColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "tabColor", $$v)
                              },
                              expression: "facade.tabColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "选项卡选中" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#999999" },
                            model: {
                              value: _vm.facade.tabSelectColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "tabSelectColor", $$v)
                              },
                              expression: "facade.tabSelectColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "默认登录" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.loginType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "loginType", $$v)
                                },
                                expression: "facade.loginType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "vercode" } }, [
                                _vm._v("验证码"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "password" } }, [
                                _vm._v("密码"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "只保留默认" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.facade.isSaveDefault,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "isSaveDefault", $$v)
                              },
                              expression: "facade.isSaveDefault",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "btn"
        ? [
            _c(
              "control",
              { attrs: { title: "按钮设置", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("按钮背景图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "backgroundImg")
                              },
                            },
                          },
                          [
                            _vm.facade.backgroundImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("backgroundImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.backgroundImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.backgroundImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.bgColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "bgColor", $$v)
                              },
                              expression: "facade.bgColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "高度" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.height,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "height", $$v)
                              },
                              expression: "facade.height",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.radius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "radius", $$v)
                              },
                              expression: "facade.radius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "阴影" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#999999" },
                            model: {
                              value: _vm.facade.shadow,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "shadow", $$v)
                              },
                              expression: "facade.shadow",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "阴影大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 50 },
                            model: {
                              value: _vm.facade.shadowSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "shadowSize", $$v)
                              },
                              expression: "facade.shadowSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("常规"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "agreement"
        ? [
            _c(
              "control",
              { attrs: { title: "协议设置", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.agreement_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "agreement_color", $$v)
                              },
                              expression: "facade.agreement_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "协议颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.agreement_link_color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "agreement_link_color",
                                  $$v
                                )
                              },
                              expression: "facade.agreement_link_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "协议字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.agreement_font_size,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "agreement_font_size", $$v)
                              },
                              expression: "facade.agreement_font_size",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("协议选中前")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "agreement_before")
                              },
                            },
                          },
                          [
                            _vm.facade.agreement_before
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("agreement_before")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.agreement_before
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.agreement_before + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("协议选中后")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "agreement_after")
                              },
                            },
                          },
                          [
                            _vm.facade.agreement_after
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("agreement_after")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.agreement_after
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.agreement_after + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }