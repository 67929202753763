var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "min-height": "85vh" } },
        [
          _c(
            "el-form",
            { attrs: { size: "small", inline: "", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "开票主体：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.companyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "companyId", $$v)
                        },
                        expression: "queryParams.companyId",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.companyList, function (item, index) {
                        return _c(
                          "el-radio-button",
                          { key: index, attrs: { label: item.id } },
                          [_vm._v(_vm._s(item.nameAs))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票类型：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getTableList(1)
                            },
                          },
                          model: {
                            value: _vm.queryParams.invoiceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "invoiceType", $$v)
                            },
                            expression: "queryParams.invoiceType",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("专票"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("普票"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开票状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getTableList(1)
                            },
                          },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "status", $$v)
                            },
                            expression: "queryParams.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("未开"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("已开"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "时间选择：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function () {
                          _vm.timeType = []
                          _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "timeType", $$v)
                        },
                        expression: "queryParams.timeType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "today" } }, [
                        _vm._v("今天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "yesterday" } }, [
                        _vm._v("昨天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "lately7" } }, [
                        _vm._v("最近7天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "lately30" } }, [
                        _vm._v("最近30天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "month" } }, [
                        _vm._v("本月"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "year" } }, [
                        _vm._v("本年"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      "value-format": "yyyy/MM/dd HH:mm:ss",
                      format: "yyyy/MM/dd HH:mm:ss",
                      size: "small",
                      type: "datetimerange",
                      placement: "bottom-end",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.onchangeTime },
                    model: {
                      value: _vm.timeType,
                      callback: function ($$v) {
                        _vm.timeType = $$v
                      },
                      expression: "timeType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "inputWidth",
                  attrs: { size: "small", placeholder: "客户名称/手机号" },
                  on: {
                    change: function ($event) {
                      return _vm.getTableList(1)
                    },
                  },
                  model: {
                    value: _vm.queryParams.memberNameOrPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "memberNameOrPhone", $$v)
                    },
                    expression: "queryParams.memberNameOrPhone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getTableList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "18px 0" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary", size: "small" },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c("el-button", { attrs: { size: "small", type: "text" } }, [
                _vm._v("查看导出记录"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "申请日期",
                  prop: "createTime",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "开票方",
                  prop: "companyName",
                  "min-width": "170",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发票抬头", prop: "header" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "开票金额", prop: "price" },
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "发票性质" } }, [
                _vm._v(" 电子 "),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发票状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { class: { active: scope.row.status == 1 } },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status == 0
                                  ? "未开"
                                  : scope.row.status == 1
                                  ? "已开票"
                                  : ""
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "m0 p0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        row.status == 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0 p0",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDownload(row)
                                  },
                                },
                              },
                              [_vm._v("下载")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status === 0 && row.isManual == 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0 p0",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onVerify(row)
                                  },
                                },
                              },
                              [_vm._v("确认开票")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("div", { staticClass: "boBox" }, [
                _vm._v("票额合计: " + _vm._s(_vm.statistical.count) + "元"),
              ]),
              _vm._v(" "),
              _c("el-pagination", {
                staticClass: "mt20",
                attrs: {
                  "page-sizes": [10, 20, 40, 80],
                  "page-size": _vm.queryParams.pageSize,
                  "current-page": _vm.queryParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认开票",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("开票方：")]),
            _vm._v(" " + _vm._s(_vm.formState.companyName) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("发票抬头：")]),
            _vm._v(" " + _vm._s(_vm.formState.header) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("发票金额：")]),
            _vm._v(" " + _vm._s(_vm.formState.price) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("发票性质：")]),
            _vm._v(" 电子 "),
          ]),
          _vm._v(" "),
          _vm.formState.isManual == 1
            ? _c("div", { staticClass: "flex flex-wrap" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "img-item flex align-items-c flex-direction mr20",
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "upload-demo",
                        attrs: { action: "", "http-request": _vm.modalPicTap },
                      },
                      [
                        _c("el-button", { attrs: { type: "primary" } }, [
                          _vm._v("上传pdf"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "详情",
            visible: _vm.visible,
            top: "5vh",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("开票方：")]),
            _vm._v(" " + _vm._s(_vm.form.companyName) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("发票抬头：")]),
            _vm._v(" " + _vm._s(_vm.form.header) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("抬头类型：")]),
            _vm._v(
              " " + _vm._s(_vm.form.headerType == 1 ? "企业" : "个人") + " "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("默认发票类型：")]),
            _vm._v(
              " " + _vm._s(_vm.form.invoiceType == 1 ? "普票" : "专票") + " "
            ),
          ]),
          _vm._v(" "),
          _vm.form.status != 0
            ? _c("div", { staticClass: "diaTit" }, [
                _c("span", [_vm._v("发票号：")]),
                _vm._v(" " + _vm._s(_vm.form.id) + " "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("发票金额：")]),
            _vm._v(" " + _vm._s(_vm.form.price) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("发票性质：")]),
            _vm._v(" 电子 "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("发票状态：")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.form.status == 0
                    ? "未开"
                    : _vm.form.status == 1
                    ? "已开票"
                    : ""
                ) +
                " "
            ),
          ]),
          _vm._v(" "),
          _vm.form.headerType == 1
            ? _c("div", [
                _c("div", { staticClass: "diaTit" }, [
                  _c("span", [_vm._v("统一社会信用代码：")]),
                  _vm._v(" " + _vm._s(_vm.form.taxNo) + " "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "diaTit" }, [
                  _c("span", [_vm._v("开户银行：")]),
                  _vm._v(" " + _vm._s(_vm.form.bank) + " "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "diaTit" }, [
                  _c("span", [_vm._v("基本开户账号：")]),
                  _vm._v(" " + _vm._s(_vm.form.bankNumber) + " "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "diaTit" }, [
                  _c("span", [_vm._v("注册场所地址：")]),
                  _vm._v(" " + _vm._s(_vm.form.address) + " "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "diaTit" }, [
                  _c("span", [_vm._v("注册固定电话：")]),
                  _vm._v(" " + _vm._s(_vm.form.telephone) + " "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("申请日期：")]),
            _vm._v(" " + _vm._s(_vm.form.createTime) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("收件人姓名：")]),
            _vm._v(" " + _vm._s(_vm.form.userName) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("收件人电话：")]),
            _vm._v(" " + _vm._s(_vm.form.userPhone) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("收件人地址：")]),
            _vm._v(" " + _vm._s(_vm.form.userAddress) + " "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "diaTit" }, [
            _c("span", [_vm._v("邮箱地址：")]),
            _vm._v(" " + _vm._s(_vm.form.userEmail) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }