"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _default = exports.default = {
  namespaced: true,
  state: {
    groupInfo: {
      id: -1,
      name: '全部'
    },
    groupList: [],
    photoList: [],
    pageInfo: {
      size: 15,
      total: 0,
      current: 1
    }
  },
  mutations: {
    /**
     * 设置分类
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setGroupInfo: function setGroupInfo(state, data) {
      state.groupInfo = data;
    },
    /**
     * 设置分类
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setGroupList: function setGroupList(state, data) {
      state.groupList = data;
    },
    /**
     * 设置分页
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setPageInfo: function setPageInfo(state, data) {
      state.pageInfo = data;
    },
    /**
     * 设置分页
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setPhotoList: function setPhotoList(state, data) {
      state.photoList = data;
    }
  },
  actions: {
    upadteGroupInfo: function upadteGroupInfo(_ref, value) {
      var commit = _ref.commit;
      commit('setGroupInfo', value);
    },
    /**
     * 获取分类数据
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    LoadGroupList: function LoadGroupList(_ref2) {
      var commit = _ref2.commit;
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('get', {
          include: 'gallery',
          parent_id: id,
          type: 2
        }).then(function (data) {
          commit('setGroupList', data);
          resolve(data);
        }).catch(function (error) {
          VM.$message({
            type: 'error',
            message: '网络请求错误，请刷新'
          });
          reject(error);
        });
      });
    },
    /**
     * 创建分类数据
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    InterGroupItem: function InterGroupItem(_ref3, _ref4) {
      var state = _ref3.state,
        commit = _ref3.commit,
        dispatch = _ref3.dispatch;
      var name = _ref4.name,
        id = _ref4.id;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('post', {
          include: 'gallery'
        }, {
          name: name,
          type: 2,
          parent_id: id || 0,
          sort: 5
        }).then(function (data) {
          VM.$message({
            type: 'success',
            message: '添加成功'
          });
          resolve(data);
        }).catch(function (error) {
          if (error.data.message) {
            VM.$message({
              type: 'error',
              message: error.data.message
            });
          } else {
            VM.$message({
              type: 'error',
              message: '创建失败'
            });
          }
          reject(error);
        });
      });
    },
    /**
     * 删除分组
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    DeleteGroupItem: function DeleteGroupItem(_ref5, _ref6) {
      var state = _ref5.state,
        commit = _ref5.commit,
        dispatch = _ref5.dispatch;
      var id = _ref6.id,
        type = _ref6.type;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('delete', id, {
          include: 'gallery'
        }).then(function (data) {
          VM.$message({
            type: 'success',
            message: '删除成功'
          });
          resolve(data);
        }).catch(function (error) {
          VM.$message({
            type: 'error',
            message: '此分组下有文件夹，不支持删除'
          });
          reject(error);
        });
      });
    },
    /**
     * 编辑分类数据
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    UpdateGroupItem: function UpdateGroupItem(_ref7, value) {
      var state = _ref7.state,
        commit = _ref7.commit,
        dispatch = _ref7.dispatch;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('put', {
          include: 'gallery',
          id: parseInt(value.id)
        }, {
          name: value.name
        }).then(function (data) {
          VM.$message({
            type: 'success',
            message: '命名成功'
          });
          resolve(data);
        }).catch(function (error) {
          VM.$message({
            type: 'error',
            message: '修改失败'
          });
          reject(error);
        });
      });
    },
    /**
     * 获取照片信息
     * @param  {[type]} options.state  [description]
     * @param  {[type]} options.commit [description]
     * @return {[type]}                [description]
     */
    LoadPhotoList: function LoadPhotoList(_ref8, _ref9) {
      var state = _ref8.state,
        commit = _ref8.commit;
      var id = _ref9.id,
        current = _ref9.current,
        pageSize = _ref9.pageSize;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      var $pageSize = pageSize || 24;
      var $id = id || 0;
      return new Promise(function (resolve, reject) {
        $heshop.gallery('get', {
          group_id: parseInt($id),
          type: 2
        }).page(current, $pageSize).then(function (data) {
          var size = parseInt(data.headers['x-pagination-per-page']);
          var total = parseInt(data.headers['x-pagination-total-count']);
          var current = parseInt(data.headers['x-pagination-current-page']);
          // setTimeout(() => {
          commit('setPageInfo', {
            size: size,
            total: total,
            current: current
          });
          commit('setPhotoList', data.data);
          // }, 100);
          resolve(data.data);
        }).catch(function (error) {
          VM.$message({
            type: 'error',
            message: '网络请求错误，请刷新'
          });
          reject(error);
        });
      });
    },
    /**
     * 删除图片
     * @param  {[type]} options.state  [description]
     * @param  {[type]} options.commit [description]
     * @param  {[type]} data           [description]
     * @return {[type]}                [description]
     */
    DeletePhotoList: function DeletePhotoList(_ref10, value) {
      var state = _ref10.state,
        commit = _ref10.commit;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.gallery('delete', value).then(function (data) {
          VM.$message({
            type: 'success',
            message: '删除成功'
          });
          resolve(data);
        }).catch(function (error) {
          VM.$message({
            type: 'error',
            message: '删除失败'
          });
          reject(error);
        });
      });
    },
    /**
     * 获取所欲分类
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    MovePhotoList: function MovePhotoList(_ref11, _ref12) {
      var state = _ref11.state,
        commit = _ref11.commit,
        dispatch = _ref11.dispatch;
      var list = _ref12.list,
        group_id = _ref12.group_id;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.gallery('put', {
          behavior: 'setgroup',
          id: list
        }, {
          group_id: group_id
        }).then(function (data) {
          VM.$message({
            type: 'success',
            message: '移动成功'
          });
          resolve(data);
        }).catch(function (error) {
          VM.$message({
            type: 'error',
            message: '移动失败'
          });
          reject(error);
        });
      });
    },
    /**
     * 编辑照片信息
     * @param  {[type]} options.state  [description]
     * @param  {[type]} options.commit [description]
     * @return {[type]}                [description]
     */
    UpdatePhotoItem: function UpdatePhotoItem(_ref13, value) {
      var state = _ref13.state,
        commit = _ref13.commit;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.gallery('put', parseInt(value.id), {
          title: value.name
        }).then(function (data) {
          VM.$message({
            type: 'success',
            message: '命名成功'
          });
          resolve(data);
        }).catch(function (error) {
          VM.$message({
            type: 'error',
            message: '命名失败，文件夹以存在'
          });
          reject(error);
        });
      });
    }
  }
};