"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _sdk = require("@/api/sdk");
var _index = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formData: {
        type: '',
        price: '',
        spaceCount: '',
        onlineCount: '',
        versionType: '',
        defaultClientCount: ''
      },
      rules: {
        type: [{
          required: true,
          message: '请输入版本名称',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入购买金额',
          trigger: 'blur'
        }],
        modeType: [{
          required: true,
          message: '请选择产品模式',
          trigger: 'blur'
        }]
      },
      modeType: [{
        label: '平台',
        value: 1
      }, {
        label: 'SaaS',
        value: 2
      }],
      productTypeList: [{
        value: 1,
        label: '入门'
      }, {
        value: 2,
        label: '进阶'
      }, {
        value: 3,
        label: '精英'
      }, {
        value: 4,
        label: '至尊'
      }]
    };
  },
  created: function created() {
    this.$route.params.id && this.getInfo();
  },
  methods: {
    numInput: function numInput(name, piont, min, max) {
      this.formData[name] = (0, _index.numInput)(this.formData[name], piont, min, max);
    },
    /**
     * 获取产品信息
     */
    getInfo: function getInfo() {
      var _this = this;
      (0, _sdk.getSdkConfigInfoApi)(this.$route.params.id).then(function (res) {
        Object.assign(_this.formData, res.data);
      }).catch(function (err) {
        _this.$message.error(err.message);
        console.log(err, 'err');
      });
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.setSdkConfig(_this2.formData);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    setSdkConfig: function setSdkConfig(form) {
      var _this3 = this;
      (0, _sdk.setSdkConfigApi)(form).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success(res.msg);
        } else {
          _this3.$message.error(res.message);
        }
      }).catch(function (err) {
        _this3.$message.error(err.message);
      });
    }
  }
};