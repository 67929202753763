"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configApi = configApi;
exports.labelAddApi = labelAddApi;
exports.labelDeleteApi = labelDeleteApi;
exports.labelEditApi = labelEditApi;
exports.labelLstApi = labelLstApi;
exports.modifyStoreApi = modifyStoreApi;
exports.noticeConfigApi = noticeConfigApi;
exports.noticeConfigUpdateApi = noticeConfigUpdateApi;
exports.renewSave = renewSave;
exports.startpageDetail = startpageDetail;
exports.startpageEditInfo = startpageEditInfo;
exports.uploadApp = uploadApp;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 验证码
 */
function configApi(key) {
  return _request.default.get("config/".concat(key));
}
function modifyStoreApi() {
  return _request.default.get("update/form");
}

/**
 * @description 标签 -- 列表
 */
function labelLstApi(data) {
  return _request.default.get('product/sys/tag/list', data);
}
/**
* @description 标签 -- 添加
*/
function labelAddApi(data) {
  return _request.default.post('/product/sys/tag/save', data);
}
/**
* @description 标签 -- 编辑表单
*/
function labelEditApi(data) {
  return _request.default.put('product/sys/tag/update', data);
}
/**
* @description 标签 -- 删除
*/
function labelDeleteApi(id) {
  return _request.default.delete("product/sys/tag/delete/".concat(id));
}

/**
 * @description 获取知设置列表
 */
function noticeConfigApi() {
  return _request.default.get('system/sys/notice/config/list');
}

/**
 * @description 修改通知设置
 */
function noticeConfigUpdateApi(data) {
  return _request.default.put('system/sys/notice/config/update', data);
}
/**
 * @description /system/sys/startpage/editInfo 保存接口
 */
function startpageEditInfo(data) {
  return _request.default.post('system/sys/startpage/editInfo', data);
}

/**
 * @description /system/sys/startpage/getInfo 查询启动广告详情
 */
function startpageDetail(data) {
  return _request.default.get('system/sys/startpage/getInfo', data);
}

/**
 * 上传APP包 /resource/oss/uploadApp
 */
function uploadApp(data) {
  return _request.default.post("resource/oss/uploadApp", data);
}

/**
 * app更新  /system/sys/renew/save
 */
function renewSave(data) {
  return _request.default.post("system/sys/renew/save", data);
}