"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _nft = require("@/api/nft");
var _index5 = require("@/utils/index");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "blockchainList",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      timeVal: [],
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        status: ""
      },
      chkName: "",
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      useIds: [],
      allCheck: false,
      saleStatusList: [{
        id: 0,
        name: "正常"
      }, {
        id: 1,
        name: "寄售中"
      }],
      visibleRecord: false,
      queryRecord: {
        remarks: ""
      },
      visibleDestroy: false,
      queryDestroy: {
        remarks: ""
      },
      // 重新投放 - data
      putInData: {
        uid: "",
        nft_data_id: "",
        show: false,
        code: "",
        title: "",
        btnLoading: false
      },
      userList: [],
      selectList: []
    };
  },
  filters: {
    formatStatus: function formatStatus(val) {
      var res = "";
      switch (val) {
        case 2:
          res = "回收中";
          break;
        case 3:
          res = "回收完成";
          break;
        case 1:
          res = "已转出";
          break;
        default:
          break;
      }
      return res;
    }
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    changeTab: function changeTab(data) {
      if (data !== this.$route.path) {
        this.$router.push({
          path: data
        });
      }
    },
    exportOrder: function exportOrder() {
      var _this = this;
      (0, _nft.productNftRecoverExportApi)(this.queryParams).then(function (res) {
        _this.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(32);
    },
    onHandle: function onHandle(name) {
      console.log(name, 309);
      this.chkName = this.chkName === name ? "" : name;
      this.changeType(!(this.chkName === ""));
    },
    changeType: function changeType(v) {
      // this.checkedIds = [];
      // this.useIds = [];
      if (v) {
        if (!this.chkName) {
          this.chkName = "dan";
        }
      } else {
        this.chkName = "";
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.pageNum);
      if (this.chkName === "dan") {
        this.checkedPage.push(this.queryParams.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this2 = this;
      var ids = [];
      var idsuser = [];
      // this.checkedIds = [];
      // this.useIds = [];
      // 过滤掉 data数据中不可点击的
      this.tableData.data.forEach(function (v) {
        // 寄售中 || 上链中 不可批量操作
        if (v.status !== 1) {
          ids.push(v.logRecordsId);
          idsuser.push(v.id);
        }
      });
      if (this.chkName === "duo") {
        // this.checkedIds = [];
        // this.useIds = [];
        this.allCheck = true;
      } else if (this.chkName === "dan") {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this2.checkedIds.indexOf(id);
          if (index === -1) {
            _this2.checkedIds.push(id);
          }
        });
        idsuser.forEach(function (id) {
          var index1 = _this2.useIds.indexOf(id);
          if (index1 === -1) {
            _this2.useIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this2.checkedIds.indexOf(id);
          if (index > -1) {
            _this2.checkedIds.splice(index, 1);
          }
        });
        idsuser.forEach(function (id) {
          var index1 = _this2.useIds.indexOf(id);
          if (index1 === -1) {
            _this2.useIds.splice(index1, 1);
          }
        });
      }
    },
    changeOne: function changeOne(v, user) {
      // this.checkedIds = [];
      // this.useIds = [];
      if (v) {
        if (this.chkName === "duo") {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var index1 = this.useIds.indexOf(user.id);
          if (index1 === -1) this.useIds.push(user.id);
          var _index = this.checkedIds.indexOf(user.logRecordsId);
          if (_index === -1) this.checkedIds.push(user.logRecordsId);
        }
      } else {
        if (this.chkName === "duo") {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.useIds.indexOf(user.id);
          if (_index3 === -1) this.useIds.splice(_index3, 1);
          var _index4 = this.checkedIds.indexOf(user.logRecordsId);
          if (_index4 > -1) this.checkedIds.splice(_index4, 1);
        }
      }
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index5.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.setTypeAction();
      (0, _nft.productNftRecoverApi)(this.queryParams).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.tableData.statistical = res.statistical;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    onchangeTime: function onchangeTime(val) {
      if (val) {
        this.queryParams.startTime = val[0];
        this.queryParams.endTime = val[1];
        this.getList(1);
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
        this.getList(1);
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    saleStatusChange: function saleStatusChange() {
      this.getList(1);
    },
    batchRecover: function batchRecover() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择作品");
      } else {
        this.visibleRecord = true;
      }
    },
    productRecoverMethod: function productRecoverMethod(remarks) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u56DE\u6536\u6240\u9009\u4E0A\u94FE?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _nft.productRecoverApi)({
          memberNftIds: _this4.checkedIds,
          remarks: remarks
        }).then(function () {
          _this4.$message.success("操作成功");
          _this4.useIds = [];
          _this4.noChecked = [];
          _this4.checkedIds = [];
          _this4.visibleRecord = false;
          _this4.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    batchDestroy: function batchDestroy() {
      // if (this.checkedIds.length === 0) {
      //   return this.$message.warning("请先选择上链");
      // } else {
      //   this.visibleDestroy = true;
      // }
      this.visibleDestroy = true;
    },
    // 销毁
    productDestroyMethod: function productDestroyMethod(remarks, pwd) {
      var _this5 = this;
      if (!pwd) {
        this.$message({
          type: "warning",
          message: "请输入密码"
        });
        return;
      }
      this.useIds = [];
      this.$confirm("\u786E\u8BA4\u9500\u6BC1\u6240\u9009\u4E0A\u94FE?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _nft.recoverToDestroy)({
          memberNftIds: _this5.selectList,
          remarks: remarks,
          pwd: pwd
        }).then(function () {
          _this5.$message.success("操作成功");
          _this5.checkedIds = [];
          _this5.noChecked = [];
          _this5.visibleDestroy = false;
          _this5.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    putInDataConfirmAction: function putInDataConfirmAction() {
      var _this6 = this;
      this.putInData.btnLoading = true;
      this.$confirm("确认转出给此用户？", "提示", {
        confirmButtonText: "确认",
        type: "warning"
      }).then(function () {
        (0, _nft.recoveryNftTransfersApi)({
          memberNftIds: _this6.selectList,
          remarks: _this6.putInData.remarks,
          userId: _this6.putInData.uid
        }).then(function () {
          _this6.$message.success("操作成功");
          _this6.clearPutInDataAction();
          _this6.useIds = [];
          _this6.checkedIds = [];
          _this6.noChecked = [];
          _this6.getList();
          _this6.putInData.btnLoading = false;
        });
      }).catch(function (_ref) {
        var message = _ref.message;
        _this6.putInData.btnLoading = false;
      });
    },
    clearPutInDataAction: function clearPutInDataAction() {
      this.putInData = {
        uid: "",
        show: false,
        remarks: "",
        btnLoading: false
      };
    },
    putInAction: function putInAction(item) {
      this.putInData = {
        uid: "",
        show: true,
        remarks: "",
        btnLoading: false
      };
    },
    searchUserAction: function searchUserAction(data) {
      var _this7 = this;
      var params = {
        nameOrPhone: data,
        pageNum: 1,
        pageSize: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this7.userList = res.rows;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectList = val.map(function (i) {
        return i.id;
      });
    },
    checkSelectable: function checkSelectable(row, index) {
      return row.status === 3;
    }
  }
};