"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'addRole',
  data: function data() {
    return {
      roleForm: {
        nickName: '',
        roleIds: [],
        status: ''
      },
      rules: {
        nickName: [{
          required: true,
          message: '请输入管理员名称',
          trigger: 'blur'
        }],
        roleIds: [{
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }],
        userName: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        phonenumber: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        passwordAgain: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }]
      },
      roleArr: [],
      props: {
        label: 'label',
        children: 'children'
      }
    };
  },
  created: function created() {
    this.getroleListApiMethod();
    if (this.$route.query.id) {
      this.adminDetailApiMethod(this.$route.query.id);
    }
  },
  methods: {
    backAction: function backAction() {
      this.$router.back();
    },
    getroleListApiMethod: function getroleListApiMethod() {
      var _this = this;
      var arr = [];
      (0, _setting.getroleListApi)().then(function (res) {
        res.data.forEach(function (element) {
          // 选择开启的角色
          if (element.status == 0) {
            arr.push(element);
          }
        });
        _this.roleArr = arr;
      });
    },
    adminDetailApiMethod: function adminDetailApiMethod(id) {
      var _this2 = this;
      (0, _setting.adminDetailApi)(id).then(function (res) {
        _this2.roleForm = res.data.user;
        _this2.roleForm.roleIds = res.data.roleIds;
      });
    },
    // this.$refs.tree.getCheckedKeys()
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this3.roleForm));
          if (_this3.$route.query.id) {
            data.userId = _this3.$route.query.id;
          } else {
            if (data.password != data.passwordAgain) {
              _this3.$message.error('密码和确认密码不一致');
              return false;
            }
          }
          data.status = Number(data.status);
          var action = _setting.adminCreateApi;
          if (_this3.$route.query.id) {
            action = _setting.adminUpdateApi;
          }
          var loading = _this3.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          action(data).then(function () {
            loading.close();
            _this3.$message.success('操作成功');
            _this3.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          return false;
        }
      });
    }
  }
};