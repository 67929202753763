var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", "label-width": "100px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v(
                                  "全部 " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.all
                                        ? _vm.orderChartType.all
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v(
                                  "待审核 " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.audit
                                        ? _vm.orderChartType.audit
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [
                                  _vm._v(
                                    "审核未通过 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.refuse
                                          ? _vm.orderChartType.refuse
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v(
                                  "审核通过 " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.agree
                                        ? _vm.orderChartType.agree
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v(
                                  "待收货 " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.backgood
                                        ? _vm.orderChartType.backgood
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "3" } }, [
                                _vm._v(
                                  "已完成 " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.end
                                        ? _vm.orderChartType.end
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(_vm.tableFrom.date)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "date", $$v)
                                },
                                expression: "tableFrom.date",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd",
                              format: "yyyy/MM/dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "退款单号：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入订单号",
                                size: "small",
                              },
                              model: {
                                value: _vm.tableFrom.refund_order_sn,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tableFrom,
                                    "refund_order_sn",
                                    $$v
                                  )
                                },
                                expression: "tableFrom.refund_order_sn",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "订单单号：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入订单号",
                                size: "small",
                              },
                              model: {
                                value: _vm.tableFrom.order_sn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "order_sn", $$v)
                                },
                                expression: "tableFrom.order_sn",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand demo-table-expands",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "订单号：" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.row.order.order_sn)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "退款商品总价：" } },
                              [
                                props.row.order.activity === 2
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getPresellTotal(
                                            props.row.refundProduct
                                          )
                                        )
                                      ),
                                    ])
                                  : props.row.order.activity === 3
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAssistTotal(
                                            props.row.refundProduct
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getTotal(props.row.refundProduct)
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "退款商品总数：" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.row.refund_num)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "申请退款时间：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.create_time
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "用户备注：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商家备注：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mer_mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            props.row.status === 2
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "快递公司：" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            props.row.delivery_type
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            props.row.status === 2
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "快递单号：" } },
                                  [
                                    _c("span", { staticClass: "mr5" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            props.row.delivery_id
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openLogistics(props.row)
                                          },
                                        },
                                      },
                                      [_vm._v("物流查询")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "退款单号", "min-width": "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { display: "block" },
                          domProps: {
                            textContent: _vm._s(scope.row.refund_order_sn),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户信息", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.user.real_name ||
                                      scope.row.user.nickname
                                  )
                                ),
                                scope.row.user.real_name
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(scope.row.user.nickname) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "refund_price",
                  label: "退款金额",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nickname",
                  label: "商品信息",
                  "min-width": "330",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.refundProduct, function (val, i) {
                        return _c("div", { key: "order" + scope.$index + i }, [
                          val.product
                            ? _c(
                                "div",
                                { staticClass: "tabBox acea-row row-middle" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "demo-image__preview" },
                                    [
                                      _c("el-image", {
                                        attrs: {
                                          src: val.product.cart_info.product
                                            .image,
                                          "preview-src-list": [
                                            val.product.cart_info.product.image,
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "tabBox_tit" }, [
                                    _vm._v(
                                      _vm._s(
                                        val.product.cart_info.product
                                          .store_name + " | "
                                      ) +
                                        _vm._s(
                                          val.product.cart_info.productAttr.sku
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  scope.row.order.activity_type === 2 &&
                                  val.product.cart_info.productPresellAttr
                                    ? _c(
                                        "span",
                                        { staticClass: "tabBox_pice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "￥" +
                                                val.product.cart_info
                                                  .productPresellAttr
                                                  .presell_price +
                                                " x " +
                                                val.refund_num
                                            )
                                          ),
                                        ]
                                      )
                                    : scope.row.order.activity_type === 3 &&
                                      val.product.cart_info.productAssistAttr
                                        .assist_price
                                    ? _c(
                                        "span",
                                        { staticClass: "tabBox_pice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "￥" +
                                                val.product.cart_info
                                                  .productAssistAttr
                                                  .assist_price +
                                                " x " +
                                                val.refund_num
                                            )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "tabBox_pice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "￥" +
                                                val.product.cart_info
                                                  .productAttr.price +
                                                " x " +
                                                val.refund_num
                                            )
                                          ),
                                        ]
                                      ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "serviceScore",
                  label: "订单状态",
                  "min-width": "250",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("orderRefundFilter")(scope.row.status)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v(
                            "退款原因：" + _vm._s(scope.row.refund_message)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v(
                            "状态变更时间：" + _vm._s(scope.row.status_time)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "180",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOrderStatus(
                                      scope.row.refund_order_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("售后")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmReceipt(
                                      scope.row.refund_order_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("确认收货")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "refund_order_id",
                  align: "center",
                  label: "退款单ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_message",
                  label: "操作记录",
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_time",
                  label: "操作时间",
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { "order-datalist": _vm.orderDatalist },
      }),
      _vm._v(" "),
      _vm.dialogLogistics
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "物流查询",
                visible: _vm.dialogLogistics,
                width: "350px",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogLogistics = $event
                },
              },
            },
            [
              _vm.orderDetails
                ? _c("logistics-from", {
                    attrs: {
                      "order-datalist": _vm.orderDetails,
                      result: _vm.result,
                      "logistics-name": _vm.logisticsName,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }