"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _nft = require("@/api/nft");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _auth = require("@/utils/auth");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      SettingMer: _settingMer.default,
      types: 'album',
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        status: '0'
      },
      formDataFile: null,
      changeCate: {},
      fileCheck: [],
      checkFlag: false,
      formData: {
        seriesId: ''
      },
      visible: false,
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      innerVisible: false,
      showImg: false,
      showImgUrl: '',
      checkoutworkArr: []
    };
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    editSortAction: function editSortAction(val, index) {
      var _this = this;
      // 修改排序
      var item = this.tableData.data[index];
      var params = {
        id: item.id,
        name: item.name,
        sort: val
      };
      (0, _nft.nftCollectionAlbumUpdateApi)(params).then(function () {
        _this.$message.success('操作成功');
        _this.getList();
      }).catch(function () {});
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === 'works') {
        this.$router.push('/merchant/digitalCollection/works/list');
        return false;
      } else if (tab.name === 'album') {
        this.$router.push('/merchant/digitalCollection/works/album');
        return false;
      }
    },
    batchUploadAuction: function batchUploadAuction(id) {
      // 批量上传
      this.visible = true;
      this.formData.seriesId = id;
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.setTypeAction();
      (0, _nft.nftCollectionAlbumListApi)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.tableData.statistical = res.statistical;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    addAction: function addAction() {
      this.$router.push('./album_detail');
    },
    editAction: function editAction(id) {
      this.$router.push('./album_detail?id=' + id);
    },
    linkAction: function linkAction(id) {
      this.copyAction("".concat(_settingMer.default.appUrl, "/pages/nftCollection/album?id=").concat(id));
    },
    QRAction: function QRAction(id) {
      var _this3 = this;
      this.showImg = true;
      (0, _system.createQRCodeApi)({
        url: "/pages/nftCollection/album?id=".concat(id),
        width: 400,
        height: 400
      }).then(function (res) {
        _this3.showImgUrl = res.data.image;
      });
    },
    uploadIconAction: function uploadIconAction(key) {
      var _this4 = this;
      this.$modalUpload(function (img) {
        _this4.facade[key] = img[0];
      }, 1);
    },
    delAction: function delAction(id) {
      var _this5 = this;
      this.$confirm('确认删除此系列？', '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      }).then(function () {
        (0, _nft.nftCollectionAlbumDelApi)(id).then(function () {
          _this5.$message.success('操作成功');
          _this5.getList();
        });
      }).catch(function (_ref) {
        var message = _ref.message;
      });
    },
    copyAction: function copyAction(val) {
      var input = document.createElement('input'); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    downloadAction: function downloadAction() {
      window.open('https://java-test-cdn.yunzongbu.cn/1665806606492387660.xlsx');
    },
    uploadRequest: function uploadRequest(file) {
      var formDataFile = new FormData();
      formDataFile.append('file', file.file);
      this.formDataFile = formDataFile;
      this.nftCollectionAlbumUpdataCheckApiMethod(formDataFile, true);
    },
    nftCollectionAlbumUpdataCheckApiMethod: function nftCollectionAlbumUpdataCheckApiMethod(formDataFile, flag) {
      var _this6 = this;
      this.checkFlag = false;
      if (flag) {
        formDataFile.append('seriesId', this.formData.seriesId);
        formDataFile.append('categoryId', this.changeCate.id);
      }
      (0, _nft.nftCollectionAlbumUpdataCheckApi)(formDataFile, {
        headers: {
          'Content-Type': 'form-data'
        }
      }).then(function (res) {
        _this6.checkFlag = true;
        // this.fileCheck = res.data
        _this6.fileCheck = [];
        _this6.checkoutworkArr = res.data;
      }).catch(function (msg) {
        console.log(msg);
        if (msg.data && msg.data.data && msg.data.data.errors) {
          _this6.checkFlag = true;
          _this6.fileCheck = msg.data.data.errors;
        }
        // loading.close();
      });
    },
    checkAgain: function checkAgain() {
      this.nftCollectionAlbumUpdataCheckApiMethod(this.formDataFile, false);
    },
    checkLook: function checkLook() {
      this.innerVisible = true;
    },
    openChangeCategory: function openChangeCategory() {
      var _this7 = this;
      this.$modalUpload(function (category) {
        _this7.changeCate = category;
      }, 1, '1', '1');
    },
    confirmAction: function confirmAction() {
      var _this8 = this;
      // this.formDataFile.append('categoryId', this.changeCate.id)
      var json = {
        seriesId: this.formData.seriesId,
        categoryId: this.changeCate.id,
        productNfts: this.checkoutworkArr
      };
      (0, _nft.nftCollectionAlbumUpdataApi)(json, false).then(function (res) {
        _this8.$message.success('操作成功');
        _this8.visible = false;
        _this8.getList(1);
      });
      // nftCollectionAlbumUpdataApi(this.formDataFile, false)
      //   .then(() => {
      //     this.$message.success('操作成功');
      //     this.visible = false
      //     this.getList(1);
      //   })
      //   .catch(msg => {
      //     // loading.close();
      //   });
    }
  }
};