var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTypeAction },
              model: {
                value: _vm.types,
                callback: function ($$v) {
                  _vm.types = $$v
                },
                expression: "types",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "邀请列表", name: "invitation" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "邀请设置", name: "invitationSet" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "clearfix" },
            [
              _c("span", [_vm._v("启用分佣")]),
              _vm._v(" "),
              _c("el-switch", {
                staticClass: "ml20",
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#909399",
                },
                on: { change: _vm.isCommissionChange },
                model: {
                  value: _vm.setSeeData.isCommission,
                  callback: function ($$v) {
                    _vm.$set(_vm.setSeeData, "isCommission", $$v)
                  },
                  expression: "setSeeData.isCommission",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.setSeeData.isCommission
            ? _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.addRule },
                    },
                    [_vm._v("添加等级")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.setSeeData.isCommission
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData.data, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "等级名称", "min-width": "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_c("span", [_vm._v(_vm._s(row.ruleName))])]
                          },
                        },
                      ],
                      null,
                      false,
                      3692427597
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "升级条件", "min-width": "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  "邀请至" + _vm._s(row.conditions) + "人升级"
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2724267866
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "佣金比例", "min-width": "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [_vm._v(_vm._s(row.scale) + "%")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      325888338
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAction(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAction(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3039473334
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.setSeeData.isCommission
            ? _c(
                "div",
                { staticClass: "block flex justify-e" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.queryParams.pageSize,
                      "current-page": _vm.queryParams.pageNum,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mt20" }, [
            _c("div", [
              _vm._v("分享海报 "),
              _c(
                "span",
                { staticStyle: { "font-size": "12px", color: "#aaa" } },
                [_vm._v("用户邀请分享海报样式，尺寸960*1500，格式JPG,不超过2M")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mt20 sharePoster",
                style:
                  "background: url(" +
                  (_vm.setSeeData.sharePoster
                    ? _vm.setSeeData.sharePoster
                    : "") +
                  ")",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "uploadImg",
                        staticClass: "upload-demo",
                        attrs: {
                          action: "happy",
                          headers: _vm.myHeaders,
                          "show-file-list": false,
                          multiple: "",
                          "http-request": _vm.uploadALiAction,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "uploadImage",
                            attrs: { size: "small" },
                          },
                          [_vm._v("上传海报")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.setSeeData.sharePoster
                      ? _c(
                          "div",
                          { staticClass: "color-gray ft12 uploadText" },
                          [_vm._v("已上传")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "uploadMa" }, [_vm._v("二维码区域")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "mt20",
              attrs: {
                type: "primary",
                size: "small",
                disabled: !_vm.setSeeData.sharePoster,
              },
              on: { click: _vm.confirmAction },
            },
            [_vm._v("保存\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleSet
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleSet,
                "close-on-click-modal": false,
                title: "开启分佣",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleSet = $event
                },
                close: _vm.setClose,
              },
            },
            [
              _c("div", [_vm._v("请选择开启设置，确定开启分佣金。")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "mt20",
                  on: { change: _vm.isSaveChange },
                  model: {
                    value: _vm.setSeeData.isSave,
                    callback: function ($$v) {
                      _vm.$set(_vm.setSeeData, "isSave", $$v)
                    },
                    expression: "setSeeData.isSave",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("保留之前邀请数据"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("清空之前邀请数据"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.isSaveFlag,
                        size: "small",
                      },
                      on: { click: _vm.confirmAction },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleRule
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleRule,
                "close-on-click-modal": false,
                title: "添加等级",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleRule = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "addRuleForm",
                  attrs: {
                    size: "small",
                    model: _vm.addRuleForm,
                    rules: _vm.addRuleRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "等级名称", prop: "ruleName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addRuleForm.ruleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.addRuleForm, "ruleName", $$v)
                          },
                          expression: "addRuleForm.ruleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "升级条件", prop: "conditions" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { min: 0 },
                        model: {
                          value: _vm.addRuleForm.conditions,
                          callback: function ($$v) {
                            _vm.$set(_vm.addRuleForm, "conditions", $$v)
                          },
                          expression: "addRuleForm.conditions",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("邀请达到该人数升级")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "佣金比例", prop: "scale" } },
                    [
                      _c(
                        "el-input",
                        {
                          model: {
                            value: _vm.addRuleForm.scale,
                            callback: function ($$v) {
                              _vm.$set(_vm.addRuleForm, "scale", $$v)
                            },
                            expression: "addRuleForm.scale",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("%")])],
                        2
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("销售额的百分比")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmRuleAction("addRuleForm")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }