var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rule-table-height" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ft12",
          attrs: {
            data: _vm.list,
            "span-method": _vm.objectSpanMethod,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "规则设置", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "规则详情", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        scope.row.name == "积分抵现比例"
                          ? [
                              _c("div", [
                                _vm._v(_vm._s(scope.row.ruleDescription + ",")),
                              ]),
                              _vm._v(" "),
                              scope.row.isOpen
                                ? _c("div", [_vm._v("已开启抵现")])
                                : _c("div", [_vm._v("已关闭抵现")]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.name == "积分获得比例"
                          ? [
                              _c("div", [
                                _vm._v(_vm._s(scope.row.ruleDescription + ",")),
                              ]),
                              _vm._v(" "),
                              scope.row.isOpen
                                ? _c("div", [_vm._v("已开启获取")])
                                : _c("div", [_vm._v("已关闭获取")]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.name != "积分抵现比例" &&
                        scope.row.name != "积分获得比例"
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.ruleDescription) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.updateTime || "-"))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == "switch"
                      ? [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#cccccc",
                              "active-value": "1",
                              "inactive-value": "0",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.editSwitchAction(
                                  scope.row.value,
                                  scope
                                )
                              },
                            },
                            model: {
                              value: scope.row.value,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "value", $$v)
                              },
                              expression: "scope.row.value",
                            },
                          }),
                        ]
                      : [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.settingAction(scope.row)
                                },
                              },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ft12",
          attrs: {
            data: _vm.ruleList,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        _vm._l(_vm.columnData, function (item) {
          return _c("el-table-column", {
            key: item.id,
            attrs: {
              prop: item.prop,
              label: item.label,
              width: item.width,
              fixed: item.position,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      item.prop === "special"
                        ? [_c("span", [_vm._v("余额")])]
                        : item.prop === "handle"
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ]
                        : [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row[item.prop]) +
                                  "\n          "
                              ),
                            ]),
                          ],
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "500px",
            "close-on-click-modal": false,
            visible: _vm.visible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(_vm._s(_vm.setData.title)),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.setData.desc))]),
          _vm._v(" "),
          _c("el-row", { staticClass: "mt10" }, [
            _vm.setData.setType > 1 && _vm.setData.setType < 8
              ? _c(
                  "div",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _vm.setData.setType == 2
                          ? _c("span", [_vm._v("自定义名称：")])
                          : _vm.setData.setType == 3
                          ? _c("span", [_vm._v("修改有效期：")])
                          : _vm.setData.setType > 3 && _vm.setData.setType < 8
                          ? _c("span", [_vm._v("获得积分后")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "w150",
                          attrs: {
                            "max-length": 12,
                            placeholder: _vm.setData.tiplaceholderValuele,
                          },
                          model: {
                            value: _vm.setData.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.setData, "value", $$v)
                            },
                            expression: "setData.value",
                          },
                        }),
                        _vm._v(" "),
                        _vm.setData.setType > 3 && _vm.setData.setType < 8
                          ? _c("span", [_vm._v("天后解锁")])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.setData.setType >= 8 && _vm.setData.setType <= 11
              ? _c(
                  "div",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("积分："),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "w150",
                            attrs: {
                              "max-length": 12,
                              placeholder: _vm.setData.tiplaceholderValuele,
                            },
                            model: {
                              value: _vm.setData.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.setData, "value", $$v)
                              },
                              expression: "setData.value",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [
                            _vm._v("抵扣1元"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c mt10" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("抵扣积分类型："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "选择积分类型" },
                              model: {
                                value: _vm.setData.scoreTypeIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.setData, "scoreTypeIds", $$v)
                                },
                                expression: "setData.scoreTypeIds",
                              },
                            },
                            _vm._l(_vm.typeList, function (m, i) {
                              return _c("el-option", {
                                key: "type" + i,
                                attrs: { value: m.id, label: m.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c mt20" },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.setData.isOpen,
                              callback: function ($$v) {
                                _vm.$set(_vm.setData, "isOpen", $$v)
                              },
                              expression: "setData.isOpen",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [
                            _vm._v("启用积分抵扣"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.setData.setType >= 12
              ? _c(
                  "div",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _vm.setData.setType == 16
                            ? _c("span", { staticStyle: { width: "130px" } }, [
                                _vm._v("每邀请1人可得："),
                              ])
                            : _vm.setData.setType == 18
                            ? _c("span", { staticStyle: { width: "130px" } }, [
                                _vm._v("下载APP获得："),
                              ])
                            : _c("span", { staticStyle: { width: "130px" } }, [
                                _vm._v("每消费："),
                              ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "w150",
                            attrs: {
                              "max-length": 12,
                              placeholder: _vm.setData.tiplaceholderValuele,
                            },
                            model: {
                              value: _vm.setData.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.setData, "value", $$v)
                              },
                              expression: "setData.value",
                            },
                          }),
                          _vm._v(" "),
                          _vm.setData.setType == 16
                            ? _c("span", { staticClass: "ml10" }, [
                                _vm._v("积分"),
                              ])
                            : _vm.setData.setType == 18
                            ? _c("span", { staticClass: "ml10" }, [
                                _vm._v("积分"),
                              ])
                            : _c("span", { staticClass: "ml10" }, [
                                _vm._v("元可获得1积分"),
                              ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.setData.setType != 18
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c mt10" },
                            [
                              _c("span", { staticStyle: { width: "130px" } }, [
                                _vm._v("每日上限："),
                              ]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "w150",
                                attrs: {
                                  "max-length": 12,
                                  placeholder: _vm.setData.placeholderUpLimit,
                                },
                                model: {
                                  value: _vm.setData.value2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setData, "value2", $$v)
                                  },
                                  expression: "setData.value2",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("积分"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c mt10" },
                        [
                          _c("span", { staticStyle: { width: "130px" } }, [
                            _vm._v("获得积分类型："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "选择积分类型" },
                              model: {
                                value: _vm.setData.scoreTypeIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.setData, "scoreTypeIds", $$v)
                                },
                                expression: "setData.scoreTypeIds",
                              },
                            },
                            _vm._l(_vm.typeList, function (m, i) {
                              return _c("el-option", {
                                key: "type" + i,
                                attrs: { value: m.id, label: m.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.setData.setType == 15
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c mt20" },
                            [
                              _c("span", { staticStyle: { width: "130px" } }),
                              _vm._v(" "),
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.setData.value3,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.setData, "value3", $$v)
                                    },
                                    expression: "setData.value3",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("服务费部分获得积分"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("消费全额获得积分"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.setData.setType <= 15
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c mt20" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#cccccc",
                                },
                                model: {
                                  value: _vm.setData.isOpen,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setData, "isOpen", $$v)
                                  },
                                  expression: "setData.isOpen",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("启用获得积分"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.setData.setType == 16
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c mt20" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#cccccc",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.setData.value3,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setData, "value3", $$v)
                                  },
                                  expression: "setData.value3",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("实名后才可获得"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.saveAction },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "设置", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _vm.info.one
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.info, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "积分类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择活动区域" },
                          model: {
                            value: _vm.info.scoreTypeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "scoreTypeId", $$v)
                            },
                            expression: "info.scoreTypeId",
                          },
                        },
                        _vm._l(_vm.typeList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D1" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.one,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "one", $$v)
                          },
                          expression: "info.one",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D2" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.two,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "two", $$v)
                          },
                          expression: "info.two",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D3" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.three,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "three", $$v)
                          },
                          expression: "info.three",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D4" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.four,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "four", $$v)
                          },
                          expression: "info.four",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D5p" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.five,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "five", $$v)
                          },
                          expression: "info.five",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D6p" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.six,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "six", $$v)
                          },
                          expression: "info.six",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D7p" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.seven,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "seven", $$v)
                          },
                          expression: "info.seven",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D8p" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.eight,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "eight", $$v)
                          },
                          expression: "info.eight",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "D9p" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 0,
                          max: 100,
                          min: 0,
                        },
                        model: {
                          value: _vm.info.nine,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "nine", $$v)
                          },
                          expression: "info.nine",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "large" },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "large" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t ft14 fWeight500 mt20" }, [
      _c("span", [_vm._v("积分规则设置")]),
      _vm._v(" "),
      _c("div", { staticClass: "bg" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t ft14 fWeight500 mt40" }, [
      _c("span", [_vm._v("奖励规则设置")]),
      _vm._v(" "),
      _c("div", { staticClass: "bg" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }