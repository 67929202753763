var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 3
      ? _c(
          "div",
          {
            staticClass: "content-box flex",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(_vm.content.style == 4 ? 3 : 2, function (m, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "l flex-one",
                staticStyle: { overflow: "hidden" },
                style:
                  i > 0
                    ? "padding-left: " + _vm.facade.item_margin / 2 + "px;"
                    : "",
              },
              _vm._l(2, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "one-list",
                    class: { mt0: index == 0 },
                    style:
                      "width: 100%;margin-top:" +
                      _vm.facade.item_margin / 2 +
                      "px;border-top-right-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-top-left-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-bottom-left-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;border-bottom-right-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;background: " +
                      _vm.facade.status_background +
                      " url(" +
                      _vm.facade.status_bg_image +
                      ") repeat left center;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        staticStyle: { background: "#999" },
                        style:
                          _vm.getHeight +
                          "border-bottom-left-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;border-bottom-right-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;overflow: hidden;",
                      },
                      [
                        _vm.facade.img_style === 1
                          ? _c("img", {
                              staticStyle: { display: "block" },
                              attrs: {
                                src:
                                  index == 0 && i == 0
                                    ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                    : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                              },
                            })
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src:
                                  index == 0 && i == 0
                                    ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                    : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                                fit:
                                  _vm.facade.img_style === 2
                                    ? "contain"
                                    : "cover",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "text color-black line1",
                        staticStyle: { padding: "8px 10px 0 10px" },
                        style:
                          "color: " +
                          _vm.facade.title_color +
                          ";font-size: " +
                          _vm.facade.title_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.title_font_weight +
                          ";",
                      },
                      [_vm._v("刺杀罪恶之文化侵蚀")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "r-address flex align-items-c",
                        staticStyle: { padding: "0 10px", "margin-top": "5px" },
                        style:
                          "color: " +
                          _vm.facade.sub_color +
                          ";font-size: " +
                          _vm.facade.sub_font_size / 2 +
                          "px;",
                      },
                      [
                        _c("img", {
                          style:
                            "width: " +
                            (_vm.facade.price_font_size + 10) / 2 +
                            "px;",
                          attrs: {
                            src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2022125/image/167023824764448889.png!120a",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "l-price ml5",
                            style:
                              "color: " +
                              _vm.facade.price_color +
                              ";font-size: " +
                              _vm.facade.price_font_size / 2 +
                              "px;font-weight: 500;line-height: " +
                              (_vm.facade.price_font_size + 10) / 2 +
                              "px",
                          },
                          [_vm._v("3000")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bottom flex align-items-c",
                        staticStyle: { padding: "0 10px", "margin-top": "7px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tag",
                            style:
                              "padding: 1px 4px;font-weight: 500;font-size: " +
                              _vm.facade.btn_font_size / 2 +
                              "px;color: " +
                              _vm.facade.main_btn_color +
                              ";background: " +
                              _vm.facade.main_btn_bg_color +
                              ";border-radius: " +
                              _vm.facade.btn_radius / 2 +
                              "px;border: 1px solid " +
                              _vm.facade.main_btn_border +
                              ";",
                          },
                          [
                            _vm._v("已兑30"),
                            _c(
                              "span",
                              {
                                style:
                                  "font-weight: 400;color: " +
                                  _vm.facade.sub_btn_color +
                                  ";",
                              },
                              [_vm._v("/100")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            )
          }),
          0
        )
      : _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(2, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index == 0 },
                style:
                  "width: 100%;margin-top:" +
                  _vm.facade.item_margin / 2 +
                  "px;border-top-right-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-top-left-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-bottom-left-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;border-bottom-right-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;background: " +
                  _vm.facade.status_background +
                  " url(" +
                  _vm.facade.status_bg_image +
                  ") repeat left center;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img",
                    style:
                      "border-bottom-left-radius: " +
                      _vm.facade.img_bottom_radius / 2 +
                      "px;border-bottom-right-radius: " +
                      _vm.facade.img_bottom_radius / 2 +
                      "px;overflow: hidden;",
                  },
                  [
                    _c("img", {
                      staticStyle: { display: "block" },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex align-items-c" }, [
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      staticStyle: { overflow: "hidden" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text color-black line1",
                          staticStyle: { padding: "8px 10px 0 10px" },
                          style:
                            "color: " +
                            _vm.facade.title_color +
                            ";font-size: " +
                            _vm.facade.title_font_size / 2 +
                            "px;font-weight: " +
                            _vm.facade.title_font_weight +
                            ";",
                        },
                        [_vm._v("刺杀罪恶之文化侵蚀")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "r-address flex align-items-c",
                          staticStyle: {
                            padding: "0 10px",
                            "margin-top": "5px",
                          },
                          style:
                            "color: " +
                            _vm.facade.sub_color +
                            ";font-size: " +
                            _vm.facade.sub_font_size / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            style:
                              "width: " +
                              (_vm.facade.price_font_size + 10) / 2 +
                              "px;",
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2022125/image/167023824764448889.png!120a",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "l-price ml5",
                              style:
                                "color: " +
                                _vm.facade.price_color +
                                ";font-size: " +
                                _vm.facade.price_font_size / 2 +
                                "px;font-weight: 500;line-height: " +
                                (_vm.facade.price_font_size + 10) / 2 +
                                "px",
                            },
                            [_vm._v("3000")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "bottom flex align-items-c",
                          staticStyle: {
                            padding: "0 10px",
                            "margin-top": "7px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tag",
                              style:
                                "padding: 1px 4px;font-weight: 500;font-size: " +
                                _vm.facade.btn_font_size / 2 +
                                "px;color: " +
                                _vm.facade.main_btn_color +
                                ";background: " +
                                _vm.facade.main_btn_bg_color +
                                ";border-radius: " +
                                _vm.facade.btn_radius / 2 +
                                "px;border: 1px solid " +
                                _vm.facade.main_btn_border +
                                ";",
                            },
                            [
                              _vm._v("已兑30"),
                              _c(
                                "span",
                                {
                                  style:
                                    "font-weight: 400;color: " +
                                    _vm.facade.sub_btn_color +
                                    ";",
                                },
                                [_vm._v("/100")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }