var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "flex align-items-c flex-betten",
        style: {
          borderRadius: _vm.facade.m_border_radio / 2 + "px",
          padding: "0 " + _vm.facade.item_padding / 2 + "px",
          height: _vm.facade.m_height / 2 + "px",
          margin: "0 " + _vm.facade.page_margin / 2 + "px",
          background: _vm.facade.m_bg_image
            ? "url(" + _vm.facade.m_bg_image + ")"
            : _vm.facade.m_bg,
          backgroundSize: "100% 100%",
        },
      },
      [
        _c("div", {}, [
          _c(
            "span",
            {
              style:
                "font-size: " +
                _vm.facade.m_fontSize / 2 +
                "px;color: " +
                _vm.facade.m_integral_color +
                ";font-weight: " +
                _vm.facade.m_level_font_w,
            },
            [_vm._v("1777")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              style:
                "font-size: " +
                _vm.facade.m_fontSize / 2 +
                "px;color: " +
                _vm.facade.m_integral_color,
            },
            [_vm._v("成长值")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c right-con" }, [
          _c("div", [
            _c(
              "p",
              {
                style:
                  "font-size: " +
                  _vm.facade.m_fontSize / 2 +
                  "px;color: " +
                  _vm.facade.m_integral_color +
                  ";font-weight: " +
                  _vm.facade.m_level_font_w,
              },
              [
                _vm._v("\n          还差400成长值成长到\n          "),
                _c(
                  "span",
                  {
                    style:
                      "font-size: " +
                      _vm.facade.m_level_fontSize / 2 +
                      "px;color: " +
                      _vm.facade.m_level_color +
                      ";font-weight: " +
                      _vm.facade.m_level_font_w,
                  },
                  [_vm._v("V4")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("img", {
              staticClass: "img",
              attrs: { src: _vm.facade.m_right_icon, alt: "" },
            }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }