"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _AIGCApi = require("@/api/AIGCApi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "workStyle",
  components: {},
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      list: [],
      pages: {
        pageSize: 10,
        pageNum: 1
      },
      imgPages: {
        pageSize: 20,
        pageNum: 1,
        total: 0
      },
      show: false,
      visibleShow: false,
      pictrueList: [],
      fromState: {},
      imgList: [],
      rules: {
        title: [{
          required: true,
          message: "请输入作品名称",
          trigger: "blur"
        }],
        prompt: [{
          required: true,
          message: "请输入对应提示词",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList(1);
    this.getImgList(1);
  },
  methods: {
    comfig: function comfig() {
      var _this = this;
      this.pictrueList.forEach(function (item) {
        if (item.isSelect) {
          _this.imgList.push(item.url);
          item.isSelect = false;
        }
      });
      this.visibleShow = false;
    },
    changImage: function changImage(item) {
      if (!item.isSelect) {
        item.isSelect = true;
      } else item.isSelect = false;
      this.$forceUpdate();
    },
    getImgList: function getImgList() {
      var _this2 = this;
      (0, _AIGCApi.getImageList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.imgPages), {}, {
        humanId: this.$route.query.id
      })).then(function (res) {
        // console.log('1-',res);
        _this2.pictrueList = res.rows;
        _this2.imgPages.total = res.total;
      });
    },
    pageChange: function pageChange(page) {
      this.imgPages.pageNum = page;
      this.getImgList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.imgPages.pageNum = 1;
      this.imgPages.pageSize = val;
      this.getImgList();
    },
    getList: function getList(reset) {
      var _this3 = this;
      if (reset) {
        this.pages.pageNum = 1;
      }
      var data = (0, _objectSpread2.default)({
        humanId: this.$route.query.id
      }, this.pages);
      this.listLoading = true;
      (0, _AIGCApi.getWorkStyleApi)(data).then(function (res) {
        if (res.code === 200) {
          // this.total = res.total;
          _this3.list = res.data;
        }
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    modalPicTap: function modalPicTap(tit) {
      var that = this;
      this.$modalUpload(function (img) {
        that.imgList = [].concat((0, _toConsumableArray2.default)(that.imgList), (0, _toConsumableArray2.default)(img));
      }, 0);
    },
    handleRemove: function handleRemove(index) {
      this.imgList.splice(index, 1);
    },
    onAdd: function onAdd() {
      this.imgList = [];
      this.fromState = {};
      this.show = true;
    },
    onEdit: function onEdit(row) {
      this.imgList = row.images ? row.images.split(",") : [];
      this.fromState = row;
      this.show = true;
    },
    onDelete: function onDelete(id) {
      var _this4 = this;
      (0, _AIGCApi.productDel)(id).then(function (res) {
        _this4.$message.success("操作成功");
        _this4.getList(1);
      });
    },
    onChange: function onChange(row) {
      var _this5 = this;
      (0, _AIGCApi.productUpdate)(row).then(function (res) {
        _this5.getList(1);
      });
    },
    submitForm: function submitForm(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var action;
          if (_this6.fromState.id) action = _AIGCApi.productUpdate;else action = _AIGCApi.productSave;
          action((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this6.fromState), {}, {
            humanId: _this6.$route.query.id,
            images: _this6.imgList.join(",")
          })).then(function (res) {
            _this6.$message.success("操作成功");
            _this6.show = false;
            _this6.getList(1);
          });
        } else {
          return false;
        }
      });
    },
    changePage: function changePage(page) {
      this.pages.pageNum = page;
      this.getList();
    },
    goBack: function goBack() {
      this.$router.push("/merchant/AIGC/numberPer");
    },
    changestatus: function changestatus(row) {
      var _this7 = this;
      var json = {};
      json.id = row.id;
      if (row.status == 2) {
        json.status = 3;
      } else if (row.status == 3) {
        json.status = 2;
      }
      (0, _AIGCApi.updateStyleStatus)(json).then(function (res) {
        _this7.getList();
      });
    }
  }
};