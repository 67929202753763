"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _saasApi = require("@/api/saasApi");
var _feature = _interopRequireDefault(require("./feature.vue"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Feature: _feature.default
  },
  data: function data() {
    return {
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        systemType: 2,
        productType: ""
      },
      //表格 分页
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      drawerVisib: false,
      metaProductType: {},
      editInfo: null
    };
  },
  created: function created() {
    var _this = this;
    (0, _saasApi.saasGetProductType)().then(function (res) {
      _this.metaProductType = res.data.metaProductType;
    });
    this.getTableList();
  },
  computed: {
    productTypeList: {
      get: function get() {
        var _this2 = this;
        return Object.keys(this.metaProductType).map(function (key) {
          return {
            id: key,
            type: _this2.metaProductType[key],
            systemType: 2
          };
        });
      }
    },
    newTableData: {
      get: function get() {
        var _this3 = this;
        return this.tableData.data.map(function (item) {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
            useTimeText: item.useTime ? item.useTime + "年" : "永久",
            productTypeText: _this3.metaProductType[item.productType]
          });
        });
      }
    }
  },
  methods: {
    getTableList: function getTableList(num) {
      var _this4 = this;
      if (num) this.queryParams.pageNum = num;
      (0, _saasApi.saasProductList)((0, _objectSpread2.default)({}, this.queryParams)).then(function (res) {
        _this4.tableData.data = res.rows;
        _this4.tableData.total = res.total;
        // this.statistical = res.statistical
      });
    },
    onAdd: function onAdd(row) {
      this.drawerVisib = true;
      this.editInfo = row ? (0, _utils.deepClone)(row) : null;
    },
    closeDrawerVisib: function closeDrawerVisib(bool) {
      this.drawerVisib = bool;
      this.getTableList(1);
    },
    pageChange: function pageChange(val) {
      this.getTableList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getTableList(1);
    }
  }
};