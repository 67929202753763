var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "digital-collection mt20" },
    [
      _c(
        "div",
        { staticClass: "tab flex align-items-c" },
        [
          _c(
            "el-input",
            {
              staticClass: "w300",
              attrs: { placeholder: "赠送人昵称/手机号/真实姓名" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.own_user,
                callback: function ($$v) {
                  _vm.own_user = $$v
                },
                expression: "own_user",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w300 ml10",
              attrs: { placeholder: "接收人昵称/手机号/真实姓名" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.recipient_user,
                callback: function ($$v) {
                  _vm.recipient_user = $$v
                },
                expression: "recipient_user",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作品名称" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "赠送人" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _vm._v(_vm._s(row.ownUser.nickname)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "接收人" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _vm._v(_vm._s(row.recipientUser.nickname)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品信息" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c("div", { staticClass: "work-img" }, [
                            _c("img", {
                              attrs: {
                                src: row.nftData.product.image + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "user-info flex-one ml10",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c("div", { staticClass: "fWeight500" }, [
                                _vm._v(_vm._s(row.nftData.product.store_name)),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(row.nftData.issue_number)),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.create_time))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: { "current-change": _vm.getList },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }