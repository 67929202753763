"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/api/request"));
var _product = require("@/api/product");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import taoBao from './taoBao';
var _default = exports.default = {
  name: 'ProductList',
  // components: { taoBao },
  data: function data() {
    return {
      props: {
        emitPath: false
      },
      roterPre: _settings.roterPre,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        mer_cate_id: '',
        cate_id: '',
        keyword: '',
        type: '1',
        is_gift_bag: ''
      },
      categoryList: [],
      // 平台
      merCateList: [],
      // 商户分类筛选
      modals: false,
      storeList: [],
      showSku: false,
      skuList: [],
      showImg: false,
      // 查看图片 控制器
      imgUrl: '' // 放大查看的图片
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  mounted: function mounted() {
    this.getLstFilterApi();
    this.getCategorySelect();
    // this.getCategoryList();
    this.getList(1);
    this.getStoreList();
  },
  methods: {
    getSuccess: function getSuccess() {
      this.getLstFilterApi();
      this.getList(1);
    },
    getStoreList: function getStoreList(num) {
      var _this = this;
      (0, _product.getStoreList)({
        page: 1
      }).then(function (res) {
        _this.storeList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 添加淘宝商品成功
    onClose: function onClose() {
      this.modals = false;
    },
    // 复制淘宝
    onCopy: function onCopy() {
      this.$refs.taoBao.modals = true;
      this.$refs.taoBao.soure_link = '';
      this.$refs.taoBao.formValidate = {};
      this.$refs.taoBao.isData = false;
    },
    handleRestore: function handleRestore(id) {
      var _this2 = this;
      this.$modalSure('恢复商品').then(function () {
        (0, _product.restoreApi)(id).then(function (res) {
          _this2.$message.success(res.message);
          _this2.getLstFilterApi();
          _this2.getList('');
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this3 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this3.merCateList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 平台分类；
    getCategoryList: function getCategoryList() {
      var _this4 = this;
      (0, _product.categoryListApi)().then(function (res) {
        _this4.categoryList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 列表表头；
    getLstFilterApi: function getLstFilterApi() {
      var _this5 = this;
      (0, _product.lstFilterApi)().then(function (res) {
        _this5.headeNum = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        _this6.listLoading = false;
        // this.$message.error(res.message);
      });
      this.getLstFilterApi();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this7 = this;
      this.$modalSure(this.tableFrom.type !== '5' ? '加入回收站' : '').then(function () {
        _this7.tableFrom.type === '5' ? (0, _product.destoryApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this7.$message.success(message);
          _this7.getList('');
          _this7.getLstFilterApi();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        }) : (0, _product.productDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this7.$message.success(message);
          _this7.getList('');
          _this7.getLstFilterApi();
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this8 = this;
      (0, _product.statusApi)({
        id: row.product_id,
        status: row.mer_status
      }).then(function (_ref5) {
        var message = _ref5.message;
        _this8.$message.success(message);
        _this8.getList('');
        _this8.getLstFilterApi();
      }).catch(function (_ref6) {
        var message = _ref6.message;
      });
    },
    showSkuAction: function showSkuAction(sku) {
      this.skuList = sku;
      this.showSku = true;
    },
    copyXcxLink: function copyXcxLink(m, event) {
      var url = '/pages/productDetail/index?id=' + m.product_id;
      var u = "/pages/otherPage/otherPage?link=".concat(encodeURIComponent(url));
      var input = document.createElement('input'); // 直接构建input
      input.value = u; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    copyH5Link: function copyH5Link(m, event) {
      var url = '/pages/productDetail/index?id=' + m.product_id;
      var url1 = '';
      if (this.domain) {
        url1 = "".concat(this.domain).concat(url);
      } else {
        url1 = "".concat(this.domain_url).concat(url);
      }
      var input = document.createElement('input'); // 直接构建input
      input.value = url1; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    showImgAction: function showImgAction(val, m) {
      var _this9 = this;
      var action = process.env.VUE_APP_BASE_API + '/api/sharePoster';
      var mer_id = this.$store.getters.mer_id;
      switch (val) {
        case 1:
          action += '?return_format=2&poster_type=product&qrcode_type=1&Independent_qrcode=1&id=' + m.product_id + '&mer_id=' + mer_id;
          break;
        case 2:
          action += '?return_format=2&poster_type=product&qrcode_type=2&Independent_qrcode=1&id=' + m.product_id + '&mer_id=' + mer_id;
          break;
        case 3:
          action += '?return_format=2&poster_type=product&qrcode_type=1&Independent_qrcode=0&id=' + m.product_id + '&mer_id=' + mer_id;
          break;
        case 4:
          action += '?return_format=2&poster_type=product&qrcode_type=2&Independent_qrcode=0&id=' + m.product_id + '&mer_id=' + mer_id;
          break;
        default:
          action += '?return_format=2&poster_type=product&qrcode_type=1&Independent_qrcode=1&id=' + m.product_id + '&mer_id=' + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;
      this.showImg = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this9.imgUrl = res.data.base_image;
      });
    },
    beforeCloseImg: function beforeCloseImg(done) {
      this.imgUrl = '';
      done();
    }
  }
};