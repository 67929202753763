"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _settings = require("@/settings");
var _request = _interopRequireDefault(require("@/api/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "blindboxList",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      props: {
        value: "store_brand_category_id",
        label: "cate_name",
        children: "children",
        emitPath: false
      },
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      },
      imgList: [],
      brandCategory: [],
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      showImg: false,
      // 查看图片 控制器
      imgUrl: '',
      // 放大查看的图片
      domain: ''
    };
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import";
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    changestatus: function changestatus(id, status) {
      var _this = this;
      this.$modalSure('确认此操作').then(function () {
        (0, _product.blindboxStatusApi)(id, {
          status: status
        }).then(function (_ref) {
          var message = _ref.message;
          _this.$message.success('操作成功');
          _this.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    },
    // 下载
    downLoad: function downLoad() {
      window.open(_settingMer.default.https + "/excel/templateDownload?type=import_store_brand&token=" + (0, _auth.getToken)());
    },
    // 上传成功
    handleSuccess: function handleSuccess(response) {
      if (response.status === 200) {
        this.$message.success("上传成功");
        this.getList();
      } else {
        this.$message.error(response.message);
      }
      this.$refs.uploadImg.clearFiles();
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _product.blindboxListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.tableData.data.map(function (item) {
          _this2.imgList.push(item.pic);
        });
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push({
        path: _settings.roterPre + "/product/addBlindBox"
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      this.$router.push({
        path: _settings.roterPre + "/product/addBlindBox?id=" + id
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _product.blindboxDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this3.$message.success(message);
          _this3.getList();
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      });
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this4 = this;
      this.tableFrom.export_type = "store_brand";
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this4.$createElement;
        _this4.$msgbox({
          title: "提示",
          message: h("p", null, [h("span", null, '文件正在生成中，请稍后点击"'), h("span", {
            style: "color: teal"
          }, "导出记录"), h("span", null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("store_brand");
    },
    copyXcxLink: function copyXcxLink(m, event) {
      var url = '/pages/product/blinddetailc?id=' + m.box_id + "&albumid=" + (m.album_id || '');
      var input = document.createElement('input'); // 直接构建input
      input.value = url; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    copyH5Link: function copyH5Link(m, event) {
      var url = _settingMer.default.appUrl + '/pages/product/blinddetailc?id=' + m.box_id + "&albumid=" + (m.album_id || '');
      var url1 = '';
      if (this.domain) {
        url1 = "".concat(this.domain).concat(url);
      } else {
        url1 = "".concat(this.domain_url).concat(url);
      }
      var input = document.createElement('input'); // 直接构建input
      input.value = url1; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    showImgAction: function showImgAction(val, m) {
      var _this5 = this;
      var action = process.env.VUE_APP_BASE_API + '/api/sharePoster';
      var mer_id = this.$store.getters.mer_id;
      switch (val) {
        case 1:
          action += '?return_format=2&poster_type=diyQrcode&qrcode_type=1&Independent_qrcode=1&path=' + encodeURIComponent('pages/otherPage/otherPage?link=' + encodeURIComponent('/pages/product/blinddetailc?id=' + m.box_id + "%26albumid%3D" + (m.album_id || '')) + '&hasQuery=1') + '&&width=1024&mer_id=' + mer_id;
          break;
        case 2:
          action += '?return_format=2&poster_type=diyQrcode&qrcode_type=2&Independent_qrcode=1&path=' + encodeURIComponent('/pages/product/blinddetailc?id=' + m.box_id + "%26albumid%3D" + (m.album_id || '')) + '&mer_id=' + mer_id;
          break;
        case 3:
          action += '?return_format=2&poster_type=auction&qrcode_type=1&Independent_qrcode=0&id=' + m.product_id + '&mer_id=' + mer_id;
          break;
        case 4:
          action += '?return_format=2&poster_type=auction&qrcode_type=2&Independent_qrcode=0&id=' + m.product_id + '&mer_id=' + mer_id;
          break;
        default:
          action += '?return_format=2&poster_type=auction&qrcode_type=1&Independent_qrcode=1&id=' + m.product_id + '&mer_id=' + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;
      this.showImg = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this5.imgUrl = res.data.base_image;
      }).catch(function (err) {
        loading.close();
        _this5.showImg = false;
      });
    }
  }
};