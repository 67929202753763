var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "80px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "status", $$v)
                            },
                            expression: "queryParams.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部 "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.all
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("退款中 "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.waitCount
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "4" } }, [
                            _vm._v("已完成 "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.overCount
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "时间选择：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange(_vm.queryParams.timeType)
                            },
                          },
                          model: {
                            value: _vm.queryParams.timeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "timeType", $$v)
                            },
                            expression: "queryParams.timeType",
                          },
                        },
                        _vm._l(_vm.timeList, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text) + "\n            ")]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "value-format": "yyyy/MM/dd HH:mm:ss",
                          format: "yyyy/MM/dd HH:mm:ss",
                          size: "small",
                          type: "datetimerange",
                          placement: "bottom-end",
                          "start-placeholder": "选择开始时间",
                          "end-placeholder": "选择结束时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeType,
                          callback: function ($$v) {
                            _vm.timeType = $$v
                          },
                          expression: "timeType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: {
                        placeholder: "作品/专辑/盲盒名称",
                        size: "small",
                      },
                      model: {
                        value: _vm.queryParams.productName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "productName", $$v)
                        },
                        expression: "queryParams.productName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "用户名称/手机号", size: "small" },
                      model: {
                        value: _vm.queryParams.memberNameOrPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "memberNameOrPhone", $$v)
                        },
                        expression: "queryParams.memberNameOrPhone",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入作品编号", size: "small" },
                      model: {
                        value: _vm.queryParams.productNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "productNumber", $$v)
                        },
                        expression: "queryParams.productNumber",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入订单编号", size: "small" },
                      model: {
                        value: _vm.queryParams.orderSn,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "orderSn", $$v)
                        },
                        expression: "queryParams.orderSn",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入支付单号", size: "small" },
                      model: {
                        value: _vm.queryParams.payOrderSn,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "payOrderSn", $$v)
                        },
                        expression: "queryParams.payOrderSn",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item selWidth",
                      attrs: {
                        placeholder: "请选择支付渠道",
                        size: "small",
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "payType", $$v)
                        },
                        expression: "queryParams.payType",
                      },
                    },
                    _vm._l(_vm.payList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt10 mb10" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("查看导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "退款单号", "min-width": "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { display: "block" },
                          domProps: { textContent: _vm._s(scope.row.refundSn) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单编号", "min-width": "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { display: "block" },
                          domProps: { textContent: _vm._s(scope.row.orderSn) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付单号", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.payOrderSn))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付方式", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.payType))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.showDetail(scope.row.memberId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.nickName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatType")(scope.row.type))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图片", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: { src: scope.row.image },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.productNumber))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "实际支付金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.realPayPrice))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "退款金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.refundPrice))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatStatus")(scope.row.status))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "提交时间", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.createTime || "-")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? _c("userdetail", {
            attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
            on: {
              close: function ($event) {
                _vm.showdetail = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }