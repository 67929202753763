var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "padding-top": "0" } },
    [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("编号")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "div",
              { staticStyle: { width: "375px" } },
              [_c("Tag", { attrs: { facade: _vm.tag } })],
              1
            ),
            _vm._v(" "),
            _c("rightBtn", {
              on: {
                showset: function ($event) {
                  return _vm.showSetAction("tag")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("优惠券")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("Coupon", { attrs: { facade: _vm.coupon } }),
            _vm._v(" "),
            _c("rightBtn", {
              on: {
                showset: function ($event) {
                  return _vm.showSetAction("coupon")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item other-setting" }, [
        _c("div", { staticClass: "title" }, [_vm._v("通用页面配置")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("页面背景色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#f7f7f7" },
                  model: {
                    value: _vm.otherData.pageBackground,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "pageBackground", $$v)
                    },
                    expression: "otherData.pageBackground",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("基础文字颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000", "no-alpha": "1" },
                  model: {
                    value: _vm.otherData.color,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "color", $$v)
                    },
                    expression: "otherData.color",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("突出文字颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.specialColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "specialColor", $$v)
                    },
                    expression: "otherData.specialColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c mt10" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("副文字颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.subColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "subColor", $$v)
                    },
                    expression: "otherData.subColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("价格文字颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.priceColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "priceColor", $$v)
                    },
                    expression: "otherData.priceColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("边框颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.borderColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "borderColor", $$v)
                    },
                    expression: "otherData.borderColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c mt10" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("页面风格")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one flex align-items-c" },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.otherData.pageStyle,
                      callback: function ($$v) {
                        _vm.$set(_vm.otherData, "pageStyle", $$v)
                      },
                      expression: "otherData.pageStyle",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "black" } }, [
                      _vm._v("浅色系"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: "white" } }, [
                      _vm._v("深色系"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("文字风格")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one flex align-items-c" },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.otherData.fontWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.otherData, "fontWeight", $$v)
                      },
                      expression: "otherData.fontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "title mt20" }, [_vm._v("寄售页面配置")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("顶部背景色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#f7f7f7" },
                  model: {
                    value: _vm.otherData.marketBackground,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "marketBackground", $$v)
                    },
                    expression: "otherData.marketBackground",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("顶部主文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.marketMainColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "marketMainColor", $$v)
                    },
                    expression: "otherData.marketMainColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("顶部副文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.marketSubColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "marketSubColor", $$v)
                    },
                    expression: "otherData.marketSubColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "title mt20" }, [_vm._v("列表页面配置")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("列表背景色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#f7f7f7" },
                  model: {
                    value: _vm.otherData.listPageBackground,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "listPageBackground", $$v)
                    },
                    expression: "otherData.listPageBackground",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("列表主文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.listMainColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "listMainColor", $$v)
                    },
                    expression: "otherData.listMainColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("列表副文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.listSubColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "listSubColor", $$v)
                    },
                    expression: "otherData.listSubColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c mt10" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("列表状态文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.listStatusColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "listStatusColor", $$v)
                    },
                    expression: "otherData.listStatusColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("阴影颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.listShadowColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "listShadowColor", $$v)
                    },
                    expression: "otherData.listShadowColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "title mt20" }, [_vm._v("搜索框配置")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("背景色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#f7f7f7" },
                  model: {
                    value: _vm.otherData.searchBgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "searchBgColor", $$v)
                    },
                    expression: "otherData.searchBgColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("文字颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.searchColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "searchColor", $$v)
                    },
                    expression: "otherData.searchColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("图标颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one flex align-items-c" },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.otherData.searchIconColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.otherData, "searchIconColor", $$v)
                      },
                      expression: "otherData.searchIconColor",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "white" } }, [
                      _vm._v("白色"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: "black" } }, [
                      _vm._v("黑色"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "title mt20" }, [_vm._v("TAB栏配置")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("TAB背景")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.tabBg,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "tabBg", $$v)
                    },
                    expression: "otherData.tabBg",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("TAB文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.tabTextColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "tabTextColor", $$v)
                    },
                    expression: "otherData.tabTextColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("TAB选中文字")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.tabCurrTextColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "tabCurrTextColor", $$v)
                    },
                    expression: "otherData.tabCurrTextColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "title mt20" }, [_vm._v("订单列表按钮配置")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c" }, [
          _c("div", { staticClass: "flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("订单标签展示")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one flex align-items-c" },
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.otherData.order_tab_new,
                      callback: function ($$v) {
                        _vm.$set(_vm.otherData, "order_tab_new", $$v)
                      },
                      expression: "otherData.order_tab_new",
                    },
                  },
                  [
                    _c("el-checkbox", { attrs: { label: 0, disabled: "" } }, [
                      _vm._v("全部"),
                    ]),
                    _vm._v(" "),
                    _c("el-checkbox", { attrs: { label: 1 } }, [
                      _vm._v("待付款"),
                    ]),
                    _vm._v(" "),
                    _c("el-checkbox", { attrs: { label: 2 } }, [
                      _vm._v("已完成"),
                    ]),
                    _vm._v(" "),
                    _c("el-checkbox", { attrs: { label: 3 } }, [
                      _vm._v("已关闭"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c mt10" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("背景颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.orderBtnBg,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "orderBtnBg", $$v)
                    },
                    expression: "otherData.orderBtnBg",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("文字颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.orderBtnColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "orderBtnColor", $$v)
                    },
                    expression: "otherData.orderBtnColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "title mt20" }, [_vm._v("订单详情顶部配置")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex align-items-c" }, [
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("顶部背景颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.orderTopBg,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "orderTopBg", $$v)
                    },
                    expression: "otherData.orderTopBg",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w300 flex align-items-c mr20" }, [
            _c("div", { staticClass: "mr10" }, [_vm._v("顶部文字颜色")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.otherData.orderTopColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherData, "orderTopColor", $$v)
                    },
                    expression: "otherData.orderTopColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c mt10" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.saveOtherAction },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.showSet,
            "append-to-body": true,
            "show-close": false,
            "with-header": _vm.setKey !== "footer_data",
            "before-close": _vm.handleClose,
            size: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSet = $event
            },
          },
        },
        [
          _vm.setKey == "footer_data"
            ? [
                _vm.showSet
                  ? _c("footerAttribute", {
                      model: {
                        value: _vm.settingData,
                        callback: function ($$v) {
                          _vm.settingData = $$v
                        },
                        expression: "settingData",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "b flex align-items-c justify-c" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.saveAction(_vm.setKey)
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.handleClose } }, [
                      _vm._v("关闭"),
                    ]),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  {
                    staticClass: "set-header flex align-items-c justify-b",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("div", [_vm._v(_vm._s(_vm.setTitle))]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20220109/d4ca9a0e7cf99adaee8e544a9935d008.png",
                        alt: "",
                      },
                      on: { click: _vm.handleClose },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "set-content", style: _vm.scrollHeight },
                  [
                    _vm.modules[_vm.setKey]
                      ? _c(
                          _vm.modules[_vm.setKey].attribute,
                          {
                            tag: "component",
                            model: {
                              value: _vm.settingData,
                              callback: function ($$v) {
                                _vm.settingData = $$v
                              },
                              expression: "settingData",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "b flex align-items-c justify-c" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveAction(_vm.setKey)
                                      },
                                    },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  { on: { click: _vm.handleClose } },
                                  [_vm._v("关闭")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }