"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var renovationRouter = {
  path: "".concat(_settings.roterPre, "/pageManagement"),
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/pageManagement/renovation/diy"),
  meta: {
    title: '页面装修'
  },
  children: [{
    path: 'renovation',
    name: 'renovation',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/index'));
      });
    },
    meta: {
      title: '页面装修',
      noCache: true
    },
    children: [{
      path: 'diy',
      name: 'renovation_diy',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/diy'));
        });
      },
      meta: {
        title: '页面创建',
        noCache: true
      }
    }, {
      path: 'base_page',
      name: 'renovation_base',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/basicPage'));
        });
      },
      meta: {
        title: '基础页面',
        noCache: true
      }
      // meta: { title: '基础页面', noCache: true, activeMenu: `${roterPre}/pageManagement/renovation/diy` }
    }, {
      path: 'base_set',
      name: 'renovation_base',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/base'));
        });
      },
      meta: {
        title: '基础风格',
        noCache: true
      }
    }, {
      path: 'base_other_set',
      name: 'renovation_base',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/base_other'));
        });
      },
      meta: {
        title: '其它风格',
        noCache: true
      }
    }]
  }]
};
var _default = exports.default = renovationRouter;