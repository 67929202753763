"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var orderRouter = {
  path: "".concat(_settings.roterPre, "/order"),
  name: "order",
  meta: {
    icon: "dashboard",
    title: "订单"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "noRedirect",
  children: [{
    path: "productOrder",
    name: "ProductOrder",
    meta: {
      title: "订单管理"
    },
    children: [{
      path: "list",
      name: "OrderList",
      meta: {
        title: "版权发行"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/index"));
        });
      }
    }, {
      path: "blind",
      name: "blindList ",
      meta: {
        title: "版权转让"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/blind/index"));
        });
      }
    }, {
      path: "consignment",
      name: "consignmentList",
      //   老的弃用了
      meta: {
        title: "作品定制"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/consignment/index"));
        });
      }
    }, {
      path: "workcustom",
      name: "workcustom",
      meta: {
        title: "作品定制"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/workcustom/index"));
        });
      }
    }, {
      path: "goodsService",
      name: "goodsService",
      meta: {
        title: "产品服务"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/goodsService/index"));
        });
      }
    }, {
      path: "pictureModeling",
      name: "pictureModeling",
      meta: {
        title: "图片建模"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/pictureModeling/index"));
        });
      }
    }, {
      path: "daoCard",
      name: "daoCard",
      meta: {
        title: "DAO共识卡"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/Dao/index"));
        });
      }
    }, {
      path: "iporder",
      name: "iporder",
      meta: {
        title: "ip直通车订单"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/iporder/index"));
        });
      }
    }, {
      path: "shopOrder",
      name: "shopOrder",
      meta: {
        title: "实物订单"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/shopOrder"));
        });
      }
    }, {
      path: "refund",
      name: "refundList",
      meta: {
        title: "退款单"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/refund/index"));
        });
      }
    }, {
      path: "exchangeRecord",
      name: "exchangeRecordList",
      meta: {
        title: "兑换记录"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/marketing/integral/exchange_record"));
        });
      }
    }],
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/productOrder"));
      });
    }
  }, {
    path: 'merchant',
    name: 'orderMerchant',
    redirect: "".concat(_settings.roterPre, "/order/merchant/recharge"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/merchant/index"));
      });
    },
    children: [{
      path: "recharge",
      name: "orderRecharge",
      meta: {
        title: "业务充值"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/merchant/recharge"));
        });
      }
    }, {
      path: "sdk",
      name: "orderSdk",
      meta: {
        title: "业务充值"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/merchant/sdk"));
        });
      }
    }]
  }, {
    path: "consignmentOrder",
    name: "ConsignmentOrder",
    meta: {
      title: "寄售市场"
    },
    children: [{
      path: "works",
      name: "ConsignmentProduct",
      meta: {
        title: "作品转让"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/consignmentSys/index"));
        });
      }
    }, {
      path: "album",
      name: "ConsignmentAlbum",
      meta: {
        title: "版权转让"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/consignmentSys/album"));
        });
      }
    }, {
      path: "blind",
      name: "ConsignmentBlind",
      meta: {
        title: "盲盒管理"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/consignmentSys/blind"));
        });
      }
    }],
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/auctionOrder"));
      });
    }
  }, {
    path: "detailed",
    name: "Detailed",
    meta: {
      title: "寄售明细"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/consignmentSys/detailed"));
      });
    }
  }, {
    path: "transaction",
    name: "Transaction",
    meta: {
      title: "交易设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/consignmentSys/transaction"));
      });
    }
  }, {
    path: "statistics",
    name: "Statistics",
    meta: {
      title: "统计分析"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/consignmentSys/statistics"));
      });
    }
  }, {
    path: "list",
    name: "OrderIndex",
    meta: {
      title: "货品订单"
    },
    children: [{
      path: "auctionMeeting",
      name: "AuctionMeetingOrder",
      meta: {
        title: "订单管理"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/goodsOrder/auctionOrder"));
        });
      }
    }, {
      path: "all",
      name: "AllOrder",
      meta: {
        title: "订单管理",
        activeMenu: "".concat(_settings.roterPre, "/order/list/auctionMeeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/order/index"));
        });
      }
    }],
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/goodsOrder/index"));
      });
    }
  }, {
    path: "refund",
    name: "OrderRefund",
    meta: {
      title: "退款单"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/orderRefund/index"));
      });
    }
  }, {
    path: "invoice",
    name: "OrderInvoice",
    meta: {
      title: "发票管理"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/orderInvoice/index"));
      });
    }
  }, {
    path: "cancellation",
    name: "OrderCancellation",
    meta: {
      title: "核销订单"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/orderCancellate/index"));
      });
    }
  }]
};
var _default = exports.default = orderRouter;