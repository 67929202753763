"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createGuardApi = createGuardApi;
exports.getAnimalsListApi = getAnimalsListApi;
exports.getGroundInfoApi = getGroundInfoApi;
exports.getGroundListApi = getGroundListApi;
exports.getGuardInfoApi = getGuardInfoApi;
exports.getInfoApi = getInfoApi;
exports.getSkinApi = getSkinApi;
exports.getSlotListApi = getSlotListApi;
exports.getUserInnerListApi = getUserInnerListApi;
exports.handleAnimalsApi = handleAnimalsApi;
exports.handleGroundApi = handleGroundApi;
exports.handleSlotApi = handleSlotApi;
exports.isHiddenApi = isHiddenApi;
exports.isShowApi = isShowApi;
exports.produceCreateApi = produceCreateApi;
exports.produceInfoApi = produceInfoApi;
exports.produceListApi = produceListApi;
exports.slotInfoApi = slotInfoApi;
exports.updateOrCreateApi = updateOrCreateApi;
var _request = _interopRequireDefault(require("./request"));
//添加产出
function produceCreateApi(data) {
  if (data.id) {
    return _request.default.post("activity/sys/animals/produceEdit", data);
  } else {
    return _request.default.post("activity/sys/animals/produceCreate", data);
  }
}
//获取产出列表
function produceListApi(data) {
  return _request.default.get("activity/sys/animals/produceList", data);
}
//获取产出详情
function produceInfoApi(id) {
  return _request.default.get("activity/sys/animals/produceInfo/".concat(id));
}
//创建神兽
function handleAnimalsApi(data) {
  if (data.id) {
    return _request.default.post("activity/sys/animals/edit", data);
  } else {
    return _request.default.post("activity/sys/animals/create", data);
  }
}

//神兽列表
function getAnimalsListApi(data) {
  return _request.default.get("activity/sys/animals/getAnimalsList", data);
}

//查看神兽详情
function getInfoApi(id) {
  return _request.default.get("activity/sys/animals/getInfo/".concat(id));
}

//创建卡槽
function handleSlotApi(data) {
  if (data.id) {
    return _request.default.post("activity/sys/animals/slotEdit", data);
  } else {
    return _request.default.post("activity/sys/animals/slotCreate", data);
  }
}
//查看卡槽详情
function slotInfoApi(id) {
  return _request.default.get("activity/sys/animals/slotInfo/".concat(id));
}

//卡槽列表
function getSlotListApi(data) {
  return _request.default.get("activity/sys/animals/slotList", data);
}

//空投和合成是否显示添加内丹
function isShowApi(data) {
  return _request.default.get("activity/sys/animals/isShow", data);
}

//创建土地
function handleGroundApi(data) {
  if (data.id) {
    return _request.default.post("activity/sys/ground/editGround", data);
  } else {
    return _request.default.post("activity/sys/ground/create", data);
  }
}

//获取土地列表
function getGroundListApi(data) {
  return _request.default.get("activity/sys/ground/getGroundList", data);
}

//获取土地详情

function getGroundInfoApi(id) {
  return _request.default.get("activity/sys/ground/getGroundInfo/".concat(id));
}

//创建守卫和肥料
function createGuardApi(data) {
  return _request.default.post("activity/sys/ground/create/guard", data);
}
//查询守卫和肥料详情
function getGuardInfoApi(data) {
  return _request.default.get("activity/sys/ground/getGuardInfo", data);
}

//获取游戏图片
function getSkinApi(data) {
  return _request.default.get("activity/sys/animals/getSkin", data);
}
//保存游戏图片
function updateOrCreateApi(data) {
  return _request.default.post("activity/sys/animals/updateOrCreate", data);
}

//是否显示土地和守卫
function isHiddenApi(data) {
  return _request.default.get("activity/sys/animals/isHidden", data);
}

//数据统计接口
function getUserInnerListApi(data) {
  return _request.default.get("activity/sys/animals/getUserInnerList", data);
}