var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTypeAction },
              model: {
                value: _vm.types,
                callback: function ($$v) {
                  _vm.types = $$v
                },
                expression: "types",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "作品", name: "works" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "专辑", name: "album" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "盲盒", name: "blind" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tab flex align-items-c" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-position": "left",
                    "label-width": "60px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "status", $$v)
                            },
                            expression: "queryParams.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: null } }, [
                            _vm._v("全部 "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.all
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: true } }, [
                            _vm._v("开启寄售 "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.waitCount
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: false } }, [
                            _vm._v("关闭寄售 "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.overCount
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tab flex align-items-c mt10" },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { size: "small", placeholder: "盲盒名称" },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.queryParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "name", $$v)
                    },
                    expression: "queryParams.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.goSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "order-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.image },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "盲盒名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.name))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发行价" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v("￥" + _vm._s(row.price))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发行数量" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.issuedCount))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "持仓用户" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.holderAction(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.positionUserCount) + " 人")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "持仓作品" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(_vm._s(row.positionProductCount)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "寄售用户" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.userAction(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.totalUser))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "寄售数量" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.quantity))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("formatStatus")(row.status))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { "min-width": "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _vm._v("\n            服务费\n            "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "快照名单有重复时，按最低费率计算",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._l(scope.row.charges, function (val) {
                              return _c("div", [
                                val.type == 0
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticStyle: { width: "130px" },
                                            attrs: { size: "mini" },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.chargeRateAction(val)
                                              },
                                            },
                                            model: {
                                              value: val.chargeRate,
                                              callback: function ($$v) {
                                                _vm.$set(val, "chargeRate", $$v)
                                              },
                                              expression: "val.chargeRate",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "append" }, [
                                              _vm._v("%"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "mt10 w100",
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                            attrs: {
                                              size: "mini",
                                              placeholder: "快照名单",
                                              clearable: "",
                                            },
                                            model: {
                                              value: val.snapshotId,
                                              callback: function ($$v) {
                                                _vm.$set(val, "snapshotId", $$v)
                                              },
                                              expression: "val.snapshotId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.snapshotList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  value: item.id,
                                                  label: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-input",
                                          {
                                            staticStyle: { width: "130px" },
                                            attrs: { size: "mini" },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.chargeRateAction(val)
                                              },
                                            },
                                            model: {
                                              value: val.chargeRate,
                                              callback: function ($$v) {
                                                _vm.$set(val, "chargeRate", $$v)
                                              },
                                              expression: "val.chargeRate",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "append" }, [
                                              _vm._v("%"),
                                            ]),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              display: "inline-block",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.chargeDeleteAction(
                                                  val,
                                                  _vm.index,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    ),
                              ])
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addChargeRate(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("+添加规则")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分佣比例", "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _vm._v("\n            分佣比例\n            "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "该比例是对服务费中的分成比例",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "110px" },
                                attrs: { size: "mini" },
                                on: {
                                  blur: function ($event) {
                                    return _vm.chargeCommisionRateAction(
                                      scope.row
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.commissionRatio,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "commissionRatio", $$v)
                                  },
                                  expression: "scope.row.commissionRatio",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("%"),
                                ]),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "限价设置", "min-width": "680" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._l(scope.row.pirceLimits, function (val) {
                              return _c("div", [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "flex-shrink": "0",
                                          "margin-right": "5px",
                                        },
                                      },
                                      [_vm._v("寄售类型")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { flex: "1" } },
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeListTypeAction(
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.marketSelectType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "marketSelectType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.marketSelectType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: 1,
                                                  indeterminate: false,
                                                },
                                              },
                                              [_vm._v("购买")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: 2,
                                                  indeterminate: false,
                                                },
                                              },
                                              [_vm._v("空投")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: 3,
                                                  indeterminate: false,
                                                },
                                              },
                                              [_vm._v("免费领取")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: 4,
                                                  indeterminate: false,
                                                },
                                              },
                                              [_vm._v("寄售购买")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: 5,
                                                  indeterminate: false,
                                                },
                                              },
                                              [_vm._v("合成")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: 6,
                                                  indeterminate: false,
                                                },
                                              },
                                              [_vm._v("兑换码")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: 7,
                                                  indeterminate: false,
                                                },
                                              },
                                              [_vm._v("拉新互助领取")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: 8,
                                                  indeterminate: false,
                                                },
                                              },
                                              [_vm._v("抽奖")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("span", [_vm._v("开始时间")]),
                                    _vm._v(" "),
                                    _c("el-date-picker", {
                                      staticClass: "ml5 mr5",
                                      staticStyle: { width: "190px" },
                                      attrs: {
                                        type: "datetime",
                                        size: "mini",
                                        format: "yyyy-MM-dd HH:mm:ss",
                                        "value-format": "yyyy-MM-dd HH:mm:ss",
                                        placeholder: "选择开始时间",
                                      },
                                      on: {
                                        change: function (time) {
                                          return _vm.limitsAction(val)
                                        },
                                      },
                                      model: {
                                        value: val.startDate,
                                        callback: function ($$v) {
                                          _vm.$set(val, "startDate", $$v)
                                        },
                                        expression: "val.startDate",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "ml5 mr5",
                                        staticStyle: { width: "100px" },
                                        attrs: { size: "mini" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.limitsAction(val)
                                          },
                                        },
                                        model: {
                                          value: val.getType,
                                          callback: function ($$v) {
                                            _vm.$set(val, "getType", $$v)
                                          },
                                          expression: "val.getType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "全部", value: "" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "购买", value: 1 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "空投", value: 2 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "免费领取",
                                            value: 3,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "寄售购买",
                                            value: 4,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "合成", value: 5 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "兑换码", value: 6 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "拉新互助领取",
                                            value: 7,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "抽奖", value: 8 },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w100 ml5 mr5",
                                      attrs: {
                                        size: "mini",
                                        placeholder: "最低限价",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.limitsAction(val)
                                        },
                                      },
                                      model: {
                                        value: val.lowestLimit,
                                        callback: function ($$v) {
                                          _vm.$set(val, "lowestLimit", $$v)
                                        },
                                        expression: "val.lowestLimit",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w100 ml5 mr5",
                                      attrs: {
                                        size: "mini",
                                        placeholder: "最高限价",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.limitsAction(val)
                                        },
                                      },
                                      model: {
                                        value: val.highestLimit,
                                        callback: function ($$v) {
                                          _vm.$set(val, "highestLimit", $$v)
                                        },
                                        expression: "val.highestLimit",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          display: "inline-block",
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.limitsDeleteAction(
                                              val,
                                              _vm.index,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPriceLimits(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("+添加规则")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ml10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.statusAction(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(row.status ? "关闭" : "开启") +
                                        "寄售"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downAction(row)
                                      },
                                    },
                                  },
                                  [_vm._v("下架所有藏品")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 flex justify-e" },
                [
                  _c("div", { staticClass: "flex-one align-items-c flex" }),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                      "page-size": _vm.queryParams.pageSize,
                      "current-page": _vm.queryParams.pageNum,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleUser
        ? _c("User", {
            attrs: { visibleUser: _vm.visibleUser, productId: _vm.productId },
            on: { userClose: _vm.userClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleHolder
        ? _c("Holder", {
            attrs: {
              visibleHolder: _vm.visibleHolder,
              types: 1,
              productId: _vm.productId,
            },
            on: { holderClose: _vm.holderClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }