var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "收款结算单",
        width: "70%",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("span", [_vm._v("买家：")]),
        _vm._v(" "),
        _c("span", { staticStyle: { color: "#347FF4" } }, [
          _vm._v(_vm._s(_vm.user.nickname)),
        ]),
        _vm._v(" "),
        _vm.userNumberPlate
          ? _c("span", { staticClass: "ml30" }, [_vm._v("号牌：")])
          : _vm._e(),
        _vm._v(" "),
        _vm.userNumberPlate
          ? _c("span", [_vm._v(_vm._s(_vm.userNumberPlate.number))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.collectMoney.show &&
      _vm.collectMoney.data &&
      _vm.collectMoney.data.orderList
        ? [
            _c(
              "el-table",
              {
                staticClass: "collect-money mt20",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.collectMoney.data.orderList,
                  size: "mini",
                  "show-summary": "",
                  "highlight-current-row": "",
                  "summary-method": _vm.getSummaries,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "图片", "min-width": "50", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "item-flex-center" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  display: "block",
                                },
                                attrs: {
                                  src: scope.row.auction_info.image + "!120a",
                                  alt: "",
                                },
                              }),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4205239616
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "图录号", "min-width": "60" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(scope.row.auction_info.lot)),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1295689138
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "拍品名称", "min-width": "240" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(scope.row.auction_info.store_name)),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    67020514
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "product_price",
                    label: "落槌价",
                    "min-width": "90",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.product_price,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4220516122
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "commission_price",
                    label: "佣金",
                    "min-width": "90",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.commission_price,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    563103612
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "total_postage",
                    label: "运费",
                    "min-width": "90",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.total_postage,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2645342533
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "admin_discount_price",
                    label: "优惠",
                    "min-width": "90",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticStyle: { color: "#FFA500" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.admin_discount_price,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    380235509
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "pay_price",
                    label: "金额",
                    "min-width": "90",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(scope.row.pay_price, 2)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1974200345
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "deduct mt20" }, [
              _c("div", { staticClass: "item item-align-center" }, [
                _c(
                  "div",
                  { staticClass: "td", staticStyle: { width: "300px" } },
                  [
                    _vm._v("买家账户余额："),
                    _c("span", { staticClass: "fw500" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.collectMoney.data.userWalletInfo.balance,
                            2
                          )
                        )
                      ),
                    ]),
                    _vm.collectMoney.data.availableFrozen &&
                    _vm.collectMoney.data.availableFrozen.price
                      ? _c("span", [
                          _vm._v(
                            "(含冻结：" +
                              _vm._s(
                                _vm.formatPriceNumber(
                                  _vm.collectMoney.data.availableFrozen.price,
                                  2
                                )
                              ) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "td" }, [_vm._v("扣款金额：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "td" },
                  [
                    _c("el-input-number", {
                      staticClass: "inp",
                      attrs: {
                        controls: false,
                        min: 0,
                        max: _vm.collectMoney.data.userWalletInfo.balance,
                      },
                      model: {
                        value: _vm.use_wallet_price,
                        callback: function ($$v) {
                          _vm.use_wallet_price = $$v
                        },
                        expression: "use_wallet_price",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "td1" }, [
                  _vm._v("注意：输入金额后，将从买家账户里扣除相应金额"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item item-align-center" }, [
                _c(
                  "div",
                  { staticClass: "td", staticStyle: { width: "300px" } },
                  [
                    _vm._v("委托方账户余额："),
                    _c("span", { staticClass: "fw500" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.collectMoney.data.entrustWalletInfo.balance,
                            2
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "td" }, [_vm._v("扣款金额：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "td" },
                  [
                    _c("el-input-number", {
                      staticClass: "inp",
                      attrs: {
                        controls: false,
                        min: 0,
                        max: _vm.collectMoney.data.entrustWalletInfo.balance,
                      },
                      model: {
                        value: _vm.use_entrust_wallet_price,
                        callback: function ($$v) {
                          _vm.use_entrust_wallet_price = $$v
                        },
                        expression: "use_entrust_wallet_price",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "td1" }, [
                  _vm._v("注意：输入金额后，将从委托方账户里扣除相应金额"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item item-align-center",
                  staticStyle: { "border-left-color": "#228B22" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "td", staticStyle: { width: "300px" } },
                    [_vm._v("线下追回收款：")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "td" }, [_vm._v("收款金额：")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "td price" }, [
                    _vm._v(_vm._s(_vm.pay_money)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "td1" }, [
                    _vm._v("注意：请确认您已经通过线下追回了此项收款"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "mt20",
                attrs: { type: "primary" },
                on: { click: _vm.payAction },
              },
              [_vm._v("确认结算")]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { height: "40px" } }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }