var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "min-height": "85vh" } },
        [
          _c(
            "el-form",
            { attrs: { size: "small", inline: "", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部\n            "),
                        _vm.statistical.all
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "(" +
                                    (_vm.statistical.all
                                      ? _vm.statistical.all
                                      : 0) +
                                    ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("待付款\n            "),
                        _vm.statistical.noBuyCount
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "(" +
                                    (_vm.statistical.noBuyCount
                                      ? _vm.statistical.noBuyCount
                                      : 0) +
                                    ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("待交付\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              "(" +
                                (_vm.statistical.waitCount
                                  ? _vm.statistical.waitCount
                                  : 0) +
                                ")"
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "4" } }, [
                        _vm._v("已完成\n            "),
                        _vm.statistical.overCount
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "(" +
                                    (_vm.statistical.overCount
                                      ? _vm.statistical.overCount
                                      : 0) +
                                    ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "-1" } }, [
                        _vm._v("已取消\n            "),
                        _vm.statistical.cancelCount
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "(" +
                                    (_vm.statistical.cancelCount
                                      ? _vm.statistical.cancelCount
                                      : 0) +
                                    ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.associationId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "associationId", $$v)
                        },
                        expression: "queryParams.associationId",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.productList, function (item, index) {
                        return _c(
                          "el-radio-button",
                          {
                            key: index,
                            attrs: { label: item.productPackageId },
                          },
                          [_vm._v(_vm._s(item.packageName))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "时间选择：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function () {
                          _vm.timeType = []
                          _vm.getTableList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "timeType", $$v)
                        },
                        expression: "queryParams.timeType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "today" } }, [
                        _vm._v("今天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "yesterday" } }, [
                        _vm._v("昨天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "lately7" } }, [
                        _vm._v("最近7天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "lately30" } }, [
                        _vm._v("最近30天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "month" } }, [
                        _vm._v("本月"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "year" } }, [
                        _vm._v("本年"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      "value-format": "yyyy/MM/dd",
                      format: "yyyy/MM/dd",
                      size: "small",
                      type: "daterange",
                      placement: "bottom-end",
                      "start-placeholder": "选择开始时间",
                      "end-placeholder": "选择结束时间",
                    },
                    on: { change: _vm.onchangeTime },
                    model: {
                      value: _vm.timeType,
                      callback: function ($$v) {
                        _vm.timeType = $$v
                      },
                      expression: "timeType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "inputWidth",
                  attrs: { size: "small", placeholder: "产品服务名称" },
                  on: {
                    change: function ($event) {
                      return _vm.getTableList(1)
                    },
                  },
                  model: {
                    value: _vm.queryParams.productName,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "productName", $$v)
                    },
                    expression: "queryParams.productName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getTableList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "inputWidth",
                  attrs: { size: "small", placeholder: "客户名称/手机号" },
                  on: {
                    change: function ($event) {
                      return _vm.getTableList(1)
                    },
                  },
                  model: {
                    value: _vm.queryParams.memberNameOrPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "memberNameOrPhone", $$v)
                    },
                    expression: "queryParams.memberNameOrPhone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getTableList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "inputWidth",
                  attrs: { size: "small", placeholder: "订单编号" },
                  on: {
                    change: function ($event) {
                      return _vm.getTableList(1)
                    },
                  },
                  model: {
                    value: _vm.queryParams.orderSn,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderSn", $$v)
                    },
                    expression: "queryParams.orderSn",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getTableList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "inputWidth",
                  attrs: { size: "small", placeholder: "支付订单号" },
                  on: {
                    change: function ($event) {
                      return _vm.getTableList(1)
                    },
                  },
                  model: {
                    value: _vm.queryParams.payOrderSn,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "payOrderSn", $$v)
                    },
                    expression: "queryParams.payOrderSn",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getTableList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "inputWidth",
                  attrs: { size: "small", placeholder: "支付渠道" },
                  on: {
                    change: function ($event) {
                      return _vm.getTableList(1)
                    },
                  },
                  model: {
                    value: _vm.queryParams.payType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "payType", $$v)
                    },
                    expression: "queryParams.payType",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getTableList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "18px 0" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addOrder },
                },
                [_vm._v("创建订单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary", size: "small" },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c("el-button", { attrs: { size: "small", type: "text" } }, [
                _vm._v("查看导出记录"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "订单编号", prop: "id", "min-width": "170" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "客户手机号",
                  prop: "phone",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "客户姓名", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.openUserDetail(scope.row.userId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.merName || scope.row.phone))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品类型",
                  prop: "productName",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "版本",
                  prop: "versionName",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "选配功能", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(row.selectMatchProductCount + "项")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "定制需求", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.customizationProductCount ? "有" : "-")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "总价", prop: "", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(scope.row.shouldPrice, 2)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "优惠减免", prop: "", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.formatPriceNumber(scope.row.subPrice, 2))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "实际支付",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(scope.row.realPrice, 2)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "核心Builder",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return undefined
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "普通Builder",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return undefined
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "订单状态",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("formatStatus")(scope.row.status))
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.status == -2
                          ? _c("div", [
                              _vm._v(
                                "\n            ￥" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      scope.row.refundPrice,
                                      2
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "支付状态",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.hasPaid ? "已支付" : "未支付")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "支付方式",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.payTypeName))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "支付订单号",
                  "min-width": "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.payOrderSn))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "支付时间",
                  "min-width": "150",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.payTime))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  "min-width": "150",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.createTime))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status === 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0 p0",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCancel(row)
                                  },
                                },
                              },
                              [_vm._v("取消订单")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("el-pagination", {
                staticClass: "mt20",
                attrs: {
                  "page-sizes": [10, 20, 40, 80],
                  "page-size": _vm.queryParams.pageSize,
                  "current-page": _vm.queryParams.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "创建订单",
            visible: _vm.drawerVisib,
            direction: "rtl",
            "append-to-body": "",
            size: "40%",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisib = $event
            },
          },
        },
        [
          _c("orderDetail", {
            on: { changeDrawerVisib: _vm.changeDrawerVisib },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? _c("userdetail", {
            attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
            on: {
              close: function ($event) {
                _vm.showdetail = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }