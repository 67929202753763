"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _inviteUser = _interopRequireDefault(require("./inviteUser"));
var _scaleAllMoney = _interopRequireDefault(require("./scaleAllMoney"));
var _alreadyMoney = _interopRequireDefault(require("./alreadyMoney"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Invitation",
  components: {
    userdetail: _userdetail.default,
    InviteUser: _inviteUser.default,
    ScaleAllMoney: _scaleAllMoney.default,
    AlreadyMoney: _alreadyMoney.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      types: "invitation",
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20
      },
      showdetail: false,
      uid: null,
      inviteParams: {
        pageNum: 1,
        pageSize: 20,
        id: "",
        status: ""
      },
      visibleUser: false,
      visibleTitle: "",
      visibleUserBuy: false,
      inviteParamsBuy: {
        pageNum: 1,
        pageSize: 20,
        userId: ""
      },
      visibleAlready: false,
      inviteParamsAlready: {
        pageNum: 1,
        pageSize: 20,
        userId: ""
      },
      inviteList: {
        data: [],
        total: 0
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(44);
    },
    derive: function derive() {
      var _this = this;
      var data = {
        nameOrPhone: this.queryParams.nameOrPhone,
        startTime: this.queryParams.startTime,
        endTime: this.queryParams.endTime
      };
      (0, _user.inviteExport)(data).then(function (res) {
        _this.$message.success("导出成功");
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "invitation") {
        this.$router.push("/merchant/user/invitation");
        return false;
      } else if (tab.name === "invitationSet") {
        this.$router.push("/merchant/user/invitationSet");
        return false;
      }
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    onchangeTime: function onchangeTime(val) {
      if (val) {
        this.queryParams.startTime = val[0];
        this.queryParams.endTime = val[1];
        this.getList(1);
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
        this.getList(1);
      }
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    nickNameClick: function nickNameClick(row) {
      this.uid = Number(row.userId);
      this.showdetail = true;
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _user.inviteListApi)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.data.forEach(function (item) {
          item.memberTagsName = "";
          if (item.memberTags) {
            item.memberTags.forEach(function (element) {
              item.memberTagsName = item.memberTagsName + element.name + "、";
            });
            item.memberTagsName = item.memberTagsName.substring(0, item.memberTagsName.length - 1);
          }
        });
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList("");
    },
    getInviteeList: function getInviteeList(row, status) {
      this.visibleUser = true;
      this.inviteParams.status = status;
      this.inviteParams.id = row.inviterId;
      if (status) {
        this.visibleTitle = "\u5B9E\u540D\u4EBA\u6570\uFF1A\u5171".concat(row.realNameNum, "\u4E2A\u7528\u6237");
      } else {
        this.visibleTitle = "\u9080\u8BF7\u4EBA\u6570\uFF1A\u5171".concat(row.inviteNum, "\u4E2A\u7528\u6237");
      }
    },
    visibleClose: function visibleClose(data) {
      this.visibleUser = data;
    },
    visibleCloseBuy: function visibleCloseBuy(data) {
      this.visibleUserBuy = data;
    },
    visibleCloseAlready: function visibleCloseAlready(data) {
      this.visibleAlready = data;
    },
    getScaleAllMoney: function getScaleAllMoney(row) {
      this.visibleUserBuy = true;
      this.inviteParamsBuy.userId = row.inviterId;
    },
    getAlreadyMoney: function getAlreadyMoney(row) {
      this.visibleAlready = true;
      this.inviteParamsAlready.userId = row.inviterId;
      this.inviteParamsAlready.alreadyMoney = row.alreadyMoney;
    }
  }
};