var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt10" },
    [
      _c(
        "el-form",
        {
          attrs: { size: "small", "label-width": "0px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("el-form-item", { attrs: { label: " " } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("span", { staticClass: "fWeight500" }, [
                  _vm._v("抽奖活动："),
                ]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "mr20",
                    attrs: { type: "button", size: "small", clearable: "" },
                    on: {
                      change: function ($event) {
                        return _vm.selectChange(_vm.tableFromIssue.date)
                      },
                    },
                    model: {
                      value: _vm.tableFromIssue.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "date", $$v)
                      },
                      expression: "tableFromIssue.date",
                    },
                  },
                  _vm._l(_vm.fromList.fromTxt, function (item, i) {
                    return _c(
                      "el-radio-button",
                      { key: i, attrs: { label: item.val } },
                      [_vm._v(_vm._s(item.text))]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c("el-date-picker", {
                  staticStyle: { width: "250px" },
                  attrs: {
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy-MM-dd",
                    size: "small",
                    type: "daterange",
                    placement: "bottom-end",
                    placeholder: "自定义时间",
                    clearable: "",
                  },
                  on: { change: _vm.onchangeTime },
                  model: {
                    value: _vm.timeVal,
                    callback: function ($$v) {
                      _vm.timeVal = $$v
                    },
                    expression: "timeVal",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: " " } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("span", { staticClass: "fWeight500" }, [
                  _vm._v("抽奖活动："),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      remote: "",
                      "reserve-keyword": "",
                      placeholder: "请输入关键词",
                      "remote-method": _vm.searchDrawAction,
                      loading: _vm.loadingSeach,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.pageChangeIssue(1)
                      },
                    },
                    model: {
                      value: _vm.tableFromIssue.luckyDrawId,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "luckyDrawId", $$v)
                      },
                      expression: "tableFromIssue.luckyDrawId",
                    },
                  },
                  _vm._l(_vm.drawList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.luckyName, value: item.id },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "fWeight500 ml20" }, [
                  _vm._v("用户手机号："),
                ]),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    staticClass: "w200 mr20",
                    attrs: { placeholder: "用户名称/手机号" },
                    model: {
                      value: _vm.tableFromIssue.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "phone", $$v)
                      },
                      expression: "tableFromIssue.phone",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: {
                        click: function ($event) {
                          return _vm.pageChangeIssue(1)
                        },
                      },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: " " } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.exportOrder },
                  },
                  [_vm._v("导出")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml20",
                    attrs: { size: "small", type: "text" },
                    on: { click: _vm.getExportFileList },
                  },
                  [_vm._v("查看导出记录")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.Loading,
              expression: "Loading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.issueData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "title", label: "用户信息", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(scope.row.userName + "/" + scope.row.userPhone)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动名称", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.luckyDrawName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品类型", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.prizeType == 0
                      ? _c("div", [_vm._v("谢谢参与")])
                      : scope.row.prizeType == 1
                      ? _c("div", [_vm._v("藏品")])
                      : scope.row.prizeType == 2
                      ? _c("div", [_vm._v("盲盒")])
                      : scope.row.prizeType == 3
                      ? _c("div", [_vm._v("积分")])
                      : scope.row.prizeType == 4
                      ? _c("div", [_vm._v("优惠券")])
                      : scope.row.prizeType == 5
                      ? _c("div", [_vm._v("实物")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品名称", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.prizeName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "消耗材料", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.consume))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "快递信息", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.prizeType == 5
                      ? [
                          _c(
                            "div",
                            { staticClass: "color-red" },
                            [
                              _vm._v(
                                _vm._s(
                                  scope.row.isToSend == 0 ? "未发货" : "已发货"
                                ) + " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ml10",
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editDeliveryAction(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.isToSend == 0
                                        ? "点击发货"
                                        : "修改物流"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          scope.row.isToSend == 1
                            ? _c("div", [_vm._v(_vm._s(scope.row.expressInfo))])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.getUserInfo
                            ? _c("div", [_vm._v(_vm._s(scope.row.getUserInfo))])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.address
                            ? _c("div", [_vm._v(_vm._s(scope.row.address))])
                            : _vm._e(),
                        ]
                      : _c("div", [_vm._v(" - ")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换时间", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticClass: "mt20",
            staticStyle: { "text-align": "right" },
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.tableFromIssue.pageSize,
              "current-page": _vm.tableFromIssue.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.issueData.total,
            },
            on: {
              "size-change": _vm.handleSizeChangeIssue,
              "current-change": _vm.pageChangeIssue,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleShip
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleShip,
                "close-on-click-modal": false,
                title: "发货",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleShip = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "shipForm",
                  staticClass: "mt20",
                  attrs: {
                    size: "small",
                    model: _vm.shipForm,
                    rules: _vm.shipRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递公司", prop: "expressCompany" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择快递公司",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.shipForm.expressCompany,
                            callback: function ($$v) {
                              _vm.$set(_vm.shipForm, "expressCompany", $$v)
                            },
                            expression: "shipForm.expressCompany",
                          },
                        },
                        _vm._l(_vm.dcList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号", prop: "expressNumber" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.shipForm.expressNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipForm, "expressNumber", $$v)
                          },
                          expression: "shipForm.expressNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmShipAction("shipForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }