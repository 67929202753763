"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _writeOff = require("@/api/writeOff");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Verifier",
  data: function data() {
    return {
      dialogVisible: false,
      userId: "",
      tableData: {
        data: [],
        total: 0
      },
      userList: [],
      columnData: [{
        label: "ID",
        prop: "userId"
      }, {
        label: "头像",
        prop: "avatar"
      }, {
        label: "核销员名称",
        prop: "nickName"
      }, {
        label: "创建时间",
        prop: "createTime"
      }, {
        label: "操作",
        prop: "handle"
      }],
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      userLoading: false,
      listLoading: false
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var pageNum = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      this.listLoading = true;
      var data = {
        pageNum: pageNum,
        pageSize: this.queryParams.pageSize,
        hasTicketChecker: 1
      };
      (0, _writeOff.allList)(data).then(function (res) {
        console.log(res);
        _this.tableData.total = res.total || 0;
        _this.tableData.data = res.rows || [];
        _this.listLoading = false;
      }).catch(function (err) {
        _this.listLoading = false;
      });
    },
    deleteItem: function deleteItem(id) {
      var _this2 = this;
      (0, _writeOff.delTicketChecker)(id).then(function (res) {
        console.log(res);
        _this2.$message.success("删除成功");
        _this2.getList(1);
      });
    },
    addUser: function addUser() {
      var _this3 = this;
      (0, _writeOff.addTicketChecker)(this.userId).then(function (res) {
        _this3.userId = "";
        _this3.dialogVisible = false;
        _this3.$message.success("添加成功");
        _this3.getList(1);
      }).catch(function (err) {
        _this3.$message.error(err.message);
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this4 = this;
      this.userLoading = true;
      var params = {
        nameOrPhone: data,
        pageNum: 1,
        pageSize: 20
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this4.userLoading = false;
        _this4.userList = res.rows;
      });
    }
  }
};