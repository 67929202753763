var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "padding20 bg-white borderRadius6",
          staticStyle: { "min-height": "100vh" },
        },
        [
          _c(
            "div",
            {
              staticClass: "remove-control form-view",
              staticStyle: { position: "relative" },
            },
            [
              _vm.ruleForm.luckyType == 0
                ? _c("img", {
                    staticClass: "sm1",
                    attrs: {
                      src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2022129/image/1670561004711569381.png!m640",
                      alt: "",
                    },
                  })
                : _vm.ruleForm.luckyType == 1
                ? _c("img", {
                    staticClass: "sm1",
                    attrs: {
                      src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2022129/image/1670561004271642203.png!m640",
                      alt: "",
                    },
                  })
                : _vm.ruleForm.luckyType == 2
                ? _c("img", {
                    staticClass: "sm1",
                    attrs: {
                      src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/2022129/image/1670561003788492242.png!m640",
                      alt: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "抽奖名称：", prop: "luckyName" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: {
                              placeholder: "请输入抽奖名称",
                              disabled: _vm.noEdit,
                            },
                            model: {
                              value: _vm.ruleForm.luckyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "luckyName", $$v)
                              },
                              expression: "ruleForm.luckyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间：", prop: "assistDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          align: "right",
                          disabled: _vm.noEdit,
                        },
                        model: {
                          value: _vm.ruleForm.assistDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "assistDate", $$v)
                          },
                          expression: "ruleForm.assistDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "抽奖形式：",
                        prop: "luckyType",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.noEdit },
                          on: { change: _vm.changeLuckyType },
                          model: {
                            value: _vm.ruleForm.luckyType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "luckyType", $$v)
                            },
                            expression: "ruleForm.luckyType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("九宫格"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("大转盘"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("泡泡机（可一键多抽）"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片上传：", required: "" } },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c("div", { staticClass: "img-item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap(
                                    "subjectBackgroundUrl",
                                    ""
                                  )
                                },
                              },
                            },
                            [
                              _vm.ruleForm.subjectBackgroundUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.ruleForm.subjectBackgroundUrl +
                                          "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "txt" }, [
                            _vm._v("主背景图"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-item ml20" }, [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("startButtonUrl", "")
                                },
                              },
                            },
                            [
                              _vm.ruleForm.startButtonUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.ruleForm.startButtonUrl + "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "txt" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.ruleForm.luckyType == 2
                                    ? "抓手绳索图"
                                    : "开始抽奖按钮"
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "txt1" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.ruleForm.luckyType == 2 ? "" : "1:1正方形"
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.ruleForm.luckyType == 2
                          ? _c("div", { staticClass: "img-item ml20" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("platFormUrl", "")
                                    },
                                  },
                                },
                                [
                                  _vm.ruleForm.platFormUrl
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.ruleForm.platFormUrl +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("抓手台面图"),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.ruleForm.luckyType == 2
                          ? _c("div", { staticClass: "img-item ml20" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("clawUrl", "")
                                    },
                                  },
                                },
                                [
                                  _vm.ruleForm.clawUrl
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.ruleForm.clawUrl + "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("抓手左勾手图"),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-item ml20" }, [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("blockBoxUrl", "")
                                },
                              },
                            },
                            [
                              _vm.ruleForm.blockBoxUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.ruleForm.blockBoxUrl + "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.ruleForm.luckyType == 0
                            ? _c("div", { staticClass: "txt" }, [
                                _vm._v("宫格图"),
                              ])
                            : _vm.ruleForm.luckyType == 1
                            ? _c("div", { staticClass: "txt" }, [
                                _vm._v(
                                  "\n                转盘图\n              "
                                ),
                              ])
                            : _vm.ruleForm.luckyType == 2
                            ? _c("div", { staticClass: "txt" }, [
                                _vm._v(
                                  "\n                奖球泡泡图\n              "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "txt1" }, [
                            _vm._v("1:1正方形"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.ruleForm.luckyType != 1
                          ? _c("div", { staticClass: "img-item ml20" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "blockBoxGroundUrl",
                                        ""
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.ruleForm.blockBoxGroundUrl
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.ruleForm.blockBoxGroundUrl +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.ruleForm.luckyType == 2
                                        ? "奖池背景"
                                        : "宫格背景"
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt1" }, [
                                _vm._v("1:1正方形"),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex align-items-c" }, [
                    _c(
                      "div",
                      { staticClass: "w400" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "页面背景色：" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value: _vm.ruleForm.backGroundColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "backGroundColor", $$v)
                                },
                                expression: "ruleForm.backGroundColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w400" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "文字颜色：" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value: _vm.ruleForm.titleColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "titleColor", $$v)
                                },
                                expression: "ruleForm.titleColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex align-items-c" }, [
                    _c(
                      "div",
                      { staticClass: "w400" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "奖品名称：" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value: _vm.ruleForm.prizeColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "prizeColor", $$v)
                                },
                                expression: "ruleForm.prizeColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w400" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "选中发光色：" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value: _vm.ruleForm.choseColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "choseColor", $$v)
                                },
                                expression: "ruleForm.choseColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex align-items-c" }, [
                    _c(
                      "div",
                      { staticClass: "w400" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "按钮背景：" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value: _vm.ruleForm.buttonColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "buttonColor", $$v)
                                },
                                expression: "ruleForm.buttonColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w400" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "按钮文字：" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value: _vm.ruleForm.buttonTitleColor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "buttonTitleColor",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.buttonTitleColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "展示参与人数" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                          },
                          model: {
                            value: _vm.ruleForm.isShowPeople,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "isShowPeople", $$v)
                            },
                            expression: "ruleForm.isShowPeople",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ml20" },
                          [
                            _c("span", [_vm._v("展示获奖通知：")]),
                            _vm._v(" "),
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#ccc",
                              },
                              model: {
                                value: _vm.ruleForm.isShowScreen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "isShowScreen", $$v)
                                },
                                expression: "ruleForm.isShowScreen",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "奖品设置：", required: "" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table",
                          staticStyle: { width: "100%", "margin-top": "-7px" },
                          attrs: {
                            "tooltip-effect": "dark",
                            data: _vm.ruleForm.luckyPrizes,
                            size: "mini",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { width: "80", label: "序号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                奖品" +
                                        _vm._s(scope.$index + 1) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { width: "160", label: "奖品类型" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { disabled: _vm.noEdit },
                                        model: {
                                          value: scope.row.prizeType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "prizeType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.prizeType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            value: 0,
                                            label: "谢谢参与",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 1, label: "藏品" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 2, label: "盲盒" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 3, label: "积分" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 4, label: "优惠券" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 5, label: "实物" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 6, label: "其他" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { "min-width": "160", label: "奖品" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.prizeType != 0 &&
                                    scope.row.prizeType != 6 &&
                                    scope.row.prizeType != 3 &&
                                    scope.row.prizeType != 5
                                      ? _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            scope.row.productName
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "line1 mr10",
                                                    staticStyle: {
                                                      "max-width": "80%",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          scope.row.productName
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                  disabled: _vm.noEdit,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeProduct(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.productName
                                                      ? "更改"
                                                      : "选择"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.prizeType == 3
                                      ? _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "w100" },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "积分类型",
                                                      disabled: _vm.noEdit,
                                                    },
                                                    model: {
                                                      value: scope.row.objectId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "objectId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.objectId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.typeList,
                                                    function (m, i) {
                                                      return _c("el-option", {
                                                        key: "type" + i,
                                                        attrs: {
                                                          value: m.id,
                                                          label: m.name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              staticClass: "w200 ml10",
                                              attrs: { disabled: _vm.noEdit },
                                              model: {
                                                value: scope.row.scoreNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "scoreNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.scoreNum",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { width: "100", label: "奖品等级" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "upLoadPicBox",
                                        attrs: { title: "正方形图片" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalPicTap(
                                              "levelImage",
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        scope.row.levelImage
                                          ? _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      scope.row.levelImage +
                                                      "!120a",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "upLoad" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-camera cameraIconfont",
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { width: "100", label: "奖品图片" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "upLoadPicBox",
                                        attrs: { title: "正方形图片" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalPicTap(
                                              "prizeUrl",
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        scope.row.prizeUrl
                                          ? _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      scope.row.prizeUrl +
                                                      "!120a",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "upLoad" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-camera cameraIconfont",
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { width: "200", label: "奖品名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "输入名称",
                                        disabled: _vm.noEdit,
                                      },
                                      model: {
                                        value: scope.row.prizeName,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "prizeName", $$v)
                                        },
                                        expression: "scope.row.prizeName",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { width: "160", label: "奖品数量" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        placeholder: "输入数量",
                                        disabled: _vm.noEdit,
                                      },
                                      model: {
                                        value: scope.row.prizeNum,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "prizeNum", $$v)
                                        },
                                        expression: "scope.row.prizeNum",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _vm.ruleForm.luckyType == 2
                            ? _c("el-table-column", {
                                attrs: { width: "100", label: "设置" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.ruleForm.luckyPrizes.length > 1
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                    disabled: _vm.noEdit,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeLuckyPrizes(
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  935234780
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.luckyType == 2
                        ? _c(
                            "div",
                            { staticClass: "mt20" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", disabled: _vm.noEdit },
                                  on: { click: _vm.addLuckyPrizes },
                                },
                                [_vm._v("添加奖品")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "人群&次数：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.noEdit },
                          model: {
                            value: _vm.ruleForm.joinType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "joinType", $$v)
                            },
                            expression: "ruleForm.joinType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不限人群"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("自定义人群次数"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: " " } }, [
                    _vm.ruleForm.joinType == 0
                      ? _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("div", [_vm._v("最多可抽")]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "w100 ml10",
                              attrs: {
                                controls: false,
                                min: 1,
                                disabled: _vm.noEdit,
                              },
                              model: {
                                value: _vm.ruleForm.joinNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "joinNum", $$v)
                                },
                                expression: "ruleForm.joinNum",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml10" }, [_vm._v("次")]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ruleForm.joinType == 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "uploadImg",
                                staticClass: "upload-demo mr10",
                                staticStyle: { display: "inline-block" },
                                attrs: {
                                  action: "#",
                                  limit: 1,
                                  multiple: "",
                                  "http-request": _vm.airdropExcels,
                                  disabled: _vm.noEdit,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("导入")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "text",
                                  disabled: _vm.noEdit,
                                },
                                on: { click: _vm.downLoad },
                              },
                              [_vm._v("下载导入模板")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "免费玩：" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": 1,
                            "inactive-value": 0,
                            disabled: _vm.noEdit,
                          },
                          model: {
                            value: _vm.ruleForm.isFreeJoin,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "isFreeJoin", $$v)
                            },
                            expression: "ruleForm.isFreeJoin",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml20" }, [
                          _vm._v("开局可免费玩"),
                        ]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "w100 ml10",
                          attrs: {
                            controls: false,
                            min: 1,
                            disabled: !_vm.ruleForm.isFreeJoin || _vm.noEdit,
                          },
                          model: {
                            value: _vm.ruleForm.freeNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "freeNum", $$v)
                            },
                            expression: "ruleForm.freeNum",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml10" }, [_vm._v("次")]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "抽奖规则：", required: "" } },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          { staticClass: "l" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled: _vm.noEdit,
                                  placeholder: "积分类型",
                                },
                                model: {
                                  value: _vm.ruleForm.singleRuleType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "singleRuleType",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.singleRuleType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: 0, label: "消耗积分" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: 1, label: "消耗藏品" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.ruleForm.singleRuleType == 0
                          ? _c(
                              "div",
                              { staticClass: "flex-one ml20" },
                              [
                                _c("span", [_vm._v("每次抽奖需消耗")]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w100 ml10",
                                    attrs: {
                                      disabled: _vm.noEdit,
                                      placeholder: "积分类型",
                                    },
                                    model: {
                                      value: _vm.ruleForm.singleScoreType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "singleScoreType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.singleScoreType",
                                    },
                                  },
                                  _vm._l(_vm.typeList, function (m, i) {
                                    return _c("el-option", {
                                      key: "type" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-input-number", {
                                  staticClass: "w100 ml10",
                                  attrs: {
                                    disabled: _vm.noEdit,
                                    controls: false,
                                    min: 1,
                                  },
                                  model: {
                                    value: _vm.ruleForm.singleScoreNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "singleScoreNum",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.singleScoreNum",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml10" }, [
                                  _vm._v("积分"),
                                ]),
                              ],
                              1
                            )
                          : _vm.ruleForm.singleRuleType == 1
                          ? _c("div", { staticClass: "flex-one ml20" }, [
                              _c(
                                "div",
                                { staticClass: "t" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: _vm.noEdit },
                                      model: {
                                        value:
                                          _vm.ruleForm.singleNftConsumeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "singleNftConsumeType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.singleNftConsumeType",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("全部藏品"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("部分藏品"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticClass: "ml20",
                                      attrs: { disabled: _vm.noEdit },
                                      model: {
                                        value: _vm.sOperate,
                                        callback: function ($$v) {
                                          _vm.sOperate = $$v
                                        },
                                        expression: "sOperate",
                                      },
                                    },
                                    [_vm._v("消耗后直接销毁（不选则默认回收）")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.ruleForm.singleNftConsumeType == 0
                                ? _c(
                                    "div",
                                    { staticClass: "b mt10" },
                                    [
                                      _c("span", [
                                        _vm._v("每次抽奖需消耗藏品"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-input-number", {
                                        staticClass: "w100 ml10",
                                        attrs: {
                                          controls: false,
                                          disabled: _vm.noEdit,
                                          min: 1,
                                        },
                                        model: {
                                          value: _vm.ruleForm.singleConsumeNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "singleConsumeNum",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.singleConsumeNum",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml10" }, [
                                        _vm._v("个"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.ruleForm.singleNftConsumeType == 1
                                ? _c("div", { staticClass: "b mt10" }, [
                                    _c(
                                      "div",
                                      { staticClass: "tab" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tr flex align-items-c",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "th",
                                                staticStyle: { width: "20%" },
                                              },
                                              [_vm._v("需消耗数量")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "th",
                                                staticStyle: { width: "70%" },
                                              },
                                              [_vm._v("消耗藏品")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "th",
                                                staticStyle: { width: "10%" },
                                              },
                                              [_vm._v("操作")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.ruleForm.singleRules,
                                          function (m, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass:
                                                  "tr flex align-items-c",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "td",
                                                    staticStyle: {
                                                      width: "20%",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        type: "number",
                                                        placeholder: "输入数量",
                                                        disabled: _vm.noEdit,
                                                      },
                                                      model: {
                                                        value: m.consumeNum,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            m,
                                                            "consumeNum",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "m.consumeNum",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "td",
                                                    staticStyle: {
                                                      width: "70%",
                                                    },
                                                  },
                                                  [
                                                    _c("PopoverTable", {
                                                      attrs: {
                                                        "show-data":
                                                          m.consumeMaterials,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "ml10",
                                                        attrs: {
                                                          type: "text",
                                                          disabled: _vm.noEdit,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showChangeAction(
                                                              "singleRules",
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("选择藏品")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "td",
                                                    staticStyle: {
                                                      width: "10%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "ml10",
                                                        attrs: {
                                                          type: "text",
                                                          disabled: _vm.noEdit,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delAction(
                                                              "singleRules",
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tr flex align-items-c",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "td",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "ml10",
                                                    attrs: {
                                                      type: "text",
                                                      disabled: _vm.noEdit,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addAction(
                                                          "singleRules"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("添加规则")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "一键多抽：" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": 1,
                            "inactive-value": 0,
                            disabled: _vm.ruleForm.luckyType != 2 || _vm.noEdit,
                          },
                          model: {
                            value: _vm.ruleForm.isOpenDoubleRule,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "isOpenDoubleRule", $$v)
                            },
                            expression: "ruleForm.isOpenDoubleRule",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml20" }, [_vm._v("一键抽")]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "w100 ml10",
                          attrs: {
                            controls: false,
                            min: 1,
                            disabled:
                              !_vm.ruleForm.isOpenDoubleRule || _vm.noEdit,
                          },
                          model: {
                            value: _vm.ruleForm.doubleNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "doubleNum", $$v)
                            },
                            expression: "ruleForm.doubleNum",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml10" }, [_vm._v("次")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticStyle: { color: "rgb(154, 154, 154)" } },
                      [
                        _vm._v(
                          "*开启【一键多抽】后，抽奖过程消耗的藏品或积分数量为用户每次点击一次抽奖后完成多次连续抽奖所需要消耗的总数量。"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex mt10" }, [
                      _c(
                        "div",
                        { staticClass: "l" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "消耗类型",
                                disabled:
                                  !_vm.ruleForm.isOpenDoubleRule || _vm.noEdit,
                              },
                              model: {
                                value: _vm.ruleForm.doubleRuleType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "doubleRuleType", $$v)
                                },
                                expression: "ruleForm.doubleRuleType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 0, label: "消耗积分" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 1, label: "消耗藏品" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.doubleRuleType == 0
                        ? _c(
                            "div",
                            { staticClass: "flex-one ml20" },
                            [
                              _c("span", [_vm._v("抽奖共需要消耗")]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticClass: "w100 ml10",
                                  attrs: {
                                    placeholder: "积分类型",
                                    disabled:
                                      !_vm.ruleForm.isOpenDoubleRule ||
                                      _vm.noEdit,
                                  },
                                  model: {
                                    value: _vm.ruleForm.doubleScoreType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "doubleScoreType",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.doubleScoreType",
                                  },
                                },
                                _vm._l(_vm.typeList, function (m, i) {
                                  return _c("el-option", {
                                    key: "type" + i,
                                    attrs: { value: m.id, label: m.name },
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                staticClass: "w100 ml10",
                                attrs: {
                                  controls: false,
                                  min: 1,
                                  disabled:
                                    !_vm.ruleForm.isOpenDoubleRule ||
                                    _vm.noEdit,
                                },
                                model: {
                                  value: _vm.ruleForm.doubleScoreNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "doubleScoreNum",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.doubleScoreNum",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("积分"),
                              ]),
                            ],
                            1
                          )
                        : _vm.ruleForm.doubleRuleType == 1
                        ? _c("div", { staticClass: "flex-one ml20" }, [
                            _c(
                              "div",
                              { staticClass: "t" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.ruleForm.isOpenDoubleRule ||
                                        _vm.noEdit,
                                    },
                                    model: {
                                      value: _vm.ruleForm.doubleNftConsumeType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "doubleNftConsumeType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ruleForm.doubleNftConsumeType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("全部藏品"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("部分藏品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass: "ml20",
                                    model: {
                                      value: _vm.dOperate,
                                      callback: function ($$v) {
                                        _vm.dOperate = $$v
                                      },
                                      expression: "dOperate",
                                    },
                                  },
                                  [_vm._v("消耗后直接销毁（不选则默认回收）")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.ruleForm.doubleNftConsumeType == 0
                              ? _c(
                                  "div",
                                  { staticClass: "b mt10" },
                                  [
                                    _c("span", [_vm._v("抽奖共需要消耗藏品")]),
                                    _vm._v(" "),
                                    _c("el-input-number", {
                                      staticClass: "w100 ml10",
                                      attrs: {
                                        controls: false,
                                        min: 1,
                                        disabled:
                                          !_vm.ruleForm.isOpenDoubleRule ||
                                          _vm.noEdit,
                                      },
                                      model: {
                                        value: _vm.ruleForm.doubleConsumeNum,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "doubleConsumeNum",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.doubleConsumeNum",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml10" }, [
                                      _vm._v("个"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.ruleForm.doubleNftConsumeType == 1
                              ? _c("div", { staticClass: "b mt10" }, [
                                  _c(
                                    "div",
                                    { staticClass: "tab" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tr flex align-items-c",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "th",
                                              staticStyle: { width: "20%" },
                                            },
                                            [_vm._v("需消耗数量")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "th",
                                              staticStyle: { width: "70%" },
                                            },
                                            [_vm._v("消耗藏品")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "th",
                                              staticStyle: { width: "10%" },
                                            },
                                            [_vm._v("操作")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.ruleForm.doubleRules,
                                        function (m, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              staticClass:
                                                "tr flex align-items-c",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "td",
                                                  staticStyle: { width: "20%" },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      type: "number",
                                                      placeholder: "输入数量",
                                                      disabled:
                                                        !_vm.ruleForm
                                                          .isOpenDoubleRule ||
                                                        _vm.noEdit,
                                                    },
                                                    model: {
                                                      value: m.consumeNum,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          m,
                                                          "consumeNum",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "m.consumeNum",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "td",
                                                  staticStyle: { width: "70%" },
                                                },
                                                [
                                                  _c("PopoverTable", {
                                                    attrs: {
                                                      "show-data":
                                                        m.consumeMaterials,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "ml10",
                                                      attrs: {
                                                        type: "text",
                                                        disabled:
                                                          !_vm.ruleForm
                                                            .isOpenDoubleRule ||
                                                          _vm.noEdit,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showChangeAction(
                                                            "doubleRules",
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("选择藏品")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "td",
                                                  staticStyle: { width: "10%" },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "ml10",
                                                      attrs: {
                                                        type: "text",
                                                        disabled:
                                                          !_vm.ruleForm
                                                            .isOpenDoubleRule ||
                                                          _vm.noEdit,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delAction(
                                                            "doubleRules",
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tr flex align-items-c",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "td",
                                              staticStyle: { width: "100%" },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "ml10",
                                                  attrs: {
                                                    type: "text",
                                                    disabled:
                                                      !_vm.ruleForm
                                                        .isOpenDoubleRule ||
                                                      _vm.noEdit,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addAction(
                                                        "doubleRules"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加规则")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动规则：" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.ruleForm.rulesExplain,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "rulesExplain", $$v)
                          },
                          expression: "ruleForm.rulesExplain",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.saveAction("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认提交")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Product", {
        ref: "product",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Coupon", {
        ref: "coupon",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nft", {
        ref: "nft",
        attrs: { showSelect: true, "current-data": [] },
        on: { selectMessage: _vm.selectNftMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }