"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _open3d = require("@/api/open3d");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listLoading: false,
      tableData: [],
      fromState: {},
      pageNum: 1,
      pageSize: 20,
      total: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      var model = (0, _objectSpread2.default)({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }, this.fromState);
      (0, _open3d.getWorksEvidenListApi)(model).then(function (res) {
        console.log(res);
        _this.tableData = res.rows;
        _this.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    onResh: function onResh() {
      this.pageNum = 1;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    }
  }
};