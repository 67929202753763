var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "80px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: { change: _vm.changeStatusAction },
                          model: {
                            value: _vm.tableFrom.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "status", $$v)
                            },
                            expression: "tableFrom.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v(
                              "全部 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.all
                                    ? _vm.orderChartType.all
                                    : 0 + ")"
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "8" } }, [
                            _vm._v(
                              "待确认 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.waitConfirm
                                    ? _vm.orderChartType.waitConfirm
                                    : 0 + ")"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v(
                              "待付款 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.unpaid
                                    ? _vm.orderChartType.unpaid
                                    : 0 + ")"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v(
                              "待发货 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.unshipped
                                    ? _vm.orderChartType.unshipped
                                    : 0 + ")"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "3" } }, [
                            _vm._v(
                              "待收货 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.untake
                                    ? _vm.orderChartType.untake
                                    : 0 + ")"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "4" } }, [
                            _vm._v(
                              "待评价 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.unevaluate
                                    ? _vm.orderChartType.unevaluate
                                    : 0 + ")"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "5" } }, [
                            _vm._v(
                              "已完成 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.complete
                                    ? _vm.orderChartType.complete
                                    : 0 + ")"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "6" } }, [
                            _vm._v(
                              "已退款 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.refund
                                    ? _vm.orderChartType.refund
                                    : 0 + ")"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "7" } }, [
                            _vm._v(
                              "已取消 " +
                                _vm._s(
                                  "(" + _vm.orderChartType.cancel
                                    ? _vm.orderChartType.cancel
                                    : 0 + ")"
                                ) +
                                "\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "时间选择：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange(_vm.tableFrom.date)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "date", $$v)
                            },
                            expression: "tableFrom.date",
                          },
                        },
                        _vm._l(_vm.fromList.fromTxt, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text) + "\n            ")]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "value-format": "yyyy/MM/dd",
                          format: "yyyy/MM/dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "自定义时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex align-items-c",
                      staticStyle: { "padding-left": "80px" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          attrs: {
                            placeholder: "拍卖会名称",
                            filterable: "",
                            remote: "",
                            size: "small",
                            clearable: "",
                            "remote-method": _vm.searchVenueAction,
                            loading: _vm.venueLoading,
                          },
                          on: { change: _vm.changeMeetingAction },
                          model: {
                            value: _vm.tableFrom.venue_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "venue_id", $$v)
                            },
                            expression: "tableFrom.venue_id",
                          },
                        },
                        _vm._l(_vm.venueList, function (m, i) {
                          return _c("el-option", {
                            key: "user" + i,
                            attrs: { value: m.venue_id, label: m.title },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "w200 ml10",
                          attrs: {
                            placeholder: "专场名称",
                            filterable: "",
                            remote: "",
                            size: "small",
                            clearable: "",
                            "remote-method": _vm.searchAlbumAction,
                            loading: _vm.venueLoading,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.album_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "album_id", $$v)
                            },
                            expression: "tableFrom.album_id",
                          },
                        },
                        _vm._l(_vm.albumList, function (m, i) {
                          return _c("el-option", {
                            key: "user" + i,
                            attrs: { value: m.album_id, label: m.album_name },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth1",
                          attrs: {
                            placeholder: "客户名称/手机号/ID",
                            size: "small",
                          },
                          model: {
                            value: _vm.tableFrom.user_name_phone_keyword,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tableFrom,
                                "user_name_phone_keyword",
                                $$v
                              )
                            },
                            expression: "tableFrom.user_name_phone_keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth1",
                          attrs: { placeholder: "货品名称/ID", size: "small" },
                          model: {
                            value: _vm.tableFrom.product_name_id_keyword,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tableFrom,
                                "product_name_id_keyword",
                                $$v
                              )
                            },
                            expression: "tableFrom.product_name_id_keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth1",
                          attrs: {
                            placeholder: "订单号/收货人/手机号",
                            size: "small",
                          },
                          model: {
                            value: _vm.tableFrom.keywords,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keywords", $$v)
                            },
                            expression: "tableFrom.keywords",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth1",
                          attrs: { placeholder: "拍品LOT号", size: "small" },
                          model: {
                            value: _vm.tableFrom.auction_lot,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "auction_lot", $$v)
                            },
                            expression: "tableFrom.auction_lot",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "w200 ml10",
                          attrs: {
                            placeholder: "买家类型",
                            size: "small",
                            clearable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.buyer_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "buyer_type", $$v)
                            },
                            expression: "tableFrom.buyer_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: "network", label: "网络买家" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "theScene", label: "现场买家" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex align-items-c mt10",
                      staticStyle: { "padding-left": "80px" },
                    },
                    [
                      _vm.tableFrom.status == 8 || _vm.tableFrom.status == 1
                        ? [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr5",
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.settlementAllAction },
                              },
                              [_vm._v("批量结算")]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tableFrom.status == 2 || !_vm.tableFrom.status
                        ? [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr5",
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.sendAllAction },
                              },
                              [_vm._v("批量发货")]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10",
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.exportOrder },
                        },
                        [_vm._v("导出")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.getExportFileList },
                        },
                        [_vm._v("导出已生成列表")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.printAllAction("pay")
                            },
                          },
                        },
                        [_vm._v("打印结算单")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.printAllAction("send")
                            },
                          },
                        },
                        [_vm._v("打印提货单")]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.loadStatus
        ? [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "order-table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  "span-method": _vm.objectSpanMethod,
                  size: "mini",
                  "highlight-current-row": "",
                  "cell-class-name": _vm.addTdClass,
                },
                on: {
                  "selection-change": _vm.handleSelectionChange,
                  "sort-change": _vm.changeSortAction,
                },
              },
              [
                _c("el-table-column", {
                  key: "1",
                  attrs: { type: "selection", width: "27" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "2",
                  attrs: { label: "客户", "min-width": "140" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.user
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.user.real_name ||
                                          scope.row.user.nickname
                                      )
                                    ),
                                    scope.row.user.real_name
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(scope.row.user.nickname) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2556373591
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "3",
                  attrs: { label: "号牌", "min-width": "80", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.userNumberPlate
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.userNumberPlate.number) +
                                      " "
                                  ),
                                  scope.row.userNumberPlate.number_type ==
                                  "特殊号牌"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "ts",
                                          staticStyle: {
                                            background: "#fa4f4f",
                                          },
                                        },
                                        [_vm._v("特")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.userNumberPlate.number_type ==
                                  "免保号牌"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "ts",
                                          staticStyle: {
                                            background: "#ddb75b",
                                          },
                                        },
                                        [_vm._v("免")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1297385662
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "4",
                  attrs: { label: "专场", "min-width": "130", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.album
                                    ? scope.row.album.album_name
                                    : ""
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2618484085
                  ),
                }),
                _vm._v(" "),
                _vm.tableFrom.status &&
                (_vm.tableFrom.status == 2 || _vm.tableFrom.status == 3)
                  ? [
                      _c("el-table-column", {
                        key: "6",
                        attrs: {
                          label: "收货地址",
                          width: "200",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.delivery_insured_price > 0
                                    ? _c(
                                        "div",
                                        { staticStyle: { color: "#ae0000" } },
                                        [
                                          _vm._v(
                                            "保价金额：" +
                                              _vm._s(
                                                scope.row.delivery_insured_price
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row.real_name) +
                                        " " +
                                        _vm._s(scope.row.user_phone)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.user_address)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3832668139
                        ),
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "7",
                  attrs: { label: "图片", "min-width": "60", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticStyle: {
                                width: "40px",
                                height: "40px",
                                display: "block",
                              },
                              attrs: {
                                src: scope.row.orderProductOne
                                  ? scope.row.orderProductOne.cart_info.product
                                      .image + "!120a"
                                  : scope.row.orderProduct[0].cart_info.product
                                      .image + "!120a",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1018836702
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "8",
                  attrs: { label: "LOT", "min-width": "60", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.orderProductOne
                                    ? scope.row.orderProductOne.cart_info
                                        .product.lot
                                    : scope.row.orderProduct[0].cart_info
                                        .product.lot
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3912829325
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "9",
                  attrs: {
                    label: "拍品名称",
                    "min-width": "140",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.orderProductOne
                                    ? scope.row.orderProductOne.cart_info
                                        .product.store_name
                                    : scope.row.orderProduct[0].cart_info
                                        .product.store_name
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3792697229
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "10",
                  attrs: {
                    label: "结拍时间",
                    "min-width": "95",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3407778047
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "11",
                  attrs: {
                    prop: "payTime",
                    label: "支付时间",
                    "min-width": "95",
                    align: "center",
                    sortable: "custom",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.pay_time))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1633108467
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "12",
                  attrs: {
                    label: "订单状态",
                    "min-width": "80",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == -2
                              ? [_c("div", [_vm._v("已取消")])]
                              : scope.row.status == -1
                              ? [_c("div", [_vm._v("已退款")])]
                              : scope.row.is_refund !== 0
                              ? [
                                  _c("div", { staticStyle: { color: "red" } }, [
                                    _vm._v("售后中"),
                                  ]),
                                ]
                              : scope.row.status == -3 || scope.row.status == 0
                              ? [
                                  _c("div", { staticStyle: { color: "red" } }, [
                                    _vm._v("待结算"),
                                  ]),
                                ]
                              : scope.row.status == -4
                              ? [
                                  _c("div", { staticStyle: { color: "red" } }, [
                                    _vm._v("转账待审核"),
                                  ]),
                                ]
                              : scope.row.status == 1 &&
                                scope.row.is_out_warehouse_order == 1
                              ? [_c("div", [_vm._v("待出库")])]
                              : scope.row.status == 1 &&
                                scope.row.delivery_type == 2
                              ? [_c("div", [_vm._v("待自提")])]
                              : scope.row.status == 1 &&
                                scope.row.delivery_type == 3
                              ? [_c("div", [_vm._v("待他人提货")])]
                              : scope.row.status == 1
                              ? [_c("div", [_vm._v("待发货")])]
                              : scope.row.status == 2
                              ? [_c("div", [_vm._v("待收货")])]
                              : scope.row.status == 3
                              ? [_c("div", [_vm._v("待评价")])]
                              : scope.row.status == 4
                              ? [_c("div", [_vm._v("已完成")])]
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    957630317
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "13",
                  attrs: {
                    label: "落槌价",
                    "min-width": "90",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.orderProductOne
                                      ? scope.row.orderProductOne.product_price
                                      : scope.row.orderProduct[0].product_price,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3977792511
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "14",
                  attrs: { label: "佣金", "min-width": "90", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.commission_price,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    563103612
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "15",
                  attrs: { label: "优惠", "min-width": "90", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.admin_discount_price
                                    ? "-" +
                                        _vm.formatPriceNumber(
                                          scope.row.admin_discount_price,
                                          2
                                        )
                                    : ""
                                ) + "\n          "
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3837230489
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "16",
                  attrs: {
                    label: "实际支付",
                    "min-width": "90",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(scope.row.pay_price, 2)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1974200345
                  ),
                }),
                _vm._v(" "),
                !_vm.tableFrom.status
                  ? [
                      _c("el-table-column", {
                        key: "17",
                        attrs: {
                          label: "收货地址",
                          width: "200",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.delivery_insured_price > 0
                                    ? _c(
                                        "div",
                                        { staticStyle: { color: "#ae0000" } },
                                        [
                                          _vm._v(
                                            "保价金额：" +
                                              _vm._s(
                                                scope.row.delivery_insured_price
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row.real_name) +
                                        " " +
                                        _vm._s(scope.row.user_phone)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.user_address)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3832668139
                        ),
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.tableFrom.status ||
                _vm.tableFrom.status == 8 ||
                _vm.tableFrom.status == 1 ||
                _vm.tableFrom.status == 2 ||
                _vm.tableFrom.status == 3 ||
                _vm.tableFrom.status == 4 ||
                _vm.tableFrom.status == 5
                  ? [
                      _c("el-table-column", {
                        key: "18",
                        attrs: { label: "处理", width: "150", fixed: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.is_refund === 0 &&
                                  scope.row.status > 0
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showRefundAction(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("手动退款")]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.is_refund !== 0
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onRefundDetail(
                                                  scope.row.order_sn
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("查看退款单")]
                                        ),
                                      ]
                                    : scope.row.status == -3 ||
                                      scope.row.status == 0
                                    ? [
                                        scope.row.uid !== 8888
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "ft13 mr5",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.settlementAction(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("结算")]
                                            )
                                          : _vm._e(),
                                      ]
                                    : scope.row.status == 1
                                    ? [
                                        scope.row.delivery_type == 1
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "ft13 mr5",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sendAction(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("发货")]
                                            )
                                          : scope.row.delivery_type == 2 ||
                                            scope.row.delivery_type == 3
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "ft13 mr5",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sendAction(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("确认自提")]
                                            )
                                          : _c(
                                              "el-button",
                                              {
                                                staticClass: "ft13 mr5",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sendAction(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("发货")]
                                            ),
                                      ]
                                    : scope.row.status == 2
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "ft13 mr5",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editDeliveryAction(
                                                  scope.row.order_id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("修改发货")]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == -3 ||
                                  scope.row.status == 0 ||
                                  scope.row.status == -5
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "ft13 mr5",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editOrderAction(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("修改")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "ft13 mr5",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editOrderAction(
                                                  scope.row,
                                                  "del"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status > 1 &&
                                  scope.row.delivery_type == 1
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "ft13 mr5",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openLogistics(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("物流跟踪")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1139067459
                        ),
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block flex align-items-c" },
        [
          _c(
            "div",
            { staticClass: "flex-one", staticStyle: { "margin-top": "20px" } },
            [
              _vm.tableFrom.status == 8 || _vm.tableFrom.status == 1
                ? [
                    _c(
                      "el-button",
                      {
                        staticClass: "mr5",
                        attrs: { type: "primary" },
                        on: { click: _vm.settlementAllAction },
                      },
                      [_vm._v("批量结算")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mr5",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.printAllAction("pay")
                          },
                        },
                      },
                      [_vm._v("批量打印")]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.tableFrom.status == 2
                ? [
                    _c(
                      "el-button",
                      {
                        staticClass: "mr5",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.printAllAction("send")
                          },
                        },
                      },
                      [_vm._v("批量打印")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mr5",
                        attrs: { type: "primary" },
                        on: { click: _vm.sendAllAction },
                      },
                      [_vm._v("批量发货")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Settlement", { ref: "settlement", on: { payOk: _vm.getList } }),
      _vm._v(" "),
      _c("Send", {
        ref: "sendEL",
        attrs: { "express-list": _vm.expressList },
        on: { sendOk: _vm.getList },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editOrder.show,
            "close-on-click-modal": false,
            center: "",
            title: _vm.editOrder.del ? "取消订单" : "修改订单",
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editOrder, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "edit-order item-align-center mt20" }, [
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [_vm._v("拍品：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "r", staticStyle: { "font-weight": "500" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.editOrder.order.lot
                        ? "LOT " + _vm.editOrder.order.lot
                        : _vm.editOrder.order.name
                    )
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [_vm._v("落槌价：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "r" },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      controls: false,
                      disabled: _vm.editOrder.del != "",
                    },
                    model: {
                      value: _vm.editOrder.order.auction_now_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.editOrder.order, "auction_now_price", $$v)
                      },
                      expression: "editOrder.order.auction_now_price",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-order item-align-center mt20" }, [
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [_vm._v("买家信息：")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r item-align-center",
                  staticStyle: { "font-weight": "500", cursor: "pointer" },
                  on: { click: _vm.editUserAction },
                },
                [
                  _vm.editOrder.user
                    ? _c("div", { staticClass: "name" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.editOrder.editUser
                                ? _vm.editOrder.editUser.nickname
                                : _vm.editOrder.user.nickname
                            ) +
                              _vm._s(
                                _vm.editOrder.numberPlate
                                  ? "：号牌" + _vm.editOrder.numberPlate.number
                                  : ""
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.editOrder.userAccount
                          ? _c("div", { staticClass: "ft12 color-gray" }, [
                              _vm._v(
                                "钱包可用余额 " +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.editOrder.userAccount.balance,
                                      2
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.editOrder.del
                    ? _c("div", { staticClass: "edit" }, [_vm._v("修改")])
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [
                _vm._v(
                  _vm._s(_vm.editOrder.order.bond_type == 1 ? "扣除" : "冻结") +
                    "保证金："
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "r" },
                [
                  _c("el-input-number", {
                    attrs: { type: "text", controls: false },
                    model: {
                      value: _vm.bondMoney,
                      callback: function ($$v) {
                        _vm.bondMoney = $$v
                      },
                      expression: "bondMoney",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-order item-align-center mt20" }, [
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [_vm._v("佣金：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "r" },
                [
                  _c("el-input-number", {
                    attrs: {
                      value: _vm.commission_price,
                      disabled: "",
                      type: "text",
                      controls: false,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [_vm._v("成交价：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "r" },
                [
                  _c("el-input-number", {
                    attrs: {
                      value: _vm.all_price,
                      type: "text",
                      controls: false,
                      disabled: "",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          !_vm.editOrder.del
            ? [
                _c(
                  "div",
                  { staticClass: "edit-order item-align-center mt40" },
                  [
                    _c("div", { staticClass: "flex-one item-flex-center" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l w100",
                          staticStyle: { color: "red" },
                        },
                        [_vm._v("佣金(%)：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "r" },
                        [
                          _c("el-input-number", {
                            attrs: { controls: false, min: 0, max: 100 },
                            model: {
                              value: _vm.editOrder.order.commission_rate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editOrder.order,
                                  "commission_rate",
                                  $$v
                                )
                              },
                              expression: "editOrder.order.commission_rate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-order item-align-center mt20" },
                  [
                    _c("div", { staticClass: "flex-one item-flex-center" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l w100",
                          staticStyle: { color: "red" },
                        },
                        [_vm._v("优惠金额：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "r" },
                        [
                          _c("el-input-number", {
                            attrs: { controls: false, max: _vm.all_price },
                            model: {
                              value: _vm.editOrder.order.admin_discount_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editOrder.order,
                                  "admin_discount_price",
                                  $$v
                                )
                              },
                              expression:
                                "editOrder.order.admin_discount_price",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-order item-align-center mt20" },
                  [
                    _c("div", { staticClass: "flex-one item-flex-center" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l w100",
                          staticStyle: { color: "red" },
                        },
                        [_vm._v("确认成交价：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "r" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              value: _vm.allPrice,
                              controls: false,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-flex-center mt40" }, [
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.editOkAction },
                        },
                        [_vm._v("确认修改")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          on: { click: _vm.closeEditAction },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : [
                _c("div", { staticClass: "item-flex-center mt40" }, [
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.closeOrderAction(1)
                            },
                          },
                        },
                        [_vm._v("扣除保证金")]
                      ),
                      _vm._v(" "),
                      _c("div"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.closeOrderAction(2)
                            },
                          },
                        },
                        [_vm._v("解冻保证金")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v("保证金回到客户余额")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.closeOrderAction(3)
                            },
                          },
                        },
                        [_vm._v("退回保证金")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v("保证金回到客户银行卡")]),
                    ],
                    1
                  ),
                ]),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看凭证",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl + "!m640", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.refundData.show,
            "close-on-click-modal": false,
            center: "",
            title: "手动退款",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.refundData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c("div", { staticClass: "l" }, [_vm._v("退款金额：")]),
              _vm._v(" "),
              _c("el-input-number", {
                attrs: { max: _vm.refundData.max_refund_price },
                model: {
                  value: _vm.refundData.refund_price,
                  callback: function ($$v) {
                    _vm.$set(_vm.refundData, "refund_price", $$v)
                  },
                  expression: "refundData.refund_price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.refundData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.refundConfirmDataAction },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("ChangeUser", {
        ref: "changeUser",
        on: { changeUid: _vm.changeUidAction },
      }),
      _vm._v(" "),
      _c("SettlementPrint", { ref: "printView" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流查询",
            visible: _vm.dialogLogistics,
            width: "350px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLogistics = $event
            },
          },
        },
        [
          _vm.orderDatalist
            ? _c("logistics-from", {
                attrs: {
                  "order-datalist": _vm.orderDatalist,
                  result: _vm.result,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }