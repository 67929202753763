"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
var _marketing = require("@/api/marketing");
var _merchant = require("@/api/merchant");
var _open3Dgrade = require("@/api/open3Dgrade");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { ElSteps } from 'element-ui/types/steps';
var _default = exports.default = {
  name: "UserList",
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      integralNum: 1,
      integralDialog: false,
      awayCoupons: "",
      options: [],
      GiveAwayCoupons: false,
      userType: "all",
      listLoading: false,
      objs: {
        userId: "",
        type: null,
        number: 0,
        couponId: "" // 优惠券ID
      },
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        identityId: "",
        builderType: "",
        levelType: "",
        daoType: "",
        pageNum: 1,
        pageSize: 20
      },
      tagList: [],
      chkName: "",
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      visibleUser: false,
      addUserForm: {
        avatar: "",
        name: "",
        phone: "",
        sex: ""
      },
      userRules: {
        name: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }],
        phone: [{
          required: true,
          message: "请输入手机号",
          trigger: "blur"
        }]
      },
      visibleLabel: false,
      labelForm: {
        tagId: [],
        userId: []
      },
      labelRules: {
        tagId: [{
          required: true,
          message: "请选择标签",
          trigger: "change"
        }]
      },
      visibleUserStatus: false,
      userStatusForm: {
        userIds: [],
        status: "1",
        // 状态 0 解禁 1 账号禁用 2 功能禁用
        types: [] // 功能禁用列表
      },
      showdetail: false,
      uid: null,
      typeList: [],
      identityList: []
    };
  },
  filters: {
    formatStatus: function formatStatus(val) {
      var res = "";
      switch (val) {
        case 1:
          res = "正常";
          break;
        case 2:
          res = "即将到期";
          break;
        case 3:
          res = "已过期";
          break;
        default:
          break;
      }
      return res;
    },
    formatTypeName: function formatTypeName(val) {
      var res = "";
      switch (val) {
        case 1:
          res = "普通";
          break;
        case 2:
          res = "核心";
          break;
        case 3:
          res = "创始";
          break;
        default:
          break;
      }
      return res;
    },
    formatSource: function formatSource(val) {
      var res = "";
      switch (val) {
        case 1:
          res = "APP";
          break;
        case 0:
          res = "H5";
          break;
        default:
          break;
      }
      return res;
    },
    formatUserStatus: function formatUserStatus(val) {
      var res = "";
      switch (val) {
        case 2:
          res = "封禁"; // 功能停用
          break;
        case 1:
          res = "封禁"; // 账号停用
          break;
        case 0:
          res = "正常";
          break;
        default:
          break;
      }
      return res;
    },
    formatHasVerified: function formatHasVerified(val) {
      var res = "";
      switch (val) {
        case true:
          res = "已实名";
          break;
        case false:
          res = "-";
          break;
        default:
          break;
      }
      return res;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _user.labelLstApi)({
      pageNum: 1,
      pageSize: 100
    }).then(function (res) {
      _this.tagList = res.rows;
    });
    (0, _merchant.getList)().then(function (res) {
      _this.identityList = res.rows;
    });
    (0, _marketing.integralTypeListApi)().then(function (res) {
      _this.typeList = res.data;
    }).catch(function (res) {
      // this.$message.error(res.message)
    });
    // this.couponList();
  },
  methods: {
    // 上传头像
    modalPicTap: function modalPicTap(tit, key, key1) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this2.addUserForm.avatar = img[0];
        } else {
          _this2.addUserForm.avatar = img[0];
        }
        _this2.$forceUpdate();
      }, tit);
    },
    delImg: function delImg(key, key1) {
      if (key1) {
        this.addUserForm.avatar = "";
      } else {
        this.addUserForm.avatar = "";
      }
      this.$forceUpdate();
    },
    // 获取优惠券
    couponList: function couponList() {
      var _this3 = this;
      (0, _marketing.couponListApi)({
        pageSize: 100,
        pageNum: 1,
        status: 1
      }).then(function (res) {
        console.log(res.rows, 454);
        _this3.options = res.rows;
      });
    },
    userChange: function userChange(type) {
      var _this4 = this;
      var data = {
        userId: this.objs.userId,
        type: type,
        scoreTypeId: this.objs.scoreTypeId,
        number: this.objs.number,
        couponId: this.objs.couponId
      };
      if (this.integralDialog && !data.scoreTypeId) {
        this.$message.error("请选择积分类型");
        return false;
      }
      (0, _user.toUserScoreOrCoupon)(data).then(function (res) {
        _this4.$message.success("修改成功");
        if (type == 1 || type == 3) {
          _this4.integralDialog = false;
        } else {
          _this4.GiveAwayCoupons = false;
        }
      });
    },
    presentIntegral: function presentIntegral(row) {
      this.objs = {
        userId: "",
        type: null,
        number: 0,
        scoreTypeId: "",
        couponId: "" // 优惠券ID
      };
      this.objs.userId = row.userId;
      this.integralDialog = true;
    },
    getCoupon: function getCoupon(row) {
      this.objs.userId = row.userId;
      this.GiveAwayCoupons = true;
    },
    unlockCity: function unlockCity(_ref) {
      var _this5 = this;
      var userId = _ref.userId;
      (0, _user.unlockCityApi)(userId).then(function (res) {
        _this5.getList();
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "all") {
        this.$router.push("/merchant/user/list");
        return false;
      } else if (tab.name === "label") {
        this.$router.push("/merchant/user/label");
        return false;
      } else if (tab.name === "authentication") {
        this.$router.push("/merchant/user/verify");
        return false;
      } else if (tab.name === "canel") {
        this.$router.push("/merchant/user/cancellation");
        return false;
      } else if (tab.name === "regiest_set") {
        this.$router.push("/merchant/user/regiest_set");
        return false;
      } else if (tab.name === "new_user_set") {
        this.$router.push("/merchant/user/new_user_set");
        return false;
      }
    },
    confirmLabelAction: function confirmLabelAction(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this6.labelForm.tagId = _this6.labelForm.tagId.join(",");
          (0, _user.addTagApi)(_this6.labelForm).then(function () {
            _this6.$message.success("操作成功");
            _this6.visibleLabel = false;
            _this6.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    confirmUserAction: function confirmUserAction(formName) {
      var _this7 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _user.createUserApi)(_this7.addUserForm).then(function () {
            _this7.$message.success("操作成功");
            _this7.visibleUser = false;
            _this7.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    confirmUserStatusAction: function confirmUserStatusAction() {
      var _this8 = this;
      // console.log(this.userStatusForm)
      (0, _user.switchUserStatusApi)(this.userStatusForm).then(function () {
        _this8.$message.success("操作成功");
        _this8.visibleUserStatus = false;
        _this8.getList(1);
      }).catch(function (msg) {
        // loading.close();
      });
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    tagIdChange: function tagIdChange() {
      this.getList(1);
    },
    onchangeTime: function onchangeTime(val) {
      if (val) {
        this.queryParams.startTime = val[0];
        this.queryParams.endTime = val[1];
        this.getList(1);
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
        this.getList(1);
      }
    },
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? "" : name;
      this.changeType(!(this.chkName === ""));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = "dan";
        }
      } else {
        this.chkName = "";
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.pageNum);
      if (this.chkName === "dan") {
        this.checkedPage.push(this.queryParams.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this9 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.userId;
      });
      if (this.chkName === "duo") {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === "dan") {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this9.checkedIds.indexOf(id);
          if (index === -1) {
            _this9.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this9.checkedIds.indexOf(id);
          if (index > -1) {
            _this9.checkedIds.splice(index, 1);
          }
        });
      }
    },
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === "duo") {
          var index = this.noChecked.indexOf(user.userId);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.userId);
          if (_index === -1) this.checkedIds.push(user.userId);
        }
      } else {
        if (this.chkName === "duo") {
          var _index2 = this.noChecked.indexOf(user.userId);
          if (_index2 === -1) this.noChecked.push(user.userId);
        } else {
          var _index3 = this.checkedIds.indexOf(user.userId);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    nickNameClick: function nickNameClick(row) {
      this.uid = row.userId;
      this.showdetail = true;
    },
    getList: function getList(num) {
      var _this10 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _open3Dgrade.getBuilderListApi)(this.queryParams).then(function (res) {
        _this10.tableData.data = res.rows;
        _this10.tableData.data.forEach(function (item) {
          item.memberTagsName = "";
          if (item.memberTags) {
            item.memberTags.forEach(function (element) {
              item.memberTagsName = item.memberTagsName + element.name + "、";
            });
            item.memberTagsName = item.memberTagsName.substring(0, item.memberTagsName.length - 1);
          }
        });
        _this10.tableData.total = res.total;
        _this10.listLoading = false;
      }).catch(function (res) {
        _this10.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList("");
    },
    adduser: function adduser() {
      this.visibleUser = true;
    },
    exportOrder: function exportOrder() {
      var _this11 = this;
      (0, _user.memberInfoExport)(this.queryParams).then(function (res) {
        _this11.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(31);
    },
    // 修改标签
    setLabel: function setLabel(row) {
      this.visibleLabel = true;
      this.labelForm.userId = [];
      this.labelForm.userId.push(row.userId);
      var arr = [];
      if (row.memberTags) {
        row.memberTags.forEach(function (item) {
          arr.push(item.id);
        });
      }
      this.labelForm.tagId = arr;
    },
    setStatus: function setStatus(row) {
      if (row.userStatus) {
        this.userStatusForm.status = "0";
        this.confirmUserStatusAction();
      } else {
        this.visibleUserStatus = true;
      }
      this.userStatusForm.userIds = [];
      this.userStatusForm.userIds.push(row.userId);
    },
    unlockAction: function unlockAction(row) {
      var _this12 = this;
      var arr = [];
      arr.push(row.userId);
      this.$confirm("\u786E\u8BA4\u89E3\u9501\u8BE5\u7528\u6237?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _user.switchLockTypeApi)({
          userIds: arr,
          status: 0
        }).then(function () {
          _this12.$message.success("操作成功");
          _this12.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    batchLabel: function batchLabel() {
      // console.log(this.checkedIds)
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择用户");
      }
      this.visibleLabel = true;
      this.labelForm.userId = this.checkedIds;
      this.clearCheck();
    },
    clearCheck: function clearCheck() {
      this.chkName = "";
      this.checkedIds = [];
      this.checkedPage = [];
      this.noChecked = [];
      this.allCheck = false;
    },
    batchUserStatusAction: function batchUserStatusAction(val) {
      var _this13 = this;
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择用户");
      }
      this.userStatusForm.userIds = this.checkedIds;
      if (val) {
        this.$confirm("\u786E\u8BA4\u89E3\u7981\u6240\u9009\u7528\u6237?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this13.userStatusForm.status = "0";
          _this13.confirmUserStatusAction();
          _this13.clearCheck();
        }).catch(function () {});
      } else {
        this.visibleUserStatus = true;
      }
    },
    batchLockTypeAction: function batchLockTypeAction() {
      var _this14 = this;
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择用户");
      }
      this.$confirm("\u786E\u8BA4\u89E3\u9501\u6240\u9009\u7528\u6237?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _user.switchLockTypeApi)({
          userIds: _this14.checkedIds,
          status: 0
        }).then(function () {
          _this14.$message.success("操作成功");
          _this14.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};