"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var accountsRouter = {
  path: "".concat(_settings.roterPre, "/accounts"),
  name: 'accounts',
  meta: {
    icon: '',
    title: '财务管理'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'buyNft',
    name: 'AccountsbuyNft',
    meta: {
      title: '上链充值',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNft/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/accounts/buyNft/account"),
    children: [{
      path: 'account',
      name: 'AccountsbuyNft_account',
      meta: {
        title: '我的账户',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNft/account'));
        });
      }
    }, {
      path: 'recharge',
      name: 'AccountsbuyNft_recharge',
      meta: {
        title: '充值',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/buyNft/account")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNft/recharge'));
        });
      }
    }]
  }, {
    path: 'verify',
    name: 'AccountVerify',
    meta: {
      title: '实名充值',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/verify/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/accounts/verify/account"),
    children: [{
      path: 'account',
      name: 'AccountsVerifyaccount',
      meta: {
        title: '我的账户',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/verify/account'));
        });
      }
    }, {
      path: 'recharge',
      name: 'AccountsVerify_recharge',
      meta: {
        title: '充值',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/verify/account")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/verify/recharge'));
        });
      }
    }]
  }, {
    path: 'buyShortMessage',
    name: 'AccountsbuyShortMessage',
    meta: {
      title: '短信购买',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyShortMessage/index'));
      });
    },
    children: [{
      path: 'show',
      name: 'AccountsbuyShortMessage_show',
      meta: {
        title: '短信使用',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/buyShortMessage/recharge")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyShortMessage/show'));
        });
      }
    }, {
      path: 'recharge',
      name: 'AccountsbuyShortMessage_recharge',
      meta: {
        title: '短信购买',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyShortMessage/recharge'));
        });
      }
    }]
  }]
};
var _default = exports.default = accountsRouter;