var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.addAction },
              },
              [_vm._v("创建转赠规则")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "order-table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  size: "mini",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "ID", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.id))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "规则名称", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.name))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "规则详情", "min-width": "220" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.isIncrease
                            ? _c(
                                "div",
                                _vm._l(row.rule, function (m, i) {
                                  return _c("span", { key: i }, [
                                    _vm._v(
                                      _vm._s(
                                        m.count == 1
                                          ? "首次转赠（" +
                                              m.day +
                                              "天）" +
                                              m.handlingFee +
                                              "元"
                                          : "/第" +
                                              m.count +
                                              "次（" +
                                              m.day +
                                              "天）" +
                                              m.handlingFee +
                                              "元"
                                      )
                                    ),
                                  ])
                                }),
                                0
                              )
                            : _c("div", [_vm._v("不可转赠")]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "提交时间", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.editAction(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.delAction(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt20 flex justify-e" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-size": _vm.queryParams.limit,
                    "current-page": _vm.queryParams.page,
                    layout: "total, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: { "current-change": _vm.getListPage },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.newDeliverDetail.show,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.newDeliverDetail, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.newDeliverDetail,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入规则名称" },
                    model: {
                      value: _vm.newDeliverDetail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "name", $$v)
                      },
                      expression: "newDeliverDetail.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "可以转赠", prop: "isIncrease" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.newDeliverDetail.isIncrease,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "isIncrease", $$v)
                      },
                      expression: "newDeliverDetail.isIncrease",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.newDeliverDetail.isIncrease
                ? _c(
                    "div",
                    { staticClass: "obj-view" },
                    [
                      _vm._l(_vm.newDeliverDetail.condition, function (m, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "dl flex align-items-c" },
                          [
                            _c(
                              "div",
                              { staticClass: "r flex align-items-c" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-left": "34px" } },
                                  [_vm._v("第")]
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w50 ml5 mr5",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { value: i + 1, disabled: "" },
                                }),
                                _c("span", [_vm._v("次转赠")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("，获得")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w100 ml5 mr5",
                                  staticStyle: { "text-align": "center" },
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    oninput:
                                      "if(value){value=value.replace(/[^\\d]/g,'')}",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.editDayAction(i)
                                    },
                                  },
                                  model: {
                                    value: m.day,
                                    callback: function ($$v) {
                                      _vm.$set(m, "day", $$v)
                                    },
                                    expression: "m.day",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("天后可转赠，并收取")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w100 ml5 mr5",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { type: "number" },
                                  on: { input: _vm.changeInput },
                                  model: {
                                    value: m.handlingFee,
                                    callback: function ($$v) {
                                      _vm.$set(m, "handlingFee", $$v)
                                    },
                                    expression: "m.handlingFee",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("元手续费")]),
                                _vm._v(" "),
                                i > 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "ml20",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delRuleAction(i)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pop",
                          staticStyle: { "padding-left": "34px" },
                        },
                        [_vm._v("最后一个规则覆盖之后次数的转赠规则")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mt10",
                          staticStyle: { "padding-left": "34px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.addRuleAction },
                            },
                            [_vm._v("+增加规则")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      staticStyle: { "font-size": "18px" },
                    },
                    [
                      _vm._v("\n          设置第三方转赠规则 "),
                      _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v("设置后可独立针对第三方平台对接转赠的规则"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.newDeliverDetail.hasThirdParty,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "hasThirdParty", $$v)
                      },
                      expression: "newDeliverDetail.hasThirdParty",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.newDeliverDetail.hasThirdParty
                ? [
                    _c("div", { staticStyle: { height: "30px" } }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "可以转赠",
                          prop: "thirdPartyIsIncrease",
                        },
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                          },
                          model: {
                            value: _vm.newDeliverDetail.thirdPartyIsIncrease,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.newDeliverDetail,
                                "thirdPartyIsIncrease",
                                $$v
                              )
                            },
                            expression: "newDeliverDetail.thirdPartyIsIncrease",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.newDeliverDetail.thirdPartyIsIncrease
                      ? _c(
                          "div",
                          { staticClass: "obj-view" },
                          [
                            _vm._l(
                              _vm.newDeliverDetail.thirdPartyRule,
                              function (m, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "dl flex align-items-c",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "r flex align-items-c" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "padding-left": "34px",
                                            },
                                          },
                                          [_vm._v("第")]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticClass: "w50 ml5 mr5",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          attrs: { value: i + 1, disabled: "" },
                                        }),
                                        _c("span", [_vm._v("次转赠")]),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("，获得")]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticClass: "w100 ml5 mr5",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          attrs: {
                                            type: "number",
                                            min: "0",
                                            oninput:
                                              "if(value){value=value.replace(/[^\\d]/g,'')}",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.editDayActionNew(i)
                                            },
                                          },
                                          model: {
                                            value: m.day,
                                            callback: function ($$v) {
                                              _vm.$set(m, "day", $$v)
                                            },
                                            expression: "m.day",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("天后可转赠，并收取"),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticClass: "w100 ml5 mr5",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          attrs: { type: "number" },
                                          on: { input: _vm.changeInputNew },
                                          model: {
                                            value: m.handlingFee,
                                            callback: function ($$v) {
                                              _vm.$set(m, "handlingFee", $$v)
                                            },
                                            expression: "m.handlingFee",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("元手续费")]),
                                        _vm._v(" "),
                                        i > 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "ml20",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delRuleActionNew(
                                                      i
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "pop",
                                staticStyle: { "padding-left": "34px" },
                              },
                              [_vm._v("最后一个规则覆盖之后次数的转赠规则")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "mt10",
                                staticStyle: { "padding-left": "34px" },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.addRuleActionNew },
                                  },
                                  [_vm._v("+增加规则")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }