import { render, staticRenderFns } from "./refund.vue?vue&type=template&id=21bcf0f6&scoped=true&lang=true"
import script from "./refund.vue?vue&type=script&lang=js"
export * from "./refund.vue?vue&type=script&lang=js"
import style0 from "./refund.vue?vue&type=style&index=0&id=21bcf0f6&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21bcf0f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/java_admin_Rm9R/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21bcf0f6')) {
      api.createRecord('21bcf0f6', component.options)
    } else {
      api.reload('21bcf0f6', component.options)
    }
    module.hot.accept("./refund.vue?vue&type=template&id=21bcf0f6&scoped=true&lang=true", function () {
      api.rerender('21bcf0f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/open3dAccount/cashOut/components/refund.vue"
export default component.exports