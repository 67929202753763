"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _open3Dgrade = require("@/api/open3Dgrade");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "GradeList",
  data: function data() {
    return {
      userType: "grade",
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "grade") {
        this.$router.push("/merchant/user/grade");
        return false;
      } else if (tab.name === "uptask") {
        this.$router.push("/merchant/user/uptask");
        return false;
      }
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _open3Dgrade.getListApi)(this.queryParams).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList("");
    },
    adduser: function adduser() {
      this.$router.push("./addGrade");
    },
    editHandle: function editHandle(row) {
      this.$router.push("./addGrade?id=" + row.id);
    }
  }
};