"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _nft = require("@/api/nft");
var _system = require("@/api/system");
var _product = require("@/api/product");
var _index4 = require("@/utils/index");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      SettingMer: _settingMer.default,
      types: 'works',
      formatPriceNumber: _index4.formatPriceNumber,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      name: '',
      // 作品名称
      seriesName: '',
      // 专辑名称
      hash: '',
      // 哈希
      authorName: '',
      // 作者Name
      issuerName: '',
      // 发行方Name
      categoryId: '',
      // 作品分类id
      status: '',
      // 作品状态
      fileType: '',
      // 文件类型 1 图片 2 3D 3 视频 4 音频
      attr: '',
      // 作品属性 1常规作品 2免费赠品 3合成碎片
      queryParams: {
        pageNum: 1,
        pageSize: 20
      },
      categoryList: [],
      attrList: [{
        id: 1,
        label: '常规作品'
      }, {
        id: 2,
        label: '免费赠品'
      }, {
        id: 3,
        label: '合成碎片'
      }],
      fileTypeList: [{
        id: 1,
        label: '图片'
      }, {
        id: 2,
        label: '3D'
      }, {
        id: 3,
        label: '视频'
      }, {
        id: 4,
        label: '音频'
      }],
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      showIncreaseQuantity: {
        show: false,
        id: '',
        addAmount: undefined
      },
      fileVisible: false,
      fileTypes: null,
      fileShow: null,
      showImg: false,
      showImgUrl: '',
      receiveData: {
        show: false,
        listLoading: true,
        list: [],
        total: 0,
        query: {
          pageNum: 1,
          pageSize: 10
        }
      },
      editCountData: {
        show: false
      }
    };
  },
  filters: {
    formatAttr: function formatAttr(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '常规作品';
          break;
        case 2:
          res = '免费赠品';
          break;
        case 3:
          res = '合成碎片';
          break;
        default:
          break;
      }
      return res;
    },
    formatIssuance: function formatIssuance(val) {
      var res = '';
      switch (val) {
        case true:
          res = '已发行';
          break;
        case false:
          res = '未发行';
          break;
        default:
          break;
      }
      return res;
    }
  },
  created: function created() {
    var _this = this;
    (0, _product.storeCategoryListApi)().then(function (res) {
      _this.categoryList = res.data;
    });
    this.getList(this.queryParams.pageNum);
  },
  methods: {
    showEditCountAction: function showEditCountAction(m) {
      this.editCountData = {
        show: true,
        virtualLotteryCount: m.virtualLotteryCount,
        id: m.id
      };
    },
    editCountAction: function editCountAction() {
      var _this2 = this;
      if (this.editCountData.virtualLotteryCount < 0) {
        this.$message.error('请输入正确的数量');
        return false;
      }
      (0, _nft.updateVirtualLotteryCountApi)({
        id: this.editCountData.id,
        virtualLotteryCount: this.editCountData.virtualLotteryCount
      }).then(function (res) {
        _this2.editCountData.show = false;
        _this2.$message.success('操作成功');
        _this2.getList();
      }).catch(function () {});
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === 'works') {
        this.$router.push('/merchant/digitalCollection/works/list');
        return false;
      } else if (tab.name === 'album') {
        this.$router.push('/merchant/digitalCollection/works/album');
        return false;
      }
    },
    nftCollectionListCheckApiFun: function nftCollectionListCheckApiFun(type, ids) {
      var _this3 = this;
      (0, _nft.nftCollectionListCheckApi)({
        type: type,
        // 1为上架检查 2为下架检查 3为删除检查 4为发布检查
        ids: ids
      }).then(function (res) {
        if (type === 1) {
          if (res.data.count === 0) {
            _this3.batchStatusMethod(ids, true);
          } else if (res.data.count !== res.data.requestCount) {
            _this3.$confirm("\u6709".concat(res.data.count, "\u4E2A\u4F5C\u54C1\u5DF2\u7ECF\u4E0A\u67B6\uFF0C\u662F\u5426\u7EE7\u7EED\u4E0A\u67B6\u5176\u5B83\u4F5C\u54C1\uFF1F"), '提示', {
              confirmButtonText: '确定',
              type: 'warning'
            }).then(function () {
              _this3.batchStatusMethod(ids, true);
            }).catch(function (_ref) {
              var message = _ref.message;
            });
          } else if (res.data.count === res.data.requestCount) {
            _this3.$message.warning('暂无可上架作品');
          }
        } else if (type === 2) {
          if (res.data.count === 0) {
            _this3.batchStatusMethod(ids, false);
          } else if (res.data.count !== res.data.requestCount) {
            _this3.$confirm("\u6709".concat(res.data.count, "\u4E2A\u4F5C\u54C1\u5DF2\u7ECF\u4E0B\u67B6\uFF0C\u662F\u5426\u7EE7\u7EED\u4E0B\u67B6\u5176\u5B83\u4F5C\u54C1\uFF1F"), '提示', {
              confirmButtonText: '确定',
              type: 'warning'
            }).then(function () {
              _this3.batchStatusMethod(ids, false);
            }).catch(function (_ref2) {
              var message = _ref2.message;
            });
          } else if (res.data.count === res.data.requestCount) {
            _this3.$message.warning('暂无可下架作品');
          }
        } else if (type === 3) {
          if (res.data.count === 0) {
            _this3.nftCollectionBatchIssueApiMethod(ids);
          } else if (res.data.count !== res.data.requestCount) {
            _this3.$confirm("\u6709".concat(res.data.count, "\u4E2A\u4F5C\u54C1\u5DF2\u7ECF\u53D1\u884C\uFF0C\u662F\u5426\u7EE7\u7EED\u53D1\u884C\u5176\u5B83\u4F5C\u54C1\uFF1F"), '提示', {
              confirmButtonText: '确定',
              type: 'warning'
            }).then(function () {
              _this3.nftCollectionBatchIssueApiMethod(ids);
            }).catch(function (_ref3) {
              var message = _ref3.message;
            });
          } else if (res.data.count === res.data.requestCount) {
            _this3.$message.warning('暂无可发行作品');
          }
        } else if (type === 4) {
          if (res.data.count === 0) {
            _this3.batchDeleteApiMethod(ids);
          } else if (res.data.count !== res.data.requestCount) {
            _this3.$confirm("\u6709".concat(res.data.count, "\u4E2A\u4F5C\u54C1\u4E0D\u53EF\u5220\u9664\uFF0C\u662F\u5426\u7EE7\u7EED\u5220\u9664\u5176\u5B83\u4F5C\u54C1\uFF1F"), '提示', {
              confirmButtonText: '确定',
              type: 'warning'
            }).then(function () {
              _this3.batchDeleteApiMethod(ids);
            }).catch(function (_ref4) {
              var message = _ref4.message;
            });
          } else if (res.data.count === res.data.requestCount) {
            _this3.$message.warning('暂无可删除作品');
          }
        }
      }).catch(function (res) {});
    },
    batchStatusMethod: function batchStatusMethod(ids, status) {
      var _this4 = this;
      (0, _product.batchstatusApi)({
        productIds: ids,
        showStatus: status
      }).then(function (_ref5) {
        var message = _ref5.message;
        _this4.$message.success('操作成功');
        _this4.checkedIds = [];
        _this4.getList('');
      });
    },
    nftCollectionBatchIssueApiMethod: function nftCollectionBatchIssueApiMethod(ids) {
      var _this5 = this;
      (0, _nft.nftCollectionBatchIssueApi)({
        productIds: ids
      }).then(function () {
        _this5.checkedIds = [];
        _this5.$message.success('操作成功');
        _this5.getList();
      });
    },
    batchDeleteApiMethod: function batchDeleteApiMethod(ids) {
      var _this6 = this;
      (0, _product.batchDeleteApi)({
        productIds: ids
      }).then(function () {
        _this6.checkedIds = [];
        _this6.$message.success('操作成功');
        _this6.getList();
      });
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index4.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    getList: function getList(num) {
      var _this7 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.setTypeAction();
      (0, _nft.nftCollectionListApi)(this.queryParams).then(function (res) {
        _this7.$nextTick(function () {
          _this7.tableData.data = res.rows;
          _this7.tableData.total = res.total;
          _this7.listLoading = false;
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    goSearch: function goSearch() {
      this.queryParams.name = this.name;
      this.queryParams.seriesName = this.seriesName;
      this.queryParams.hash = this.hash;
      this.queryParams.authorName = this.authorName;
      this.queryParams.issuerName = this.issuerName;
      this.queryParams.categoryId = this.categoryId[this.categoryId.length - 1];
      this.queryParams.status = this.status;
      this.queryParams.fileType = this.fileType;
      this.queryParams.attr = this.attr;
      this.getList(1);
    },
    addAction: function addAction() {
      this.$router.push('./detail');
    },
    // 选择
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.pageNum);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.queryParams.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this8 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this8.checkedIds.indexOf(id);
          if (index === -1) {
            _this8.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this8.checkedIds.indexOf(id);
          if (index > -1) {
            _this8.checkedIds.splice(index, 1);
          }
        });
      }
    },
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    batchUp: function batchUp() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.nftCollectionListCheckApiFun(1, this.checkedIds);
    },
    batchDown: function batchDown() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.nftCollectionListCheckApiFun(2, this.checkedIds);
    },
    batchIssueAction: function batchIssueAction() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.nftCollectionListCheckApiFun(3, this.checkedIds);
    },
    batchDel: function batchDel() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.nftCollectionListCheckApiFun(4, this.checkedIds);
    },
    editSortAction: function editSortAction(val, index) {
      var _this9 = this;
      // 修改排序
      var item = this.tableData.data[index];
      var params = {
        id: item.id,
        sort: val
      };
      (0, _nft.nftCollectionDetailApi)(params).then(function () {
        _this9.$message.success('操作成功');
        _this9.getList();
      }).catch(function () {});
    },
    editAction: function editAction(id) {
      this.$router.push('./detail?id=' + id);
    },
    issueAction: function issueAction(id) {
      var _this10 = this;
      this.$confirm('确认发行此作品？', '提示', {
        confirmButtonText: '发行',
        type: 'warning'
      }).then(function () {
        (0, _nft.nftCollectionIssueApi)(id).then(function () {
          _this10.$message.success('操作成功');
          _this10.getList();
        });
      }).catch(function (_ref6) {
        var message = _ref6.message;
      });
    },
    delAction: function delAction(id) {
      var _this11 = this;
      this.$confirm('确认删除作品？', '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      }).then(function () {
        (0, _nft.nftCollectionDelApi)(id).then(function () {
          _this11.$message.success('操作成功');
          _this11.getList();
        });
      }).catch(function (_ref7) {
        var message = _ref7.message;
      });
    },
    increaseQuantity: function increaseQuantity(id) {
      this.showIncreaseQuantity = {
        show: true,
        id: id,
        addAmount: undefined
      };
    },
    confirmIncreaseAction: function confirmIncreaseAction() {
      var _this12 = this;
      if (!this.showIncreaseQuantity.addAmount || isNaN(this.showIncreaseQuantity.addAmount)) {
        this.$message.error('请输入正确的增发数量');
        return false;
      }
      this.$confirm('确认增发' + this.showIncreaseQuantity.addAmount + '件藏品？', '提示', {
        confirmButtonText: '确认',
        type: 'warning'
      }).then(function () {
        (0, _nft.nftAddIssueNum)({
          id: _this12.showIncreaseQuantity.id,
          addAmount: _this12.showIncreaseQuantity.addAmount
        }).then(function () {
          _this12.$message.success('操作成功');
          _this12.showIncreaseQuantity.show = false;
          _this12.getList();
        });
      }).catch(function (_ref8) {
        var message = _ref8.message;
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this13 = this;
      var arr = [];
      arr.push(row.id);
      (0, _product.batchstatusApi)({
        productIds: arr,
        showStatus: !row.isShow
      }).then(function (_ref9) {
        var message = _ref9.message;
        _this13.checkedIds = [];
        _this13.getList('');
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    linkAction: function linkAction(id) {
      this.copyAction("".concat(_settingMer.default.appUrl, "/pages/nftCollection/index?id=").concat(id));
    },
    copyAction: function copyAction(val) {
      var input = document.createElement('input'); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    QRAction: function QRAction(id) {
      var _this14 = this;
      this.showImg = true;
      (0, _system.createQRCodeApi)({
        url: "/pages/nftCollection/index?id=".concat(id),
        width: 400,
        height: 400
      }).then(function (res) {
        _this14.showImgUrl = res.data.image;
      });
    },
    showVideoAction: function showVideoAction(row) {
      var _this15 = this;
      this.fileShow = row.file;
      this.fileTypes = row.fileType;
      this.fileVisible = true;
      if (this.fileTypes == 3) {
        this.$nextTick(function () {
          _this15.loadVideo();
        });
      }
    },
    loadVideo: function loadVideo() {
      var w = 550;
      this.player = TCPlayer('id_test_video', {
        width: w,
        height: w / 16 * 9,
        autoplay: true
      });
      this.player.src(this.fileShow);
    },
    closeBeforeAction: function closeBeforeAction(done) {
      if (this.fileTypes == 3) {
        if (this.player) {
          this.player.dispose();
          this.player = null;
        }
      }
      done();
    },
    showReceiveDataAction: function showReceiveDataAction(m) {
      this.receiveData = {
        show: true,
        listLoading: true,
        list: [],
        total: 0,
        query: {
          pageNum: 1,
          pageSize: 10,
          productId: m.productId
        }
      };
      this.pageChangeNew(1);
    },
    pageChangeNew: function pageChangeNew(page) {
      this.receiveData.query.pageNum = page;
      this.loadReceiveAction();
    },
    loadReceiveAction: function loadReceiveAction() {
      var _this16 = this;
      this.receiveData.listLoading = true;
      (0, _nft.drawLotsApi)(this.receiveData.query).then(function (res) {
        _this16.receiveData.total = res.total;
        _this16.receiveData.list = res.rows;
        _this16.receiveData.listLoading = false;
      }).catch(function () {});
    }
  }
};