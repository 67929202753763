"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      ruleForm: {
        title: '',
        type: this.$route.query.t || '1',
        // type 1为参拍须知 2 为交易服务隐私协议
        message: ''
      }
    };
  },
  created: function created() {
    var _this = this;
    (0, _setting.getAgreement)({
      type: this.ruleForm.type
    }).then(function (res) {
      _this.ruleForm.title = res.data.title;
      _this.ruleForm.message = res.data.message;
      _this.$refs['editor'].setContent(res.data.message);
    }).catch(function () {});
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      if (!this.ruleForm.title) {
        this.$message.error('请输入规则名称');
        return false;
      }
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _setting.setAgreement)(this.ruleForm).then(function (res) {
        loading.close();
        _this2.$message.success('操作成功');
        _this2.$router.back();
      }).catch(function () {});
    }
  }
};