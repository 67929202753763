"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _open3d = require("@/api/open3d");
var _index = require("@/utils/index");
var _holder = _interopRequireDefault(require("../../../order/consignmentSys/holder"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Holder: _holder.default
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      detail: {},
      formatPriceNumber: _index.formatPriceNumber,
      productId: '',
      visibleHolder: false
    };
  },
  created: function created() {
    var _this = this;
    console.log(this.id);
    (0, _open3d.getWorksDetailApi)(this.id).then(function (res) {
      _this.detail = res.data;
      // setTimeout(() => {
      //   this.$emit('showVideoAction', res.data)
      // }, 1000);
    }).catch(function () {});
  },
  methods: {
    showVideoAction: function showVideoAction() {
      this.$emit('showVideoAction', this.detail);
    },
    showUserAction: function showUserAction(id) {
      this.$emit('showUser', id);
    },
    holderAction: function holderAction(row) {
      this.productId = this.detail.productId;
      this.visibleHolder = true;
    },
    holderClose: function holderClose() {
      this.visibleHolder = false;
    }
  }
};