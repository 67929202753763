"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _attribute = _interopRequireDefault(require("./attribute"));
var _element = _interopRequireDefault(require("./element"));
var _widget = _interopRequireDefault(require("./widget"));
var _default = exports.default = {
  attribute: _attribute.default,
  element: _element.default,
  widget: _widget.default
};