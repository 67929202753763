"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserGroup',
  data: function data() {
    return {
      tableFrom: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      userType: 'label',
      visibleLabel: false,
      addLabelForm: {
        id: '',
        name: ''
      },
      labelRules: {
        name: [{
          required: true,
          message: '请输入用户标签名称',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    confirmLabelAction: function confirmLabelAction(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var search = _user.labelAddApi;
          if (_this.addLabelForm.id) {
            search = _user.labelEditApi;
          }
          search(_this.addLabelForm).then(function () {
            _this.$message.success('操作成功');
            _this.visibleLabel = false;
            _this.getList();
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === 'all') {
        this.$router.push('/merchant/user/list');
        return false;
      } else if (tab.name === 'label') {
        this.$router.push('/merchant/user/label');
        return false;
      } else if (tab.name === 'authentication') {
        this.$router.push('/merchant/user/verify');
        return false;
      } else if (tab.name === 'canel') {
        this.$router.push('/merchant/user/cancellation');
        return false;
      } else if (tab.name === 'regiest_set') {
        this.$router.push('/merchant/user/regiest_set');
        return false;
      } else if (tab.name === 'new_user_set') {
        this.$router.push('/merchant/user/new_user_set');
        return false;
      }
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _user.labelLstApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.visibleLabel = true;
      this.addLabelForm.id = '';
      this.addLabelForm.name = '';
    },
    // 编辑
    onEdit: function onEdit(row) {
      this.visibleLabel = true;
      this.addLabelForm.id = row.id;
      this.addLabelForm.name = row.name;
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u6807\u7B7E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.labelDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success('操作成功');
          _this3.tableData.data.splice(idx, 1);
          _this3.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this3.$message.error(message);
        });
      }).catch(function () {});
    }
  }
};