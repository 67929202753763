"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.allAssets = allAssets;
exports.allAssetsExport = allAssetsExport;
exports.allMemberAssets = allMemberAssets;
exports.allMemberAssetsExport = allMemberAssetsExport;
var _request = _interopRequireDefault(require("./request"));
//获取拍品资产数据
function allAssets(data) {
  return _request.default.get("/product/sys/platform/assets/allAssets", data);
}

//藏家资产
function allMemberAssets(data) {
  return _request.default.get("/product/sys/platform/assets/allMemberAssets", data);
}
//藏家导出
function allMemberAssetsExport(data) {
  return _request.default.get("/product/sys/platform/assets/allMemberAssetsExport", data);
}

//平台导出
function allAssetsExport(data) {
  return _request.default.get("/product/sys/platform/assets/allAssetsExport", data);
}