var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mt20", staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "mr15",
              attrs: { type: "primary", label: "default" },
              on: {
                click: function ($event) {
                  return _vm.addProject(null)
                },
              },
            },
            [_vm._v("创建套餐")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: {
              label: "解决方案",
              prop: "packageName",
              "min-width": "160",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "版本", prop: "versionName", "min-width": "160" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "功能板块",
              prop: "productStr",
              "min-width": "160",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "费用", prop: "priceStr", "min-width": "160" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "remark", "min-width": "240" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "50",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "m0 p0",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.addProject(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            staticClass: "mt20",
            attrs: {
              "page-sizes": [10, 20, 40, 80],
              "page-size": _vm.queryParams.pageSize,
              "current-page": _vm.queryParams.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "创建SaaS系统套餐",
            visible: _vm.drawerVisib,
            direction: "rtl",
            "append-to-body": "",
            size: "40%",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisib = $event
            },
          },
        },
        [
          _c("mealDetail", {
            attrs: {
              productList: _vm.productList,
              editPackageInfo: _vm.editPackageInfo,
            },
            on: { changeDrawerVisib: _vm.changeDrawerVisib },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }