"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "inventorycrate",
  data: function data() {
    return {
      loading: false,
      rules: {
        out_warehouse_id: [{
          required: true,
          message: "请选择仓库ID",
          trigger: "change"
        }],
        manage_admin_id: [{
          required: false,
          message: "请选择业务负责人",
          trigger: "change"
        }],
        out_type: [{
          required: true,
          message: "请选择出库类型",
          trigger: "change"
        }]
      },
      formValidate: {
        out_warehouse_id: "",
        manage_admin_id: "",
        out_type: ""
      },
      timeVal: [],
      goodsData: [],
      selectFlag: false,
      tableFrom: {
        page: 1,
        limit: 20,
        cate_id: "",
        keyword: "",
        product_id: ""
      },
      tableData: {
        data: [],
        total: 0
      },
      merSelect: [],
      editFlag: false,
      num: 0,
      id: this.$route.query.id || "",
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      },
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: "",
      storeArr: [],
      adminarr: [],
      goodsArr: [],
      goodsIdArr: [],
      BrandList: [],
      attrList: [],
      attr: [],
      orderno: '',
      mannagename: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    // if (this.id) {
    //   this.getGoodsList();
    // }
    // this.getDetail();
    (0, _warehouse.getOrderNo)().then(function (res) {
      _this.orderno = res.data.order_no;
    });
    this.getstore();
    this.getadminList();
    this.getBrandListApi();
    (0, _user.getBaseInfo)().then(function (res) {
      _this.label = res.data;
      _this.mannagename = res.data.merchantAdmin.real_name;
      if (res.data.isStaff) {
        _this.formValidate.manage_admin_id = res.data.merchantAdmin.merchant_admin_id;
      }
    }).catch(function (_ref) {
      var message = _ref.message;
    } // 
    );
  },
  methods: {
    close: function close() {
      this.$router.back();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this2 = this;
      (0, _product.brandListApi)().then(function (res) {
        _this2.BrandList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getadminList: function getadminList() {
      var _this3 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this3.adminarr = res.data.list;
      });
    },
    getstore: function getstore() {
      var _this4 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this4.storeArr = res.data.list;
      });
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // if(!this.goodsIdArr.length){
          //    return this.$message.error('请选择货品')
          // }
          _this5.loading = true;
          var goodsarr = _this5.goodsArr;
          if (!goodsarr.length) {
            return _this5.$message.error('请选择货品');
          }
          _this5.formValidate.productData = goodsarr;
          // this.formValidate.out_warehouse_id = this.formValidate.warehouse_id;
          if (_this5.id) {
            editinventoryOrder(_this5.id, _this5.formValidate).then(function (res) {
              _this5.loading = false;
              _this5.$message.success("保存成功");
              console.log(_this5.refser);
              if (_this5.refser) {
                _this5.$emit('savesuccess', res.message);
              } else {
                _this5.$router.back();
              }
            }).catch(function (res) {
              _this5.loading = false;
              // this.$message.error(res.message);
            });
            return;
          }
          (0, _warehouse.createoutOrder)(_this5.formValidate).then(function (res) {
            _this5.loading = false;
            if (res.status == 200) {
              _this5.$message.success("保存成功");
              _this5.$router.back();
            }
          }).catch(function (res) {
            _this5.loading = false;
            // this.$message.error(res.message);
          });
        } else {
          return false;
        }
      });
    },
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      //   this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.formValidate.stime = e[0];
      this.formValidate.etime = e[1];
    },
    getList: function getList() {
      var _this6 = this;
      var fun = _basic.goodswarehouseList;
      this.tableFrom.warehouse_id = this.formValidate.out_warehouse_id;
      fun(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.selected = false;
        });
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        _this6.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    selectInventGoods: function selectInventGoods() {
      this.selectFlag = true;
      this.getList();
      this.selectInventFlag = true;
    },
    selectActiveGoods: function selectActiveGoods() {
      if (!this.formValidate.out_warehouse_id) {
        this.$message.error('请选择仓库');
        return;
      }
      this.selectFlag = true;
      this.selectInventFlag = false;
      this.getList();
    },
    saleGoods: function saleGoods(row, index) {
      if (this.goodsIdArr.indexOf(row.product_id) > -1) {
        return;
      }
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      this.goodsArr.push(row);
      this.goodsIdArr.push(row.product_id);
    },
    closeSelect: function closeSelect() {
      this.selectFlag = false;
    },
    delGoods: function delGoods(index, row, cindex) {
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
      return;
      row.selected = false;
      this.tableData.data.splice(index, 1);
      this.tableData.data.push(row);
    }
  }
};