var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.createdDiy },
            },
            [_vm._v("新增自定义页面")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20 ft12",
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
            size: "mini",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "页面ID",
              prop: "name",
              width: "170",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "页面名称",
              prop: "name",
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.name) +
                            "\n          "
                        ),
                        scope.row.isIndex == 1
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "ml5",
                                attrs: { type: "success", effect: "dark" },
                              },
                              [_vm._v("首页")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "页面链接", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v("/pages/home/home?id=" + _vm._s(scope.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "站外推广", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "ft12",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.createQRCode(scope.row.id, 3)
                          },
                        },
                      },
                      [_vm._v("H5二维码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ft12",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.createQRCode(scope.row.id, 4)
                          },
                        },
                      },
                      [_vm._v("H5链接")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "name",
              width: "170",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.createTime) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.isIndex
                      ? _c(
                          "el-button",
                          {
                            staticClass: "ft12",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.setHomeAction(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("设置为首页\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ft12",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editDiy(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ft12",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.copy(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                    _vm._v(" "),
                    scope.row.status != 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "ft12",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delDiy(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": _vm.queryParams.pageSize,
              "current-page": _vm.queryParams.pageNum,
              layout: "total, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: { "current-change": _vm.pageChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }