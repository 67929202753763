var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mealBox" },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formState,
            rules: _vm.rules,
            size: "small",
            inline: "",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "formBlock",
              attrs: { label: "", prop: "systemType" },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { type: "button" },
                  model: {
                    value: _vm.formState.systemType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "systemType", $$v)
                    },
                    expression: "formState.systemType",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    {
                      attrs: {
                        disabled: _vm.formState.systemType == 2,
                        label: "1",
                      },
                    },
                    [_vm._v("SaaS系统")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-button",
                    {
                      attrs: {
                        disabled: _vm.formState.systemType == 1,
                        label: "2",
                      },
                    },
                    [_vm._v("独立系统")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formBlock",
              attrs: { label: "产品类型：", prop: "productType" },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "inputWidth",
                  attrs: { placeholder: "请选择产品类型" },
                  model: {
                    value: _vm.formState.productType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "productType", $$v)
                    },
                    expression: "formState.productType",
                  },
                },
                _vm._l(_vm.productTypeList, function (type) {
                  return _c("el-option", {
                    key: type.id,
                    attrs: { label: type.type, value: type.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formBlock productCode",
              attrs: { label: "产品名称：", prop: "productName" },
            },
            [
              _c("el-input", {
                staticClass: "inputWidth",
                attrs: { size: "small", placeholder: "请输入产品名称" },
                model: {
                  value: _vm.formState.productName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "productName", $$v)
                  },
                  expression: "formState.productName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "productCode",
              attrs: { label: "", prop: "productCode" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "80px" },
                attrs: { size: "small", placeholder: "编码" },
                model: {
                  value: _vm.formState.productCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "productCode", $$v)
                  },
                  expression: "formState.productCode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formBlock",
              attrs: { label: "产品价格：", prop: "price" },
            },
            [
              _c("el-input", {
                staticClass: "inputWidth",
                attrs: { size: "small", placeholder: "请输入产品价格" },
                model: {
                  value: _vm.formState.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "price", $$v)
                  },
                  expression: "formState.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formBlock",
              attrs: { label: "使用时长：", prop: "useTime" },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "inputWidth",
                  attrs: { placeholder: "请选择时长" },
                  model: {
                    value: _vm.formState.useTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "useTime", $$v)
                    },
                    expression: "formState.useTime",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "一年", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "三年", value: 3 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "五年", value: 5 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "永久", value: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formBlock",
              attrs: { label: "开通条件：", prop: "useCondition" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "360px" },
                attrs: { size: "small", placeholder: "请输入开通条件" },
                model: {
                  value: _vm.formState.useCondition,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "useCondition", $$v)
                  },
                  expression: "formState.useCondition",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "formArea",
              attrs: { label: "产品介绍：", prop: "content" },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: "8", resize: "none" },
                model: {
                  value: _vm.formState.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "content", $$v)
                  },
                  expression: "formState.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "mr15",
          attrs: { type: "primary", label: "default", size: "small" },
          on: { click: _vm.onSubmit },
        },
        [_vm._v("保存")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }