"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _system = require("@/api/system");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SystemLog',
  data: function data() {
    return {
      props: {
        emitPath: false
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      dialogVisible: false,
      categoryList: [],
      merCateList: [],
      merSelect: [],
      fullscreenLoading: false,
      roterPre: _settings.roterPre,
      tableFrom: {
        page: 1,
        limit: 20,
        category_id: '',
        store_name: '',
        type: '',
        mer_id: '',
        keyword: '',
        is_trader: ''
      },
      // 要展开的行，数值的元素是row的key值
      expands: [],
      idx: 0
    };
  },
  mounted: function mounted() {
    this.getList('');
  },
  methods: {
    // 获取row的key值
    getRowKeys: function getRowKeys(row) {
      return row.notice_log_id;
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      var that = this;
      that.listLoading = true;
      that.tableFrom.page = num || that.tableFrom.page;
      (0, _system.stationNewsList)(that.tableFrom).then(function (res) {
        that.tableData.data = res.data.list;
        that.tableData.total = res.data.count;
        that.listLoading = false;
        if (that.$route.params.id) {
          that.handleRead({
            notice_log_id: that.$route.params.id,
            is_read: 0
          });
          that.tableData.data.forEach(function (item, index) {
            if (item.notice_log_id == that.$route.params.id) {
              that.idx = index;
              console.log(that.idx);
              that.expands.push(that.tableData.data[that.idx].notice_log_id);
            }
          });
        }
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    handleRead: function handleRead(data) {
      var _this2 = this;
      if (data.is_read === 0) {
        (0, _system.stationNoticeRead)(data.notice_log_id).then(function (res) {
          _this2.listLoading = false;
          data.is_read = 1;
          if (_this2.$route.params.id && _this2.tableFrom.page === 1) {
            _this2.tableData.data[_this2.idx].is_read = 1;
          }
          // this.$refs.headerNotice.getList("");
        }).catch(function (res) {
          _this2.listLoading = false;
          // this.$message.error(res.message)
        });
      }
    }
  }
};