"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cms = require("@/api/cms");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ProductClassify',
  data: function data() {
    return {
      moren: require('@/assets/images/bjt.png'),
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      editCateData: {
        show: false,
        tagName: ''
      },
      cateLists: [],
      rules: {
        tagName: [{
          required: true,
          message: '请输入标签名称',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _cms.articleTagListApi)().then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    // 添加
    onAdd: function onAdd() {
      this.editCateData = {
        show: true,
        tagName: ''
      };
    },
    // 编辑
    onEdit: function onEdit(item) {
      this.editCateData = {
        show: true,
        tagName: item.tagName,
        id: item.id
      };
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _cms.articleDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        } // this.$message.error(message);
        );
      });
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.editCateData));
          data.parentId = _this4.editCateData.parentId && _this4.editCateData.parentId[_this4.editCateData.parentId.length - 1] ? _this4.editCateData.parentId[_this4.editCateData.parentId.length - 1] : '0';
          _this4.$confirm("\u786E\u8BA4".concat(_this4.editCateData.id ? '修改' : '添加', "\u8BE5\u6807\u7B7E?"), '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            var action = _cms.articleTagCreatedApi;
            if (_this4.editCateData.id) {
              action = _cms.articleTagUpdateApi;
            }
            action(data).then(function () {
              _this4.$message.success('操作成功');
              _this4.getList();
              _this4.editCateData.show = false;
            }).catch(function (_ref3) {
              var message = _ref3.message;
            });
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.editCateData.url = img[0];
      }, tit);
    },
    handleRemove: function handleRemove() {
      this.editCateData.url = '';
    }
  }
};