"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _automaticOrder = require("@/api/automaticOrder");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AccountManage",
  data: function data() {
    return {
      keyword: "",
      listColumn: [{
        label: "ID",
        prop: "memberId"
      }, {
        label: "用户姓名",
        prop: "nickName"
      }, {
        label: "手机号",
        prop: "phone"
      }, {
        label: "注册时间",
        prop: "userCreateTime"
      }, {
        label: "开通钱包",
        prop: "payNames"
      }, {
        label: "操作",
        prop: "handle"
      }],
      listLoading: false,
      listTableData: {
        data: [],
        total: 0
      },
      listPageConfig: {
        pageNum: 1,
        pageSize: 10
      },
      userName: "",
      userDialog: false,
      userTableData: {
        data: [],
        total: 0
      },
      userPageConfig: {
        pageNum: 1,
        pageSize: 10
      },
      userLoading: false,
      multipleUser: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(page) {
      var _this = this;
      var data = {
        keyword: this.keyword,
        pageNum: page ? page : this.listPageConfig.page,
        pageSize: this.listPageConfig.pageSize
      };
      this.listLoading = true;
      (0, _automaticOrder.autoOrderListApi)(data).then(function (res) {
        console.log(res);
        _this.listTableData.total = res.total;
        _this.listTableData.data = res.rows;
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPageConfig.pageSize = val;
      this.getList();
    },
    getUserList: function getUserList(page) {
      var _this2 = this;
      var data = {
        keyword: this.userName,
        pageNum: page ? page : this.userPageConfig.pageNum,
        pageSize: this.userPageConfig.pageSize
      };
      this.userLoading = true;
      (0, _automaticOrder.listByClearApi)(data).then(function (res) {
        _this2.userLoading = false;
        _this2.userTableData.total = res.total;
        _this2.userTableData.data = res.rows;
        if (!_this2.userDialog) {
          _this2.userDialog = true;
        }
      });
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.userPageConfig.pageSize = val;
      this.getUserList();
    },
    bathAddUser: function bathAddUser() {
      var _this3 = this;
      this.$confirm("确认添加吗?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var loadingInstance = _elementUi.Loading.service();
        var newArr = [];
        _this3.multipleUser.forEach(function (item) {
          newArr.push({
            memberId: item.id,
            phone: item.userPhone,
            nickName: item.nickName,
            userCreateTime: item.createTime
          });
        });
        (0, _automaticOrder.saveBatchApi)({
          autoOrderMembers: newArr
        }).then(function (res) {
          _this3.$message.success("添加成功");
          _this3.closeDialog("userDialog");
          _this3.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this3.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function () {});
    },
    addAccount: function addAccount() {
      this.getUserList(1);
    },
    closeDialog: function closeDialog(ele) {
      if (ele) {
        this[ele] = false;
        this.userName = "";
        this.userPageConfig.pageNum = 1;
      }
      this.getList(1);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleUser = val;
    },
    deleteUser: function deleteUser(_ref) {
      var _this4 = this;
      var id = _ref.id;
      this.$confirm("确认移除该用户吗?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var loadingInstance = _elementUi.Loading.service();
        (0, _automaticOrder.deleteUserApi)(id).then(function (res) {
          _this4.$message.success("移除成功");
          _this4.closeDialog();
          _this4.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this4.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function () {});
    },
    setPayAccount: function setPayAccount(_ref2) {
      var _this5 = this;
      var id = _ref2.id,
        hasPayAccount = _ref2.hasPayAccount;
      var word = hasPayAccount ? "确认取消支付账户吗?" : "确认设置为支付账户吗?";
      this.$confirm("".concat(word), "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var loadingInstance = _elementUi.Loading.service();
        (0, _automaticOrder.setPayAccountApi)({
          id: id,
          type: hasPayAccount ? 2 : 1
        }).then(function (res) {
          _this5.$message.success("设置成功");
          _this5.closeDialog();
          _this5.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this5.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function () {});
    }
  }
};