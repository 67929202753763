"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _animal = require("@/api/animal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "List",
  data: function data() {
    return {
      listLoading: false,
      animalList: {
        data: [],
        total: 0
      },
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      columnData: [{
        label: "ID",
        prop: "id"
      }, {
        label: "神兽名称",
        prop: "productName"
      }, {
        label: "用户持有",
        prop: "holdMember"
      }, {
        label: "产出速率/个",
        prop: "produceOrExchanges"
      }, {
        label: "初始灵力值",
        prop: "initialEnergy"
      }, {
        label: "灵力值上限",
        prop: "maxEnergy"
      }, {
        label: "灵力值消耗",
        prop: "consumeEnergy"
      }, {
        label: "操作",
        prop: "handle"
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  computed: {
    accountNum: function accountNum() {
      return function (row) {
        var arr = row.filter(function (item) {
          return item.type == 1;
        });
        return "".concat(arr[0].name, ":").concat(arr[0].accountNum, "\u79D2");
      };
    }
  },
  methods: {
    createAnimal: function createAnimal() {
      this.$router.push("detail");
    },
    editAnimal: function editAnimal(id) {
      this.$router.push({
        path: "detail",
        query: {
          id: id
        }
      });
    },
    getList: function getList() {
      var _this = this;
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      this.listLoading = true;
      this.pageConfig.pageNum = val;
      (0, _animal.getAnimalsListApi)(this.pageConfig).then(function (res) {
        console.log(res);
        _this.listLoading = false;
        _this.animalList.data = res.rows;
        _this.animalList.total = res.total;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.getList();
    }
  }
};