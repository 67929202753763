"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _nft = require("@/api/nft");
var _system = require("@/api/system");
var _user = require("@/api/user");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UserDetails",
  components: {
    fileList: _fileList.default
  },
  props: {
    uid: {
      type: String,
      default: null
    },
    showdetail: {
      type: Boolean,
      default: false
    },
    showretaildetail: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      timeVal: [],
      showview: false,
      moren: require("@/assets/images/f.png"),
      loading: false,
      columns: [],
      Visible: false,
      list: [{
        val: "0",
        label: "消费记录"
      }, {
        val: "3",
        label: "持有优惠券"
      }],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        type: '',
        timeType: '',
        nameOrPhone: '',
        pageNum: 1,
        pageSize: 20
      },
      orderTableData: {
        data: [],
        total: 0
      },
      orderTableFrom: {
        pageNum: 1,
        pageSize: 10
      },
      psInfo: null,
      type: "0",
      drawtype: "nft",
      userdetail: {},
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      nftListLoading: false,
      userjson: {},
      showusercard: false,
      showImg: false,
      imgSrc: "",
      fromList: [{
        text: "全部",
        val: ""
      }, {
        text: "今天",
        val: "today"
      }, {
        text: "昨天",
        val: "yesterday"
      }, {
        text: "最近7天",
        val: "lately7"
      }, {
        text: "最近30天",
        val: "lately30"
      }, {
        text: "本月",
        val: "month"
      }, {
        text: "本年",
        val: "year"
      }],
      showadd: false,
      adduid: "",
      userList: [],
      searchLoading: false,
      row: {},
      showOrderflag: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.uid) {
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.tableFrom.userId = this.uid;
      // this.tableFrom.type = 'order';
      (0, _marketing.distributorTeamListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
        _this.showview = true;
        loading.close();
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
      //   this.getNft();
    }
  },
  methods: {
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.timeType = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    delrow: function delrow(row) {
      var _this2 = this;
      this.$confirm("确定删除该团队成员吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _marketing.distributorDeleteTeamApi)({
          id: row.id
        }).then(function (res) {
          _this2.$message({
            type: "success",
            message: "删除成功"
          });
          _this2.getList();
        });
      });
    },
    handleadd: function handleadd() {
      var _this3 = this;
      var params = {
        userId: this.adduid,
        retailUserId: this.uid
      };
      (0, _marketing.distributorAddTeamApi)(params).then(function (res) {
        _this3.showadd = false;
        _this3.getList(1);
        _this3.$emit('refash');
      });
    },
    getList: function getList(page) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = page || this.tableFrom.pageNum;
      (0, _marketing.distributorTeamListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.rows;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    changeadduser: function changeadduser() {
      this.showadd = true;
      this.adduid = "";
      this.searchUserAction();
    },
    searchUserAction: function searchUserAction(data) {
      var _this5 = this;
      var params = {
        nameOrPhone: data,
        pageNum: 1,
        pageSize: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this5.userList = res.rows;
      });
    },
    changedrawtype: function changedrawtype(type) {
      this.drawtype = type;
    },
    changeshowdetail: function changeshowdetail() {
      this.$emit("close");
    },
    pageChange: function pageChange(page) {
      var _this6 = this;
      this.tableFrom.pageNum = page;
      (0, _marketing.distributorTeamListApi)(this.tableFrom).then(function (res) {
        _this6.tableData.data = res.rows;
        _this6.tableData.total = res.total;
        _this6.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
      // this.getInfo(this.type)
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this7 = this;
      this.tableFrom.pageSize = val;
      (0, _marketing.distributorTeamListApi)(this.tableFrom).then(function (res) {
        _this7.tableData.data = res.rows;
        _this7.tableData.total = res.total;
        _this7.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
      // this.getInfo(this.type)
    },
    showOrder: function showOrder(row) {
      this.showOrderflag = true;
      this.orderTableFrom.userId = this.uid;
      this.orderTableFrom.uid = row.uid;
      this.getOrderList(1);
    },
    getOrderList: function getOrderList(page) {
      var _this8 = this;
      this.orderTableFrom.pageNum = page || this.orderTableFrom.pageNum;
      (0, _marketing.distributorOrderListApi)(this.orderTableFrom).then(function (res) {
        _this8.orderTableData.data = res.rows;
        _this8.orderTableData.total = res.total;
        _this8.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this8.listLoading = false;
      });
    },
    pageOrderChange: function pageOrderChange(page) {
      var _this9 = this;
      this.orderTableFrom.pageNum = page;
      (0, _marketing.distributorOrderListApi)(this.orderTableFrom).then(function (res) {
        _this9.orderTableData.data = res.rows;
        _this9.orderTableData.total = res.total;
        _this9.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this9.listLoading = false;
      });
    },
    handleOrderSizeChange: function handleOrderSizeChange(val) {
      var _this10 = this;
      this.orderTableFrom.pageSize = val;
      (0, _marketing.distributorOrderListApi)(this.orderTableFrom).then(function (res) {
        _this10.orderTableData.data = res.rows;
        _this10.orderTableData.total = res.total;
        _this10.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this10.listLoading = false;
      });
    },
    getHeader: function getHeader() {
      this.psInfo = this.row;
    },
    showImgAction: function showImgAction(img) {
      this.imgSrc = img;
      this.showImg = true;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this11 = this;
      // this.tableFrom)
      (0, _marketing.retailTeamMemberExport)(this.tableFrom).then(function (res) {
        _this11.$message.success('操作成功');
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(30);
    }
  }
};