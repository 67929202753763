"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var userRouter = {
  path: "".concat(_settings.roterPre, "/saas"),
  name: "saas",
  meta: {
    icon: "dashboard",
    title: "云总部SaaS"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "noRedirect",
  children: [{
    path: "saasUser",
    name: "saasUser",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/userManage/index.vue"));
      });
    },
    redirect: "".concat(_settings.roterPre, "/saas/saasUser/index"),
    children: [{
      path: "index",
      name: "saasUserIndex",
      meta: {
        title: "客户列表",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/saas/saasUser")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/userManage/userlist.vue"));
        });
      }
    }, {
      path: "statement",
      name: "saaStatement",
      meta: {
        title: "统计报表",
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/userManage/statement.vue"));
        });
      }
    }]
  }, {
    path: "saasOrder",
    name: "saasOrder",
    meta: {
      title: "订单管理",
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/orderManage/index.vue"));
      });
    }
  }, {
    path: "saasProduct",
    name: "saasProduct",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/productConfig/index.vue"));
      });
    },
    redirect: "".concat(_settings.roterPre, "/saas/saasProduct/index"),
    children: [{
      path: "index",
      name: "saasIndex",
      meta: {
        title: "SaaS产品",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/saas/saasProduct")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/productConfig/saasPro.vue"));
        });
      }
    }, {
      path: "setMeal",
      name: "saaSetMeal",
      meta: {
        title: "SaaS套餐",
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/productConfig/setMeal.vue"));
        });
      }
    }, {
      path: "system",
      name: "saaSystem",
      meta: {
        title: "独立系统",
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/productConfig/system.vue"));
        });
      }
    }, {
      path: "edition",
      name: "saasEdition",
      meta: {
        title: "系统版本",
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/saasCloud/productConfig/edition.vue"));
        });
      }
    }]
  }]
};
var _default = exports.default = userRouter;