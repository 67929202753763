var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "min-height": "85vh" } },
        [
          _c(
            "div",
            { staticClass: "top-view plr20" },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: {
                    "default-active": _vm.$route.path,
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                [
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/merchant/manage/list" } },
                    [_vm._v("商户列表")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/merchant/manage/setting" } },
                    [_vm._v("身份设置")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/merchant/manage/review" } },
                    [_vm._v("商户审核")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }