"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      listLoading: true,
      queryParams: {
        status: '',
        pageNum: 1,
        pageSize: 20,
        flagChnl: '',
        payType: '',
        timeType: ''
      },
      timeType: [],
      timeList: [{
        text: '全部',
        val: ''
      }, {
        text: '今天',
        val: 'today'
      }, {
        text: '昨天',
        val: 'yesterday'
      }, {
        text: '最近7天',
        val: 'lately7'
      }, {
        text: '最近30天',
        val: 'lately30'
      }, {
        text: '本月',
        val: 'month'
      }, {
        text: '本年',
        val: 'year'
      }],
      payList: [{
        name: '全部',
        id: ''
      }]
    };
  },
  filters: {
    formatType: function formatType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '作品';
          break;
        case 2:
          res = '盲盒';
          break;
        default:
          break;
      }
      return res;
    },
    formatStatus: function formatStatus(val) {
      var res = '';
      switch (val) {
        case 0:
          res = '退款中';
          break;
        case 1:
          res = '已退款';
          break;
        default:
          break;
      }
      return res;
    }
  },
  mounted: function mounted() {
    this.getList(1);
    this.getPayTypeList();
  },
  methods: {
    getPayTypeList: function getPayTypeList() {
      var _this = this;
      this.payList = [{
        name: '全部',
        id: ''
      }];
      (0, _order.getPayType)().then(function (res) {
        // console.log(res)
        // this.queryParams.payType = res.data[0].id;
        _this.payList = _this.payList.concat(res.data);
      }).catch(function (res) {});
    },
    exportOrder: function exportOrder() {
      var _this2 = this;
      // alert('暂未开发')
      (0, _finance.getPay)(this.queryParams).then(function (res) {
        _this2.$message.success('操作成功');
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(26);
    },
    selectChange: function selectChange(val) {
      console.log(val, 228);
      this.timeType = [];
      this.queryParams.date = val;
      this.queryParams.timeType = this.queryParams.date;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.timeType = e ? this.timeType.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _finance.getPayOrderList)(this.queryParams).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        // this.tableData.statistical = res.statistical;
        // if (this.tableData.statistical !== null) {
        //   this.tableData.statistical['all'] = this.tableData.statistical.overCount + this.tableData.statistical.closeCount + this.tableData.statistical.waitCount
        // }
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList('');
    }
  }
};