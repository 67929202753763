"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectDestructuringEmpty2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectDestructuringEmpty.js"));
require("core-js/modules/es6.function.name");
var _default = exports.default = {
  namespaced: true,
  state: {
    index: -1,
    classify: [],
    groups: [],
    pictures: [],
    classifyInfo: {},
    pageInfo: {
      size: 15,
      total: 0,
      current: 1
    }
  },
  mutations: {
    /**
     * 设置分类
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setIndex: function setIndex(state, data) {
      state.index = data;
    },
    /**
     * 设置分类
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setClassify: function setClassify(state, data) {
      state.classify = data;
    },
    /**
     * 设置分类
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setGroups: function setGroups(state, data) {
      state.groups = data;
    },
    /**
     * 设置分类信息
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setClassifyInfo: function setClassifyInfo(state, data) {
      state.classifyInfo = data;
    },
    /**
     * 设置图片
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setPictures: function setPictures(state, data) {
      state.pictures = data;
    },
    /**
     * 设置分页
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setPageInfo: function setPageInfo(state, data) {
      state.pageInfo = data;
    }
  },
  actions: {
    /**
     * 创建分类数据
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    handleMakeClassify: function handleMakeClassify(_ref, _ref2) {
      var state = _ref.state,
        commit = _ref.commit,
        dispatch = _ref.dispatch;
      var name = _ref2.name,
        id = _ref2.id,
        type = _ref2.type;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('post', {
          include: 'gallery'
        }, {
          name: name,
          parent_id: id || 0,
          sort: 5
        }).then(function (data) {
          resolve(data);
        }).catch(function (error) {
          if (type == 1) {
            dispatch('handleClassify', 0);
          }
          if (type == 2) {
            dispatch('handlePictures', {
              id: id,
              current: 1
            });
          }
        });
      });
    },
    /**
     * 编辑分类数据
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    handleEditClassify: function handleEditClassify(_ref3, value) {
      var state = _ref3.state,
        commit = _ref3.commit,
        dispatch = _ref3.dispatch;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('put', {
          include: 'gallery',
          id: parseInt(value.id)
        }, {
          name: value.name
        }).then(function (data) {
          resolve(data);
        }).catch(function (error) {
          console.error('修改分类报错', error);
          VM.$message({
            type: 'error',
            message: '修改失败!'
          });
        });
      });
    },
    /**
     * 删除分组
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    handleDeleteClassify: function handleDeleteClassify(_ref4, _ref5) {
      var state = _ref4.state,
        commit = _ref4.commit,
        dispatch = _ref4.dispatch;
      var id = _ref5.id,
        type = _ref5.type;
      var VM = this._vm;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('delete', id, {
          include: 'gallery'
        }).then(function (data) {
          VM.$message({
            type: 'success',
            message: '删除成功!'
          });
          if (type == 1) {
            dispatch('handleClassify', 0);
          }
          if (type == 2) {
            dispatch('handlePictures', {
              id: id,
              current: 1
            });
          }
          resolve(data);
        }).catch(function (error) {
          VM.$alert('此分组下有文件夹，不支持删除', '', {
            confirmButtonText: '我知道了'
          });
        });
      });
    },
    /**
     * 获取分类数据
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    handleClassify: function handleClassify(_ref6) {
      var state = _ref6.state,
        commit = _ref6.commit,
        dispatch = _ref6.dispatch;
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('get', {
          include: 'gallery',
          parent_id: id
        }).then(function (data) {
          commit('setClassify', data);
          commit('setClassifyInfo', data[0]);
          dispatch('handlePictures', {
            id: data[0].id,
            current: 1,
            pageSize: 15
          });
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    /**
     * 获取所欲分类
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    handleGroups: function handleGroups(_ref7) {
      var state = _ref7.state,
        commit = _ref7.commit,
        dispatch = _ref7.dispatch;
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.group('get', {
          include: 'gallery'
        }).then(function (data) {
          commit('setGroups', data);
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    /**
     * 获取所欲分类
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.nickName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    handleMoveGroup: function handleMoveGroup(_ref8, _ref9) {
      var state = _ref8.state,
        commit = _ref8.commit,
        dispatch = _ref8.dispatch;
      var list = _ref9.list,
        group_id = _ref9.group_id;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.gallery('put', {
          behavior: 'setgroup',
          id: list
        }, {
          group_id: group_id
        }).then(function (data) {
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    /**
     * 获取照片信息
     * @param  {[type]} options.state  [description]
     * @param  {[type]} options.commit [description]
     * @return {[type]}                [description]
     */
    handlePictures: function handlePictures(_ref10, _ref11) {
      var _this = this;
      var state = _ref10.state,
        commit = _ref10.commit;
      var id = _ref11.id,
        current = _ref11.current,
        pageSize = _ref11.pageSize;
      var $heshop = this._vm.$heshop;
      var $pageSize = pageSize || 24;
      var $id = id || 0;
      return new Promise(function (resolve, reject) {
        $heshop.gallery('get', {
          group_id: parseInt($id)
        }).page(current, $pageSize).then(function (data) {
          var size = parseInt(data.headers['x-pagination-per-page']);
          var total = parseInt(data.headers['x-pagination-total-count']);
          var current = parseInt(data.headers['x-pagination-current-page']);
          commit('setPageInfo', {
            size: size,
            total: total,
            current: current
          });
          commit('setPictures', data.data);
          resolve(data.data);
        }).catch(function (error) {
          _this.loading = false;
        });
      });
    },
    /**
     * 编辑照片信息
     * @param  {[type]} options.state  [description]
     * @param  {[type]} options.commit [description]
     * @return {[type]}                [description]
     */
    handleEditPicture: function handleEditPicture(_ref12, value) {
      var _this2 = this;
      var state = _ref12.state,
        commit = _ref12.commit;
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.gallery('put', parseInt(value.id), {
          title: value.name
        }).then(function (data) {
          resolve(data);
        }).catch(function (error) {
          _this2.loading = false;
        });
      });
    },
    /**
     * 删除图片
     * @param  {[type]} options.state  [description]
     * @param  {[type]} options.commit [description]
     * @param  {[type]} data           [description]
     * @return {[type]}                [description]
     */
    handleDeleteGallery: function handleDeleteGallery(_ref13, value) {
      var _this3 = this;
      (0, _objectDestructuringEmpty2.default)(_ref13);
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.gallery('delete', value).then(function (data) {
          resolve(data);
        }).catch(function (error) {
          _this3.loading = false;
        });
      });
    }
  }
};