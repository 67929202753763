"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _widget = _interopRequireDefault(require("@/components/customPage/widget.vue"));
var _element = _interopRequireDefault(require("./element.vue"));
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Widget: _widget.default,
    Element: _element.default
  },
  props: {
    index: {
      type: [Number, String]
    },
    facade: {
      type: [Object, Array]
    },
    content: {
      type: [Object, Array]
    }
  }
};