var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      size: "small",
                      inline: "",
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "width100",
                        attrs: { label: "用户手机号：" },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "selWidth",
                            attrs: { placeholder: "请输入", size: "small" },
                            model: {
                              value: _vm.tableFrom.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "phone", $$v)
                              },
                              expression: "tableFrom.phone",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.exportOrder },
                  },
                  [_vm._v("导出")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: { click: _vm.getExportFileList },
                  },
                  [_vm._v("导出记录")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  size: "small",
                  "row-key": "box_id",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "aircraft_id",
                    label: "用户名",
                    "min-width": "140",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "default" },
                              on: {
                                click: function ($event) {
                                  return _vm.showuser(scope.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(scope.row.nickName) +
                                  " (" +
                                  _vm._s(scope.row.memberPhone) +
                                  ")"
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "投入情况", "min-width": "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n                        " +
                              _vm._s(scope.row.productName) +
                              "*" +
                              _vm._s(scope.row.productCount) +
                              " - " +
                              _vm._s(scope.row.magnification) +
                              "\n                    "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "getScore",
                    label: "获得保卫积分",
                    "min-width": "140",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "createTime",
                    label: "投入时间",
                    "min-width": "140",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [20, 40, 60, 80],
                    "page-size": _vm.tableFrom.limit,
                    "current-page": _vm.tableFrom.page,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.pageChange,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("file-list", { ref: "exportList" }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.showImg,
                  "close-on-click-modal": false,
                  center: "",
                  title: "查看二维码",
                  width: "500px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showImg = $event
                  },
                },
              },
              [
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: { src: _vm.showImgUrl, alt: "" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "挖矿成绩",
                  visible: _vm.resultflag,
                  width: "600px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.resultflag = $event
                  },
                },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.resultData.data, size: "small" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "aircraft_id",
                        label: "挖矿成绩",
                        "min-width": "140",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(scope.row.title) +
                                  " "
                              ),
                              scope.row.type == 1
                                ? _c("span", [
                                    _vm._v(
                                      "(藏品：" +
                                        _vm._s(scope.row.product.store_name) +
                                        "*" +
                                        _vm._s(scope.row.num) +
                                        ")"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "add_time",
                        label: "获得时间",
                        "min-width": "140",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [20, 40, 60, 80],
                        "page-size": _vm.resultFrom.limit,
                        "current-page": _vm.resultFrom.page,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.resultData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeResultChange,
                        "current-change": _vm.pageResultChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }